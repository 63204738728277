import { FormControl, Box, TextField, Typography, Button, RadioGroup, FormControlLabel, Radio, CircularProgress, Checkbox, Paper, useTheme } from '@mui/material'
import { formatDateTimeForInputField } from '../../../utils/exam-utilities'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import AudioInput from '../../../components/exams-components/AudioInput'
import BreadcrumbList from '../../../components/common/BreadcrumbList'
import { useSettingsStore } from '../../../store/settings.store'
import { useNavigate, useParams } from 'react-router-dom'
import { addStyles } from "react-mathquill"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useExamStore } from '../../../store/exam.store'
import noExamsImg from '../../../assets/noexams.svg'
import React, { useEffect, useState } from 'react'
import { Icons, latexDelimiters } from '../../../utils/utilities'
import { Add, Mic } from '@mui/icons-material'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import * as Yup from 'yup'
import CustomWysiwyg from '../../../components/common/CustomWysiwyg'
import Latex from 'react-latex-next'


export default function AddQuestions() {
    const [multipleCorrectAnswers, setMultipleCorrectAnswers] = useState( {} )
    const [audioRecorderStatus, setAudioRecorderStatus] = useState( false )
    const [selectedAudioFiles, setSelectedAudioFiles] = useState( [] )
    const [numberOfAnswer, setNumberOfAnswer] = useState( [1] )
    const [latexOptions, setLatexOptions] = useState( {} )
    const [plainAnswers, setPlainAnswers] = useState( {} )
    const [inProgress, setInProgress] = useState( true )
    const [isAdding, setIsAdding] = useState( false )
    const [answers, setAnswers] = useState( {} )
    const [exam, setExam] = useState( null )

    const SettingsStore = useSettingsStore()
    const ExamStore = useExamStore()
    const navigate = useNavigate()
    const { id } = useParams()

    const { palette, border, theme } = useTheme()

    addStyles()

    const initialValues = {
        question: "",
        questionUrl: "",
        hint: "",
        marks: 1,
        questionType: "MCQ",
        selectionType: "single",
        answers: '',
        correctAnswer: null
    }

    const validationSchema = Yup.object().shape( {
        question: Yup.string().required( "This field is required" ),
        marks: Yup.number().required( "This field is required" ).min( 1, "Minimum marks should be 1" ),
        questionUrl: Yup.string().notRequired().matches( /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Please provide a valid URL" ),
        answers: Yup.mixed().required( "Answer is required!" )
            .test( {
                name: "MCQ_ANSWERS", message: "Provide options for MCQ type questions", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' ) {
                        if ( context.parent.answers ) {
                            if ( Object.keys( context.parent.answers ).length < 2 ) return false
                            else return true
                        }
                    } else return true
                }
            } )
            .test( {
                name: "CORRECT_ANSWER", message: "Select the correct answer", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' && context.parent.selectionType === 'single' ) {
                        if ( !context.parent.correctAnswer ) return false
                        return true
                    } else return true
                }
            } )
            .test( {
                name: "MULTIPLE_CORRECT_ANSWER", message: "Please select multiple correct answers", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' && context.parent.selectionType !== 'single' ) {
                        if ( !context.parent.correctAnswer ) {
                            return false
                        } else {
                            let correctAnswersCount = 0
                            for ( const answer in context.parent.correctAnswer ) {
                                if ( context.parent.correctAnswer[answer] ) correctAnswersCount++
                                if ( correctAnswersCount > 1 ) return true
                            }
                            return false
                        }
                    } else return true
                }
            } )
            .test( {
                name: "EMPTY_ANSWERS", message: "Please fill all the answer fileds!", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' ) {
                        if ( context.parent.answers ) {
                            for ( const ans in plainAnswers ) {
                                if ( plainAnswers[ans].trim() === '' ) {
                                    return false
                                }
                            }
                            if ( numberOfAnswer.length !== Object.keys( context.parent.answers ).length )
                                return false
                            return true
                        }
                    } else return true
                }
            } )
    } )

    const addAnswerField = () => {
        setNumberOfAnswer( [...numberOfAnswer, numberOfAnswer.length + 1] )
    }

    // const setQuestionType = ( e ) => {
    //     if ( e.target.value === 'descriptive' ) {
    //         setNumberOfAnswer( [1] )
    //         setMultipleCorrectAnswers( {} )
    //     }
    // }

    const setNewAnswers = ( number, answer, text ) => {
        const newAnswers = { ...answers, [number]: answer }
        setAnswers( newAnswers )
        setPlainAnswers( { ...plainAnswers, [number]: text } )
        return newAnswers
    }

    const submit = async ( values, props ) => {
        setIsAdding( true )
        try {
            const formData = new FormData()
            for ( const name in values ) {
                if ( typeof values[name] === 'object' )
                    formData.append( name, JSON.stringify( values[name] ) )
                else
                    formData.append( name, values[name] )
            }
            if ( selectedAudioFiles.length > 0 ) {
                for ( let j = 0; j < selectedAudioFiles.length; j++ ) {
                    const item = selectedAudioFiles[j]
                    console.log( item )
                    formData.append( 'audioattachment[]', item, item.name )
                }
            }
            const res = await api.addQuestion( id, formData )
            if ( res.status === 200 ) {
                props.resetForm()
                toast.success( "Question added" )
                setPlainAnswers( {} )
                setAnswers( {} )
                setNumberOfAnswer( [1] )
                setMultipleCorrectAnswers( {} )
                document.querySelectorAll( '.ql-editor' ).forEach( editor => {
                    editor.textContent = ""
                } )
                setLatexOptions( {} )
                setSelectedAudioFiles( [] )
            }
        } catch ( e ) {
            // toast( e.response.data )
            console.log( e )
        } finally {
            setIsAdding( false )
        }
    }

    useEffect( () => {
        const getExam = async () => {
            try {
                await ExamStore.fetchExam( id )
                if ( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() ) {
                    toast.error( "Exam already started! can't add questions now" )
                    navigate( `/faculty/exams/${id}` )
                } else if ( ExamStore.getExam.exam.exam_status === 'Published' ) {
                    toast.error( "Exam already published! can't add questions now" )
                    navigate( `/faculty/exams/${id}` )
                }
                else setExam( ExamStore.getExam.exam )
            }
            catch ( e ) {
                if ( e.response && e.response.data && "Network Error" === e.response.data ) {
                    navigate( '/networkerror' )
                }
                toast.error( ( e.response && e.response.data ) ? e.response.data : "Something went wrong! try again later" )
            } finally {
                setInProgress( false )
            }
        }
        getExam()
    }, [id, navigate, ExamStore] )

    return (
        <Box flexGrow={1} minWidth="0" overflow="auto" sx={{ padding: "20px" }}>

            {exam && <BreadcrumbList items={[
                { label: "Exams", link: `/faculty/exams` },
                { label: exam ? exam.exam_name : <CircularProgress size={12} />, link: `/faculty/exams/${id}` },
                "Add question"
            ]} />}

            <Paper sx={{ overflow: "hidden" }}>
                {exam && !inProgress &&
                    <Box padding="20px" sx={{ background: ` linear-gradient(233.69deg, #6A6790 -93.75%, #1F1E2A 145.73%)` }} >
                        <Typography color="white" textTransform="capitalize" variant='h5'>{exam.exam_name}</Typography>
                        <Typography color="white" variant='body2' >Add questions and options</Typography>
                    </Box>
                }
                {!exam && !inProgress && <Box display="flex" flexDirection="column" gap="10px" alignItems="center" justifyContent="center" padding="20px">
                    <img src={noExamsImg} width="150px" alt="No Exams" />
                    <Typography variant='subtitle2' color="textSecondary">The exam you are looking for does not exists!</Typography>
                    <Button startIcon={Icons.default.ArrowBack} sx={{ textTransform: "capitalize", marginTop: "10px" }} variant='outlined' size="small" onClick={() => navigate( `/faculty/exams`, { replace: true } )}>Back to exam</Button>
                </Box>}
                {exam && !inProgress && <>
                    <Box margin="20px" padding="20px" border={border[1]} bgcolor={palette.contentBg} borderRadius="5px">
                        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={submit}>
                            {
                                ( { values, setFieldValue } ) => (
                                    <Form autoComplete='off' style={{ display: exam ? "block" : "none" }}>
                                        <FormControl fullWidth margin="normal" sx={{ maxWidth: "900px" }} >
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom >Question*</Typography>
                                            <Box display="flex" borderColor={palette.primary.main} bgcolor={theme === 'dark' ? "#232323" : "#fff"}>
                                                {/* <EditableMathField
                                                    latex={latex}
                                                    onChange={( mathField ) => {
                                                        setFieldValue( 'question', `LATEX-${mathField.latex()}` )
                                                        setLatex( mathField.latex() )
                                                    }}
                                                    placeholder='Question goes here...'
                                                /> */}
                                                <CustomWysiwyg key="question-text" placeholder="Write question here..." onChange={val => setFieldValue( 'question', val )} styles={{ flexGrow: 1 }} value={values.question} />
                                                {values.question?.match( /\$/ ) && <Box marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} borderRadius="10px">
                                                    <Latex delimiters={latexDelimiters} >{values.question}</Latex>
                                                </Box>}
                                            </Box>
                                            <Typography sx={{ color: "errorMessage.main" }}><ErrorMessage name='question' /></Typography>
                                        </FormControl>
                                        {SettingsStore.getUseSettings['exam_question_audio_attachment_enable'] && <Box>
                                            {!audioRecorderStatus && <Typography color="primaryDark.main" onClick={() => setAudioRecorderStatus( !audioRecorderStatus )} variant='subtitle2' sx={{ cursor: "pointer", fontSize: "12px", display: "flex", alignItems: "center", gap: "10px", textTransform: "capitalize" }} > <Mic sx={{ fontSize: "14px" }} /> Add audio attachment</Typography>}
                                            {audioRecorderStatus &&
                                                <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                                    <Typography variant="subtitle2" color={palette.labelColor} gutterBottom >Question audio attachment</Typography>
                                                    <AudioInput supportedAudioFormats={SettingsStore.getUseSettings['question_audio_attachment_suported_formats']} maxSize={SettingsStore.getUseSettings['exam_question_audio_attachment_max_size']} container="bordered-box" setRecordedCardStatus={setAudioRecorderStatus} selectedAudioFiles={selectedAudioFiles} setSelectedAudioFiles={setSelectedAudioFiles} maxLength={SettingsStore.getUseSettings['exam_question_audio_attachment_max_length']} />
                                                </FormControl>
                                            }
                                        </Box>}
                                        <FormControl fullWidth margin="normal" sx={{ maxWidth: "900px" }} >
                                            <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Hint</Typography>
                                            <Field size="small" as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} name="hint" />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal" sx={{ maxWidth: "900px" }} >
                                            <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Marks *</Typography>
                                            <Field size="small" as={TextField} onWheel={( e ) => e.target.blur()} InputLabelProps={{ sx: { fontSize: "14px !important" } }} type="number" name="marks" />
                                            <Typography sx={{ color: "errorMessage.main" }}><ErrorMessage name='marks' /></Typography>
                                        </FormControl>

                                        <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                            <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Answer selection type</Typography>
                                            <RadioGroup name='selectionType' value={values.selectionType} color='primaryDark' onChange={( e ) => ( setFieldValue( 'selectionType', e.target.value ) )} row>
                                                <FormControlLabel value="single" control={<Radio color="primaryDark" />} label="Single" />
                                                <FormControlLabel value="multiple" control={<Radio color="primaryDark" />} label="Multiple" />
                                            </RadioGroup>
                                        </FormControl>
                                        <RadioGroup name='correctAnswer' onChange={( e ) => { setFieldValue( 'correctAnswer', e.target.value ) }} value={values.correctAnswer}>
                                            {numberOfAnswer.map( ( i, index ) => (
                                                <FormControl key={index} fullWidth sx={{ maxWidth: "900px" }} >
                                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                                        <Typography color={palette.labelColor} variant='subtitle2'>Answer {index === 0 ? ' ' : index + 1} *</Typography>
                                                        {values.questionType === 'MCQ' && values.selectionType === 'single' && <FormControlLabel value={`${index}`} control={<Radio color="primaryDark" />} labelPlacement="end" label={<Typography variant="subtitle2" color="textSecondary">Correct answer</Typography>} />}
                                                        {values.questionType === 'MCQ' && values.selectionType !== 'single' && <FormControlLabel value={`${index}`} control={<Checkbox color="primaryDark" name='correctAnswer' onChange={e => { setFieldValue( 'correctAnswer', { ...multipleCorrectAnswers, [index]: e.target.checked } ); setMultipleCorrectAnswers( { ...multipleCorrectAnswers, [index]: e.target.checked } ) }} />} labelPlacement="end" label={<Typography variant="subtitle2" color="textSecondary">Correct answer</Typography>} />}
                                                    </Box>
                                                    <Box display="flex" borderColor={palette.primary.main} bgcolor={theme === 'dark' ? "#232323" : "#fff"}>
                                                        {/* <EditableMathField
                                                            latex={latexOptions[index] ? latexOptions[index] : ""}
                                                            onChange={( mathField ) => {
                                                                setFieldValue( 'answers', setNewAnswers( index, mathField.latex(), mathField.latex().replace( /\\\\/g, "" ) ) )
                                                                setLatexOptions( { ...latexOptions, [index]: mathField.latex() } )
                                                            }}
                                                            placeholder='Option goes here...'
                                                        /> */}
                                                        <CustomWysiwyg
                                                            key={index}
                                                            placeholder="Option goes here..."
                                                            height='45px'
                                                            value={latexOptions[index] || ""}
                                                            onChange={( val, textVal ) => {
                                                                setFieldValue( 'answers', setNewAnswers( index, val, textVal ) )
                                                                setLatexOptions( { ...latexOptions, [index]: val } )
                                                            }}
                                                        />
                                                        {latexOptions[index]?.match( /\$/ ) && <Box marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} borderRadius="10px">
                                                            <Latex delimiters={latexDelimiters} >{latexOptions[index]}</Latex>
                                                        </Box>}
                                                    </Box>
                                                </FormControl> ) )}
                                            <Button color="primaryDark" sx={{ width: "fit-content" }} onClick={addAnswerField} startIcon={<Add fontSize='small' />} >
                                                Add new option
                                            </Button>
                                            <Typography sx={{ color: "errorMessage.main" }}><ErrorMessage name='answers' /></Typography>
                                        </RadioGroup>
                                        <Box display="flex" flexWrap="wrap" marginTop="40px" gap="20px">
                                            {/* {values.questionType === 'MCQ' && <Button variant='contained' onClick={addAnswerField} sx={{ textTransform: "capitalize", marginTop: "10px" }} color='warning' >Add another option</Button>} */}
                                            <Button variant='contained' disableElevation startIcon={isAdding ? <CircularProgress size={14} /> : undefined} disabled={( !exam || isAdding )} type="submit" sx={{ marginTop: "10px", textTransform: "capitalize" }}>{isAdding ? "Adding question..." : "Add question"}</Button>
                                            <Button variant='contained' color="error" disableElevation disabled={( !exam || isAdding )} onClick={() => navigate( `/faculty/exams/${id}` )} sx={{ marginTop: "10px", textTransform: "capitalize" }}>Cancel</Button>
                                        </Box>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Box>
                </>}
                {inProgress &&
                    <DataLoadingSpinner waitingMessage="Loading please wait..." />
                }
            </Paper>

        </Box >
    )
}

