import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { Icons } from '../../utils/utilities'

const AddQuizToCourseDialog = ( { setState, state } ) => {
    return (
        <Dialog open={state}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3", fontSize: "16px" }}>Add quiz</DialogTitle>
            <DialogContent>
                {/* <TextField sx={{ marginTop: "20px" }} fullWidth placeholder='Enter the module name here...' onChange={( e ) => setAttachmentEditValue( e.target.value )} value={attachmentEditValue} /> */}
                <Box marginTop="20px" display="flex" gap="10px">
                    <Button startIcon={Icons.default.EditIcon} variant='contained' disableElevation onClick={() => { }} sx={{ textTransform: "capitalize" }}>Update</Button>
                    <Button startIcon={Icons.default.CloseIcon} variant='outlined' color='error' onClick={() => setState( false )} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AddQuizToCourseDialog
