import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, IconButton, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Icons, convertToCDNLink, defaultOnlineCourseThumbs, flatColors } from '../../../utils/utilities'
import ModuleBox from '../../../components/online-course-components/ModuleBox'
import AddAttachmentsDialog from '../../../components/online-course-components/AddAttachmentsDialog'
import { useUserStore } from '../../../store/user.store'
import { useEffect } from 'react'
import { useOnlineCourseStore } from '../../../store/onlinecourse.store'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AddModuleDialog from '../../../components/online-course-components/AddModuleDialog'
import { observer } from 'mobx-react'
import VideoPlayer from '../../../components/online-course-components/VideoPlayer'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { convert } from 'html-to-text'
import { CloudUpload } from '@mui/icons-material'
import EditCourseDialog from '../../../components/online-course-components/EditCourseDialog'
import DocumentInfoArea from '../../../components/online-course-components/DocumentInfoArea'
import ConfirmDialog from '../../../components/common/ConfirmDialog'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import BlockMessage from '../../../components/common/BlockMessage'

const OnlineCourse = observer( () => {

    const [selectedModule, setSelectedModule] = useState( null )
    const [hasOrderChanged, setHasOrderChanged] = useState( false )
    const [addModuleState, setAddModuleState] = useState( false )
    const [selectedAttachmentLink, setSelectedAttachmentLink] = useState( null )
    const [selectedAttachment, setSelectedAttachment] = useState( null )
    const [mode, setMode] = useState( "view" )
    const [isLoading, setIsLoading] = useState( true )
    const [isInEditMode, setIsInEditMode] = useState( false )
    const [nextAndPrevAttachmentIndex, setNextAndPrevAttachmentIndex] = useState( { next: null, prev: null } )
    const [notFound, setNotFound] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )

    // DELETE DIALOG
    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )

    const { course_id } = useParams()

    const UserStore = useUserStore()
    const OnlineCourseStore = useOnlineCourseStore()
    const navigate = useNavigate()

    const handleModuleDrag = ( e, module ) => {
        e.dataTransfer.setData( "text/plain", module )
    }

    const findNextAndPrevIndex = ( list, item_id ) => {
        const nextAndPrevIndex = {}
        for ( let i = 0; i < list.length; i++ ) {
            const item = list[i]
            nextAndPrevIndex.next = null
            nextAndPrevIndex.prev = null
            if ( item.attachment_id === item_id ) {
                if ( i !== list.length - 1 )
                    nextAndPrevIndex.next = i + 1
                if ( i !== 0 )
                    nextAndPrevIndex.prev = i - 1
                break
            }
        }
        return nextAndPrevIndex
    }

    const saveNewModulesOrder = async () => {
        try {
            const res = await api.reorderCourseModules( OnlineCourseStore.getCourse["modules"] )
            toast( res.data.message )
            setHasOrderChanged( false )
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    // const handleDownloadsDialogClose = () => {
    //     if ( uploadsDialogStatus ) {
    //         let newStatuses = [...attachmentsStatus]
    //         let newAttachments = [...attachments]
    //         for ( let i = attachmentsStatus.length - 1; i >= 0; i-- ) {
    //             const status = attachmentsStatus[i]
    //             if ( status.statusText === "completed" ) {
    //                 newStatuses.splice( i, 1 )
    //                 newAttachments.splice( i, 1 )
    //             }
    //         }
    //         setAttachments( newAttachments )
    //         setAttachmentsStatus( newStatuses )
    //     }
    //     setUploadsDialogStatus( !uploadsDialogStatus )
    // }

    const handleModuleDrop = ( e, module ) => {
        const dropModuleIndex = module
        const draggingModuleIndex = e.dataTransfer.getData( "text" )
        if ( +draggingModuleIndex !== dropModuleIndex ) {
            let newOrder = [...OnlineCourseStore.getCourse["modules"]]
            const draggingModule = newOrder.splice( draggingModuleIndex, 1 )
            newOrder.splice( dropModuleIndex, 0, ...draggingModule )
            OnlineCourseStore.setNewModuleOrder( newOrder )
            setHasOrderChanged( true )
        }
    }

    const publishCourse = async () => {
        await OnlineCourseStore.publish( course_id )
    }

    const deleteCourse = async () => {
        try {
            await api.deleteCourse( course_id )
            toast( <span>Deleted <i style={{ color: "orange" }}>{OnlineCourseStore.getCourse['course'].title}</i> successfully</span> )
            navigate( '/admin/online-course' )
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data.message : "Something went wrong!" )
        }
    }

    const viewAttachment = async ( att ) => {
        try {
            const { data: { data } } = await api.getCourseAttachment( att.attachment_id )
            setSelectedAttachment( att )
            setNextAndPrevAttachmentIndex( findNextAndPrevIndex( OnlineCourseStore.getCourse['attachments'], att.attachment_id ) )
            if ( !['docx', 'pptx', 'txt'].includes( att.attachment_link.split( "." ).pop().toLowerCase() ) ) {
                setSelectedAttachmentLink( convertToCDNLink( data ) )
                setMode( "video" )
            }
            else {
                setSelectedAttachmentLink( convertToCDNLink( data ) )
                setMode( "doc" )
            }
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    const handleNext = () => {
        setMode( null )
        viewAttachment( OnlineCourseStore.getCourse['attachments'][nextAndPrevAttachmentIndex.next] )
    }

    const handlePrev = () => {
        setMode( null )
        viewAttachment( OnlineCourseStore.getCourse['attachments'][nextAndPrevAttachmentIndex.prev] )
    }

    useEffect( () => {
        const getData = async () => {
            await OnlineCourseStore.fetchCourse( course_id )
            if ( !OnlineCourseStore.getCourse['course'] ) {
                setNotFound( true )
            }
            setIsLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.online_course ) {
            setHasPermission( false )
        } else
            getData()
    }, [OnlineCourseStore, course_id, UserStore] )

    return (
        hasPermission ? <Box padding="20px" flexGrow={1} display="flex" flexDirection="column" >
            <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link className="breadcrumb-link" to="/admin/online-course">Online Courses</Link>{Icons.small.ChevronRightIcon} {isLoading ? <DataLoadingSpinner padding='0' size={14} /> : OnlineCourseStore.getCourse['course']?.title} </Typography>
            <Dialog open={notFound}>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant='h3'>404</Typography>
                        <Typography marginTop="10px">Online course you are looking for doen't exists!</Typography>
                        <Button sx={{ textTransform: "capitalize", marginTop: "20px" }} variant='outlined' onClick={() => navigate( UserStore.getUser['user_role'] === "STUDENT" ? "/student/online-course" : "/admin/online-course" )} color='error'>Back to online courses</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <ConfirmDialog actionName="delete" confirmAction={deleteCourse} message={`Do you really want to delete this online course`} status={deleteConfirmDialogState} cancelAction={() => setDeleteConfirmDialogState( false )} />
            <AddAttachmentsDialog state={selectedModule} setState={setSelectedModule} module={selectedModule} />
            <AddModuleDialog course_id={course_id} state={addModuleState} setAddModuleState={setAddModuleState} />

            {isLoading && <Paper>
                <DataLoadingSpinner waitingMessage="Loading course..." />
            </Paper>}
            {OnlineCourseStore.getCourse['course'] && OnlineCourseStore.getCourse['course'].title && !isLoading && <Paper>
                <EditCourseDialog course={OnlineCourseStore.getCourse['course']} state={isInEditMode} setState={setIsInEditMode} />
                {mode === "view" && <Box sx={{ position: "relative" }} borderRadius="5px 5px 0 0">
                    <img style={{ objectFit: "cover", borderRadius: "5px 5px 0 0" }} width="100%" height="200px" src={OnlineCourseStore.getCourse['course'].thumbnail_uri.includes( 'http' ) ? convertToCDNLink( OnlineCourseStore.getCourse['course'].thumbnail_uri ) : defaultOnlineCourseThumbs[parseInt( OnlineCourseStore.getCourse['course'].thumbnail_uri )]} alt="" />
                    <Box padding="30px" display="flex" gap="20px" alignItems="flex-end" sx={{ position: "absolute", bottom: "0", top: 0, right: "0", left: 0, background: "linear-gradient(to top,rgba(0,0,0,0.9), rgba(0,0,0,0.4))" }}>
                        <Box display="flex" alignItems="center" gap="20px">
                            <IconButton onClick={() => { }} sx={{ color: "black", fontSize: "20px", background: "white", "&:hover": { background: "white", color: "black", transform: "scale(1.1)" } }}>
                                {Icons.default.PlayArrowIcon}
                            </IconButton>
                            <Box>
                                <Typography fontSize="20px" fontWeight="600" sx={{ color: "white" }}>{OnlineCourseStore.getCourse['course'].title}</Typography>
                                <Typography variant="subtitle2" sx={{ color: "white" }} >{OnlineCourseStore.getCourse['course'].author}</Typography>
                                <Typography variant='body2' sx={{ color: "white" }} >{new Date( OnlineCourseStore.getCourse['course'].created_at ).toLocaleDateString( 'IN-en' ).replace( /\//g, "-" )}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>}
                {mode === "video" && <Box bgcolor="rgba(48,48,48,0.8)" position="relative" height="450px" display="flex">
                    <VideoPlayer containerHeight="450px" nextAndPrevAttachmentIndex={nextAndPrevAttachmentIndex} onPrevButtonClick={handlePrev} onNextButtonClick={handleNext} link={selectedAttachmentLink} />
                </Box>}
                {mode === "doc" && <Box justifyContent="center" bgcolor="#bcf5f1" flexDirection="column" gap="20px" color="black" padding="30px" position="relative" height="450px" display="flex">
                    <DocumentInfoArea link={selectedAttachmentLink} selectedAttachment={selectedAttachment} nextAndPrevAttachmentIndex={nextAndPrevAttachmentIndex} onPrevButtonClick={handlePrev} onNextButtonClick={handleNext} />
                </Box>}
                {mode === null && <Box justifyContent="center" bgcolor="rgba(48,48,48,0.9)" flexDirection="column" gap="20px" color="white" padding="30px" position="relative" height="450px" display="flex">
                    <CircularProgress sx={{ color: "white", borderColor: "white" }} />
                </Box>}
                <Box padding="20px" display="flex" alignItems="center" flexWrap="wrap" gap="10px" paddingBottom="0">
                    <Button startIcon={Icons.default.EditIcon} onClick={() => setIsInEditMode( true )} variant='contained' color='primary' disableElevation sx={{ textTransform: "capitalize" }}>Edit</Button>
                    {OnlineCourseStore.getCourse['course'].status !== "PUBLISHED" && <Button startIcon={<CloudUpload />} variant='contained' color='secondary' onClick={publishCourse} disableElevation sx={{ textTransform: "capitalize" }}>Publish Course</Button>}
                    <Button startIcon={Icons.default.DeleteIcon} onClick={() => setDeleteConfirmDialogState( true )} variant='contained' color='error' disableElevation sx={{ textTransform: "capitalize" }}>Delete</Button>
                </Box>
                <Box padding="20px">
                    {/* <Typography marginTop="20px" variant='h6'>What is in this course</Typography> */}
                    <Box>{convert( OnlineCourseStore.getCourse['course'].description )}</Box>
                    <Box marginTop="20px" display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                        {OnlineCourseStore.getCourse['course'].key_word.split( "," ).map( ( keyWord, i ) => (
                            <Chip size='large' sx={{ color: "white", background: flatColors[i % flatColors.length] }} key={keyWord} label={keyWord} />
                        ) )}
                    </Box>
                </Box>
                <Box padding="20px">
                    <Box marginBottom="10px" display="flex" justifyContent="space-between">
                        <Typography variant='h6'>Course Content</Typography>
                        <Box>
                            {hasOrderChanged && <Button onClick={saveNewModulesOrder} variant='outlined' sx={{ textTransform: "capitalize", marginRight: "5px", fontSize: "12px !important" }}>Save changes</Button>}
                            {UserStore.getUser["user_role"] === "admin" && <Button disableElevation variant='contained' onClick={() => setAddModuleState( true )} sx={{ textTransform: "capitalize", fontSize: "12px !important" }}>Add new module</Button>}
                        </Box>
                    </Box>
                    <Box display="flex" gap="2px" flexDirection="column">
                        {OnlineCourseStore.getCourse["modules"] && OnlineCourseStore.getCourse["modules"].length > 0 && OnlineCourseStore.getCourse["modules"].map( ( module, k ) => (
                            <ModuleBox viewAttachment={viewAttachment} setSelectedAttachment={setSelectedAttachment} selectedAttachment={selectedAttachment} setSelectedAttachmentLink={setSelectedAttachmentLink} setMode={setMode} attachments={OnlineCourseStore.getCourse["attachments"].filter( a => a.online_course_module_id === module.id )} index={k} handleModuleDrag={handleModuleDrag} handleModuleDrop={handleModuleDrop} key={module.id} module={module} setSelectedModule={setSelectedModule} />
                        ) )}
                        {OnlineCourseStore.getCourse["modules"].length === 0 && <Typography variant='subtitle2' color="textSecondary" padding="20px" borderRadius="5px" bgcolor="#eee">
                            No modules created for this course
                        </Typography>}
                    </Box>
                </Box>
            </Paper>}
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
} )
export default OnlineCourse
