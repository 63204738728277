import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useOnlineCourseStore } from '../../store/onlinecourse.store'
import { SkipNext, SkipPrevious } from '@mui/icons-material'
import { attachmentThumbnails } from '../../utils/utilities'

const DocumentInfoArea = ( { selectedAttachment, nextAndPrevAttachmentIndex, onPrevButtonClick, onNextButtonClick, link, updateProgressDetail, progressDetails } ) => {

    const [showPrevsState, setShowPrevsState] = useState( false )
    const [showNextsState, setShowNextsState] = useState( false )

    const OnlineCourseStore = useOnlineCourseStore()

    // const openDocument = () => {
    //     const anchor = document.createElement( 'a' )
    //     try {
    //         anchor.setAttribute( 'target', '_blank' )
    //         // https://view.officeapps.live.com/op/view.aspx?src=FILE_URL
    //         // https://drive.google.com/viewer?url=
    //         anchor.setAttribute( 'href', `https://drive.google.com/viewer?url='${encodeURI( link )}'` )
    //         anchor.click()
    //         if ( progressDetails )
    //             updateProgressDetail( progressDetails.id, { status: "INPROGRESS" } )
    //     } catch ( err ) { console.log( err ) }
    // }

    const downloadDocument = () => {
        const anchor = document.createElement( 'a' )
        try {
            anchor.setAttribute( 'download', selectedAttachment.attachment_name )
            anchor.setAttribute( 'href', link )
            anchor.click()
            console.log( link )
            if ( progressDetails )
                updateProgressDetail( progressDetails.id, { status: "INPROGRESS" } )
        } catch ( err ) { console.log( err ) }
    }

    return (
        <Box>
            <img width="100px" src={attachmentThumbnails[selectedAttachment.attachment_name.split( "." ).pop()] ? attachmentThumbnails[selectedAttachment.attachment_name.split( "." ).pop()] : attachmentThumbnails['default']} alt="file-type" />
            <Typography variant='h4' textTransform="capitalize" gutterBottom>
                {selectedAttachment.attachment_name.split( "." ).slice( 0, -1 ).join()}
            </Typography>
            <Box display="flex" flexWrap="wrap" alignItems="center" gap="10px">
                {/* <Button onClick={openDocument} variant='contained' sx={{ textTransform: "capitalize", background: "white", color: "black", "&:hover": { background: "white", color: "black" } }}>Open this document</Button> */}
                <Button onClick={downloadDocument} variant='contained' sx={{ textTransform: "capitalize", background: "white", color: "black", "&:hover": { background: "white", color: "black" } }}>Download this document</Button>
            </Box>

            <Box position="absolute" bottom="0" left="0" right="0" display="flex" justifyContent="space-between" padding="20px">
                <Box position="relative" onMouseEnter={() => setShowPrevsState( true )} onMouseLeave={() => setShowPrevsState( false )} >
                    <Box position="absolute" width="150px" maxWidth="250px" bottom="100%" display={showPrevsState ? "block" : "none"}>
                        {nextAndPrevAttachmentIndex.prev !== null && <Paper elevation={4} sx={{ padding: "10px" }}>
                            <b style={{ color: "black", fontSize: "12px" }}>Prev</b>
                            <Typography fontSize="12px" sx={{ color: "black" }} variant='subtitle2'> {OnlineCourseStore.getCourse['attachments'][nextAndPrevAttachmentIndex.prev].attachment_name.split( "." ).slice( 0, -1 ).join( "" )} </Typography>
                        </Paper>}
                    </Box>
                    <button disabled={nextAndPrevAttachmentIndex.prev === null} onClick={onPrevButtonClick} className='control-btn'>
                        <SkipPrevious sx={{ fontSize: "30px", color: "black" }} />
                    </button>
                </Box>
                <Box position="relative" onMouseEnter={() => setShowNextsState( true )} onMouseLeave={() => setShowNextsState( false )} >
                    <Box position="absolute" width="150px" maxWidth="250px" right="100%" bottom="100%" display={showNextsState ? "block" : "none"}>
                        {nextAndPrevAttachmentIndex.next && <Paper elevation={4} sx={{ padding: "10px" }}>
                            <b style={{ color: "black", fontSize: "12px" }}>Next</b>
                            <Typography fontSize="12px" sx={{ color: "black" }} variant='subtitle2'> {OnlineCourseStore.getCourse['attachments'][nextAndPrevAttachmentIndex.next].attachment_name.split( "." ).slice( 0, -1 ).join( "" )} </Typography>
                        </Paper>}
                    </Box>
                    <button disabled={!nextAndPrevAttachmentIndex.next} onClick={onNextButtonClick} className='control-btn'>
                        <SkipNext sx={{ fontSize: "30px", color: "black" }} />
                    </button>
                </Box>
            </Box>
        </Box>
    )
}

export default DocumentInfoArea
