import { Box, Button, FormControl, FormHelperText, Icon, IconButton, Paper, TextField, Typography } from '@mui/material'
import { Icons, defaultOnlineCourseThumbs } from '../../../utils/utilities'
import { useOnlineCourseStore } from '../../../store/onlinecourse.store'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useUserStore } from '../../../store/user.store'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import BlockMessage from '../../../components/common/BlockMessage'
import CustomWysiwyg from '../../../components/common/CustomWysiwyg'

const AddOnlineCourse = () => {

    const [thumbnail, setThumbnail] = useState( null )
    const [thumbnailSrc, setThumbnailSrc] = useState( null )
    const [defaultThumb, setDefaultThumb] = useState( [0, 1, 2].at( Math.floor( Math.random() * 3 ) ) )
    const [isAddingCourse, setIsAddingCourse] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )

    const OnlineCourseStore = useOnlineCourseStore()
    const UserStore = useUserStore()
    const navigate = useNavigate()

    const initialValues = {
        title: '',
        subject_code: "",
        description: "",
        key_word: '',
        author: '',
    }

    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( "Title is required!" ),
        subject_code: Yup.string().required( "Subject code is required!" ),
        author: Yup.string().required( "Author is required!" ),
        key_word: Yup.string().required( "Key words covered should be listed!" ),
        description: Yup.string().notOneOf( ["<p><br/></p>"] ).required( "Description is required!" )
    } )

    const handelThumbnailChange = ( e ) => {
        const file = e.target.files[0]
        setThumbnail( file )
        const fileReader = new FileReader()
        fileReader.onload = () => {
            setThumbnailSrc( fileReader.result )
        }
        fileReader.readAsDataURL( file )
    }

    const createOnlineCourse = async ( values, { resetForm } ) => {
        const formData = new FormData()
        formData.append( 'title', values.title )
        formData.append( 'description', values.description )
        formData.append( 'key_word', values.key_word )
        formData.append( 'author', values.author )
        formData.append( 'subject_code', values.subject_code )
        if ( thumbnail )
            formData.append( 'thumbnail', thumbnail, `${values.title}-thumb-${Date.now()}.${thumbnail.name.split( "." ).pop()}` )
        else
            formData.append( 'defaultThumb', defaultThumb )
        setIsAddingCourse( true )
        if ( await OnlineCourseStore.addNewCourse( formData ) ) {
            resetForm()
            navigate( '/admin/online-course' )
        } else {
            setDefaultThumb( [0, 1, 2].at( Math.floor( Math.random() * 3 ) ) )
        }
        setIsAddingCourse( false )
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.online_course ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        hasPermission ? <Box padding="20px">
            <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link className="breadcrumb-link" to="/admin/online-course">Online Courses</Link>{Icons.small.ChevronRightIcon} Add new online course </Typography>
            <Paper>
                <Box padding="20px" borderBottom="1px solid #d3d3d3">
                    <Typography variant='h5'>Create new Video Tutorial</Typography>
                    <Typography variant='subtitle2' color="textSecondary">Add new online course here</Typography>
                </Box>
                <Box padding="20px">
                    <Formik onSubmit={createOnlineCourse} initialValues={initialValues} validationSchema={validationSchema}>
                        {( { values, setFieldValue } ) => (
                            <Form>
                                <FormControl margin='normal' fullWidth>
                                    <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} type="text" name="title" label="Online course title" />
                                    <FormHelperText sx={{ marginLeft: 0, color: 'error.main', fontWeight: "bold" }}> <ErrorMessage name='title' /> </FormHelperText>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    {/* <Typography fontSize="14px" color="textSecondary">Description</Typography> */}
                                    <CustomWysiwyg value={values.description} placeholder='Description goes here...' onChange={val => setFieldValue( 'description', val )} />
                                    <FormHelperText sx={{ marginLeft: 0, color: 'error.main', fontWeight: "bold" }}> <ErrorMessage name='description' /> </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin='normal'>
                                    <Box border="1px solid #cbcbcb" sx={{ "&:hover": { borderColor: "#000" } }} borderRadius="5px" padding="10px" position="relative">
                                        <input id="thumbnail-input" onChange={handelThumbnailChange} accept='image/jpg,image/jpeg' type="file" name="thumbnail" className="thumbnail-input" />
                                        <Typography display="flex" gap="10px" variant="subtitle2" alignItems="center"><Icon sx={{ marginBottom: "5px" }}>{Icons.default.UploadFileIcon}</Icon> Upload thumbnail</Typography>
                                        <Typography fontSize="14px" color="textSecondary">Max. size 10Mb, supported formats .JPG and .JPEG</Typography>
                                    </Box>
                                    {thumbnailSrc && <Box justifyContent="space-between" bgcolor="#eee" borderRadius="5px" marginTop="10px" display="flex" gap="10px">
                                        <Box bgcolor="#eee" borderRadius="5px" padding="5px" display="flex" gap="10px">
                                            <img height="40px" src={thumbnailSrc} alt="thumbnail" />
                                            <Box>
                                                <Typography variant='subtitle2' fontSize="14px">{thumbnail.name}</Typography>
                                                <Typography fontSize="12px">{thumbnail.size} Bytes</Typography>
                                            </Box>
                                        </Box>
                                        <IconButton disableRipple disableTouchRipple size='small' onClick={() => { setThumbnail( null ); setThumbnailSrc( null ); document.getElementById( 'thumbnail-input' ).value = '' }}> {Icons.default.CloseIcon} </IconButton>
                                    </Box>}
                                    {!thumbnailSrc && <Box marginTop="10px" borderRadius="5px" padding="10px" bgcolor="#eee">
                                        <Typography gutterBottom>Or select one of these</Typography>
                                        <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap">
                                            {defaultOnlineCourseThumbs.map( ( im, i ) => (
                                                <img key={i} onClick={() => setDefaultThumb( i )} className={`default-thumb-preview${defaultThumb === i ? " selected-preview-img" : ""}`} src={defaultOnlineCourseThumbs[i]} width="100px" height="70px" alt={`thumb${i + 1}`} />
                                            ) )}
                                        </Box>
                                    </Box>}
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} type="text" name="author" label="Course author" />
                                    <FormHelperText sx={{ marginLeft: 0, color: 'error.main', fontWeight: "bold" }}> <ErrorMessage name='author' /> </FormHelperText>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} type="text" name="subject_code" label="Subject code" />
                                    <FormHelperText sx={{ marginLeft: 0, color: 'error.main', fontWeight: "bold" }}> <ErrorMessage name='subject_code' /> </FormHelperText>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} type="text" name="key_word" label="Keywords" />
                                    <FormHelperText sx={{ marginLeft: 0, fontWeight: "bold" }}>Enter keywords as comma separated values.</FormHelperText>
                                    <FormHelperText sx={{ marginLeft: 0, color: 'error.main', fontWeight: "bold" }}> <ErrorMessage name='key_word' /> </FormHelperText>
                                </FormControl>
                                <Box display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                                    <Button disabled={isAddingCourse} type="submit" variant="contained" disableElevation sx={{ height: "53px", textTransform: "capitalize" }}>{isAddingCourse ? "Creating new course..." : "Create online course"}</Button>
                                    <Button color='error' onClick={() => navigate( '/admin/online-course' )} disabled={isAddingCourse} variant="outlined" sx={{ height: "53px", textTransform: "capitalize" }}>Cancel</Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Paper>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
}

export default AddOnlineCourse
