import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import networkErrorImg from '../assets/networkerror.svg'
import { useNavigate } from 'react-router-dom'

const NetworkError = () => {
    const navigate = useNavigate()

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" width="100%">
            <Paper sx={{ padding: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img width="200px" src={networkErrorImg} alt="NetworkError" />
                <Typography marginTop="20px" variant='h5' color="textSecondary">{window.navigator.onLine ? "Server network down!" : "Please connect to your internet"}</Typography>
                <Button sx={{ textTransform: "capitalize", marginTop: "20px" }} onClick={() => navigate( -1 )} variant="contained" disableElevation>Retry</Button>
            </Paper>
        </Box>
    )
}

export default NetworkError
