import { Box, Button, Chip, CircularProgress, Paper, Typography } from '@mui/material'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useOnlineCourseStore } from '../../../store/onlinecourse.store'
import { useEffect } from 'react'
import { useState } from 'react'
import { Icons, convertToCDNLink, defaultOnlineCourseThumbs, flatColors } from '../../../utils/utilities'
import ModuleBox from '../../../components/online-course-components/ModuleBox'
import { observer } from 'mobx-react'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'

const StudentOnlineCourse = observer( () => {

    const [isLoading, setIsLoading] = useState( true )
    const [selectedModule, setSelectedModule] = useState( null )
    const [isEnrolling, setIsEnrolling] = useState( false )

    const OnlineCourseStore = useOnlineCourseStore()
    const navigate = useNavigate()
    const { course_id } = useParams()

    const enroll = async () => {
        try {
            setIsEnrolling( true )
            const { data } = await api.enroll( course_id, {} )
            await OnlineCourseStore.fetchCourse( course_id )
            await OnlineCourseStore.fetchCourseEnrollmentDetails( course_id )
            toast( data.message )
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        } finally {
            setIsEnrolling( false )
        }
    }

    const startLearning = async () => {
        let role = 'student'
        try {
            if ( OnlineCourseStore.getCourseEnrollmentData.status === "ENROLLED" ) {
                await api.startCourse( OnlineCourseStore.getCourseEnrollmentData.id )
                navigate( `/${role}/online-course/${course_id}/learn/${OnlineCourseStore.getCourseEnrollmentData.id}` )
            } else {
                navigate( `/${role}/online-course/${course_id}/learn/${OnlineCourseStore.getCourseEnrollmentData.id}` )
            }
        } catch ( err ) {
            console.log( err )
            toast( "Something went wrong! try again later" )
        }
    }

    useEffect( () => {
        const getData = async () => {
            await OnlineCourseStore.fetchCourse( course_id )
            await OnlineCourseStore.fetchCourseEnrollmentDetails( course_id )
        }
        getData()
        setIsLoading( false )
    }, [OnlineCourseStore, course_id] )

    return (
        <Box flexGrow={1} overflow="auto" minWidth="0" padding="20px">
            {!isLoading && <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link className="breadcrumb-link" to="/student/online-course">Online Courses</Link>{Icons.small.ChevronRightIcon} {OnlineCourseStore.getCourse['course'] && OnlineCourseStore.getCourse['course'].title}</Typography>}
            {isLoading && <DataLoadingSpinner waitingMessage="Loading course..." />}
            {OnlineCourseStore.getCourse['course'] && OnlineCourseStore.getCourse['course'].title && !isLoading && <Paper>
                <Box sx={{ position: "relative" }} borderRadius="5px 5px 0 0">
                    <img style={{ objectFit: "cover", borderRadius: "5px 5px 0 0" }} width="100%" height="200px" src={OnlineCourseStore.getCourse['course'].thumbnail_uri.includes( 'http' ) ? convertToCDNLink( OnlineCourseStore.getCourse['course'].thumbnail_uri ) : defaultOnlineCourseThumbs[parseInt( OnlineCourseStore.getCourse['course'].thumbnail_uri )]} alt="" />
                    <Box padding="30px" display="flex" gap="20px" alignItems="flex-end" sx={{ position: "absolute", bottom: "0", top: 0, right: "0", left: 0, background: "linear-gradient(to top,rgba(0,0,0,0.9), rgba(0,0,0,0.4))" }}>
                        <Box display="flex" flexGrow={1} alignItems="flex-end" gap="20px">
                            <Box flexGrow={1}>
                                <Typography fontSize="20px" fontWeight="600" sx={{ color: "white" }}>{OnlineCourseStore.getCourse['course'].title}</Typography>
                                <Typography variant="subtitle2" sx={{ color: "white" }} >{OnlineCourseStore.getCourse['course'].author}</Typography>
                                <Typography variant='body2' sx={{ color: "white" }} >Last updated: {new Date( OnlineCourseStore.getCourse['course'].updated_at ).toLocaleDateString( 'IN-en' ).replace( /\//g, "-" )}</Typography>
                            </Box>
                            {OnlineCourseStore.getEnrollmentStatus !== null && OnlineCourseStore.getEnrollmentStatus === false && <Button disabled={isEnrolling} endIcon={Icons.default.ChevronRightIcon} onClick={enroll} sx={{ background: isEnrolling ? '#fffa' : "white", color: "black", "&:hover": { background: isEnrolling ? '#fffa' : "white", color: isEnrolling ? "lightgrey" : "secondary.main" } }}>{isEnrolling ? <Typography textTransform="capitalize" color="textSecondary" fontSize="14px" display="flex" alignItems="center" gap="10px"> <CircularProgress size={14} /> Enrolling to course </Typography> : "Enroll for the course"}</Button>}
                            {OnlineCourseStore.getEnrollmentStatus !== null && OnlineCourseStore.getEnrollmentStatus === true && <Button endIcon={Icons.default.ChevronRightIcon} onClick={startLearning} sx={{ background: "white", color: "black", "&:hover": { background: "white", color: "secondary.main" } }}>Start learning</Button>}
                        </Box>
                    </Box>
                </Box>
                <Box padding="20px">
                    <Typography marginTop="20px" variant='h6'>What is in this course</Typography>
                    <Typography fontSize="14px">{OnlineCourseStore.getCourse['course'].description}</Typography>
                    <Box marginTop="20px" display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                        {OnlineCourseStore.getCourse['course'].key_word.split( "," ).map( ( keyWord, i ) => (
                            <Chip size='large' sx={{ color: "white", background: flatColors[i % flatColors.length] }} key={keyWord} label={keyWord} />
                        ) )}
                    </Box>
                </Box>
                <Box padding="10px 20px 20px 20px">
                    <Typography gutterBottom variant='h6'>Course Content</Typography>
                    <Box display="flex" gap="2px" flexDirection="column">
                        {OnlineCourseStore.getCourse["modules"] && OnlineCourseStore.getCourse["modules"].length > 0 && OnlineCourseStore.getCourse["modules"].map( ( module, k ) => (
                            <ModuleBox selectedModule={selectedModule} setSelectedAttachment={() => { }} selectedAttachment={null} setSelectedAttachmentLink={() => { }} setMode={() => { }} attachments={OnlineCourseStore.getCourse["attachments"].filter( a => a.online_course_module_id === module.id )} index={k} handleModuleDrag={() => { }} handleModuleDrop={() => { }} key={module.id} module={module} setSelectedModule={setSelectedModule} />
                        ) )}
                        {OnlineCourseStore.getCourse["modules"].length === 0 && <Typography variant='subtitle2' color="textSecondary" padding="20px" borderRadius="5px" bgcolor="#eee">
                            No modules created for this course
                        </Typography>}
                    </Box>
                </Box>
            </Paper>}
        </Box>
    )
} )

export default StudentOnlineCourse
