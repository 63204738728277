import { Box, Button, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import TableHeader from '../../common/TableHeader'
import { useParams } from 'react-router-dom'
import api from '../../../service/api'
import { showDateInIndianDateFormat } from '../../../utils/utilities'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import errorImg from '../../../assets/request-error.svg'
import noAssignmentImg from '../../../assets/noassignment.svg'
import AssignmentViewer from '../../common/AssignmentViewer'
import SubmissionsViewer from '../assignments/SubmissionsViewer'
import { Analytics, OpenInNew } from '@mui/icons-material'

const columns = [
    { name: "Sl. no.", padding: "10px 20px 10p 40px", align: "center" },
    { name: "Assignment Title" },
    { name: "Created on" },
    { name: "Subject" },
    { name: "Submissions", align: "center" },
    { name: "Actions", align: "right", padding: "10px 40px 10px 20px", },
]

const AssignmentDetails = ( { userData } ) => {

    const [submissionViewerState, setSubmissionViewerState] = useState( false )
    const [selectedAssignment, setSelectedAssignment] = useState( null )
    const [assignmentDetails, setAssignmentDetails] = useState( [] )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const { palette, table } = useTheme()

    const { auid } = useParams()

    const loadAssingments = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data } } = await api.audit.getAssignmentsByFacultyId( auid )
            setAssignmentDetails( data )
            setError( false )
        } catch ( err ) {
            setError( true )
        } finally {
            setLoading( false )
        }
    }, [auid] )

    useEffect( () => {
        loadAssingments()
    }, [loadAssingments] )

    return (
        <Box borderRadius="10px" margin="10px 20px" bgcolor={palette.contentBg} maxHeight="350px" display="flex" flexDirection="column" overflow="hidden">

            <AssignmentViewer onClose={() => setSelectedAssignment( null )} loaded={true} selectedValue={selectedAssignment} open={!submissionViewerState && Boolean( selectedAssignment )} />
            {selectedAssignment && submissionViewerState && <SubmissionsViewer selectedValue={selectedAssignment} handleClose={() => { setSubmissionViewerState( false ); setSelectedAssignment( null ) }} assignmentId={selectedAssignment?.assignment_id} open={submissionViewerState} />}

            {!loading && !error && assignmentDetails.length > 0 && <TableContainer sx={{ overflow: "auto", }} className='custom-scrollbar'>
                <Table>
                    <TableHeader columns={columns} />
                    <TableBody>
                        {assignmentDetails?.map( ( item, index ) => (
                            <TableRow className="table-row" key={item.assignment_id}>
                                <TableCell align={columns[0].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[0].padding || "10px 20px" }} ><strong>{index + 1}</strong></TableCell>
                                <TableCell align={columns[1].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[1].padding || "10px 20px" }} ><strong>{item.assignment_name}</strong></TableCell>
                                <TableCell align={columns[2].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[2].padding || "10px 20px" }} ><strong>{showDateInIndianDateFormat( item.assignment_created_at )}</strong></TableCell>
                                <TableCell align={columns[3].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[3].padding || "10px 20px" }} ><strong>{item?.assignment_subject?.subject_name || "Not Available"}</strong></TableCell>
                                <TableCell align={columns[4].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[4].padding || "10px 20px" }} ><strong>{item.assignment_submission}</strong></TableCell>
                                <TableCell align={columns[5].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[5].padding || "10px 20px" }} >
                                    <Box display="flex" justifyContent="right">
                                        <Tooltip title="Open details">
                                            <IconButton onClick={() => setSelectedAssignment( item )} color="primary" size="small"><OpenInNew fontSize='small' /></IconButton>
                                        </Tooltip>
                                        {+item.assignment_submission > 0 && <Tooltip title="View submissions">
                                            <IconButton onClick={() => { setSelectedAssignment( item ); setSubmissionViewerState( true ) }} color="secondary" size="small"><Analytics fontSize='small' /></IconButton>
                                        </Tooltip>}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) )}
                    </TableBody>
                </Table>
            </TableContainer>}
            {loading && !error && <Box padding="50px 20px" display="flex" justifyContent="center">
                <DataLoadingSpinner waitingMessage="Please wait! Loading assignments..." />
            </Box>}
            {error && <Box padding="20px" display="flex" alignItems="center" gap="20px" flexDirection="column" justifyContent="center">
                <img src={errorImg} height="100px" alt="Something went wrong!" />
                <Typography variant="subtitle2">Something went wrong!</Typography>
                <Button disabled={loading} startIcon={loading && <CircularProgress size={14} />} onClick={loadAssingments} variant="contained" sx={{ textTransform: "capitalize" }} color="errorMessage" disableElevation>Reload assignments!</Button>
            </Box>}
            {!loading && !error && assignmentDetails.length === 0 && <Box padding="20px" gap="20px" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                <img src={noAssignmentImg} height="100px" alt="Something went wrong!" />
                <Typography variant="subtitle2"><em>{userData.employee_name}</em> has not given any assignments!</Typography>
            </Box>}
        </Box>
    )
}

export default AssignmentDetails
