import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Popover, Typography, useTheme } from '@mui/material'
import * as d3 from 'd3'
import CustomSwitch from './CustomSwitch'

const LineBarGraphPlot = ( {
    barColor,
    barData,
    allData,
    lineData,
    lineColor,
    yAxisName,
    xAxisName,
    showLabels,
    y2AxisName,
    width = 640,
    height = 400,
    barValuesKey,
    lineValuesKey,
    marginTop = 20,
    xAxisValuesKey,
    marginLeft = 40,
    marginRight = 40,
    showDetailsDialog,
    hideDetailsDialog,
    marginBottom = 40,
    placeDetailsDialog,
    dateformat = "%m-%d-%Y",
    graphComponentVisibility
} ) => {


    const utcTimeParser = d3.utcParse( dateformat )
    const svg = useRef()
    const gx = useRef()
    const gy = useRef()
    const gy2 = useRef()


    const bandWidth = ( width - 30 ) / 30 > 10 ? 10 : ( width - 30 ) / 30

    const x = d3.scaleUtc().domain( [d3.min( lineData, d => utcTimeParser( d[xAxisValuesKey] ) ), d3.max( lineData, d => utcTimeParser( d[xAxisValuesKey] ) )] ).range( [marginLeft, width - marginRight] )
    const y = d3.scaleLinear().domain( [0, d3.max( lineData.concat( [{ [lineValuesKey]: 4 }] ), d => +d[lineValuesKey] )] ).range( [height - marginBottom, marginTop] )
    const y2 = d3.scaleLinear().domain( [0, d3.max( barData.concat( [{ [barValuesKey]: 4 }] ), d => +d[barValuesKey] )] ).range( [height - marginBottom, marginTop] )

    useEffect( () => void d3.select( gx.current ).attr( 'class', 'graph-axis' ).call( d3.axisBottom( x ).ticks( 5 ) ), [gx, x] )
    useEffect( () => void d3.select( gy.current ).attr( 'class', 'graph-axis' ).call( d3.axisLeft( y ).ticks( 5 ) ), [gy, y] )
    useEffect( () => {
        if ( barData.length > 0 ) {
            d3.select( gy2.current ).attr( 'class', 'graph-axis' ).call( d3.axisRight( y2 ).ticks( 5 ) )
        }
    }, [gy2, y2, barData] )

    const line = d3.line().x( d => x( utcTimeParser( d[xAxisValuesKey] ) ) ).y( d => y( d[lineValuesKey] ) )( lineData )


    return <svg ref={svg} className='graph-svg-container'>
        {/* .attr( 'class', 'graph-axis-label' ).attr( 'text-anchor', 'middle' ).attr( "x", width / 2 ).attr( "y", height - 10 ).text( "X Axis Label" ) */}
        {showLabels && <text className='graph-axis-label' textAnchor='middle' x={width / 2} y={height - 10}>{xAxisName}</text>}
        {showLabels && <text className='graph-axis-label' textAnchor='middle' transform='rotate(-90)' x={-height / 2} y={15}>{yAxisName}</text>}
        {showLabels && <text className='graph-axis-label' textAnchor='middle' transform='rotate(90)' x={height / 2} y={-( width - 15 )}>{y2AxisName}</text>}
        <g ref={gx} transform={`translate(0,${height - marginBottom})`} />
        {graphComponentVisibility.line && <g ref={gy} transform={`translate(${marginLeft},0)`} />}
        {barData.length > 0 && graphComponentVisibility.bar && <g ref={gy2} transform={`translate(${width - marginRight},0)`} />}
        {graphComponentVisibility.bar && <g>
            {barData.map( d => {
                return (
                    <rect key={d[xAxisValuesKey]} width={bandWidth} fill={barColor} transform={`translate(-${bandWidth / 2},0)`} x={x( utcTimeParser( d[xAxisValuesKey] ) )} y={+d[barValuesKey] === 0 ? height - marginBottom : y2( +d[barValuesKey] )} height={+d[barValuesKey] === 0 ? 0 : height - marginBottom - y2( +d[barValuesKey] )} />
                )
            } )}
        </g>}
        {graphComponentVisibility.line && <path fill="none" stroke={lineColor} strokeLinecap='round' strokeWidth="5" d={line} />}

        <g>
            {Object.keys( allData ).map( date => {
                return (
                    <g key={date} className="graph-data-group" >
                        <line className='graph-data-point-vertical-line' stroke='lightgrey' strokeDasharray={4} strokeDashoffset={4} strokeWidth={2} x1={x( utcTimeParser( date ) )} y1={marginTop} x2={x( utcTimeParser( date ) )} y2={height - marginBottom} />
                        <line className='graph-data-point-vertical-line' stroke={lineColor + "aa"} strokeDasharray={4} strokeDashoffset={4} strokeWidth={2} x1={x( utcTimeParser( date ) )} y1={y( parseInt( allData[date][0] ) )} x2={x( utcTimeParser( lineData.at( 0 )[xAxisValuesKey] ) )} y2={y( parseInt( allData[date][0] ) )} />
                        {barData && barData?.length > 0 && <line className='graph-data-point-vertical-line' stroke={barColor + "aa"} strokeDasharray={4} strokeDashoffset={4} strokeWidth={2} x1={x( utcTimeParser( date ) )} y1={y2( parseInt( allData[date][1] ) )} x2={x( utcTimeParser( lineData.at( -1 )[xAxisValuesKey] ) )} y2={y2( parseInt( allData[date][1] ) )} />}
                        <line stroke="red" className='graph-data-point-vertical-line-ref' onMouseLeave={e => hideDetailsDialog( e, date )} onMouseMove={e => placeDetailsDialog( e, date )} onMouseOver={e => showDetailsDialog( e, date )} strokeWidth={bandWidth} x1={x( utcTimeParser( date ) )} y1={marginTop} x2={x( utcTimeParser( date ) )} y2={height - marginBottom} />
                    </g>
                )
            } )}
        </g>

        {graphComponentVisibility.line && <g fill="white" stroke="currentColor" style={{ zIndex: 10, position: "relative" }} strokeWidth="1.5">
            {lineData.map( d => (
                <circle stroke={lineColor} strokeWidth={1} key={d[xAxisValuesKey]} className='graph-data-point' cx={x( utcTimeParser( d[xAxisValuesKey] ) )} cy={y( d[lineValuesKey] )} r="2.5" />
            ) )}
        </g>}
    </svg>
}

const LineBarGraph = ( {
    lineColor = "#134fcf",
    barColor = "#f37509",
    lineData = [],
    barData = [],
    bgColor,
    dateformat,
    lineValuesKey,
    barValuesKey,
    xAxisValuesKey,
    barLegend,
    lineLegend,
    yAxisName,
    xAxisName,
    y2AxisName,
    graphTitle,
    hideLegend = false,
    showMenuBar = false,
    showLabels = false
} ) => {

    const [dimentions, setDimentions] = useState( null )
    const [selectedDate, setSelectedDate] = useState( null )
    const [graphComponentVisibility, setGraphComponentVisibility] = useState( { bar: true, line: true } )
    const [detailsDialogPosition, setDetailsDialogPosition] = useState( { left: 0, top: 0 } )

    const container = useRef()

    const { palette } = useTheme()

    let allData = {}
    lineData.forEach( d => {
        allData[d[xAxisValuesKey]] = [`${d[lineValuesKey]} ${lineLegend}.`]
    } )
    barData.forEach( d => {
        if ( barValuesKey ) {
            if ( allData[d[xAxisValuesKey]] )
                allData[d[xAxisValuesKey]].push( `${d[barValuesKey]} ${barLegend}.` )
            else
                allData[d[xAxisValuesKey]] = `${d[barValuesKey]} ${barLegend}.`
        }
    } )

    const showDetailsDialog = ( e, date ) => {
        setSelectedDate( date )
    }

    const hideDetailsDialog = ( e, date ) => {
        setSelectedDate( null )
    }

    const placeDetailsDialog = ( e, date ) => {
        setDetailsDialogPosition( { top: e.pageY, left: e.pageX } )
    }

    const handleResize = e => {
        const { width, height } = container.current.getBoundingClientRect()
        setDimentions( { height: height, width: width } )
    }

    const handleGraphComponentVisibilityChange = ( val, component ) => {
        const newState = { ...graphComponentVisibility }
        newState[component] = val
        setGraphComponentVisibility( newState )
    }

    useEffect( () => {
        const { width, height } = container.current.getBoundingClientRect()
        setDimentions( { height: height, width: width } )
        window.addEventListener( 'resize', handleResize )
        return () => {
            window.removeEventListener( 'resize', handleResize )
        }
    }, [] )

    return (
        <Box bgcolor={bgColor || palette.contentBg} borderRadius="10px" flexGrow={1} height="100%" flexDirection="column" display="flex">

            <Box padding="20px" display="flex" gap="10px" alignItems="center" justifyContent="space-between">
                <Typography variant='h6' fontSize="16px" color="primaryDark.main">{graphTitle}</Typography>
                {showMenuBar && <Box display="flex" gap="10px" alignItems="center">
                    <Box display="flex" gap="5px" alignItems="center">
                        <Box display="flex" gap="5px" alignItems="center">
                            <CustomSwitch onChange={e => handleGraphComponentVisibilityChange( e.target.checked, 'line' )} color="primaryDark" checked={graphComponentVisibility.line} />
                            <Typography variant='subtitle2'>Line</Typography>
                        </Box>
                        <Box display="flex" gap="5px" alignItems="center">
                            <CustomSwitch onChange={e => handleGraphComponentVisibilityChange( e.target.checked, 'bar' )} color="primaryDark" checked={graphComponentVisibility.bar} />
                            <Typography variant='subtitle2'>Bar</Typography>
                        </Box>
                    </Box>
                </Box>}
                <Box display="flex" gap="5px" alignItems="center">
                    <Typography fontSize={"12px"} color="GrayText">{xAxisName} </Typography>
                    <Typography fontWeight="bold" fontSize={"12px"} sx={{ color: lineColor }}> / {yAxisName} </Typography>
                    {y2AxisName && <Typography fontWeight="bold" sx={{ color: barColor }} fontSize={"12px"}>{`/ ${y2AxisName}`} </Typography>}
                </Box>
            </Box>

            <Box ref={container} flexGrow={1} paddingBottom="1px" display="flex" >
                {dimentions ? <LineBarGraphPlot graphComponentVisibility={graphComponentVisibility} showLabels={showLabels} yAxisName={yAxisName} xAxisName={xAxisName} y2AxisName={y2AxisName} placeDetailsDialog={placeDetailsDialog} hideDetailsDialog={hideDetailsDialog} showDetailsDialog={showDetailsDialog} allData={allData} dateformat={dateformat} lineValuesKey={lineValuesKey} barValuesKey={barValuesKey} xAxisValuesKey={xAxisValuesKey} barColor={barColor} lineColor={lineColor} barData={barData} lineData={lineData} width={dimentions.width} height={dimentions.height} /> : ""}
                <Popover transitionDuration={0} sx={{ pointerEvents: "none" }} transformOrigin={{ vertical: "bottom", horizontal: "center" }} anchorReference='anchorPosition' open={Boolean( selectedDate )} anchorPosition={detailsDialogPosition}>
                    <Box padding="10px">
                        <Typography variant='h6' fontSize="15px">On {selectedDate}</Typography>
                        {selectedDate && allData[selectedDate].map( ( msg, index ) => (
                            <Typography fontSize="12px" color="textSecondary" variant='subtitle2' key={index}>{msg}</Typography>
                        ) )}
                    </Box>
                </Popover>
            </Box>
            {!hideLegend && <Box justifyContent="center" display="flex" paddingTop="1px" marginBottom="20px" gap="20px" alignItems="center">
                <Typography fontWeight="bold" fontSize={"12px"} sx={{ '&::before': { content: '""', display: "inline-block", marginRight: "5px", background: lineColor, aspectRatio: 1, width: "10px", height: "10px", borderRadius: "50%" } }}> {yAxisName} </Typography>
                {y2AxisName && <Typography fontWeight="bold" sx={{ '&::before': { content: '""', display: "inline-block", marginRight: "5px", background: barColor, aspectRatio: 1, width: "10px", height: "10px", borderRadius: "50%" } }} fontSize={"12px"}>{y2AxisName} </Typography>}
            </Box>}
        </Box>
    )
}

LineBarGraph.propTypes = {
    lineData: PropTypes.array.isRequired,
    lineValuesKey: PropTypes.string.isRequired,
    barValuesKey: PropTypes.string,
    xAxisValuesKey: PropTypes.string.isRequired,
    lineLegend: PropTypes.string.isRequired,
    barLegend: PropTypes.string,
}

export default LineBarGraph


// Plotting calculations
// const y = d3.scaleLinear().domain( [1, d3.max( data, d => d.y )] ).range( [height - margin.bottom, margin.top] )

// Plotting
// useEffect( () => {
//     const calculateScales = async () => {
//         // const { height, width } = await svg.current.getBoundingClientRect()
//         // setDimentions( { height, width } )

//     }
//     calculateScales()
// }, [marginBottom, marginTop, marginLeft, marginRight, utcTimeParser] )