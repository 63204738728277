import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { downloadParticipationReport } from '../../../utils/exam-utilities'
import { toast } from 'react-toastify'
import Submission from '../../exams-components/Submission'
import { UploadOutlined } from '@mui/icons-material'
import api from '../../../service/api'
import { Icons, showDateInIndianDateFormat } from '../../../utils/utilities'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'

const AuditStudentAnswers = ( { exam, participation_id, setSelectedAttempt } ) => {

    const [loadingStudentParticipation, setLoadingStudentParticipation] = useState( true )
    const [participationDetails, setParticipationDetails] = useState( {} )
    const [submissions, setSubmissions] = useState( {} )
    const [questions, setQuestions] = useState( [] )

    const { palette, border } = useTheme()

    useEffect( () => {
        const loadParticipation = async () => {
            try {
                setLoadingStudentParticipation( true )
                const participationData = await api.fetchParticipationData( exam.exam_id, participation_id )
                const { data: submissionData } = await api.fetchParticipationSubmissions( exam.exam_id, participation_id )
                const { data: questionsData } = await api.fetchQuestions( exam.exam_id )
                setParticipationDetails( participationData.data )
                let subs = {}
                for ( let i = 0; i < submissionData.data.length; i++ ) {
                    subs[submissionData.data[i].question_id] ? subs[submissionData.data[i].question_id].push( submissionData.data[i] ) : subs[submissionData.data[i].question_id] = [submissionData.data[i]]
                }
                setSubmissions( subs )
                setQuestions( questionsData.data )
            }
            catch ( e ) {
                console.log( e )
                toast( e?.response?.data?.message || "Something went wrong!" )
            } finally {
                setLoadingStudentParticipation( false )
            }
        }
        loadParticipation()
    }, [exam, participation_id] )

    return (
        <Box sx={{ flexGrow: "1", display: "flex", flexDirection: "column", overflow: "auto" }}>
            <Box ><Button sx={{ marginBottom: "10px" }} startIcon={Icons.default.ArrowBack} onClick={() => setSelectedAttempt( null )}>Back</Button></Box>
            {!loadingStudentParticipation && <Box sx={{ background: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 180%)` }} color="white" gap="20px" display="flex" flexDirection={{ md: "row", xs: "column" }} justifyContent="space-between" alignItems={{ md: "center", xs: "flex-start" }} padding="20px">
                <Box>
                    <Typography variant='h5' textTransform="capitalize">{exam.exam_name}</Typography>
                    <Typography variant='subtitle2' textTransform="capitalize"> Submissions of {participationDetails.participation.student_name.toLowerCase()}</Typography>
                </Box>
                <Typography display="flex" alignItems="center" gap="10px" variant='subtitle2' textTransform="capitalize">{Icons.default.Calendar} {`${showDateInIndianDateFormat( participationDetails.participation.exam_participation_start_time )}, ${new Date( participationDetails.participation.exam_participation_start_time ).toLocaleTimeString()}`}</Typography>
                <Typography variant='body2' textTransform="capitalize">Attempt:  {participationDetails.participation.exam_participation_attempt}</Typography>
                <Typography bgcolor="#D9D9D914" border="1px solid #fff" borderRadius="5px" padding="5px 20px" fontWeight="bold" fontSize="16px" variant='h6'>{participationDetails.participation.exam_participation_score ? participationDetails.participation.exam_participation_score : "0"} Out Of {participationDetails.marks.sum}</Typography>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Tooltip title="Download Report as CSV">
                        <Button
                            variant='contained'
                            disableElevation
                            color='customThemeColor'
                            endIcon={<UploadOutlined />}
                            onClick={() => downloadParticipationReport( participationDetails.participation, questions, submissions )}
                            sx={{
                                textTransform: "capitalize",
                                minWidth: "max-content",
                                background: `linear-gradient(200.17deg, #F3C400 -47.65%, #994322 167.67%)`,
                                '&:hover': {
                                    background: `linear-gradient(200.17deg, #F3C400 -47.65%, #994322 167.67%)`
                                }
                            }} >
                            Export report
                        </Button>
                    </Tooltip>
                </Box>
            </Box>}
            {!loadingStudentParticipation && <Box maxHeight="450px" flexGrow={1} className="custom-scrollbar" overflow="auto" display="flex" flexDirection="column" gap="10px">
                <Box overflow="auto" flexGrow={1} bgcolor={palette.contentBg} className="custom-scrollbar" border={border[1]} borderRadius="0 0 5px 5px">
                    <Box padding="20px" maxWidth="700px" display="flex" className="custom-scrollbar" overflow="auto" flexDirection="column" gap="20px">
                        {submissions && questions && questions.map( ( question, index ) => (
                            <Box key={index}>
                                <Submission question={question.question} answer={submissions[question.question.question_id]} options={question.options} keyAnswer={""} index={index} />
                            </Box>
                        ) )}
                    </Box>
                </Box>
            </Box >}
            {loadingStudentParticipation && <DataLoadingSpinner waitingMessage="Loading attempt answer report..." />}
        </Box>
    )
}

export default AuditStudentAnswers
