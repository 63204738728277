import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import YearChecklist from '../../components/user/academics/YearChecklist'
import { Box, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import api from '../../service/api'
import { useEffect } from 'react'

const Academics = () => {

    const [data, setData] = useState( [] )
    const [loading, setLoading] = useState( true )

    useEffect( () => {
        const getData = async () => {
            try {
                const { data } = await api.fetchStudentAcademicRules()
                const studentData = data.data
                const structuredData = []
                for ( let i = 0; i < studentData.length; i++ ) {
                    const checklist = studentData[i]
                    if ( checklist.session_type === "year" ) {
                        if ( structuredData[checklist.session_number] ) {
                            structuredData[checklist.session_number].checklist.push( checklist )
                            if ( checklist.is_required )
                                structuredData[checklist.session_number].totalRequired += 1
                            if ( checklist.is_completed )
                                structuredData[checklist.session_number].totalCompleted += 1
                        } else {
                            structuredData[checklist.session_number] = { checklist: [checklist], semester: [], totalRequired: checklist.is_required ? 1 : 0, totalCompleted: checklist.is_completed ? 1 : 0 }
                        }
                    } else {
                        const year = Math.ceil( checklist.session_number / 2 )
                        if ( structuredData[year] ) {
                            if ( checklist.is_required )
                                structuredData[year].totalRequired += 1
                            if ( checklist.is_completed )
                                structuredData[year].totalCompleted += 1
                            if ( structuredData[year]['semester'][checklist.session_number] ) {
                                structuredData[year]['semester'][checklist.session_number].push( checklist )
                            } else {
                                structuredData[year]['semester'][checklist.session_number] = [checklist]
                            }
                        } else {
                            const temp = { checklist: [], semester: [], totalRequired: checklist.is_required ? 1 : 0, totalCompleted: checklist.is_completed ? 1 : 0 }
                            temp.semester[checklist.session_number] = [checklist]
                            structuredData[year] = temp
                        }
                    }
                }
                setData( structuredData )
            } catch ( err ) {
                console.log( err )
                toast( err?.response?.data?.message || "Something went wrong while fetching your data!" )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [] )

    return (
        <Box padding="20px" flexGrow={1} minWidth="0">
            <Paper>
                <Box borderBottom="1px solid #d3d3d3" padding="20px">
                    <Typography variant='h5'>Academic checklist</Typography>
                    <Typography variant='subtitle2' color="textSecondary">Check your academic to-do list here.</Typography>
                </Box>
                {!loading && <Box>
                    {data?.length > 0 && <Box display="flex" flexDirection="column" gap="5px" padding="20px">
                        {data?.map( ( year, yearNumber ) => (
                            <YearChecklist key={yearNumber} year={year} yearNumber={yearNumber} />
                        ) )}
                    </Box>}
                    {data?.length === 0 && <Box display="flex" flexDirection="column" gap="5px" padding="20px">
                        <Typography variant="subtitle2" padding="50px 20px" textAlign="center">No checklist created for your batch.</Typography>
                    </Box>}
                </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading academics data..." />}
            </Paper>
        </Box>
    )
}

export default Academics
