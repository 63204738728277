import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import TableHeader from '../../common/TableHeader'
import { useParams } from 'react-router-dom'
import api from '../../../service/api'
import { showDateInIndianDateFormat } from '../../../utils/utilities'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import noExamImg from '../../../assets/noexams.svg'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'
import AuditQuizViewer from '../quizzes/AuditQuizViewer'

const columns = [
    { name: "Sl. no.", padding: "10px 20px 10px 40px", align: "center" },
    { name: "Quiz Title" },
    { name: "Created on" },
    { name: "Subject" },
    { name: "Instances", align: "center", padding: "10px 40px 10px 20px" },
]

const QuizDetails = ( { userData } ) => {

    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [quizDetails, setQuizDetails] = useState( [] )
    const [selectedQuiz, setSelectedQuiz] = useState( null )

    const { palette, table } = useTheme()

    const { auid } = useParams()

    const handleDialogClose = () => {
        setSelectedQuiz( null )
    }

    const loadQuizzes = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data } } = await api.audit.getQuizzesByFacultyId( auid )
            console.log( data )
            setQuizDetails( data )
            setError( false )
        } catch ( err ) {
            setError( err?.response?.data?.message || "Something went wrong while fetching assignments details." )
        } finally {
            setLoading( false )
        }
    }, [auid] )

    useEffect( () => {
        loadQuizzes()
    }, [loadQuizzes] )

    return (
        <Box borderRadius="10px" margin="10px 20px" bgcolor={palette.contentBg} maxHeight="350px" display="flex" flexDirection="column" overflow="hidden">

            {selectedQuiz && <AuditQuizViewer quiz={selectedQuiz} handleClose={handleDialogClose} openState={Boolean( selectedQuiz )} />}

            {!loading && !error && quizDetails.length > 0 && <TableContainer sx={{ overflow: "auto", }} className='custom-scrollbar' >
                <Table stickyHeader>
                    <TableHeader columns={columns} />
                    <TableBody>
                        {quizDetails?.map( ( item, index ) => (
                            <TableRow className="table-row" sx={{ cursor: "pointer", '&:hover': { background: palette.rowHover }, '&:hover .underline': { textDecoration: "underline" } }} key={item.assignment_id}>
                                <TableCell align={columns[0].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[0].padding || "10px 20px" }} ><strong>{index + 1}</strong></TableCell>
                                <TableCell className='underline' align={columns[1].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[1].padding || "10px 20px" }} ><strong>{item.title}</strong></TableCell>
                                <TableCell align={columns[2].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[2].padding || "10px 20px" }} ><strong>{showDateInIndianDateFormat( item.created_at )}</strong></TableCell>
                                <TableCell align={columns[3].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[3].padding || "10px 20px" }} ><strong>{item?.subject?.subject_name || "Not Available"}</strong></TableCell>
                                <TableCell align={columns[4].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[4].padding || "10px 20px" }} ><strong>{item.instances}</strong></TableCell>
                            </TableRow>
                        ) )}
                    </TableBody>
                </Table>
            </TableContainer>}
            {loading && !error && <Box padding="50px 20px" display="flex" justifyContent="center">
                <DataLoadingSpinner waitingMessage="Please wait! Loading quizzes..." />
            </Box>}
            {error && <TryAgainMessageBlock getData={loadQuizzes} loading={loading} err={error} />}
            {!loading && !error && quizDetails.length === 0 && <Box padding="20px" gap="20px" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                <img src={noExamImg} height="100px" alt="Something went wrong!" />
                <Typography variant="subtitle2"><em>{userData.employee_name}</em> hasn't given any quizzes!</Typography>
            </Box>}
        </Box>
    )
}

export default QuizDetails
