import { Box, Collapse, Dialog, IconButton, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import api from '../../../service/api'
import { Icons, flatLightColors } from '../../../utils/utilities'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'

const optionsList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

const QuestionBlock = ( { question, index, colors = false } ) => {

    const [open, setOpen] = useState( false )

    const { palette } = useTheme()

    return (
        <Box bgcolor={colors ? flatLightColors[+question.id % flatLightColors.length] : palette.greyedOut} border={open ? "1px solid rgba(0,0,0,0.1)" : "1px solid #fff"} sx={{ "&:hover": { border: "1px solid rgba(0,0,0,0.1)" } }} borderRadius="5px">

            <Box sx={{ cursor: "pointer" }} display="flex" gap="10px" alignItems="center">
                <Box flexGrow={1} onClick={() => setOpen( !open )} display="flex" gap="10px" alignItems="center" padding="10px">
                    <Typography variant="subtitle2">{index + 1}</Typography>
                    <Box flexGrow={1}>
                        <Typography marginBottom="4px" fontSize="10px" color="secondary">{question.question_type}</Typography>
                        <Box className='questionbank-wysiwyg-container' fontWeight="bold" marginTop="-5px" fontSize="14px" dangerouslySetInnerHTML={{ __html: question.question_text }}></Box>
                    </Box>
                </Box>
            </Box>
            <Collapse in={open}>
                <Box padding="10px" borderTop="1px solid #d3d3d3">
                    {question.options.map( ( option, optionIndex ) => {
                        const isCorrect = question.selection_type === "single" ? +question.correct_answer === optionIndex : question.options_status[optionIndex]
                        return (
                            <Box display="flex" marginBottom="2px" alignItems="flex-start" gap="10px" key={optionIndex}>
                                <Typography marginBottom="2px" variant="subtitle2" fontSize="12px" sx={{ aspectRatio: 1 / 1 }} display="flex" alignItems="center" justifyContent="center" width='20px' borderRadius="50%" margin="0" bgcolor={isCorrect && "success.light"} fontWeight={isCorrect ? "bolder" : "regular"} color={isCorrect ? "white" : "textSecondary"}>{optionsList[optionIndex]}</Typography>
                                <Box variant="subtitle2" className='questionbank-wysiwyg-container' dangerouslySetInnerHTML={{ __html: option.option_text }}></Box>
                            </Box>
                        )
                    } )}
                </Box>
            </Collapse>
        </Box >
    )
}


const TopicBlock = ( { questions, topic } ) => {

    const [open, setOpen] = useState( false )
    const { palette } = useTheme()

    return (
        <Box sx={{
            "&:hover .display-on-hover": {
                display: { md: "flex", xs: "none" }
            }
        }} >


            <Box sx={{
                position: "relative",
                '&:after': {
                    content: `''`,
                    height: "1px",
                    width: "calc(100% - 40px)",
                    left: "50%",
                    bottom: 0,
                    background: palette.borderColor,
                    position: "absolute",
                    transform: "translateX(-50%)"
                },
            }} height="30px" padding="10px 20px" display="flex" justifyContent="space-between" alignItems="center" >
                <Box sx={{ cursor: "pointer" }} flexGrow={1} onClick={() => setOpen( !open )} display="flex" gap="20px" alignItems="center" >
                    <Typography fontWeight="500" variant="subtitle1">{topic}</Typography>
                    {open ? Icons.default.KeyboardArrowUp : Icons.default.KeyboardArrowDownIcon}
                </Box>
            </Box>
            <Collapse in={open}>
                {questions && questions.length > 0 && <Box display="flex" flexDirection="column" gap="5px" padding="10px 20px">
                    {questions.map( ( question, index ) => {
                        return <QuestionBlock question={question} index={index} key={index} />
                    } )}
                </Box>}
                {questions && questions.length === 0 && <Box display="flex" flexDirection="column" gap="5px" padding="10px 20px">
                    <Typography color="GrayText" variant="body2">This topic does not contains any  questions.</Typography>
                </Box>}
            </Collapse>
        </Box>
    )
}


const AuditQuestionBankViewer = ( { handleClose, openState, questionbank } ) => {

    const [questions, setQuestions] = useState( [] )
    const [fetchingQuestions, setFetchingQuestions] = useState( {} )
    const [questionsFetchError, setQuestionsFetchError] = useState( false )

    const fetchQuestions = useCallback( async () => {
        setFetchingQuestions( true )
        try {
            setQuestionsFetchError( true )
            const { data: { data } } = await api.quiz.fetchQuestionBankQuestions( questionbank.id )
            const topicAndQuestions = {}
            for ( let i = 0; i < data.length; i++ ) {
                const question = data[i]
                if ( topicAndQuestions[question.topic_id] )
                    topicAndQuestions[question.topic_id].push( question )
                else topicAndQuestions[question.topic_id] = [question]
            }
            setQuestions( topicAndQuestions )
        } catch ( err ) {
            console.log( err )
            setQuestionsFetchError( err?.response?.data?.message || "Opps! An error occured while trying to fetchquiz questions try again." )
        } finally {
            setFetchingQuestions( false )
        }
    }, [questionbank] )

    useEffect( () => {
        fetchQuestions()
    }, [fetchQuestions] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "1200px", minHeight: "400px" } }} open={openState}>
            <Box sx={{
                background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
            }} display="flex" alignItems="center" justifyContent="space-between" padding="20px" >
                <Box>
                    <Typography color="white" variant="h6">{questionbank.name}</Typography>
                    <Typography variant='body2' color='white' >Questions: {questionbank.questions}</Typography>
                </Box>
                <IconButton onClick={handleClose}>{Icons.default.CloseIcon}</IconButton>
            </Box>
            {!fetchingQuestions && <Box>
                {Object.keys( questions ).length > 0 && <Box display="flex" flexDirection="column" gap="10px">
                    {Object.keys( questions ).map( ( topicId, index ) => {
                        const topicQuestions = questions[topicId]
                        return < TopicBlock key={topicId} topic={questions[topicId][0].topic_name} questions={topicQuestions} />
                    } )}
                </Box>}
                {questionsFetchError && <Typography padding="20px" color="errorMessage.main" variant='subtitle2'>{questionsFetchError}</Typography>}
            </Box>}

            {fetchingQuestions && <DataLoadingSpinner waitingMessage="Loading the questionbank questions please wait..." />}
        </Dialog>
    )
}

export default AuditQuestionBankViewer
