import { handleSeekingAround, handleVolumeSlide, toggleSeeking, toggleMute, handleKeyDown, togglePlay, toggleFullScreen, handleFullScreenChange, handlePlay, handleVideoData, handleOnCanPlay, handleMouseOnTimeline, handleOnlineCourseVideoDataLoad, handleMouseEnter, handleVideoBuffer, handleMouseLeave, handlePause, handleVolumeChange } from '../../utils/video-player-utilities'
import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import { useOnlineCourseStore } from '../../store/onlinecourse.store'
import { Icons } from '../../utils/utilities'
import React, { useEffect, useRef, useState } from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import { SkipPrevious } from '@mui/icons-material'
import PauseIcon from '@mui/icons-material/Pause'

let isSeeking = false, wasPaused, overlayDisappearInterval

const VideoPlayer = ( { link, containerHeight, onPrevButtonClick, onNextButtonClick, handleOnDestroy, nextAndPrevAttachmentIndex, updateProgressDetail, progressDetails, handleVideoEnded = () => { }, videoStartTime, title } ) => {

    const [volume, setVolume] = useState( 1 )
    const [isInFullscreenMode, setIsInFullscreenMode] = useState( false )
    const [isPaused, setIsPaused] = useState( false )
    const [durationData, setDurationData] = useState( '' )
    const [showNextsState, setShowNextsState] = useState( false )
    const [showPrevsState, setShowPrevsState] = useState( false )
    const [attachmentDataLoading, setAttachmentDataLoading] = useState( false )
    const [isBuffering, setIsBuffering] = useState( true )

    const OnlineCourseStore = useOnlineCourseStore()

    const controlsContainerRef = useRef()
    const videoPlayerRef = useRef()
    const videoContainerRef = useRef()
    const timelineContainerRef = useRef()

    // const toggleSeeking = async ( e ) => {
    //     const boundary = timelineContainerRef.current?.getBoundingClientRect()
    //     const percent = Math.min( Math.max( 0, e.clientX - boundary.x ), boundary.width ) / boundary.width
    //     isSeeking = ( e.buttons & 1 ) === 1
    //     videoContainerRef.current?.classList.toggle( 'scrubbing', isSeeking )
    //     if ( isSeeking ) {
    //         wasPaused = videoPlayerRef.current.paused
    //         try {
    //             await videoPlayerRef.current.pause()
    //         } catch ( err ) { }
    //     } else {
    //         try {
    //             videoPlayerRef.current.currentTime = percent * videoPlayerRef.current.duration
    //             if ( !wasPaused )
    //                 await videoPlayerRef.current.play()
    //         } catch ( err ) { }
    //     }
    //     handleMouseOnTimeline( e )
    // }

    // const togglePlay = async () => {
    //     try {
    //         videoPlayerRef.current.paused ? await videoPlayerRef.current.play() : await videoPlayerRef.current.pause()
    //     } catch ( err ) { }
    // }

    // const toggleMute = () => {
    //     videoPlayerRef.current.muted = !videoPlayerRef.current.muted
    //     setVolume( videoPlayerRef.current.muted ? 0 : videoPlayerRef.current.volume )
    // }

    // const handlePlay = () => {
    //     setIsPaused( false )
    //     controlsContainerRef.current?.classList.remove( 'paused' )
    // }

    // const handleOnCanPlay = async () => {
    //     setIsBuffering( false )
    //     try {
    //         if ( videoPlayerRef.current && videoPlayerRef.current.paused )
    //             await videoPlayerRef.current.play()
    //     } catch ( err ) {

    //     }
    // }

    // const handleMouseEnter = ( e ) => {
    //     if ( overlayDisappearInterval ) clearInterval( overlayDisappearInterval )
    //     if ( controlsContainerRef.current ) {
    //         controlsContainerRef.current?.classList.add( 'show-overlay' )
    //         if ( e.type !== "mouseenter" ) {
    //             overlayDisappearInterval = setTimeout( () => {
    //                 controlsContainerRef.current?.classList.remove( 'show-overlay' )
    //             }, 5000 )
    //         }
    //     }
    // }

    // const handleMouseLeave = () => {
    //     if ( controlsContainerRef.current ) {
    //         controlsContainerRef.current?.classList.remove( 'show-overlay' )
    //     }
    // }

    // const handlePause = () => {
    //     setIsPaused( true )
    //     controlsContainerRef.current?.classList.add( 'paused' )
    // }

    // const handleVolumeChange = ( e ) => {
    //     setVolume( videoPlayerRef.current.muted ? 0 : videoPlayerRef.current.volume )
    // }

    // const handleVideoBuffer = () => {
    //     if ( videoPlayerRef.current ) {
    //         try {
    //             let range = 0
    //             const bf = videoPlayerRef.current.buffered
    //             const time = videoPlayerRef.current.currentTime
    //             while ( !( bf.start( range ) <= time && time <= bf.end( range ) ) ) {
    //                 range += 1
    //             }
    //             const loadStartPercentage = bf.start( range ) / videoPlayerRef.current.duration
    //             const loadEndPercentage = bf.end( range ) / videoPlayerRef.current.duration
    //             const loadPercentage = loadEndPercentage - loadStartPercentage
    //             timelineContainerRef.current?.style.setProperty( "--buffer-position", loadPercentage )
    //         } catch ( err ) { }
    //     }
    // }

    // const handleOnlineCourseVideoDataLoad = () => {
    //     if ( progressDetails && progressDetails?.status === "NOTSTARTED" )
    //         updateProgressDetail( progressDetails.id, { status: "INPROGRESS" } )
    //     if ( videoStartTime ) videoPlayerRef.current.currentTime = videoStartTime
    //     setDurationData( `${formatVideoDuration( videoPlayerRef.current.currentTime )} / ${formatVideoDuration( videoPlayerRef.current.duration )}` )
    //     const percent = videoPlayerRef.current.currentTime / videoPlayerRef.current.duration
    //     timelineContainerRef.current?.style.setProperty( "--progress-position", percent )
    // }

    // const handleVideoData = () => {
    //     setDurationData( `${formatVideoDuration( videoPlayerRef.current.currentTime )} / ${formatVideoDuration( videoPlayerRef.current.duration )}` )
    //     const percent = videoPlayerRef.current.currentTime / videoPlayerRef.current.duration
    //     timelineContainerRef.current?.style.setProperty( "--progress-position", percent )
    // }

    // const handleMouseOnTimeline = ( e ) => {
    //     const boundary = timelineContainerRef.current?.getBoundingClientRect()
    //     const percent = Math.min( Math.max( 0, e.clientX - boundary.x ), boundary.width ) / boundary.width
    //     timelineContainerRef.current?.style.setProperty( "--preview-position", percent )
    //     if ( isSeeking ) {
    //         e.preventDefault()
    //         timelineContainerRef.current?.style.setProperty( "--progress-position", percent )
    //     }
    // }

    // const handleSeekingAround = ( e ) => {
    //     if ( isSeeking ) toggleSeeking( e )
    // }

    const handleSeeking = e => {
        if ( isSeeking )
            handleMouseOnTimeline( e, isSeeking, timelineContainerRef.current )
    }

    // const toggleFullScreen = async () => {
    //     if ( !document.fullscreenElement ) {
    //         if ( videoContainerRef.current?.requestFullscreen ) {
    //             videoContainerRef.current?.requestFullscreen()
    //         } else if ( videoContainerRef.current?.webkitRequestFullscreen ) {
    //             videoContainerRef.current?.webkitRequestFullscreen()
    //         } else if ( videoContainerRef.current?.msRequestFullscreen ) {
    //             videoContainerRef.current?.msRequestFullscreen()
    //         }
    //         try {
    //             await window.screen.orientation.lock( 'portrait-primary' )
    //         } catch ( err ) { }
    //         videoContainerRef.current?.classList.add( 'fullscreen' )
    //     } else {
    //         if ( document.exitFullscreen ) {
    //             document.exitFullscreen()
    //         } else if ( document.webkitExitFullscreen ) {
    //             document.webkitExitFullscreen()
    //         } else if ( document.msExitFullscreen ) {
    //             document.msExitFullscreen()
    //         }
    //         videoContainerRef.current?.classList.remove( 'fullscreen' )
    //     }
    // }

    // const handleFullScreenChange = () => {
    //     if ( document.fullscreenElement ) {
    //         setIsInFullscreenMode( true )
    //         videoContainerRef.current?.classList.add( 'fullscreen' )
    //     }
    //     else {
    //         setIsInFullscreenMode( false )
    //         videoContainerRef.current?.classList.remove( 'fullscreen' )
    //     }
    // }

    const handleOrientationChange = ( e ) => {
        const screenOrientation = window.screen.orientation.type
        if ( screenOrientation === "landscape-primary" || screenOrientation === "landscape-secondary" ) {
            toggleFullScreen( videoContainerRef.current )
        } else if ( screenOrientation === "portrait-secondary" || screenOrientation === "portrait-primary" ) {
            toggleFullScreen( videoContainerRef.current )
        } else if ( screenOrientation === undefined ) {
            console.log( "The orientation API isn't supported in this browser :(" )
        }
    }

    const fullscreenChangeHandler = () => handleFullScreenChange( videoContainerRef.current, setIsInFullscreenMode )

    const mouseUpHandler = async ( e ) => {
        const returnedValue = await handleSeekingAround( e, isSeeking, videoPlayerRef.current, videoContainerRef.current, timelineContainerRef.current, wasPaused )
        wasPaused = returnedValue.wasPaused
        isSeeking = returnedValue.isSeeking
    }

    // Online Course functions
    useEffect( () => {
        const videoPlayer = videoContainerRef.current
        return () => {
            try {
                if ( handleOnDestroy && videoPlayer ) {
                    handleOnDestroy( progressDetails.id, { last_saved_duration: videoPlayer?.currentTime } )
                }
            } catch ( err ) { }
        }
    }, [handleOnDestroy, progressDetails] )

    useEffect( () => {
        const videoContainer = videoContainerRef.current
        videoContainerRef.current?.addEventListener( 'fullscreenchange', fullscreenChangeHandler )
        document.addEventListener( 'mouseup', mouseUpHandler )
        document.addEventListener( 'mousemove', handleSeeking )
        window.screen.orientation.addEventListener( "change", handleOrientationChange )
        return () => {
            videoContainer.removeEventListener( 'fullscreenchange', fullscreenChangeHandler )
            document.removeEventListener( 'mouseup', mouseUpHandler )
            document.removeEventListener( 'mousemove', handleSeeking )
            window.screen.orientation.removeEventListener( "change", handleOrientationChange )
        }
    }, [] )

    return (
        <Box height={containerHeight} ref={videoContainerRef} onMouseEnter={( e ) => overlayDisappearInterval = handleMouseEnter( e, controlsContainerRef.current, overlayDisappearInterval )} onMouseMove={( e ) => overlayDisappearInterval = handleMouseEnter( e, controlsContainerRef.current, overlayDisappearInterval )} onMouseLeave={() => handleMouseLeave( controlsContainerRef.current )} tabIndex={0} onKeyDown={( e ) => handleKeyDown( e, videoPlayerRef.current, videoContainerRef.current )} className='video-container' id='video-container' width={{ lg: "auto", xs: "100%" }}>
            {!attachmentDataLoading &&
                <video
                    ref={videoPlayerRef}
                    onCanPlay={( e ) => handleOnCanPlay( e, setIsBuffering, videoPlayerRef.current )}
                    onWaiting={() => setIsBuffering( true )}
                    autoPlay id='video' className='video-player' src={link}
                    onPlay={() => handlePlay( setIsPaused, controlsContainerRef.current )}
                    onPause={() => handlePause( setIsPaused, controlsContainerRef.current )}
                    onVolumeChange={() => handleVolumeChange( setVolume, videoPlayerRef.current )}
                    onLoadedData={() => handleOnlineCourseVideoDataLoad( setDurationData, videoPlayerRef.current, timelineContainerRef.current, progressDetails, updateProgressDetail, videoStartTime )}
                    onTimeUpdate={() => handleVideoData( setDurationData, videoPlayerRef.current, timelineContainerRef.current )}
                    onLoadedMetadata={() => setAttachmentDataLoading( false )}
                    onEnded={() => handleVideoEnded( progressDetails )}
                    onProgress={() => handleVideoBuffer( videoPlayerRef.current, timelineContainerRef.current )}
                ></video>}
            <Box width="100%" ref={controlsContainerRef} height="100%" className="video-controls-container">
                {title && <Box className="video-title">
                    <Typography variant='subtitle2'>{title}</Typography>
                </Box>}
                {!attachmentDataLoading && <Box id="controls-area" className="video-controls">
                    <Box
                        onMouseDown={
                            async ( e ) => {
                                const returnedValue = await toggleSeeking( e, videoPlayerRef.current, videoContainerRef.current, timelineContainerRef.current, isSeeking, wasPaused )
                                wasPaused = returnedValue.wasPaused
                                isSeeking = returnedValue.isSeeking
                            }}
                        ref={timelineContainerRef}
                        onMouseMove={( e ) => handleMouseOnTimeline( e, isSeeking, timelineContainerRef.current )}
                        id="timeline-container"
                        className="timeline-container"
                    >
                        <div className="timeline">
                            <span className="thumb-indicator"></span>
                            <span className="buffer-loaded"></span>
                        </div>
                    </Box>
                    <Box sx={{ pointerEvents: "all" }} display="flex" gap="20px" justifyContent="space-between" alignItems="flex-end">
                        <Box display="flex" alignItems="center" gap="10px" flexGrow="1">
                            <button onClick={() => togglePlay( videoPlayerRef.current )} className='control-btn'>
                                {!isPaused ? Icons.default.PauseIcon : Icons.default.PlayArrowIcon}
                            </button>
                            <Box position="relative" onMouseEnter={() => setShowPrevsState( true )} onMouseLeave={() => setShowPrevsState( false )} >
                                <Box position="absolute" width="150px" maxWidth="250px" bottom="100%" display={showPrevsState ? "block" : "none"}>
                                    {nextAndPrevAttachmentIndex.prev !== null && <Paper elevation={4} sx={{ padding: "10px" }}>
                                        <b style={{ color: "black", fontSize: "12px" }}>Prev</b>
                                        <Typography fontSize="12px" sx={{ color: "black" }} variant='subtitle2'> {OnlineCourseStore.getCourse['attachments'][nextAndPrevAttachmentIndex.prev].attachment_name.split( "." ).slice( 0, -1 ).join( "" )} </Typography>
                                    </Paper>}
                                </Box>
                                <button disabled={nextAndPrevAttachmentIndex.prev === null} onClick={onPrevButtonClick} className='control-btn'>
                                    <SkipPrevious />
                                </button>
                            </Box>
                            <Box position="relative" onMouseEnter={() => setShowNextsState( true )} onMouseLeave={() => setShowNextsState( false )} >
                                <Box position="absolute" width="150px" maxWidth="250px" bottom="100%" display={showNextsState ? "block" : "none"}>
                                    {nextAndPrevAttachmentIndex.next && <Paper elevation={4} sx={{ padding: "10px" }}>
                                        <b style={{ color: "black", fontSize: "12px" }}>Next</b>
                                        <Typography fontSize="12px" sx={{ color: "black" }} variant='subtitle2'> {OnlineCourseStore.getCourse['attachments'][nextAndPrevAttachmentIndex.next].attachment_name.split( "." ).slice( 0, -1 ).join( "" )} </Typography>
                                    </Paper>}
                                </Box>
                                <button disabled={!nextAndPrevAttachmentIndex.next} onClick={onNextButtonClick} className='control-btn'>
                                    <SkipNextIcon />
                                </button>
                            </Box>
                            <Box className="volume-container" display="flex" alignItems="center">
                                {videoPlayerRef.current?.muted ? <button onClick={() => toggleMute( videoPlayerRef.current, setVolume )} className="control-btn">{Icons.default.VolumeOffIcon}</button> : volume > 0.5 ? <button onClick={() => toggleMute( videoPlayerRef.current, setVolume )} className="control-btn">{Icons.default.VolumeUpIcon}</button> : <button onClick={() => toggleMute( videoPlayerRef.current, setVolume )} className="control-btn">{Icons.default.VolumeDownIcon}</button>}
                                <input
                                    type="range"
                                    onChange={( e ) => handleVolumeSlide( e, setVolume, videoPlayerRef.current )}
                                    className='volume-slider'
                                    value={volume}
                                    step="0.1"
                                    min={0}
                                    max={1}
                                />
                            </Box>
                            <span style={{ fontSize: "12px" }}>{durationData}</span>
                        </Box>
                        <Box display="flex" alignItems="center" gap="10px" >
                            <button onClick={() => toggleFullScreen( videoContainerRef.current )} className='control-btn'>
                                {isInFullscreenMode ? Icons.default.FullscreenExitIcon : Icons.default.FullscreenIcon}
                            </button>
                        </Box>
                    </Box>
                </Box>}
                {!attachmentDataLoading && <Box onClick={() => togglePlay( videoPlayerRef.current )} position="absolute" top="0" bottom="0" right="0" left="0" display="flex" alignItems="center" justifyContent="center">
                    <button className="play-pause-btn" style={{ opacity: isPaused ? 1 : 0, transition: "opacity 1s ease" }}>
                        {isPaused ? <PlayArrowIcon sx={{ fontSize: "60px !important" }} /> : <PauseIcon sx={{ fontSize: "60px !important" }} />}
                    </button>
                </Box>}
                {( isBuffering || attachmentDataLoading ) && <Box onClick={() => togglePlay( videoPlayerRef.current )} position="absolute" top="0" bottom="0" right="0" left="0" display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress sx={{ color: "customThemeColor.dark" }} size={40} />
                </Box>}
            </Box>
        </Box>
    )
}

export default VideoPlayer
