import { ArrowDownwardOutlined, ArrowUpwardOutlined, CloseOutlined, FilterList, History, LocalFireDepartmentOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, Popover, Typography, useTheme } from '@mui/material'
import CustomSearchBar from '../common/CustomSearchBar'
import { useSearchParams } from 'react-router-dom'
import { Icons } from '../../utils/utilities'
import React, { useState } from 'react'

const sortOptions = [
    { icon: <LocalFireDepartmentOutlined fontSize="small" />, label: "Latest", value: "latest" },
    { icon: <ArrowUpwardOutlined fontSize="small" />, label: "A-Z", value: "atoz" },
    { icon: <ArrowDownwardOutlined fontSize="small" />, label: "Z-A", value: "ztoa" },
    { icon: <History fontSize="small" />, label: "Oldest", value: "oldest" }
]

const DiscussionForumFilterButton = () => {

    const [searchParams, setSearchParams] = useSearchParams( { sort: 'latest', category: "", searchText: "" } )
    const searchText = searchParams.get( 'searchText' )
    const category = searchParams.get( 'category' )
    const sort = searchParams.get( 'sort' )

    const [filterOptionsDialogAnchorEl, setFilterOptionsDialogAnchorEl] = useState( null )
    const [sortOptionsAnchorEl, setSortOptionsAnchorEl] = useState( null )
    const [categoryInputValue, setCategoryInputValue] = useState( '' )
    const [searchInputValue, setSearchInputValue] = useState( searchText )

    const { palette, border } = useTheme()

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const handleKeyDown = ( type, event ) => {
        if ( event.key === 'Enter' ) {
            event.preventDefault()
            if ( type === 'cat' ) {
                const val = event.target.value.trim()
                if ( val && val.length > 0 ) {
                    console.log( category, category.split( "," ) )
                    let newCategoriesValue = ( category.trim() || '' ).split( "," ).filter( c => c.trim().length > 0 )
                    newCategoriesValue.push( val.toLowerCase() )
                    setSearchParamValue( 'category', newCategoriesValue.join( "," ) )
                    setCategoryInputValue( "" )
                }
            } else {
                if ( searchInputValue.trim().length > 0 ) {
                    setSearchParamValue( 'searchText', searchInputValue )
                }
            }
        }
    }

    const removeCategory = ( cat ) => {
        let categoriesList = category.split( "," )
        const index = categoriesList.indexOf( cat )
        categoriesList.splice( index, 1 )
        setSearchParamValue( 'category', categoriesList.join( "," ) )
    }

    const handleSortOptionChange = async ( val ) => {
        setSearchParamValue( 'sort', val )
    }

    const clearFilters = () => {
        setSearchParams( prev => {
            prev.set( 'searchText', '' )
            prev.set( 'category', '' )
            prev.set( 'latest', '' )
        }, { replace: true } )
    }

    const handleSearch = () => {
        if ( searchInputValue.trim().length > 0 ) {
            setSearchParamValue( 'searchText', searchInputValue )
        }
    }

    const applyFilters = () => {
        const val = categoryInputValue
        let newCategoriesValue = []
        if ( val && val.length > 0 ) {
            newCategoriesValue = ( category.trim() || '' ).split( "," ).filter( c => c.trim().length > 0 )
            newCategoriesValue.push( val.toLowerCase() )
        }
        setSearchParams( prev => {
            prev.set( 'category', newCategoriesValue.join( "," ) )
            prev.set( 'searchText', searchInputValue )
            return prev
        }, { replace: true } )
        setCategoryInputValue( "" )

    }

    return (
        <Box>
            <Button onClick={( e ) => setFilterOptionsDialogAnchorEl( e.currentTarget )} size="large" sx={{ display: "flex", alignitems: "center", gap: "10px", justifyContent: "space-between", paddingX: "10px", textTransform: "capitalize", color: "black", borderColor: "black" }}>
                <Typography display="flex" gap="10px" alignItems="center">
                    <FilterList fontSize="small" />
                    Filter
                </Typography>
            </Button>

            {/* Popover */}
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={() => setFilterOptionsDialogAnchorEl( null )}
                sx={{ padding: 0 }}
                slotProps={{ paper: { sx: { padding: 0, maxWidth: "500px", background: palette.form.formCardBg } } }}
                elevation={2} open={Boolean( filterOptionsDialogAnchorEl )}
                anchorEl={filterOptionsDialogAnchorEl}
            >
                <Box>
                    <Box borderBottom="1px solid #d3d3d3" padding="10px 20px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
                        <Box display="flex" color="customThemeColor.main" gap="10px" alignItems="center">
                            {Icons.default.FilterAlt}
                            <Typography variant="h6" color="primaryDark.main" fontSize="18px">
                                Apply filters on discussions
                            </Typography>
                        </Box>
                        <IconButton onClick={() => setFilterOptionsDialogAnchorEl( null )}>
                            {Icons.default.CloseIcon}
                        </IconButton>
                    </Box>
                    <Box margin="20px" display="flex" flexDirection="column" gap="20px" borderRadius="5px">
                        <Box>
                            <Box minWidth="200px" display="flex" flexDirection="column">
                                <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Categories</Typography>
                                <CustomSearchBar border={`1px solid ${palette.borderColor}`} value={categoryInputValue} icon={""} onChange={e => setCategoryInputValue( e.target.value )} onKeyDown={( e ) => handleKeyDown( 'cat', e )} style={{ width: "100%" }} type="text" placeholder="Type category and press enter..." />
                            </Box>
                            {category && category.split( "," ).length > 0 && <Box display="flex" flexWrap="wrap" gap="10px" padding="5px">
                                {category.split( "," ).map( cat => (
                                    <Box padding="3px 10px" gap="10px" display="flex" alignItems="center" border="1px solid #d3d3d3" borderRadius="5px" key={cat}>
                                        <Typography textTransform="capitalize" fontSize="12px" fontWeight="500">{cat}</Typography>
                                        <IconButton size="small" onClick={() => removeCategory( cat )}>
                                            <CloseOutlined sx={{ fontSize: "15px" }} />
                                        </IconButton>
                                    </Box>
                                ) )}
                            </Box>}
                        </Box>
                        <Box >
                            <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Search by name</Typography>
                            <Box borderRadius="5px" border={border[1]} overflow="hidden" display="flex" gap="0" alignItems="stretch">
                                <CustomSearchBar icon={<Box display="inline-block" marginX="5px"></Box>} value={searchInputValue} onKeyDown={e => handleKeyDown( 'search', e )} style={{ flexGrow: 1, borderRadius: "0" }} onChange={e => setSearchInputValue( e.target.value )} size="small" type="text" placeholder="By title or subject" />
                                <Box flexGrow={1} marginLeft="-10px" onClick={handleSearch} bgcolor={palette.greyedOut} display="flex" alignItems="center">
                                    <IconButton sx={{ borderRadius: "0", padding: "0 20px", height: "100%", }} size="small" >
                                        {Icons.default.SearchIcon}
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" gap="20px">
                            <Box display="flex" flexDirection="column" gap="5px">
                                <Typography color={palette.labelColor} variant='subtitle2'>Sort</Typography>
                                <Box display="flex">
                                    <Button variant='outlined' onClick={( e ) => setSortOptionsAnchorEl( e.currentTarget )} sx={{ flexGrow: 1, display: "flex", alignitems: "center", gap: "10px", justifyContent: "space-between", paddingX: "10px", textTransform: "capitalize", color: palette.common.font, borderColor: palette.common.font }}>
                                        {sortOptions?.find( s => s.value === sort )?.label || "Latest"}
                                        {Icons.small.DropDown}
                                    </Button>
                                    <Popover onClose={() => setSortOptionsAnchorEl( null )} open={Boolean( sortOptionsAnchorEl )} anchorEl={sortOptionsAnchorEl}>
                                        {sortOptions.map( sortOption => (
                                            <Box
                                                onClick={() => handleSortOptionChange( sortOption.value )}
                                                display="flex" sx={{ background: sort === sortOption.value ? `${palette.primary.light}22` : palette.contentBg, cursor: "pointer", "&:hover": { background: palette.rowHover } }} gap="10px" padding="5px 10px" key={sortOption.label} alignItems="center">
                                                {sortOption.icon}
                                                <Typography variant="subtitle2">{sortOption.label}</Typography>
                                            </Box>
                                        ) )}
                                    </Popover>
                                </Box>
                            </Box>

                            <Box display="flex" gap="20px" justifyContent="space-between">
                                <Button onClick={applyFilters} variant="contained" color="primary" disableElevation>Apply</Button>
                                <Button onClick={clearFilters} variant="contained" color="error" disableElevation>Clear filters</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Popover >
        </Box >
    )
}

export default DiscussionForumFilterButton
