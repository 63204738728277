import React, { useState } from 'react'
import { Typography, Box, TextField, useTheme, FormControl, Button, FormHelperText, IconButton, Tooltip } from '@mui/material'
import parse from 'html-react-parser'
// import { useStyles } from '../../styles/evaluate.styles'
import { useParams } from 'react-router-dom'
import KeyIcon from '@mui/icons-material/Key'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import * as Yup from 'yup'
import { Axios } from '../../utils/exam-utilities'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { EditableMathField } from 'react-mathquill'
import Latex from 'react-latex-next'
import { latexDelimiters } from '../../utils/utilities'

export default function Submission( { question, answer, index, keyAnswer, options } ) {
    let originalMarks = answer && answer.submission_score ? answer.submission_score : 0
    const { id, participation_id } = useParams()
    const [isAssigned, setIsAssigned] = useState( answer && answer.submission_score ? true : false )
    const [showScheme, setShowScheme] = useState( false )

    const { palette, border } = useTheme()

    const validationSchema = Yup.object().shape( {
        marks: Yup.number().required( "Marks required" ).min( 0, "Minimum marks 0" ).max( question.question_point, `Maximum marks ${question.question_point}` )
    } )

    const initialValues = {
        marks: answer && answer.submission_score ? answer.submission_score : '',
        question_id: question.question_id
    }

    const getAnswerText = ( id, options ) => {
        for ( let i = 0; i < options.length; i++ ) {
            if ( id === options[i].answer_id )
                return options[i].answer_text.includes( "LATEX-" ) ? <EditableMathField id="static-latex" style={{ color: "inherit !important", padding: "0" }} latex={options[i].answer_text.split( "LATEX-" )[1]} /> : <Latex delimiters={latexDelimiters}>{options[i].answer_text}</Latex>
        }
    }

    const assignMarks = async ( values, props ) => {
        try {
            const res = await Axios.post( `/exams/${id}/submission/${participation_id}/assignmarks`, { marks: values.marks - originalMarks, question_id: values.question_id }, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem( "id_token" )}`
                }
            } )
            originalMarks = values.marks
            toast.success( res.data )
            setIsAssigned( true )
        }
        catch ( e ) {
            toast.error( e.response.data )
        }
    }

    const answeredCorrectly = answer && answer.some( ans => ans.submission_score > 0 )


    return (
        <Box>
            <Box gap={1} sx={{ "& p": { margin: 0, display: "inline-block" }, flexDirection: { sm: "row", md: "row", lg: "row", xs: "column" }, alignItems: { sm: "center", md: "center", lg: "center", xs: "flex-start" } }} alignItems="center" color="textSecondary">
                <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap="20px">
                    <Typography variant="body2" fontSize="12px" >
                        Question {index + 1}
                    </Typography>
                    {/* <Box display="flex" gap="10px" alignItems="center" padding="5px" borderRadius="5px" sx={{ flexDirection: "row-reverse" }}>
                        {answer && question.question_type !== 'MCQ' && answer.submission_score &&
                            <Tooltip placement='top' title="Marks already assigned">
                                <CheckCircleIcon color='success' sx={{ cursor: "pointer", fontSize: "20px" }} />
                            </Tooltip>
                        }
                        {answer && question.question_type === 'MCQ' && <>
                            <Tooltip placement='top' title="Marks already assigned">
                                <CheckCircleIcon color='success' sx={{ cursor: "pointer", fontSize: "20px" }} />
                            </Tooltip>
                            <Tooltip placement='top' title="Marks auto alloted">
                                <HdrAutoIcon color='success' sx={{ cursor: "pointer", fontSize: "20px" }} />
                            </Tooltip>
                        </>
                        }
                    </Box> */}
                    <Box display="flex" fontSize="12px" gap="5px" color={answeredCorrectly ? "#22DD3B" : "#E4586C"} fontWeight="600" alignItems="center" >
                        <Typography bgcolor={answeredCorrectly ? "#22DD3B" : "#E4586C"} textAlign="center" fontSize="12px" noWrap sx={{ width: "20px", display: "flex", alignItems: "center", color: "white", justifyContent: "center", borderRadius: "50%", margin: 0, padding: 0, aspectRatio: 1 / 1 }} >{( answer && answer[0].submission_score ) ? answer[0].submission_score : 0}</Typography> OUT OF {question.question_point}
                    </Box>
                </Box>

                {answer && question.question_type !== 'MCQ' && <Tooltip title="Show key answer">
                    <IconButton onClick={e => { setShowScheme( !showScheme ) }} sx={{ fontSize: "10px" }}> <KeyIcon sx={{ fontSize: "20px" }} /> </IconButton>
                </Tooltip>}
                <Box color={palette.common.font} textAlign="justify">
                    {question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField latex={question.question_text.trim().split( "LATEX-" )[1]} id='static-latex'> </EditableMathField> : <Latex delimiters={latexDelimiters}>{question.question_text.trim()}</Latex>}
                </Box>

            </Box>
            {showScheme &&
                <Box sx={{ fontSize: "12px", padding: "20px", border: "#eee 2px solid", borderRadius: "5px", marginBottom: "5px", "& p": { margin: 0 } }}>
                    <Typography variant='h6' color="secondary" textAlign="justify">Key answer</Typography>
                    <Latex delimiters={latexDelimiters}>{keyAnswer.answer_text}</Latex>
                </Box>
            }
            <Box display="flex" marginTop="5px" padding="10px" borderRadius="5px" gap="10px" flexWrap="wrap" border={border[1]} borderColor={answeredCorrectly ? "#22DD3B" : "#E4586C"} alignItems="center">
                <Typography variant='body2' >Student's answer: </Typography>
                {answer && <Box flexGrow="1" sx={{ background: palette.contentBg, borderRadius: "5px", fontSize: "14px", "& p": { margin: "0" } }}>
                    <Box display="block">
                        {answer.map( ( ans, index ) => (
                            ans.answer_id ? <Box key={ans.answer_id ? ans.answer_id : index} textAlign="justify" fontSize="14px" borderRadius="5px"  >{getAnswerText( ans.answer_id, options )}</Box> : <Latex delimiters={latexDelimiters} >{answer.answer_descriptive}</Latex>
                        ) )}
                    </Box>
                </Box>}
                {!answer && <Typography variant='subtitle2' flexGrow="1" fontSize="14px" sx={{ background: palette.contentBg, borderRadius: "5px", fontSize: "14px", "& p": { margin: "0" } }} color="error">Not answered</Typography>}
            </Box>

            {answer && question.question_type !== 'MCQ' &&
                <Formik onSubmit={assignMarks} initialValues={initialValues} validationSchema={validationSchema}>
                    {( { values, setFieldValue } ) => (
                        <>
                            <Form autoComplete='off' method='POST' sx={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                                <FormControl>
                                    <Field as={TextField} sx={{ marginTop: "10px" }} disabled={isAssigned} onWheel={e => e.target.blur()} type="number" name="marks" inputProps={{ step: 0.5 }} variant="outlined" label="Marks" />
                                    <input type="hidden" name="question_id" value={question.question_id} />
                                </FormControl>
                                <Button type='submit' variant='contained' disabled={isAssigned} sx={{ textTransform: "capitalize", height: "53px", margin: "10px 0 0 10px", width: "fit-content" }}>Submit</Button>
                                {isAssigned && <Button onClick={( e ) => setIsAssigned( false )} variant='contained' sx={{ textTransform: "capitalize", height: "53px", margin: "10px 0 0 10px", width: "fit-content" }}>Edit</Button>}
                            </Form>
                            <FormHelperText sx={{ color: "#de1738" }}> <ErrorMessage name='marks' /> </FormHelperText>
                        </>
                    )}
                </Formik>
            }
        </Box >
    )
}
