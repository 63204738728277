import { Dialog, Box, Typography, Button, useTheme, CircularProgress } from '@mui/material'
import { Icons, getCroppedImage } from '../../../utils/utilities'
import React, { useState } from 'react'
import ImageEditor from 'react-easy-crop'
import { toast } from 'react-toastify'

const ProfileImageCropper = ( { imageSrc, setImageSrc, handleCropSave } ) => {

    const [croppedAreaPixels, setCroppedAreaPixels] = useState( null )
    const [uploading, setUploading] = useState( false )
    const [crop, setCrop] = useState( { x: 0, y: 0 } )
    const [zoom, setZoom] = useState( 1 )

    const { palette } = useTheme()


    const onCropComplete = ( croppedArea, croppedAreaPixels ) => {
        setCroppedAreaPixels( croppedAreaPixels )
    }

    const handleClose = () => {
        setImageSrc( null )
    }

    const handleUpload = async () => {
        setUploading( true )
        try {
            const { file, url } = await getCroppedImage( imageSrc, croppedAreaPixels )
            await handleCropSave( file, url )
        } catch ( err ) {
            toast( "Something went wrong while uploading your profile image!" )
        } finally {
            setUploading( false )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "80%", maxWidth: "400px" } }} onClose={handleClose} open={Boolean( imageSrc )}>
            <Box borderBottom="1px solid #d3d3d3" padding="20px">
                <Typography variant="subtitle2">Adjust your profile image</Typography>
                <Typography variant="body2" fontSize="12px" color="textSecondary">Max Size: 2MB, Minimum dimentions: 200px * 200px</Typography>
            </Box>
            {imageSrc && <Box display="flex" justifyContent="center">
                <Box width="300px" height="300px" position="relative">
                    <ImageEditor
                        style={{ containerStyle: { background: "none" } }}
                        objectFit='contain'
                        image={imageSrc}
                        showGrid={false}
                        cropShape='round'
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </Box>
            </Box>}
            <Box bgcolor={palette.contentBg} display="flex" gap="20px" justifyContent="flex-end" borderTop="1px solid #d3d3d3" padding="20px">
                <Button sx={{ textTransform: "capitalize" }} startIcon={uploading ? <CircularProgress size={14} /> : Icons.default.UploadFileIcon} onClick={handleUpload} variant="contained" disabled={uploading} disableElevation>{uploading ? "Uploading..." : "Upload"}</Button>
                <Button sx={{ textTransform: "capitalize" }} color="error" onClick={handleClose} variant="contained" disabled={uploading} disableElevation>Cancel</Button>
            </Box>
        </Dialog>
    )
}

export default ProfileImageCropper
