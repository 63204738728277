import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, useTheme } from '@mui/material'
import React from 'react'
import { Icons, showDateInIndianDateFormat } from '../../utils/utilities'
import { useNavigate } from 'react-router-dom'
import TableHeader from '../common/TableHeader'


const columns = [
    { label: "Material Name", align: "left", minWidth: "200px", padding: "10px 20px 10px 40px" },
    { label: "Subject-Code", align: "left", minWidth: "200px", },
    { label: "Created on", align: "left", minWidth: "100px" },
    { label: "Document count", align: "right", minWidth: "150px", padding: "10px 40px 10px 20px" }
]

const FacultyMaterialsList = ( { filteredMaterials, docsCount, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } ) => {

    const { palette, table, border } = useTheme()
    const navigate = useNavigate()

    return (
        <Box flexGrow={1} margin="20px" borderRadius="5px" border={border[1]} bgcolor={palette.contentBg} overflow="auto" display="flex" flexDirection="column" justifyContent="space-between" >
            <TableContainer className="custom-scrollbar" sx={{ borderRadius: "0 0 5px 5px", overflow: "auto", maxHeight: "400px" }}>
                <Table stickyHeader>
                    <TableHeader columns={columns} />
                    <TableBody>
                        {filteredMaterials
                            .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                            .map( material => (
                                <TableRow className='table-row' sx={{ cursor: "pointer", "&:hover": { background: `${palette.rowHover} !important` } }} onClick={() => navigate( `/faculty/material/${material.study_material_id}` )} key={material.study_material_id}>
                                    <TableCell sx={{ color: table.color, padding: columns[0].padding || "10px 20px", fontWeight: "bold", display: "table-cell", alignItems: "center", gap: "10px" }} align='left'> <Box display="flex" gap="10px" alignItems="center">
                                        {Icons.default.Material} <strong >{material.study_material_name}</strong>
                                    </Box> </TableCell>
                                    <TableCell sx={{ color: table.color, padding: columns[1].padding || "10px 20px", }} align='left'><strong>{material.subject_name}-{material.subject_code}</strong></TableCell>
                                    <TableCell sx={{ color: table.color, padding: columns[2].padding || "10px 20px", }} align='left'><strong>{showDateInIndianDateFormat( material.study_material_published_date )}</strong></TableCell>
                                    <TableCell sx={{ color: table.color, padding: columns[3].padding || "10px 20px", }} align='right'><strong>{docsCount[material.study_material_id] ? docsCount[material.study_material_id] : 0}</strong></TableCell>
                                </TableRow>
                            ) )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                sx={{ background: table.headerBg, position: "sticky", bottom: "0", height: "60px" }}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredMaterials.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}

export default FacultyMaterialsList
