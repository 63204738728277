import { SwapVertOutlined } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'

const CustomTableColumnLabel = ( { align = "flex-start", data, setData, label, sortByProperty, setSearchParamValue, sortKey, sortName, sortFlag, sortValueType, minWidth } ) => {
    return <Box display="flex" justifyContent={align} minWidth={minWidth} alignItems="center" gap="5px">
        {label}
        <IconButton onClick={
            () => {
                sortByProperty( data, setData, sortKey, sortValueType, sortFlag === "1" ? "-1" : "1" )
                setSearchParamValue( sortName, sortFlag === "1" ? "-1" : "1" )
            }
        } size="small" sx={{ cursor: "pointer", padding: "5px", "&:hover": { background: "rgba(0,0,0,0.4)", color: "white", } }}>
            <SwapVertOutlined sx={{ fontSize: "16px" }} />
        </IconButton>
    </Box>
}

export default CustomTableColumnLabel
