import React, { useState } from "react"
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  styled,
  CircularProgress,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Formik, Form, Field, ErrorMessage } from "formik"
import logoImg from "../../assets/alivelogo.png"
import backgroundImg from "../../assets/candidate-login-bg.jpg"
import { useAuthStore } from "../../store/auth.store"
import * as Yup from "yup"
import { useUserStore } from "../../store/user.store"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import api from "../../service/api"
// import { toast } from "react-toastify";
// import api from "../service/api";


const LoginContainer = styled( Box )( ( { theme } ) => ( {
  display: "flex",
  height: "100vh",
  position: "relative",
  overflow: "hidden",
} ) )

const ContentWrapper = styled( Box )( {
  display: "flex",
  position: "absolute",
  inset: 0,
  zIndex: 2,
} )

const LogoSection = styled( Box )( ( { theme } ) => ( {
  position: "absolute",
  top: 20,
  left: 20,
  display: "flex",
  alignItems: "center",
  zIndex: 3,
  [theme.breakpoints.down( "sm" )]: {
    top: 10,
    left: 10,
  },
} ) )

const Logo = styled( "img" )( ( { theme } ) => ( {
  height: 30,
  marginRight: 8,
  [theme.breakpoints.down( "sm" )]: {
    height: 25,
  },
} ) )

const LoginSection = styled( Box )( ( { theme } ) => ( {
  position: "absolute",
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "50%",
  maxWidth: 500,
  padding: 20,
  [theme.breakpoints.down( "md" )]: {
    width: "60%",
  },
  [theme.breakpoints.down( "sm" )]: {
    width: "100%",
    maxWidth: "none",
  },
} ) )

const LoginForm = styled( Box )( ( { theme } ) => ( {
  width: "100%",
  maxWidth: 350,
  padding: 50,
  backgroundColor: "white",
  borderRadius: 24,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.down( "sm" )]: {
    maxWidth: "90%",
    padding: 20,
  },
} ) )

const BackgroundSection = styled( Box )( {
  position: "absolute",
  left: 0,
  width: "100%",
  height: "100vh",
  zIndex: 1,
} )

const BackgroundImage = styled( "img" )( {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "top",
} )

const CandidateLogin = () => {

  const [isLoggingIn, setIsLoggingIn] = useState( false )
  const [showPassword, setShowPassword] = useState( false )
  const togglePasswordVisibility = () => setShowPassword( !showPassword )
  const [checkingEligibility, setCheckingEligibility] = useState( false )

  const AuthStore = useAuthStore()
  const UserStore = useUserStore()
  const navigate = useNavigate()

  const initialValues = {
    email: "",
    password: "",
  }

  const validationSchema = Yup.object().shape( {
    email: Yup.string().required( "This field is required!" ),
    password: Yup.string().required( "Password field is required!" ),
  } )

  const attempt = async ( id ) => {
    try {
      // setAttempting( true )
      const { status, data } = await api.admissionExams.attempt( id )
      if ( status === 200 ) {
        if ( !data.isNewParticipation ) toast.success( "Proceeding with the previously unfinished exam!", { containerId: "main" } )
        const url = `/admission-exam/${id}/${data.participation_id}/attempt/${data.attempt}`

        navigate( url, { replace: true } )
      }
    } catch ( e ) {
      console.log( e )
      toast.error( e?.response?.data?.message || e?.response?.data || "Something went wrong", { containerId: "main" } )
    } finally {
      // setAttempting( false )
    }
  }

  const login = async ( values ) => {
    setIsLoggingIn( true )
    const currentYear = ( new Date().getFullYear() + "" )?.substring( 2 )
    try {
      if ( values.email?.substring( 3, 5 ) !== currentYear ) {
        return toast( "Only newly admitted students can login here! If you are newly admitted, please check your AUID" )
      }
      const aliveRes = await AuthStore.doAuthViaERP( {
        username: values.email,
        password: values.password,
        usertype: "STUDENT"
      }, false )

      if ( aliveRes ) {

        await UserStore.fetchUser()
        setCheckingEligibility( true )
        const { data } = await api.admissionExams.verifyCandidate()
        if ( data.exceedsMaximumNumberOfAttempts )
          toast( `The maximum number of attempts exceeded` )
        else {
          await attempt( data.exam_id )
        }
        setCheckingEligibility( false )
      }
    } catch ( err ) {
      localStorage?.clear()
      toast( err?.response?.data?.message || err?.response?.data || "Something went wrong!" )
    } finally {
      setCheckingEligibility( false )
      setIsLoggingIn( false )
    }
  }


  return (
    <LoginContainer>
      <ContentWrapper>
        <LogoSection>
          <Logo src={logoImg} alt="Alive Logo" />
          <Typography variant="h5" fontWeight="bold">
            Alive
          </Typography>
        </LogoSection>
        <LoginSection>
          <LoginForm>
            <Typography variant="h5" fontSize="30px" fontWeight="bold" marginBottom={1}>
              Preliminary Assessment
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              marginBottom={5}
            >
              Please login to start the assessment
            </Typography>
            <Typography variant="h5" marginBottom={2}>
              Login
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={login}
            >
              {( { isSubmitting } ) => (
                <Form>
                  <Field
                    as={TextField}
                    fullWidth
                    name="email"
                    placeholder="Enter username"
                    variant="outlined"
                    margin="normal"
                  />
                  <Typography
                    color="error.dark"
                    variant="subtitle2"
                    fontSize="12px"
                    marginLeft="2px"
                  >
                    <ErrorMessage name="email" />
                  </Typography>
                  <Field
                    as={TextField}
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    color="error.dark"
                    variant="subtitle2"
                    fontSize="12px"
                    marginLeft="2px"
                  >
                    <ErrorMessage name="password" />
                  </Typography>
                  <Button
                    startIcon={isLoggingIn && <CircularProgress size={14} />}
                    type="submit"
                    fullWidth
                    disableElevation
                    variant="contained"
                    sx={{ height: "53px", mt: 3, mb: 2, fontWeight: 600 }}
                    disabled={isLoggingIn}
                  >
                    {isLoggingIn ? checkingEligibility ? "Checking eligibility..." : "Logging you in..." : "LOGIN"}
                  </Button>
                </Form>
              )}
            </Formik>
          </LoginForm>
        </LoginSection>
      </ContentWrapper>
      <BackgroundSection>
        <BackgroundImage src={backgroundImg} alt="Background" />
      </BackgroundSection>
    </LoginContainer>
  )
}

export default CandidateLogin
