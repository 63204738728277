import { Box, Button, CircularProgress, Dialog, FormControl, IconButton, Typography, useTheme } from '@mui/material'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Icons } from '../../utils/utilities'
import { AccessTime } from '@mui/icons-material'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { useExamStore } from '../../store/exam.store'
import { formatDateTimeForDB } from '../../utils/exam-utilities'

const ExamRescheduleDialog = ( { state, setState, exam } ) => {

    const [updating, setUpdating] = useState( false )
    const ExamStore = useExamStore()

    const { palette, border } = useTheme()

    const initialValues = {
        start_date: new Date( exam.exam_start_date ),
        end_date: new Date( exam.exam_end_date ),
        start_time: exam.exam_start_time,
        end_time: exam.exam_end_time,
    }

    const validationSchema = Yup.object().shape( {
        start_time: Yup.mixed().required( "Start time is required" ).test( "VALID_START_TIME", "Please select a valid start time", ( val, { parent } ) => {
            const currentTime = new Date().toLocaleTimeString( 'en-IN', { hourCycle: "h24", hour: "2-digit", minute: "2-digit" } )
            const currentDate = new Date()
            const selectedStartDate = parent.start_date
            if ( currentDate > selectedStartDate ) return true
            return new Date( `2024-01-01 ${currentTime}` ) < new Date( `2024-01-01 ${val}` )
        } ),
        end_time: Yup.mixed().required( "Start time is required" ).test( "VALID_END_TIME", "Please select a valid end time", ( val, { parent } ) => {
            const startDateTime = new Date( `${parent.start_date?.toISOString().split( "T" )[0]} ${parent.start_time}` )
            const endDateTime = new Date( `${parent.end_date?.toISOString().split( "T" )[0]} ${val}` )
            if ( !parent.end_date )
                return true
            return startDateTime < endDateTime
        } ),
    } )

    const handleDateChange = ( name, e, setValue ) => {
        setValue( name, e )
    }

    const handleTimeChange = ( name, e, setValue ) => {
        const time = new Date( e ).toLocaleTimeString( 'en-IN', { hourCycle: "h24", hour: "2-digit", minute: "2-digit" } )
        setValue( name, time )
    }

    const rescheduleExam = async ( values ) => {
        try {
            setUpdating( true )
            await api.rescheduleExam( exam.exam_id, { ...values, start_date: formatDateTimeForDB( values.start_date, 'en-IN', "-" ), end_date: formatDateTimeForDB( values.end_date, 'en-IN', "-" ) } )
            toast( 'Exam rescheduled successfully' )
            await ExamStore.fetchExam( exam.exam_id )
            setState( false )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || err?.response?.data || "Something went wrong while rescheduling the exam" )
        } finally {
            setUpdating( false )
        }
    }

    return (
        <Dialog open={state} >
            <Formik onSubmit={rescheduleExam} initialValues={initialValues} validationSchema={validationSchema}>
                {( { values, setFieldValue } ) => {
                    return (
                        <Box>
                            <Box padding='10px 20px' display="flex" gap="20px" alignItems="center" justifyContent="space-between">
                                <Typography variant='subtitle2'>Reschedule {exam.exam_name}</Typography>
                                <IconButton size="small" onClick={() => { setState( false ) }}>
                                    {Icons.default.CloseIcon}
                                </IconButton>
                            </Box>
                            <Box margin="20px" marginTop="0" bgcolor={palette.form.formBg} border={border[1]} borderRadius="5px" padding="20px">
                                <Form>
                                    <Box sx={{ display: "flex", gap: "10px", }}>
                                        <FormControl >
                                            <Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Start date  </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    minDate={dayjs( new Date() )}
                                                    onChange={e => handleDateChange( 'start_date', e, setFieldValue )}
                                                    value={dayjs( values.start_date )}
                                                    format="DD-MM-YYYY"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                        <FormControl >
                                            <Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Start time  </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    onChange={e => handleTimeChange( 'start_time', e, setFieldValue )}
                                                    defaultValue={dayjs( new Date( `2024-01-01 ${values.start_time}` ) )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Box>
                                    <Typography color="error" fontSize="12px" sx={{ fontWeight: "bold" }}>
                                        <ErrorMessage name='start_date' />
                                    </Typography>
                                    <Typography color="error" fontSize="12px" sx={{ fontWeight: "bold" }}>
                                        <ErrorMessage name='start_time' />
                                    </Typography>


                                    <Box marginTop="20px" sx={{ display: "flex", gap: "10px" }}>
                                        <FormControl >
                                            <Typography gutterBottom variant="subtitle2" color={palette.labelColor}> End date  </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    minDate={dayjs( values.start_date )}
                                                    onChange={e => handleDateChange( 'end_date', e, setFieldValue )}
                                                    value={dayjs( values.end_date )}
                                                    format="DD-MM-YYYY"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                        <FormControl >
                                            <Typography gutterBottom variant="subtitle2" color={palette.labelColor}> End time  </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    onChange={e => handleTimeChange( 'end_time', e, setFieldValue )}
                                                    defaultValue={dayjs( new Date( `2024-01-01 ${values.end_time}` ) )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Box>
                                    <Typography color="error" fontSize="12px" sx={{ fontWeight: "bold" }}>
                                        <ErrorMessage name='end_date' />
                                    </Typography>
                                    <Typography color="error" fontSize="12px" sx={{ fontWeight: "bold" }}>
                                        <ErrorMessage name='end_time' />
                                    </Typography>
                                    <Box marginTop="40px" display="flex">
                                        <Button variant="contained" disableElevation type='submit' disabled={updating} startIcon={updating ? <CircularProgress size={14} /> : <AccessTime />} >{updating ? "Rescheduling exam..." : "Reschedule exam"}</Button>
                                    </Box>
                                </Form>
                            </Box>
                        </Box>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default ExamRescheduleDialog