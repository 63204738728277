export const sortByProperty = ( data, setData, property, type, order ) => {
    let newFilteredData = [...data]
    newFilteredData.sort( ( a, b ) => {
        if ( type === "number" ) {
            if ( order === "1" )
                return ( parseInt( a[property] ) - parseInt( b[property] ) )
            else
                return ( parseInt( b[property] ) - parseInt( a[property] ) )
        } else {
            if ( order === "1" )
                return ( ( a[property] < b[property] ) ? -1 : 0 )
            else
                return ( ( a[property] > b[property] ) ? -1 : 0 )
        }
    } )
    setData( newFilteredData )
}

export const structureData = ( data, filters, requiredChecklistItemsCount, optionalChecklistItemsCount, progressDetails ) => {
    const formatedData = []
    for ( let i = 0; i < data.length; i++ ) {
        const student = data[i]
        const studentData = {}
        studentData.auid = student.auid
        studentData.student_name = student.student_name
        studentData.optionalChecklistItemsCount = optionalChecklistItemsCount
        studentData.requiredCompleted = progressDetails[student.auid]?.completed || 0
        studentData.optionalCompleted = progressDetails[student.auid]?.optionalCompleted || 0
        studentData.pendingCount = requiredChecklistItemsCount - ( progressDetails[student.auid]?.completed || 0 )
        if ( filters?.frontFilter === "onlypending" && studentData.pendingCount === 0 ) continue
        if ( filters?.frontFilter === "onlycompleted" && studentData.pendingCount > 0 ) continue
        studentData.pendingItems = progressDetails[student.auid]?.pending_items
        studentData.requiredItemsCompletionPercentage = requiredChecklistItemsCount ? Math.round( ( ( progressDetails[student.auid]?.completed || 0 ) * 100 ) / requiredChecklistItemsCount ) : 0
        studentData.totalProgress = ( requiredChecklistItemsCount + optionalChecklistItemsCount ) > 0 ? Math.round( ( ( ( progressDetails[student.auid]?.completed || 0 ) + ( progressDetails[student.auid]?.optionalCompleted || 0 ) ) * 100 ) / ( requiredChecklistItemsCount + optionalChecklistItemsCount ) ) : 0
        formatedData.push( studentData )
    }
    return formatedData
}

export const filterBranches = ( institute, branches, setData ) => {
    try {
        const filtered = branches.filter(
            i => i.institute_name_short === institute
        )
        setData( filtered )
        return filtered
    } catch ( e ) {
        console.log( e )
    }
}