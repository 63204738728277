import { Box, Button, CircularProgress, IconButton, MenuItem, Paper, Select, Tooltip, Typography, useTheme } from '@mui/material'
import { Icons } from '../../utils/utilities'
import React, { useCallback, useRef } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { AudioFileOutlined, AudioFileTwoTone, CancelOutlined, KeyboardVoiceOutlined, Mic, MicOff, Pause, PlayArrow, Stop } from '@mui/icons-material'
import AudioPlayer from './AudioPlayer'
import Progress from '../common/Progress'
const mimeType = "audio/webm"
const audioFileType = "audio/wav"

let activeDevices, activeListeningDevice

const AudioRecorder = ( { maxSize, maxLength, selectedAudioFiles, setSelectedAudioFiles, setRecordedCardStatus, container, supportedAudioFormats = ['.mp3', '.weba'], multiple = false } ) => {
    const [permission, setPermission] = useState( false )
    const mediaRecorder = useRef( null )
    const [stream, setStream] = useState( null )
    const [audioChunks, setAudioChunks] = useState( [] )
    const [recordLength, setRecordLength] = useState( 0 )
    // const [timer, setTimer] = useState( "00:00" )
    const [recorderStatus, setRecorderStatus] = useState( "idle" )
    const [draggingOver, setDraggingOver] = useState( false )
    const [selectedAudioFilesData, setSelectedAudioFilesData] = useState( [] )
    const [tabValue, setTabValue] = useState( 'browse' )
    const [isGettingMicrophonePermission, setIsGettingMicrophonePermission] = useState( false )
    const [audioRecorderErrorState, setAudioRecorderErrorState] = useState( null )
    const [possibleAudioInputDevices, setPossibleAudioInputDevices] = useState( [] )
    const [selectedAudioInputDeviceId, setSelectedAudioInputDeviceId] = useState( null )

    const { palette, border } = useTheme()

    const handleAudioDeviceStateChange = ( e ) => {
        setAudioRecorderErrorState( e.type === "unmute" ? null : "muted" )
    }


    const stopAudioTracks = async () => {
        try {
            if ( stream ) {
                const tracks = stream.getTracks()
                await Promise.all( tracks.map( track => {
                    return track.stop()
                } ) )

                await Promise.all( tracks.map( track => {
                    return stream.removeTrack( track )
                } ) )
            }
        } catch ( err ) { }
    }

    const handleAudioInputDeviceChange = async ( e ) => {
        const deviceId = e.target.value
        await stopAudioTracks()
        await getMicrophonePermission( deviceId )
        setSelectedAudioInputDeviceId( deviceId )
    }

    const getMicrophonePermission = async ( deviceId ) => {
        setIsGettingMicrophonePermission( true )
        if ( "MediaRecorder" in window ) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia( {
                    audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
                    video: false,
                } )
                const devices = await navigator.mediaDevices.enumerateDevices()
                setPossibleAudioInputDevices( devices.filter( i => i.kind === "audioinput" ) )
                activeDevices = streamData.getAudioTracks()
                if ( activeDevices.length > 0 ) {
                    activeListeningDevice = activeDevices[0]
                    const deviceSelected = devices.find( i => i.label === activeListeningDevice.label )
                    setSelectedAudioInputDeviceId( deviceSelected.deviceId )
                    if ( activeListeningDevice.muted ) setAudioRecorderErrorState( "muted" )
                    activeListeningDevice.addEventListener( 'mute', handleAudioDeviceStateChange )
                    activeListeningDevice.addEventListener( 'unmute', handleAudioDeviceStateChange )
                    toast( `Connected to ${activeListeningDevice.label}` )
                } else {
                    toast( "No audio devices found" )
                }
                setPermission( true )
                setStream( streamData )
            } catch ( err ) {
                toast( err.message )
            } finally {
                setIsGettingMicrophonePermission( false )
            }
        } else {
            toast( "The MediaRecorder API is not supported in your browser." )
        }
    }

    const handleTabChange = ( e, newVal ) => {
        setTabValue( newVal )
        if ( newVal === 'record' )
            getMicrophonePermission()
        stopAudioTracks()
    }

    const handleAudioFileInput = async ( e ) => {
        try {
            if ( multiple ) {
                for ( let i = 0; i < e.target.files.length; i++ ) {
                    const audioFile = e.target.files[i]
                    if ( audioFile.size / ( 1024 * 1024 ) < maxSize ) {
                        if ( supportedAudioFormats.includes( `.${audioFile.name.split( "." ).pop()}` ) ) {
                            const fileReader = new FileReader()
                            fileReader.onload = () => {
                                setSelectedAudioFiles( p => [...p, audioFile] )
                                setSelectedAudioFilesData( p => [...p, [audioFile.name, fileReader.result]] )
                            }
                            fileReader.readAsDataURL( audioFile )
                        } else {
                            toast( `The format of the file ${audioFile.name} is not supported` )
                        }
                    } else {
                        toast( `The file ${audioFile.name} exceeds the maximum size(${maxSize} MB)` )
                    }

                }
            } else {
                const audioFile = e.target.files[0]
                if ( audioFile.size / ( 1024 * 1024 ) < maxSize ) {
                    if ( supportedAudioFormats.includes( `.${audioFile.name.split( "." ).pop()}` ) ) {
                        const fileReader = new FileReader()
                        fileReader.onload = async () => {
                            setSelectedAudioFilesData( [[audioFile.name, fileReader.result]] )
                            setSelectedAudioFiles( [audioFile] )
                        }
                        fileReader.readAsDataURL( audioFile )
                    } else {
                        toast( `Unsupported file format` )
                    }
                } else {
                    toast( `Selected file exceeds maximum Size(${maxSize} MB)` )
                }

            }
            e.target.value = ''
        } catch ( err ) { }
    }

    const startRecording = async () => {
        try {
            const media = new MediaRecorder( stream, { type: mimeType } )
            mediaRecorder.current = media
            mediaRecorder.current.start()
            let localAudioChunks = []
            mediaRecorder.current.ondataavailable = ( event ) => {
                if ( typeof event.data === "undefined" ) return
                if ( event.data.size === 0 ) return
                localAudioChunks.push( event.data )
            }
            setAudioChunks( localAudioChunks )
            setRecorderStatus( "recording" )
        } catch ( err ) {
            console.log( err )
            toast( "Error occured while starting to recorder" )
        }
    }

    const stopRecording = useCallback( () => {
        try {
            setRecorderStatus( "stoped" )
            setRecordLength( 0 )
            mediaRecorder.current.stop()
            mediaRecorder.current.onstop = () => {
                const audioBlob = new Blob( audioChunks, { type: mimeType } )
                const audioUrl = URL.createObjectURL( audioBlob )
                if ( multiple ) {
                    const recordedFile = new File( [audioBlob], `voice${Date.now()}.wav`, { type: audioFileType } )
                    setSelectedAudioFilesData( data => [...data, [recordedFile.name, audioUrl]] )
                    setSelectedAudioFiles( files => [...files, recordedFile] )
                }
                else {
                    const recordedFile = new File( [audioBlob], `voice${Date.now()}.wav`, { type: audioFileType } )
                    setSelectedAudioFilesData( [[recordedFile.name, audioUrl]] )
                    setSelectedAudioFiles( [recordedFile] )
                }
                setAudioChunks( [] )
            }
        } catch ( err ) {
            console.log( err )
            toast( "Error occured while saving recorded audio! try again." )
        }
    }, [audioChunks, multiple, setSelectedAudioFiles] )

    const removeAudioFile = ( index ) => {
        const updatedFilesDataList = [...selectedAudioFilesData]
        const updatedFilesList = [...selectedAudioFiles]
        updatedFilesDataList.splice( index, 1 )
        updatedFilesList.splice( index, 1 )
        setSelectedAudioFilesData( updatedFilesDataList )
        setSelectedAudioFiles( updatedFilesList )
    }

    // useEffect( () => {
    //     let minutes = Math.floor( recordLength / 60 )
    //     let seconds = recordLength % 60
    //     setTimer( `${leadingZeroFormatter.format( minutes )}:${leadingZeroFormatter.format( seconds )}` )
    // }, [recordLength] )

    useEffect( () => {
        let interval = null
        if ( recorderStatus === "recording" ) {
            interval = setInterval( () => {

                setRecordLength( prev => {
                    if ( prev + 1 === parseInt( maxLength ) ) {
                        clearInterval( interval )
                        stopRecording()
                    }
                    return prev + 1
                } )
            }, 1000 )
        } else {
            clearInterval( interval )
        }
        return () => {
            clearInterval( interval )
        }
    }, [recorderStatus, maxLength, stopRecording] )

    useEffect( () => {
        if ( selectedAudioFiles.length === 0 ) {
            setSelectedAudioFilesData( [] )
        }
    }, [selectedAudioFiles] )


    useEffect( () => {
        return () => {
            if ( activeListeningDevice ) {
                activeListeningDevice?.removeEventListener( 'mute', handleAudioDeviceStateChange )
                activeListeningDevice?.removeEventListener( 'unmute', handleAudioDeviceStateChange )
            }
        }
    }, [selectedAudioInputDeviceId] )

    return (
        <Paper elevation={container === 'paper' ? 1 : 0} sx={{ border: container === 'paper' ? "none" : "1px solid #d3d3d3", "&:hover": { border: container === 'paper' ? "none" : "1px solid #444" } }}>
            <Box borderRadius="5px 5px 0 0" borderBottom="1px solid #d3d3d3" paddingRight="10px" display="flex" justifyContent="space-between">
                {/* <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                >
                    <Tab value='browse' sx={{ textTransform: "capitalize", color: tabValue === 'browse' ? "secondary.main" : "none" }} label={<Typography display="flex" gap="5px" alignItems="center"> <FolderOpenOutlined sx={{ fontSize: "18px" }} /> Browse System</Typography>} />
                    <Tab value='record' sx={{ textTransform: "capitalize", color: tabValue === 'record' ? "secondary.main" : "none" }} label={<Typography display="flex" gap="5px" alignItems="center"> <MicOutlined sx={{ fontSize: "18px" }} />Record Audio</Typography>} />
                </Tabs> */}
                <Box padding="20px" display="flex" gap="20px" alignItems="center">
                    <Button onClick={e => handleTabChange( e, "browse" )} startIcon={<AudioFileTwoTone />} variant='outlined' sx={{ textTransform: "capitalize", background: "#1DB5B7", border: "1px solid #148788", color: "#fff", '&:hover': { background: "#147288" } }} size="small" >Browse system</Button>
                    <Button onClick={e => handleTabChange( e, "record" )} startIcon={<Mic />} color="secondary" variant='outlined' sx={{ textTransform: "capitalize", background: palette.secondary.light + "33", '&:hover': { background: palette.secondary.light + "55" } }} size="small" >Record Audio</Button>
                </Box>
                <IconButton color='error' size="small" onClick={() => setRecordedCardStatus( false )}>
                    {Icons.default.CloseIcon}
                </IconButton>
            </Box>
            <Box position="relative" flexWrap="wrap" borderBottom="1px solid #d3d3d3" display="flex" alignItems="center" gap="10px">
                {tabValue === 'record' && <Box borderRadius="0 0 5px 5px" display="flex" flexDirection="column" width="100%" justifyContent="center" height="250px" alignItems="center" gap="10px">
                    <Box display="flex" borderRadius="0 0 5px 5px" gap="10px" alignItems="center">
                        <Box display="flex" alignItems="center" gap="10px" flexDirection="column">
                            {( recorderStatus === "idle" || recorderStatus === "stoped" ) && permission && <Tooltip title={audioRecorderErrorState === null ? recorderStatus === "stoped" ? "Start new recording" : "Start recording" : "Unmute to record"}>
                                <Box borderRadius="50%" overflow="visible" sx={{ zIndex: "7" }} position="relative">
                                    {audioRecorderErrorState === "muted" && <MicOff sx={{ position: "absolute", top: "-5px", right: "-10px", zIndex: 10, borderRadius: "50%", padding: "5px", background: palette.error.dark, fontSize: "15px", color: "white" }} />}
                                    <Box borderRadius="50%" sx={{ border: `10px solid ${palette.error.light + "22"}`, }}>
                                        <IconButton sx={{ background: '#FF8794', opacity: 1, color: "white", '&:hover': { background: '#FF8794' } }} disabled={( !permission || audioRecorderErrorState !== null )} size="small" onClick={startRecording}>
                                            <KeyboardVoiceOutlined sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Tooltip>}

                            {( recorderStatus === "idle" || recorderStatus === "stoped" ) && <Button onClick={startRecording} variant='outlined' sx={{ textTransform: "capitalize", borderRadius: "20px" }} color="errorMessage">{recorderStatus === "idle" ? "Start Recording" : "Start New Recording"}</Button>}

                            {( recorderStatus === "idle" || recorderStatus === "stoped" ) && permission && possibleAudioInputDevices.length > 1 && <Box>
                                <Select onChange={handleAudioInputDeviceChange} sx={{ borderRadius: "20px", fontSize: "14px" }} value={selectedAudioInputDeviceId || 'default'} size="small">
                                    {possibleAudioInputDevices.map( dev => (
                                        <MenuItem key={dev.deviceId} sx={{ fontSize: "14px" }} value={dev.deviceId}>{dev.label}</MenuItem>
                                    ) )}
                                </Select>
                            </Box>}
                        </Box>
                        {( recorderStatus === "recording" || recorderStatus === "paused" ) &&
                            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                                <Box display="flex" justifyContent="center" alignItems="center" gap="10px">
                                    <Tooltip title={recorderStatus === "idle" ? "Start Recording" : recorderStatus === "paused" ? "Resume Recording" : "Pause Recording"}>
                                        <IconButton color="greyed" size="small"
                                            onClick={() => {
                                                if ( recorderStatus === "paused" ) {
                                                    mediaRecorder.current.resume()
                                                    setRecorderStatus( "recording" )
                                                } else {
                                                    mediaRecorder.current.pause()
                                                    setRecorderStatus( "paused" )
                                                }
                                            }}>
                                            {recorderStatus === "paused" ? <PlayArrow sx={{ fontSize: "35px" }} /> : <Pause sx={{ fontSize: "35px" }} />}
                                        </IconButton>
                                    </Tooltip>
                                    {/* <KeyboardVoiceOutlined className='blink' sx={{ fontSize: "35px", background: "rgba(200,0,0,0.6)", color: "#fff", padding: "5px", borderRadius: "50%" }} /> */}
                                    <Progress progress={( recordLength / maxLength ) * 100} size={75} indicatorWidth={10} trackWidth={12} secondaryColor='#E22B3F17'
                                        color={palette.errorMessage.main} showLabel={false}
                                        icon={<KeyboardVoiceOutlined sx={{ fontSize: "25px", background: 'red', color: "white", borderRadius: "50%", padding: "5px", marginTop: "5px" }} />}
                                    />
                                    <Tooltip title="Stop Recording">
                                        <IconButton color="greyed" size="small" onClick={stopRecording}>
                                            <Stop sx={{ fontSize: "35px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Box>
                                    {( recorderStatus === "recording" || recorderStatus === "paused" ) && <Typography borderRadius="0 0 5px 5px" color="primaryDark.main" variant='subtitle2' fontSize="20px" alignItems="center" display="flex" gap="5px" justifyContent="center"> {recordLength} Sec</Typography>}
                                    {permission && <Typography variant="subtitle2" color="GrayText" >
                                        {recorderStatus === "recording" && "Recording"}
                                        {recorderStatus === "paused" && "Recording Paused"}
                                    </Typography >}
                                </Box>
                            </Box>}
                    </Box>
                    {( recorderStatus === 'idle' || recorderStatus === 'stoped' ) && <Typography variant="subtitle2" fontSize="12px" >
                        Max Length: {maxLength} seconds
                    </Typography >}
                    {!permission && <Box>
                        {!isGettingMicrophonePermission && <Button onClick={getMicrophonePermission} variant='outlined' sx={{ textTransform: "capitalize" }} color="warning" >
                            Allow microphone permission
                        </Button>}
                        {isGettingMicrophonePermission && <Typography display="flex" gap="10px" alignItems="center" variant="subtitle2" color="GrayText" >
                            <CircularProgress size={14} /> Getting required permissions
                        </Typography>}
                    </Box>}
                </Box>}
            </Box>
            {
                tabValue === 'browse' && <Box
                    onDragOver={( e ) => { e.preventDefault(); setDraggingOver( true ) }}
                    onDragEnter={() => setDraggingOver( true )}
                    onDragLeave={() => setDraggingOver( false )}
                    onDrop={() => setDraggingOver( false )}
                    position="relative"
                    height="160px"
                    margin="20px"
                    borderRadius="10px"
                    bgcolor={draggingOver ? palette.primary.light + "33" : "none"}
                    flexDirection="column"
                    gap="5px"
                    padding="20px 10px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    overflow="hidden"
                    sx={{
                        background: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke-width='2' stroke='${palette.primary.main?.replace( /#/, '%23' )}' stroke-dasharray='6%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                        backgroundColor: palette.primary.light + "22"
                    }}
                >
                    <AudioFileOutlined color="primary" sx={{ fontSize: "50px" }} />
                    <Box marginY="10px" fontSize="14px" color="TextSecondary">
                        Drag and Drop or <Typography fontSize="14px" display="inline-block" color='#E25A26' sx={{ cursor: "pointer" }}>Choose file</Typography>
                    </Box>
                    <Typography fontSize="12px" textTransform="uppercase">
                        Supports: {supportedAudioFormats.join( ', ' )}
                    </Typography>
                    <Typography variant="subtitle2" fontSize="12px">
                        Max Size: {maxSize} MB
                    </Typography>
                    <input multiple={multiple} accept='audio/webm,audio/mpeg,audio/mp3,audio/ogg' onInput={handleAudioFileInput} style={{ position: "absolute", cursor: "pointer", opacity: "0", top: "0", left: "0", width: "100%", height: "100%" }} type='file' />
                </Box>
            }
            {
                selectedAudioFilesData.length > 0 && <Box padding="10px 20px" display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                    {selectedAudioFilesData.map( ( audio, index ) => (
                        <Box bgcolor={palette.contentBg} borderRadius="5px" border={border[1]} display="flex" gap="5px" padding="10px" alignItems="flex-start" key={index}>
                            <AudioPlayer audioName={audio[0]} audioSrc={audio[1]} />
                            <Tooltip title="Remove this audio file">
                                <IconButton sx={{ marginTop: "-10px", marginRight: "-10px" }} onClick={() => removeAudioFile( index )} color="greyed">
                                    <CancelOutlined sx={{ fontSize: "20px" }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ) )}
                </Box>
            }
        </Paper >
    )
}

AudioRecorder.propTypes = {
    labelVariant: PropTypes.oneOf( ['body1', 'body2', 'button', 'caption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'inherit', 'overline', 'subtitle1', 'subtitle2', null, undefined] ),
    container: PropTypes.oneOf( ['paper', 'bordered-box'] )
}

export default AudioRecorder


