import { TabContext, TabList } from '@mui/lab'
import { Box, Button, Paper, Tab, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useUserStore } from '../../../store/user.store'
import { Icons } from '../../../utils/utilities'
import { useNavigate } from 'react-router-dom'
import noCourses from '../../../assets/no-courses.svg'
import OnlineCourseCard from '../../../components/online-course-components/OnlineCourseCard'
import { useOnlineCourseStore } from '../../../store/onlinecourse.store'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import CustomSearchBar from '../../../components/common/CustomSearchBar'
import BlockMessage from '../../../components/common/BlockMessage'

const OnlineCourses = () => {

    const [mode, setMode] = useState( localStorage.getItem( "videoTutorialsMode" ) ? localStorage.getItem( "videoTutorialsMode" ) : "manage" )
    const [hasPermission, setHasPermission] = useState( true )
    const [searchText, setSearchText] = useState( "" )
    const [isLoading, setIsLoading] = useState( true )

    const navigate = useNavigate()
    const UserStore = useUserStore()
    const OnlineCourseStore = useOnlineCourseStore()

    const handleTabChange = async ( e, newValue ) => {
        setMode( newValue )
    }

    useEffect( () => {
        const getData = async () => {
            await OnlineCourseStore.fetchCourses()
            setIsLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.online_course ) {
            setHasPermission( false )
        } else
            getData()
        getData()
    }, [OnlineCourseStore, UserStore] )

    return (
        hasPermission ? <Box flexGrow={1} padding="20px">
            <TabContext value={mode}>
                <Box bgcolor="#fff" borderBottom="none">
                    <TabList sx={{ borderBottom: "2px solid rgba(0,0,0,0.2)" }} onChange={handleTabChange}>
                        <Tab iconPosition='start' sx={{ "&:hover": { background: "#eee" } }} icon={Icons.default.DescriptionIcon} value={"manage"} label="Manage" />
                        <Tab iconPosition='start' sx={{ "&:hover": { background: "#eee" } }} icon={Icons.default.InsightsIcon} value={"audit"} label="Audit" />
                    </TabList>
                </Box>
            </TabContext>
            <Paper>
                <Box padding="20px" borderBottom="1px solid #d3d3d3" display="flex" gap="20px" alignItems={{ md: "center", xs: "flex-start" }} flexDirection={{ md: "row", xs: "column" }} justifyContent="space-between">
                    <Box>
                        <Typography variant="h5" width="fit-content" fontWeight="normal">Online Courses</Typography>
                        <Typography color="textSecondary" variant='subtitle2'>{mode === "manage" ? "Manage all online courses" : "Audit the online courses"}</Typography>
                    </Box>
                    <Box width={{ md: "auto", xs: "100%" }} display="flex" sx={{ "@media(max-width:900px)": { flexDirection: "column" } }} gap="10px" justifyContent={{ xs: "space-between", md: "flex-end" }} flexWrap="wrap">
                        {/* <TextField name='alivevideosearch' autoComplete='off' value={searchText} onChange={( e ) => setSearchText( e.target.value )} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", "@media(max-width:900px)": { width: "100%" } }, startAdornment: <SearchIcon sx={{ marginX: "10px" }} /> }} sx={{ boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)", border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "@media(max-width:900px)": { width: "100%" } }} placeholder="Search courses..." variant="standard" type="text" /> */}
                        <CustomSearchBar border='1px solid #d3d3d3' placeholder='Search courses...' value={searchText} onChange={( e ) => setSearchText( e.target.value )} />
                        {mode === "manage" && <Button disableElevation onClick={() => navigate( `/admin/online-course/add` )} variant="contained" startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize", "@media(max-width:900px)": { width: "100%" } }} >Create</Button>}
                    </Box>
                </Box>
                {!isLoading && OnlineCourseStore.getCourses.filter( c => c.title.toLowerCase().includes( searchText.toLowerCase() ) ).length > 0 && <Box>
                    <Box display="flex" flexWrap="wrap" padding="20px" gap="20px">
                        {OnlineCourseStore.getCourses.filter( c => c.title.toLowerCase().includes( searchText.toLowerCase() ) ).map( ( onlineCourse, k ) => (
                            <OnlineCourseCard key={k} onlineCourse={onlineCourse} />
                        ) )}
                    </Box>
                </Box>}
                {!isLoading && ( OnlineCourseStore.getCourses.length === 0 || OnlineCourseStore.getCourses.filter( c => c.title.toLowerCase().includes( searchText.toLowerCase() ) ).length === 0 ) && <Box padding="20px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor="#eee">
                    <img width="200px" src={noCourses} alt="no-courses" />
                    <Typography marginY="10px" variant='subtitle2'>No online courses</Typography>
                    <Box display="flex" gap="20px" flexWrap="wrap">
                        {searchText !== "" && <Button startIcon={Icons.default.DoNotDisturb} variant='outlined' onClick={() => setSearchText( "" )} color='secondary' sx={{ textTransform: "capitalize" }}>Clear filter</Button>}
                        <Button startIcon={Icons.default.AddIcon} variant='outlined' onClick={() => navigate( "/admin/online-course/add" )} color='primary' sx={{ textTransform: "capitalize" }}>Add new course</Button>
                    </Box>
                </Box>}
                {isLoading && <DataLoadingSpinner waitingMessage="Fetching cources..." />}
            </Paper>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
}

export default OnlineCourses

