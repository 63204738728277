import { LoginOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import warningImg from '../../../assets/waiting.svg'
import alivelogo from '../../../assets/alivelogo.png'
import noConnectionImg from '../../../assets/no-connection.svg'
import serverDownImg from '../../../assets/server-down.svg'
import { useUserStore } from '../../../store/user.store'
import { observer } from 'mobx-react'
import { useSettingsStore } from '../../../store/settings.store'

const AdmissionExamsRequireAuth = observer( ( { allowedRoles = [] } ) => {

    const [loading, setLoading] = useState( true )
    const [errorCode, setErrorCode] = useState( null )


    const UserStore = useUserStore()
    const SettingsStore = useSettingsStore()

    const navigate = useNavigate()

    const navigateToSignin = () => {
        if ( UserStore?.getUser?.user_role === "admin" )
            navigate( `/admin` )
        else navigate( '/admission-exams/login' )

    }


    const handleConnectionStatusChange = () => {
        if ( !window.navigator.onLine ) {
            setErrorCode( "noInternet" )
        }
    }


    useEffect( () => {
        const getUser = async () => {
            const { netError } = await UserStore.fetchUser( { userType: "candidate" } )
            if ( netError ) {
                const internetConnected = window.navigator.onLine
                if ( !internetConnected )
                    setErrorCode( "noInternet" )
                else
                    setErrorCode( "serverConnectionError" )
            } else
                await SettingsStore.fetchSettings( { filter: 'foruse' } )
            setLoading( false )
        }
        getUser()
        window.addEventListener( 'online', handleConnectionStatusChange )
        window.addEventListener( 'offline', handleConnectionStatusChange )
        return () => {
            window.removeEventListener( 'online', handleConnectionStatusChange )
            window.removeEventListener( 'offline', handleConnectionStatusChange )
        }
    }, [UserStore, allowedRoles, SettingsStore] )

    return (
        loading ? <Box sx={{ background: `url('/imgs/bg-pattern1.png')` }} display="flex" height="100%" alignItems="center" justifyContent="center">
            <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                <img width="70px" src={alivelogo} alt="Alive" />
                <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px"> <CircularProgress size={14} /> Loading Alive...</Typography>
            </Paper>
        </Box>
            : errorCode ? <Box sx={{ background: `url('/imgs/bg-pattern1.png')` }} display="flex" height="100%" alignItems="center" justifyContent="center">
                {errorCode === "noInternet" && <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                    <img width="70px" src={noConnectionImg} alt="Alive" />
                    <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px"> Check your intenet connection</Typography>
                </Paper>}
                {errorCode === "serverConnectionError" && <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                    <img width="150px" src={serverDownImg} alt="Alive" />
                    <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px"> Server cannot be reached! try again later</Typography>
                </Paper>}
            </Box> :
                allowedRoles.includes( UserStore.getUser['user_role'].toUpperCase() )
                    ?
                    <Outlet />
                    : <Box padding="20px" height="calc(100% - 40px)" sx={{ background: `url('/imgs/bg-pattern1.png')` }} display="flex" alignItems="center" justifyContent="center">
                        <Paper sx={{ display: "flex", padding: "20px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", flexDirection: "column", justifyContent: "center", gap: "20px", alignItems: "center", borderRadius: "5px" }}>
                            <img width="250px" src={warningImg} alt="session ended" />
                            <Typography variant='subtitle2' textAlign="center" >
                                You are not allowed to access this page. this pages if only available for <em>{allowedRoles.join( ", " )}.</em>
                            </Typography>
                            {!allowedRoles.includes( 'CANDIDATE' ) && <Button variant="outlined" startIcon={<LoginOutlined />} color="primary" onClick={navigateToSignin} sx={{ textTransform: "capitalize" }}>Sign in</Button>}
                        </Paper>
                    </Box>
    )
} )

export default AdmissionExamsRequireAuth
