import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Icons } from '../../../utils/utilities'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { Warning } from '@mui/icons-material'

const MultipleChecklistDeleteDialog = ( { setState, state, items, selectedItems, getData, setSelectedItems } ) => {

    const { palette } = useTheme()

    const [response, setResponse] = useState( null )
    const [deleting, setDeleting] = useState( false )
    const [completed, setCompleted] = useState( false )

    const confirmAction = async () => {
        try {
            setDeleting( true )
            const { data: { success, message, data } } = await api.deleteChecklistItem( selectedItems )
            if ( !success ) {
                toast( message || "Something went wrong while deleting checklist items" )
            } else {
                setResponse( data )
                setCompleted( true )
            }
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong while deleting checklist items!" )
        } finally {
            setDeleting( false )
        }
    }

    const handleDismiss = async () => {
        setSelectedItems( [] )
        await getData()
        setCompleted( false )
        setResponse( null )
        setState( false )
    }

    return (
        <Dialog PaperProps={{ sx: { width: "85%", maxWidth: "650px" } }} open={state}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3", justifyContent: "space-between", display: "flex", alignItems: "center", gap: "20px" }}>
                Are you sure you want to delete these items?
                {!completed && <IconButton onClick={() => setState( false )}>
                    {Icons.default.CloseIcon}
                </IconButton>}
            </DialogTitle>
            <DialogContent sx={{ padding: "0" }}>
                <Box>
                    <Typography padding="10px 20px" bgcolor={palette.error.light} gap="10px" display="flex" alignItems="center" color="white" fontSize="12px" fontWeight="500">
                        <Warning fontSize='small' />
                        This action cannot be undone.
                    </Typography>
                </Box>
                <Box padding="20px">
                    {selectedItems.map( ( item, index ) => {
                        const itemName = items.find( i => i.id === item ).input_label
                        return itemName ? <Box display="flex" key={item} gap="10px" alignItems="center">
                            <Typography sx={{ fontWeight: "bold" }} variant='subtitle2'>
                                {index + 1}. {itemName}
                            </Typography>
                            {response && <Typography fontSize="12px" padding="5px" borderRadius="5px" variant='subtitle2' border={`1px solid ${response[index] ? palette.success.main : palette.error.main}`} color={response[index] ? "success" : "error"}>{response[index] ? "Deleted" : "Data exists"} </Typography>}
                        </Box>
                            : ""
                    } )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid #d3d3d3", bgcolor: palette.rowHover }}>
                {!completed && <Button variant='contained' disabled={deleting} color='error' sx={{ textTransform: "capitalize" }} startIcon={deleting ? <CircularProgress size={14} /> : Icons.default.DeleteIcon} onClick={confirmAction} disableElevation>{deleting ? "Deleting items..." : "Delete"}</Button>}
                {completed && <Button variant='contained' color='success' sx={{ textTransform: "capitalize" }} startIcon={Icons.default.CheckCircleIcon} onClick={handleDismiss} disableElevation>Okay</Button>}
            </DialogActions>
        </Dialog>
    )
}

export default MultipleChecklistDeleteDialog
