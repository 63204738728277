import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, FormControl, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Icons, latexDelimiters } from '../../utils/utilities'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { convert } from 'html-to-text'
import CustomSwitch from '../common/CustomSwitch'
import { useQuizAndQuestionbankStore } from '../../store/quiz.store'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { RefreshOutlined } from '@mui/icons-material'
import Latex from 'react-latex-next'
import CustomWysiwyg from '../common/CustomWysiwyg'


const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
        ],
        // handlers: {
        //     image: async () => await handleFileInput( 'image' ),
        //     video: async () => await handleFileInput( 'video' ),
        // },
    },
}

const AddQuestionDialog = observer( ( { setSelectedTopicId, setAddTopicDialogState, title = "Add question", state, setState, fromComponent, bankId, topicId } ) => {

    const [adding, setAdding] = useState( false )
    const [optionAddedFieldValue, setOptionAddedFieldValue] = useState( "" )
    const [optionAdderSwitchState, setOptionAdderSwitchState] = useState( false )

    const QuizQuestionbankStore = useQuizAndQuestionbankStore()
    const { bank_id } = useParams()

    const { palette, border } = useTheme()

    const initialValues = {
        question: "",
        marks: "",
        questionType: "MCQ",
        selectionType: "single",
        options: [],
        answersStatus: [],
        correctAnswer: "",
        topic_id: topicId || "-",
        question_bank_id: +bank_id
    }

    const validationSchema = Yup.object().shape( {
        question: Yup.string().required( "Question is required!" ),
        marks: Yup.number().required( "Marks is required." ).test( "VALID_MARKS", "Enter a valid marks", ( val, { parent } ) => {
            console.log( val, !isNaN( parseInt( val ) ), parseInt( val ) > 0 )
            if ( val && !isNaN( parseInt( val ) ) && parseInt( val ) > 0 )
                return true
            return false
        } ),
        options: Yup.mixed().required( "Options are required" ).test( "MINIMUM_TWO_OPTIONS", "A question should has minimum of 2 options.", ( val ) => {
            if ( val.length > 1 )
                return true
            return false
        } ),
        answersStatus: Yup.mixed().test( "ATLEAST_TWO_CORRECT_ANSWERS", "Please select at least two correct answers.", ( val, { parent } ) => {
            if ( parent.selectionType === "single" ) return true
            if ( val.filter( i => i === true ).length >= 2 )
                return true
            return false
        } ),
        correctAnswer: Yup.number().test( "CORRECT_ANSWER", "Please select a correct answer.", ( val, { parent } ) => {
            if ( parent.selectionType !== "single" ) return true
            if ( val >= 0 && val < parent.options.length )
                return true
            return false
        } ),
        topic_id: Yup.string().test( "CHECK_FOR_TOPIC", "Please select a topic.", ( val, { parent } ) => {
            if ( fromComponent !== "questionbank" ) return true
            if ( val !== "-" )
                return true
            return false
        } )
    } )

    const handleEditorValueChange = ( val, setValue, prevVal ) => {
        setValue( 'question', val )
    }

    const handleSelectionTypeChange = ( val, setValue ) => {
        setValue( 'correctAnswer', "" )
        setValue( 'selectionType', val )
    }

    const handleCorrectAnswerChange = ( index, values, val, setValue ) => {
        if ( values.selectionType === 'single' )
            setValue( 'correctAnswer', index )
        else {
            let newStatus = [...values.answersStatus]
            newStatus[index] = val
            setValue( 'answersStatus', newStatus )
        }
    }

    const addOption = ( options, correctAnswers, type, setValue ) => {
        if ( optionAddedFieldValue && ( optionAddedFieldValue?.trim() !== "" && convert( optionAddedFieldValue?.trim() ) !== "" ) ) {
            const newOptions = [...options, { option_text: optionAddedFieldValue }]
            if ( type === 'single' && optionAdderSwitchState ) {
                setValue( 'correctAnswer', newOptions.length - 1 )
            }
            const newStatuses = [...correctAnswers, optionAdderSwitchState]
            setValue( 'answersStatus', newStatuses )

            setValue( 'options', newOptions )
            setOptionAddedFieldValue( "" )
            setOptionAdderSwitchState( false )
        }
    }

    const removeOption = ( index, values, setValue ) => {
        let newOptions = [...values.options]
        let newStatus = [...values.answersStatus]
        newStatus.splice( index, 1 )
        newOptions.splice( index, 1 )
        setValue( 'answersStatus', newStatus )
        setValue( 'options', newOptions )
        if ( values.correctAnswer === index )
            setValue( 'correctAnswer', "" )
    }

    const handleClose = ( resetForm ) => {
        resetForm()
        setSelectedTopicId( null )
        setState( false )
    }

    const fetchUpdatedList = async ( e ) => {
        e.target.style.transition = "250ms"
        e.target.style.transform = "rotate(360deg)"
    }

    const addQuestion = async ( values, { resetForm } ) => {
        setAdding( true )
        if ( fromComponent === "questionbank" ) {
            if ( await QuizQuestionbankStore.addQuestions( bank_id, topicId, [values] ) )
                handleClose( resetForm )
        }
        setAdding( false )
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "950px", background: palette.form.formCardBg } }} open={state}>
            <Formik onSubmit={addQuestion} initialValues={initialValues} validationSchema={validationSchema}>
                {( { values, setFieldValue, resetForm } ) => (
                    <Box>
                        <Box display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="20px">
                            <Typography variant="h6" fontSize="18px">{title}</Typography>
                            <IconButton onClick={() => handleClose( resetForm )} size="small">{Icons.default.CloseIcon}</IconButton>
                        </Box>
                        <DialogContent sx={{ paddingTop: "0", overflow: "auto" }}>
                            <Form>
                                <Box border={border[1]} padding="20px" borderRadius="5px" bgcolor={palette.contentBg} >
                                    {fromComponent === "questionbank" && <FormControl margin="dense" fullWidth>
                                        <Typography variant='subtitle2' color={palette.labelColor}>
                                            Question bank topic *
                                            <Tooltip title="Refresh list" placement='top'>
                                                <IconButton onClick={fetchUpdatedList} sx={{ marginLeft: "10px" }} size='small'>
                                                    <RefreshOutlined sx={{ fontSize: "18px" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                        <Box>
                                            <Field disabled={( !QuizQuestionbankStore?.getQuestionBank?.topics || QuizQuestionbankStore?.getQuestionBank?.topics?.length === 0 )} fullWidth size="small" sx={{ color: values.topic_id === "-" ? "#cbcbcb" : "inherit" }} as={Select} name="topic_id" value={values.topic_id}>
                                                <MenuItem dense disabled value={"-"}>Select topic Eg: Chapter 1.1</MenuItem>
                                                {QuizQuestionbankStore?.getQuestionBank?.topics?.map( topic => (
                                                    <MenuItem sx={{ fontSize: "14px" }} dense key={topic.id} value={topic.id}>{topic.name}</MenuItem>
                                                ) )}
                                            </Field>
                                        </Box>
                                        <Box fontSize="12px" color="GrayText">
                                            {( !QuizQuestionbankStore?.getQuestionBank?.topics || QuizQuestionbankStore?.getQuestionBank?.topics?.length === 0 ) ?
                                                "This question bank does not have any topics, please add topics to insert questions. " :
                                                "Need a new topic? fret not. "
                                            }
                                            <Typography onClick={() => setAddTopicDialogState( true )} display="inline" color="secondary" fontSize="12px" fontStyle="italic" fontWeight="500" sx={{ cursor: "pointer", textDecoration: "underline" }} >Add one here</Typography>
                                        </Box>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='topic_id' /></Typography>
                                    </FormControl>}
                                    <FormControl margin="dense" fullWidth>
                                        <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question Text *</Typography>
                                        <Box>
                                            <Box bgcolor={palette.form.inputBg}>
                                                <CustomWysiwyg
                                                    placeholder={'Type here...'}
                                                    value={values.question}
                                                    onChange={val =>
                                                        handleEditorValueChange( val, setFieldValue, values.question )
                                                    }
                                                />
                                            </Box>
                                            <Typography margin="5px 0 0 2px" fontSize="12px" color='textSecondary'>To Add mathematical formula, use latex, Eg: <strong> <em>${"{LATEX}"}$</em> </strong></Typography>
                                            {values.question?.match( /\$\$?(.)+\$\$?/ ) && <Box bgcolor={palette.greyedOut} marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} sx={{ borderStyle: "dotted", borderWidth: "2px" }} borderRadius="10px">
                                                <Latex delimiters={latexDelimiters} >{values.question}</Latex>
                                            </Box>}
                                        </Box>
                                        <Typography fontSize="12px" color='errorMessage.main'>
                                            <ErrorMessage name='question' />{' '}
                                        </Typography>

                                    </FormControl>
                                    <FormControl margin="dense" fullWidth>
                                        <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question marks *</Typography>
                                        <Field size="small" as={TextField} placeholder="Specify question's points" type="number" name="marks" onWheel={e => { e.target.blur() }} />
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='marks' />{' '}</Typography>
                                    </FormControl>
                                    <FormControl margin="dense" fullWidth>
                                        <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Selection type</Typography>
                                        <RadioGroup sx={{ marginTop: "-5px" }} name='selectionType' value={values.selectionType} color='primaryDark' onChange={( e ) => ( handleSelectionTypeChange( e.target.value, setFieldValue ) )} row>
                                            <FormControlLabel value="single" control={<Radio color='primaryDark' />} label="Single" />
                                            <FormControlLabel value="multiple" control={<Radio color='primaryDark' />} label="Multiple" />
                                        </RadioGroup>
                                    </FormControl>
                                    {values.options.length > 0 && <Box>
                                        <Typography gutterBottom variant="subtitle2">Options</Typography>
                                        {values.options.map( ( option, optionIndex ) => (
                                            <Box marginBottom="2px" key={optionIndex} border="1px solid #d3d3d3" borderRadius="5px" display="flex" gap="5px" alignItems="center">
                                                <Tooltip placement='right' title={( optionIndex === values.correctAnswer || values.answersStatus[optionIndex] ) ? values.selectionType === "single" ? "Marked as correct" : "mark as incorrect" : "Mark as correct answer"}>
                                                    <span>
                                                        {values.selectionType !== "single" && <Checkbox onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={values.answersStatus[optionIndex] || false} />}
                                                        {values.selectionType === "single" && <Radio onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={optionIndex === values.correctAnswer} />}
                                                    </span>
                                                </Tooltip>
                                                <Box flexGrow={1} className='discussion-wysiwyg-container' >
                                                    <Latex delimiters={latexDelimiters}>{option.option_text}</Latex>
                                                </Box>
                                                <Box>
                                                    <Tooltip title="Remove option">
                                                        <IconButton size="small" color="errorMessage.main" onClick={() => removeOption( optionIndex, values, setFieldValue )}>{Icons.default.RemoveIcon}</IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        ) )}
                                    </Box>}
                                    <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='options' /></Typography>
                                    <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='correctAnswer' /></Typography>
                                    <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='answersStatus' /></Typography>
                                    <Box marginTop="10px" border={border[1]} display="flex" gap="10px" flexDirection="column" borderRadius="5px">
                                        <Typography padding="20px 20px 0 20px" variant='subtitle1' gutterBottom fontWeight='500'>Add option</Typography>
                                        <Box padding="0 20px 20px 20px" display="flex" alignItems="flex-start" gap="10px" flexDirection="column">
                                            <Box bgcolor={palette.form.inputBg} color={palette.common.font} width="100%">
                                                <CustomWysiwyg height='45px' placeholder='Type the option text here...' modules={modules} value={optionAddedFieldValue} onChange={setOptionAddedFieldValue} />
                                            </Box>
                                            <Typography fontSize="12px" color='textSecondary'>To Add mathematical formula, use latex, Eg: <strong> <em>${"{LATEX}"}$</em> </strong></Typography>
                                            {optionAddedFieldValue?.match( /\$\$?(.)+\$\$?/ ) && <Box bgcolor={palette.greyedOut} marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} sx={{ borderStyle: "dotted", borderWidth: "2px" }} borderRadius="10px">
                                                <Latex delimiters={latexDelimiters} >{optionAddedFieldValue}</Latex>
                                            </Box>}
                                            <FormControlLabel sx={{ marginLeft: "0" }} labelPlacement='start' label="Correct answer?" control={<CustomSwitch color='primaryDark' checked={optionAdderSwitchState} onChange={e => setOptionAdderSwitchState( e.target.checked )} />} />
                                            <Button sx={{ background: "white", textTransform: "capitalize", '&:hover': { background: "white" } }} onClick={() => addOption( values.options, values.answersStatus, values.selectionType, setFieldValue )} variant="outlined" type="button" startIcon={Icons.default.AddIcon} >Add Option</Button>
                                        </Box>
                                    </Box>
                                    <Box marginTop="40px" display="flex" gap="10px" flexWrap="wrap">
                                        <Button type="submit" disabled={adding} startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation >Add Question</Button>
                                        <Button disableElevation onClick={() => handleClose( resetForm )} sx={{ textTransform: "capitalize" }} variant="contained" color="error" >Cancel</Button>
                                    </Box>
                                </Box>
                            </Form>
                        </DialogContent>
                    </Box>
                )}
            </Formik>
        </Dialog>
    )
} )

export default AddQuestionDialog
