import { Box, Button, CircularProgress, Dialog, DialogContent, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Icons } from '../../../utils/utilities'
import { useParams } from 'react-router-dom'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'

const CreateTopicDialog = ( { setState, state = false, bankId } ) => {

    const [fieldValue, setFieldValue] = useState( "" )
    const [adding, setAdding] = useState( false )
    const { bank_id = bankId } = useParams()

    const { palette, border } = useTheme()

    const QuizStore = useQuizAndQuestionbankStore()

    const handleClose = () => {
        setFieldValue( "" )
        setState( false )
    }

    const createTopic = async ( e ) => {
        if ( ( e.type === "keydown" && e.key === "Enter" ) || e.type === "click" ) {
            setAdding( true )
            if ( fieldValue && fieldValue?.trim().length > 0 ) {
                const success = await QuizStore.createTopic( bank_id, { question_bank_id: bank_id, name: fieldValue } )
                if ( success ) {
                    setFieldValue( "" )
                    setState( false )
                }
            }
            setAdding( false )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "60%", minWidth: "180px", maxWidth: "450px", background: palette.form.formCardBg } }} open={state}>
            <DialogContent>
                <Typography variant="h6" flexGrow={1}>Create a new topic</Typography>
                <Box borderRadius="5px" border={border[1]} marginTop="15px" padding="20px" bgcolor={palette.form.formBg}>
                    <Box>
                        <Typography color={palette.labelColor} gutterBottom variant="subtitle2">Topic name *</Typography>
                        <TextField size="small" onKeyDown={createTopic} value={fieldValue} onChange={e => setFieldValue( e.target.value )} autoFocus fullWidth placeholder="Eg: Introduction" />
                    </Box>
                    <Box marginTop="30px" display="flex" alignItems="center" gap="20px">
                        <Button disabled={adding} onClick={createTopic} startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} variant="contained" disableElevation >Add Topic</Button>
                        <Button onClick={handleClose} variant="contained" disableElevation color="error" >Cancel</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default CreateTopicDialog
