import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Icons } from '../../../utils/utilities'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useUserStore } from '../../../store/user.store'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'

const AddQuestionBank = ( { state, setState, subject = null } ) => {

    const [loading, setLoading] = useState( true )
    const UserStore = useUserStore()
    const QuizStore = useQuizAndQuestionbankStore()

    const { palette, border } = useTheme()

    const initialValues = {
        name: '',
        subject: subject || '-',
    }

    const validationSchema = Yup.object().shape( {
        name: Yup.string().required( "Question bank name is required!" ),
        subject: Yup.mixed().required( 'Subject is required' ).notOneOf( ["-"], "Please select a question bank subject" )
    } )

    const addQuestionBank = async ( values, { resetForm } ) => {
        const success = await QuizStore.addQuestionBank( values, subject?.subject_id )
        if ( success ) {
            resetForm()
            setState( false )
        }
    }

    useEffect( () => {
        const getData = async () => {
            await UserStore.fetchUserSubjects()
            setLoading( false )
        }
        getData()
    }, [UserStore] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "650px", background: palette.form.formCardBg } }} open={state}>
            <DialogTitle sx={{ padding: "20px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                New Question bank
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Formik onSubmit={addQuestionBank} initialValues={initialValues} validationSchema={validationSchema}>
                        {( { values, setFieldValue, resetForm } ) => (
                            <Form>
                                <Box borderRadius="5px" border={border[1]} bgcolor={palette.form.formBg} padding="20px" >
                                    <FormControl fullWidth>
                                        <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>Question bank name *</Typography>
                                        <Field size="small" sx={{ fontSize: "14px" }} InputProps={{ sx: { fontSize: "14px" } }} placeholder="Eg: Data Structures question bank" as={TextField} name="name" type="text" />
                                        <Typography fontSize="12px" color="error"> <ErrorMessage name='name' /> </Typography>
                                    </FormControl>
                                    <FormControl margin="normal" fullWidth>
                                        <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>Subject *</Typography>
                                        <Field
                                            size="small"
                                            as={Select}
                                            onChange={( e ) => {
                                                setFieldValue( 'subject', UserStore.getUserSubjects.find( s => s.subject_id === e.target.value ) )
                                            }}
                                            disabled={loading || Boolean( subject )}
                                            placeholder="Select the question bank related subject"
                                            value={values?.subject?.subject_id || "-"}
                                            sx={{ fontSize: "14px", color: values.subject === "-" ? '#cbcbcb' : "#000" }}
                                            name='subject'
                                        >
                                            <MenuItem value="-" dense sx={{ color: "GrayText", fontSize: "14px", paddingY: "5px" }}>{loading ? <DataLoadingSpinner waitingMessage="Loading subjects..." padding='0' size={14} /> : "Eg: Data structures "}</MenuItem>
                                            {UserStore.getUserSubjects.map( ( sub, index ) => (
                                                <MenuItem value={sub.subject_id} key={sub.subject_id} dense sx={{ fontSize: "14px", paddingY: "5px" }}>{sub.subject_name}</MenuItem>
                                            ) )}
                                        </Field>
                                        <Typography fontSize="12px" color="error"> <ErrorMessage name='subject' /> </Typography>
                                    </FormControl>

                                    <Box display="flex" marginTop="30px" gap="20px" flexWrap="wrap" alignItems="center">
                                        <Button variant="contained" disableElevation type="submit" startIcon={Icons.default.AddIcon}>Add Question bank</Button>
                                        <Button onClick={() => { setState( false ); resetForm() }} variant="contained" disableElevation color="error" >Cancel</Button>
                                    </Box>
                                </Box>

                            </Form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default AddQuestionBank
