import { Box, Table, TableRow, TableBody, TableCell, TableContainer, Typography, useTheme, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { formatDateTimeForDisplay } from '../../utils/exam-utilities'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TableHeader from '../common/TableHeader'

let columns = [
    { id: 0, name: "Sl.no", padding: "10px 20px", },
    { id: 1, name: "Title", },
    { id: 3, name: "Details", },
    { id: 2, name: "Exam Date", padding: "10px 40px 10px 20px" },
]


export default function ListView( { exams, userRole, isAuditing } ) {

    const [tableColumns, setTableColumns] = useState( columns )

    const [searchParams, setSearchParams] = useSearchParams( { searchText: "" } )
    const searchText = searchParams.get( 'searchText' )

    const navigate = useNavigate()
    const { palette, border, table } = useTheme()

    const removeFilter = () => {
        setSearchParams( prev => {
            prev.set( 'searchText', "" )
            return prev
        }, { replace: true } )
    }

    useEffect( () => {
        if ( userRole !== "STUDENT" ) {
            setTableColumns( [
                { id: 0, name: "Sl.no", padding: "10px 20px", },
                { id: 1, name: "Title", },
                { id: 3, name: "Details", },
                { id: 2, name: "Exam Date" },
                { id: 5, name: "Status", align: "right", padding: "10px 40px 10px 20px" }] )
        }
    }, [userRole] )

    return (
        <Box display="flex" bgcolor={palette.contentBg} flexGrow={1} border={border[1]} borderRadius="5px" flexDirection="column" overflow="auto" >
            {exams.length > 0 && exams.filter( item => item.exam_name.toLowerCase().includes( searchText ) ).length !== 0 &&
                <TableContainer>
                    <Table stickyHeader>
                        <TableHeader columns={tableColumns} />
                        <TableBody>
                            {exams.filter( item => item.exam_name.toLowerCase().includes( searchText ) ).map( ( exam, index ) => (
                                <TableRow className='table-row' key={exam.exam_id} onClick={() => { userRole === "STUDENT" ? navigate( `/student/exams/${exam.exam_id}` ) : navigate( `/faculty/exams/${exam.exam_id}` ) }} sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }}>
                                    <TableCell sx={{ padding: columns[0].padding || "10px 20px" }} >{index + 1}</TableCell>
                                    <TableCell sx={{ color: table.color, padding: columns[1].padding || "10px 20px", "&:hover": { textDecoration: "underlined !important" } }} >
                                        <Typography sx={{ textDecoration: "underline !important" }} title={exam.exam_name} maxWidth="150px" noWrap fontSize="14px" fontWeight='bold'>{exam.exam_name}</Typography>
                                    </TableCell>
                                    <TableCell style={{ color: table.color, padding: columns[2].padding || "10px 20px", fontSize: "12px", textTransform: "capitalize" }}><strong>{`${exam.exam_description.substring( 0, 20 )}${exam.exam_description.length > 20 ? "..." : ""}`}</strong></TableCell>
                                    <TableCell style={{ color: table.color, padding: columns.length === 4 ? columns[3].padding || "10px 20px" : "10px 20px", fontSize: "12px", textTransform: "uppercase" }}><strong>{formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )}</strong></TableCell>
                                    {userRole !== "STUDENT" && <TableCell key={4} align="left" sx={{ padding: columns[4]?.padding || "10px 40px 10px 20px", minWidth: "50px", width: "50px", background: "transparent", color: exam.exam_status === 'Published' ? "green" : "gray", fontSize: "12px" }} ><strong>{exam.exam_status.toUpperCase()}</strong></TableCell>}
                                </TableRow>
                            ) )}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {exams.filter( item => item.exam_name.toLowerCase().includes( searchText ) ).length === 0 && <Box flexGrow={1} display="flex" flexDirection="column" gap="20px" alignItems="center" justifyContent="center" borderRadius="5px" bgcolor={palette.contentBg}>
                <Typography variant='subtitle2'>No results found for <strong>{searchText}</strong></Typography>
                <Button onClick={removeFilter} variant="outlined" color="secondary" sx={{ textTransform: "capitalize" }}>Remove filter</Button>
            </Box>}
        </Box >
    )
}
