import { Box, Button, Collapse, Icon, Paper, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { Icons } from '../../../utils/utilities'
import { useNavigate, useSearchParams } from 'react-router-dom'
import noPrcoteeImg from '../../../assets/no-proctees.svg'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import CustomSearchBar from '../../../components/common/CustomSearchBar'
import { useUserStore } from '../../../store/user.store'

const Proctees = () => {

    const [loading, setLoading] = useState( true )
    const [academicYears, setAcademicYears] = useState( null )
    const [openedAcademicYear, setOpenedAcademicYear] = useState( null )

    const [searchParams, setSearchParams] = useSearchParams( { searchText: '' } )
    const searchText = searchParams.get( "searchText" )
    const UserStore = useUserStore()

    const setSearchText = val => {
        setSearchParams( prev => {
            prev.set( 'searchText', val )
            return prev
        }, { replace: true } )
    }

    const navigate = useNavigate()

    const { palette } = useTheme()

    useEffect( () => {
        const getData = async () => {
            try {
                const { data } = await api.fetchProctorAcademicYear()
                setAcademicYears( data.data )
            } catch ( err ) {
                if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                    UserStore.setERPSessionState( "ended" )
                } else
                    toast( err?.response?.data?.message || "Something went wrong! try again" )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [UserStore] )

    return (
        <Box flexGrow={1} display="flex" flexDirection="column" padding="20px">
            <Paper sx={{ flexGrow: 1, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                <Box
                    position="relative"
                    sx={{
                        background: `url('/imgs/proctees-header-bg.jpg')`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                    padding="20px">

                    <Box height="100%" position="absolute" sx={{ zIndex: 0, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 150.03%)`, }}>
                    </Box>

                    <Box position="relative" sx={{ zIndex: "10" }} >
                        <Typography color="white" variant='h5'>Proctoring</Typography>
                        <Typography variant='body2' color="white">Manage yout proctees here.</Typography>
                    </Box>
                </Box>
                {!loading && academicYears && Object.keys( academicYears ).length > 0 && <Box display="flex" gap="5px" padding="10px" flexDirection="column">
                    {Object.keys( academicYears ).map( item => (
                        <Box borderRadius="5px" overflow="hidden" border={`1px solid ${openedAcademicYear === item ? palette.secondary.dark : palette.borderColor}`} key={item}>
                            <Box color={palette.secondary.main} bgcolor={palette.secondary.dark} onClick={() => { setOpenedAcademicYear( openedAcademicYear === item ? null : item ); if ( openedAcademicYear !== item ) setSearchText( "" ) }} borderBottom="1px solid #d3d3d3" sx={{ cursor: "pointer", '&:hover': { background: palette.secondary.dark } }}>
                                <Box padding="10px" color="white" sx={{ "&:hover": { background: openedAcademicYear !== item && palette.rowHover, color: "white" } }} display="flex" gap="20px" alignItems="center">
                                    <Typography fontWeight="800" color="inherit" variant="subtitle2">
                                        {item}
                                    </Typography>
                                    {openedAcademicYear === item ? <Icon sx={{ borderRadius: "5px", background: palette.secondary.light + "55", color: "white" }}>{Icons.default.KeyboardArrowUp}</Icon> : <Icon sx={{ borderRadius: "5px", background: palette.secondary.light + "55", color: "white" }}>{Icons.default.KeyboardArrowDownIcon}</Icon>}
                                </Box>
                            </Box>
                            <Collapse in={openedAcademicYear === item}>
                                <Box padding="5px 10px">
                                    <CustomSearchBar value={searchText} onChange={( e ) => setSearchText( e.target.value )} width='100%' border={`1px solid ${palette.secondary.light}`} placeholder="Search student..." />
                                </Box>
                                <Box maxHeight="250px" className="custom-scrollbar" overflow="auto">
                                    {academicYears[item].filter( item => item.student_name?.toLowerCase().includes( searchText.toLowerCase() ) || item.auid.toLowerCase().includes( searchText.toLowerCase() ) ).map( student => (
                                        <Box sx={{ "&:hover": { background: palette.rowHover } }} padding="0 20px" key={student.auid}>
                                            <Box borderBottom={"1px solid #d3d3d3"} onClick={() => navigate( `/faculty/proctoring/proctee/${student.auid}` )}>
                                                <Typography textTransform="capitalize" sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }} variant="subtitle2" color="textSecondary" padding="10px 5px">
                                                    {`${student.student_name?.toLowerCase()} (${student.auid}) year: ${student.current_year} sem: ${student.current_sem}`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) )}
                                    {academicYears[item].filter( item => item.student_name?.toLowerCase().includes( searchText.toLowerCase() ) || item.auid.toLowerCase().includes( searchText.toLowerCase() ) ).length === 0 && searchText !== "" &&
                                        <Box padding="40px 20px" display="flex" justifyContent="center">
                                            <Button size="small" onClick={() => setSearchText( "" )} sx={{ textTransform: "capitalize" }} variant="outlined" color="error" startIcon={Icons.default.DoNotDisturb}>Clear search filter</Button>
                                        </Box>
                                    }
                                </Box>
                            </Collapse>
                        </Box>
                    ) )}
                </Box>}
                {!loading && academicYears && Object.keys( academicYears ).length === 0 && <Box bgcolor="#eee" display="flex" flexDirection="column" gap="20px" justifyContent="center" alignItems="center" padding="20px">
                    <img width="200px" src={noPrcoteeImg} alt="No proctees assigned" />
                    <Typography variant='subtitle2' color="GrayText">No student is assigned to you!</Typography>
                </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading data..." />}
            </Paper>
        </Box>
    )
}

export default Proctees
