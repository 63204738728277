import { Box, Dialog, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReplicateQuizForm from './ReplicateQuizForm'
import NewQuizForClassForm from './NewQuizFromClassForm'
import { useUserStore } from '../../../../store/user.store'
import { Icons } from '../../../../utils/utilities'
import ManageNewQuestionBank from './ManageNewQuestionBank'
import { observer } from 'mobx-react'

const AddQuizFromClassDialog = observer( ( { fetchQuizzesInstances, setCreateMode, room, state, setState } ) => {

    const [addMode, setAddMode] = useState( false )
    const [loadingSubjects, setLoadingSubjects] = useState( true )
    const [newBankId, setNewBankId] = useState( 57 )
    const [questionBankMode, setQuestionBankMode] = useState( 'select' )

    const UserStore = useUserStore()

    const handleClose = async () => {
        setState( null )
        await fetchQuizzesInstances()
    }

    useEffect( () => {
        const getSubjects = async () => {
            try {
                setLoadingSubjects( true )
                await UserStore.fetchUserSubjects()
            } catch ( err ) {
            } finally {
                setLoadingSubjects( false )
            }
        }

        getSubjects()
    }, [UserStore] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "2000px" } }} open={state} >
            <Box padding="20px" display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <Typography variant="h6">Add quiz to the class</Typography>
                    <Typography variant="body2">{room.course_short_name}, {room.branch_name}, {room.batch_short_name !== "NA" ? <span>Batch: <em>{room.batch_short_name}</em></span> : <span>Section: <em>{room.section_short_name}</em></span>}</Typography>
                </Box>
                <IconButton size="small" onClick={() => handleClose()} >{Icons.default.CloseIcon}</IconButton>
            </Box>
            <Box display="grid" gridTemplateColumns={questionBankMode !== 'managequestions' ? "1fr" : "1fr 1fr"} >
                {!addMode ? <ReplicateQuizForm fetchQuizzesInstances={fetchQuizzesInstances} handleClose={handleClose} room={room} setAddMode={setAddMode} addMode={addMode} /> :
                    <NewQuizForClassForm fetchQuizzesInstances={fetchQuizzesInstances} setNewBankId={setNewBankId} questionBankMode={questionBankMode} setQuestionBankMode={setQuestionBankMode} loadingSubjects={loadingSubjects} handleClose={handleClose} subject={UserStore.getUserSubjects?.find( sub => sub.subject_id === room.subject_id )} room={room} setAddMode={setAddMode} addMode={addMode} />}
                {questionBankMode === 'managequestions' && newBankId && <ManageNewQuestionBank setQuestionBankMode={setQuestionBankMode} bank_id={newBankId} />}
            </Box>
        </Dialog>
    )
} )

export default AddQuizFromClassDialog
