import { Box, Button, Collapse, Typography, useTheme } from '@mui/material'
import { Icons, flatLightColors, latexDelimiters } from '../../../utils/utilities'
import React, { useState } from 'react'
import ConfirmDialog from '../../common/ConfirmDialog'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import Latex from 'react-latex-next'

const colorListLength = flatLightColors.length

const optionsList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

const QuestionBlock = ( { question, index, colors = false } ) => {

    const [open, setOpen] = useState( false )
    const [deleting, setDeleting] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )

    const QuizQuestionbankStore = useQuizAndQuestionbankStore()

    const { palette } = useTheme()

    const deleteQuestion = async () => {
        setDeleting( true )
        await QuizQuestionbankStore.deleteQuestion( question.question_bank_id, question.topic_id, question.id )
        setDeleteDialogState( false )
        setDeleting( false )
    }

    return (
        <Box bgcolor={colors ? flatLightColors[+question.id % colorListLength] : palette.greyedOut} border={open ? "1px solid rgba(0,0,0,0.1)" : "1px solid #fff"} sx={{ "&:hover": { border: "1px solid rgba(0,0,0,0.1)" } }} borderRadius="5px">

            <ConfirmDialog waiting={deleting} status={deleteDialogState} cancelAction={() => setDeleteDialogState( false )} confirmAction={deleteQuestion} actionName="Yes, Delete it!" message={`Do you really want to delete this question?`} />

            <Box sx={{ cursor: "pointer" }} display="flex" gap="10px" alignItems="center">
                <Box flexGrow={1} onClick={() => setOpen( !open )} display="flex" gap="10px" alignItems="flex-start" padding="10px">
                    <Typography variant="subtitle2">{index + 1}</Typography>
                    <Box flexGrow={1}>
                        <Typography marginBottom="4px" fontSize="10px" color="secondary">{question.question_type}</Typography>
                        <Box className='questionbank-wysiwyg-container' fontWeight="bold" marginTop="-5px" fontSize="14px">
                            <Latex delimiters={latexDelimiters}>{question.question_text}</Latex>
                        </Box>
                    </Box>
                </Box>
                <Button color='greyed' onClick={() => setDeleteDialogState( true )} startIcon={Icons.default.RemoveCircleIcon} sx={{ textTransform: "capitalize" }}>
                    remove
                </Button>
            </Box>
            <Collapse in={open}>
                <Box padding="10px" borderTop="1px solid #d3d3d3">
                    {question.options.map( ( option, optionIndex ) => {
                        const isCorrect = question.selection_type === "single" ? +question.correct_answer === optionIndex : question.options_status[optionIndex]
                        return (
                            <Box display="flex" marginBottom="2px" alignItems="flex-start" gap="10px" key={optionIndex}>
                                <Typography marginBottom="2px" variant="subtitle2" fontSize="12px" sx={{ aspectRatio: 1 / 1 }} display="flex" alignItems="center" justifyContent="center" width='20px' borderRadius="50%" margin="0" bgcolor={isCorrect && "success.light"} fontWeight={isCorrect ? "bolder" : "regular"} color={isCorrect ? "white" : "textSecondary"}>{optionsList[optionIndex]}</Typography>
                                <Box variant="subtitle2" className='questionbank-wysiwyg-container'>
                                    <Latex delimiters={latexDelimiters}>{option.option_text}</Latex>
                                </Box>
                            </Box>
                        )
                    } )}
                </Box>
            </Collapse>
        </Box >
    )
}


export default QuestionBlock
