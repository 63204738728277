import { Box, Button, CircularProgress, Dialog, FormControl, FormControlLabel, IconButton, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Icons } from '../../utils/utilities'
import { Percent } from '@mui/icons-material'
import CustomSwitch from '../common/CustomSwitch'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { useAdmissionExamStore } from '../../store/admission-exam.store'

const CutoffEditDialog = ( { state, setState, currentExamCutoffs, sections } ) => {

    const [saving, setSaving] = useState( false )
    const [cutoffs, setCutoffs] = useState( currentExamCutoffs )
    const [sectionsCutoffEnabled, setSectionsCutoffEnabled] = useState( !Boolean( currentExamCutoffs.totalCutoff ) )

    const AdmissionExamStore = useAdmissionExamStore()

    const { palette, border } = useTheme()

    const { id } = useParams()

    const handleTotalCutoffChange = e => {
        const newValue = { totalCutoff: e.target.value }
        setCutoffs( newValue )
    }

    const handleSectionCutoffChange = ( val, section ) => {
        setCutoffs( prev => {
            const newVal = { ...prev }
            newVal[section] = +val
            return newVal
        } )
    }

    const updateCutoffs = async () => {
        setSaving( true )
        try {
            await api.admissionExams.updateAdmissionExam( id, { cutoffs } )
            await AdmissionExamStore.fetchExam( id )
            toast( "Exam updated successfully!" )
            setState( false )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while updating the cutoffs!" )
        } finally {
            setSaving( false )
        }
    }


    return (
        <Dialog open={state}>
            <Box borderBottom={border[1]} padding="10px 20px" display="flex" alignItems="center" justifyContent="space-between">
                <Typography>Edit exam cut-off</Typography>
                <IconButton onClick={() => setState( false )}>
                    {Icons.default.CloseIcon}
                </IconButton>
            </Box>
            <Box padding="20px" margin="20px" borderRadius="10px" border={border[1]} bgcolor={palette.contentBg}>
                <Box display="flex" flexDirection="column">
                    {!sectionsCutoffEnabled && <FormControl margin="normal" sx={{ maxWidth: "450px" }} fullWidth>
                        <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Exam cut-off %</Typography></label>
                        <TextField onWheel={e => e.target?.blur()} onChange={handleTotalCutoffChange} error={!cutoffs?.totalCutoff || cutoffs?.totalCutoff < 0 || cutoffs?.totalCutoff > 100} value={cutoffs?.totalCutoff} disabled={sectionsCutoffEnabled} type="number" InputProps={{ endAdornment: <Percent sx={{ paddingX: "10px" }} fontSize='small' /> }} inputProps={{ min: 0, step: 0.001, max: 100 }} id="cutoff" size="small" InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='cutoff' />
                        <Typography variant='subtitle2' fontSize="12px" >Candidate will only be qualified if cut-off has been reached!</Typography>
                    </FormControl>}
                    {sections?.length >= 2 && <FormControlLabel control={<CustomSwitch color='primaryDark' checked={sectionsCutoffEnabled} onChange={e => setSectionsCutoffEnabled( prev => !prev )} />} label="Use section-wise cut-off." />}
                    {sectionsCutoffEnabled && sections.map( ( section, index ) => (
                        <FormControl margin="normal" key={index} sx={{ maxWidth: "450px" }} fullWidth>
                            <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}>{section} cut-off %</Typography></label>
                            <TextField onWheel={e => e.target?.blur()} onChange={e => handleSectionCutoffChange( e.target.value, section )} error={!cutoffs[section] || cutoffs[section] > 100 || cutoffs[section] < 0} value={cutoffs[section]} type="number" InputProps={{ endAdornment: <Percent sx={{ paddingX: "10px" }} fontSize='small' /> }} inputProps={{ min: 0, step: 0.001, max: 100 }} id="cutoff" size="small" InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='cutoff' />
                            <Typography variant='subtitle2' fontSize="12px" >Candidate will only be qualified if cut-off has been reached!</Typography>
                        </FormControl>
                    ) )}
                </Box>
                <Button disabled={saving} sx={{ textTransform: "capitalize", marginTop: "20px" }} onClick={updateCutoffs} variant='contained' color="success" disableElevation startIcon={saving ? <CircularProgress size={14} /> : Icons.default.CheckIcon}>Save</Button>
            </Box>
        </Dialog>
    )
}

export default CutoffEditDialog
