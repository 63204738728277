import { makeObservable, observable, runInAction, action, toJS, computed } from "mobx"
import React from "react"
import api from '../service/api'
import NotificationStore from "./notification.store"
import { EMAIL_PATTERN, URL_PATTERN } from "../utils/resume-builder-utilities"

const notification = NotificationStore

class StudentResumeFormStore {
    form
    studentResumeInfo
    template
    templates
    updatedResumeData
    saveData
    changesMade
    emailTypeErrors
    urlTypeErrors
    fileValues
    fileUploadWait

    constructor () {
        this.form = {}
        this.studentResumeInfo = {}
        this.template = {}
        this.templates = []
        this.updatedResumeData = {}
        this.saveData = { status: true, statusText: "saved", lastSaved: null }
        this.changesMade = false
        this.emailTypeErrors = {}
        this.urlTypeErrors = {}
        this.fileUploadWait = {}
        this.fileValues = {}
        makeObservable( this, {
            form: observable,
            template: observable,
            templates: observable,
            studentResumeInfo: observable,
            updatedResumeData: observable,
            saveData: observable,
            changesMade: observable,
            fileValues: observable,
            urlTypeErrors: observable,
            emailTypeErrors: observable,
            fileUploadWait: observable,
            save: action,
            clearForm: action,
            setUpdatedResumeData: action,
            setSaveData: action,
            setChangesMade: action,
            clearSection: action,
            setFileValues: action,
            fetchStudentForm: action,
            fetchStudentResumeInfo: action,
            updateStudentResumeInfo: action,
            handleProfileFieldChange: action,
            handleDocumentFieldChange: action,
            setFileUploadWait: action,
            fetchForm: action,
            fetchTemplate: action,
            validateEmail: action,
            setEmailTypeErrors: action,
            validateURL: action,
            setUrlTypeErrors: action,
            fetchTemplates: action,
            getTemplate: computed,
            getTemplates: computed,
            getFileUploadWait: computed,
            getForm: computed,
            getStudentResumeInfo: computed,
            getSaveData: computed,
            getSavedFormData: computed,
            getUpdatedResumeData: computed,
            getChangesMade: computed,
            getEmailTypeErrors: computed,
            getUrlTypeErrors: computed,
            getFileValues: computed
        } )
    }

    setUpdatedResumeData( data ) {
        runInAction( () => {
            this.updatedResumeData = data
        } )
    }

    setSaveData( data ) {
        runInAction( () => {
            this.saveData = data
        } )
    }

    setChangesMade( value ) {
        runInAction( () => {
            this.changesMade = value
        } )
    }

    setEmailTypeErrors( errors ) {
        runInAction( () => {
            this.emailTypeErrors = errors
        } )
    }

    setUrlTypeErrors( errors ) {
        runInAction( () => {
            this.urlTypeErrors = errors
        } )
    }

    setFileUploadWait( waits ) {
        runInAction( () => {
            this.fileUploadWait = waits
        } )
    }

    setFileValues( files ) {
        runInAction( () => {
            this.fileValues = files
        } )
    }

    async fetchStudentForm() {
        try {
            const { data } = await api.fetchStudentForm()
            if ( data.success ) {
                runInAction( () => {
                    this.form = data.data
                } )
                return true
            } else {
                return false
            }
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    async fetchStudentResumeInfo() {
        try {
            const { data } = await api.fetchStudentResumeInfo()
            if ( data.success ) {
                runInAction( () => {
                    this.studentResumeInfo = data.data
                } )
            }
            return true
        } catch ( err ) {
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    async updateStudentResumeInfo() {
        console.log( toJS( this.updatedResumeData ) )
        try {
            if ( !Object.values( this.urlTypeErrors ).includes( true ) && !Object.values( this.emailTypeErrors ).includes( true ) ) {
                this.setSaveData( { ...this.saveData, statusText: "saving" } )
                const { data } = await api.updateStudentResumeInfo( { studentInfo: this.updatedResumeData } )
                runInAction( () => {
                    if ( data.success )
                        this.studentResumeInfo = data.data
                } )
                this.setSaveData( { status: true, statusText: "saved", lastSaved: new Date() } )
                this.setChangesMade( false )
                return true
            } else {
                notification.setNotification( true, "Clear all the errors please" )
            }
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            this.setSaveData( { ...this.saveData, status: false, statusText: "saved" } )
            return false
        }
    }

    async fetchForm( id ) {
        try {
            const { data } = await api.fetchResumeForm( id )
            if ( data.success && data.data ) {
                runInAction( () => {
                    this.form = data.data
                } )
                return true
            } else {
                return false
            }
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    // Form Input Handling

    validateEmail( section, attrkey, mailString ) {
        if ( mailString.trim() === '' || EMAIL_PATTERN.test( mailString ) ) {
            this.setEmailTypeErrors( { ...this.emailTypeErrors, [`${section.section_title}-${attrkey}`]: null } )
        } else {
            this.setEmailTypeErrors( { ...this.emailTypeErrors, [`${section.section_title}-${attrkey}`]: true } )
        }
    }

    validateURL( section, attrkey, urlString ) {
        if ( urlString.trim() === '' || URL_PATTERN.test( urlString ) ) {
            this.setUrlTypeErrors( { ...this.urlTypeErrors, [`${section.section_title}-${attrkey}`]: null } )
        } else {
            this.setUrlTypeErrors( { ...this.urlTypeErrors, [`${section.section_title}-${attrkey}`]: true } )
        }
    }

    async getFilePresignedUrl( key ) {
        try {
            const { data: { data } } = await api.getResumeFilePresignedUrl( key )
            return data
        } catch ( err ) {
            return null
        }
    }

    async handleProfileFieldChange( section, attrkey, file ) {
        if ( file ) {
            try {
                const formData = new FormData()
                formData.append( 'profileFile', file )
                formData.append( 'section', section.section_title )
                formData.append( 'attrkey', attrkey )
                formData.append( 'data', JSON.stringify( this.updatedResumeData ) )
                this.setFileUploadWait( { ...this.fileUploadWait, [`${section.section_title}-${attrkey}`]: true } )
                const { data } = await api.uploadStudentProfileToResume( formData )
                if ( data.success && data?.fileData?.key && data?.data ) {
                    this.setFileValues( { ...this.fileValues, [`${section.section_title}-${attrkey}`]: data.fileData.key } )
                    this.setUpdatedResumeData( data?.data )
                    return data.fileData.key
                }
                else
                    return false
            } catch ( err ) {
                console.log( err )
                notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            } finally {
                this.setFileUploadWait( { ...this.fileUploadWait, [`${section.section_title}-${attrkey}`]: false } )
            }
        }
    }

    async handleDocumentFieldChange( section, attrkey, e ) {
        if ( e.target.files.length > 0 ) {
            try {
                const formData = new FormData()
                formData.append( 'section', section.section_title )
                formData.append( 'multiple', section.multiple )
                formData.append( 'attrkey', attrkey )
                formData.append( 'data', JSON.stringify( this.updatedResumeData ) )
                formData.append( 'document', e.target.files[0] )
                this.setFileUploadWait( { ...this.fileUploadWait, [`${section.section_title}-${attrkey}`]: true } )
                const { data } = await api.uploadStudentDocToResume( formData )

                if ( data.success && data?.fileData?.key ) {
                    this.setFileValues( { ...this.fileValues, [`${section.section_title}-${attrkey}`]: data.fileData.key } )
                    if ( !section.multiple && data.data ) {
                        this.setUpdatedResumeData( data?.data )
                        return data.fileData.key
                    } else {
                        return { [attrkey]: data.fileData.key }
                    }
                }
                else
                    return false
            } catch ( err ) {
                console.log( err )
                notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            } finally {
                this.setFileUploadWait( { ...this.fileUploadWait, [`${section.section_title}-${attrkey}`]: false } )
                e.target.value = ''
            }
        }
    }


    async save( data ) {
        try {
            this.setUpdatedResumeData( data )
            this.setSaveData( { ...this.saveData, statusText: "saving" } )
            if ( await this.updateStudentResumeInfo() ) {
                this.setSaveData( { status: true, statusText: "saved", lastSaved: new Date() } )
            }
            else {
                this.setSaveData( { ...this.saveData, status: false } )
            }
            this.setChangesMade( false )
        } catch ( err ) {
            this.setSaveData( { ...this.saveData, status: false } )
        }
    }


    async clearSection( isMultiple, sectionTitle ) {
        try {
            this.setSaveData( { ...this.saveData, statusText: "saving" } )
            if ( isMultiple ) {
                this.setChangesMade( true )
                await api.clearResumeSection( { data: { ...this.updatedResumeData }, section: sectionTitle } )
                this.setUpdatedResumeData( { ...this.updatedResumeData, [sectionTitle]: [] } )
            }
            else {
                await api.clearResumeSection( { data: { ...this.updatedResumeData }, section: sectionTitle } )
                this.setUpdatedResumeData( { ...this.updatedResumeData, [sectionTitle]: {} } )
            }
            this.setChangesMade( false )
            await this.fetchStudentResumeInfo()
            this.setSaveData( { status: true, statusText: "saved", lastSaved: new Date() } )
            return isMultiple ? [] : {}
        } catch ( err ) {
            this.setSaveData( { ...this.saveData, status: false, statusText: "saved", } )
            NotificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong! data not cleared" )
        }
    }

    async clearForm() {
        try {
            this.setSaveData( { ...this.saveData, statusText: "saving" } )
            if ( await api.clearResumeData( { data: this.updatedResumeData } ) ) {
                this.setSaveData( { status: true, statusText: "saved", lastSaved: new Date() } )
                await this.fetchStudentResumeInfo()
                this.setUpdatedResumeData( {} )
                this.setUrlTypeErrors( {} )
                this.setEmailTypeErrors( {} )
                this.fileUploadWait( {} )
                this.fileValues( {} )
            }
            else {
                this.setSaveData( { ...this.saveData, statusText: "saved", status: false } )
            }
        } catch ( err ) {
            this.setSaveData( { ...this.saveData, status: false, statusText: "saved", } )

        }
    }

    // Templates

    async createTemplate( payload ) {
        try {
            const { data } = await api.createTemplate( payload )
            if ( data.success ) {
                runInAction( () => {
                    this.template = data.data
                } )
            }
            const toastMessage = <span>Successfully created new template </span>
            notification.setNotification( true, toastMessage )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    async fetchTemplates( institute = null ) {
        try {
            const { data } = await api.getTemplates( institute )
            runInAction( () => {
                this.templates = data.success ? data.data : []
            } )
            return true
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }


    async fetchTemplate( id ) {
        try {
            const { data } = await api.getTemplate( id )
            runInAction( () => {
                this.template = data.success ? data.data : {}
            } )
            return true
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    get getForm() {
        return toJS( this.form )
    }

    get getStudentResumeInfo() {
        return toJS( this.studentResumeInfo )
    }

    get getUpdatedResumeData() {
        return toJS( this.updatedResumeData )
    }

    get getSavedFormData() {
        if ( this.studentResumeInfo.resume_data )
            return toJS( this.studentResumeInfo.resume_data )
        else return {}
    }

    get getChangesMade() {
        return toJS( this.changesMade )
    }

    get getSaveData() {
        return toJS( this.saveData )
    }

    get getEmailTypeErrors() {
        return toJS( this.emailTypeErrors )
    }

    get getUrlTypeErrors() {
        return toJS( this.urlTypeErrors )
    }

    get getFileUploadWait() {
        return toJS( this.fileUploadWait )
    }

    get getFileValues() {
        return toJS( this.fileValues )
    }

    get getTemplates() {
        return toJS( this.templates )
    }

    get getTemplate() {
        return toJS( this.template )
    }
}
const StudentResumeFormStoreInstance = new StudentResumeFormStore()

export default StudentResumeFormStoreInstance

const StudentResumeFormStoreContext = React.createContext()

export const StudentResumeFormStoreProvider = ( { children, store } ) => {
    return (
        <StudentResumeFormStoreContext.Provider value={store}>{children}</StudentResumeFormStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useStudentResumeFormStore = () => React.useContext( StudentResumeFormStoreContext )