import { Pie, PieChart, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts'
import { Typography, Paper, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useApiProviderStore } from '../../../store/apiprovider.store'
import { observer } from 'mobx-react'

const COLORS = ['#E31A4B', '#F26922', '#F7B217', '#B6D433', '#209D48', '#3FBED5', '#745BA7', '#E31A4B', '#F26922']


const ServerAllocationPieChart = observer( () => {

    const ApiProviderStore = useApiProviderStore()
    const [isLoading, setIsLoading] = useState( true )

    useEffect( () => {
        const getData = async () => {
            setIsLoading( true )
            await ApiProviderStore.fetchApiProviders()
            setIsLoading( false )
        }
        getData()
    }, [ApiProviderStore] )

    const prepareDataPoints = () => {
        const data = ApiProviderStore.getApiProvider
        let institutes = []
        let datapoints = {}
        let graphDataPoints = []
        for ( let i = 0; i < data.length; i++ ) {

            let serverShare = parseInt( data[i]["api_user_threshold"] / data[i]["api_org_codes"].length )
            for ( let j = 0; j < data[i]["api_org_codes"].length; j++ ) {
                if ( institutes.indexOf( data[i]["api_org_codes"][j] ) === -1 ) {
                    institutes.push( data[i]["api_org_codes"][j] )
                    datapoints[data[i]["api_org_codes"][j]] = serverShare
                } else {
                    datapoints[data[i]["api_org_codes"][j]] += serverShare
                }
            }
            //console.log(i)
        }

        for ( const [key, value] of Object.entries( datapoints ) ) {
            graphDataPoints.push( { "name": key, "value": value } )
        }
        return graphDataPoints
    }

    return (
        <Paper sx={{ height: "400px", padding: "20px", width: "94%", maxWidth: "550px" }}>
            <Typography variant='h5' >Server Allocation</Typography>
            <Typography variant='body2' color="textSecondary">Institute User Capacity{"(Avg)"}</Typography>
            {!isLoading && <ResponsiveContainer width="100%" height="80%">
                <PieChart>
                    <Pie
                        dataKey="value"
                        data={prepareDataPoints()}
                        cx="50%"
                        cy="50%"
                        outerRadius={110}
                        fill="#8884d8"
                        label>
                        {prepareDataPoints().map( ( entry, index ) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ) )}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="bottom" height={36} />
                </PieChart>
            </ResponsiveContainer>}
            {isLoading && <Typography display="flex" height="100%" alignItems="center" justifyContent="center"> <CircularProgress /></Typography>}
        </Paper>
    )
} )

export default ServerAllocationPieChart
