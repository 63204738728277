import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import TableHeader from '../../common/TableHeader'
import { useParams } from 'react-router-dom'
import api from '../../../service/api'
import { showDateInIndianDateFormat } from '../../../utils/utilities'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import errorImg from '../../../assets/request-error.svg'
import noMaterialsImg from '../../../assets/no-material.svg'
import StudyMaterialViewer from '../study-materials/StudyMaterialViewer'

const columns = [
    { name: "Sl. no.", padding: "10px 20px 10px 40px", align: "center" },
    { name: "Study Material" },
    { name: "Created on" },
    { name: "Subject" },
    { name: "Attachments", align: "center" },
    { name: "Downloads", padding: "10px 40px 10px 20px", align: "center" },
]

const StudyMaterialDetails = ( { userData } ) => {

    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [selectedMaterial, setSelectedMaterial] = useState( null )
    const [studyMaterialDetails, setStudyMaterialDetails] = useState( [] )

    const { palette, table } = useTheme()

    const { auid } = useParams()

    const loadMaterials = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data } } = await api.audit.getStudyMaterialsByFacultyId( auid )
            setStudyMaterialDetails( data )
            setError( null )
        } catch ( err ) {
            console.log( err )
            setError( err?.response?.data?.message || "Something went wrong while fetching study materials details." )
        } finally {
            setLoading( false )
        }
    }, [auid] )

    useEffect( () => {
        loadMaterials()
    }, [loadMaterials] )

    return (
        <Box borderRadius="10px" margin="10px 20px" bgcolor={palette.contentBg} maxHeight="350px" display="flex" flexDirection="column" overflow="hidden">

            {selectedMaterial && <StudyMaterialViewer studyMaterial={selectedMaterial} openState={Boolean( selectedMaterial )} handleClose={() => setSelectedMaterial( null )} />}

            {!loading && !error && studyMaterialDetails.length > 0 && <TableContainer sx={{ overflow: "auto", }} className='custom-scrollbar' >
                <Table stickyHeader>
                    <TableHeader columns={columns} />
                    <TableBody>
                        {studyMaterialDetails?.map( ( item, index ) => (
                            <TableRow sx={{ cursor: "pointer", '&:hover': { background: palette.rowHover, }, '&:hover .underline': { textDecoration: "underline", } }} className="table-row" onClick={() => setSelectedMaterial( item )} key={item.study_material_id}>
                                <TableCell align={columns[0].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[0].padding || "10px 20px" }} ><strong>{index + 1}</strong></TableCell>
                                <TableCell className='underline' align={columns[1].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[1].padding || "10px 20px" }} ><strong>{item.study_material_name}</strong></TableCell>
                                <TableCell align={columns[2].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[2].padding || "10px 20px" }} ><strong>{showDateInIndianDateFormat( item.study_material_published_date )}</strong></TableCell>
                                <TableCell align={columns[3].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[3].padding || "10px 20px" }} ><strong>{item?.subject_name || "Not Available"}</strong></TableCell>
                                <TableCell align={columns[4].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[4].padding || "10px 20px" }} ><strong>{item.attachments_count}</strong></TableCell>
                                <TableCell align={columns[5].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[5].padding || "10px 20px" }} ><strong>{item.attachments_download_count}</strong></TableCell>
                            </TableRow>
                        ) )}
                    </TableBody>
                </Table>
            </TableContainer>}
            {loading && !error && <Box padding="50px 20px" display="flex" justifyContent="center">
                <DataLoadingSpinner waitingMessage="Please wait! Loading materials..." />
            </Box>}
            {error && <Box padding="20px" display="flex" alignItems="center" gap="20px" flexDirection="column" justifyContent="center">
                <img src={errorImg} height="100px" alt="Something went wrong!" />
                <Typography variant="subtitle2">{error}</Typography>
                <Button disabled={loading} startIcon={loading && <CircularProgress size={14} />} onClick={loadMaterials} variant="contained" sx={{ textTransform: "capitalize" }} color="errorMessage" disableElevation>Retry!</Button>
            </Box>}
            {!loading && !error && studyMaterialDetails.length === 0 && <Box padding="20px" gap="20px" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                <img src={noMaterialsImg} height="100px" alt="Something went wrong!" />
                <Typography variant="subtitle2"><em>{userData.employee_name}</em> hasn't created any study materials!</Typography>
            </Box>}
        </Box>
    )
}

export default StudyMaterialDetails
