import { Box, Button, CircularProgress, Dialog, DialogTitle, FormControl, TextField, Typography, useTheme } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { Icons } from '../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../service/api'

const AddNewBatch = ( { openState, setOpenState, getBatches } ) => {

    const { palette, border } = useTheme()

    const [adding, setAdding] = useState( false )

    const initialValues = {
        batchName: '',
        batchPassword: ''
    }

    const validationSchema = Yup.object().shape( {
        batchName: Yup.string().required( "Batch name is required" ),
        batchPassword: Yup.string().required( "Batch password is required" )
    } )

    const addBatch = async ( values, { resetForm } ) => {
        try {
            setAdding( true )
            await api.temporaryUsers.addBatch( values )
            resetForm()
            setOpenState( false )
            await getBatches()
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Error occured while creating a new batch." )
        } finally {
            setAdding( false )
        }
    }

    return (
        <Dialog sx={{ bgcolor: palette }} open={openState}>
            <DialogTitle sx={{ borderBottom: border[1] }} >Add New Batch</DialogTitle>
            <Box margin="20px" bgcolor={palette.form.formBg} borderRadius="5px" border={border[1]} padding="20px">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={addBatch}>
                    {() => {
                        return (
                            <Form>
                                <FormControl margin='normal' fullWidth>
                                    <label htmlFor="batch-name">
                                        <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>
                                            Batch name *
                                        </Typography>
                                    </label>
                                    <Field size="small" as={TextField}
                                        placeholder="Eg: ADMISSIONS 23-24"
                                        InputLabelProps={{ sx: { fontSize: "14px" } }}
                                        id="batch-name"
                                        type="text"
                                        rows={4}
                                        name="batchName"
                                    />
                                    <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="batchName" /> </Typography>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <label htmlFor="batch-password">
                                        <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>
                                            Batch password *
                                        </Typography>
                                    </label>
                                    <Field size="small" as={TextField}
                                        placeholder="A strong password"
                                        InputLabelProps={{ sx: { fontSize: "14px" } }}
                                        id="batch-password"
                                        type="type"
                                        rows={4}
                                        name="batchPassword"
                                    />
                                    <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="batchPassword" /> </Typography>
                                </FormControl>
                                <Box display="flex" marginTop="40px" gap="20px" alignItems="center" flexWrap="wrap">
                                    <Button startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} disabled={adding} disableElevation sx={{ textTransform: "capitalize" }} type="submit" variant="contained" color="primary">
                                        {adding === false ? 'Add batch' : "Adding batch..."}
                                    </Button>
                                    <Button disableElevation disabled={adding} onClick={() => setOpenState( false )} sx={{ textTransform: "capitalize" }} variant="contained" color="error">
                                        Cancel
                                    </Button>
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>
            </Box>
        </Dialog>
    )
}

export default AddNewBatch
