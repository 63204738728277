import { Box, Button, Dialog, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Icons, showDateInIndianDateFormat } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import TableHeader from '../../common/TableHeader'
import { useAssignmentStore } from '../../../store/assignment.store'

const columns = [
    { name: "Student", padding: "10px 20px 10x 40px" },
    { name: "AUID", padding: "10px 20px" },
    { name: "Submitted on", padding: "10px 20px" },
    { name: "Marks Assigned", padding: "10px 20px" },
    { name: "Submitted file", align: "right", padding: "10px 40px 10px 20px" },
]

const SubmissionsViewer = ( { selectedValue, assignmentId, open, handleClose } ) => {

    const [loading, setLoading] = useState( true )
    const [submissions, setSubmissions] = useState( [] )

    const { palette, table } = useTheme()

    const AssignmentStore = useAssignmentStore()

    useEffect( () => {
        const getData = async () => {
            try {
                const { data: { data } } = await api.fetchAssignmentSubmission( assignmentId )
                setSubmissions( data )
            } catch ( err ) {
                toast( err?.response?.data?.message || "Something went wrong while fetching submissions." )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [assignmentId] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "1024px" } }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            {selectedValue && <Box>
                <Box sx={{
                    background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
                }} display="flex" alignItems="center" justifyContent="space-between" padding="20px" >
                    <Box>
                        <Typography color="white" variant="h6">{selectedValue.assignment_name} submissions</Typography>
                        <Typography color="white" variant="subtitle2">Due Date: {new Date( selectedValue.assignment_due_date ).toLocaleDateString( "en-IN", { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>{Icons.default.CloseIcon}</IconButton>
                </Box>
            </Box>}
            {selectedValue && !loading && submissions.length > 0 && <Box borderRadius="10px" overflow="hidden" bgcolor={palette.contentBg} margin="20px">
                <TableContainer sx={{ maxHeight: "450px", overflow: "auto" }} className='custom-scrollbar'>
                    <Table stickyHeader>
                        <TableHeader columns={columns} />
                        <TableBody>
                            {submissions.map( sub => (
                                <TableRow key={sub.assignment_submission_id} className='table-row'>
                                    <TableCell sx={{ fontSize: "12px", padding: columns[0].padding || "10px 20px", color: table.color }} align={columns[0].align || "left"} > <strong>{sub.student_name}</strong> </TableCell>
                                    <TableCell sx={{ fontSize: "12px", padding: columns[1].padding || "10px 20px", color: table.color }} align={columns[1].align || "left"} > <strong>{sub.assignment_submitted_by}</strong> </TableCell>
                                    <TableCell sx={{ fontSize: "12px", padding: columns[2].padding || "10px 20px", color: table.color }} align={columns[2].align || "left"} > <strong>{showDateInIndianDateFormat( sub.assignment_submitted_on )}</strong> </TableCell>
                                    <TableCell sx={{ fontSize: "12px", padding: columns[3].padding || "10px 20px", color: table.color }} align={columns[3].align || "left"} > <strong>{sub.assignment_marks || "Not assigned yet!"}</strong> </TableCell>
                                    <TableCell sx={{ fontSize: "12px", padding: columns[4].padding || "10px 20px", color: table.color }} align={columns[4].align || "left"} >
                                        <Button onClick={e => AssignmentStore.downloadAssignment( sub.assignment_submission_file_paths[0] )} size="small" color="primary" sx={{ textTransform: "capitalize" }} endIcon={Icons.default.DownloadIconOutlined} >Download</Button>
                                    </TableCell>
                                </TableRow>
                            ) )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
            {loading && <DataLoadingSpinner waitingMessage="Please wait while we fetch assignment's submissions..." />}
        </Dialog>
    )
}

export default SubmissionsViewer
