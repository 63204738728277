import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react'
import { Box, Typography, Card, CardContent, Paper, Dialog, DialogTitle, List, ListItem, ListItemText, Button, CircularProgress } from '@mui/material'
import api from '../../service/api'
import { DataGrid } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import noAttendenceImg from '../../assets/noattendence.svg'
import { InfoOutlined } from '@mui/icons-material'

const columns = [
    { field: 'selected_date', headerName: 'Date', width: 110 },
    { field: 'faculty_name', headerName: 'Faculty Name', width: 160 },
    { field: 'subject_short_name', headerName: 'Subject', width: 160 },
    { field: 'duration', headerName: 'Duration', width: 120 }, //subject_name_short
    { field: 'time', headerName: 'Time', width: 190 }, //selected_date
]

const Attendence = () => {
    const [loading, setLoading] = React.useState( false )
    const [allAttendance, setAllAttendance] = React.useState( [] )
    const [fromDate, setFromDate] = React.useState( new Date().toISOString() )
    const [toDate, setToDate] = React.useState( new Date().toISOString() )
    const [openDialog, setOpenDialog] = React.useState( false )
    const [auidList, setAuidList] = React.useState( [] )

    const submit = async () => {
        let payload = {
            "date_from": fromDate.toString().split( "T" )[0],
            "date_to": toDate.toString().split( "T" )[0],
            "percentage": 50
        }
        setLoading( true )
        try {
            const res = await api.studentAttendence( payload )
            setAllAttendance( res["data"]["data"] ? res["data"]["data"] : [] )
        } catch ( error ) {
            alert( error )
        } finally {
            setLoading( false )
        }

    }

    const handleClose = () => {
        setOpenDialog( false )
        setAuidList( [] )
    }


    const AttendanceDialog = () => {
        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialog}>
                <DialogTitle id="simple-dialog-title">Attendance List</DialogTitle>
                <List>
                    {auidList.map( ( list ) => (
                        <ListItem key={list}>
                            <ListItemText primary={list} />
                        </ListItem>
                    ) )}
                </List>
            </Dialog>
        )
    }



    return (
        <Box padding="20px">
            <Box>
                <Box display="flex" flexDirection={{ sm: "row", xs: "column" }} gap="20px">
                    <Box>
                        <Card sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Search
                                </Typography>
                                <form style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px" }} noValidate autoComplete="off">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            style={{ "width": "100%" }}
                                            disableToolbar
                                            variant="inline"
                                            format="DD-MM-YYYY"
                                            margin="normal"
                                            id="fromdate-picker-inline"
                                            label="From Date"
                                            value={dayjs( fromDate )}
                                            onChange={( e ) => setFromDate( e.toISOString() )}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}

                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            style={{ "width": "100%" }}
                                            disableToolbar
                                            maxDate={dayjs( new Date() )}
                                            variant="inline"
                                            format="DD-MM-YYYY"
                                            margin="normal"
                                            id="todate-picker-inline"
                                            label="To Date"
                                            value={dayjs( toDate )}
                                            onChange={( e ) => setToDate( e.toISOString() )}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}


                                        />
                                    </LocalizationProvider>
                                </form>
                                <Button variant='contained' disableElevation sx={{ marginTop: "20px", width: "100%", height: "53px" }} size='large' color="primary" onClick={submit}>
                                    Search
                                </Button>
                            </CardContent>
                        </Card>
                    </Box>
                    {allAttendance.length > 0 && <Box flexGrow={1}>
                        <AttendanceDialog />
                        <Typography gutterBottom variant='subtitle2' display="flex" alignItems="center" gap="5px"> <InfoOutlined fontSize='small' /> Attendance is taken into account only if the student is present in class for more than 50% of the time.</Typography>
                        <DataGrid sx={{ bgcolor: "white" }} autoHeight={true} rows={allAttendance}
                            columns={columns} pageSize={( allAttendance.length > 10 ) ? 10 : 0} />

                    </Box>}
                    {allAttendance.length === 0 && !loading &&
                        <Paper sx={{ flexGrow: 1, padding: "20px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                            <img src={noAttendenceImg} width="200px" alt="No attendence details for selected date range!" />
                            <Typography color="textSecondary" variant="subtitle2" marginY="20px">No attendence details for selected date range!</Typography>
                        </Paper>
                    }
                    {loading &&
                        <Paper sx={{ flexGrow: 1, gap: "10px", padding: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress size={20} />
                            <Typography variant='h6' color="textSecondary" marginY="20px">Loading attendence...</Typography>
                        </Paper>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default Attendence
