import { Box, FormControl, FormHelperText, TextField, Typography, Collapse, Chip, Button, useTheme } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { chipColors, flatColors } from '../../utils/utilities'

const headBgColor = flatColors[Math.floor( Math.random() * flatColors.length )]

const MaterialEdit = ( { material, currentTags, setEditMode, update, isUpdating } ) => {

    const [tags, setTags] = useState( currentTags.length === 1 ? currentTags[0] === "" ? [] : [...currentTags] : currentTags )

    const { palette } = useTheme()

    const initialValues = {
        materialName: material.study_material_name,
        materialDescription: material.study_material_desc,
        tags: currentTags.join()
    }

    const validationSchema = Yup.object().shape( {
        materialName: Yup.string().required( "Material name is required" )
    } )

    const deleteTag = ( index, setFieldValue ) => {
        const t = [...tags]
        t.splice( index, 1 )
        setTags( t )
        setFieldValue( 'tags', t.join( "," ) )
    }

    const handleKeyDown = ( e, setFieldValue ) => {
        if ( e.code === "Enter" ) {
            e.preventDefault()
            if ( e.target.value.trim() !== '' ) {
                let newTags = e.target.value.split( "," )
                let filteredTags = []
                newTags.forEach( tag => {
                    if ( tag.trim() !== '' ) filteredTags.push( tag )
                } )
                setTags( [...tags, ...filteredTags] )
                setFieldValue( 'tags', [...tags, ...filteredTags].join( "," ) )
                e.target.value = ""
            }
        }
    }

    const handleBlur = ( e, setFieldValue ) => {
        if ( e.target.value.trim() !== '' ) {
            let newTags = e.target.value.split( "," )
            let filteredTags = []
            newTags.forEach( tag => {
                if ( tag.trim() !== '' ) filteredTags.push( tag )
            } )
            setTags( [...tags, ...filteredTags] )
            setFieldValue( 'tags', [...tags, ...filteredTags].join( "," ) )
            e.target.value = ""
        }
    }

    return (
        <Box borderRadius="10px" bgcolor={palette.contentBg} overflow="hidden">
            {material && <Box>
                <Box bgcolor={headBgColor} color="white" padding="10px 20px">
                    <Typography variant="h6">{material.study_material_name}</Typography>
                    <Typography variant="body2">Edit material</Typography>
                </Box>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={update}>
                    {( { setFieldValue, values } ) => {
                        return (
                            <Form style={{ padding: "20px" }} autoComplete='false'>
                                <FormControl fullWidth>
                                    <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Material name *</Typography>
                                    <Field size="small" as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} fullWidth type="text" name="materialName" />
                                    <FormHelperText sx={{ color: "error.main", fontWeight: "bold", marginLeft: 0 }}> <ErrorMessage name="materialName" /> </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal" >
                                    <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Material description *</Typography>
                                    <Field size="small" as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} multiline rows={5} fullWidth type="text" name="materialDescription" />
                                    <FormHelperText> <ErrorMessage name="materialDescription" /> </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth>
                                    <Typography gutterBottom sx={{ color: "#777" }} fontSize="14px">Add Tags * {`(Type tags as comma seperated values)`}</Typography>
                                    <Box >
                                        <TextField type="text" size="small" placeholder='Add new tag' InputProps={{ sx: { fontSize: "14px !important" } }} fullWidth onBlur={( e ) => { handleBlur( e, setFieldValue ) }} onKeyDown={e => { handleKeyDown( e, setFieldValue ) }} />
                                        <Collapse in={tags.length > 0}>
                                            <Typography marginTop="10px" gutterBottom variant='subtitle2' color="secondary">Added tags</Typography>
                                            <Box display="flex" alignItems="center" gap="10px">
                                                {tags.length > 0 && tags.map( ( tag, index ) => {
                                                    const color = chipColors[index % chipColors.length]
                                                    return (
                                                        <Chip key={index} sx={{ background: color, color: "#fff", fontWeight: 500 }} label={tag} onDelete={() => deleteTag( index, setFieldValue )} size="medium" />
                                                    )
                                                } )}
                                            </Box>
                                        </Collapse>
                                    </Box>
                                </FormControl>
                                <Box marginTop="40px" display="flex" alignItems="center" gap="20px">
                                    <Button type="submit" sx={{ textTransform: "capitalize" }} disabled={( values.materialName.trim() === material.study_material_name.trim() && values.materialDescription.trim() === material.study_material_desc.trim() && currentTags.join( "," ).trim() === tags.join( "," ).trim() ) || isUpdating} disableElevation variant='contained'>{isUpdating ? "Updating..." : "Update"}</Button>
                                    <Button onClick={e => { setEditMode( false ); window.location.hash = "" }} sx={{ textTransform: "capitalize" }} color='error' variant='contained' disableElevation>Cancel</Button>
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>
            </Box>}
        </Box>
    )
}

export default MaterialEdit
