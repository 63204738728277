import StudentResumeFormStore, { StudentResumeFormStoreProvider } from '../../../store/studentresumeform.store'
import DownloadStatusListDialog from '../../study-material-components/DownloadStatusListDialog'
import { Box, Button, Dialog, DialogContent, Typography, useTheme } from '@mui/material'
import { useLayoutContext } from '../../../contexts/layout.context'
import sessionEndedImg from '../../../assets/waiting.svg'
import { useAuthStore } from '../../../store/auth.store'
import { Outlet, useNavigate } from 'react-router-dom'
import { LoginOutlined } from '@mui/icons-material'
import SideDrawer from './UserSideDrawer'
import { observer } from 'mobx-react'
import { useCallback, useEffect } from 'react'
import Navbar from './UserNavbar'
import React from 'react'
import ResourceDownloadStatusDialog from '../../live-session/ResourceDownloadStatusDialog'

const openedSidebarWidth = 300

const UserLayout = observer( ( { navHeadText } ) => {

    const { setSecondarySidebarStatus, active, focusedMenuItem, setSidebarWidth, setMenuState, navHeight, minSidebarCollapseWidth } = useLayoutContext()

    const AuthStore = useAuthStore()
    const navigate = useNavigate()
    const { palette } = useTheme()

    const handleResizeOrLoad = useCallback( () => {
        if ( window.innerWidth < minSidebarCollapseWidth ) {
            setMenuState( false )
        }
        else {
            setMenuState( true )
            if ( focusedMenuItem?.children ) {
                setSidebarWidth( openedSidebarWidth )

                if ( active.match( /^(\/student\/subject\/\d+\/?)\/?$/ ) ) {
                    setSecondarySidebarStatus( false )
                }
            } else {

                if ( active.match( /^(\/student\/subject\/\d+\/?)\/?$/ ) ) {
                    setSecondarySidebarStatus( true )
                }
                else {
                    setSecondarySidebarStatus( false )
                }
            }
        }
    }, [minSidebarCollapseWidth, active, focusedMenuItem, setSecondarySidebarStatus, setSidebarWidth, setMenuState] )


    useEffect( () => {
        window.addEventListener( 'resize', handleResizeOrLoad )
        window.addEventListener( 'load', handleResizeOrLoad )
        return () => {
            window.removeEventListener( 'resize', handleResizeOrLoad )
            window.removeEventListener( 'resize', handleResizeOrLoad )
        }
    }, [handleResizeOrLoad] )

    return (
        <Box display="flex" height="100%" bgcolor={palette.layoutBackground} overflow="auto">
            <StudentResumeFormStoreProvider store={StudentResumeFormStore}>
                <SideDrawer closeSideMenu={() => setMenuState( false )} />
                <Box
                    flexGrow={1}
                    display="flex"
                    minWidth="0"
                    position="relative"
                >
                    <Navbar navHeadText={navHeadText} closeSideMenu={() => setMenuState( false )} openSideMenu={() => setMenuState( true )} />
                    {AuthStore.getErpSessionState !== "ended" &&
                        <Box
                            flexGrow={1}
                            display="flex"
                            maxHeight="100%"
                            id="page-content"
                            paddingTop={`${navHeight}px`}
                            minWidth="0"
                        >
                            <Outlet />
                        </Box>
                    }
                </Box>
                <Dialog open={AuthStore.getErpSessionState === "ended"}>
                    <DialogContent >
                        <Box display="flex" alignItems="center" gap="20px" flexDirection="column">
                            <img width="200px" src={sessionEndedImg} alt="Session ended!" />
                            <Typography textAlign="center" variant='subtitle2'>Your session seems to have ended please login again.</Typography>
                            <Button onClick={() => navigate( `/?redirect=${window.location.pathname}${window.location.search}` )} startIcon={<LoginOutlined fontSize='small' />} variant='outlined' sx={{ textTransform: "capitalize" }}>Sign In</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
                <DownloadStatusListDialog />
                <ResourceDownloadStatusDialog />
            </StudentResumeFormStoreProvider>
        </Box>
    )
} )

export default UserLayout
