import { Box, Button, Card, Chip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { formatDateTimeForDisplay } from '../../utils/exam-utilities'
import { Link, useSearchParams } from 'react-router-dom'
import { chipColors } from '../../utils/utilities'

export default function GridView( { exams, userRole, isAuditing } ) {

    const { palette, border, theme } = useTheme()

    const [searchParams, setSearchParams] = useSearchParams( { searchText: "" } )
    const searchText = searchParams.get( 'searchText' )

    const removeFilter = () => {
        setSearchParams( prev => {
            prev.set( 'searchText', "" )
            return prev
        }, { replace: true } )
    }

    return (
        <Box flexGrow={1} display="flex" flexDirection="column" width="100%" overflow="visible" borderRadius="5px" >
            <Box sx={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start", gridTemplateColumns: { lg: "repeat(4,1fr)", md: "repeat(3,1fr)", sm: "repeat(2,1fr)", xs: "1fr" }, flexWrap: "wrap", gap: "20px" }}>
                {exams.length > 0 && exams.filter( item => item.exam_name.toLowerCase().includes( searchText ) ).map( ( exam, index ) => {
                    return (
                        <Card key={index} sx={{ maxWidth: "300px", borderRadius: "8px", border: theme === 'dark' && border[1] }}>
                            <Link to={userRole === "STUDENT" ? `/student/exams/${exam.exam_id}` : `/faculty/exams/${exam.exam_id}`} style={{ margin: "10px 0" }}>
                                <Box overflow="hidden" borderRadius="5px" sx={{ cursor: "pointer", transition: "ease 200ms" }} key={exam.exam_id}>
                                    <Box bgcolor="#EFF1F3" padding="10px" display="flex" alignItems="flex-start" gap="10px">
                                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="13" cy="7" r="7" fill="#FF8A58" />
                                            <path d="M10 2L18.6603 17H1.33975L10 2Z" fill="#0F1368" fillOpacity="0.76" />
                                        </svg>
                                        <Box flexGrow={1} overflow="hidden">
                                            <Typography color="black" noWrap textOverflow="ellipsis" width="100%" marginTop="-5px" fontSize="16px" variant="h6">{exam.exam_name}</Typography>
                                            <Typography color="black" fontSize="12px">{formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )} - {formatDateTimeForDisplay( exam.exam_end_date, exam.exam_end_time )}</Typography>
                                        </Box>
                                    </Box>
                                    <Box bgcolor={palette.background.bg} padding="10px" overflow="hidden">
                                        <Box overflow="auto" className="no-scrollbar" gap="5px" display="flex">
                                            {exam?.exam_topics?.map( ( topic, index ) => {
                                                const color = chipColors[Math.floor( Math.random() * chipColors.length )]
                                                return (
                                                    <Chip sx={{ fontWeight: 500, borderRadius: "5px", background: color, color: "#fff", border: `1px solid ${color}` }} size='small' key={topic} label={topic} />
                                                )
                                            } )}
                                        </Box>
                                        <Typography
                                            marginTop="10px"
                                            variant='body2'
                                            noWrap
                                            width="250px"
                                            fontSize="14px"
                                            color={palette.shadedText}
                                            fontWeight="500"
                                        >
                                            {exam.exam_description?.substring( 0, 100 )}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Card>
                    )
                } )}
            </Box>
            {exams.filter( item => item.exam_name.toLowerCase().includes( searchText ) ).length === 0 && <Box flexGrow={1} display="flex" flexDirection="column" gap="20px" alignItems="center" justifyContent="center" padding="10px" borderRadius="5px" bgcolor={palette.contentBg}>
                <Typography variant='subtitle2'>No results found for <strong>{searchText}</strong></Typography>
                <Button onClick={removeFilter} variant="outlined" color="secondary" sx={{ textTransform: "capitalize" }}>Remove filter</Button>
            </Box>}
        </Box>
    )
}
