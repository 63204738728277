import { Typography, Button, Box, Dialog, useTheme, IconButton, CircularProgress, Tooltip } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ResourceInput from './ResourceInput'
import React, { useEffect, useState } from 'react'
import { AddRounded, CheckBox, CheckBoxOutlineBlank, DownloadOutlined, Remove, UploadOutlined } from '@mui/icons-material'
import { useLiveSessionStore } from '../../../store/livesessions.store'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Icons } from '../../../utils/utilities'
import ConfirmDialog from '../../../components/common/ConfirmDialog'
import noResource from '../../../assets/no-resources.svg'


const AddResourcesDialog = observer( ( { initialMode: linkMode = "upload", state, setState, filter, setLinkMode } ) => {

    const [resourceFilter, setResourceFilter] = useState( 'available' )
    const [selectedResources, setSelectedResources] = useState( [] )
    const [markedResources, setMarkedResources] = useState( [] )
    const [uploading, setUploading] = useState( false )
    const [importing, setImporting] = useState( false )
    const [loading, setLoading] = useState( true )
    const [resourceSelectedForDeletion, setResourceSelectedForDeletion] = useState( null )
    const [deleting, setDeleting] = useState( false )

    const LiveSessionStore = useLiveSessionStore()
    const { session_id } = useParams()

    const { palette, border } = useTheme()

    const handleClose = () => {
        setState( false )
        setSelectedResources( [] )
        setMarkedResources( [] )
    }

    const filterFunc = r => {
        if ( resourceFilter === 'all' ) return true
        if ( resourceFilter === 'available' ) return !r.exists
        if ( resourceFilter === 'in use' ) return r.exists
    }

    const toggleMark = ( id, present ) => {
        if ( present >= 0 ) {
            let updated = [...markedResources]
            updated.splice( updated.indexOf( id ), 1 )
            setMarkedResources( updated )
        } else {
            let updated = [...markedResources, id]
            setMarkedResources( updated )
        }
    }

    const importResources = async () => {
        setImporting( true )
        const resources = markedResources?.map( resource => { return { id: resource, category: filter } } )
        if ( await LiveSessionStore.linkResource( session_id, resources ) )
            handleClose()
        setImporting( false )
    }

    const minimizeDialog = () => {
        LiveSessionStore.minimizeResourceUploadDialog()
        handleClose()
    }

    const deleteResource = async ( id ) => {
        setDeleting( true )
        const success = await LiveSessionStore.deleteResource( id )
        setDeleting( false )
        if ( success ) {
            setLoading( true )
            await LiveSessionStore.fetchResources( { session_id: session_id, filter } )
            setLoading( false )
        }
        setResourceSelectedForDeletion( null )
    }

    useEffect( () => {
        const getData = async () => {
            if ( linkMode === 'import' && state === true ) {
                await LiveSessionStore.fetchResources( { session_id: session_id, filter } )
            }
            setLoading( false )
        }
        getData()
    }, [linkMode, state, LiveSessionStore, filter, session_id] )


    return (
        <Dialog open={state} PaperProps={{ sx: { width: "90%", maxWidth: "1200px" } }} onClose={handleClose}>

            <ConfirmDialog waiting={deleting} message="The resource will be removed from all associated sessions." status={Boolean( resourceSelectedForDeletion )} actionName="Yes! delete it" confirmAction={() => deleteResource( resourceSelectedForDeletion )} cancelAction={() => setResourceSelectedForDeletion( null )} />

            <Box padding="20px" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: "flex", gap: "10px", alignItems: 'center' }}>
                    <Box sx={{ display: "flex", gap: "10px", alignItems: 'center' }}>
                        {linkMode === 'upload' ? <UploadOutlined fontSize='small' /> : <DownloadOutlined fontSize="small" />}
                        <Typography textTransform="capitalize">{linkMode} resources</Typography>
                    </Box>
                    <Typography variant="body2" marginTop="5px" fontSize="12px"><small>{filter}</small></Typography>
                </Box>
                {linkMode === 'upload' && <Box sx={{ display: 'flex', alignItems: 'center', color: 'black', padding: '0' }}>
                    {uploading && <Button startIcon={<Remove />} sx={{ textTransform: "capitalize" }} variant='text' color='primaryDark' onClick={minimizeDialog}>
                        minimize
                    </Button>}
                    <Button disabled={uploading || importing} startIcon={<HighlightOffIcon />} sx={{ textTransform: "capitalize" }} variant='text' color='primaryDark' onClick={handleClose}>
                        Cancel {linkMode}
                    </Button>
                </Box>}
            </Box>
            {linkMode === 'upload' ? <Box>
                <ResourceInput
                    uploading={uploading}
                    setUploading={setUploading}
                    setState={setState}
                    filter={filter}
                    handleClose={handleClose}
                    selectedResources={selectedResources}
                    setSelectedResources={setSelectedResources}
                // selectedResourceTypes={selectedResourceTypes} setSelectedResourceTypes={setSelectedResourceTypes} setResourceNameList={setResourceNameList} resourceNameList={resourceNameList}
                />
            </Box> : <Box padding="0 20px 20px 20px">
                {loading && <DataLoadingSpinner waitingMessage="Loading resources..." />}
                {!loading && LiveSessionStore.getResources.length > 0 && <Box >
                    <Box borderRadius="5px 5px 0 0" border={`1px solid ${palette.borderColor}`} display="flex" gap="10px" height="40px" padding="5px">
                        <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setResourceFilter( 'available' )} sx={{ color: resourceFilter === "available" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", }} padding="3px" bgcolor={resourceFilter === "available" ? `#5E2974` : palette.common.default} variant="subtitle2">Available</Typography>
                        <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setResourceFilter( 'in use' )} sx={{ color: resourceFilter === "in use" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", }} padding="3px" bgcolor={resourceFilter === "in use" ? `#5E2974` : palette.common.default} variant="subtitle2">In use</Typography>
                        <Typography width="100px" display="flex" alignItems="center" justifyContent="center" textAlign="center" onClick={() => setResourceFilter( 'all' )} sx={{ color: resourceFilter === "all" ? `${palette.primary.contrastText}` : 'inherit', cursor: "pointer", borderRadius: "3px", }} padding="3px" bgcolor={resourceFilter === "all" ? `#5E2974` : palette.common.default} variant="subtitle2">All</Typography>
                    </Box>

                    <Box border={`1px solid ${palette.borderColor}`} borderTop="none">
                        <Box display="flex" gap="20px" alignItems="center" padding="10px" paddingTop="20px">
                            <Typography color="GrayText" variant='subtitle2'>Total files {LiveSessionStore.getResources.filter( filterFunc ).length}</Typography>
                            <Typography color="GrayText" variant='subtitle2'>Selected {markedResources.length}</Typography>
                        </Box>
                        <Box padding="10px" display="flex" gap="10px" flexWrap="wrap">
                            {LiveSessionStore.getResources.filter( filterFunc ).map( ( resource, index ) => {
                                const marked = markedResources.indexOf( resource.id )
                                return (
                                    <Box padding="10px" width="100%" maxWidth="220px" display="flex" gap="10px" borderRadius="5px" alignItems="flex-start" border={border[1]} key={resource.id}>
                                        {!resource.exists && <IconButton disabled={importing} onClick={() => toggleMark( resource.id, marked )} color={marked ? "primary" : "greyed"} size="small">
                                            {marked >= 0 ? <CheckBox color={"primary"} /> : <CheckBoxOutlineBlank color="greyed" />}
                                        </IconButton>}
                                        {resource.exists && <IconButton disabled color="greyed" size="small">
                                            <CheckBox />
                                        </IconButton>}
                                        <Box display="flex" flexGrow={1} overflow="hidden" alignItems="center" justifyContent="space-between" gap="20px">
                                            <Box overflow="hidden">
                                                <Typography noWrap variant='subtitle2'>{resource.name}</Typography>
                                                <Typography fontSize="12px" variant='body2'>{resource.resource_type}</Typography>
                                            </Box>
                                            <Tooltip title={marked >= 0 ? "Unmark to delete" : resource.exists ? "Linked resource cannot be deleted" : "Delete resource"}>
                                                <span>
                                                    <IconButton disabled={importing || marked >= 0 || resource.exists} onClick={() => setResourceSelectedForDeletion( resource.id )} color="error" size="small">
                                                        {Icons.default.DeleteIconOutlined}
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                )
                            } )}
                            {LiveSessionStore.getResources.filter( filterFunc ).length === 0 && <Typography border={border[1]} flexGrow={1} borderRadius="5px" bgcolor={palette.contentBg} padding="20px" variant='subtitle2'>
                                No resources in this section
                            </Typography>}
                        </Box>
                        <Box display="flex" gap="20px" padding="20px 10px" alignItems="center">
                            <Button disableElevation disabled={importing} variant='contained' color="primary" onClick={importResources} startIcon={importing ? <CircularProgress size={14} /> : Icons.default.DownloadIcon} >
                                Import
                            </Button>
                            <Button disabled={importing} disableElevation variant="contained" color='error' onClick={handleClose}>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>}
                {!loading && LiveSessionStore.getResources.length === 0 && <Box bgcolor={palette.contentBg} borderRadius="5px" padding="50px 20px" display="flex" flexDirection="column" gap="20px" alignItems="center" justifyContent="center">
                    <img src={noResource} width="120px" alt="No resources present" />
                    <Typography variant="subtitle2">No resources to show!  </Typography>
                    <Typography variant='subtitle2' sx={{ color: "grey", fontSize: "14px", cursor: "pointer", '&:hover': { color: "darkgrey" } }} onClick={() => setLinkMode( 0 )} alignItems="center" display="flex" gap="5px">
                        <AddRounded />
                        Add resources
                    </Typography>
                </Box>}
            </Box>}
        </Dialog>
    )
} )

export default AddResourcesDialog