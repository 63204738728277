import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { Icons } from '../../../utils/utilities'
import { useOrganizationStore } from '../../../store/organization.store'
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material'

const AddEditAuditor = ( { getAuditors, state, setState, mode, auditor } ) => {

    const [loading, setLoading] = useState( true )
    const [autoFilled, setAutoFilled] = useState( false )
    const [allBranches, setAllBranches] = useState( [] )
    const [inProgress, setInProgress] = useState( false )
    const [facultyLoaded, setFacultyLoaded] = useState( null )
    const [instituteBranches, setInstituteBranches] = useState( [] )
    const [loadingFacultyDetails, setLoadingFacultyDetails] = useState( false )

    const OrganizationStore = useOrganizationStore()

    const fetchFacultyDetails = async ( e, setFieldValue ) => {
        try {
            const id = e.target.value
            if ( id && id.trim() !== "" ) {
                setLoadingFacultyDetails( true )

                const { data: { data: faculty } } = await api.fetchFacultyDetailsById( id )

                if ( faculty ) {
                    setFieldValue( 'auditor_id', faculty?.user_id || id )
                    setFieldValue( 'auditor_name', faculty?.employee_name || "" )

                    if ( faculty?.institute_name_short ) {
                        setFieldValue( 'institute', faculty.institute_name_short )
                        const instituteDepartments = allBranches.filter( item => item.institute_name_short.trim() === faculty.institute_name_short.trim() )
                        setInstituteBranches( instituteDepartments )
                        setFieldValue( 'branches_allowed_for_auditing', [] )
                    } else {
                        setFieldValue( 'institute', "-" )
                    }
                    setAutoFilled( true )
                } else {
                    setAutoFilled( false )
                    toast( "No data found for the entered username/AUID. Please enter details manually" )
                }
                setFacultyLoaded( faculty || {} )
            }
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong!" )
        } finally {
            setLoadingFacultyDetails( false )
        }
    }

    const handleInstituteChange = ( e, setValue ) => {
        const instituteDepartments = allBranches.filter( item => item.institute_name_short.trim() === e.target.value.trim() )
        setInstituteBranches( instituteDepartments )
        setValue( 'institute', e.target.value )
        setValue( 'branches_allowed_for_auditing', [] )
    }

    const handleBranchChange = ( e, setValue ) => {
        const { target: { value } } = e
        setValue( 'branches_allowed_for_auditing', typeof value === 'string' ? value.split( ',' ) : value )
    }

    const submit = async ( values, { resetForm } ) => {
        setInProgress( true )
        if ( values?.auditor_id && values?.auditor_id.match( /^(\w{2}\d{6})$/ ) ) {
            try {
                mode === "add" ? await api.createAcademicProgramAuditor( values ) : await api.updateAcademicProgramAuditor( auditor.id, { branches_allowed_for_auditing: values.branches_allowed_for_auditing } )
                await getAuditors()
                resetForm()
                setState( false )
            } catch ( err ) {
                console.log( err )
                toast( err?.response?.data?.message || "Something went wrong!" )
            } finally {
                setInProgress( false )
            }
        } else {
            toast( "Please enter valid AUID for manually entered data!" )
        }
    }


    const initialValues = {
        auditor_id: mode === "edit" ? auditor.auditor_id : '',
        auditor_name: mode === "edit" ? auditor.auditor_name : '',
        institute: mode === "edit" ? auditor.institute : "-",
        branches_allowed_for_auditing: mode === "edit" ? auditor.branches_allowed_for_auditing : []
    }

    const validationSchema = Yup.object().shape( {
        auditor_id: Yup.string().required( "AUID is required." ),
        auditor_name: Yup.string().required( "Auditor name is required." ),
        institute: Yup.string().not( ["-"], "Select institute." ).required( "Institute is required" ),
        branches_allowed_for_auditing: Yup.array().min( 1, "At least one branch should be specified" ).required( "At least one branch should be specified" )
    } )

    const handleClose = ( resetForm ) => {
        resetForm()
        setState( false )
    }

    useEffect( () => {
        const getData = async () => {
            try {
                await OrganizationStore.fetchOrganization()
                const { data } = await api.fetchAcademicChecklistDepartments()
                setAllBranches( data.data )
                if ( mode === "edit" ) {
                    const instituteDepartments = data.data.filter( item => item.institute_name_short.trim() === auditor.institute.trim() )
                    setInstituteBranches( instituteDepartments )
                }
            } catch ( err ) {
                toast( err?.response?.data?.message || "Something went wrong while fatching institutes and departments data!" )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [OrganizationStore, mode, auditor] )

    return (
        <Dialog PaperProps={{ sx: { width: "85%", maxWidth: "650px" } }} open={state}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>
                Add new academic progress auditor
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {( { values, setFieldValue, resetForm } ) => (
                            <Form>
                                {mode === "add" && <FormControl fullWidth margin="normal">
                                    <Field onBlur={( e ) => fetchFacultyDetails( e, setFieldValue )} as={TextField} type="text" label="AUID *" name="auditor_id" />
                                    {loadingFacultyDetails && <Typography variant="subtitle2" color="GrayText"> <CircularProgress size={14} /> Loading the faculty details... </Typography>}
                                    <Typography variant="subtitle2" fontSize="12px" color="error.dark"> <ErrorMessage name='auditor_id' /> </Typography>
                                </FormControl>}
                                <FormControl fullWidth margin="normal">
                                    <Field disabled={mode === "edit" || !facultyLoaded || autoFilled} as={TextField} type="text" label="Auditor name *" name="auditor_name" />
                                    <Typography variant="subtitle2" fontSize="12px" color="error.dark"> <ErrorMessage name='auditor_name' /> </Typography>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <InputLabel >Institute *</InputLabel>
                                    <Field disabled={( !facultyLoaded && mode !== "edit" ) || autoFilled} onChange={( e ) => { handleInstituteChange( e, setFieldValue ) }} as={Select} sx={{ textTransform: "capitalize" }} name="institute" label="Institute *" >
                                        <MenuItem disabled dense value="-">{loading ? <Typography fontSize="14px" display="flex" gap="10px" alignItems="center"> <CircularProgress size={14} /> Loading institutes... </Typography> : "Select Institute"}</MenuItem>
                                        {!loading && OrganizationStore.getOrganization.map( int => (
                                            <MenuItem dense value={int.institute_name_short} sx={{ fontSize: "14px", textTransform: "capitalize" }} key={int.institute_name_short} >{( int.institute_name ).toLowerCase()}</MenuItem>
                                        ) )}
                                    </Field>
                                    {!autoFilled && <Typography variant="subtitle2" fontSize="12px" color="gray">Please select only faculty's respective intitute.</Typography>}
                                    <Typography variant="subtitle2" fontSize="12px" color="error.dark"> <ErrorMessage name="institute" /> </Typography>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel  >Branches *</InputLabel>
                                    <Field
                                        renderValue={( selected ) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map( ( value ) => {
                                                    const branch = allBranches.find( k => k.course_branch_assignment_id === value )
                                                    return (
                                                        <Typography key={value} padding="0 5px" borderRadius="5px" textTransform="capitalize" border="2px dotted #939393">{`${branch.course_name.toLowerCase()} - ${branch.course_branch_short_name}`}</Typography>
                                                    )
                                                } )}
                                            </Box>
                                        )}
                                        disabled={( !facultyLoaded || values.institute === "-" ) && mode !== "edit"} multiple sx={{ textTransform: "capitalize" }} as={Select} type="text" value={values.branches_allowed_for_auditing} onChange={( e ) => handleBranchChange( e, setFieldValue )} label="Branches *" name="branches_allowed_for_auditing">
                                        <MenuItem dense disabled value="-">{loading ? <Typography fontSize="14px" display="flex" gap="10px" alignItems="center"> <CircularProgress size={14} /> Loading branches... </Typography> : "Select Department"}</MenuItem>
                                        {!loading && instituteBranches.length > 0 && instituteBranches.map( branch => (
                                            <MenuItem dense value={branch.course_branch_assignment_id} sx={{ fontSize: "14px", textTransform: "capitalize", display: "flex", gap: "5px", alignItems: "center" }} key={branch.course_branch_assignment_id} >
                                                <Box display="flex" alignItems="center" gap="5px">
                                                    {values.branches_allowed_for_auditing.includes( branch.course_branch_assignment_id ) ? <CheckBoxOutlined sx={{ fontSize: "16px", color: "black" }} /> : <CheckBoxOutlineBlank sx={{ fontSize: "16px", color: "lightgray" }} />}
                                                    {branch.course_name.toLowerCase()} {" - "} <b> {branch.course_branch_short_name}</b>
                                                </Box>
                                            </MenuItem>
                                        ) )}
                                    </Field>
                                    <Typography variant="subtitle2" fontSize="12px" color="error.dark"> <ErrorMessage name='branches_allowed_for_auditing' /> </Typography>
                                </FormControl>
                                <Box display="flex" gap="10px" alignItems="center">
                                    <Button type="submit" variant="contained" color="primary" startIcon={inProgress ? <CircularProgress size={14} /> : mode === "add" ? Icons.default.AddIcon : Icons.default.EditIcon} disableElevation disabled={inProgress} sx={{ textTransform: "capitalize" }} >{mode}{inProgress ? "ing" : ""} auditor{inProgress ? "..." : ""}</Button>
                                    <Button disabled={inProgress} onClick={() => handleClose( resetForm )} variant="outlined" color="error" startIcon={Icons.default.CloseIcon} sx={{ textTransform: "capitalize" }} >Cancel</Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AddEditAuditor
