import { Box, IconButton, TableCell, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Icons } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'

const EntitiesTableRow = ( { item, setSelectedEntity } ) => {

    const ResumeBuilderStore = useResumeBuilderStore()
    const { palette } = useTheme()

    const deleteEntity = async () => {
        try {
            await api.deleteResumeEntity( item.id )
            await ResumeBuilderStore.fetchEntities()
        } catch ( err ) {
            console.log( err )
            toast( ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    return (
        <TableRow sx={{ "&:hover": { background: palette.rowHover } }}>
            <TableCell sx={{ padding: "5px 10px", fontWeight: "600" }}>{item.entity_name}</TableCell>
            <TableCell sx={{ padding: "5px 10px", display: { md: "table-cell", xs: "none", maxWidth: "200px" } }}> <Typography fontSize="14px" title={item.description} noWrap>{item.description}</Typography> </TableCell>
            <TableCell sx={{ padding: "5px 10px" }}>{item.entity_detail.key}</TableCell>
            <TableCell sx={{ padding: "5px 10px" }}>{item.entity_detail.label}</TableCell>
            <TableCell sx={{ padding: "5px 10px" }}>{String( item.entity_detail.label_show )}</TableCell>
            <TableCell sx={{ padding: "5px 10px" }}>{item.entity_detail.data_type}{item.entity_detail.longText !== false && item.entity_detail.longText === true && "(long)"}</TableCell>
            <TableCell sx={{ padding: "5px 10px" }}>
                <Box display="flex" alignItems="center" gap="10px">
                    <Tooltip title="Edit Entity" placement='top-start'>
                        <IconButton onClick={() => setSelectedEntity( item )} size='small' color='secondary'>
                            {Icons.default.EditIcon}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Entity" placement='top-start'>
                        <IconButton onClick={deleteEntity} size='small' color='error'>
                            {Icons.default.DeleteIcon}
                        </IconButton>
                    </Tooltip>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default EntitiesTableRow
