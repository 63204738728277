import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, Typography } from '@mui/material'
import CustomSwitch from '../common/CustomSwitch'
import { useSearchParams } from 'react-router-dom'
import { Icons } from '../../utils/utilities'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const semesterNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

const AuditFilterDialog = ( { state, setState, getData, filtersDetails, setFiltersDetails } ) => {

    const [selectedSemesters, setSelectedSemesters] = useState( [] )
    const [applyingFilters, setApplyingFilters] = useState( false )
    const [removingFilters, setRemovingFilters] = useState( false )
    const [newFilters, setNewFilters] = useState( {} )

    const [searchParams] = useSearchParams()
    const selectedBranch = searchParams.get( "selectedBranch" )
    const academicYear = searchParams.get( "academicYear" )

    const handleOnlyUptoCurrentSemChange = async ( e ) => {
        if ( e.target.checked ) {
            setNewFilters( { ...newFilters, filter: "uptocurrentsem", filterData: "" } )
        } else {
            let updatedFilters = { ...newFilters, filter: "", filterData: "" }
            delete updatedFilters["filter"]
            setNewFilters( updatedFilters )
        }
    }

    const handleOnlyCurrentSemChange = ( e ) => {
        if ( e.target.checked ) {
            setNewFilters( { ...newFilters, filter: "onlycurrentsem", filterData: "" } )
        } else {
            let updatedFilters = { ...newFilters, filter: "", filterData: "" }
            delete updatedFilters["filter"]
            setNewFilters( updatedFilters )
        }
    }

    const handleOnlyPendingChange = ( e ) => {
        if ( e.target.checked ) {
            setNewFilters( { ...newFilters, frontFilter: "onlypending" } )
        } else
            setNewFilters( { ...newFilters, frontFilter: null } )

    }

    const handleOnlyCompletedChange = ( e ) => {
        if ( e.target.checked ) {
            setNewFilters( { ...newFilters, frontFilter: "onlycompleted" } )
        } else
            setNewFilters( { ...newFilters, frontFilter: null } )
    }

    const addSemToFilter = ( sem ) => {
        setNewFilters( { ...newFilters, filter: "semspecific", filterData: [...( newFilters.filterData || "" ).split( "," ), sem].join( "," ) } )
    }

    const removeSemFromFilter = ( sem ) => {
        let newList = ( newFilters.filterData || "" ).split( "," )
        const index = newList.indexOf( sem )
        newList.splice( index, 1 )
        setNewFilters( { ...newFilters, filter: "semspecific", filterData: newList.join( "," ) } )
    }

    const applyFilters = async () => {
        setApplyingFilters( true )
        if ( await getData( selectedBranch, academicYear, newFilters ) ) {
            setFiltersDetails( newFilters )
            setState( false )
            toast( "Filters applied!" )
        }
        setApplyingFilters( false )
    }
    const removeFilters = async () => {
        setRemovingFilters( true )
        setNewFilters( {} )
        if ( JSON.stringify( filtersDetails ) !== "{}" && await getData( selectedBranch, academicYear, {} ) ) {
            setFiltersDetails( {} )
            setState( false )
            toast( "Filters removed!" )
        }
        if ( JSON.stringify( filtersDetails ) === "{}" )
            setState( false )
        setRemovingFilters( false )
    }

    useEffect( () => {
        const semsSelected = ( newFilters.filterData || "" ).split( "," ).filter( i => i !== "" )
        setSelectedSemesters( semsSelected )
        console.log( semsSelected )
    }, [newFilters] )

    return (
        <Dialog open={state}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>Filters</DialogTitle>
            <DialogContent>
                <Box display="flex" gap="20px" marginTop="10px" flexDirection="column">
                    <Box>
                        <FormControlLabel labelPlacement='start' sx={{ display: "flex", justifyContent: "space-between", margin: "0", borderBottom: "1px solid #d3d3d3", alignItems: "center" }} control={<CustomSwitch onChange={handleOnlyUptoCurrentSemChange} checked={newFilters?.filter === "uptocurrentsem"} />} label="Fetch progress only up to current semester" />
                        <FormControlLabel labelPlacement='start' sx={{ display: "flex", justifyContent: "space-between", margin: "0", borderBottom: "1px solid #d3d3d3", alignItems: "center" }} control={<CustomSwitch onChange={handleOnlyCurrentSemChange} checked={newFilters?.filter === "onlycurrentsem"} />} label="Show current semester only" />
                        <FormControlLabel labelPlacement='start' sx={{ display: "flex", justifyContent: "space-between", margin: "0", borderBottom: "1px solid #d3d3d3", alignItems: "center" }} control={<CustomSwitch onChange={handleOnlyPendingChange} checked={newFilters?.frontFilter === "onlypending"} />} label="Show only pending students" />
                        <FormControlLabel labelPlacement='start' sx={{ display: "flex", justifyContent: "space-between", margin: "0", borderBottom: "1px solid #d3d3d3", alignItems: "center" }} control={<CustomSwitch onChange={handleOnlyCompletedChange} checked={newFilters?.frontFilter === "onlycompleted"} />} label="Show only completed students" />
                    </Box>
                    <Box>
                        <Typography color="secondary" gutterBottom variant="subtitle2">Filter on semesters</Typography>
                        <Box display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                            {semesterNumbers.map( sem => (
                                <Chip key={sem} color={selectedSemesters.includes( sem ) ? "success" : "default"} deleteIcon={selectedSemesters.includes( sem ) ? Icons.default.CancelIcon : Icons.default.AddIcon} onDelete={selectedSemesters.includes( sem ) ? () => removeSemFromFilter( sem ) : () => addSemToFilter( sem )} sx={{ fontWeight: "bold", border: "1px solid #d3d3d3" }} label={`Sem ${sem}`} />
                            ) )}
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" flexWrap="wrap" gap="10px">
                        <Button disabled={applyingFilters || removingFilters || ( JSON.stringify( filtersDetails ) === JSON.stringify( newFilters ) )} onClick={applyFilters} disableElevation variant='contained' startIcon={applyingFilters ? <CircularProgress size={14} /> : Icons.default.FilterAlt} sx={{ textTransform: "capitalize" }}>{applyingFilters ? "Applying filters..." : "Apply filters"}</Button>
                        <Button disabled={applyingFilters || removingFilters} onClick={removeFilters} color='secondary' variant='outlined' startIcon={removingFilters ? <CircularProgress size={14} /> : Icons.default.FilterAltOff} sx={{ textTransform: "capitalize" }}>{removingFilters ? "Removing filters..." : "Remove filters"}</Button>
                        <Button disabled={applyingFilters || removingFilters} color='error' onClick={() => { setState( false ); setNewFilters( filtersDetails ) }} variant='outlined' startIcon={Icons.default.CloseIcon} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AuditFilterDialog
