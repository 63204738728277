import { Box, Button, Dialog, Icon, DialogContent, DialogTitle, FormControl, IconButton, FormHelperText, TextField, Typography, CircularProgress } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useState } from 'react'
import { Icons, defaultOnlineCourseThumbs } from '../../utils/utilities'
import * as Yup from 'yup'
import { useOnlineCourseStore } from '../../store/onlinecourse.store'
import { useParams } from 'react-router-dom'

const EditCourseDialog = ( { state, course, setState } ) => {

    const [thumbnailSrc, setThumbnailSrc] = useState( null )
    const [thumbnail, setThumbnail] = useState( null )
    const [isUpdating, setIsUpdating] = useState( false )
    const [defaultThumb, setDefaultThumb] = useState( [0, 1, 2].at( Math.floor( Math.random() * 3 ) ) )

    const OnlineCourseStore = useOnlineCourseStore()
    const { course_id } = useParams()

    const initialValues = {
        title: course.title,
        description: course.description,
        key_word: course.key_word,
    }

    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( "Title is required!" ),
        key_word: Yup.string().required( "Key words covered should be listed!" ),
        description: Yup.string().required( "Description is required!" )
    } )

    const handelThumbnailChange = ( e ) => {
        const file = e.target.files[0]
        setThumbnail( file )
        const fileReader = new FileReader()
        fileReader.onload = () => {
            setThumbnailSrc( fileReader.result )
        }
        fileReader.readAsDataURL( file )
    }

    const updateCourse = async ( values ) => {
        const formData = new FormData()
        formData.append( 'title', values.title )
        formData.append( 'description', values.description )
        formData.append( 'key_word', values.key_word )
        if ( thumbnail )
            formData.append( 'thumbnail', thumbnail, `${values.title}-thumb.${thumbnail.name.split( "." ).pop()}` )
        else
            formData.append( 'defaultThumb', defaultThumb )
        setIsUpdating( true )
        await OnlineCourseStore.updateCourse( course_id, formData )
        console.log( "UPDATE" )
        await OnlineCourseStore.fetchCourse( course_id )
        setIsUpdating( false )
        setState( false )
    }

    return (
        <Dialog open={state}>
            <DialogTitle>Edit online course</DialogTitle>
            <DialogContent>
                <Formik onSubmit={updateCourse} initialValues={initialValues} validationSchema={validationSchema}>
                    {() => (
                        <Form>
                            <FormControl margin='normal' fullWidth>
                                <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} type="text" name="title" label="Online course title" />
                                <FormHelperText sx={{ marginLeft: 0, color: 'error.main', fontWeight: "bold" }}> <ErrorMessage name='title' /> </FormHelperText>
                            </FormControl>
                            <FormControl margin='normal' fullWidth>
                                <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} multiline rows={3} type="text" name="description" label="Online course description" />
                                <FormHelperText sx={{ marginLeft: 0, color: 'error.main', fontWeight: "bold" }}> <ErrorMessage name='description' /> </FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin='normal'>
                                <Box border="1px solid #cbcbcb" sx={{ "&:hover": { borderColor: "#000" } }} borderRadius="5px" padding="10px" position="relative">
                                    <input id="thumbnail-input" onChange={handelThumbnailChange} accept='image/jpg,image/jpeg' type="file" name="thumbnail" className="thumbnail-input" />
                                    <Typography display="flex" gap="10px" variant="subtitle2" alignItems="center"><Icon sx={{ marginBottom: "5px" }}>{Icons.default.UploadFileIcon}</Icon> Upload thumbnail</Typography>
                                    <Typography fontSize="14px" color="textSecondary">Max. size 10Mb, supported formats .JPG and .JPEG</Typography>
                                </Box>
                                {thumbnailSrc && <Box justifyContent="space-between" bgcolor="#eee" borderRadius="5px" marginTop="10px" display="flex" gap="10px">
                                    <Box bgcolor="#eee" borderRadius="5px" padding="5px" display="flex" gap="10px">
                                        <img height="40px" src={thumbnailSrc} alt="thumbnail" />
                                        <Box>
                                            <Typography variant='subtitle2' fontSize="14px">{thumbnail.name}</Typography>
                                            <Typography fontSize="12px">{thumbnail.size} Bytes</Typography>
                                        </Box>
                                    </Box>
                                    <IconButton disableRipple disableTouchRipple size='small' onClick={() => { setThumbnail( null ); setThumbnailSrc( null ); document.getElementById( 'thumbnail-input' ).value = '' }}> {Icons.default.CloseIcon} </IconButton>
                                </Box>}
                                {( !thumbnailSrc && !course.thumbnail_uri.includes( 'http' ) ) && <Box marginTop="10px" borderRadius="5px" padding="10px" bgcolor="#eee">
                                    <Typography gutterBottom>Or select from one of these</Typography>
                                    <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap">
                                        {defaultOnlineCourseThumbs.map( ( im, i ) => (
                                            <img key={i} onClick={() => setDefaultThumb( i )} className={`default-thumb-preview${defaultThumb === i ? " selected-preview-img" : ""}`} src={defaultOnlineCourseThumbs[i]} width="100px" height="70px" alt={`thumb${i + 1}`} />
                                        ) )}
                                    </Box>
                                </Box>}
                            </FormControl>
                            <FormControl margin='normal' fullWidth>
                                <Field as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} type="text" name="key_word" label="Keywords" />
                                <FormHelperText sx={{ marginLeft: 0, fontWeight: "bold" }}>Enter keywords as comma separated values.</FormHelperText>
                                <FormHelperText sx={{ marginLeft: 0, color: 'error.main', fontWeight: "bold" }}> <ErrorMessage name='key_word' /> </FormHelperText>
                            </FormControl>
                            <Box display="flex" gap="10px" alignItems="center">
                                <Button disabled={isUpdating} type="submit" variant="contained" disableElevation sx={{ height: "53px", textTransform: "capitalize" }}>{isUpdating ? <Typography display="flex" alignItems="center" gap="5px"> <CircularProgress size={14} /> Updating...</Typography> : "Update online course"}</Button>
                                <Button disabled={isUpdating} onClick={() => setState( false )} color='error' variant="outlined" sx={{ height: "53px", textTransform: "capitalize" }}>Cancel</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default EditCourseDialog
