import { Box, Button, Checkbox, IconButton, FormControl, FormControlLabel, Paper, TextField, Typography, InputLabel, FormHelperText, Select, MenuItem, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { Icons } from '../../../utils/utilities'
import { useState } from 'react'
import { observer } from 'mobx-react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import DocumentUploadInput from './DocumentUploadInput'
import { useStudentResumeFormStore } from '../../../store/studentresumeform.store'
import MultipleValueArrayInput from './MultipleValueArrayInput'
import DateOrPresentInput from './DateOrPresentInput'
import CgpaOrPercentageInput from './CgpaOrPercentageInput'
import AddEditDialog from './AddEditDialog'
import ImageInput from './ImageInput'
import { DOCUMENTS_BASE_FOLDER } from '../../../utils/resume-builder-utilities'
import { UploadFile } from '@mui/icons-material'
import api from '../../../service/api'




const AddBlob = ( { onClickAction = () => { } } ) => {

    return (
        <Box
            width="150px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="150px"
            sx={{
                backgroundImage: `url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22c%22%3E%3Cstop offset=%220%25%22 stop-color=%22%23bcbcbc%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22%235d4f52%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22b%22%3E%3Cpath fill=%22currentColor%22 d=%22M724 650.5Q598 801 423 742T201.5 466q-46.5-217 188-282.5T737 309q113 191-13 341.5Z%22%2F%3E%3C%2FclipPath%3E%3Cfilter id=%22a%22 x=%22-50vw%22 y=%22-50vh%22 width=%22100vw%22 height=%22100vh%22%3E%3CfeFlood flood-color=%22%23fff%22 result=%22neutral-gray%22%2F%3E%3CfeTurbulence type=%22fractalNoise%22 baseFrequency=%222.5%22 numOctaves=%22100%22 stitchTiles=%22stitch%22 result=%22noise%22%2F%3E%3CfeColorMatrix in=%22noise%22 type=%22saturate%22 values=%220%22 result=%22destaturatedNoise%22%2F%3E%3CfeComponentTransfer in=%22desaturatedNoise%22 result=%22theNoise%22%3E%3CfeFuncA type=%22table%22 tableValues=%220 0 0.3 0%22%2F%3E%3C%2FfeComponentTransfer%3E%3CfeBlend in=%22SourceGraphic%22 in2=%22theNoise%22 mode=%22soft-light%22 result=%22noisy-image%22%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3Cg filter=%22url(%23a)%22 clip-path=%22url(%23b)%22%3E%3Cpath fill=%22url(%23c)%22 d=%22M724 650.5Q598 801 423 742T201.5 466q-46.5-217 188-282.5T737 309q113 191-13 341.5Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E")`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "repeat",
                cursor: "pointer"
            }}
            onClick={onClickAction}
        >
            <svg width="140" height="140" viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
                <path d="M70 56V84M56 70H84" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    )
}

const SectionForm = observer( ( { section, setResponseJson } ) => {

    const [addMode, setAddMode] = useState( false )
    const [sectionResponseJson, setSectionResponseJson] = useState( {} )

    const { palette } = useTheme()

    const StudentResumeFormStore = useStudentResumeFormStore()

    const handleFieldChange = ( type, key, value ) => {
        setSectionResponseJson( { ...sectionResponseJson, [key]: value } )
        StudentResumeFormStore.setChangesMade( true )
        saveChanges( section.section_title, { ...sectionResponseJson, [key]: value } )
    }

    const clearSection = async ( multiple, sectionTitle ) => {
        const newValue = await StudentResumeFormStore.clearSection( multiple, sectionTitle )
        setSectionResponseJson( newValue )
    }

    const deleteMultipleValueAt = async ( index ) => {
        const newData = [...sectionResponseJson]
        const deleted = newData[index]

        const files = Object.values( deleted ).filter( i => i.includes( DOCUMENTS_BASE_FOLDER ) )
        if ( files.length > 0 ) {
            try {
                await api.deleteResumeDocuments( { files } )
                newData.splice( index, 1 )
                setSectionResponseJson( newData )
                StudentResumeFormStore.setChangesMade( true )
                saveChanges( section.section_title, newData )
            } catch ( err ) {
                console.log( err )
            }
        } else {
            newData.splice( index, 1 )
            setSectionResponseJson( newData )
            StudentResumeFormStore.setChangesMade( true )
            saveChanges( section.section_title, newData )
        }
    }

    const saveChanges = async ( sectionTitle, sectionData ) => {
        StudentResumeFormStore.setUpdatedResumeData( { ...StudentResumeFormStore.getUpdatedResumeData, [sectionTitle]: sectionData } )
    }

    const handleEmailFieldChange = ( attrkey, e ) => {
        try {
            StudentResumeFormStore.validateEmail( section, attrkey, e.target.value )
            handleFieldChange( "email", attrkey, e.target.value.trim() )
        } catch ( err ) { }
    }


    const handleUrlFieldChange = ( attrkey, e ) => {
        try {
            StudentResumeFormStore.validateURL( section, attrkey, e.target.value )
            handleFieldChange( "url", attrkey, e.target.value.trim() )
        } catch ( err ) { }
    }

    useEffect( () => {
        const setInitialValues = () => {
            if ( !section.multiple ) {
                const savedData = StudentResumeFormStore.getSavedFormData[section.section_title] ? StudentResumeFormStore.getSavedFormData[section.section_title] : {}
                const initialValues = {}
                for ( let i = 0; i < section.attributes.length; i++ ) {
                    const group = section.attributes[i]
                    for ( let j = 0; j < group.length; j++ ) {
                        const attr = group[j]
                        if ( attr.entity_detail.data_type === "boolean" )
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : false
                        else if ( attr.entity_detail.data_type === "array" )
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : []
                        else if ( attr.entity_detail.data_type === 'linkwithlabel' ) {
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : ''
                            initialValues[`${attr.entity_detail.key}-link`] = savedData[`${attr.entity_detail.key}-link`] ? savedData[`${attr.entity_detail.key}-link`] : ''
                        }
                        else if ( attr.entity_detail.data_type === "select" )
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : ''
                        else {
                            initialValues[attr.entity_detail.key] = savedData[attr.entity_detail.key] ? savedData[attr.entity_detail.key] : ''
                        }
                    }
                }
                setSectionResponseJson( initialValues )
            } else {
                const savedData = StudentResumeFormStore.getSavedFormData[section.section_title] ? StudentResumeFormStore.getSavedFormData[section.section_title] : []
                setSectionResponseJson( savedData )
            }
        }
        setInitialValues()
    }, [section, StudentResumeFormStore] )

    return (
        <Paper>
            <Box display="flex" alignItems="center" justifyContent="space-between" padding="10px 20px" borderBottom="1px solid #d3d3d3">
                <Typography variant='h6'>
                    {section.section_title}
                </Typography>
                <Box display="flex" alignItems="center" gap="10px">
                    <Button color="error" onClick={() => clearSection( section.multiple, section.section_title )} size="small" sx={{ textTransform: "capitalize" }}>Clear Section</Button>
                    {section.multiple && sectionResponseJson.length > 0 && <Button sx={{ textTransform: "capitalize" }} onClick={e => setAddMode( true )} startIcon={Icons.default.AddIcon} size='small' color='secondary'>Add {section.section_title}</Button>}
                </Box>
            </Box>
            {section.multiple && <Box>
                {sectionResponseJson.length > 0 && sectionResponseJson.map( ( detail, index ) => (
                    <Paper key={index} sx={{ justifyContent: "space-between", alignItems: "flex-start", margin: "5px", display: "flex", padding: "10px 20px", border: "1px solid #d3d3d3" }} elevation={1}>
                        <Box display="flex" flexDirection="column" gap="5px">
                            {section.attributes.map( ( group, groupIndex ) => (
                                <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }} elevation={section.multiple ? 2 : 0} key={groupIndex}>
                                    {group.map( ( attr, attrIndex ) => {
                                        if ( detail[attr.entity_detail.key].includes( DOCUMENTS_BASE_FOLDER ) )
                                            return (
                                                <Box key={attrIndex} borderRadius="5px" padding="5px" display="flex" alignItems="center" gap="10px" border="1px solid #d3d3d3">
                                                    <UploadFile fontSize='small' />
                                                    <Typography variant='subtitle2'>{detail[attr.entity_detail.key]}</Typography>
                                                </Box>
                                            )
                                        else
                                            return (
                                                <Typography fontSize={groupIndex === 0 ? "18px" : groupIndex === 1 ? "16px" : "14px"} variant={groupIndex === 0 ? "h6" : groupIndex === 1 ? "h5" : "subtitle2"} color={groupIndex === 0 ? "primary" : groupIndex === 1 ? "black" : "textSecondary"} key={attrIndex}>{detail[attr.entity_detail.key]}</Typography>
                                            )
                                    } )}
                                </Box>
                            ) )}
                        </Box><Box display="flex" gap="10px" alignItems="center" >
                            <IconButton onClick={() => { deleteMultipleValueAt( index ) }} sx={{ color: "error.dark" }} size='small'>
                                {Icons.default.DeleteIcon}
                            </IconButton>
                        </Box>
                    </Paper>
                ) )}
                {sectionResponseJson.length === 0 && <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding="20px">
                    <AddBlob />
                    <Typography marginTop="-20px" variant="body2" fontWeight="300" color="GrayText">No {section.section_title} yet!</Typography>
                    <Button onClick={e => setAddMode( true )} variant="outlined" sx={{ textTransform: "capitalize", marginTop: "10px" }} color="greyed" startIcon={Icons.default.AddIcon}>Add one</Button>
                </Box>}
                <AddEditDialog saveChanges={saveChanges} setResponseJson={setResponseJson} sectionResponseJson={sectionResponseJson} setSectionResponseJson={setSectionResponseJson} section={section} setAddMode={setAddMode} addMode={addMode} mode={"add"} />
            </Box>}
            {!section.multiple && <Box padding="20px">
                <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }} elevation={section.multiple ? 2 : 0}>
                    {section.attributes.map( ( group, groupIndex ) => (
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }} key={groupIndex}>
                            {/* eslint-disable-next-line */}
                            {group.map( ( attr, attrIndex ) => {
                                const type = attr.entity_detail.data_type
                                const children = attr.entity_detail.children
                                const attrkey = attr.entity_detail.key
                                if ( type === 'string' || type === 'number' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" gutterBottom color={palette.form.labelColor}>{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson && sectionResponseJson && sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ""} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} type={type === 'string' ? "text" : "number"} rows={4} />
                                        </Box>
                                    )
                                } else if ( type === 'boolean' ) {
                                    return (
                                        <FormControl margin='dense' fullWidth sx={{ flexGrow: "1" }} key={attrIndex}>
                                            <FormControlLabel sx={{ marginLeft: "10px" }} control={<Checkbox color='primary' onChange={( e ) => handleFieldChange( type, attrkey, e.target.checked )} checked={sectionResponseJson && sectionResponseJson && sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : false} />} label={<Typography>{attr.entity_detail.label}</Typography>} />
                                        </FormControl>
                                    )
                                } else if ( type === 'email' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography gutterBottom variant="subtitle2" color={palette.form.labelColor}>{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson && sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ""} size="small" onChange={( e ) => handleEmailFieldChange( attrkey, e )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} type="email" rows={4} />
                                            {StudentResumeFormStore.getEmailTypeErrors[`${section.section_title}-${attrkey}`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid E-mail </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'date' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color={palette.form.labelColor}>{attr.entity_detail.label}</Typography> </InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer sx={{ width: "100%" }} components={['DatePicker']}>
                                                    <DatePicker
                                                        onClick={() => console.log( "Date click" )}
                                                        disableFuture
                                                        sx={{ width: "100%" }}
                                                        format='DD/MM/YYYY'
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs( sectionResponseJson && sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : "" )}
                                                        onAccept={( e ) => {
                                                            handleFieldChange( type, attrkey, new Date( e.$d ).toLocaleDateString( 'en-US' ) )
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            <Typography variant="subtitle2" sx={{ fontStyle: "italic", fontSize: "12px" }} color={palette.form.labelColor}>Date selected: {sectionResponseJson && sectionResponseJson[attrkey] ? new Date( sectionResponseJson[attrkey] ).toLocaleDateString( 'en-IN' ) : ""}</Typography>
                                        </Box>
                                    )
                                } else if ( type === 'url' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography gutterBottom variant="subtitle2" color={palette.form.labelColor}>{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson && sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ""} size="small" onChange={( e ) => handleUrlFieldChange( attrkey, e )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} rows={4} />
                                            {StudentResumeFormStore.getUrlTypeErrors[`${section.section_title}-${attrkey}`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid URL, Start with https:// or http:// </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'linkwithlabel' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography gutterBottom variant="subtitle2" color={palette.form.labelColor}>{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson && sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ""} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth type="text" rows={4} />
                                            <InputLabel sx={{ marginTop: "10px" }}><Typography variant="subtitle2" color={palette.form.labelColor}>{attr.entity_detail.link_label}</Typography> </InputLabel>
                                            <TextField value={sectionResponseJson[`${attrkey}-link`] ? sectionResponseJson[`${attrkey}-link`] : ""} size="small" onChange={( e ) => handleUrlFieldChange( `${attrkey}-link`, e )} variant='outlined' fullWidth type="text" rows={4} />
                                            {StudentResumeFormStore.getUrlTypeErrors[`${attrkey}-link`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid URL </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'file' ) {
                                    return ( <ImageInput attr={attr} handleFieldChange={handleFieldChange} section={section} attrkey={attrkey} sectionResponseJson={sectionResponseJson} key={attrIndex} /> )

                                } else if ( type === 'cgpaorpercentage' ) {
                                    let fieldValue = sectionResponseJson[attrkey] || ""
                                    if ( fieldValue !== "" ) {
                                        if ( fieldValue.includes( "%" ) ) {
                                            fieldValue = parseInt( fieldValue.replace( '%', "" ) )
                                        } else {
                                            fieldValue = parseInt( fieldValue.replace( ' CGPA', "" ) )
                                        }
                                    }
                                    return <CgpaOrPercentageInput key={attrIndex} handleFieldChange={handleFieldChange} saveChanges={saveChanges} attr={attr} attrkey={attrkey} value={fieldValue} />
                                } else if ( type === 'dateorpresent' ) {
                                    let fieldValue = sectionResponseJson[attrkey] || ""
                                    if ( fieldValue !== "" ) {
                                        if ( fieldValue !== "present" )
                                            fieldValue = dayjs( fieldValue )
                                    }
                                    return <DateOrPresentInput key={attrIndex} handleFieldChange={handleFieldChange} saveChanges={saveChanges} attr={attr} attrkey={attrkey} value={fieldValue} />
                                } else if ( type === 'select' ) {
                                    return ( <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                        <InputLabel> <Typography variant="subtitle2" color={palette.form.labelColor}>{attr.entity_detail.label}</Typography> </InputLabel>
                                        <Select value={sectionResponseJson && sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : ''} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth>
                                            <MenuItem value={""} disabled>Select {attr.entity_detail.label}</MenuItem>
                                            {children.map( child => (
                                                <MenuItem key={child} value={child}>{child}</MenuItem>
                                            ) )}
                                        </Select>
                                    </Box> )
                                } else if ( type === 'document' ) {
                                    return ( <DocumentUploadInput handleFieldChange={handleFieldChange} section={section} attr={attr} sectionResponseJson={sectionResponseJson} attrkey={attrkey} key={attrIndex} /> )
                                }
                                else {
                                    return (
                                        <MultipleValueArrayInput saveChanges={saveChanges} section={section} sectionResponseJson={sectionResponseJson} values={sectionResponseJson && sectionResponseJson[attrkey] ? sectionResponseJson[attrkey] : []} setSectionResponseJson={setSectionResponseJson} key={attrIndex} attrkey={attrkey} attrIndex={attrIndex} attr={attr} />
                                    )
                                }
                            } )}
                        </Box>
                    ) )}
                </Box>
            </Box>}
        </Paper>
    )
} )

export default SectionForm
