import { Box, Button, Chip, CircularProgress, Collapse, FormControl, FormHelperText, TextField, Typography, Paper, Select, MenuItem, Dialog, DialogContent, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import Layout from '../../components/Layout'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FileInput from '../../components/study-material-components/FileInput'
import BreadcrumbList from '../../components/common/BreadcrumbList'
import { toast } from 'react-toastify'
// import { Axios } from '../../utils/utilities'
import { useNavigate } from 'react-router-dom'
import { useStudyMaterialStore } from '../../store/studymaterial.store'
import { useUserStore } from '../../store/user.store'
import sessionExpiredImg from '../../assets/warning.svg'
import { Icons, flatColors } from '../../utils/utilities'

const AddMaterial = () => {

    const [tags, setTags] = useState( [] )
    const [selectedAttachments, setSelectedAttachments] = useState( [] )
    const [isAddingMaterial, setIsAddingMaterial] = useState( false )
    const [isLoadingSubjects, setIsLoadingSubjects] = useState( true )
    const [availableSubjects, setAvailableSubjects] = useState( [] )
    const [sessionExpired, setSessionExpired] = useState( false )
    const [attachmentsNames, setAttachmentsNames] = useState( [] )
    const [attachmentsSizes, setAttachmentsSizes] = useState( [] )
    const [progress, setProgress] = useState( 0 )
    const navigate = useNavigate()
    const StudyMaterialStore = useStudyMaterialStore()
    const UserStore = useUserStore()

    const { palette, border } = useTheme()

    const initialValues = {
        materialName: '',
        materialDesc: '',
        tags: '',
        subject: '-',
        attachments: null
    }

    const validationSchema = Yup.object().shape( {
        materialName: Yup.string().required( "Material name is required" ),
        subject: Yup.string().required( "Subject is required" ).notOneOf( ["-"], "Please select related subject" ),
        tags: Yup.mixed().required( "Tags is required" ).test( "TAGS", "Please add atleast one tag", ( val ) => {
            return val && val.length > 0
        } )
    } )

    const deleteTag = ( index ) => {
        const t = [...tags]
        t.splice( index, 1 )
        setTags( t )
    }

    const handleKeyDown = ( e, setFieldValue ) => {
        if ( e.code === "Enter" ) {
            e.preventDefault()
            if ( e.target.value.trim() !== '' ) {
                let newTags = e.target.value.split( "," )
                let filteredTags = []
                newTags.forEach( tag => {
                    if ( tag.trim() !== '' ) filteredTags.push( tag )
                } )
                setTags( [...tags, ...filteredTags] )
                setFieldValue( 'tags', [...tags, ...filteredTags].join( "," ) )
                e.target.value = ""
            }
        }
    }

    const progressHandler = ( event ) => {
        const { loaded, total } = event
        let percent = Math.round( ( loaded * 100 ) / total )
        console.log( percent )
        if ( percent <= 100 ) {
            setProgress( percent )
        }
    }

    const handleBlur = ( e, setFieldValue ) => {
        if ( e.target.value.trim() !== '' ) {
            let newTags = e.target.value.split( "," )
            let filteredTags = []
            newTags.forEach( tag => {
                if ( tag.trim() !== '' ) filteredTags.push( tag )
            } )
            setTags( [...tags, ...filteredTags] )
            setFieldValue( 'tags', [...tags, ...filteredTags].join( "," ) )
            e.target.value = ""
        }
    }

    const addMaterial = async ( values, { resetForm } ) => {
        setIsAddingMaterial( true )
        try {
            const formData = new FormData()
            formData.append( 'name', values.materialName )
            formData.append( 'description', values.materialDesc )
            formData.append( 'tags', values.tags )
            formData.append( 'subject', JSON.stringify( UserStore.getUserSubjects.find( sub => sub.subject_assignment_id === values.subject ) ) )
            let emptyName = false
            for ( let i = 0; i < attachmentsNames.length; i++ ) {
                const name = attachmentsNames[i]
                if ( name === "" ) {
                    emptyName = true
                }
            }
            if ( !emptyName ) {
                if ( values.attachments ) {
                    values.attachments.forEach( ( file, i ) => {
                        const ext = file.name.split( "." ).pop()
                        let fileName = encodeURIComponent( `${file.name.split( ext )[0]}-${Date.now()}${i}.${ext}`.replace( /\s/g, '-' ) )
                        formData.append( 'attachments[]', file, fileName )
                    } )
                }
                formData.append( 'attachmentNames[]', JSON.stringify( attachmentsNames ) )
                formData.append( 'attachmentsSizes[]', JSON.stringify( attachmentsSizes ) )
                await StudyMaterialStore.createMaterial( formData, progressHandler )
                navigate( '/faculty/material' )
            } else {
                toast( "Please provide names for all the attachments" )
            }
        } catch ( e ) {
            console.log( e )
            if ( e?.response?.data?.data?.unuploaded?.length > 0 ) {
                setSelectedAttachments( [] )
                setAttachmentsNames( [] )
                let unuploadedFiles = []
                for ( let i = 0; i < e?.response?.data?.data?.unuploaded.length; i++ ) {
                    const file = e?.response?.data?.data?.unuploaded[i]
                    unuploadedFiles.push( file.originalname.replace( /-(\d)+/g, "" ) )
                }
                const toastMsg = <p>Failed to upload these files: <b><i>{unuploadedFiles.join( ", " )}</i></b> due to unsupported format. </p>
                toast( toastMsg )
            } else
                toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsAddingMaterial( false )
        }
    }

    useEffect( () => {
        const getSubjects = async () => {
            if ( !await UserStore.fetchUserSubjects() ) {
                setSessionExpired( true )
            }
            await StudyMaterialStore.fetchMaterials()
            const facultyMaterials = StudyMaterialStore.getMaterials["materials"]
            let availableSubjects = []
            for ( let i = 0; i < UserStore.getUserSubjects.length; i++ ) {
                const sub = UserStore.getUserSubjects[i]
                if ( facultyMaterials.findIndex( material => String( material.subject_id ) === String( sub.subject_id ) ) < 0 ) {
                    availableSubjects.push( sub )
                }
            }
            setAvailableSubjects( availableSubjects )
            setIsLoadingSubjects( false )
        }
        getSubjects()
    }, [UserStore, StudyMaterialStore] )

    return (
        <Box padding="20px" overflow="auto" flexGrow={1}>

            <BreadcrumbList items={[{ link: UserStore.getUser['user_role'] === "STUDENT" ? '/student/material' : '/faculty/material', label: "Study materials" }, "Add new"]} />

            <Dialog open={sessionExpired}>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <img width="150px" src={sessionExpiredImg} alt="Session Expired!" />
                        <Typography marginTop="10px">Session has expired! please login again</Typography>
                        <Button onClick={() => navigate( '/' )} color='error'>Login</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Paper sx={{ paddingBottom: "1px", overflow: "hidden" }}>
                <Box display="flex" gap="10px" sx={{
                    background: `linear-gradient(233.69deg, #6A6790 -93.75%, #1F1E2A 145.73%)`
                }} alignItems="center" padding="20px">
                    <Box>
                        <Typography variant='h5' color="white" fontWeight="normal">Add new material</Typography>
                        <Typography variant='body2' color="white" >Provide all the required information to create a material.</Typography>
                    </Box>
                </Box>
                <Box margin="20px" borderRadius="5px" bgcolor={palette.form.formBg} border={border[1]} padding="20px">
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={addMaterial}>
                        {( { setFieldValue, resetForm, values } ) => (
                            <Form>
                                <FormControl fullWidth margin="normal" sx={{ marginTop: "20px" }}>
                                    <label htmlFor="name">
                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Material name *</Typography>
                                    </label>
                                    <Field size="small" placeholder="Eg: New material for DS" id="name" as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} fullWidth type="text" name="materialName" />
                                    <FormHelperText sx={{ color: "errorMessage.main", fontWeight: "bold", marginLeft: 0 }}> <ErrorMessage name="materialName" /> </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal" >
                                    <label htmlFor="desc">
                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Description *</Typography>
                                    </label>
                                    <Field size="small" placeholder="Description goes here..." id="desc" as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} multiline rows={5} fullWidth type="text" name="materialDesc" />
                                    <FormHelperText> <ErrorMessage name="materialDesc" /> </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <label htmlFor="subject">
                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Material Subject *</Typography>
                                    </label>
                                    <Field size="small" as={Select} id="subject" disabled={isLoadingSubjects} name='subject' sx={{ color: values.subject === "-" && palette.form.placeholder, "&:disabled": { color: "rgba(0,0,0,0.4)" } }} >
                                        <MenuItem sx={{ fontSize: "14px" }} value="-" disabled>{isLoadingSubjects ? <Typography display="flex" alignItems="center" gap="5px" fontSize="14px" > <CircularProgress size={14} />Loading subjects... </Typography> : "Select subject"}</MenuItem>
                                        {!isLoadingSubjects && availableSubjects.map( sub => (
                                            <MenuItem key={sub.subject_assignment_id} sx={{ fontSize: "14px" }} value={sub.subject_assignment_id}>{`${sub.subject_name}-${sub.subject_code} (${sub.ac_year})`}</MenuItem>
                                        ) )}
                                    </Field>
                                    <Typography marginTop="5px" fontSize="12px">If subject not displayed in the dropdown then you already have a material for that subject</Typography>
                                    <FormHelperText sx={{ color: "errorMessage.main", fontWeight: "bold", marginLeft: 0 }}> <ErrorMessage name="subject" /> </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth>
                                    <label htmlFor="tags">
                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Add Tags *{`(Type tags as comma seperated values)`}</Typography>
                                    </label>
                                    <Box >
                                        <TextField id="tags" size="small" type="text" placeholder='Add new tag' fullWidth onBlur={( e ) => { handleBlur( e, setFieldValue ) }} onKeyDown={e => { handleKeyDown( e, setFieldValue ) }} />
                                        <FormHelperText sx={{ color: "errorMessage.main", fontWeight: "bold", marginLeft: 0 }}> <ErrorMessage name="tags" /> </FormHelperText>
                                        <Collapse in={tags.length > 0}>
                                            <Typography marginTop="10px" variant='subtitle2' gutterBottom color="textSecondary">Added tags  </Typography>
                                            <Box display="flex" alignItems="center" gap="10px">
                                                {tags.length > 0 && tags.map( ( tag, index ) => {
                                                    const color = flatColors[index % flatColors.length]
                                                    return (
                                                        <Chip deleteIcon={Icons.default.CloseIcon} key={index} sx={{ borderRadius: "20px", border: `2px solid ${color}`, background: color + "22", color }} label={tag} onDelete={() => deleteTag( index, setFieldValue )} size="medium" />
                                                    )
                                                } )}
                                            </Box>
                                        </Collapse>
                                    </Box>
                                </FormControl>
                                <Box marginTop="20px">
                                    <FileInput attachmentsSizes={attachmentsSizes} setAttachmentsSizes={setAttachmentsSizes} progress={progress} size={60} isUploading={isAddingMaterial} selectedAttachments={selectedAttachments} setSelectedAttachments={setSelectedAttachments} setFieldValue={setFieldValue} attachmentsNames={attachmentsNames} setAttachmentsNames={setAttachmentsNames} />
                                </Box>
                                <Box marginTop="60px" alignItems="center" gap="20px" display="flex">
                                    <Button disabled={isAddingMaterial} type="submit" variant='contained' sx={{ textTransform: "capitalize" }} disableElevation>{isAddingMaterial ? <Typography gap="10px" display="flex" alignItems="center"> <CircularProgress size={14} /> Adding Material please wait...</Typography> : `Add new material`}</Button>
                                    <Button disableElevation variant='contained' onClick={() => navigate( '/faculty/material' )} color='error' sx={{ textTransform: "capitalize" }}>Cancel</Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Paper>
        </Box>
    )
}

export default AddMaterial


