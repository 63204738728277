import { Button, Dialog, DialogContent, DialogTitle, TextField, FormControl, Box } from '@mui/material'
import { useOnlineCourseStore } from '../../store/onlinecourse.store'
import { Icons } from '../../utils/utilities'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

const AddModuleDialog = ( { course_id, state, setAddModuleState } ) => {

    const [name, setName] = useState( "" )

    const OnlineCourseStore = useOnlineCourseStore()

    const createModule = async () => {
        if ( name && name.trim() !== "" ) {
            await OnlineCourseStore.addNewModule( course_id, { name } )
            toast( <span>New module <i style={{ color: "orange" }}>{name}</i> added</span> )
            setAddModuleState( false )
            setName( '' )
        }
        else {
            setName( '' )
            toast( "Please provide a name for the module" )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90vw", maxWidth: "450px" } }} open={state}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>Add new module</DialogTitle>
            <DialogContent>
                <FormControl margin='normal' fullWidth>
                    <TextField onChange={e => setName( e.target.value )} value={name} variant='outlined' fullWidth label="Enter the module name here..." />
                </FormControl>
            </DialogContent>
            <Box sx={{ justifyContent: "flex-start" }} display="flex" gap="10px" alignitems="center" padding="20px" paddingTop="0">
                <Button sx={{ textTransform: "capitalize" }} startIcon={Icons.default.AddIcon} disableElevation variant='contained' onClick={createModule}>Create</Button>
                <Button sx={{ textTransform: "capitalize" }} startIcon={Icons.default.CloseIcon} color='error' variant='outlined' onClick={() => setAddModuleState( false )}>Cancel</Button>
            </Box>
        </Dialog>
    )
}

export default AddModuleDialog
