import { Box, CircularProgress, Fab, Paper, Tooltip, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import VideoPlayer from '../../../components/online-course-components/VideoPlayer'
import CoursePlaylist from '../../../components/online-course-components/CoursePlaylist'
import { Link, useParams } from 'react-router-dom'
import { useOnlineCourseStore } from '../../../store/onlinecourse.store'
import { useEffect } from 'react'
import { useState } from 'react'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { PlaylistAddCheckCircleOutlined } from '@mui/icons-material'
import DocumentInfoArea from '../../../components/online-course-components/DocumentInfoArea'
import { Icons, convertToCDNLink } from '../../../utils/utilities'



const LearnOnlineCourse = () => {

    const [selectedAttachment, setSelectedAttachment] = useState( null )
    const [attachmentLink, setAttachmentLink] = useState( null )
    const [nextAndPrevAttachmentIndex, setNextAndPrevAttachmentIndex] = useState( { prev: null, next: null } )
    const [isPlaylistOpen, setIsPlaylistOpen] = useState( true )
    const [currentAttachmentType, setCurrentAttachmentType] = useState( null )
    const [progresses, setProgresses] = useState( {} )
    const [videoStartTime, setVideoStartTime] = useState( 0 )

    const OnlineCourseStore = useOnlineCourseStore()
    const { course_id, progress_id } = useParams()

    const onVideoPlayerExit = useCallback( async ( id, payload ) => {
        try {
            await api.updateProgressDetail( id, payload )
            await OnlineCourseStore.fetchProgressData( progress_id )
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }, [OnlineCourseStore, progress_id] )

    const handleVideoEnded = async ( progressDetails ) => {
        if ( progressDetails )
            updateProgressDetail( progressDetails.id, { status: "COMPLETED" } )
        handleNext()
    }

    const findNextAndPrevIndex = ( list, item_id ) => {
        const nextAndPrevIndex = {}
        for ( let i = 0; i < list.length; i++ ) {
            const item = list[i]
            nextAndPrevIndex.next = null
            nextAndPrevIndex.prev = null
            if ( item.attachment_id === item_id ) {
                if ( i !== list.length - 1 )
                    nextAndPrevIndex.next = i + 1
                if ( i !== 0 )
                    nextAndPrevIndex.prev = i - 1
                break
            }
        }
        return nextAndPrevIndex
    }

    const updateProgressDetail = useCallback( async ( id, payload ) => {
        try {
            await api.updateProgressDetail( id, payload )
            await OnlineCourseStore.fetchProgressData( progress_id )
            const mapProgressAndAttachments = () => {
                const map = {}
                for ( let i = 0; i < OnlineCourseStore.getCourseProgressData.progress_details.length; i++ ) {
                    const progress = OnlineCourseStore.getCourseProgressData.progress_details[i]
                    map[progress.online_course_attachment_id] = progress
                }
                setProgresses( map )
            }
            mapProgressAndAttachments()
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }, [OnlineCourseStore, progress_id] )

    const startFromWhereStoped = useCallback( async () => {
        console.log( OnlineCourseStore.getCourse )
        try {
            const att = OnlineCourseStore.getCourse['attachments'].find( a => a.id === OnlineCourseStore.getCourseProgressData.progress_details[0].online_course_attachment_id )
            const { data: { data } } = await api.getCourseAttachment( att.attachment_id )
            setSelectedAttachment( att )
            setNextAndPrevAttachmentIndex( findNextAndPrevIndex( OnlineCourseStore.getCourse['attachments'], att.attachment_id ) )
            if ( !['docx', 'pptx', 'txt'].includes( att.attachment_link.split( "." ).pop().toLowerCase() ) ) {
                setVideoStartTime( OnlineCourseStore.getCourseProgressData.progress_details[0].last_saved_duration )
                setAttachmentLink( convertToCDNLink( data ) )
                setCurrentAttachmentType( "video" )
            }
            else {
                setAttachmentLink( convertToCDNLink( data ) )
                setCurrentAttachmentType( "doc" )
                setVideoStartTime( 0 )
            }
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }, [OnlineCourseStore] )

    const viewAttachment = useCallback( async ( att ) => {
        try {
            const { data: { data } } = await api.getCourseAttachment( att.attachment_id )
            const attachmentProgress = OnlineCourseStore.getCourseProgressData.progress_details.find( d => d.online_course_attachment_id === att.attachment_id )
            if ( attachmentProgress === undefined ) {
                await api.startAttachment( progress_id, { progress_id, module_id: att.module_id, attachment_id: att.attachment_id } )
            } else {
                updateProgressDetail( attachmentProgress.id, {} )
            }
            setSelectedAttachment( att )
            setNextAndPrevAttachmentIndex( findNextAndPrevIndex( OnlineCourseStore.getCourse['attachments'], att.attachment_id ) )
            if ( !['docx', 'pptx', 'txt'].includes( att.attachment_link.split( "." ).pop().toLowerCase() ) ) {
                setAttachmentLink( convertToCDNLink( data ) )
                setCurrentAttachmentType( "video" )
                setVideoStartTime( attachmentProgress?.last_saved_duration || 0 )
            }
            else {
                setVideoStartTime( 0 )
                setAttachmentLink( convertToCDNLink( data ) )
                setCurrentAttachmentType( "doc" )
            }
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }, [OnlineCourseStore, progress_id, updateProgressDetail] )

    const handleNext = () => {
        setCurrentAttachmentType( null )
        if ( nextAndPrevAttachmentIndex.next !== null ) {
            viewAttachment( OnlineCourseStore.getCourse['attachments'][nextAndPrevAttachmentIndex.next] )
        }
    }

    const handlePrev = () => {
        setCurrentAttachmentType( null )
        viewAttachment( OnlineCourseStore.getCourse['attachments'][nextAndPrevAttachmentIndex.prev] )
    }

    useEffect( () => {
        const getData = async () => {
            await OnlineCourseStore.fetchCourse( course_id )
            await OnlineCourseStore.fetchProgressData( progress_id )
            const mapProgressAndAttachments = () => {
                const map = {}
                for ( let i = 0; i < OnlineCourseStore.getCourseProgressData.progress_details.length; i++ ) {
                    const progress = OnlineCourseStore.getCourseProgressData.progress_details[i]
                    map[progress.online_course_attachment_id] = progress
                }
                setProgresses( map )
            }
            mapProgressAndAttachments()
            startFromWhereStoped()
        }
        getData()
    }, [OnlineCourseStore, course_id, startFromWhereStoped, progress_id] )

    return (
        <Box padding="20px" minWidth="0" display="flex" flexDirection="column" overflow="auto" flexGrow={1} >

            <Typography display="flex" alignItems="center" gutterBottom color="textSecondary" variant='subtitle2'>
                <Link className='breadcrumb-link' to="/student/my-online-course">Your Courses</Link>
                {Icons.small.ChevronRightIcon}
                <Link className='breadcrumb-link' to={OnlineCourseStore.getCourse['course']?.id ? `/student/my-online-course/${OnlineCourseStore.getCourse['course']?.title}` : "#"}>{OnlineCourseStore.getCourse['course']?.title}</Link>
                {Icons.small.ChevronRightIcon}
                Learn
            </Typography>

            <Paper sx={{ minHeight: "350px", flexGrow: 1 }}>
                <Box borderRadius="5px" display="flex" position="relative" height="100%">
                    <Box flexGrow={1} overflow="auto">
                        {currentAttachmentType === "video" && selectedAttachment && <Box bgcolor="rgba(48,48,48,0.8)" position="relative" height={isPlaylistOpen ? "450px" : "500px"} display="flex">
                            <VideoPlayer title={selectedAttachment.attachment_name.split( "." ).slice( 0, -1 ).join( "" )} videoStartTime={videoStartTime} handleVideoEnded={handleVideoEnded} handleOnDestroy={onVideoPlayerExit} updateProgressDetail={updateProgressDetail} progressDetails={progresses[selectedAttachment.attachment_id]} nextAndPrevAttachmentIndex={nextAndPrevAttachmentIndex} onPrevButtonClick={handlePrev} onNextButtonClick={handleNext} link={attachmentLink} />
                        </Box>}
                        {currentAttachmentType === "doc" && selectedAttachment && <Box justifyContent="center" bgcolor="rgba(48,48,48,0.9)" flexDirection="column" gap="20px" color="white" padding="30px" position="relative" height={isPlaylistOpen ? "450px" : "500px"} display="flex">
                            <DocumentInfoArea updateProgressDetail={updateProgressDetail} progressDetails={progresses[selectedAttachment.attachment_id]} link={attachmentLink} selectedAttachment={selectedAttachment} nextAndPrevAttachmentIndex={nextAndPrevAttachmentIndex} onPrevButtonClick={handlePrev} onNextButtonClick={handleNext} />
                        </Box>}
                        {currentAttachmentType === null && <Box justifyContent="center" bgcolor="rgba(48,48,48,0.9)" alignItems="center" gap="20px" color="white" padding="30px" position="relative" height="450px" display="flex">
                            <CircularProgress sx={{ color: "white", borderColor: "white" }} />
                        </Box>}
                        <Box padding="20px">
                            <Typography variant='h5'>{OnlineCourseStore.getCourse['course'].title}</Typography>
                            <Typography>{OnlineCourseStore.getCourse['course'].description}</Typography>
                        </Box>
                    </Box>
                    {!isPlaylistOpen && <Tooltip placement='top-start' title="Open course content" sx={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Fab onClick={() => setIsPlaylistOpen( true )} size='small'> <PlaylistAddCheckCircleOutlined /> </Fab>
                    </Tooltip>}
                    <CoursePlaylist setProgresses={setProgresses} progresses={progresses} isPlaylistOpen={isPlaylistOpen} setIsPlaylistOpen={setIsPlaylistOpen} viewAttachment={viewAttachment} selectedAttachment={selectedAttachment || {}} />
                </Box>
            </Paper >
        </Box >
    )
}

export default LearnOnlineCourse
