import { Box, IconButton, Typography, Paper, useTheme } from '@mui/material'
import React from 'react'
import { useDownloadStatusListStore } from '../../store/downloadstatuslistdialog.store'
import { ArrowDropDown, CheckRounded, DescriptionOutlined, Error, FolderOpen } from '@mui/icons-material'
import { Icons } from '../../utils/utilities'
import Progress from '../common/Progress'
import { observer } from 'mobx-react'

const DownloadStatusListDialog = observer( () => {

    const { palette, theme } = useTheme()

    const DownloadStatusListStore = useDownloadStatusListStore()

    const handleDownloadsDialogClose = () => {
        DownloadStatusListStore.closeDialog()
    }

    return (
        <Box id="Download List">
            {Object.keys( DownloadStatusListStore.getProgressStatusList ).length > 0 &&
                <Paper
                    elevation={5}
                    sx={{
                        borderRadius: " 10px 10px 0 0 ",
                        border: theme === 'dark' ? `1px solid ${palette.borderColor}` : "none",
                        position: "fixed",
                        display: { md: "block", xs: "none" },
                        zIndex: 2000000,
                        bottom: "0",
                        right: "20px",
                        width: "250px",
                        maxWidth: "250px"
                    }}>
                    <Box display="flex" padding="10px" alignItems="center" justifyContent="space-between">
                        {Object.values( DownloadStatusListStore.getProgressStatusList ).filter( item => item.inProgress ).length > 0 ? <Typography variant="subtitle2">
                            Downloading {Object.values( DownloadStatusListStore.getProgressStatusList ).filter( item => item.inProgress || item.progress >= 100 ).length} items...
                        </Typography> : <Typography variant="subtitle2">
                            Downloaded {Object.values( DownloadStatusListStore.getProgressStatusList ).length} items
                        </Typography>}
                        <IconButton color="greyed" size="small" onClick={handleDownloadsDialogClose}>
                            {!DownloadStatusListStore.getDialogStatus ? <ArrowDropDown /> : Icons.default.CloseIcon}
                        </IconButton>
                    </Box>
                    {DownloadStatusListStore.getDialogStatus && <Box bgcolor={palette.contentBg} maxHeight="150px" overflow="auto">
                        {Object.keys( DownloadStatusListStore.getProgressStatusList ).map( key => (
                            <Box key={key} borderTop="2px solid rgba(0,0,0,0.1)" fontSize="14px" display="flex" alignItems="center" justifyContent="space-betweeen" gap="5px" padding="10px">
                                <Box flexGrow="1" display="flex" alignItems="center" gap="5px">
                                    {DownloadStatusListStore.getProgressStatusList[key].type === 'material' ? <FolderOpen fontSize="small" color="textSecondary" /> : <DescriptionOutlined fontSize="small" color="textSecondary" />}
                                    <Typography variant='body2' fontSize="12px" width="150px" noWrap>{DownloadStatusListStore.getProgressStatusList[key].name}</Typography>
                                </Box>
                                {DownloadStatusListStore.getProgressStatusList[key].status !== "failed" ? DownloadStatusListStore.getProgressStatusList[key].inProgress && DownloadStatusListStore.getProgressStatusList[key].progress !== 100 ? <Progress size={25} trackWidth={5} indicatorWidth={5} progress={DownloadStatusListStore.getProgressStatusList[key].progress} /> : <CheckRounded color="success" /> : <Error color="errorMessage" />}
                            </Box>
                        ) )}
                    </Box>}
                </Paper>}
        </Box>
    )
} )

export default DownloadStatusListDialog
