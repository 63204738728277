import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
// import api from '../service/api'
// import NotificationStore from "./notification.store"
import React from 'react'
// const notification = NotificationStore

class SubjectStore {
    subject
    subjectExams
    subjectCourses
    subjectMaterials
    subjectAssignments
    subjectPendingTasks

    constructor () {
        this.subject = null
        this.subjectExams = []
        this.subjectCourses = []
        this.subjectMaterials = []
        this.subjectAssignments = []
        this.subjectPendingTasks = []

        makeObservable( this, {
            subject: observable,
            subjectExams: observable,
            subjectCourses: observable,
            subjectMaterials: observable,
            subjectAssignments: observable,
            subjectPendingTasks: observable,

            getSubjectData: action,
            fetchSubjectExams: action,
            fetchSubjectCourses: action,
            fetchSubjectMaterials: action,
            fetchSubjectAssignments: action,
            fetchSubjectPendingTasks: action,

            getSubject: computed,
            getSubjectExams: computed,
            getSubjectCourses: computed,
            getSubjectMaterials: computed,
            getSubjectAssignments: computed,
            getSubjectPendingTasks: computed,
        } )
    }

    get getSubject() {
        return toJS( this.subject )
    }

    get getSubjectPendingTasks() {
        return toJS( this.subjectPendingTasks )
    }

    get getSubjectExams() {
        return toJS( this.subjectExams )
    }

    get getSubjectAssignments() {
        return toJS( this.subjectAssignments )
    }

    get getSubjectCourses() {
        return toJS( this.subjectCourses )
    }

    get getSubjectMaterials() {
        return toJS( this.subjectMaterials )
    }

    async fetchSubjectModulesDetails( subject ) {

    }

    async getSubjectData( data, role, subject_id ) {
        if ( role === "STUDENT" ) {
            const yearsList = Object.keys( data )
            for ( let i = 0; i < yearsList.length; i++ ) {
                const year = yearsList[i]
                const semList = Object.keys( data[year] )
                for ( let j = 0; j < semList.length; j++ ) {
                    const subs = data[year][semList[j]]
                    const sub = subs.find( s => s.subject_id === subject_id )
                    if ( sub ) {
                        runInAction( () => {
                            this.subject = sub
                        } )
                        this.fetchSubjectModulesDetails( sub )
                        return true
                    }
                }
            }
        } else {
            runInAction( () => {
                this.subject = data.find( s => s.subject_id === subject_id )
            } )
        }
    }

    async fetchSubjectPendingTasks() {

    }

    async fetchSubjectExams() {

    }

    async fetchSubjectAssignments() {

    }

    async fetchSubjectCourses() {

    }

    async fetchSubjectMaterials() {

    }
}


const SubjectStoreInstance = new SubjectStore()

export default SubjectStoreInstance

const SubjectStoreContext = React.createContext()

export const SubjectStoreProvider = ( { children, store } ) => {
    return (
        <SubjectStoreContext.Provider value={store}>{children}</SubjectStoreContext.Provider>
    )
}

export const useSubjectStore = () => React.useContext( SubjectStoreContext )