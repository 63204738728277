import { Box, Button, FormControl, FormControlLabel, OutlinedInput, Select, TextField, Typography, MenuItem, Checkbox, CircularProgress, Paper, useTheme, Popover, Chip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { formatDateTimeForDB } from '../../../utils/exam-utilities'
import { useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import api from '../../../service/api'
import BreadcrumbList from '../../../components/common/BreadcrumbList'
import { useUserStore } from '../../../store/user.store'
import { observer } from 'mobx-react'
import { RefreshOutlined } from '@mui/icons-material'
import { flatColors, Icons } from '../../../utils/utilities'

const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]

const AddExam = observer( () => {
    const initialDate = new Date().toISOString().split( 'T' )[0] + "T00:00"
    const supportedFormats = ["png", "jpg", "jpeg"]
    const attachmentsSupportedFormats = ["pdf", "docx", "doc", "xlsx"]

    const [loadingAcademicYears, setLoadingAcademicYears] = useState( true )
    const [isCreatingExam, setIsCreatingExam] = useState( false )
    const [selectedBatches, setSelectedBatches] = useState( [] )
    const [selectedBatchesNames, setSelectedBatchesNames] = useState( [] )
    const [startTimeData, setStartTimeData] = useState( new Date() )
    const [endDateTime, setEndDateTime] = useState( new Date() )
    const [academicYear, setAcademicYear] = useState( "-1" )
    const [isFetchingBatches, setIsFetchingBatches] = useState( false )
    const [isOpenExam, setIsOpenExam] = useState( true )
    const [openExamInfo, setOpenExamInfo] = useState( false )
    const [randomizeInfo, setRandomizeInfo] = useState( false )
    const [durationHours, setDurationHours] = useState( "00" )
    const [durationMinutes, setDurationMinutes] = useState( "00" )
    const [batches, setBatches] = useState( {} )
    const [sections, setSections] = useState( {} )
    // const [sessionExpired, setSessionExpired] = useState( false )

    const navigate = useNavigate()
    const UserStore = useUserStore()

    const { palette, border } = useTheme()

    const initialValues = {
        title: "",
        description: "",
        topics: '',
        thumbnail: '',
        attachments: [],
        status: 'Draft',
        startDateTime: initialDate,
        endDateTime: initialDate,
        selectedBatches: [],
        duration: null,
        maximumAttempt: 1,
        subject: '-',
        randomize: false,
    }

    // const setPreviewImg = ( file ) => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL( file )
    //     reader.onload = () => {
    //         setPreviewUrl( reader.result )
    //     }
    // }

    const handleBatchChange = ( e ) => {
        if ( e.target.value ) {
            const batchesAndSections = e.target.value
            setSelectedBatches( batchesAndSections )
            let selectedBatchesAndSectionsNames = []
            batchesAndSections.forEach( item => {
                selectedBatchesAndSectionsNames.push( batches[item] ? batches[item] : sections[item] )
            } )
            setSelectedBatchesNames( selectedBatchesAndSectionsNames )
        }
    }

    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( "Exam title is required." ),
        description: Yup.string().required( "Exam description is required." ),
        topics: Yup.array().required( "Please provide the exam topics." ),
        thumbnail: Yup.mixed().nullable().notRequired().test( "FILE_FORMAT", "Unsupported file format, accepted formats: JPG,JPEG,PNG.", ( value ) => {
            if ( value )
                return supportedFormats.includes( value.name.split( "." ).pop().toLowerCase() )
            return true
        } ),
        startDateTime: Yup.date().required().min( new Date(), "Date is invalid" ),
        endDateTime: Yup.date().required().when( "startDateTime", ( startDate ) => {
            if ( startDate ) return Yup.date().min( startDate, "End date must be after start date" )
        } ),

        attachments: Yup.mixed().nullable().notRequired().test( "ATTACHMENTS_FILE_FORMAT", "Unsupported attachment format! accepted formats: PDF.", ( value ) => {
            if ( value ) {
                const files = Object.values( value )
                for ( let i = 0; i < files.length; i++ ) {
                    const file = files[i]
                    if ( !attachmentsSupportedFormats.includes( file.name.split( "." ).pop().toLowerCase() ) ) {
                        return false
                    }
                }
                return true
            }
            else return true
        } ),

        duration: Yup.string().nullable().test( "CHECK_DURATION", "Invalid time format hours: HH and minutes: MM", ( value ) => {
            if ( isOpenExam ) {
                if ( value && value !== '00:00' ) return true
                return false
            } else return true
        } ),

        maximumAttempt: Yup.number().required( "This field is required!" ).min( 1, "At least 1 attempt must be given" ),
        subject: Yup.mixed().required( "Please select the subject." ).notOneOf( ["-"], "Please select the subject." )
    } )

    const submit = async ( values, { resetForm } ) => {
        setIsCreatingExam( true )
        const formData = new FormData()
        formData.append( 'title', values.title )
        if ( values.thumbnail )
            formData.append( "thumbnail", values.thumbnail, values.thumbnail ? `${values.title}-thumb.${values.thumbnail.name.split( "." )[values.thumbnail.name.split( "." ).length - 1]}` : '' )
        formData.append( 'description', values.description )
        formData.append( 'topics', JSON.stringify( values.topics ) )
        formData.append( 'startDateTime', JSON.stringify( formatDateTimeForDB( values.startDateTime, 'en-IN', '-' ) ) )
        formData.append( 'endDateTime', JSON.stringify( formatDateTimeForDB( values.endDateTime, 'en-IN', '-' ) ) )
        formData.append( "status", values.status )
        formData.append( 'selectedBatches', JSON.stringify( values.selectedBatches ) )
        formData.append( 'duration', isOpenExam ? values.duration : null )
        formData.append( 'maximumAttempt', values.maximumAttempt )
        formData.append( "randomize", values.randomize )
        formData.append( "examSubject", JSON.stringify( values.subject ) )
        for ( let i = 0; i < values.attachments.length; i++ ) {
            formData.append( "attachments[]", values.attachments[i], `${values.title}-${Date.now()}${String( i + 1 )}.${values.attachments[i].name.split( "." )[values.attachments[i].name.split( "." ).length - 1]}` )
        }
        try {
            const { data } = await api.createExam( formData )
            navigate( data?.data?.exam_id ? `/faculty/exams/${data?.data?.exam_id}` : `/faculty/exams` )
            toast.success( "New exam created successfully!" )
        } catch ( err ) {
            toast( err?.response?.data?.message || "An errorMessage occured while creating a new exam! try again later" )
        } finally {
            setIsCreatingExam( false )
        }
    }

    const getBatches = async ( year = academicYear, refresh = false ) => {
        setIsFetchingBatches( true )
        try {
            await UserStore.fetchUserBatchesAndSection( year, refresh )
            if ( UserStore.getUserBatchesAndSection?.batches.length > 0 ) {
                const batchesForSelect = {}
                UserStore.getUserBatchesAndSection?.batches.forEach( batch => {
                    batchesForSelect[`${batch.batch_assignment_id}`] = batch.batch_name + "-" + batch.course_name_short + " Y" + batch.current_year + " S" + batch.current_sem + " " + batch.batch_short_name
                } )
                setBatches( batchesForSelect )
            } else {
                setBatches( {} )
            }
            if ( UserStore.getUserBatchesAndSection?.sections.length > 0 ) {
                const sectionsForSelect = {}
                UserStore.getUserBatchesAndSection?.sections.forEach( section => {
                    sectionsForSelect[`${section.section_assignment_id}-${section.section_short_name}`] = section.course_branch_short_name + "-" + section.course_short_name + " Y" + section.current_year + " S" + section.current_sem + " (" + section.section_short_name + ")"
                } )
                setSections( sectionsForSelect )
            } else {
                setSections( {} )
            }
        } catch ( e ) {
            console.log( e )
            toast.errorMessage( "Something went wrong!" )
        } finally {
            setIsFetchingBatches( false )
        }
    }

    const refreshYears = async () => {
        await UserStore.fetchUserAcademicYears( true )
    }

    const refreshBatches = async () => {
        if ( academicYear !== "-1" )
            await getBatches( academicYear, true )
    }

    useEffect( () => {
        const getAcademicYears = async () => {
            try {
                await UserStore.fetchUserAcademicYears()
                await UserStore.fetchUserSubjects()
            } catch ( e ) {
                console.log( e )
                if ( e.message === "Network Error" )
                    navigate( '/networkerror' )
                else toast.errorMessage( "Something went wrong! try again later" )
            } finally {
                setLoadingAcademicYears( false )
            }
        }
        getAcademicYears()
    }, [navigate, UserStore] )

    return (
        <Box padding="20px" flexGrow={1} minWidth="0" overflow="auto" >

            <BreadcrumbList items={[
                { label: "Exams", link: '/faculty/exams' },
                "Create new exam"
            ]} />

            <Paper sx={{ paddingBottom: "1px" }}>
                <Box alignItems="center" display="flex" gap="10px" padding="20px">
                    <Box>
                        <Typography variant='h6' color="primaryDark" >New exam</Typography>
                        <Typography color="primaryDark" variant='body2' >Create exam by providing all required details</Typography>
                    </Box>
                </Box>
                <Box margin="20px" marginTop="0" bgcolor={palette.form.formBg} padding="20px" borderRadius="5px" border={border[1]}>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {( { values, setFieldValue } ) => (
                            <Form method='POST' id='add-form' autoComplete='off' encType="multipart/form-data">

                                <FormControl margin="normal" fullWidth>
                                    <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Exam title * </Typography></label>
                                    <Field placeholder="Eg: Kannada language internal test - 1" as={TextField} id="title" size="small" InputLabelProps={{ sx: { fontSize: "14px !important" } }} fullWidth name='title' />
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='title' /></Typography>
                                </FormControl>

                                <FormControl variant='outlined' margin="normal" fullWidth>
                                    <label htmlFor="description"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Exam description * </Typography></label>
                                    <Field placeholder="Eg: Please answer all the question in all the sections." as={TextField} size="small" multiline rows={4} InputLabelProps={{ sx: { fontSize: "14px !important" } }} name='description' id='description' />
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='description' /></Typography>
                                </FormControl>

                                <FormControl variant='outlined' margin="normal" fullWidth>
                                    <label htmlFor="topics"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Topics * </Typography></label>
                                    <Field
                                        placeholder="Eg: Module - 1, Module - 2"
                                        as={TextField} size="small" onChange={( e ) => { setFieldValue( 'topics', e.target.value.split( "," ) ) }}
                                        InputLabelProps={{ sx: { fontSize: "14px !important" } }}
                                        fullWidth
                                        name='topics'
                                        id='topics'
                                    />
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='topics' /></Typography>
                                </FormControl>

                                {values.topics.length > 0 && <Box display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                                    {values.topics?.map( ( tag, index ) => {
                                        if ( tag?.trim() !== "" ) {
                                            const color = flatColors[index % flatColors.length]
                                            return (
                                                <Chip deleteIcon={Icons.default.CloseIcon} key={index} sx={{ borderRadius: "20px", border: `2px solid ${color}`, background: color + "22", color }} label={tag} size="medium" />
                                            )
                                        }
                                        else return ""
                                    } )}
                                </Box>}

                                <FormControl margin='normal' variant='outlined' fullWidth>
                                    <label htmlFor='subject' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Subject *</Typography> </label>
                                    <Field as={Select} onChange={( e ) => { setFieldValue( 'subject', UserStore.getUserSubjects.find( s => s.subject_id === e.target.value ) ) }}
                                        value={values.subject?.subject_id || "-"}
                                        sx={{ color: values.subject === "-" && palette.form.placeholder }}
                                        name='subject'
                                        id='subject'
                                        size="small"
                                    >
                                        <MenuItem value={"-"} disabled key={"default"} dense sx={{ fontSize: "14px", paddingY: "5px" }}>Eg: Kannada</MenuItem>
                                        {UserStore.getUserSubjects.map( ( sub, index ) => (
                                            <MenuItem value={sub.subject_id} key={sub.subject_id} dense sx={{ fontSize: "14px", paddingY: "5px" }}>{sub.subject_name}</MenuItem>
                                        ) )}
                                    </Field>
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='subject' /></Typography>
                                </FormControl>

                                <Box>
                                    <Box marginTop="10px" display="flex" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }} gap="20px" alignItems="flex-end">
                                        <FormControl sx={{ maxWidth: "250px", width: "40%", position: "relative" }}>
                                            <label htmlFor='academicYears' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Batches exam conducted for *</Typography> </label>
                                            {UserStore.getUserAcademicYears && <Select
                                                sx={{ color: academicYear === "-1" && palette.form.placeholder }}
                                                size="small"
                                                disabled={loadingAcademicYears}
                                                value={academicYear} onChange={( e ) => { setAcademicYear( e.target.value ); getBatches( e.target.value ) }} input={<OutlinedInput />}>
                                                <MenuItem sx={{ fontSize: "14px" }} value="-1" disabled>{UserStore.getUserAcademicYears.length === 0 ? "No academic years!" : "Select academic year of admission"}</MenuItem>
                                                {UserStore.getUserAcademicYears.length > 0 && UserStore.getUserAcademicYears.map( year => (
                                                    <MenuItem sx={{ fontSize: "14px" }} key={year.ac_year_id} value={year.ac_year_id}>{year.ac_year}</MenuItem>
                                                ) )}
                                            </Select>}
                                            {<Typography onClick={refreshYears} sx={{ cursor: "pointer", '&:hover': { textDecoration: "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color="secondary" fontWeight="500"><RefreshOutlined fontSize='16px' />Refresh academic years</Typography>}
                                        </FormControl>
                                        <FormControl sx={{ position: "relative", maxWidth: "250px", width: "40%", }} >
                                            <Select
                                                sx={{ fontWeight: "500", color: values.selectedBatches === "-" && palette.form.placeholder }}
                                                size="small" id="selectedBatches" disabled={isFetchingBatches || academicYear === "-1"}
                                                name='selectedBatches' multiple displayEmpty value={selectedBatches}
                                                onChange={( e ) => { handleBatchChange( e ); setFieldValue( 'selectedBatches', typeof e.target.value === 'string' ? e.target.value.split( "," ) : e.target.value ) }}
                                                input={<OutlinedInput placeholder='Eg: CSE - section B' />}
                                                renderValue={( selected ) => (
                                                    <Typography fontSize="14px" color={selectedBatchesNames.length === 0 && palette.form.placeholder} noWrap >
                                                        {selectedBatchesNames.length > 0 ? selectedBatchesNames.join( ", " ) : "Select batch or section"}
                                                    </Typography>
                                                )}>
                                                <MenuItem dense sx={{ fontSize: "14px" }} disabled key={"batch"} value={""}>Select batch or section</MenuItem>
                                                <Typography paddingX="15px" fontWeight="bold" color="secondary">Batches</Typography>
                                                {batches && Object.keys( batches ).length > 0 && Object.keys( batches ).map( batch => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={batch} value={batch}>{batches[batch]}</MenuItem>
                                                ) )}
                                                {( !batches || batches.length === 0 ) && <MenuItem value={"No batches"} disabled>No batches for selected year!</MenuItem>}
                                                <hr />
                                                <Typography paddingX="15px" fontWeight="bold" color="secondary">Sections</Typography>
                                                {sections && Object.keys( sections ).length > 0 && Object.keys( sections ).map( section => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={section} value={section}>{sections[section]}</MenuItem>
                                                ) )}
                                                {( !sections || sections.length === 0 ) && <MenuItem value={"No sections"} disabled>No sections for selected year!</MenuItem>}
                                            </Select>
                                            {isFetchingBatches && <Typography position="absolute" top="100%" marginLeft="5px" variant='body2' color="textSecondary"> <CircularProgress size={10} sx={{ marginRight: "5px" }} /> Loading batches...</Typography>}
                                            <Typography onClick={refreshBatches} sx={{ cursor: "pointer", '&:hover': { cursor: academicYear === "-1" && "not-allowed", textDecoration: academicYear !== "-1" && "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color={academicYear === "-1" ? "GrayText" : "secondary"} fontWeight="500"><RefreshOutlined fontSize='16px' /> Refresh batches</Typography>
                                        </FormControl>
                                        {!batches && <Typography marginTop="10px" variant='body2' color="textSecondary">No batches for selected academic year...</Typography>}
                                    </Box>
                                </Box>

                                <FormControl fullWidth margin="normal" sx={{ maxWidth: "250px" }}>
                                    <label htmlFor='maximumattempt' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Maximum attempts *</Typography> </label>
                                    <Field as={TextField} id="maximumattempt" size="small" InputLabelProps={{ sx: { fontSize: "14px !important" } }} name='maximumAttempt' />
                                    <Typography color="errorMessage.main"><ErrorMessage name='maximumAttempt' /></Typography>
                                </FormControl>

                                <FormControl margin='normal' sx={{ display: "flex", maxWidth: "250px" }}>
                                    <Box >
                                        <label htmlFor='startdatetime' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Start date and time *</Typography> </label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                id="startdatetime"
                                                sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                minDateTime={dayjs( new Date() )}
                                                value={dayjs( startTimeData )}
                                                format="DD-MM-YYYY hh:mm A"
                                                onChange={( e ) => { if ( e.$d !== "Invalid Date" ) { setStartTimeData( e ); setFieldValue( 'startDateTime', e ) } }}

                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Typography variant="subtitle2" fontSize="12px" color="textSecondary">Enter start date and time.</Typography>
                                    <Typography fontSize="12px" color="errorMessage.main"><ErrorMessage name='startDateTime' /></Typography>

                                </FormControl>
                                <FormControl margin="normal" sx={{ maxWidth: "250px", display: "flex" }}>
                                    <Box>
                                        <label htmlFor='startdatetime' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>End date and time *</Typography> </label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                id="startdatetime"
                                                sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                value={dayjs( endDateTime )}
                                                format="DD-MM-YYYY hh:mm A"
                                                onChange={( e ) => { setEndDateTime( e ); setFieldValue( 'endDateTime', e ) }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Typography variant="subtitle2" fontSize="12px" color="textSecondary">Enter end date and time.</Typography>
                                    <Typography color="errorMessage.main"><ErrorMessage name='endDateTime' /></Typography>
                                </FormControl>

                                {isOpenExam && <FormControl variant='outlined' sx={{ display: "flex" }}>
                                    <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Exam duration</Typography>
                                    <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                                        <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                            <Select endAdornment={<Typography fontSize="12px" marginRight="20px" sx={{ pointerEvents: "none" }} color="textSecondary">HOURS</Typography>} size="small" onChange={e => { setDurationHours( e.target.value ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours}>
                                                {hours.map( hour => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={hour} value={hour}>{hour}</MenuItem>
                                                ) )}
                                            </Select>
                                        </FormControl >
                                        <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                            <Select endAdornment={<Typography fontSize="12px" marginRight="20px" sx={{ pointerEvents: "none" }} color="textSecondary">MIN</Typography>} size="small" onChange={e => { setDurationMinutes( e.target.value ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes}>
                                                {minutes.map( min => (
                                                    <MenuItem dense sx={{ fontSize: "14px" }} key={min} value={min}>{min}</MenuItem>
                                                ) )}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Typography variant="subtitle2" >Exam duration: {`${durationHours ? durationHours : 0} hours ${durationMinutes ? durationMinutes : 0} minutes`}</Typography>
                                    <Typography fontSize="12px" color="errorMessage.main" ><ErrorMessage name='duration' /></Typography>
                                </FormControl>}


                                {<Box position="relative">
                                    {<Popover slotProps={{ paper: { sx: { maxWidth: "300px" } } }} anchorEl={openExamInfo} onClose={e => setOpenExamInfo( null )} open={Boolean( openExamInfo )} >
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Stretched Exams</Typography>
                                        <Typography padding="10px" textAlign="justify" variant='body2'>In stretched exams the the exam countdown once started by a student, ends at the exam's end date and time as opposed to the duration exam where the countdown ends when the duration set by the faculty ends.</Typography>
                                    </Popover>}
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' value={!isOpenExam} onChange={e => { setIsOpenExam( !e.target.checked ) }} />} label="Stretch exam from start to end time?" />
                                        <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setOpenExamInfo( e.currentTarget )} />
                                    </Box>
                                </Box>}

                                {<Box position="relative">
                                    <Popover open={Boolean( randomizeInfo )} onClose={e => setRandomizeInfo( null )} anchorEl={randomizeInfo} slotProps={{ paper: { sx: { maxWidth: "300px" } } }}>
                                        <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Randomize questions and options?</Typography>
                                        <Typography textAlign="justify" padding="10px" variant='body2'>If selected the questions and their options will be in random order for each individual taking the exam.</Typography>
                                    </Popover>
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel control={<Checkbox color='primaryDark' value={values.randomize} onChange={e => { setFieldValue( 'randomize', e.target.checked ) }} />} label="Randamize questions and options?" />
                                        <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setRandomizeInfo( e.currentTarget )} />
                                    </Box>
                                </Box>}
                                <Box display="flex" gap="20px" marginTop="40px">
                                    <Button disableElevation disabled={isCreatingExam} type='submit' startIcon={isCreatingExam && <CircularProgress size={14} />} variant='contained' sx={{ textTransform: "capitalize" }}>{isCreatingExam ? "Creating exam..." : "Create Exam"}</Button>
                                    <Button disableElevation color="error" onClick={() => navigate( '/faculty/exams' )} disabled={isCreatingExam} variant='contained' sx={{ textTransform: "capitalize" }}>Cancel</Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Paper>
        </Box>
    )
}

)

export default AddExam