import { Box, Button, Chip, CircularProgress, Dialog, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import api from '../../../service/api'
import { Icons, flatColorsAndBackgrounds, showDateInIndianDateFormat, time24to12 } from '../../../utils/utilities'
import TableHeader from '../../common/TableHeader'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import { QuizBlub } from '../../../assets/custom-icons'
import { toast } from 'react-toastify'

const scoresColumns = [
    { name: 'NAME', align: "left", padding: "10px 20px 10px 40px" },
    { name: 'STUDENT ID', align: "left" },
    { name: 'MAX MARKS', align: "center" },
    { name: 'SCORED MARKS', align: "right", padding: "10px 40px 10px 20px" },
]


const AuditQuizViewer = ( { handleClose, openState, quiz } ) => {

    const [scores, setScores] = useState( [] )
    const [mode, setMode] = useState( "instances" )
    const [instances, setInstances] = useState( [] )
    const [loadingScores, setLoadingScores] = useState( false )
    const [fetchingInstances, setFetchingInstances] = useState( false )
    const [selectedInstanceDetails, setSelectedInstanceDetails] = useState( null )
    const [selectedQuizInstance, setSelectedQuizInstance] = useState( null )
    const [instancesFetchError, setInstancesFetchError] = useState( false )

    const { palette, shadows, border, table } = useTheme()

    const backToInstances = () => {
        setMode( "instances" )
        setSelectedQuizInstance( null )
        setSelectedInstanceDetails( null )
    }

    const viewScores = async ( instance ) => {
        setSelectedQuizInstance( instance.id )
        setLoadingScores( instance.id )
        try {
            const { data: { scores } } = await api.quiz.fetchQuizInstanceScores( quiz.id, instance.id )
            setScores( scores )
            setSelectedInstanceDetails( instance )
            setMode( 'scores' )
        } catch ( err ) {
            setSelectedQuizInstance( null )
            toast( err?.response?.data?.message || "Something went wrong while fetching scores!" )
        } finally {
            setLoadingScores( null )
        }
    }

    const fetchQuizInstances = useCallback( async () => {
        setFetchingInstances( true )
        try {
            setInstancesFetchError( true )
            const { data: { data } } = await api.quiz.fetchQuizInstances( quiz.id )
            setInstances( data )
        } catch ( err ) {
            console.log( err )
            setInstancesFetchError( err?.response?.data?.message || "Opps! An error occured while trying to fetchquiz instances try again." )
        } finally {
            setFetchingInstances( false )
        }
    }, [quiz] )

    useEffect( () => {
        fetchQuizInstances()
    }, [fetchQuizInstances] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "1200px" } }} open={openState}>
            <Box sx={{
                background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
            }} display="flex" alignItems="center" justifyContent="space-between" padding="20px" >
                <Box>
                    <Typography color="white" variant="h6">{quiz.title}</Typography>
                    <Typography variant='body2' color='white' >Instances: {quiz.instances}</Typography>
                </Box>
                <IconButton onClick={handleClose}>{Icons.default.CloseIcon}</IconButton>
            </Box>
            <Box padding="20px">
                <Typography variant='h6' fontSize="18px" gutterBottom color={palette.shadedText}>Topics and number of question</Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", }} flexWrap="wrap" gap="5px">
                    {quiz.question_bank_topics && quiz.question_bank_topics.map( ( topic, index ) => (
                        <Chip key={topic.id} label={`${topic.name} (${topic.question_count})`} sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg, fontWeight: "600" }} />
                    ) )}
                </Box>

                {!fetchingInstances && ( mode === "instances" || !selectedQuizInstance ) && <Box marginTop="20px">
                    {instances.length > 0 && <Box display="flex" marginTop="10px" gap="10px">
                        {instances.map( ( instance, index ) => {
                            let title = ''
                            if ( instance?.access_group?.section_assignment_id ) {
                                title = `${instance.access_group.course_branch_short_name ? instance.access_group.course_branch_short_name + "," : ""} ${instance.access_group?.current_year ? `year: ${instance.access_group?.current_year},` : ""} ${instance.access_group.current_sem ? `Sem: ${instance.access_group.current_sem},` : ""} Sec: ${instance.access_group.section_short_name}`
                            } else
                                title = `${instance.access_group.course_name_short ? instance.access_group.course_name_short + "," : ""} ${instance.access_group?.current_year ? `year: ${instance.access_group?.current_year},` : ""} ${instance.access_group.current_sem ? `Sem: ${instance.access_group.current_sem},` : ""} Batch: ${instance.access_group.batch_short_name}`

                            return <Box borderRadius="5px" width="300px" justifyContent="space-between" alignItems="center" boxShadow={shadows[1]} border={border[1]} sx={{ cursor: "pointer", transition: "200ms", "&:hover": { boxShadow: shadows[2] } }} bgcolor={palette.background.paper} key={instance.id}>
                                <Box display="flex" padding="20px" gap="20px" alignItems="flex-start">
                                    <img src={QuizBlub} alt="Quiz Instance" />
                                    <Box>
                                        <Tooltip title={title}>
                                            <Typography noWrap width="75%" fontSize="14px" variant="subtitle1"><strong><font color={palette.primary.main}>Quiz for</font> <em>{title}</em> </strong> </Typography>
                                        </Tooltip>
                                        <Typography fontSize="12px" variant="subtitle2" color="GrayText">
                                            {showDateInIndianDateFormat( instance.start_date )}
                                        </Typography>
                                        <Typography fontSize="12px" variant="subtitle2" color="GrayText">
                                            {time24to12( instance.start_time )} - {time24to12( instance.end_time )}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box padding="5px 20px" borderTop={`1px solid ${palette.borderColor}`} display="flex" gap="10px" alignItems="center">
                                    <Button onClick={() => viewScores( instance )} disabled={loadingScores === instance.id} startIcon={loadingScores === instance.id ? <CircularProgress size={14} /> : Icons.default.AnalyticsIconOutlined} color="customThemeColor" sx={{ textTransform: "capitalize" }} >View Scores</Button>
                                </Box>
                            </Box>
                        } )}
                    </Box>}
                    {instancesFetchError && <Typography color="errorMessage.main" variant='subtitle2'>{instancesFetchError}</Typography>}
                </Box>}
                {!fetchingInstances && mode === "scores" && selectedQuizInstance && <Box marginTop="20px" overflow="hidden" bgcolor={palette.background.paper} borderRadius="5px">
                    <Box display="flex" padding="10px 20px" bgcolor={table.headerBg} color={table.headerColor} alignItems="center" gap="10px">
                        <IconButton size="small" onClick={backToInstances}>{Icons.default.ArrowBack}</IconButton>
                        <Box >
                            {selectedInstanceDetails?.access_group?.batch_assignment_id && <Typography variant='subtitle1' >  SCORES - <em>{selectedInstanceDetails.access_group.course_name_short}, year: {selectedInstanceDetails.access_group.current_year}, sem: {selectedInstanceDetails.access_group.current_sem}, {selectedInstanceDetails.access_group.batch_short_name}</em><small>,  On: {showDateInIndianDateFormat( selectedInstanceDetails?.access_group?.start_date )}</small>  </Typography>}
                            {selectedInstanceDetails?.access_group?.section_assignment_id && <Typography variant='subtitle1' >  SCORES -  <em>{selectedInstanceDetails.access_group.course_branch_short_name}, year: {selectedInstanceDetails.access_group.current_year}, Section: {selectedInstanceDetails.access_group.section_short_name}</em><small>,  On: {showDateInIndianDateFormat( selectedInstanceDetails?.access_group?.start_date )}</small> </Typography>}
                        </Box>
                    </Box>
                    <Box padding="20px">
                        {loadingScores && <DataLoadingSpinner waitingMessage="Loading scores..." />}
                        {!loadingScores && scores.length > 0 && <Box bgcolor={palette.contentBg} border={border[1]} borderRadius="5px" >
                            <TableContainer sx={{ minWidth: "750px", flexGrow: 1, overflow: "auto", borderRadius: "5px" }}>
                                <Table stickyHeader>
                                    <TableHeader columns={scoresColumns} />
                                    <TableBody>
                                        {scores.map( score => (
                                            <TableRow className="table-row" key={score.id}>
                                                <TableCell align={scoresColumns[0].align} sx={{ border: "none", padding: scoresColumns[0].padding || "10px 20px", color: table.color }} >
                                                    <strong>{score?.student_details?.name} </strong>
                                                </TableCell>
                                                <TableCell align={scoresColumns[1].align} sx={{ border: "none", padding: "10px 20px", color: table.color }}>
                                                    <strong>{score.student_id}</strong>
                                                </TableCell>
                                                <TableCell align={scoresColumns[2].align} sx={{ border: "none", padding: "10px 20px", color: table.color }}>
                                                    <strong> {score.max_score}</strong>
                                                </TableCell>
                                                <TableCell align={scoresColumns[3].align} sx={{ border: "none", padding: scoresColumns[3].padding || "10px 20px", color: table.color }}>
                                                    <strong>{score.total_score}</strong>
                                                </TableCell>
                                            </TableRow>
                                        ) )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>}
                        {!loadingScores && scores.length === 0 && <Typography variant='subtitle2'  >
                            There are no participations for this quiz!
                        </Typography>}
                    </Box>
                </Box>}
                {fetchingInstances && <DataLoadingSpinner waitingMessage="Loading the quiz instances please wait..." />}
            </Box>
        </Dialog>
    )
}

export default AuditQuizViewer
