import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
// import parse from 'html-react-parser'
import Latex from 'react-latex-next'

const questionNbrStyles = {
    display: 'flex',
    padding: '2px',
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: '#464E5B',
    fontSize: '12px',
    fontWeight: 'bold',

}

const answerdStyles = {
    position: 'relative',
    background: 'green !important',
    color: 'white',
}

const notAnsweredStyles = {
    position: 'relative',
    // background: '#888',
    color: 'white',
}


export default function QuestionList( { questions, selectQuestion, currentQuestionIndex, submissions, showQuestions } ) {

    const { palette, border } = useTheme()

    const isAnswered = ( id ) => {
        for ( let i = 0; i < submissions.length; i++ ) {
            if ( submissions[i].question_id === id ) {
                return true
            }
        }
        return false
    }

    const getQuestionTypeColor = ( question ) => {
        const questionType = question.question.question_type
        const answerSelection = question.question.question_answer_selection

        let questionTypeColor = ''
        let answerSelectionColor = ''

        // Determine color based on question type
        if ( questionType === 'MCQ' && answerSelection === 'single' ) {
            questionTypeColor = '#C54097'
            answerSelectionColor = '#C54097'
        }
        else if ( questionType === 'MCQ' && answerSelection === 'multiple' ) {
            questionTypeColor = '#00720F'
            answerSelectionColor = '#00720F'
        }
        else if ( questionType === 'descriptive' ) {
            questionTypeColor = '#008E8E'
            answerSelectionColor = '#008E8E'
        }

        return {
            questionTypeColor,
            answerSelectionColor
        }
    }


    return (
        <Box margin="10px" borderRadius="5px" width={showQuestions ? "auto" : "0"} orientation="horizontal" className="custom-scrollbar" sx={{ border: border[1], height: "calc(100% - 2px)", flexDirection: "column", overflow: "auto !important" }}>
            <Box borderBottom={border[1]} display={{ md: "block", xs: "none" }} fontSize="14px" bgcolor="#FFF1DE" fontWeight="500" position="sticky" top="0" sx={{ zIndex: 10 }} textTransform="uppercase" color="#A3A3A3" padding="20px 20px">
                Questions
            </Box>
            <Box sx={{ overflowX: "hidden", overflowY: "auto" }} className="custom-scrollbar" display="flex" position="relative" flexDirection="column" bgcolor={palette.common.bg}>

                {questions.map( ( question, index ) => (
                    <Box padding={{ md: "10px", xs: "2px" }} key={question.question.question_id} display="flex" bgcolor={currentQuestionIndex === index ? palette.greyedOut : palette.common.bg} borderBottom="1px solid #aaa" alignItems="flex-start" sx={{ userSelect: "none", cursor: "pointer", display: "flex !important", justifyContent: "flex-start", "&:hover": { background: palette.rowHover } }} onClick={() => selectQuestion( question.question.question_id )}>
                        <Box sx={
                            isAnswered( question.question.question_id ) ?
                                { ...questionNbrStyles, ...answerdStyles, }
                                : { ...questionNbrStyles, ...notAnsweredStyles }}>{index + 1}</Box>
                        <Box marginLeft="10px" flexGrow={1} sx={{ display: { md: "block", xs: "none" } }}>
                            <Box variant='subtitle1' color={palette.common.font} fontSize="12px" textOverflow="ellipsis" whiteSpace="nowrap" sx={{ "& p": { margin: '0' }, "& img": { width: '10px', height: "0px", objectFit: "cover" } }}><Latex>{question.question.question_text.replace( "LATEX-", '' )}</Latex></Box>
                            <Box display="flex" alignItems="center" gap="5px">
                                <Typography color="secondary" fontSize={"12px"} sx={{ color: getQuestionTypeColor( question ).questionTypeColor }}>{question.question.question_type}</Typography>
                                <Typography color="secondary" fontSize={"12px"} sx={{ color: getQuestionTypeColor( question ).answerSelectionColor }}>{question.question.question_answer_selection}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ) )}
            </Box>
        </Box>
    )
}
