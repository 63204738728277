import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { useSettingsStore } from '../../../store/settings.store'


const AddSetting = ( { dialogState, setState, getSelectedSettings, setAddModuleDialogState, parents } ) => {

    const [adding, setAdding] = useState( false )

    const SettingsStore = useSettingsStore()

    const initialValues = {
        setting_label: '',
        setting_description: '',
        setting_data_type: 'text',
        module: SettingsStore.getModules[0]?.module || "-",
        setting_key: '',
        parent: 'none',
        setting_unit: '',
        default_value: '',
        setting_options: []
    }

    const validationSchema = Yup.object().shape( {
        setting_label: Yup.string().required( "Setting label is required!" ),
        setting_key: Yup.string().required( "Setting key is required!" ),
        default_value: Yup.string().required( "Setting's default value is required!" )
    } )

    const handleParentChange = ( e, setFieldValue ) => {
        if ( e.target.value === "none" ) {
            setFieldValue( 'module', 'general' )
            setFieldValue( 'parent', 'none' )
        } else {
            const selectedParent = parents.find( p => p.id === e.target.value )
            setFieldValue( 'module', selectedParent.module )
            setFieldValue( 'parent', e.target.value )
        }
    }

    const handleKeyDownForOptionsInput = ( e, setFieldValue, options ) => {
        if ( e.key === "Enter" )
            if ( /^[a-zA-Z0-9]+$/.test( e.target.value ) && !options.includes( e.target.value ) ) {
                e.preventDefault()
                console.log( "Options", options )
                setFieldValue( 'setting_options', [...options, e.target.value] )
                e.target.value = ''
                e.target.focus()
            } else {
                toast.error( "Options should be unique and should only contain numbers and alphpabets" )
            }
    }

    const handleKeyValueChange = ( e, setValue ) => {
        const val = e.target.value
        if ( val && val.trim() !== "" )
            setValue( 'setting_key', val.replace( /[^0-9a-zA-Z]/g, "_" ).toLowerCase() )
        else
            setValue( 'setting_key', "" )
    }

    const deleteOption = ( options, setFieldValue, index ) => {
        let newOptions = [...options]
        newOptions.splice( index, 1 )
        setFieldValue( 'setting_options', newOptions )
    }

    const addSetting = async ( values, { resetForm } ) => {
        setAdding( true )
        try {
            await api.addSetting( values )
            await getSelectedSettings( 'instituteandmodule', true )
            toast.success( `New setting added successfully` )
            resetForm()
            setState( false )
        } catch ( err ) {
            toast( "Something went wrong!" )
        } finally {
            setAdding( false )
        }
    }

    const openAddModuleDialog = () => {
        setState( false )
        setAddModuleDialogState( true )
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90vw", maxWidth: "800px" } }} open={dialogState}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }} >Add new setting</DialogTitle>
            <DialogContent sx={{ marginTop: "5px" }}>
                <Formik onSubmit={addSetting} initialValues={initialValues} validationSchema={validationSchema}>
                    {( { values, setFieldValue } ) => (
                        <Form>
                            <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <Field sx={{ fontSize: "14px" }} as={TextField} name='setting_label' label="Setting label*" type='text' />
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='setting_label' /></Typography>
                            </FormControl>
                            <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <Field sx={{ fontSize: "14px" }} as={TextField} name='setting_description' rows={4} multiline label="Setting Description" type='text' />
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='setting_description' /></Typography>
                            </FormControl>
                            <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <InputLabel>Select parent</InputLabel>
                                <Field sx={{ fontSize: "14px" }} onChange={( e ) => handleParentChange( e, setFieldValue )} name='parent' value={values.parent} as={Select} label="Select parent*">
                                    <MenuItem dense sx={{ fontSize: "14px" }} value='none'>None</MenuItem>
                                    {parents.map( parent => (
                                        <MenuItem sx={{ whiteSpace: "normal" }} dense key={parent.id} value={parent.id}> <Typography noWrap variant='body1' sx={{ fontSize: "14px", whiteSpace: 'normal', maxWidth: "calc(100% - 5px)" }} >[{parent.module}] {parent.setting_label}</Typography> </MenuItem>
                                    ) )}
                                </Field>
                            </FormControl>
                            <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <Field sx={{ fontSize: "14px" }} as={TextField} placeholder="Unique identifier for the setting" onChange={( e ) => handleKeyValueChange( e, setFieldValue )} name='setting_key' label="Setting key*" type='text' />
                                {values.setting_key && <Typography variant='subtitle2'>{values.setting_key}</Typography>}
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='setting_key' /></Typography>
                            </FormControl>
                            <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <InputLabel>Module</InputLabel>
                                <Field disabled={values.parent !== 'none'} sx={{ fontSize: "14px" }} as={Select} name='module' label="Module*">
                                    <MenuItem sx={{ fontSize: "14px", pointerEvents: "none" }} value="-">
                                        <Typography onClick={openAddModuleDialog} variant='subtitle2' sx={{ pointerEvents: "all", width: "fit-content", textDecoration: "underline", cursor: "pointer" }} color="secondary" >Add new module</Typography>
                                    </MenuItem>
                                    {SettingsStore.getModules?.map( mod => (
                                        <MenuItem key={mod.id} sx={{ fontSize: "14px" }} value={mod.module}>{mod.label}</MenuItem>
                                    ) )}
                                </Field>
                            </FormControl>
                            <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <InputLabel>Setting type</InputLabel>
                                <Field sx={{ fontSize: "14px" }} defaultValue="text" as={Select}
                                    onChange={( e ) => {
                                        if ( e.target.value === 'boolean' )
                                            setFieldValue( 'default_value', false )
                                        else if ( e.target.value === 'select' )
                                            setFieldValue( 'default_value', 'none' )
                                        else {
                                            setFieldValue( 'default_value', '' )
                                            setFieldValue( 'setting_options', [] )
                                        }
                                        setFieldValue( 'setting_data_type', e.target.value )
                                    }} name='setting_data_type' label="Setting type*">
                                    <MenuItem dense sx={{ fontSize: "14px" }} value="boolean">Boolean</MenuItem>
                                    <MenuItem dense sx={{ fontSize: "14px" }} value="multiple">Multiple</MenuItem>
                                    <MenuItem dense sx={{ fontSize: "14px" }} value="number">Number</MenuItem>
                                    <MenuItem dense sx={{ fontSize: "14px" }} value="select">Select (predefined options)</MenuItem>
                                    <MenuItem dense sx={{ fontSize: "14px" }} value="text">Text</MenuItem>
                                </Field>
                                <Typography variant='subtitle2' placeholder="Unique identifier for the setting" fontSize="12px" color="error"><ErrorMessage name='setting_data_type' /></Typography>
                            </FormControl>
                            {["text", "number", "multiple"].includes( values.setting_data_type ) && <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <Field sx={{ fontSize: "14px" }} as={TextField} name='default_value' label="Default value*" type='text' />
                                {values.setting_data_type === 'multiple' && <Typography variant='subtitle2' color="GrayText">Enter as comma separated values</Typography>}
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='default_value' /></Typography>
                            </FormControl>}
                            {values.setting_data_type === 'select' && <Box>
                                <Paper>
                                    <Box padding="10px" display="flex" alignItems="center" flexWrap="wrap" gap="10px">
                                        {values.setting_options.map( ( option, index ) => (
                                            <Chip key={option} onDelete={() => deleteOption( values.setting_options, setFieldValue, index )} size="small" label={option} />
                                        ) )}
                                        <TextField onKeyDown={( e ) => { handleKeyDownForOptionsInput( e, setFieldValue, values.setting_options ) }} type="text" variant='standard' InputProps={{ disableUnderline: true, sx: { border: "none", outline: "none", fontSize: "14px" } }} sx={{ width: "300px", minWidth: "200px", border: "none", outline: "none", padding: "5px" }} size="small" placeholder='Type options and press enter to add...' />
                                    </Box>
                                </Paper>
                                <FormControl sx={{ marginBottom: "10px", marginTop: "20px" }} fullWidth>
                                    <InputLabel>Select default value*</InputLabel>
                                    <Field as={Select} value={values.default_value} name='default_value' label="Select default value*">
                                        <MenuItem disabled dense sx={{ fontSize: "14px" }} value={"none"}>None</MenuItem>
                                        {values.setting_options.map( option => (
                                            <MenuItem key={option} sx={{ fontSize: "14px" }} dense value={option}>{option}</MenuItem>
                                        ) )}
                                    </Field>
                                    <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='default_value' /></Typography>
                                </FormControl>
                            </Box>}
                            {values.setting_data_type === 'boolean' && <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <InputLabel>Default value*</InputLabel>
                                <Field sx={{ fontSize: "14px" }} as={Select} name='default_value' label="Default value*">
                                    <MenuItem sx={{ fontSize: "14px" }} value={true}>True</MenuItem>
                                    <MenuItem sx={{ fontSize: "14px" }} value={false}>False</MenuItem>
                                </Field>
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='default_value' /></Typography>
                            </FormControl>}
                            {!['boolean', 'select', 'multiple'].includes( values.setting_data_type ) && <FormControl sx={{ marginBottom: "10px", marginTop: "10px" }} fullWidth>
                                <Field sx={{ fontSize: "14px" }} as={TextField} name='setting_unit' label="Setting unit" type='text' />
                                <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='setting_unit' /></Typography>
                            </FormControl>}

                            <Box marginTop="10px" display="flex" gap="10px" alignItems="center">
                                <Button disabled={adding} type="submit" disableElevation sx={{ textTransform: "capitalize", display: "flex", alignItems: "center", gap: "10px" }} variant='contained'> {adding && <CircularProgress size={14} />} {adding ? "Adding setting..." : "Add setting"}</Button>
                                <Button onClick={() => setState( false )} color="error" sx={{ textTransform: "capitalize" }} variant="outlined">Cancel</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default AddSetting
