import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Icons } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import { useState } from 'react'
import * as Yup from 'yup'
import React from 'react'

const allEntityTypes = [
    { value: "array", label: "Array" },
    { value: "boolean", label: "Boolean" },
    { value: "cgpaorpercentage", label: "CGPA or Percentage" },
    { value: "date", label: "Date" },
    { value: "dateorpresent", label: "Date or present" },
    { value: "document", label: "Document" },
    { value: "email", label: "E-mail" },
    { value: "file", label: "Profile" },
    { value: "linkwithlabel", label: "Label with link" },
    { value: "number", label: "Number" },
    { value: "select", label: "Select" },
    { value: "string", label: "String" },
    { value: "url", label: "URL" },
]

const EditEntity = ( { entity, setSelectedEntity } ) => {

    const [entityType, setEntityType] = useState( entity.entity_detail.data_type )
    const [textfieldValue, setTextfieldValue] = useState( '' )
    const [optionValues, setOptionValues] = useState( entity.entity_detail.children )

    const ResumeBuilderStore = useResumeBuilderStore()

    const handleChange = ( e ) => {
        setTextfieldValue( e.target.value )
    }

    const removeItem = ( i ) => {
        let newValues = [...optionValues]
        newValues.splice( i, 1 )
        setOptionValues( newValues )
    }

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            e.preventDefault()
            let newValues = [...optionValues]
            newValues.push( e.target.value )
            setOptionValues( newValues )
            setTextfieldValue( '' )
        }
    }

    const validationSchema = Yup.object().shape( {
        entityName: Yup.string().required( "Template name is required!" ),
        entityDescription: Yup.string().required( "Description is required!" ),
        key: Yup.string().required( "Key is required!" ),
        entityLabel: Yup.string().required( "Entity label is required!" )
    } )

    const initialValues = {
        entityName: entity.entity_name,
        entityDescription: entity.description,
        entityLabel: entity.entity_detail.label,
        key: entity.entity_detail.key,
        entityType: entity.entity_detail.data_type,
        showLabel: entity.entity_detail.label_show,
        longText: entity.entity_detail.longText,
        forProctors: entity.entity_detail.only_for_proctors ? true : false
    }

    const updateEntity = async ( { entityName, entityDescription, key, entityLabel, entityType, longText, showLabel, forProctors }, { resetForm } ) => {
        if ( entityType === "select" ) {
            if ( optionValues.length < 2 ) {
                toast( "Two or more options are required for this type of entity" )
            } else {
                const payload = { id: entity.id, entityName, entityDescription, entityDetail: { key: key.replace( /[^\w\d]/g, "_" ).toLowerCase(), label: entityLabel, label_show: showLabel, data_type: entityType, longText, only_for_proctors: forProctors, children: optionValues } }
                await ResumeBuilderStore.editEntity( payload )
                await ResumeBuilderStore.fetchEntities()
                setSelectedEntity( null )
                resetForm()
            }
        } else {
            const payload = { id: entity.id, entityName, entityDescription, entityDetail: { key: key.replace( /[^\w\d]/g, "_" ).toLowerCase(), label: entityLabel, label_show: showLabel, data_type: entityType, children: [] } }
            await ResumeBuilderStore.editEntity( payload )
            await ResumeBuilderStore.fetchEntities()
            setSelectedEntity( null )
            resetForm()
        }
    }

    const closeDialog = () => {
        setSelectedEntity( null )
    }

    return (
        <Dialog PaperProps={{ sx: { width: "1050px" } }} open={entity ? true : false}>
            <DialogTitle>
                Edit {entity.entity_name}
            </DialogTitle>
            <DialogContent>
                <Formik onSubmit={updateEntity} validationSchema={validationSchema} initialValues={initialValues}>
                    {( { values, setFieldValue, resetForm } ) => {
                        return (
                            <Form>
                                <FormControl sx={{ marginBottom: "20px", marginTop: "10px" }} fullWidth>
                                    <Field as={TextField} name='entityName' label="Entity name" type='text' />
                                    <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='entityName' /></Typography>
                                </FormControl>
                                <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                    <Field as={TextField} multiline rows={3} name='entityDescription' label="Entity description" type='text' />
                                    <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='entityDescription' /></Typography>
                                </FormControl>
                                <Typography marginBottom="10px" variant='subtitle2' color="textSecondary">Entity details</Typography>
                                <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                    <Field as={TextField} name='key' label="Entity Key" type='text' />
                                    <Typography variant='subtitle2' fontSize="12px" color="GrayText">{values.key.replace( /[^\w\d]/g, "_" ).toLowerCase()}</Typography>
                                    <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='key' /></Typography>
                                </FormControl>
                                <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                    <Field as={TextField} name='entityLabel' label="Entity label" type='text' />
                                    <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='entityLabel' /></Typography>
                                </FormControl>
                                <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                    <InputLabel>Entity type</InputLabel>
                                    <Field as={Select} value={entityType} onChange={( e ) => { setEntityType( e.target.value ); setFieldValue( 'entityType', e.target.value ) }} name='entityType' label="Entity type" type='text' >
                                        {allEntityTypes.map( type => (
                                            <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                        ) )}
                                    </Field>
                                    <Typography variant='subtitle2' fontSize="12px" color="error"><ErrorMessage name='entityType' /></Typography>
                                </FormControl>
                                {entityType === 'linkwithlabel' &&
                                    <FormControl sx={{ marginBottom: "20px" }} fullWidth>
                                        <Field as={TextField} name='linkLabel' label="Link label" type='text' />
                                    </FormControl>
                                }
                                {( entityType === 'select' ) &&
                                    <Box marginBottom="20px" padding="5px 0 0 5px" borderRadius="5px" border="1px solid #d3d3d3">
                                        <FormControl sx={{ flexGrow: 1 }} fullWidth>
                                            <TextField value={textfieldValue} onChange={handleChange} onKeyDown={handleKeyDown} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px", "@media(max-width:900px)": { width: "100%" } } }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "@media(max-width:900px)": { width: "100%" } }} placeholder={`To add options, type and press 'Enter'`} variant="standard" type="text" />
                                        </FormControl>
                                        <Box padding="10px">
                                            <Typography variant='subtitle2' gutterBottom={optionValues.length !== 0} color="GrayText">{optionValues.length === 0 ? `No options Yet` : ``}</Typography>
                                            {optionValues.length > 0 && <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap">
                                                {optionValues.map( ( val, i ) => (
                                                    <Chip key={i} label={val} size='large' deleteIcon={Icons.default.CloseIcon} onDelete={() => { removeItem( i ) }} sx={{ border: "1px solid #d3d3d3", fontSize: "14px", fontWeight: "600" }} />
                                                ) )}
                                            </Box>}
                                        </Box>
                                    </Box>
                                }
                                <Box width="fit-content" display="flex" flexDirection="column" gap="5px">
                                    <Box>
                                        <Field as={FormControlLabel} sx={{ margin: "0", justifyContent: "flex-start" }} labelPlacement="start" control={<Switch name="showLabel" size='small' checked={values.showLabel} onChange={() => setFieldValue( 'showLabel', !values.showLabel )} />} label={<Typography variant="subtitle2" marginRight="20px">Show label</Typography>} />
                                    </Box>
                                    <Box>
                                        <Field as={FormControlLabel} sx={{ margin: "0" }} labelPlacement="start" control={<Switch name="forProctors" checked={values.forProctors} onChange={() => setFieldValue( 'forProctors', !values.forProctors )} size='small' />} label={<Typography variant="subtitle2" marginRight="20px">Only for proctors</Typography>} />
                                    </Box>
                                    {entityType === 'string' && <Box>
                                        <Field as={FormControlLabel} sx={{ margin: "0" }} labelPlacement="start" control={<Switch name="longText" size='small' checked={values.longText} onChange={() => setFieldValue( 'longText', !values.longText )} />} label={<Typography variant="subtitle2" marginRight="20px">Long text(paragraph)</Typography>} />
                                    </Box>}
                                </Box>
                                <Box marginTop="20px" display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                                    <Button type='submit' disableElevation variant='contained' sx={{ textTransform: "capitalize" }}>
                                        Save changes
                                    </Button>
                                    <Button onClick={() => { closeDialog(); resetForm() }} color="error" variant='outlined' sx={{ textTransform: "capitalize" }}>
                                        Cancel
                                    </Button>
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default EditEntity
