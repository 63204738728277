import { Box, Collapse, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { flatColors } from '../../../utils/utilities'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { CopyAllOutlined, Edit } from '@mui/icons-material'
import EditSetting from './EditSetting'
import CustomSwitch from '../../common/CustomSwitch'

const validateAsNumber = ( val ) => {
    try {
        if ( val !== '' )
            return !Number.isNaN( parseInt( val ) )
        else
            return true
    } catch ( err ) {
        return false
    }
}

const getNumericValue = ( val ) => {
    return parseInt( val )
}

const ControlPanelSettingBox = observer( ( { setting, getSelectedSettings } ) => {

    const [isSettingActive, setIsSettingActive] = useState( setting.setting_data_type === 'boolean' ? setting.value === "true" : false )
    const [settingValue, setSettingValue] = useState( setting.setting_data_type === 'boolean' ? setting.value === "true" : setting.value )
    const [subSettingsValues, setSubSettingsValues] = useState( {} )
    const [settingForEdit, setSettingForEdit] = useState( null )

    const handleSettingChange = async ( value, setting ) => {
        try {
            await api.updateSetting( setting.setting_data_id, { value } )
            toast( `${setting.setting_label} updated successfully` )
        } catch ( err ) {
            console.log( err )
        }
    }

    const copySettingKey = ( key ) => {
        navigator.clipboard.writeText( key )
        toast( "Copied key to clipboard" )
    }

    useEffect( () => {
        if ( setting.children.length > 0 ) {
            let subSetts = {}
            for ( let i = 0; i < setting.children.length; i++ ) {
                const child = setting.children[i]
                subSetts[child.setting_data_id] = child.value
            }
            setSubSettingsValues( subSetts )
        }
    }, [setting, setting.setting_data_id] )


    return (
        <Box minWidth="400px" sx={{ borderLeft: `5px solid ${flatColors[3]}` }} display="flex" borderBottom="5px solid #d3d3d3" height="fit-content" alignItems='stretch'>
            {settingForEdit && <EditSetting setState={setSettingForEdit} getSelectedSettings={getSelectedSettings} dialogState={settingForEdit ? true : false} setting={settingForEdit} />}
            <Box flexGrow={1} borderRight="1px solid #eee">
                {setting.setting_data_id && <Box minHeight="60px" flexWrap="wrap" gap="10px" flexGrow={1} padding="5px" display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                        <Box display="flex" flexWrap="wrap" gap={{ md: "10px", xs: "5px" }}>
                            <Typography display="inline-block" fontSize="16px" fontWeight="500">{setting.setting_label} </Typography>
                            <Typography display="inline-flex" alignItems="center" variant="subtitle2" color="secondary" sx={{ cursor: "pointer" }} onClick={() => { setSettingForEdit( setting ) }} > <Edit sx={{ fontSize: "14px" }} /> Edit</Typography>
                            <Typography display="inline-flex" alignItems="center" variant="subtitle2" color="primary" sx={{ cursor: "pointer" }} onClick={() => { copySettingKey( setting.setting_key ) }} > <CopyAllOutlined sx={{ fontSize: "14px" }} /> Copy Setting Key</Typography>
                        </Box>
                        <Typography fontSize="10px">{setting.setting_description}</Typography>
                    </Box>
                    <Box>
                        {setting.setting_data_type === 'boolean' && <CustomSwitch checked={isSettingActive} onChange={( e ) => { handleSettingChange( e.target.checked, setting ); setIsSettingActive( !isSettingActive ) }} />}
                        {( setting.setting_data_type === 'text' || setting.setting_data_type === 'multiple' ) && <TextField InputProps={{ endAdornment: <Typography fontSize="10px" fontWeight="600" color="GrayText">{setting.unit}</Typography> }} size="small" value={settingValue} onBlur={( e ) => handleSettingChange( e.target.value, setting )} onChange={( e ) => setSettingValue( e.target.value )} />}
                        {setting.setting_data_type === 'number' && <TextField type='number' InputProps={{ endAdornment: <Typography fontSize="10px" fontWeight="600" color="GrayText">{setting.unit}</Typography> }} size="small" value={settingValue} onBlur={( e ) => { if ( validateAsNumber( e.target.value ) ); handleSettingChange( getNumericValue( e.target.value ), setting ) }} onChange={( e ) => { if ( validateAsNumber( e.target.value ) ) setSettingValue( parseInt( e.target.value ) ) }} />}
                        {setting.setting_data_type === 'select' && <Select size="small" value={settingValue} onChange={( e ) => { handleSettingChange( e.target.value, setting ); setSettingValue( e.target.value ) }}>
                            {setting.setting_options.map( option => (
                                <MenuItem value={option} sx={{ fontSize: "14px" }} dense key={option}>{option}</MenuItem>
                            ) )}
                        </Select>}
                        {setting.setting_data_type === 'multiple' && <Typography color="GrayText" fontSize="12px" textAlign="right" variant='subtitle2'>Enter as comma separated values</Typography>}
                    </Box>
                </Box>}
                <Collapse in={isSettingActive === true && setting.children.length > 0}>
                    <Box display="flex" flexDirection="column" gap="5px" borderTop="2px solid #eee" padding="10px">
                        {setting?.children?.map( child => (
                            <Box sx={{ "&:last-child": { border: "none", paddingBottom: 0 } }} padding="0 0 5px 0" borderBottom="1px solid #d3d3d355" flexGrow={1} display="flex" alignItems="center" flexWrap="wrap" gap="10px" justifyContent="space-between" key={child.setting_data_id}>
                                <Box>
                                    <Box display="flex" flexWrap="wrap" gap={{ md: "10px", xs: "5px" }}>
                                        <Typography display="inline-block" fontSize="16px" fontWeight="500">{child.setting_label} </Typography>
                                        <Typography display="inline-flex" alignItems="center" variant="subtitle2" color="secondary" sx={{ cursor: "pointer" }} onClick={() => { setSettingForEdit( child ) }} > <Edit sx={{ fontSize: "14px" }} /> Edit</Typography>
                                        <Typography display="inline-flex" alignItems="center" variant="subtitle2" color="primary" sx={{ cursor: "pointer" }} onClick={() => { copySettingKey( child.setting_key ) }} > <CopyAllOutlined sx={{ fontSize: "14px" }} /> Copy Setting Key</Typography>
                                    </Box>
                                    <Typography fontSize="10px" fontWeight="300">{child.setting_description}</Typography>
                                </Box>
                                <Box>
                                    {child.setting_data_type === 'boolean' && <CustomSwitch checked={subSettingsValues[child.setting_data_id] ? subSettingsValues[child.setting_data_id] : false} onChange={( e ) => { handleSettingChange( e.target.checked, child ); setSubSettingsValues( { [child.setting_data_id]: e.target.value } ) }} />}
                                    {( child.setting_data_type === 'text' || child.setting_data_type === 'multiple' ) && <TextField InputProps={{ endAdornment: <Typography fontSize="10px" fontWeight="600" color="GrayText">{child.unit}</Typography> }} size="small" value={subSettingsValues[child.setting_data_id] ? subSettingsValues[child.setting_data_id] : ''} onBlur={( e ) => handleSettingChange( e.target.value, child )} onChange={( e ) => setSubSettingsValues( { ...subSettingsValues, [child.setting_data_id]: e.target.value } )} />}
                                    {child.setting_data_type === 'number' && <TextField type='number' InputProps={{ endAdornment: <Typography fontSize="10px" fontWeight="600" color="GrayText">{child.unit}</Typography> }} size="small" value={subSettingsValues[child.setting_data_id] ? subSettingsValues[child.setting_data_id] : ''} onBlur={( e ) => handleSettingChange( e.target.value, child )} onChange={( e ) => { if ( validateAsNumber( e.target.value ) ) setSubSettingsValues( { ...subSettingsValues, [child.setting_data_id]: parseInt( e.target.value ) } ) }} />}
                                    {child.setting_data_type === 'select' && <Select size="small" value={settingValue} onChange={( e ) => { handleSettingChange( e.target.value, child ); setSettingValue( e.target.value ) }}>
                                        {child.setting_options.map( option => (
                                            <MenuItem value={option} sx={{ fontSize: "14px" }} dense key={option}>{option}</MenuItem>
                                        ) )}
                                    </Select>}
                                    {child.setting_data_type === 'multiple' && <Typography color="GrayText" fontSize="12px" textAlign="right" variant='subtitle2'>Enter as comma separated values</Typography>}
                                </Box>
                            </Box>
                        ) )}
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )
} )

export default ControlPanelSettingBox
