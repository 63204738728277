
// import leaderboard1badge from '../../assets/card-images/leaderboard-rank-1.png'
// import leaderboard2badge from '../../assets/card-images/leaderboard-rank-2.png'
// import leaderboard3badge from '../../assets/card-images/leaderboard-rank-3.png'
import { Box, Typography, useTheme } from '@mui/material'
// import profileImage from '../../assets/avatar copy.jpg'
// import { useNavigate } from 'react-router-dom'
import React from 'react'


const LeaderboardCard = () => {

    const { theme } = useTheme()

    // const navigate = useNavigate()

    return (
        <Box
            flexShrink={0}
            padding="20px"
            boxShadow={theme !== 'dark' && "0px 2px 5px 0px #DBDBDBAB"}
            borderRadius="10px"
            display="flex"
            flexDirection="column"
            // onClick={() => navigate( '/student/leaderboard' )}
            sx={{
                background: "linear-gradient(121.05deg, #FF9500 6.44%, #D31F33 85.55%)",
                position: "relative",
                overflow: "hidden",
                // cursor: "pointer",

                "&::after": {
                    content: `''`,
                    height: "150%",
                    aspectRatio: "1",
                    background: "linear-gradient(130.99deg, rgba(255, 255, 255, 0.24) -16.86%, rgba(255, 255, 255, 0) 74.99%)",
                    position: "absolute",
                    transformOrigin: "center",
                    borderRadius: "50%",
                    bottom: "-90%",
                    transform: "translate(-50%,0)",
                    zIndex: 1
                },
                "&::before": {
                    content: `''`,
                    height: "100%",
                    aspectRatio: "1",
                    background: "linear-gradient(320.29deg, rgba(255, 255, 255, 0.24) -13.64%, rgba(255, 255, 255, 0) 35.52%)",
                    position: "absolute",
                    transformOrigin: "center",
                    borderRadius: "50%",
                    top: "-50%",
                    right: "0",
                    transform: "translate(20%,0)"
                }
            }}>
            {/* <Typography fontSize="20px" color="white" fontWeight="700">Leaderboard</Typography> */}
            {/* <Box padding="10px" display="flex" justifyContent="center" alignItems="center" gap="30px">
                <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                    <Box position="relative" sx={{ zIndex: 2 }} borderRadius="50%" width="50px" height="50px" border="3px solid #fff">
                        <img src={profileImage} style={{ objectFit: "cover", borderRadius: "50%" }} width="100%" height="100%" alt="rank 1" />
                        <Box width="20px" position="absolute" bottom="-10px" right="-5px">
                            <img width="100%" height="auto" src={leaderboard2badge} alt="rank 2" />
                        </Box>
                    </Box>
                    <Typography color="white" textAlign="center" variant="subtitle2" fontSize="12px">Jhon David</Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                    <Box position="relative" borderRadius="50%" width="60px" height="60px" border="3px solid #fff">
                        <img src={profileImage} style={{ objectFit: "cover", borderRadius: "50%" }} width="100%" height="100%" alt="rank 1" />
                        <Box width="30px" position="absolute" bottom="-15px" right="-15px">
                            <img width="100%" height="auto" src={leaderboard1badge} alt="rank 1" />
                        </Box>
                    </Box>
                    <Typography color="white" textAlign="center" variant="subtitle2" fontSize="12px">Tim David</Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                    <Box position="relative" borderRadius="50%" width="50px" height="50px" border="3px solid #fff">
                        <img src={profileImage} style={{ objectFit: "cover", borderRadius: "50%" }} width="100%" height="100%" alt="rank 1" />
                        <Box width="20px" position="absolute" bottom="-10px" right="-5px">
                            <img width="100%" height="auto" src={leaderboard3badge} alt="rank 3" />
                        </Box>
                    </Box>
                    <Typography color="white" textAlign="center" variant="subtitle2" fontSize="12px">Jhon Doe</Typography>
                </Box>
            </Box> */}
            <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
                <Typography variant='h6' color="white" textAlign="center" fontWeight={400}>LEADERBOARD <br /> COMMING SOON</Typography>
            </Box>
        </Box>
    )
}

export default LeaderboardCard
