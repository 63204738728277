import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Icons, latexDelimiters } from '../../utils/utilities'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import CustomSwitch from '../common/CustomSwitch'
// import { useQuizAndQuestionbankStore } from '../../store/quiz.store'
import { observer } from 'mobx-react'
import Latex from 'react-latex-next'
import CustomWysiwyg from '../common/CustomWysiwyg'

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
            ['code-block'],
        ],
        // handlers: {
        //     image: async () => await handleFileInput( 'image' ),
        //     video: async () => await handleFileInput( 'video' ),
        // },
    },
}

const AddQuestionDialog = observer( ( { setSelectedSection, title = "Add question", state, setState, section, setQuestions } ) => {

    const [adding, setAdding] = useState( false )
    const [optionAddedFieldValue, setOptionAddedFieldValue] = useState( "" )
    const [optionAdderSwitchState, setOptionAdderSwitchState] = useState( false )

    const { palette, border } = useTheme()


    const initialValues = {
        question: "",
        questionPlainText: "",
        marks: "",
        questionType: "MCQ",
        selectionType: "single",
        options: [],
        answersStatus: [],
        correctAnswer: "",
        section: section || "",
        keyAnswer: "",
        keyAnswerPlainText: "",
    }

    const validationSchema = Yup.object().shape( {
        questionPlainText: Yup.string().required( "Question is required!" ),
        marks: Yup.number().required( "Marks is required." ).test( "VALID_MARKS", "Enter a valid marks", ( val, { parent } ) => {
            if ( val && !isNaN( parseInt( val ) ) && parseInt( val ) > 0 )
                return true
            return false
        } ),
        options: Yup.mixed().required( "Options are required" ).test( "MINIMUM_TWO_OPTIONS", "A question should has minimum of 2 options.", ( val, { parent } ) => {
            if ( val.length > 1 || parent?.questionType !== "MCQ" )
                return true
            return false
        } ),
        answersStatus: Yup.mixed().test( "ATLEAST_TWO_CORRECT_ANSWERS", "Please select at least two correct answers.", ( val, { parent } ) => {
            if ( parent.selectionType === "single" || parent?.questionType !== 'MCQ' ) return true
            if ( val.filter( i => i === true ).length >= 2 )
                return true
            return false
        } ),
        correctAnswer: Yup.number().test( "CORRECT_ANSWER", "Please select a correct answer.", ( val, { parent } ) => {
            if ( parent.selectionType !== "single" || parent?.questionType !== 'MCQ' ) return true
            if ( val >= 0 && val < parent.options.length )
                return true
            return false
        } ),
        keyAnswer: Yup.string().test( "KEY_ANSWER_TEST", "Please provide a key answer.", ( val, { parent } ) => {
            if ( parent?.questionType === 'MCQ' ) return true
            if ( !parent?.keyAnswerPlainText || parent?.keyAnswerPlainText?.length === 0 ) return false
            return true
        } )
    } )

    const handleEditorValueChange = ( val, text, setValue ) => {
        setValue( 'question', val )
        setValue( 'questionPlainText', text?.trim() )
    }

    const handleKeyAnswerChange = ( val, text, setValue ) => {
        setValue( 'keyAnswer', val )
        setValue( 'keyAnswerPlainText', text?.trim() )
    }

    const handleSelectionTypeChange = ( val, setValue ) => {
        setValue( 'correctAnswer', "" )
        setValue( 'selectionType', val )
    }

    const handleQuestionTypeChange = ( val, setValue ) => {
        setValue( 'questionType', val )
        setValue( 'options', [] )
    }

    const handleCorrectAnswerChange = ( index, values, val, setValue ) => {
        if ( values.selectionType === 'single' )
            setValue( 'correctAnswer', index )
        else {
            let newStatus = [...values.answersStatus]
            newStatus[index] = val
            setValue( 'answersStatus', newStatus )
        }
    }

    const addOption = ( options, correctAnswers, type, setValue ) => {
        if ( optionAddedFieldValue && ( optionAddedFieldValue?.trim() !== "" && optionAddedFieldValue?.trim() !== "<p><br/></p>" && optionAddedFieldValue?.trim() !== "<p></p><br/>" && optionAddedFieldValue?.trim() !== "<p><br></p>" ) ) {
            const newOptions = [...options, { option_text: optionAddedFieldValue }]
            if ( type === 'single' && optionAdderSwitchState ) {
                setValue( 'correctAnswer', newOptions.length - 1 )
            }
            const newStatuses = [...correctAnswers, optionAdderSwitchState]
            setValue( 'answersStatus', newStatuses )

            setValue( 'options', newOptions )
            setOptionAddedFieldValue( "<p></p><br/>" )
            setOptionAdderSwitchState( false )
        }
    }

    const removeOption = ( index, values, setValue ) => {
        let newOptions = [...values.options]
        let newStatus = [...values.answersStatus]
        newStatus.splice( index, 1 )
        newOptions.splice( index, 1 )
        setValue( 'answersStatus', newStatus )
        setValue( 'options', newOptions )
        if ( values.correctAnswer === index )
            setValue( 'correctAnswer', "" )
    }

    const handleClose = ( resetForm ) => {
        resetForm()
        setSelectedSection( null )
        setState( false )
    }

    const addQuestion = async ( values, { resetForm } ) => {
        values.question_section = section
        values.id = Date.now()
        setAdding( true )
        setQuestions( prev => [...prev, values] )
        setAdding( false )
        resetForm()
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "950px", background: palette.form.formCardBg } }} open={state}>
            <Formik onSubmit={addQuestion} initialValues={initialValues} validationSchema={validationSchema}>
                {( { values, setFieldValue, resetForm } ) => {

                    return (
                        <Box>
                            <Box display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="20px">
                                <Typography variant="h6" fontSize="18px">{title}</Typography>
                                <IconButton onClick={() => handleClose( resetForm )} size="small">{Icons.default.CloseIcon}</IconButton>
                            </Box>
                            <DialogContent sx={{ paddingTop: "0", overflow: "auto" }}>
                                <Form>
                                    <Box border={border[1]} padding="20px" borderRadius="5px" bgcolor={palette.contentBg} >
                                        {values.section && values.section !== "Primary" && <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor}>
                                                Question Section
                                            </Typography>
                                            <Field disabled={true} fullWidth size="small" as={TextField} name="section" value={values.section} />
                                            <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='section' /></Typography>
                                        </FormControl>}
                                        <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question Text *</Typography>
                                            <Box bgcolor={palette.form.inputBg}>
                                                <CustomWysiwyg modules={modules} onChange={( val, text ) => handleEditorValueChange( val, text, setFieldValue )} value={values.question?.replace( /$/g, "" )} placeholder={'Type here...'} />
                                                <Typography fontSize="12px" color='textSecondary'>To Add mathematical formula, use latex, Eg: <strong> <em>${"{LATEX}"}$</em> </strong></Typography>
                                                {values.question?.match( /\$/ ) && <Box marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} borderRadius="10px">
                                                    <Latex delimiters={latexDelimiters} >{values.question}</Latex>
                                                </Box>}

                                            </Box>
                                            <Typography fontSize="12px" color='errorMessage.main'>
                                                <ErrorMessage name='questionPlainText' />{' '}
                                            </Typography>
                                        </FormControl>
                                        <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question marks *</Typography>
                                            <Field size="small" as={TextField} placeholder="Specify question's points" type="number" name="marks" onWheel={e => { e.target.blur() }} />
                                            <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='marks' />{' '}</Typography>
                                        </FormControl>
                                        <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                            <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Question type</Typography>
                                            <RadioGroup name='questionType' value={values.questionType} color='primaryDark' onChange={e => handleQuestionTypeChange( e.target.value, setFieldValue )} row>
                                                <FormControlLabel value="MCQ" control={<Radio color="primaryDark" />} label="MCQ" />
                                                <FormControlLabel value="descriptive" control={<Radio color="primaryDark" />} label="Descriptive" />
                                            </RadioGroup>
                                        </FormControl>
                                        {values?.questionType === 'MCQ' && <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Selection type</Typography>
                                            <RadioGroup sx={{ marginTop: "-5px" }} name='selectionType' value={values.selectionType} color='primaryDark' onChange={( e ) => ( handleSelectionTypeChange( e.target.value, setFieldValue ) )} row>
                                                <FormControlLabel value="single" control={<Radio color='primaryDark' />} label="Single" />
                                                <FormControlLabel value="multiple" control={<Radio color='primaryDark' />} label="Multiple" />
                                            </RadioGroup>
                                        </FormControl>}
                                        {values.options.length > 0 && <Box>
                                            <Typography gutterBottom variant="subtitle2">Options</Typography>
                                            {values.options.map( ( option, optionIndex ) => (
                                                <Box marginBottom="2px" key={optionIndex} border="1px solid #d3d3d3" borderRadius="5px" display="flex" gap="5px" alignItems="center">
                                                    <Tooltip placement='right' title={( optionIndex === values.correctAnswer || values.answersStatus[optionIndex] ) ? values.selectionType === "single" ? "Marked as correct" : "mark as incorrect" : "Mark as correct answer"}>
                                                        <span>
                                                            {values.selectionType !== "single" && <Checkbox onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={values.answersStatus[optionIndex] || false} />}
                                                            {values.selectionType === "single" && <Radio onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={optionIndex === values.correctAnswer} />}
                                                        </span>
                                                    </Tooltip>
                                                    <Box flexGrow={1} className='discussion-wysiwyg-container' borderRadius="10px">
                                                        <Latex delimiters={latexDelimiters} >{option.option_text}</Latex>
                                                    </Box>
                                                    <Box>
                                                        <Tooltip title="Remove option">
                                                            <IconButton size="small" color="errorMessage.main" onClick={() => removeOption( optionIndex, values, setFieldValue )}>{Icons.default.RemoveIcon}</IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                            ) )}
                                        </Box>}
                                        {values.questionType === 'MCQ' && <Box>
                                            <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='options' /></Typography>
                                            <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='correctAnswer' /></Typography>
                                            <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='answersStatus' /></Typography>
                                            <Box marginTop="10px" border={border[1]} display="flex" gap="10px" flexDirection="column" borderRadius="5px">
                                                <Typography padding="20px 20px 0 20px" variant='subtitle1' gutterBottom fontWeight='500'>Add option</Typography>
                                                <Box padding="0 20px 20px 20px" display="flex" alignItems="flex-start" gap="10px" flexDirection="column">
                                                    <Box bgcolor={palette.form.inputBg} color={palette.common.font} width="100%">
                                                        <CustomWysiwyg placeholder='Type the option text here...' modules={modules} value={optionAddedFieldValue} onChange={setOptionAddedFieldValue} />
                                                    </Box>
                                                    {optionAddedFieldValue?.includes( "$" ) && <Box marginTop="10px" className='discussion-wysiwyg-container' width="calc(100% - 20px)" padding="10px" border={border[1]} borderRadius="10px">
                                                        <Latex delimiters={latexDelimiters} >{optionAddedFieldValue}</Latex>
                                                    </Box>}
                                                    <FormControlLabel sx={{ marginLeft: "0" }} labelPlacement='start' label="Correct answer?" control={<CustomSwitch color='primaryDark' checked={optionAdderSwitchState} onChange={e => setOptionAdderSwitchState( e.target.checked )} />} />
                                                    <Button sx={{ background: "white", textTransform: "capitalize", '&:hover': { background: "white" } }} onClick={() => addOption( values.options, values.answersStatus, values.selectionType, setFieldValue )} variant="outlined" type="button" startIcon={Icons.default.AddIcon} >Add Option</Button>
                                                </Box>
                                            </Box>
                                        </Box>}
                                        {values.questionType !== 'MCQ' && <Box marginTop="10px" display="flex" flexDirection="column">
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Specify key answer for evaluation *</Typography>
                                            <Box display="flex" alignItems="flex-start" gap="10px" flexDirection="column">
                                                <Box bgcolor={palette.form.inputBg} color={palette.common.font} width="100%">
                                                    <CustomWysiwyg placeholder='Type the answer here...' className='custom-quill' modules={modules} value={values.keyAnswer} onChange={( val, text ) => handleKeyAnswerChange( val, text, setFieldValue )} />
                                                </Box>
                                                {values?.keyAnswer?.includes( "$" ) && <Box marginTop="10px" className='discussion-wysiwyg-container' width="calc(100% - 20px)" padding="10px" border={border[1]} borderRadius="10px">
                                                    <Latex delimiters={latexDelimiters} >{values?.keyAnswer}</Latex>
                                                </Box>}
                                            </Box>
                                            <Typography fontSize="12px" color='errorMessage.main'>
                                                <ErrorMessage name='keyAnswer' />{' '}
                                            </Typography>
                                        </Box>}
                                        <Box marginTop="40px" display="flex" gap="10px" flexWrap="wrap">
                                            <Button type="submit" disabled={adding} startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation >Add Question</Button>
                                            <Button disableElevation onClick={() => handleClose( resetForm )} sx={{ textTransform: "capitalize" }} variant="contained" color="error" >Close</Button>
                                        </Box>
                                    </Box>
                                </Form>
                            </DialogContent>
                        </Box>
                    )
                }}
            </Formik>
        </Dialog>
    )
} )

export default AddQuestionDialog
