import React from 'react'
import ResumeTemplateBuilder from '../../../components/admin/resume-builder/ResumeTemplateBuilder'
import { Box } from '@mui/material'
import ResumeTemplateEditStore, { ResumeTemplateEditStoreProvider } from '../../../store/resumetemplateEdit.store'
import { useEffect } from 'react'
import { useUserStore } from '../../../store/user.store'
import { useState } from 'react'
import BlockMessage from '../../../components/common/BlockMessage'

const ResumeTemplate = () => {

    const [hasPermission, setHasPermission] = useState( true )
    const UserStore = useUserStore()

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.resume_builder ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        hasPermission ? <Box overflow="auto" padding="20px" flexGrow={1} display="flex">
            <ResumeTemplateEditStoreProvider store={ResumeTemplateEditStore}>
                <ResumeTemplateBuilder />
            </ResumeTemplateEditStoreProvider>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
}

export default ResumeTemplate
