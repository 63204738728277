import { CachedOutlined, CheckCircleOutline, UploadFileOutlined } from '@mui/icons-material'
import { Box, CircularProgress, InputLabel, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import React from 'react'
import { useState } from 'react'
import { useStudentResumeFormStore } from '../../../store/studentresumeform.store'

const DocumentUploadInput = observer( ( { sectionResponseJson, attrkey, attr, section, handleFieldChange } ) => {

    const StudentResumeFormStore = useStudentResumeFormStore()
    const [selectedDoc, setSelectedDoc] = useState( null )
    const [isDraggingOver, setIsDraggingOver] = useState( false )

    const handleDocumentFieldChange = async ( attrkey, e ) => {
        if ( e.target.files.length > 0 ) {
            setSelectedDoc( e.target.files[0] )
            if ( section.multiple )
                handleFieldChange( 'document', attrkey, e.target.files[0] )
            else {
                const key = await StudentResumeFormStore.handleDocumentFieldChange( section, attrkey, e )
                handleFieldChange( 'document', attrkey, key )
            }
            e.target.value = ''
        }
    }
    return (
        <Box sx={{ flexGrow: "1", width: "100%" }} >
            <Box>
                <InputLabel> <Typography variant="subtitle2" gutterBottom color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                <Box
                    onDragOver={() => setIsDraggingOver( true )}
                    onDragLeave={() => setIsDraggingOver( false )}
                    onDragEnter={() => setIsDraggingOver( true )}
                    onDrop={() => setIsDraggingOver( false )}
                    borderRadius='5px'
                    position="relative"
                    sx={{ "&:hover": { borderColor: !StudentResumeFormStore.getFileUploadWait[`${section.section_title}-${attrkey}`] ? "#888b" : "#d3d3d3" }, background: isDraggingOver ? "#eee6" : "none", border: isDraggingOver ? "5px dotted #d3d3d3" : "3px dotted #d3d3d3", opacity: StudentResumeFormStore.getFileUploadWait[`${section.section_title}-${attrkey}`] ? "0.6" : 1 }}
                    padding="10px"
                >
                    <input onChange={( e ) => handleDocumentFieldChange( attrkey, e )} accept='application/pdf' type="file" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, opacity: "0", cursor: "pointer", pointerEvents: StudentResumeFormStore.getFileUploadWait[`${section.section_title}-${attrkey}`] ? "none" : "all" }} />
                    {!sectionResponseJson[attrkey] && <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                        <UploadFileOutlined />
                        <Box>
                            <Typography variant="subtitle2" color="textSecondary">Upload {attr.entity_detail.label}</Typography>
                            <Typography variant="subtitle2">Drag and Drop or <span> <u> <b> <i>Browse</i> </b> </u> </span> </Typography>
                            <Typography fontSize="10px">SUPPORTED FORMAT: <b> <i>PDF</i> </b> </Typography>
                        </Box>
                    </Box>}
                    {( sectionResponseJson[attrkey] || StudentResumeFormStore.getFileValues[`${section.section_title}-${attrkey}`] ) && typeof sectionResponseJson[attrkey] === 'string' && sectionResponseJson[attrkey].trim().length > 0 && <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                        <CachedOutlined />
                        <Box>
                            <Typography variant="subtitle2">Drag and Drop or <span> <u> <b> <i>Browse</i> </b> </u> </span> to replace <b><i>{sectionResponseJson[attrkey].split( "/" ).pop()}</i></b> </Typography>
                            <Typography fontSize="10px">SUPPORTED FORMAT: <b> <i>PDF</i> </b> </Typography>
                        </Box>
                    </Box>}
                </Box>
            </Box>
            {( sectionResponseJson[attrkey] || StudentResumeFormStore.getFileValues[`${section.section_title}-${attrkey}`] ) && typeof sectionResponseJson[attrkey] === 'string' && sectionResponseJson[attrkey].trim().length > 0 && <Typography marginTop="5px" display="flex" alignItems="center" gap="10px" variant="subtitle2">
                <CheckCircleOutline color="success" sx={{ fontSize: "20px" }} /> Uploaded {sectionResponseJson[attrkey].split( "/" ).pop()}
            </Typography>}
            {sectionResponseJson[attrkey] && typeof sectionResponseJson[attrkey] === 'object' && <Typography marginTop="5px" display="flex" alignItems="center" gap="10px" variant="subtitle2">
                <UploadFileOutlined color="success" sx={{ fontSize: "20px" }} /> {sectionResponseJson[attrkey]?.name}
            </Typography>}
            {StudentResumeFormStore.getFileUploadWait[`${section.section_title}-${attrkey}`] && <Typography marginTop="5px" display="flex" alignItems="center" gap="10px" sx={{ color: "customThemeColor.main" }} variant="subtitle2">
                <CircularProgress size={14} /> Uploading {selectedDoc?.name}
            </Typography>}
        </Box>
    )
} )

export default DocumentUploadInput
