import { Box, Button, CircularProgress, Dialog, FormControl, TextField, Typography, useTheme } from "@mui/material"
import { Icons } from "../../utils/utilities"
import { ErrorMessage, Field, Form, Formik } from "formik"
import api from "../../service/api"
import { useState } from "react"
import * as Yup from 'yup'
import { toast } from "react-toastify"


const AddUserDialog = ( { addMode, setAddMode, batch, fetchUsers } ) => {

    const [adding, setAdding] = useState( false )

    const { palette, border } = useTheme()

    const initialValues = {
        name: "",
        id: ""
    }

    const validationSchema = Yup.object().shape( {
        name: Yup.string().required( "User name is required!" ),
        id: Yup.string().required( "User ID is required!" ),
    } )

    const closeDialog = () => {
        setAddMode( false )
    }

    const addUsers = async ( values, { resetForm } ) => {
        setAdding( true )
        try {
            const payload = {}
            payload.batchId = batch.id
            payload.users = [[values.id, values.name]]
            console.log( payload )
            await api.temporaryUsers.addUser( batch.id, payload )
            toast( "User added successfully!" )
            fetchUsers()
            resetForm()
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong while adding user to the batch" )
        } finally {
            setAdding( false )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "350px" } }} open={addMode}>
            <Box padding="10px 20px" borderBottom={border[1]}>
                <Typography variant='h6'>Add new user</Typography>
            </Box>
            <Box padding="20px">
                <Formik onSubmit={addUsers} initialValues={initialValues} validationSchema={validationSchema} >
                    {() => (
                        <Form>
                            <FormControl margin='dense' fullWidth>
                                <label htmlFor="name">
                                    <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>
                                        Name *
                                    </Typography>
                                </label>
                                <Field size="small" as={TextField}
                                    placeholder="Eg: Rohith R"
                                    InputLabelProps={{ sx: { fontSize: "14px" } }}
                                    id="name"
                                    type="text"
                                    rows={4}
                                    name="name"
                                />
                                <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="name" /> </Typography>
                            </FormControl>
                            <FormControl margin='dense' fullWidth>
                                <label htmlFor="user-id">
                                    <Typography gutterBottom variant='subtitle2' color={palette.labelColor}>
                                        User ID *
                                    </Typography>
                                </label>
                                <Field size="small" as={TextField}
                                    placeholder="Eg: AIT19BECS040"
                                    InputLabelProps={{ sx: { fontSize: "14px" } }}
                                    id="user-id"
                                    type="type"
                                    name="id"
                                />
                                <Typography variant='subtitle2' color="errorMessage.main" fontSize="12px"> <ErrorMessage name="id" /> </Typography>
                            </FormControl>
                            <Box display="flex" marginTop="40px" gap="20px" alignItems="center" flexWrap="wrap">
                                <Button startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} disabled={adding} disableElevation sx={{ textTransform: "capitalize" }} type="submit" variant="contained" color="primary">
                                    {adding === false ? 'Add user' : "Adding user..."}
                                </Button>
                                <Button disableElevation disabled={adding} onClick={closeDialog} sx={{ textTransform: "capitalize" }} variant="contained" color="error">
                                    Cancel
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Dialog>
    )
}

export default AddUserDialog