import axios from 'axios'
import { convert } from 'html-to-text'
import writeXlsxFile from 'write-excel-file'
import { showDateInIndianDateFormat } from './utilities'

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday', 'Sunday']
const hours = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
const eventCells = ["00:00:00", "00:30:00", "01:00:00", "01:30:00", "02:00:00", "02:30:00", "03:00:00", "03:30:00", "04:00:00", "04:30:00", "05:00:00", "05:30:00", "06:00:00", "06:30:00", "07:00:00", "07:30:00", "08:00:00", "08:30:00", "09:00:00", "09:30:00", "10:00:00", "10:30:00", "11:00:00", "11:30:00", "12:00:00", "12:30:00", "13:00:00", "13:30:00", "14:00:00", "14:30:00", "15:00:00", "15:30:00", "16:00:00", "16:30:00", "17:00:00", "17:30:00", "18:00:00", "18:30:00", "19:00:00", "19:30:00", "20:00:00", "20:30:00", "21:00:00", "21:30:00", "22:00:00", "22:30:00", "23:00:00", "23:30:00"]
const supportedFormats = ["image/png", "image/jpg", "image/jpeg"]
const attachmentsSupportedFormats = ["image/png", "image/jpg", "image/jpeg", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
export const flatColors = ["#2e3131", "#392e4a", "#16453e", "#cf2f74", "#ff4c30"]
export const chipColors = ["#16453e", "#714d69", "#6d214f", "#f9b42d", "#00b5cc"]

const Axios = axios.create( {
    baseURL: 'https://exam-api.alive.university/api/'
} )

export const BASE_URL = 'https://exam-api.alive.university'
export const ATTACHMENTS_BASE_URL = 'https://alive-content.blr1.digitaloceanspaces.com/'

export const validateQuestionsImportFormat = ( data, type ) => {
    let preview = []
    let questions = []
    let errors = []
    let errorLines = []
    let lines
    if ( type === "txt" ) {
        lines = data.split( "\n" )
        let question = null
        let options = {}
        let numberOfOption = 0
        let correctAnswer = null
        let answers = []
        for ( let i = 0; i < lines.length; i++ ) {
            preview.push( `<p> ${lines[i]}</p>` )
            if ( lines[i] === "\r" ) {
                if ( ( question && question.trim() !== "" ) && ( Object.keys( options ).length > 0 || correctAnswer ) ) {
                    errors.push( { line: i + 1, messages: ["- Answer or options not provided"] } )
                    errorLines.push( i + 1 )
                }
                question = null
                options = {}
                correctAnswer = null
                numberOfOption = 0
                answers = []
            } else if ( lines[i].match( /^([A-Z][.)]\s)/ ) ) {
                const splitted = lines[i].split( /^([A-Z][.)]\s)/ )
                options[numberOfOption] = splitted[2]
                answers.push( splitted[1][0] )
                numberOfOption = numberOfOption + 1
            } else if ( lines[i].match( /ANSWER: / ) ) {
                const answer = lines[i].split( ": " )[1][0]
                correctAnswer = answers.indexOf( answer )
                if ( question && correctAnswer !== null && correctAnswer >= 0 && Object.keys( options ).length > 1 ) {
                    const values = { question: question, questionUrl: "", hint: "", marks: 1, questionType: "MCQ", selectionType: "single", answers: JSON.stringify( options ), correctAnswer: correctAnswer }
                    questions.push( values )
                } else {
                    let messages = []
                    if ( !question || question.trim() === "" )
                        messages.push( "- Question is not provided." )
                    if ( correctAnswer === null || correctAnswer < 0 )
                        messages.push( "- Correct answer is not provided." )
                    if ( Object.keys( options ).length === 0 )
                        messages.push( "- Options not provided." )
                    errors.push( { line: i + 1, messages } )
                    errorLines.push( i + 1 )
                }
                question = null
                options = {}
                correctAnswer = null
                numberOfOption = 0
                answers = []
            } else {
                question = lines[i]
                if ( Object.keys( options ).length > 0 || correctAnswer ) {
                    errors.push( { line: i + 1, messages: ["- Answer or options not provided"] } )
                    errorLines.push( i + 1 )
                }
                options = {}
                correctAnswer = null
                numberOfOption = 0
                answers = []
            }
        }
    } else {
        lines = data.split( "</p>" )
        let question = null
        let options = {}
        let numberOfOption = 0
        let correctAnswer = null
        let answers = []
        for ( let i = 0; i < lines.length; i++ ) {
            preview.push( `<p>${lines[i].substring( 3 )}</p>` )
            if ( lines[i] === "<br />" ) {
                if ( ( question && question.trim() !== "" ) && ( Object.keys( options ).length > 0 || correctAnswer ) ) {
                    errors.push( { line: i + 1, messages: ["- Answer or options not provided"] } )
                    errorLines.push( i + 1 )
                }
                question = null
                options = {}
                correctAnswer = null
                numberOfOption = 0
                answers = []
            } else if ( lines[i].match( /^(<p>[A-Z][.)]\s)/ ) ) {
                const splitted = lines[i].split( /([A-Z][.)]\s)/ )
                options[numberOfOption] = splitted[2]
                answers.push( splitted[1][0] )
                numberOfOption = numberOfOption + 1
            } else if ( lines[i].match( /<p>ANSWER: / ) ) {
                const answer = lines[i].split( ": " )[1][0]
                correctAnswer = answers.indexOf( answer )
                if ( question && correctAnswer !== null && correctAnswer >= 0 && Object.keys( options ).length > 1 ) {
                    const values = { question: question, questionUrl: "", hint: "", marks: 1, questionType: "MCQ", selectionType: "single", answers: JSON.stringify( options ), correctAnswer: correctAnswer }
                    questions.push( values )
                } else {
                    let messages = []
                    if ( !question || question.trim() === "" )
                        messages.push( "- Question is not provided." )
                    if ( correctAnswer === null || correctAnswer < 0 )
                        messages.push( "- Correct answer is not provided." )
                    if ( Object.keys( options ).length === 0 )
                        messages.push( "- Options not provided." )
                    errors.push( { line: i + 1, messages } )
                    errorLines.push( i + 1 )
                }
                question = null
                options = {}
                correctAnswer = null
                numberOfOption = 0
                answers = []
            } else {
                question = lines[i] + "</p>"
                if ( Object.keys( options ).length > 0 || correctAnswer ) {
                    errors.push( { line: i + 1, messages: ["- Answer or options not provided"] } )
                    errorLines.push( i + 1 )
                }
                options = {}
                correctAnswer = null
                numberOfOption = 0
                answers = []
            }
        }
    }
    return { questions, errors, preview, errorLines }
}

export const validateQuestionsImportFormatForQuestionBank = ( data, type, question_bank_id, topic_id ) => {
    let preview = []
    let questions = []
    let errors = {}
    let errorLines = []
    let lines
    if ( type === "txt" ) {
        lines = data.split( "\n" )
        let question = null
        let options = []
        let correctAnswer = null
        let answersStatus = []
        let answers = []
        for ( let i = 0; i < lines.length; i++ ) {
            preview.push( `<p> ${lines[i]}</p>` )
            if ( lines[i] === "\r" ) {
                if ( ( question && question.trim() !== "" ) && ( options.length > 0 || correctAnswer ) ) {
                    errors[i + 1] = { line: i + 1, messages: ["- Answer or options not provided"] }
                    errorLines.push( i + 1 )
                }
                question = null
                options = []
                answersStatus = []
                correctAnswer = null
                answers = []
            } else if ( lines[i].match( /^([A-Z][.)]\s)/ ) ) {
                const splitted = lines[i].split( /^([A-Z][.)]\s)/ )
                options.push( { option_text: splitted[2] } )
                answers.push( splitted[1][0] )
                answersStatus.push( false )
            } else if ( lines[i].match( /ANSWER: / ) ) {
                const answer = lines[i].split( ": " )[1][0]
                correctAnswer = answers.indexOf( answer )
                answersStatus[correctAnswer] = true
                if ( question && correctAnswer !== null && correctAnswer >= 0 && options.length > 1 ) {
                    const values = { question: question, marks: 1, questionType: "MCQ", selectionType: "single", options, correctAnswer, answersStatus, topic_id, question_bank_id }
                    questions.push( values )
                } else {
                    let messages = []
                    if ( !question || question.trim() === "" )
                        messages.push( "- Question is not provided." )
                    if ( correctAnswer === null || correctAnswer < 0 )
                        messages.push( "- Correct answer is not provided." )
                    if ( options.length === 0 )
                        messages.push( "- Options not provided." )
                    errors[i + 1] = { line: i + 1, messages }
                    errorLines.push( i + 1 )
                }
                question = null
                options = []
                correctAnswer = null
                answersStatus = []
                answers = []
            } else {
                question = lines[i]
                if ( options.length > 0 || correctAnswer ) {
                    errors[i + 1] = { line: i + 1, messages: ["- Answer or options not provided"] }
                    errorLines.push( i + 1 )
                }
                options = []
                correctAnswer = null
                answers = []
                answersStatus = []
            }
        }
    } else {
        lines = data.split( "</p>" )
        let question = null
        let options = []
        let correctAnswer = null
        let answersStatus = []
        let answers = []
        for ( let i = 0; i < lines.length; i++ ) {
            preview.push( `<p>${lines[i].substring( 3 )}</p>` )
            if ( lines[i] === "<br />" ) {
                if ( ( question && question.trim() !== "" ) && ( options.length > 0 || correctAnswer ) ) {
                    errors[i + 1] = { line: i + 1, messages: ["- Answer or options not provided"] }
                    errorLines.push( i + 1 )
                }
                question = null
                options = []
                correctAnswer = null
                answers = []
            } else if ( lines[i].match( /^(<p>[A-Z][.)]\s)/ ) ) {
                const splitted = lines[i].split( /([A-Z][.)]\s)/ )
                options.push( { option_text: splitted[2] } )
                answers.push( splitted[1][0] )
                answersStatus.push( false )
            } else if ( lines[i].match( /<p>ANSWER: / ) ) {
                const answer = lines[i].split( ": " )[1][0]
                correctAnswer = answers.indexOf( answer )
                answersStatus[correctAnswer] = true
                if ( question && correctAnswer !== null && correctAnswer >= 0 && options.length > 1 ) {
                    const values = { question: question, marks: 1, questionType: "MCQ", selectionType: "single", options, correctAnswer, answersStatus, topic_id, question_bank_id }
                    questions.push( values )
                } else {
                    let messages = []
                    if ( !question || question.trim() === "" )
                        messages.push( "- Question is not provided." )
                    if ( correctAnswer === null || correctAnswer < 0 )
                        messages.push( "- Correct answer is not provided." )
                    if ( options.length === 0 )
                        messages.push( "- Options not provided." )
                    errors[i + 1] = { line: i + 1, messages }
                    errorLines.push( i + 1 )
                }
                question = null
                options = {}
                correctAnswer = null
                answersStatus = []
                answers = []
            } else {
                question = lines[i] + "</p>"
                if ( options.length > 0 || correctAnswer ) {
                    errors[i + 1] = { line: i + 1, messages: ["- Answer or options not provided"] }
                    errorLines.push( i + 1 )
                }
                options = []
                correctAnswer = null
                answersStatus = []
                answers = []
            }
        }
    }
    return { questions, errors, preview, errorLines }
}

const getCurrentWeek = ( date = new Date() ) => {
    const curr = new Date( date )
    const dday = curr.getDay()
    let week = []
    if ( dday === 0 ) {
        for ( let i = 5; i >= -1; i-- ) {
            let tempCurr = new Date( date )
            let first = tempCurr.getDate() - i
            let day = new Date( tempCurr.setDate( first ) ).toISOString().slice( 0, 10 )
            week.push( day )
        }
    }
    else if ( dday !== 0 ) {
        for ( let i = 1; i <= 6; i++ ) {
            let first = curr.getDate() - curr.getDay() + i + 1
            let day = new Date( curr.setDate( first ) ).toISOString().slice( 0, 10 )
            week.push( day )
        }
        week.push( new Date( curr.setDate( curr.getDate() + 1 ) ).toISOString().slice( 0, 10 ) )
    }

    return week
}

const getPreviousWeek = ( currentWeekStartDate ) => {
    const currentWeekDate = new Date( currentWeekStartDate[0] )
    console.log( currentWeekStartDate )
    const newWeekDate = new Date( currentWeekDate.setDate( currentWeekDate.getDate() - 7 ) )
    const formatedDate = newWeekDate.toLocaleDateString( 'en-us', { year: "numeric", month: "numeric", day: "numeric" } ).replace( /\//g, '-' )
    console.log( getCurrentWeek( formatedDate ) )
    return getCurrentWeek( formatedDate )
}

const getPreviousWorkWeek = ( currentWeekStartDate ) => {
    const currentWeekDate = new Date( currentWeekStartDate[0] )
    const newWeekDate = new Date( currentWeekDate.setDate( currentWeekDate.getDate() - 7 ) )
    const formatedDate = newWeekDate.toLocaleDateString( 'en-us', { year: "numeric", month: "numeric", day: "numeric" } ).replace( /\//g, '-' )
    return getCurrentWorkWeek( formatedDate )
}

const getNextWeek = ( currentWeekStartDate ) => {
    const currentWeekDate = new Date( currentWeekStartDate[0] )
    const newWeekDate = new Date( currentWeekDate.setDate( currentWeekDate.getDate() + 7 ) )
    const formatedDate = newWeekDate.toLocaleDateString( 'en-us', { year: "numeric", month: "numeric", day: "numeric" } ).replace( /\//g, '-' )
    return getCurrentWeek( formatedDate )
}

const getNextWorkWeek = ( currentWeekStartDate ) => {
    const currentWeekDate = new Date( currentWeekStartDate[0] )
    const newWeekDate = new Date( currentWeekDate.setDate( currentWeekDate.getDate() + 7 ) )
    const formatedDate = newWeekDate.toLocaleDateString( 'en-us', { year: "numeric", month: "numeric", day: "numeric" } ).replace( /\//g, '-' )
    return getCurrentWorkWeek( formatedDate )
}

const getCurrentWorkWeek = ( date = new Date() ) => {
    const curr = new Date( date )
    const dday = curr.getDay()
    let week = []
    if ( dday === 0 ) {
        for ( let i = 5; i >= -1; i-- ) {
            let tempCurr = new Date( date )
            let first = tempCurr.getDate() - i
            let day = new Date( tempCurr.setDate( first ) ).toISOString().slice( 0, 10 )
            week.push( day )
        }
    }
    else if ( dday !== 0 ) {
        for ( let i = 1; i <= 6; i++ ) {
            let first = curr.getDate() - curr.getDay() + i + 1
            let day = new Date( curr.setDate( first ) ).toISOString().slice( 0, 10 )
            week.push( day )
        }
    }
    return week.slice( 0, 5 )
}

const getCurrentDate = ( date = new Date() ) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday']
    const today = new Date( date )
    const formatedDate = today.toLocaleDateString( 'en-us', { year: "numeric", month: "numeric", day: "numeric" } )
    return [formatedDate.replace( /\//g, '-' ), today.getDate(), days[today.getDay()]]
}

const getPreviousDate = ( currentDate ) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday']
    const current = new Date( currentDate )
    const previousDate = new Date( current.setDate( current.getDate() - 1 ) )
    const formatedDate = previousDate.toLocaleDateString( 'en-us', { year: "numeric", month: "numeric", day: "numeric" } ).replace( /\//g, '-' )
    return [formatedDate, previousDate.getDate(), days[previousDate.getDay()]]
}

const getNextDate = ( currentDate ) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday']
    const current = new Date( currentDate )
    const nextDate = new Date( current.setDate( current.getDate() + 1 ) )
    const formatedDate = nextDate.toLocaleDateString( 'en-us', { year: "numeric", month: "numeric", day: "numeric" } ).replace( /\//g, '-' )
    return [formatedDate, nextDate.getDate(), days[nextDate.getDay()]]
}

const jsDateFormat = ( date ) => {
    let d = date.split( "T" )[0]
    d = new Date( d ).setDate( new Date( d ).getDate() + 1 )
    return ( new Date( d ).toISOString() ).split( "T" )[0]
}

const formatDateTimeForInputField = ( date, time ) => {
    const dateInSec = new Date( date ).getTime()
    let newDate = new Date( dateInSec + ( 5.5 * 60 * 60 ) ).toLocaleDateString( 'en-US', { month: "2-digit", day: "2-digit", year: "numeric" } ).split( "/" )
    return `${newDate[2]}-${newDate[0]}-${newDate[1]}T${time.substring( 0, 5 )}`
}

const formatDateTimeForDB = ( date, code, delimiter ) => {
    let d = new Date( date ).toLocaleDateString( code, { month: "2-digit", year: "numeric", day: "2-digit", hour: "2-digit", minute: "2-digit" } )
    d = d.replace( /\//g, delimiter )
    const data = {}
    data.dateTime = d
    data.date = d.split( "," )[0]
    data.date = data.date.split( delimiter ).reverse().join( delimiter )
    data.time = d.split( ", " )[1].substring( 0, 5 )
    data.period = d.split( " " )[2]
    if ( data.period === 'pm' && data.time.split( ":" )[0] !== "12" ) {
        let time = parseInt( data.time.split( ":" )[0] ) + 12
        data.twentyFourFormat = `${time}:${data.time.split( ":" )[1]}`
    } else if ( data.period === 'am' && data.time.split( ":" )[0] === "12" ) {
        data.twentyFourFormat = `00:${data.time.split( ":" )[1]}`
    }
    else {
        data.twentyFourFormat = data.time
    }
    return data
}

const formatDateTimeForDisplay = ( date, time ) => {
    const dateTime = new Date( formatDateTimeForInputField( date, time ) )
    return dateTime.toLocaleDateString( 'en-IN', { day: "2-digit", month: "short", year: "numeric" } ).replace( /\//g, '-' ) + ", " + dateTime.toLocaleTimeString( 'en-IN', { minute: "2-digit", hour: "2-digit" } )
}

const normalDateFormat = ( date ) => {
    let d = date.split( "T" )[0]
    d = new Date( d ).setDate( new Date( d ).getDate() + 1 )
    return ( new Date( d ).toLocaleDateString( "en-GB", { year: "numeric", day: "2-digit", month: "2-digit" } ) ).replace( /\//g, '-' )
}

const downloadParticipationReport = async ( participation, questions, submissions ) => {
    let rows = [[{ fontWeight: 'bold', value: "Q.No" }, { value: "Question", fontWeight: 'bold' }, { fontWeight: 'bold', value: "Question Point" }, { value: "Answer", fontWeight: 'bold' }, { value: "Score", fontWeight: 'bold' }]]
    for ( let i = 0; i < questions.length; i++ ) {
        let row = [{ value: i + 1, type: Number }]
        const question = questions[i].question
        const options = questions[i].options
        const answerMap = {}
        if ( options ) {
            options.forEach( option => {
                answerMap[option.answer_id] = `"${convert( option.answer_text ).replace( /"/g, '""' )}"`
            } )
        }
        row.push( { value: `"${convert( question.question_text ).replace( /"/g, '""' )}"`, type: String } )
        row.push( { value: question.question_point, type: Number } )
        if ( submissions[question.question_id] ) {
            const submission = submissions[question.question_id]
            if ( submission.length === 1 ) {
                if ( submission[0].answer_id ) {
                    row.push( { value: String( answerMap[submission[0].answer_id] ), type: String } )
                } else {
                    row.push( { value: `"${convert( submission[0].answer_descriptive ).replace( /"/g, '""' )}"`, type: String } )
                }
            } else if ( submission.length > 1 ) {
                let answers = []
                submission.forEach( ans => {
                    answers.push( answerMap[ans.answer_id] )
                } )
                row.push( { value: `"{${answers.join( "," )}}"`, type: String } )
            }
            if ( submission[0].submission_score || submission[0].submission_score === 0 ) row.push( { value: submission[0].submission_score, type: Number } )
            else row.push( { value: "Not yet evaluated", type: String } )

        } else {
            row.push( { value: "Answer not given", type: String } )
            row.push( { value: 0, type: Number } )
        }
        rows.push( row )
    }
    // let csvContent = ''
    // rows.forEach( row => {
    //     csvContent += `${row.join( "," )}\n`
    // } )
    const excelData = await writeXlsxFile( rows, { buffer: true } )
    const blob = new Blob( [excelData] )
    const a = document.createElement( 'a' )
    a.download = `${participation.exam_title}${participation.student_name ? "-" + participation.student_name : ""}-Attempt-${participation.exam_participation_attempt}-test-report.xlsx`
    a.href = window.URL.createObjectURL( blob )
    a.click()
}


const exportQuizReport = async ( data, title, date ) => {
    let rows = [[
        { fontWeight: 'bold', value: "Q.No" },
        { value: "Student name", fontWeight: 'bold' },
        { fontWeight: 'bold', value: "AUID" },
        { value: "MAX marks", fontWeight: 'bold' },
        { value: "Score", fontWeight: 'bold' }
    ]]
    for ( let i = 0; i < data.length; i++ ) {
        const participation = data[i]
        let row = []
        row.push( { value: i + 1, width: "200px", type: Number } )
        row.push( { value: participation?.student_details?.name, width: "200px", type: String } )
        row.push( { value: participation.student_id, width: "200px", type: String } )
        row.push( { value: +participation.max_score, width: "200px", type: Number } )
        row.push( { value: +participation.total_score, width: "200px", type: Number } )
        rows.push( row )
    }
    const excelData = await writeXlsxFile( rows, { buffer: true } )
    const blob = new Blob( [excelData] )
    const a = document.createElement( 'a' )
    a.download = `${title}-${showDateInIndianDateFormat( date )}-report.xlsx`
    a.href = window.URL.createObjectURL( blob )
    a.click()
}

const exportExamParticipation = async ( participations, totalMarks ) => {
    const head = [{ fontWeight: 'bold', value: "Sl.No." }, { fontWeight: 'bold', value: "AUID" }, { fontWeight: 'bold', value: "Student Name" }, { fontWeight: 'bold', value: "Attempt" }, { fontWeight: 'bold', value: "Exam Finish Date and Time" }, { fontWeight: 'bold', value: "Total" }, { fontWeight: 'bold', value: "Score" }, { fontWeight: 'bold', value: "Remarks" }]
    let data = []
    data.push( head )
    // let csvContent = `${head.join( "," )}`
    participations.forEach( ( participation, index ) => {
        let row = []
        row.push( { value: index + 1, width: "200px", type: Number } )
        row.push( { value: ( participation.student_id ).replace( /"/g, '""' ), width: "200px", type: String } )
        row.push( { value: ( participation.student_name ).replace( /"/g, '""' ), width: "200px", type: String } )
        row.push( { value: participation.exam_participation_attempt, width: "200px", type: Number } )
        row.push( { value: ( new Date( participation.exam_participation_end_time ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" ) ).replace( /"/g, '""' ), width: "200px", type: String } )
        row.push( { value: totalMarks, width: "200px", type: Number } )
        row.push( { value: participation.exam_participation_score ? parseInt( participation.exam_participation_score ) : 0, width: "200px", type: Number } )
        row.push( { value: participation.exam_participation_remark ? participation.exam_participation_remark : "No remarks.", width: "200px", type: String } )
        // csvContent += `\n${index + 1},"${( participation.student_name ).replace( /"/g, '""' )}",${participation.exam_participation_attempt},"${( new Date( participation.exam_participation_end_time ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" ) ).replace( /"/g, '""' )}, ${( new Date( participation.exam_participation_end_time ).toLocaleTimeString( 'en-IN', { hour: "2-digit", minute: "2-digit" } ) ).replace( /"/g, '""' )}",${totalMarks},${participation.exam_participation_score ? parseInt( participation.exam_participation_score ) : 0},${participation.exam_participation_remark ? participation.exam_participation_remark : "No remarks."}`
        data.push( row )
    } )
    const excelFile = await writeXlsxFile( data, { buffer: true } )
    const blob = new Blob( [excelFile] )
    const a = document.createElement( 'a' )
    a.download = `${participations[0].exam_title}-participation-report.xlsx`
    a.href = window.URL.createObjectURL( blob )
    a.click()
}

const exportAdmissionExamParticipation = async ( participations ) => {
    const head = [{ fontWeight: 'bold', value: "Sl.No." }, { fontWeight: 'bold', value: "AUID" }, { fontWeight: 'bold', value: "Student Name" }, { fontWeight: 'bold', value: "Participation Status" }, { fontWeight: 'bold', value: "Attempt" }, { fontWeight: 'bold', value: "Exam Finish Date and Time" }, { fontWeight: 'bold', value: "Total" }, { fontWeight: 'bold', value: "Score" }, { fontWeight: 'bold', value: "Remarks" }]
    let data = []
    data.push( head )
    // let csvContent = `${head.join( "," )}`
    participations.forEach( ( participation, index ) => {
        let row = []
        row.push( { value: index + 1, width: "200px", type: Number } )
        row.push( { value: ( participation.student_id ).replace( /"/g, '""' ), width: "200px", type: String } )
        row.push( { value: ( participation.student_name ).replace( /"/g, '""' ), width: "200px", type: String } )
        row.push( { value: ( participation.exam_participation_status ), width: "200px", type: String } )
        row.push( { value: participation.exam_participation_attempt, width: "200px", type: Number } )
        row.push( { value: ( new Date( participation.exam_participation_end_time ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" ) ).replace( /"/g, '""' ), width: "200px", type: String } )
        row.push( { value: participation.total_marks, width: "200px", type: Number } )
        row.push( { value: participation.exam_participation_score ? parseInt( participation.exam_participation_score ) : 0, width: "200px", type: Number } )
        row.push( { value: participation.exam_participation_remark ? participation.exam_participation_remark : "No remarks.", width: "200px", type: String } )
        // csvContent += `\n${index + 1},"${( participation.student_name ).replace( /"/g, '""' )}",${participation.exam_participation_attempt},"${( new Date( participation.exam_participation_end_time ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" ) ).replace( /"/g, '""' )}, ${( new Date( participation.exam_participation_end_time ).toLocaleTimeString( 'en-IN', { hour: "2-digit", minute: "2-digit" } ) ).replace( /"/g, '""' )}",${totalMarks},${participation.exam_participation_score ? parseInt( participation.exam_participation_score ) : 0},${participation.exam_participation_remark ? participation.exam_participation_remark : "No remarks."}`
        data.push( row )
    } )
    const excelFile = await writeXlsxFile( data, { buffer: true } )
    const blob = new Blob( [excelFile] )
    const a = document.createElement( 'a' )
    a.download = `${participations[0].exam_title}-participation-report.xlsx`
    a.href = window.URL.createObjectURL( blob )
    a.click()
}

const uploadQuestionImage = async ( file ) => {
    console.log( file )
    const data = new FormData()
    data.append( "file", file )
    const res = await axios.post( `${BASE_URL}/exams/addquestionimage`, data )
    console.log( ( res.data ) )
    let url = ''
    return url
}

const instituteCourseAndBranches = {
    "AGS": [
        {
            "course": "BA",
            "branch": "PCE",
            "course_branch_name": "BA - PSYCHOLOGY/CRIMINOLOGY/ENGLISH"
        },
        {
            "course": "BA",
            "branch": "PJE",
            "course_branch_name": "BA - PSYCHOLOGY/JOURNALISM/ENGLISH"
        },
        {
            "course": "BBA",
            "branch": "BBA",
            "course_branch_name": "BACHELOR OF BUSINESS ADMINISTRATION"
        },
        {
            "course": "BBA-Analytics",
            "branch": "Analytics",
            "course_branch_name": "Business Analytics"
        },
        {
            "course": "BBA - AV",
            "branch": "BBA - AV",
            "course_branch_name": "BACHELOR OF BUSINESS ADMINISTRATION - AVIATION"
        },
        {
            "course_branch_name": "BACHELOR OF COMPUTER APPLICATION",
            "course": "BCA",
            "branch": "BCA"
        },
        {
            "course_branch_name": "BCA DATA SCIENCE",
            "course": "BCA -DS",
            "branch": "BCA -DS"
        },
        {
            "course": "BCA - CC",
            "branch": "BCA - CC",
            "course_branch_name": "BCA CLOUD COMPUTING"
        },
        {
            "course": "ACCA",
            "branch": "BCOM ACCA",
            "course_branch_name": "BCOM ACCA"
        },
        {
            "course": "BCOM",
            "branch": "BCOM",
            "course_branch_name": "BACHELOR OF COMMERCE"
        },
        {
            "course": "BSC",
            "branch": "PMC",
            "course_branch_name": "PHYSICS/MATHS/COMPUTERS"
        },
        {
            "course": "BSc - CSCDS",
            "branch": "BSc CSCDS",
            "course_branch_name": "BSc CSCDS"
        },
        {
            "course": "BSC FAD",
            "branch": "BSCFAD",
            "course_branch_name": "FASHION AND APPAREL DESIGN"
        },
        {
            "course": "BSW",
            "branch": "BSW",
            "course_branch_name": "BACHELOR OF SOCIAL WORK"
        }
    ],
    "AIT": [
        {
            "course": "BE",
            "branch": "AE",
            "course_branch_name": "AERONAUTICAL ENGG"
        },
        {
            "course": "BE",
            "branch": "BT",
            "course_branch_name": "BIOTECHNOLOGY"
        },
        {
            "course": "BE",
            "branch": "CV",
            "course_branch_name": "CIVIL ENGG"
        },
        {
            "course": "BE",
            "branch": "EC",
            "course_branch_name": "ELECTRONICS & COMMUNICATION ENGG"
        },
        {
            "course": "BE",
            "branch": "EE",
            "course_branch_name": "ELECTRICAL & ELECTRONICS ENGG"
        },
        {
            "course": "BE",
            "branch": "ME",
            "course_branch_name": "MECHANICAL ENGG"
        },
        {
            "course": "BE",
            "branch": "MT",
            "course_branch_name": "MECHATRONICS ENGG"
        },
        {
            "course": "BE",
            "branch": "CS",
            "course_branch_name": "COMPUTER SCIENCE ENGG"
        },
        {
            "course": "BE",
            "branch": "IS",
            "course_branch_name": "INFORMATION SCIENCE ENGG"
        },
        {
            "course": "BE",
            "branch": "AIML",
            "course_branch_name": "ARTIFICIAL INTELLIGENCE & MACHINE LEARNING"
        },
        {
            "course": "BE",
            "branch": "CSD",
            "course_branch_name": "Computer Science & Engineering (Data Science)"
        }
    ]
}


export { exportAdmissionExamParticipation, instituteCourseAndBranches, exportQuizReport, exportExamParticipation, downloadParticipationReport, formatDateTimeForDB, formatDateTimeForDisplay, formatDateTimeForInputField, Axios, getCurrentWeek, uploadQuestionImage, supportedFormats, attachmentsSupportedFormats, normalDateFormat, jsDateFormat, getCurrentDate, days, eventCells, hours, getCurrentWorkWeek, getPreviousWeek, getPreviousWorkWeek, getNextWorkWeek, getNextWeek, getPreviousDate, getNextDate }

// const getCurrentWeek = ( date = new Date() ) => {
//     const curr = new Date( date )
//     const dday = curr.getDay()
//     let week = []
//     if ( dday === 0 ) {
//         for ( let i = 5; i >= -1; i-- ) {
//             let tempCurr = new Date( date )
//             let first = tempCurr.getDate() - i
//             let day = new Date( tempCurr.setDate( first ) ).toISOString().slice( 0, 10 )
//             week.push( day )
//         }
//     }
//     else if ( dday !== 0 ) {
//         for ( let i = 1; i <= 6; i++ ) {
//             let first = curr.getDate() - curr.getDay() + i + 1
//             let day = new Date( curr.setDate( first ) ).toISOString().slice( 0, 10 )
//             week.push( day )
//         }
//         week.push( new Date( curr.setDate( curr.getDate() + 1 ) ).toISOString().slice( 0, 10 ) )
//     }

//     return week
// }
// <p><img src="https://content-ai.sgp1.digitaloceanspaces.com/temp/1666510241861.DarkLogo.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&amp;X-Amz-Credential=FDDWKZU3GR54F3DS6IXF%2F20221023%2Fsgp1%2Fs3%2Faws4_request&amp;X-Amz-Date=20221023T073043Z&amp;X-Amz-Expires=3600&amp;X-Amz-Signature=2fd5749a217271ccf5fd9a93a5da0b9a5a5b510dc4cdb2d830781f0676632e9a&amp;X-Amz-SignedHeaders=host&amp;x-id=GetObject"></p>
