import { Box, Collapse, Typography, useTheme } from '@mui/material'
import React from 'react'
import Latex from 'react-latex-next'
import { latexDelimiters } from '../../utils/utilities'

const questionNbrStyles = {
    display: 'flex',
    padding: '2px',
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: '#464E5B',
    fontSize: '12px',
    fontWeight: 'bold'
}

const answerdStyles = {
    position: 'relative',
    background: 'green !important',
    color: 'white',
}

const notAnsweredStyles = {
    position: 'relative',
    background: '#888',
    color: 'white',
}

export default function QuestionList( { questions, selectQuestion, currentQuestionIndex, submissions, showQuestions } ) {

    const { palette } = useTheme()

    const isAnswered = ( id ) => {
        for ( let i = 0; i < submissions.length; i++ ) {
            if ( submissions[i].question_id === id ) {
                return true
            }
        }
        return false
    }

    return (
        <Collapse in={showQuestions} orientation="horizontal" className="custom-scrollbar" sx={{ height: "100%", display: "flex", flexDirection: "column", minWidth: "50px", overflow: "auto !important" }}>
            <Box display={{ md: "block", xs: "none" }} bgcolor="#FFF1DE" position="sticky" top="0" sx={{ zIndex: 10 }} textTransform="uppercase" color={palette.grey[500]} padding="10px 20px">
                Questions
            </Box>
            <Box overflow="auto" display="flex" position="relative" flexDirection="column" bgcolor={palette.common.bg}>

                {questions.map( ( question, index ) => (
                    <Box padding="10px" key={question.question.question_id} display="flex" bgcolor={currentQuestionIndex === index ? palette.greyedOut : palette.common.bg} borderBottom="1px solid #aaa" alignItems="flex-start" sx={{ cursor: "pointer", display: "flex !important", justifyContent: "flex-start", "&:hover": { background: palette.rowHover }, '@media(max-width:350px)': { padding: "2px !important" } }} onClick={() => selectQuestion( question.question.question_id )}>
                        <Box sx={
                            isAnswered( question.question.question_id ) ?
                                { ...questionNbrStyles, ...answerdStyles, }
                                : { ...questionNbrStyles, ...notAnsweredStyles }}>{index + 1}</Box>
                        <Box marginLeft="10px" flexGrow={1} sx={{ display: { md: "block", xs: "none" } }}>
                            <Box variant='subtitle1' fontSize="12px" color={palette.common.font} sx={{ "& p": { margin: '0' } }}> <Latex delimiters={latexDelimiters} >{question.question.question_text.replace( "LATEX-", '' )}</Latex > </Box>
                            <Box display="flex" alignItems="center" gap="5px">
                                <Typography color="secondary" fontSize={"12px"}>{question.question.question_type}</Typography>
                                <Typography color="secondary" fontSize={"12px"}>{question.question.question_answer_selection}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ) )}
            </Box>
        </Collapse>
    )
}
