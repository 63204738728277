import { Box, useTheme } from '@mui/material'
import React from 'react'
import Navbar from './AdminNavbar'
import SideDrawer from './AdminSideDrawer'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import AnalyticsStoreInstance, { AnalyticsStoreProvider } from '../../../store/analytics.store'
import ApiProviderStoreInstance, { ApiProviderStoreProvider } from '../../../store/apiprovider.store'
import UploadStatusListInstance, { UploadStatusListProvider } from '../../../store/uploadstatuslistdialog.store'
import UploadsStatusList from '../../online-course-components/UploadsStatusList'
import DownloadStatusListDialog from '../../study-material-components/DownloadStatusListDialog'
import { useLayoutContext } from '../../../contexts/layout.context'
import AdmissionExamStoreInstance, { AdmissionExamStoreProvider } from '../../../store/admission-exam.store'


const Layout = ( { navHeadText } ) => {

    const { navHeight, setMenuState, minSidebarCollapseWidth } = useLayoutContext()

    const { palette } = useTheme()

    const handleResizeOrLoad = () => window.innerWidth < minSidebarCollapseWidth ? setMenuState( false ) : setMenuState( true )

    useEffect( () => {
        window.addEventListener( 'resize', handleResizeOrLoad )
        window.addEventListener( 'load', handleResizeOrLoad )
        return () => {
            window.removeEventListener( 'resize', handleResizeOrLoad )
            window.removeEventListener( 'resize', handleResizeOrLoad )
        }
    } )

    return (
        <AnalyticsStoreProvider store={AnalyticsStoreInstance}>
            <ApiProviderStoreProvider store={ApiProviderStoreInstance}>
                <UploadStatusListProvider store={UploadStatusListInstance}>
                    <AdmissionExamStoreProvider store={AdmissionExamStoreInstance}>
                        <Box bgcolor={palette.layoutBackground} display="flex" height="100%" overflow="auto">
                            <SideDrawer closeSideMenu={() => setMenuState( false )} />
                            <Box
                                flexGrow={1}
                                display="flex"
                                minWidth="0"
                                position="relative"
                            >
                                <Navbar navHeadText={navHeadText} closeSideMenu={() => setMenuState( false )} openSideMenu={() => setMenuState( true )} />
                                <Box
                                    flexGrow={1}
                                    display="flex"
                                    maxHeight="100%"
                                    id="page-content"
                                    paddingTop={`${navHeight}px`}
                                    minWidth="0">
                                    <Outlet />
                                </Box>
                            </Box>
                            <DownloadStatusListDialog />
                            <UploadsStatusList />
                        </Box>
                    </AdmissionExamStoreProvider>
                </UploadStatusListProvider>
            </ApiProviderStoreProvider>
        </AnalyticsStoreProvider>
    )
}

export default Layout
