import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import React from 'react'

const DateOrPresentInput = ( { attr, value, attrkey, handleFieldChange } ) => {
    return (
        <Box sx={{ flexGrow: "1", width: "100%" }}>
            <Box display="flex" alignItems="center" gap="5px">
                {value !== "present" && <Box>
                    <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                format='DD/MM/YYYY'
                                slotProps={{ textField: { size: 'small' } }}
                                value={dayjs( value ? value : "" )}
                                onAccept={( e ) => {
                                    handleFieldChange( "dateorpresent", attrkey, new Date( e.$d ).toLocaleDateString( 'en-US' ) )
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <Typography variant="subtitle2" sx={{ fontStyle: "italic", fontSize: "12px" }} color="GrayText">Date selected: {value ? new Date( value ).toLocaleDateString( 'en-IN' ) : ""}</Typography>
                </Box>
                }
                <FormControl margin='dense' fullWidth sx={{ flexGrow: "1" }}>
                    <FormControlLabel sx={{ marginLeft: "10px" }} control={<Checkbox size='small' color='primary' onChange={( e ) => handleFieldChange( "dateorpresent", attrkey, e.target.checked ? "present" : "" )} checked={value === "present" ? true : false} />} label={<Typography color="GrayText" fontSize="14px">Present</Typography>} />
                </FormControl>
            </Box>
        </Box>
    )
}

export default DateOrPresentInput
