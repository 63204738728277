import { Box, Paper, Select, Typography, useTheme, MenuItem, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, TablePagination, Menu, CircularProgress, IconButton } from "@mui/material"
import AuditStudentChecklistDetailsDialog from "../../../components/academic-program/AuditStudentChecklistDetailsDialog"
import BranchAcademicProgressStatsDialog from "../../../components/academic-program/BranchAcademicProgressStatsDialog"
import { filterBranches, sortByProperty, structureData } from "../../../utils/academic-program-utilities"
import CustomTableColumnLabel from "../../../components/academic-program/CustomTableColumnLabel"
import AuditFilterDialog from "../../../components/academic-program/AuditFilterDialog"
import AuditStudentRow from "../../../components/academic-program/AuditStudentRow"
import { Icons, exportAcademicProgressReport } from "../../../utils/utilities"
import DataLoadingSpinner from "../../../components/common/DataLoadingSpinner"
import CustomSearchBar from "../../../components/common/CustomSearchBar"
import { useOrganizationStore } from '../../../store/organization.store'
import BlockMessage from "../../../components/common/BlockMessage"
import noStudentsImg from '../../../assets/no-proctees.svg'
import { useUserStore } from "../../../store/user.store"
import noRuleImg from '../../../assets/no-rules.svg'
import React, { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import api from "../../../service/api"
import { toast } from "react-toastify"
import { observer } from "mobx-react"

let allBranches = []

const AcademicProgressAudit = observer( () => {
    const [requiredChecklistItemsCount, setRequiredChecklistItemsCount] = useState( 0 )
    const [filteredAndSortedData, setFilteredAndSortedData] = useState( [] )
    const [detailsDialogState, setDetailsDialogState] = useState( false )
    const [filtersDialogState, setFiltersDialogState] = useState( false )
    const [statsDialogState, setStatsDialogState] = useState( false )
    const [exportMenuAnchor, setExportMenuAnchor] = useState( null )
    const [currentDataLength, setCurrentDataLength] = useState( 0 )
    const [filteredBranches, setFilteredBranches] = useState( [] )
    const [selectedStudent, setSelectedStudent] = useState( null )
    const [showRemoveFilterSection, setShowRemoveFilterSection] = useState( false )
    const [filtersDetails, setFiltersDetails] = useState( {} )
    const [hasPermission, setHasPermission] = useState( true )
    const [academicYears, setAcademicYears] = useState( [] )
    const [loadingData, setLoadingData] = useState( false )
    const [rowsPerPage, setRowsPerPage] = useState( 20 )
    const [filtersCount, setFiltersCount] = useState( 0 )
    const [exporting, setExporting] = useState( false )
    const [errorCode, setErrorCode] = useState( null )
    const [loading, setLoading] = useState( true )
    const [rules, setRules] = useState( {} )
    const [data, setData] = useState( [] )
    const [page, setPage] = useState( 0 )


    const [searchParams, setSearchParams] = useSearchParams( {
        selectedInstitute: "-",
        selectedBranch: "-",
        academicYear: "-",
        searchText: "",
        sortByAUID: "0",
        sortByName: "0",
        sortByProgress: "0"
    } )

    const selectedInstitute = searchParams.get( "selectedInstitute" )
    const selectedBranch = searchParams.get( "selectedBranch" )
    const sortByProgress = searchParams.get( "sortByProgress" )
    const academicYear = searchParams.get( "academicYear" )
    const searchText = searchParams.get( "searchText" )
    const sortByAUID = searchParams.get( "sortByAUID" )
    const sortByName = searchParams.get( "sortByName" )

    const { palette } = useTheme()
    const UserStore = useUserStore()
    const OrganizationStore = useOrganizationStore()
    const exportMenuOpen = Boolean( exportMenuAnchor )


    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }


    const columns = [
        { id: 1, name: <CustomTableColumnLabel data={filteredAndSortedData} setData={setFilteredAndSortedData} label="AUID" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"auid"} sortName="sortByAUID" sortFlag={sortByAUID} sortValueType="string" />, styles: { minWidth: "150px", padding: "10px 20px" } },
        { id: 2, name: <CustomTableColumnLabel data={filteredAndSortedData} setData={setFilteredAndSortedData} label="Student Name" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"student_name"} sortName="sortByName" sortFlag={sortByName} sortValueType="string" />, styles: { minWidth: "200px", maxWidth: "200px", padding: "10px 20px" } },
        { id: 3, name: "Required", styles: { minWidth: "50px", padding: "10px 20px" } },
        { id: 4, name: <CustomTableColumnLabel data={filteredAndSortedData} setData={setFilteredAndSortedData} label="Pending" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"pendingCount"} sortName="sortByProgress" sortFlag={sortByProgress} sortValueType="number" />, styles: { minWidth: "50px", padding: "10px 20px" } },
        { id: 5, name: "Pending data", styles: { minWidth: "100px", padding: "10px 20px" } },
        { id: 6, name: <CustomTableColumnLabel data={filteredAndSortedData} setData={setFilteredAndSortedData} label="Progress" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"totalProgress"} sortName="sortByProgress" sortFlag={sortByProgress} sortValueType="number" />, styles: { align: "right", minWidth: "100px", padding: "10px 20px" } },
    ]

    const getData = useCallback( async ( branchId = selectedBranch, acYear = academicYear, applyingFilters = filtersDetails ) => {
        try {
            setLoadingData( true )
            setErrorCode( null )

            const { data: { data: { students, progressDetails, requiredChecklistItemsCount, rules, optionalChecklistItemsCount, appliedFilter } } } = await api.getBranchStudentsProgressReport( { institute: selectedInstitute, branch_id: branchId, ac_year: acYear }, applyingFilters )

            setRules( rules )
            setRequiredChecklistItemsCount( requiredChecklistItemsCount )

            if ( requiredChecklistItemsCount === 0 && optionalChecklistItemsCount === 0 )
                toast.warn( "No checklist for this class!" )

            const formatedData = structureData( students, appliedFilter, requiredChecklistItemsCount, optionalChecklistItemsCount, progressDetails )

            setFilteredAndSortedData( formatedData )

            if ( !appliedFilter?.filter || appliedFilter?.filter === "" )
                setData( formatedData )
            return true

        } catch ( err ) {
            setData( [] )
            setFilteredAndSortedData( [] )
            setRequiredChecklistItemsCount( 0 )
            setErrorCode( err?.response?.data?.errorCode )
            console.log( err?.response?.data?.errorCode )
            if ( !( err?.response?.data?.errorCode ) )
                toast(
                    err?.response?.data?.message ||
                    "Something went wrong! try again."
                )
            return false
        } finally {
            setLoadingData( false )
        }
    }, [academicYear, filtersDetails, selectedBranch, selectedInstitute] )

    const handleInstituteChange = ( e ) => {
        setSearchParamValue( 'selectedInstitute', e.target.value )
        setSearchParamValue( 'selectedBranch', '-' )
        setSearchParamValue( 'academicYear', '-' )
        filterBranches( e.target.value, allBranches, setFilteredBranches )
    }

    const handleBranchChange = async ( e ) => {
        try {
            setErrorCode( null )
            setSearchParamValue( 'selectedBranch', e.target.value )
            if ( academicYear && academicYear.match( /^(\d\d\d\d-\d\d\d\d)$/ ) ) {
                await getData( e.target.value, academicYear, {} )
            } else {
                setSearchParamValue( 'academicYear', '-' )

            }
        } catch ( err ) {
            if ( !( err?.response?.data?.errorCode ) )
                toast(
                    err?.response?.data?.message ||
                    "Something went wrong! try again."
                )
        }
    }

    const handleAcademicYearChange = async ( e ) => {
        setSearchParamValue( 'academicYear', e.target.value )
        setErrorCode( null )
        try {
            if ( selectedBranch !== "-" ) {
                await getData( selectedBranch, e.target.value, {} )
            }
        } catch ( err ) {
            if ( !( err?.response?.data?.errorCode ) )
                toast(
                    err?.response?.data?.message ||
                    "Something went wrong! try again."
                )
        }
    }

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    const handleSearchTextChange = ( e ) => {
        setSearchParamValue( 'searchText', e.target.value )
    }

    const exportWithFilters = async () => {
        try {
            setExporting( true )
            await exportAcademicProgressReport( data, { branch: filteredBranches.find( i => i.course_branch_assignment_id === selectedBranch ), academicYear } )
        } catch ( err ) {
            toast( "Error occured while exporting report" )
        } finally {
            setExporting( false )
        }
    }

    const exportWithOutFilters = async () => {
        try {
            setExporting( true )
            await exportAcademicProgressReport( data, { branch: filteredBranches.find( i => i.course_branch_assignment_id === selectedBranch ), academicYear } )
        } catch ( err ) {
            toast( "Error occured while exporting report" )
        } finally {
            setExporting( false )
        }
    }

    const handleRemoveFilters = async () => {
        if ( filtersCount > 0 ) {
            setFiltersDetails( {} )
            await getData( selectedBranch, academicYear, {} )
        }
        setSearchParamValue( 'searchText', "" )
    }

    const handleExportDataButtonClick = async ( e ) => {
        if ( filtersCount === 0 )
            await exportWithOutFilters()
        else
            setExportMenuAnchor( e.currentTarget )
    }

    const cachedSetSearchParamValue = useCallback( setSearchParamValue, [setSearchParams] )

    useEffect( () => {
        const len = filteredAndSortedData.filter( i => ( i.student_name.toLowerCase().includes( searchText ) || i.auid.toLowerCase().includes( searchText ) ) ).length
        setCurrentDataLength( len )
        setShowRemoveFilterSection( len === 0 && data.length > 0 )
    }, [searchText, filteredAndSortedData, data] )

    useEffect( () => {
        const len = ( filtersDetails?.filter?.length > 0 ? 1 : 0 ) + ( filtersDetails?.frontFilter?.length > 0 ? 1 : 0 )
        setFiltersCount( len )
        cachedSetSearchParamValue( 'filter', filtersDetails?.filter )
        cachedSetSearchParamValue( 'filterData', filtersDetails?.filterData )
    }, [filtersDetails, cachedSetSearchParamValue] )


    useEffect( () => {
        const getInitialData = async () => {
            try {
                await OrganizationStore.fetchOrganization()
                const { data: { data: branches } } = await api.fetchAcademicChecklistDepartments()
                const { data: { data: academicYearsList } } = await api.fetchAllAcademicYears()
                setAcademicYears( academicYearsList )
                allBranches = branches
                let filtered = []
                if ( selectedInstitute !== "-" )
                    filtered = filterBranches( selectedInstitute, branches, setFilteredBranches )
                if ( selectedInstitute && selectedInstitute !== "-" && selectedBranch && filtered.find( i => i.course_branch_assignment_id === selectedBranch ) && academicYear && academicYear.match( /^(\d\d\d\d-\d\d\d\d)$/ ) ) {
                    setErrorCode( null )
                    await getData( selectedBranch, academicYear, {} )
                }
            } catch ( err ) {
                console.log( err )
                if ( !( err?.response?.data?.errorCode ) )
                    toast(
                        err?.response?.data?.message ||
                        "Something went wrong! try again."
                    )
            } finally {
                setLoading( false )
            }
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.academics ) {
            setHasPermission( false )
        } else
            getInitialData()
    }, [UserStore, academicYear, getData, selectedBranch, OrganizationStore, selectedInstitute] )

    return (
        hasPermission ? <Box flexGrow={1} display="flex" flexDirection="column" overflow="auto" padding="20px">
            {/* Export Menu */}
            <Menu onClose={() => setExportMenuAnchor( null )} open={exportMenuOpen} anchorEl={exportMenuAnchor}>
                <MenuItem onClick={exportWithFilters} >Export with filters</MenuItem>
                <MenuItem onClick={exportWithOutFilters} >Export without filters</MenuItem>
            </Menu>

            {/* Dialogs */}
            {selectedStudent && <AuditStudentChecklistDetailsDialog rules={rules} state={detailsDialogState} setState={setDetailsDialogState} student={selectedStudent} setStudent={setSelectedStudent} />}
            <AuditFilterDialog filtersDetails={filtersDetails} setFiltersDetails={setFiltersDetails} state={filtersDialogState} getData={getData} setState={setFiltersDialogState} />
            {data.length > 0 && <BranchAcademicProgressStatsDialog setState={setStatsDialogState} data={{ data, totalRequired: requiredChecklistItemsCount }} branchDetails={{ branch: filteredBranches.find( i => i.course_branch_assignment_id === selectedBranch ) || {}, academicYear }} state={statsDialogState} />}

            {/* Breadcrunm */}
            <Typography gutterBottom fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary">Academics {Icons.small.ChevronRightIcon} Audit {Icons.small.ChevronRightIcon} {UserStore.getUser?.user_org_code}</Typography>

            {/* Content */}
            <Paper sx={{ minHeight: !loading && !loadingData ? currentDataLength > 0 ? "800px" : "600px" : "initial", flexGrow: ( loading || data.length === 0 || filteredAndSortedData.length === 0 || errorCode ) ? "initial" : "1", display: "flex", flexDirection: "column" }}>
                <Box display="flex" flexWrap="wrap" gap="20px" alignItems="center" padding="20px" borderBottom="1px solid #d3d3d3" >

                    <Box flexGrow={1}>
                        <Typography variant="h5">
                            Audit students academic progress
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            Audit department's students academic progress here.
                        </Typography>
                    </Box>

                    {!loading && (
                        <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">

                            <Select
                                disabled={loading}
                                variant="outlined"
                                sx={{ fontSize: "14px", flexGrow: 1, outline: "none", border: "none" }}
                                size="small"
                                onChange={handleInstituteChange}
                                value={selectedInstitute}
                            >
                                <MenuItem dense value={"-"} disabled sx={{ fontSize: "12px" }} >Select institute</MenuItem>

                                {OrganizationStore.getOrganization.map( ( institute, index ) => (
                                    <MenuItem dense value={institute.institute_name_short} sx={{ fontSize: "12px", textTransform: "capitalize", display: "flex", gap: "5px", alignItems: "center", }} key={institute.institute_name_short} >{institute.institute_name_short}</MenuItem>
                                ) )}
                            </Select>

                            {filteredBranches && (
                                <Select
                                    disabled={selectedInstitute === "-"}
                                    variant="outlined"
                                    sx={{ fontSize: "14px", flexGrow: 1, outline: "none", border: "none", textTransform: "capitalize" }}
                                    size="small"
                                    onChange={e => handleBranchChange( e )}
                                    value={selectedBranch}
                                    renderValue={( val ) => {
                                        const branch = val !== "-" ? filteredBranches.find( i => i.course_branch_assignment_id === val ) || {} : {}
                                        return val !== "-" && branch ? <Typography noWrap textTransform="capitalize" fontSize="14px" minWidth="130px" width="130px">
                                            {branch?.course_name?.toLowerCase()}-{branch?.course_branch_short_name}
                                        </Typography> : <Typography noWrap textTransform="capitalize" fontSize="14px" minWidth="130px" width="130px">
                                            Select Branch
                                        </Typography>
                                    }}
                                >
                                    <MenuItem dense value={"-"} disabled sx={{ fontSize: "12px" }} >Select branch</MenuItem>

                                    {filteredBranches.map( ( branch, index ) => (
                                        <MenuItem dense value={`${branch.course_branch_assignment_id}`} sx={{ fontSize: "12px", textTransform: "capitalize", display: "flex", gap: "5px", alignItems: "center", }} key={branch.course_branch_assignment_id} >{branch.course_name.toLowerCase()}{" "}{" - "}{" "}<b>{" "}{branch.course_branch_short_name}</b>{" "}</MenuItem>
                                    ) )}
                                </Select>
                            )}

                            <Select variant="outlined" sx={{ fontSize: "14px", flexGrow: 1, outline: "none", border: "none" }} disabled={selectedBranch === "-"} size="small" onChange={handleAcademicYearChange} value={academicYear}>
                                <MenuItem dense value={"-"} disabled sx={{ fontSize: "12px" }} >Academic year</MenuItem>

                                {academicYears.map( ( year ) => (
                                    <MenuItem dense value={year.ac_year} sx={{ fontSize: "12px", textTransform: "capitalize", display: "flex", gap: "5px", alignItems: "center", }} key={year.ac_year_id} >
                                        {year.ac_year}
                                    </MenuItem>
                                ) )}
                            </Select>
                        </Box>
                    )}
                </Box>
                {!loading && !loadingData && data.length > 0 && (
                    <Box display="flex" flexDirection="column" flexGrow={1} height={data.length > 0 && "50px"}>

                        {filteredAndSortedData && filteredAndSortedData.length > 0 && <Box padding="10px 20px" flexWrap="wrap" display="flex" flexDirection={{ md: "row", xs: "column" }} gap="10px" alignItems={{ md: "center", xs: "stretch" }} borderBottom="1px solid #d3d3d3">
                            <CustomSearchBar style={{ flexGrow: 1, margin: "0", padding: "0" }} placeholder="Search student by name or AUID" value={searchText} onChange={handleSearchTextChange} border="1px solid #d3d3d3" />
                            <Box display={{ sm: "flex", xs: "none" }} gap="10px" alignItems="center" height={{ md: "100%", xs: "fit-content" }}>
                                <Button onClick={() => setFiltersDialogState( true )} variant="outlined" sx={{ flexGrow: "1 !important", height: "100%", textTransform: "capitalize", width: "max-content" }} startIcon={Icons.default.FilterAlt}>Filters ({filtersCount})</Button>
                                <Button disabled={exporting} onClick={handleExportDataButtonClick} variant="outlined" sx={{ flexGrow: "1 !important", height: "100%", textTransform: "capitalize", width: "max-content" }} startIcon={exporting ? <CircularProgress size={14} /> : Icons.default.DownloadIcon}>Export</Button>
                                <Button onClick={() => setStatsDialogState( true )} variant="outlined" sx={{ flexGrow: "1 !important", height: "100%", textTransform: "capitalize", width: "max-content" }} startIcon={Icons.default.AnalyticsIcon} >Stats</Button>
                            </Box>
                            <Box display={{ sm: "none", xs: "flex" }} gap="10px" justifyContent="space-evenly" alignItems="center" height={{ md: "100%", xs: "fit-content" }}>
                                <IconButton color="primary" size="small" onClick={() => setFiltersDialogState( true )}  >{Icons.default.FilterAlt} ({filtersCount})</IconButton>
                                <IconButton color="primary" size="small" disabled={exporting} onClick={handleExportDataButtonClick}  >{exporting ? <CircularProgress size={14} /> : Icons.default.DownloadIcon}</IconButton>
                                <IconButton color="primary" size="small" onClick={() => setStatsDialogState( true )}  >{Icons.default.AnalyticsIcon} </IconButton>
                            </Box>
                        </Box>}

                        {data && data.length > 0 && !loading && <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
                            {currentDataLength > 0 && <TableContainer className="custom-scrollbar" sx={{ overflow: "auto", height: "100%", position: "relative" }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map( col => (
                                                <TableCell align={col.styles.align} sx={{ ...col.styles, background: palette.primary.main, color: palette.primary.contrastText }} key={col.id}>{col.name}</TableCell>
                                            ) )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredAndSortedData
                                            .filter( i => ( i.student_name.toLowerCase().includes( searchText ) || i.auid.toLowerCase().includes( searchText ) ) )
                                            .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                            .map( ( item, index ) => {
                                                return (
                                                    <AuditStudentRow key={index} setDetailsDialogState={setDetailsDialogState} setSelectedStudent={setSelectedStudent} requiredChecklistItemsCount={requiredChecklistItemsCount} item={item} />
                                                )
                                            }
                                            )}
                                    </TableBody>
                                </Table>
                                {currentDataLength > 10 && <TablePagination sx={{ background: "#eee", position: "sticky", bottom: 0, left: 0, right: 0 }} rowsPerPageOptions={[10, 20, 30]} component="div" count={currentDataLength} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
                                />}
                            </TableContainer>}

                            {showRemoveFilterSection === 0 && <Box justifyContent="center" alignItems="center" flexGrow={1} bgcolor="#eee" display="flex" flexDirection="column" gap="10px">
                                <img src={noStudentsImg} width="140px" alt="no-students-filter" />
                                <Typography variant="subtitle2" color="textSecondary">No students present for the applied filters!</Typography>
                                <Button color="error" onClick={handleRemoveFilters} variant="outlined" sx={{ textTransform: "capitalize" }} startIcon={Icons.default.FilterAltOff}>Remove all filters</Button>
                            </Box>}
                        </Box>}
                    </Box>
                )}
                {!loading && !loadingData && ( data?.length === 0 || errorCode ) && <Box flexGrow={1} display="flex" flexDirection="column" gap="10px">
                    {data?.length === 0 && !errorCode &&
                        <Box flexGrow={1} justifyContent="center" display="flex" flexDirection="column" gap="10px" bgcolor="#eee" padding="20px" alignItems="center">
                            <img src={noStudentsImg} width="170px" alt={"no-students"} />
                            <Typography textAlign="center" variant="subtitle2" color="textSecondary">No students present for the selected academic year and branch</Typography>
                        </Box>
                    }
                    {errorCode === "NORULE" &&
                        <Box flexGrow={1} justifyContent="center" display="flex" flexDirection="column" gap="10px" bgcolor="#eee" padding="20px" alignItems="center">
                            <img src={noRuleImg} width="170px" alt={"no-rules"} />
                            <Typography textAlign="center" variant="subtitle2" color="GrayText">No rule present for the selected academic year and branch</Typography>
                        </Box>
                    }
                    {errorCode === "NOSTUDENTS" &&
                        <Box flexGrow={1} justifyContent="center" display="flex" flexDirection="column" gap="10px" bgcolor="#eee" padding="20px" alignItems="center">
                            <img src={noStudentsImg} width="170px" alt={"no-students"} />
                            <Typography textAlign="center" variant="subtitle2" color="textSecondary">No students present for the selected academic year and branch</Typography>
                        </Box>
                    }
                </Box>}
                {( loadingData || loading ) && (
                    <DataLoadingSpinner waitingMessage="Loading data..." />
                )}
            </Paper>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
} )

export default AcademicProgressAudit    