import React from 'react'
import api from '../../service/api'
import { Box, Dialog, AppBar, Toolbar, Typography, IconButton, Paper } from '@mui/material'
import PropTypes from 'prop-types'
import { Icons } from "../../utils/utilities"
import SelectInstitute from "../../components/admin/SelectInstitute"
import noRecordingImg from '../../assets/norecording.svg'
import recordingThumb from '../../assets/recording-thumbnail.svg'
import { useUserStore } from '../../store/user.store'
import selectInstitute from '../../assets/selectinstitute.svg'
import { useEffect } from 'react'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import BlockMessage from '../../components/common/BlockMessage'

const videoCardStyles = {
    maxWidth: "450px",
    width: "fit-content",
    alignItems: "center",
    background: "white",
    borderRadius: "5px",
    padding: "0",
    display: "flex",
    border: "1px solid #d3d3d3",
    cursor: "pointer",
    "@media(max-width:350px)": {
        flexDirection: "column",
        maxWidth: "100%",
        width: "100%",
        alignItems: "flex-start"
    },
    "&:hover": {
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 8px 20px 0 rgba(0, 0, 0, 0.19)"
    }
}

const SimpleDialog = ( props ) => {
    const { onClose, selectedValue, open } = props

    const handleClose = () => {
        onClose( selectedValue )
    }

    return (
        <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <AppBar sx={{ position: "relative", background: "#f3f6fd" }}>
                <Toolbar>

                    <Typography variant="h6" sx={{
                        fontSize: 14,
                        color: 'black'
                    }}>
                        Recorded Session
                    </Typography>
                    <IconButton color="primary.main" onClick={handleClose} aria-label="close">
                        {Icons.default.CloseIcon}
                    </IconButton>


                </Toolbar>
            </AppBar>
            {/* <DialogTitle id="simple-dialog-title">Video Player</DialogTitle> */}
            <iframe title="iframe-window" type="text/html" id="embedd-bbb" scrolling="no" allowFullScreen
                src={selectedValue}
            />
        </Dialog>
    )
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
}

const Recording = ( props ) => {
    // const [instituteCode, setInstituteCode] = React.useState( '' )
    const [recording, setRecording] = React.useState( [] )
    const [open, setOpen] = React.useState( false )
    const [selectedValue, setSelectedValue] = React.useState( '' )
    const [instituteCode, setInstituteCode] = React.useState( "0" )
    const [isLoadingRecordings, setIsLoadingRecordings] = React.useState( false )
    const [hasPermission, setHasPermission] = React.useState( true )

    const UserStore = useUserStore()

    const handleChange = ( val ) => {
        setInstituteCode( val )
        loadRecording( val )
    }

    const loadRecording = async ( val ) => {
        setIsLoadingRecordings( true )
        setRecording( [] )
        try {
            const res = await api.getAllRecording( val )
            setRecording( res["data"]["data"] ? res["data"]["data"] : 0 )
            console.log( res["data"]["data"][0] )
        } catch ( err ) {
            console.log( err )
        } finally {
            setIsLoadingRecordings( false )
        }

    }

    const playVideo = ( data ) => {
        setOpen( true )
        setSelectedValue( `https://recording.alive.university/?meetingId=${data.session_id}&server=${data.server_id}` )
    }

    const handleClose = ( val ) => {
        setOpen( false )
        setSelectedValue( val )
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.recordings ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        hasPermission ? <Box flexGrow={1} overflow={isLoadingRecordings || ( !isLoadingRecordings && recording.length === 0 ) ? "auto" : "hidden"} padding="20px">
            <Paper sx={{ height: !isLoadingRecordings && recording.length > 0 ? "100%" : "fit-content", display: "flex", flexDirection: "column" }}>
                <Box borderBottom="1px solid #d3d3d3" display="flex" alignItems="center" justifyContent="space-between" padding="20px">
                    <Box >
                        <Typography variant='h5'>Recordings</Typography>
                        <Typography variant='subtitle2' color="textSecondary">Monitor all the recordings</Typography>
                    </Box>
                    <SelectInstitute padding="0" variant="outlined" onSelect={handleChange} />
                </Box>
                {!isLoadingRecordings && recording.length > 0 && <Box className="custom-scrollbar" flexGrow="1" overflow="auto">
                    <Box overflow="auto" padding="20px" display="flex" gap="10px" flexWrap="wrap">
                        {recording.map( ( item, i ) => {
                            return <Paper key={i} onClick={() => playVideo( item )} sx={videoCardStyles}>
                                {/* <Box class="card-body"> */}
                                <img width="160px" style={{ objectFit: "cover", borderRadius: "5px 0 0 5px" }} height="100%" src={recordingThumb} alt="thumbnail" />
                                <Box className="details" padding="10px" >
                                    <Typography width="160px" fontSize="12px" fontWeight="bold"> {item.course_name}-{item.room_session_data.branch_name}</Typography>
                                    <Typography noWrap width="120px" fontSize="12px"> {item.room_session_data.current_sem}{item.room_session_data.section_short_name} </Typography>
                                    <Typography noWrap width="120px" fontSize="12px" fontWeight="bold"> {item.faculty}  </Typography>
                                    <Typography noWrap width="120px" fontSize="12px"> {item.room_session_data.interval}  </Typography>
                                    <Typography noWrap width="120px" fontSize="12px"> {item.room_session_data.duration} min </Typography>
                                </Box>
                                {/* </Box> */}

                            </Paper>
                        } )}
                    </Box>
                </Box>}
                {
                    ( recording.length === 0 && instituteCode !== "0" && !isLoadingRecordings ) ?
                        <Box padding="20px" width="100%" display="flex" flexDirection="column" alignItems="center">
                            <img width="200px" src={noRecordingImg} alt="img-fluid" />
                            <Typography variant="subtitle2" color="textSecondary">No recorded videos available!</Typography>
                        </Box> : ''
                }

                {
                    isLoadingRecordings && <DataLoadingSpinner waitingMessage="Loading recordings" />
                }
                {
                    ( recording.length === 0 && instituteCode === "0" && !isLoadingRecordings ) ?
                        <Box padding="20px" bgcolor="#eee" gap="10px" display="flex" flexDirection="column" alignItems="center">
                            <img width="150px" src={selectInstitute} alt="img-fluid" />
                            <Typography variant="subtitle2" color="textSecondary">Select institute</Typography>
                        </Box> : ''
                }
            </Paper>
            <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
}

export default Recording
