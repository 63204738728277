import { Box, Button, Collapse, IconButton, Typography, useTheme } from '@mui/material'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import ConfirmDialog from '../../common/ConfirmDialog'
import { Icons } from '../../../utils/utilities'
import QuestionBlock from './QuestionBlock'
import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

const TopicBlock = ( { fromComponent = 'questionbank', onImportChange, newBankId = undefined, setQuestionsImportDialogState, questions, topic, setSelectedTopicId, setAddQuestionDialogState } ) => {

    const [open, setOpen] = useState( false )
    const [deleting, setDeleting] = useState( false )
    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )

    const QuizQuestionbankStore = useQuizAndQuestionbankStore()

    const importFileInputRef = useRef()

    const { palette } = useTheme()
    const { bank_id = newBankId } = useParams()

    const deleteTopic = async () => {
        setDeleting( true )
        await QuizQuestionbankStore.deleteTopic( bank_id, topic.id )
        setDeleteConfirmDialogState( false )
        setDeleting( false )
    }

    const openImportFileInputDialog = () => {
        setSelectedTopicId( topic.id )
        setQuestionsImportDialogState( true )
    }

    return (
        <Box sx={{
            "&:hover .display-on-hover": {
                display: { md: "flex", xs: "none" }
            }
        }} >

            <ConfirmDialog confirmAction={deleteTopic} actionName="Yes, Delete it!" status={deleteConfirmDialogState} cancelAction={() => setDeleteConfirmDialogState( false )} message={`All the associated questions will be deleted from this action!`} />

            <Box sx={{
                position: "relative",
                '&:after': {
                    content: `''`,
                    height: "1px",
                    width: "calc(100% - 40px)",
                    left: "50%",
                    bottom: 0,
                    background: palette.borderColor,
                    position: "absolute",
                    transform: "translateX(-50%)"
                },
            }} height="30px" padding="10px 20px" display="flex" justifyContent="space-between" alignItems="center" >
                <Box sx={{ cursor: "pointer" }} flexGrow={1} onClick={() => setOpen( !open )} display="flex" gap="20px" alignItems="center" >
                    <Typography fontWeight="500" variant="subtitle1">{topic.name}</Typography>
                    {open ? Icons.default.KeyboardArrowUp : Icons.default.KeyboardArrowDownIcon}
                </Box>
                {fromComponent === 'newQuestionBank' && <input accept='text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document' onChange={e => onImportChange( e, topic.id )} style={{ width: 0, height: 0, opacity: 0 }} type="file" ref={importFileInputRef} />}
                <Box display="flex" gap="10px" alignItems="center">
                    {fromComponent !== 'newQuestionBank' && <Button disabled={deleting} startIcon={Icons.default.AddIcon} onClick={() => { setSelectedTopicId( topic.id ); setAddQuestionDialogState( true ) }} color="secondary" size="small" sx={{ textTransform: "capitalize" }} > Add new questions </Button>}
                    {fromComponent !== 'newQuestionBank' && <Button disabled={deleting} startIcon={Icons.default.DownloadForOfflineIcon} onClick={openImportFileInputDialog} color="success" size="small" sx={{ textTransform: "capitalize" }} > Import questions </Button>}
                    {fromComponent === 'newQuestionBank' && <Button disabled={deleting} startIcon={Icons.default.DownloadForOfflineIcon} onClick={() => importFileInputRef.current.click()} color="success" size="small" sx={{ textTransform: "capitalize" }} > Import questions </Button>}
                    <Button disabled={deleting} startIcon={Icons.default.DeleteIconOutlined} onClick={() => { setDeleteConfirmDialogState( true ) }} color="error" size="small" sx={{ textTransform: "capitalize" }} > Delete </Button>
                </Box>
                <Box display={{ md: "none", xs: "flex" }} gap="10px" alignItems="center">
                    {fromComponent !== 'newQuestionBank' && <IconButton disabled={deleting} onClick={() => { setSelectedTopicId( topic.id ); setAddQuestionDialogState( true ) }} color="secondary" size="small" >{Icons.default.AddIcon}</IconButton>}
                    {fromComponent !== 'newQuestionBank' && <IconButton disabled={deleting} onClick={openImportFileInputDialog} color="secondary" size="small" >{Icons.default.DownloadForOfflineIcon}</IconButton>}
                    {fromComponent === 'newQuestionBank' && <IconButton disabled={deleting} onClick={() => importFileInputRef.current.click()} color="secondary" size="small" >{Icons.default.DownloadForOfflineIcon}</IconButton>}
                    <IconButton disabled={deleting} onClick={() => { setDeleteConfirmDialogState( true ) }} color="error" size="small" >{Icons.default.DeleteIconOutlined}</IconButton>
                </Box>
            </Box>
            <Collapse in={open}>
                {questions && questions.length > 0 && <Box display="flex" flexDirection="column" gap="5px" padding="10px 20px">
                    {questions.map( ( question, index ) => {
                        return <QuestionBlock question={question} index={index} key={index} />
                    } )}
                </Box>}
                {questions && questions.length === 0 && <Box display="flex" flexDirection="column" gap="5px" padding="10px 20px">
                    <Typography color="GrayText" variant="body2">This topic does not contains any  questions.</Typography>
                </Box>}
            </Collapse>
        </Box>
    )
}

export default TopicBlock
