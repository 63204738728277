import { Box, Button, Paper, Typography, useTheme } from '@mui/material'
import CreateThreadDialog from '../../components/discussion-forum/CreateThreadDialog'
import DiscussionCard from '../../components/discussion-forum/DiscussionCard'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { useDiscussionForum } from '../../store/discussionforum.store'
import CustomSearchBar from '../../components/common/CustomSearchBar'
import noDiscussionsImg from '../../assets/no-discussions.svg'
import { useUserStore } from '../../store/user.store'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../utils/utilities'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'

const MyDiscussions = () => {

    const [createDialogStatus, setCreateDialogStatus] = useState( false )
    const [searchText, setSearchText] = useState( "" )
    const [loading, setLoading] = useState( true )

    const DiscussionForumStore = useDiscussionForum()
    const UserStore = useUserStore()
    const { id } = useParams()

    const { palette } = useTheme()

    const navigate = useNavigate()

    // const navigateToDiscussionsWithQuery = ( query ) => {
    //     const usertype = UserStore.getUser.user_role === "STUDENT" ? 'student' : "faculty"
    //     navigate( `/${usertype}/discussions?${query || ""}` )
    // }

    const getData = async () => {
        setLoading( true )
        await DiscussionForumStore.fetchUserDiscussions()
        setLoading( false )
    }

    useEffect( () => {
        const getData = async () => {
            await DiscussionForumStore.fetchUserDiscussions()
            const searchParams = window.location.search
            if ( DiscussionForumStore?.getDiscussions.length > 0 && !id ) {
                navigate( `${DiscussionForumStore?.getDiscussions[0]?._id}${searchParams}` )
            }
            setLoading( false )
        }
        getData()
    }, [DiscussionForumStore, id, navigate] )

    return (
        <Box minWidth="0" flexGrow={1} padding="20px">
            <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary">
                <Link className="breadcrumb-link" to={UserStore.getUser.user_role === "STUDENT" ? "/student/discussions" : "/faculty/discussions"}>Discussions</Link>
                {Icons.small.ChevronRightIcon} My discussions
            </Typography>
            <Paper sx={{ flexGrow: ( !loading && DiscussionForumStore.getDiscussions.length > 0 ) ? 1 : "initial", minHeight: !loading && "350px", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <CreateThreadDialog getData={getData} state={createDialogStatus} setState={setCreateDialogStatus} />

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="20px"
                    position="relative"
                    sx={{
                        background: `url('/imgs/discussions-header-bg.jpg')`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box height="100%" position="absolute" sx={{ zIndex: 0, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 150.03%)`, }}></Box>
                    <Box sx={{ position: "relative", zIndex: 1 }} >
                        <Typography color="white" variant="h5">Discussions</Typography>
                        <Typography color="white" variant="body2" >Browse discussions from wide range of categories here...</Typography>
                    </Box>
                    <Button onClick={() => setCreateDialogStatus( true )} variant="contained" disableElevation startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize" }}>Create</Button>
                </Box>
                {!loading && <Box flexGrow={1} display="flex" bgcolor={palette.greyedOut} flexDirection="column">

                    {/* Discussions */}
                    <Box padding="10px 20px">
                        <CustomSearchBar border="1px solid #d3d3d3" value={searchText} onChange={( e ) => setSearchText( e.target.value )} style={{ width: "100%" }} placeholder='Search from you discussion by title' />
                    </Box>
                    {DiscussionForumStore.getDiscussions.length > 0 && <Box minHeight="300px" flexGrow={1} height="600px" alignItems="flex-start" display="grid" overflow="auto" gridTemplateColumns={{ md: "350px auto", xs: "1fr" }} gap="20px" padding="20px">
                        <Box display="flex" className="slim-custom-scrollbar" flexDirection="column" height="100%" overflow="auto" gap="10px" >
                            {DiscussionForumStore.getDiscussions
                                .filter( i => i.title?.toLowerCase()?.includes( searchText?.toLowerCase() ) )
                                .map( discussion => (
                                    <DiscussionCard discussion={discussion} key={discussion._id} />
                                ) )}
                        </Box>
                        <Box height="100%" display={{ md: "flex", xs: "none" }} flexDirection="column" overflow="auto">
                            <Outlet />
                        </Box>
                    </Box>}

                    {/* No discussions secction */}
                    {DiscussionForumStore.getDiscussions
                        .filter( i => i.title?.toLowerCase()?.includes( searchText?.toLowerCase() ) )
                        .length === 0 &&
                        <Box padding="20px" bgcolor={palette.contentBg} display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="10px">
                            <img width="150px" src={noDiscussionsImg} alt="No discussions" />
                            <Typography variant='subtitle2' color="textSecondary">You haven't created any discussions!</Typography>
                            {DiscussionForumStore.getDiscussions.length === 0 ?
                                <Button onClick={() => setCreateDialogStatus( true )} color="secondary" sx={{ width: "fit-content", textTransform: "capitalize", flexGrow: 1 }} variant="outlined" startIcon={Icons.default.AddIcon}>Create one</Button> :
                                <Button onClick={() => setSearchText( "" )} sx={{ width: "fit-content", textTransform: "capitalize", flexGrow: 1 }} variant="outlined" color="error" startIcon={Icons.default.FilterAltOff}>Remove search filter</Button>
                            }
                        </Box>}
                </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading your discussions..." />}
            </Paper>
        </Box>
    )
}

export default MyDiscussions
