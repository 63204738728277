import { Analytics, Upload, ViewList } from '@mui/icons-material'
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import TableHeader from '../../common/TableHeader'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { leadingZeroFormatter } from '../../../utils/utilities'
import CustomTableColumnLabel from '../../academic-program/CustomTableColumnLabel'
import api from '../../../service/api'
import { exportStudyMaterialsAuditReport } from '../../../utils/auditing-export-utilities'
import NoDatImg from '../../../assets/no-data.svg'
import LineBarGraph from '../../common/LineBarGraph'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import CustomSwitch from '../../common/CustomSwitch'


const sortByProperty = ( data, setData, property, type, order ) => {
    let newFilteredData = [...data]
    newFilteredData.sort( ( a, b ) => {
        if ( type === "number" ) {
            if ( order === "1" )
                return ( parseInt( a[property] ) - parseInt( b[property] ) )
            else
                return ( parseInt( b[property] ) - parseInt( a[property] ) )
        } else {
            if ( order === "1" ) {
                return ( ( a[property] < b[property] ) ? -1 : 0 )
            }
            else
                return ( ( a[property] > b[property] ) ? -1 : 0 )
        }
    } )
    setData( newFilteredData )
}


const StudyMaterialsStats = () => {

    const [searchParams, setSearchParams] = useSearchParams( { searchText: "", month: "", selectedDepartment: "all", selectedInstitute: "all", view: "tabular", sortByName: "1", sortByDownloadCounts: "1", sortByAttachmentCount: "1", sortByMaterialsCount: "1" } )
    const month = searchParams.get( 'month' ) || ""
    const view = searchParams.get( 'view' ) || "tabular"
    const searchText = searchParams.get( 'searchText' ) || ""
    const sortByName = searchParams.get( 'sortByName' ) || "1"
    const sortByAUID = searchParams.get( 'sortByAUID' ) || "1"
    const selectedInstitute = searchParams.get( 'selectedInstitute' ) || "all"
    const selectedDepartment = searchParams.get( 'selectedDepartment' ) || "all"
    const sortByDownloadCounts = searchParams.get( 'sortByDownloadCounts' ) || "1"
    const sortByMaterialsCount = searchParams.get( 'sortByMaterialsCount' ) || "1"
    const sortByAttachmentCount = searchParams.get( 'sortByAttachmentCount' ) || "1"

    const [loading, setLoading] = useState( true )
    const [loadingGraphData, setLoadingGraphData] = useState( true )
    const [showMonthPicker, setShowMonthPicker] = useState( false )
    const [graphData, setGraphData] = useState( [] )
    const [facultyStats, setFacultyStats] = useState( [] )
    const [sortedFacultyStats, setSortedFacultyStats] = useState( [] )
    const [showzeromaterialsfaculties, setShowzeromaterialsfaculties] = useState( false )


    const { palette, table } = useTheme()

    const navigate = useNavigate()

    const hasFilters = !( !month && selectedDepartment === "all" && selectedInstitute === "all" && !searchText )

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const removeAppliedFilters = () => {
        setSearchParams( prev => {
            prev.set( 'searchText', "" )
            prev.set( 'month', "" )
            prev.set( 'selectedDepartment', "all" )
            prev.set( 'selectedInstitute', "all" )
            return prev
        }, { replace: true } )
    }

    const handleFilterChange = ( e, newVal ) => {
        if ( newVal === '' || newVal === 'last30days' ) {
            setSearchParamValue( 'month', newVal )
        } else {
        }
    }

    const handleMonthChange = newDate => {
        const date = new Date( newDate )
        if ( date !== 'Invalid Date' ) {
            setSearchParamValue( 'month', `${date.getFullYear()}-${leadingZeroFormatter.format( date.getMonth() + 1 )}` )
            setShowMonthPicker( false )
        }
    }

    const searchFilterFunc = item => {
        const searchValue = new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' )
        if ( selectedDepartment !== "all" )
            return item.department_id === selectedDepartment && ( item?.department?.match( searchValue ) || item?.faculty_name?.match( searchValue ) || item.auid?.match( searchValue ) )
        else
            return item?.department?.match( searchValue ) || item?.faculty_name?.match( searchValue ) || item.auid?.match( searchValue )
    }

    const columns =
        [
            { id: 1, name: 'Sl.No.', minWidth: "50px", align: "center", padding: "10px 20px 10px 40px" },
            { id: 2, name: <CustomTableColumnLabel data={sortedFacultyStats} setData={setSortedFacultyStats} label="Faculty" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"faculty_name"} sortName="sortByName" sortFlag={sortByName} sortValueType="string" />, minWidth: "150px", padding: "10px 20px" },
            { id: 3, name: <CustomTableColumnLabel data={sortedFacultyStats} setData={setSortedFacultyStats} label="AUID" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"auid"} sortName="sortByAUID" sortFlag={sortByAUID} sortValueType="string" />, align: "center", padding: "10px 20px" },
            { id: 6, name: 'DEPARTMENT', align: "center", padding: "10px 20px" },
            { id: 4, align: "center", style: { align: "center" }, name: <CustomTableColumnLabel align="center" data={sortedFacultyStats} setData={setSortedFacultyStats} label="Materials" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"material_count"} sortName="sortByMaterialsCount" sortFlag={sortByMaterialsCount} sortValueType="number" />, minWidth: "150px", padding: "10px 20px" },
            { id: 5, align: "center", style: { align: "center" }, name: <CustomTableColumnLabel align="center" data={sortedFacultyStats} setData={setSortedFacultyStats} label="Attachments" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"attachments"} sortName="sortByAttachmentCount" sortFlag={sortByAttachmentCount} sortValueType="number" />, minWidth: "150px" },
            { id: 6, align: "center", style: { align: "center" }, name: <CustomTableColumnLabel align="center" data={sortedFacultyStats} setData={setSortedFacultyStats} label="Downloads" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"downloads"} sortName="sortByDownloadCounts" sortFlag={sortByDownloadCounts} sortValueType="number" />, minWidth: "150px", padding: "10px 40px 10px 20px" },
        ]


    const getStats = useCallback( async () => {
        try {
            setLoading( true )
            const { data: { data: facultyStats, noMaterialsFacultiesList = [] } } = await api.audit.fetchStudyMaterialStats( { month, showzeromaterialsfaculties, institute: selectedInstitute !== "all" ? selectedInstitute : "" } )
            let alldata = facultyStats
            if ( showzeromaterialsfaculties ) {
                alldata = [...noMaterialsFacultiesList, ...facultyStats]
            }
            setFacultyStats( alldata )
            setSortedFacultyStats( alldata )
        } catch ( err ) {
            console.log( "STATTS ERR", err )
            toast( err?.response?.data?.message || "Something went wrong while fetching the assignment audit data." )
        } finally {
            setLoading( false )
            setLoadingGraphData( false )
        }
    }, [month, selectedInstitute, showzeromaterialsfaculties] )

    useEffect( () => {
        getStats()
    }, [getStats] )

    useEffect( () => {
        const getGraphData = async () => {
            try {
                const institute = selectedInstitute !== "all" ? selectedInstitute : ""
                const { data: graphDetails } = await api.audit.getStudyMaterialGraph( { month, institute } )
                setGraphData( graphDetails.data )
            } catch ( err ) {
                toast( err?.response?.data?.message || "Something went wrong while fetching the assignment graph data." )
            } finally {
                setLoadingGraphData( false )
            }
        }
        getGraphData()
    }, [month, selectedInstitute] )

    return (
        <Card sx={{ flexShrink: 0, display: "flex", flexDirection: "column" }}>
            <Box padding="20px" display="flex" gap="20px" justifyContent="space-between" >
                <Box>
                    <Typography color="primaryDark.main" fontSize="16px" variant='h6'>Study material statistics</Typography>
                    {month === "" && view === 'tabular' && <Box display="flex" alignItems="center" gap="10px">
                        <Typography color="primaryDark.main" fontSize="12px" variant='subtitle2'>Show faculty with zero uploads</Typography>
                        <CustomSwitch checked={showzeromaterialsfaculties} onChange={e => setShowzeromaterialsfaculties( e.target.checked )} />
                    </Box>}
                </Box>
                <Box position="relative" display="flex" gap="20px" alignItems="center">
                    <ToggleButtonGroup size='small' value={view} exclusive onChange={( e, newVal ) => setSearchParamValue( 'view', newVal )} >
                        <Tooltip title="Tabular view">
                            <ToggleButton value='tabular' sx={{ padding: "6px", '&.Mui-selected': { background: palette.primary.light, color: "white", '&:hover': { background: palette.primary.light } } }} size="small">
                                <ViewList fontSize='small' />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Graphical view">
                            <ToggleButton sx={{ padding: "6px", '&.Mui-selected': { background: palette.primary.light, color: "white", '&:hover': { background: palette.primary.light } } }} value='graphical' size="small">
                                <Analytics fontSize='small' />
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup size='small' onChange={handleFilterChange} exclusive value={month || ""}>
                        {view === 'tabular' && <ToggleButton size='small' sx={{ textTransform: "capitalize", padding: "4.5px 10px" }} value="">All</ToggleButton>}
                        <ToggleButton size='small' sx={{ textTransform: "capitalize", padding: "4.5px 10px" }} value="last30days" selected={month === "last30days" || ( view !== 'tabular' && !Boolean( month.match( /^(\d{4}-\d{2})$/ ) ) )}>Last 30 days</ToggleButton>
                        <ToggleButton size='small' sx={{ textTransform: "capitalize", padding: "4.5px 10px" }} value="-" selected={Boolean( month.match( /^(\d{4}-\d{2})$/ ) )}>
                            {month && month !== 'last30days' ? month : "Select month"}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    open={showMonthPicker}
                                    onClose={() => setShowMonthPicker( false )}
                                    id="startdatetime"
                                    slotProps={{
                                        textField: {
                                            onClick: () => setShowMonthPicker( true )
                                        }
                                    }}
                                    slots={{
                                        openPickerButton: () => null,
                                    }}
                                    sx={{ opacity: 0, position: "absolute", inset: 0, fontSize: "14px", '& input': { padding: "5px 10px", cursor: "pointer" } }}
                                    // value={dayjs( endDateTime )}
                                    views={['year', 'month']}
                                    onMonthChange={handleMonthChange}
                                />
                            </LocalizationProvider>
                        </ToggleButton>
                    </ToggleButtonGroup>


                    <Button size="small" onClick={() => navigate( `list` )} variant="contained" disableElevation sx={{ height: "33.5px", textTransform: "capitalize", background: "#FFE0E0", border: `1px solid #FF6C8F`, color: "#FF736F", '&:hover': { background: "#FFE0E0", color: "#FF736F" } }} >View Materials</Button>
                    <Button size="small" onClick={() => exportStudyMaterialsAuditReport( sortedFacultyStats, `${month || "complete"}-${selectedInstitute !== "all" ? selectedInstitute + "-" : ""}${selectedDepartment !== "all" ? selectedDepartment : "alldept"}-materails-audit-report` )} variant="contained" disableElevation sx={{ height: "33.5px", background: `linear-gradient(200.17deg, #1CB5B5 -47.65%, #105958 167.67%)`, color: "#fff", '&:hover': { background: `linear-gradient(200.17deg, #1CB5B5 -47.65%, #105958 167.67%)`, color: "#fff" } }} endIcon={<Upload fontSize='small' />} >Export</Button>
                </Box>
            </Box >
            {!loading && view === 'tabular' && sortedFacultyStats.filter( searchFilterFunc ).length > 0 && <Box overflow="hidden" bgcolor={palette.contentBg} borderRadius="10px" margin="0 20px 20px 20px">
                <TableContainer sx={{ overflow: "auto", maxHeight: "500px" }} className='custom-scrollbar' >
                    <Table stickyHeader>
                        <TableHeader columns={columns} />
                        <TableBody>
                            {sortedFacultyStats.filter( searchFilterFunc ).map( ( row, index ) => {
                                return <TableRow sx={{ background: +row.material_count === 0 && palette.error.light + "44" }} className='table-row' key={index}>
                                    <TableCell align={columns[0].align || "left"} sx={{ fontSize: "12px", padding: columns[0].padding || "10px 20px", color: table.color }}><strong>{index + 1}</strong></TableCell>
                                    <TableCell sx={{ fontSize: "12px", padding: columns[1].padding || "10px 20px", color: table.color, textTransform: "capitalize", '&:hover': { textDecoration: "underline" } }}><strong><Link to={`/audit/faculty/${row.auid}`} className='default-link' >{row?.faculty_name?.toLowerCase() || row?.auid}</Link></strong></TableCell>
                                    <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", padding: columns[2].padding || "10px 20px", color: table.color, '&:hover': { textDecoration: "underline" } }}><strong><Link to={`/audit/faculty/${row.auid}`} className='default-link' >{row.auid}</Link></strong></TableCell>
                                    <TableCell align={columns[3].align || "left"} sx={{ fontSize: "12px", padding: columns[3].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}><strong>{row?.institute}-{row?.department?.toLowerCase()}</strong></TableCell>
                                    <TableCell align={columns[4].align || "left"} sx={{ fontSize: "12px", padding: columns[4].padding || "10px 20px", color: table.color }}><strong>{row.material_count}</strong></TableCell>
                                    <TableCell align={columns[5].align || "left"} sx={{ fontSize: "12px", padding: columns[5].padding || "10px 20px", color: table.color }}><strong>{row.attachments}</strong></TableCell>
                                    <TableCell align={columns[6].align || "left"} sx={{ fontSize: "12px", padding: columns[6].padding || "10px 20px", color: table.color }}><strong>{row.downloads}</strong></TableCell>
                                </TableRow>
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
            {!loading && sortedFacultyStats.filter( searchFilterFunc ).length === 0 && <Box flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" gap="20px">
                <img src={NoDatImg} height="120px" alt="No materials in last 30 days" />
                <Typography textAlign="center" variant='subtitle2'>No materials were found for applied filters!</Typography>
                {( facultyStats.length > 0 || hasFilters ) && <Button onClick={removeAppliedFilters} sx={{ textTransform: "capitalize" }} color="secondary" variant="contained" disableElevation>Remove filters</Button>}
            </Box>}
            {
                !loading && !loadingGraphData && view !== 'tabular' && sortedFacultyStats.filter( searchFilterFunc ).length > 0 &&
                <Box display="flex" flexGrow={1} minHeight="400px">
                    <LineBarGraph lineColor='#101820' barColor='#FEE715' showMenuBar={false} yAxisName="Attachments" y2AxisName="Downloads" barData={graphData} barValuesKey='downloads' barLegend="Downloads" xAxisName="Date" lineLegend='Attachements uploaded' dateformat={"%Y-%m-%d"} xAxisValuesKey={'date'} lineValuesKey={'attachments_uploaded'} lineData={graphData} />
                </Box>
            }
            {( loading || loadingGraphData ) && <DataLoadingSpinner waitingMessage="Loading materials audit report please..." />}
        </Card >
    )
}

export default StudyMaterialsStats
