import { Box, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'

const PendingDataList = ( { list, onClick } ) => {

    const [showAll, setShowAll] = useState( false )
    const { palette } = useTheme()

    return (
        <Box>
            {list.length > 0 && <Box onClick={onClick} display="flex" gap="5px" flexDirection="column">
                {list.slice( 0, showAll ? list.length : 2 ).map( item => (
                    <Tooltip key={item.id} placement="left" title={item.is_required ? "REQUIRED" : "OPTIONAL"}>
                        <Box width="fit-content" display="inline-block" fontSize="12px" fontWeight="bold" borderBottom={`1px ${item.is_required ? "solid" : "dotted"} ${item.is_required ? palette.error.light : palette.primary.light}`}> {item.input_label} </Box>
                    </Tooltip>
                ) )}
            </Box>}
            {list.length > 2 && <Typography marginTop="5px" width="fit-content" onClick={() => setShowAll( !showAll )} color="primaryDark.main" variant="subtitle2" fontSize="12px" sx={{ textDecoration: "underline", pointerEvents: "all" }}>{showAll ? "Show less" : "Show all"}</Typography>}
            {list.length === 0 && <Typography variant="subtitle2">No pending checklists to complete!</Typography>}
        </Box>
    )
}

export default PendingDataList
