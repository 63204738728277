import { toast } from "react-toastify"
import writeXlsxFile from "write-excel-file"

export const exportAssignmentsAuditReport = async ( data, name ) => {
    try {
        let rows = [[{ fontWeight: 'bold', value: "Sl. No." }, { value: "Faculty name", fontWeight: 'bold' }, { fontWeight: 'bold', value: "AUID" }, { value: "Department", fontWeight: 'bold' }, { value: "Assignments", fontWeight: 'bold' }, { value: "Submissions received", fontWeight: 'bold' }]]
        for ( let i = 0; i < data.length; i++ ) {
            const record = data[i]
            let row = []
            row.push( { value: i + 1, type: Number } )
            row.push( { value: record.faculty_name, type: String } )
            row.push( { value: record.assignment_created_by, type: String } )
            row.push( { value: record.department, type: String } )
            row.push( { value: +record.count, type: Number } )
            row.push( { value: +record.submissions_received, type: Number } )
            rows.push( row )
        }
        // let csvContent = ''
        // rows.forEach( row => {
        //     csvContent += `${row.join( "," )}\n`
        // } )
        const excelData = await writeXlsxFile( rows, { buffer: true } )
        const blob = new Blob( [excelData] )
        const a = document.createElement( 'a' )
        a.download = `${name}.xlsx`
        a.href = window.URL.createObjectURL( blob )
        a.click()
    } catch ( err ) {
        toast( "Ooops! Something went wrong while exporting the report!" )
    }
}

export const exportExamsAuditReport = async ( data, name ) => {
    try {
        let rows = [[{ fontWeight: 'bold', value: "Sl. No." }, { value: "Faculty name", fontWeight: 'bold' }, { fontWeight: 'bold', value: "AUID" }, { value: "Department", fontWeight: 'bold' }, { value: "Exams", fontWeight: 'bold' }, { value: "Attempts Count", fontWeight: 'bold' }]]
        for ( let i = 0; i < data.length; i++ ) {
            const record = data[i]
            let row = []
            row.push( { value: i + 1, type: Number } )
            row.push( { value: record.faculty_name, type: String } )
            row.push( { value: record.created_by, type: String } )
            row.push( { value: record?.created_by_data?.branch_name || "Not Recorded", type: String } )
            row.push( { value: +record.count, type: Number } )
            row.push( { value: +record.attempts, type: Number } )
            rows.push( row )
        }
        const excelData = await writeXlsxFile( rows, { buffer: true } )
        const blob = new Blob( [excelData] )
        const a = document.createElement( 'a' )
        a.download = `${name}.xlsx`
        a.href = window.URL.createObjectURL( blob )
        a.click()
    } catch ( err ) {
        toast( "Ooops! Something went wrong while exporting the report!" )
    }
}

export const exportQuizzesAuditReport = async ( data, name ) => {
    try {
        let rows = [[{ fontWeight: 'bold', value: "Sl. No." }, { value: "Faculty name", fontWeight: 'bold' }, { fontWeight: 'bold', value: "AUID" }, { value: "Department", fontWeight: 'bold' }, { value: "Exams", fontWeight: 'bold' }, { value: "Attempts Count", fontWeight: 'bold' }]]
        for ( let i = 0; i < data.length; i++ ) {
            const record = data[i]
            let row = []
            row.push( { value: i + 1, type: Number } )
            row.push( { value: record.faculty_name, type: String } )
            row.push( { value: record.created_by, type: String } )
            row.push( { value: `${record?.creator_data?.institute ? record?.creator_data?.institute + "-" : ""}${record?.creator_data?.department?.toLowerCase()}`, type: String } )
            row.push( { value: +record.count, type: Number } )
            row.push( { value: +record.attempts, type: Number } )
            rows.push( row )
        }
        const excelData = await writeXlsxFile( rows, { buffer: true } )
        const blob = new Blob( [excelData] )
        const a = document.createElement( 'a' )
        a.download = `${name}.xlsx`
        a.href = window.URL.createObjectURL( blob )
        a.click()
    } catch ( err ) {
        toast( "Ooops! Something went wrong while exporting the report!" )
    }
}

export const exportQuestionBanksAuditReport = async ( data, name ) => {
    try {
        let rows = [[{ fontWeight: 'bold', value: "Sl. No." }, { value: "Faculty name", fontWeight: 'bold' }, { fontWeight: 'bold', value: "AUID" }, { value: "Department", fontWeight: 'bold' }, { value: "Question banks", fontWeight: 'bold' }, { value: "Question Count", fontWeight: 'bold' }]]
        for ( let i = 0; i < data.length; i++ ) {
            const record = data[i]
            let row = []
            row.push( { value: i + 1, type: Number } )
            row.push( { value: record.faculty_name, type: String } )
            row.push( { value: record.created_by, type: String } )
            row.push( { value: `${record?.creator_data?.institute ? record?.creator_data?.institute + "-" : ""}${record?.creator_data?.department_name?.toLowerCase()}`, type: String } )
            row.push( { value: +record.count, type: Number } )
            row.push( { value: +record.questions, type: Number } )
            rows.push( row )
        }
        const excelData = await writeXlsxFile( rows, { buffer: true } )
        const blob = new Blob( [excelData] )
        const a = document.createElement( 'a' )
        a.download = `${name}.xlsx`
        a.href = window.URL.createObjectURL( blob )
        a.click()
    } catch ( err ) {
        toast( "Ooops! Something went wrong while exporting the report!" )
    }
}

export const exportStudyMaterialsAuditReport = async ( data, name ) => {
    try {
        let rows = [[{ fontWeight: 'bold', value: "Sl. No." }, { value: "Faculty name", fontWeight: 'bold' }, { fontWeight: 'bold', value: "AUID" }, { value: "Department", fontWeight: 'bold' }, { value: "Study materials", fontWeight: 'bold' }, { value: "Attachments", fontWeight: 'bold' }, { value: "Downloads", fontWeight: 'bold' }]]
        for ( let i = 0; i < data.length; i++ ) {
            const record = data[i]
            let row = []
            row.push( { value: i + 1, type: Number } )
            row.push( { value: record.faculty_name, type: String } )
            row.push( { value: record.auid, type: String } )
            row.push( { value: `${record?.institute}-${record?.department?.toLowerCase()}`, type: String } )
            row.push( { value: +record.material_count, type: Number } )
            row.push( { value: +record.attachments, type: Number } )
            row.push( { value: +record.downloads, type: Number } )
            rows.push( row )
        }
        const excelData = await writeXlsxFile( rows, { buffer: true } )
        const blob = new Blob( [excelData] )
        const a = document.createElement( 'a' )
        a.download = `${name}.xlsx`
        a.href = window.URL.createObjectURL( blob )
        a.click()
    } catch ( err ) {
        toast( "Ooops! Something went wrong while exporting the report!" )
    }
}

export const exportDiscussionsAuditReport = async ( data, name ) => {
    try {
        let rows = [[
            { fontWeight: 'bold', value: "Sl. No." },
            { value: "Posted by", fontWeight: 'bold' },
            { fontWeight: 'bold', value: "AUID" },
            { value: "Institute", fontWeight: 'bold' },
            { value: "Discussions", fontWeight: 'bold' },
            { value: "Views", fontWeight: 'bold' },
            { value: "Comments", fontWeight: 'bold' },
            { value: "Votes", fontWeight: 'bold' }
        ]]
        for ( let i = 0; i < data.length; i++ ) {
            const record = data[i]
            let row = []
            row.push( { value: i + 1, type: Number } )
            row.push( { value: record?._id?.name?.toLowerCase() || record?._id.created_by, type: String } )
            row.push( { value: record._id.created_by, type: String } )
            row.push( { value: record?._id?.institute, type: String } )
            row.push( { value: +record.count, type: Number } )
            row.push( { value: +record.views, type: Number } )
            row.push( { value: +record.comments, type: Number } )
            row.push( { value: +record.votes, type: Number } )
            rows.push( row )
        }
        const excelData = await writeXlsxFile( rows, { buffer: true } )
        const blob = new Blob( [excelData] )
        const a = document.createElement( 'a' )
        a.download = `${name}.xlsx`
        a.href = window.URL.createObjectURL( blob )
        a.click()
    } catch ( err ) {
        toast( "Ooops! Something went wrong while exporting the report!" )
    }
}