import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, FormControl, FormControlLabel, IconButton, Radio, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Icons, latexDelimiters } from '../../utils/utilities'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import CustomSwitch from '../common/CustomSwitch'
// import { useQuizAndQuestionbankStore } from '../../store/quiz.store'
import Latex from 'react-latex-next'
import { toast } from 'react-toastify'
import api from '../../service/api'
import { useParams } from 'react-router-dom'
import CustomWysiwyg from '../common/CustomWysiwyg'

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
            ['code-block'],
        ],
        // handlers: {
        //     image: async () => await handleFileInput( 'image' ),
        //     video: async () => await handleFileInput( 'video' ),
        // },
    },
}

const OptionComponent = ( { getQuestions, setFieldValue, removeOption, option, optionIndex, values, handleCorrectAnswerChange } ) => {

    const [saving, setSaving] = useState( false )
    const [editMode, setEditMode] = useState( false )
    const [optionPlainText, setOptionPlainText] = useState()
    const [editValue, setEditValue] = useState( option.answer_text )
    const [currentSavedOptionTextValue, setCurrentSavedOptionTextValue] = useState( option.answer_text )

    const { id } = useParams()

    const hadleOptionValueChange = ( val, text ) => {
        setOptionPlainText( text?.trim() )
        setEditValue( val )
    }

    const updateOptionText = async () => {
        setSaving( true )
        try {
            if ( optionPlainText ) {
                const { data } = await api.admissionExams.updateOptionText( id, option?.answer_id, { answer_text: editValue } )
                toast( data?.message || "Option saved successfully!" )
                setCurrentSavedOptionTextValue( editValue )
                await getQuestions()
                setEditMode( false )
            }
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't save changes! try again." )
        } finally {
            setSaving( false )
        }
    }

    const { palette, shadows } = useTheme()

    const remove = () => {
        setEditMode( false )
        removeOption( optionIndex, values, setFieldValue )
    }

    return (
        <Box borderRadius="5px" sx={{ transition: "ease 200ms" }} marginY={editMode && "10px"} marginX={editMode && "-5px"} padding={editMode && "5px"} overflow="hidden" boxShadow={editMode && shadows[5]} border="1px solid #d3d3d3" borderColor={editMode && palette.secondary.light} >
            <Box marginBottom="2px" display="flex" gap="5px" alignItems="center">
                {!editMode && <Tooltip placement='right' title={( optionIndex === values.correctAnswer ) ? values.selectionType === "single" ? "Marked as correct" : "mark as incorrect" : "Mark as correct answer"}>
                    <span>
                        {values.selectionType !== "single" && <Checkbox onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={values.answersStatus[optionIndex] || false} />}
                        {values.selectionType === "single" && <Radio onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={optionIndex === values.correctAnswer} />}
                    </span>
                </Tooltip>}
                {editMode && <Typography paddingLeft="10px" fontWeight="500" fontSize="15px">Edit option</Typography>}

                {!editMode && <Box flexGrow={1} className='discussion-wysiwyg-container' borderRadius="10px">
                    <Latex delimiters={latexDelimiters} >{currentSavedOptionTextValue}</Latex>
                </Box>}
                <Box display="flex" flexGrow={1} justifyContent="flex-end" gap="20px">
                    {!editMode && <IconButton size="small" color="secondary" onClick={() => setEditMode( prev => !prev )}>{Icons.small.EditIcon}</IconButton>}
                    {editMode && <Button size="small" startIcon={Icons.small.CloseIcon} sx={{ textTransform: "capitalize" }} color="errorMessage" onClick={() => setEditMode( prev => !prev )}>Cancel edit</Button>}
                    {!editMode && <Tooltip title="Remove option">
                        <IconButton size="small" color="errorMessage.main" onClick={remove}>{Icons.default.RemoveIcon}</IconButton>
                    </Tooltip>}
                    {editMode && <Button size="small" startIcon={Icons.default.RemoveIcon} sx={{ textTransform: "capitalize" }} color="greyed" onClick={remove}>Remove option</Button>}
                </Box>
            </Box>
            {editMode && <Box padding="5px" bgcolor={palette.form.inputBg} color={palette.common.font} >
                <CustomWysiwyg placeholder='Type the option text here...' modules={modules} value={editValue} onChange={hadleOptionValueChange} />
                <Button disabled={saving || optionPlainText === ''} startIcon={saving && <CircularProgress size={14} />} onClick={updateOptionText} sx={{ textTransform: "capitalize", marginTop: "10px" }} size='small' color="success" variant='contained' disableElevation >Save</Button>
            </Box>}
        </Box>
    )
}

const EditQuestionDialog = ( { question, title = "Edit question", state, setState, getQuestions } ) => {

    const [saving, setSaving] = useState( false )
    const [hasOptionsChanged, setHasOptionsChanged] = useState( false )
    const [optionAddedFieldValue, setOptionAddedFieldValue] = useState( "" )
    const [optionAdderSwitchState, setOptionAdderSwitchState] = useState( false )

    const { palette, border } = useTheme()
    const { id } = useParams()

    const initialValues = {
        question: question.question_text,
        marks: question.marks,
        questionType: question.question_type,
        selectionType: question.selectiontype,
        options: question.options,
        answersStatus: question.options_status,
        correctAnswer: question.question_type === 'MCQ' ? question.selectiontype === 'single' ? question.correct_answer || question.options_status?.indexOf( true ) : '' : question.options[0],
        section: question.question_section,
    }


    const validationSchema = Yup.object().shape( {
        question: Yup.string().required( "Question is required!" ),
        marks: Yup.number().required( "Marks is required." ).test( "VALID_MARKS", "Enter a valid marks", ( val, { parent } ) => {
            if ( val && !isNaN( parseInt( val ) ) && parseInt( val ) > 0 )
                return true
            return false
        } ),
        options: Yup.mixed().required( "Options are required" ).test( "MINIMUM_TWO_OPTIONS", "A question should has minimum of 2 options.", ( val ) => {
            if ( val.length > 1 )
                return true
            return false
        } ),
        answersStatus: Yup.mixed().test( "ATLEAST_TWO_CORRECT_ANSWERS", "Please select at least two correct answers.", ( val, { parent } ) => {
            if ( parent.selectionType === "single" ) return true
            if ( val.filter( i => i === true ).length >= 2 )
                return true
            return false
        } ),
        correctAnswer: Yup.number().test( "CORRECT_ANSWER", "Please select a correct answer.", ( val, { parent } ) => {
            if ( parent.selectionType !== "single" ) return true
            if ( val >= 0 && val < parent.options.length )
                return true
            return false
        } ),
    } )

    const handleEditorValueChange = ( val, setValue, prevVal ) => {
        setValue( 'question', val )
    }

    const retriveOldOptions = ( setValue ) => {
        setValue( 'options', initialValues.options )
        setValue( 'correctAnswer', initialValues.correctAnswer )
        setValue( 'answersStatus', initialValues.answersStatus )
    }

    const handleCorrectAnswerChange = ( index, values, val, setValue ) => {
        if ( values.selectionType === 'single' )
            setValue( 'correctAnswer', index )
        else {
            let newStatus = [...values.answersStatus]
            newStatus[index] = val
            setValue( 'answersStatus', newStatus )
        }
    }

    const addOption = ( options, correctAnswers, type, setValue ) => {
        if ( optionAddedFieldValue && ( optionAddedFieldValue?.trim() !== "" && optionAddedFieldValue?.trim() !== "<p><br/></p>" && optionAddedFieldValue?.trim() !== "<p></p><br/>" && optionAddedFieldValue?.trim() !== "<p><br></p>" ) ) {
            const newOptions = [...options, { answer_text: optionAddedFieldValue }]
            if ( type === 'single' && optionAdderSwitchState ) {
                setValue( 'correctAnswer', newOptions.length - 1 )
            }
            const newStatuses = [...correctAnswers, optionAdderSwitchState]
            setValue( 'answersStatus', newStatuses )

            setValue( 'options', newOptions )
            setOptionAddedFieldValue( "<p></p><br/>" )
            setOptionAdderSwitchState( false )
        }
    }

    const removeOption = ( index, values, setValue ) => {
        let newOptions = [...values.options]
        let newStatus = [...values.answersStatus]
        newStatus.splice( index, 1 )
        newOptions.splice( index, 1 )
        setValue( 'answersStatus', newStatus )
        setValue( 'options', newOptions )
        if ( values.correctAnswer === index )
            setValue( 'correctAnswer', "" )
    }

    const handleClose = async () => {
        getQuestions()
        setState( false )
    }

    const editQuestion = async ( values ) => {
        setSaving( true )
        try {
            values.hasOptionsChanged = hasOptionsChanged
            const { data } = await api.admissionExams.updateQuestion( id, question.question_id, values )
            toast( data?.message || "Question saved successfully!" )
            handleClose()
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't save changes! try again." )
        } finally {
            setSaving( false )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "950px", background: palette.form.formCardBg } }} open={state}>
            <Formik onSubmit={editQuestion} initialValues={initialValues} validationSchema={validationSchema}>
                {( { values, setFieldValue, resetForm } ) => {

                    let optionsChanged = false
                    if ( question.options?.length !== values.options.length ) {
                        optionsChanged = true
                    } else {
                        for ( let k = 0; k < values.options.length; k++ ) {
                            const val = values.options[k]
                            const original = question.options[k]
                            if ( val.answer_id !== original?.answer_id ) {
                                optionsChanged = true
                                break
                            }
                        }
                        if ( !optionsChanged ) {
                            if ( values.correctAnswer !== initialValues.correctAnswer ) {
                                optionsChanged = true
                            }
                        }
                    }
                    setHasOptionsChanged( optionsChanged )

                    return (
                        <Box>
                            <Box display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="20px">
                                <Typography variant="h6" fontSize="18px">{title}</Typography>
                                <IconButton onClick={handleClose} size="small">{Icons.default.CloseIcon}</IconButton>
                            </Box>
                            <DialogContent sx={{ paddingTop: "0", overflow: "auto" }}>
                                <Form>
                                    <Box border={border[1]} padding="20px" borderRadius="5px" bgcolor={palette.contentBg} >
                                        <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor}>
                                                Question Section
                                            </Typography>
                                            <Field disabled={true} fullWidth size="small" as={TextField} name="section" value={values.section} />
                                            <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='section' /></Typography>
                                        </FormControl>
                                        <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question Text *</Typography>
                                            <Box bgcolor={palette.form.inputBg}>

                                                <CustomWysiwyg
                                                    modules={modules}
                                                    placeholder={'Type here...'}
                                                    value={values.question?.replace( /$/g, "" )}
                                                    onChange={val =>
                                                        handleEditorValueChange( val, setFieldValue, values.question )
                                                    }
                                                />
                                                <Typography fontSize="12px" color='textSecondary'>To Add mathematical formula, use latex, Eg: <strong> <em>${"{LATEX}"}$</em> </strong></Typography>
                                                {/* {values.question?.match( /\$/ ) && <Box marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} borderRadius="10px">
                                                    <Latex delimiters={latexDelimiters} >{values.question}</Latex>
                                                </Box>} */}

                                            </Box>
                                            <Typography fontSize="12px" color='errorMessage.main'>
                                                <ErrorMessage name='question' />{' '}
                                            </Typography>
                                        </FormControl>
                                        <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question marks *</Typography>
                                            <Field size="small" as={TextField} placeholder="Specify question's points" type="number" name="marks" onWheel={e => { e.target.blur() }} />
                                            <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='marks' />{' '}</Typography>
                                        </FormControl>
                                        {values.options.length > 0 && <Box>
                                            <Box display="flex" gap="20px" marginTop="20px" marginBottom="5px" alignItems="center" color="secondary">
                                                <Typography gutterBottom sx={{ color: palette.labelColor }} variant="subtitle2">Options</Typography>
                                                {optionsChanged && <Button startIcon={Icons.default.Reply} onClick={() => retriveOldOptions( setFieldValue )} sx={{ textTransform: "capitalize" }} >Retrive old options</Button>}
                                            </Box>
                                            <Box display="flex" flexDirection="column" gap="2px">
                                                {values.options.map( ( option, optionIndex ) => (
                                                    <OptionComponent getQuestions={getQuestions} setFieldValue={setFieldValue} removeOption={removeOption} option={option} values={values} key={optionIndex} optionIndex={optionIndex} handleCorrectAnswerChange={handleCorrectAnswerChange} />
                                                ) )}
                                            </Box>
                                        </Box>}
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='options' /></Typography>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='correctAnswer' /></Typography>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='answersStatus' /></Typography>
                                        <Box marginTop="10px" border={border[1]} display="flex" gap="10px" flexDirection="column" borderRadius="5px">
                                            <Typography padding="20px 20px 0 20px" variant='subtitle1' gutterBottom fontWeight='500'>Add option</Typography>
                                            <Box padding="0 20px 20px 20px" display="flex" alignItems="flex-start" gap="10px" flexDirection="column">
                                                <Box bgcolor={palette.form.inputBg} color={palette.common.font} width="100%">
                                                    <CustomWysiwyg placeholder='Type the option text here...' modules={modules} value={optionAddedFieldValue} onChange={setOptionAddedFieldValue} />
                                                </Box>

                                                {/* {optionAddedFieldValue?.includes( "$" ) && <Box marginTop="10px" className='discussion-wysiwyg-container' width="calc(100% - 20px)" padding="10px" border={border[1]} borderRadius="10px">
                                                    <Latex delimiters={latexDelimiters} >{optionAddedFieldValue}</Latex>
                                                </Box>} */}

                                                <FormControlLabel sx={{ marginLeft: "0" }} labelPlacement='start' label="Correct answer?" control={<CustomSwitch color='primaryDark' checked={optionAdderSwitchState} onChange={e => setOptionAdderSwitchState( e.target.checked )} />} />
                                                <Button sx={{ background: "white", textTransform: "capitalize", '&:hover': { background: "white" } }} onClick={() => addOption( values.options, values.answersStatus, values.selectionType, setFieldValue )} variant="outlined" type="button" startIcon={Icons.default.AddIcon} >Add Option</Button>
                                            </Box>
                                        </Box>
                                        <Box marginTop="40px" display="flex" gap="10px" flexWrap="wrap">
                                            <Button type="submit" disabled={saving} color='success' startIcon={saving ? <CircularProgress size={14} /> : Icons.default.CheckIcon} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation>Save question</Button>
                                            <Button disableElevation onClick={() => handleClose( resetForm )} sx={{ textTransform: "capitalize" }} variant="contained" color="error" >Close</Button>
                                        </Box>
                                    </Box>
                                </Form>
                            </DialogContent>
                        </Box>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default EditQuestionDialog
