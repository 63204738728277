import { Box, Button, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import TableHeader from '../../common/TableHeader'
import { useParams } from 'react-router-dom'
import api from '../../../service/api'
import { showDateInIndianDateFormat } from '../../../utils/utilities'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import errorImg from '../../../assets/request-error.svg'
import noExamImg from '../../../assets/noexams.svg'
import AuditExamViewer from '../exams/AuditExamViewer'
import { Analytics, OpenInNew } from '@mui/icons-material'
import AuditExamParticipations from '../exams/AuditExamParticipations'

const columns = [
    { name: "Sl. no.", padding: "10px 20px 10px 40px", align: "center" },
    { name: "Exam Title" },
    { name: "Created on" },
    { name: "Subject" },
    { name: "Attempts", align: "center" },
    { name: "Actions", align: "right", padding: "10px 40px 10px 20px", },
]

const ExamDetails = ( { userData } ) => {

    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [examDetails, setExamDetails] = useState( [] )
    const [selectedExam, setSelectedExam] = useState( null )
    const [partipationsViewerState, setPartipationsViewerState] = useState( false )

    const { palette, table } = useTheme()

    const { auid } = useParams()

    const handleDialogClose = () => {
        setSelectedExam( null )
    }

    const loadExams = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data } } = await api.audit.getExamsByFacultyId( auid )
            setExamDetails( data )
            setError( false )
        } catch ( err ) {
            setError( err?.response?.data?.message || "Something went wrong while fetching assignments details." )
        } finally {
            setLoading( false )
        }
    }, [auid] )

    useEffect( () => {
        loadExams()
    }, [loadExams] )

    return (
        <Box borderRadius="10px" margin="10px 20px" bgcolor={palette.contentBg} maxHeight="350px" display="flex" flexDirection="column" overflow="hidden">

            {selectedExam && <AuditExamViewer setExamList={setExamDetails} exam={selectedExam} handleClose={handleDialogClose} openState={Boolean( selectedExam ) && !partipationsViewerState} />}
            {selectedExam && partipationsViewerState && <AuditExamParticipations exam={selectedExam} handleClose={() => { setPartipationsViewerState( false ); setSelectedExam( null ) }} openState={partipationsViewerState} />}

            {!loading && !error && examDetails.length > 0 && <TableContainer sx={{ overflow: "auto", }} className='custom-scrollbar' >
                <Table stickyHeader>
                    <TableHeader columns={columns} />
                    <TableBody>
                        {examDetails?.map( ( item, index ) => (
                            <TableRow className="table-row" key={item.assignment_id}>
                                <TableCell align={columns[0].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[0].padding || "10px 20px" }} ><strong>{index + 1}</strong></TableCell>
                                <TableCell align={columns[1].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[1].padding || "10px 20px" }} ><strong>{item.exam_name}</strong></TableCell>
                                <TableCell align={columns[2].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[2].padding || "10px 20px" }} ><strong>{showDateInIndianDateFormat( item.created_at )}</strong></TableCell>
                                <TableCell align={columns[3].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[3].padding || "10px 20px" }} ><strong>{item?.subject?.subject_name || "Not Available"}</strong></TableCell>
                                <TableCell align={columns[4].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[4].padding || "10px 20px" }} ><strong>{item.attempts}</strong></TableCell>
                                <TableCell align={columns[5].align || 'left'} sx={{ fontSize: "12px", color: table.color, padding: columns[5].padding || "10px 20px" }} >
                                    <Box display="flex" justifyContent="flex-end" gap="10px" alignItems="center">
                                        <Tooltip title="Open details">
                                            <IconButton onClick={() => setSelectedExam( item )} color="primary" size="small"><OpenInNew fontSize='small' /></IconButton>
                                        </Tooltip>
                                        {+item.attempts > 0 && <Tooltip title="View participations">
                                            <IconButton onClick={() => { setSelectedExam( item ); setPartipationsViewerState( true ) }} color="secondary" size="small"><Analytics fontSize='small' /></IconButton>
                                        </Tooltip>}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) )}
                    </TableBody>
                </Table>
            </TableContainer>}
            {loading && !error && <Box padding="50px 20px" display="flex" justifyContent="center">
                <DataLoadingSpinner waitingMessage="Please wait! Loading exams..." />
            </Box>}
            {error && <Box padding="20px" display="flex" alignItems="center" gap="20px" flexDirection="column" justifyContent="center">
                <img src={errorImg} height="100px" alt="Something went wrong!" />
                <Typography variant="subtitle2">{error}</Typography>
                <Button disabled={loading} startIcon={loading && <CircularProgress size={14} />} onClick={loadExams} variant="contained" sx={{ textTransform: "capitalize" }} color="errorMessage" disableElevation>Retry!</Button>
            </Box>}
            {!loading && !error && examDetails.length === 0 && <Box padding="20px" gap="20px" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                <img src={noExamImg} height="100px" alt="Something went wrong!" />
                <Typography variant="subtitle2"><em>{userData.employee_name}</em> hasn't given any exams!</Typography>
            </Box>}
        </Box>
    )
}

export default ExamDetails
