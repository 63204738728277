import { Avatar, Box, Button, Card, Typography } from '@mui/material'
import StudyMaterialDetails from '../../components/audit/facultypage/StudyMaterialDetails'
import AssignmentDetails from '../../components/audit/facultypage/AssignmentDetails'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ExamDetails from '../../components/audit/facultypage/ExamDetails'
import React, { useCallback, useEffect, useState } from 'react'
import { Icons } from '../../utils/utilities'
import api from '../../service/api'
import QuizDetails from '../../components/audit/facultypage/QuizDetails'
import QuestionBankDetails from '../../components/audit/facultypage/QuestionBankDetails'
import DiscussionDetails from '../../components/audit/facultypage/DiscussionDetails'

const FacultyAudit = () => {

    const { auid } = useParams()

    const [userData, setUserData] = useState( null )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const navigate = useNavigate()
    const [searchParams] = useSearchParams( { redirect: "" } )

    const navigateBack = () => {
        const redirectPath = searchParams.get( 'redirect' )
        navigate( redirectPath || '/audit/' )
    }

    const getData = useCallback( async () => {
        try {
            setLoading( true )
            const { data: { userData } } = await api.audit.fetchUserDetails( auid )
            setError( null )
            setUserData( userData )
        } catch ( err ) {
            setError( err?.response?.data?.message || "Something went wrong while fetching user details." )
        } finally {
            setLoading( false )
        }
    }, [auid] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        !error ? <Box display="flex" flexGrow={1} flexDirection="column" gap="20px" padding="20px" overflow="auto">

            <Button onClick={navigateBack} size="small" sx={{ width: "fit-content", color: "GrayText" }} startIcon={Icons.default.ArrowBack}>
                <Typography color="GrayText" textTransform="capitalize" variant='subtitle2'>Back</Typography>
            </Button>

            <Card sx={{ flexShrink: "0", width: "100%" }}>
                {!loading && userData && <Box padding="20px" gap="20px" justifyContent="space-between" display="flex">
                    <Box display="flex" gap="20px">
                        <Avatar sx={{ aspectRatio: 1, width: "80px", height: "80px", borderRadius: "10px" }} src={userData.user_profile_image_url} />
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <Typography variant='h6' marginBottom="10px" fontSize="16px" textTransform="capitalize" color="primaryDark.main" >{userData.employee_name?.toLowerCase()}</Typography>
                            <Typography variant='body2' fontSize="14px" fontWeight={500} color="primaryDark.main" >{userData.institute_name_short}</Typography>
                            <Typography variant='body2' fontSize="14px" fontWeight={500} textTransform="capitalize" color="primaryDark.main" >{userData.branch_name?.toLowerCase()}</Typography>
                        </Box>
                    </Box>
                </Box>}
            </Card>

            <Card sx={{ flexShrink: "0", width: "100%", overflow: "auto" }}>
                {!loading && <Box>
                    {/* ASSIGNMENTS DETAILS */}
                    <Box padding="20px 20px 0 20px">
                        <Typography variant="h6" fontSize="16px" color="primaryDark.main">Assignments</Typography>
                    </Box>
                    {userData && <AssignmentDetails userData={userData} />}

                    {/* EXAMS DETAILS */}
                    <Box padding="20px 20px 0 20px">
                        <Typography variant="h6" fontSize="16px" color="primaryDark.main">Exams</Typography>
                    </Box>
                    {userData && <ExamDetails userData={userData} />}

                    {/* MATERIALS DETAILS */}
                    <Box padding="20px 20px 0 20px">
                        <Typography variant="h6" fontSize="16px" color="primaryDark.main">Study Materials</Typography>
                    </Box>
                    {userData && <StudyMaterialDetails userData={userData} />}

                    {/* QUIZZES DETAILS */}
                    <Box padding="20px 20px 0 20px">
                        <Typography variant="h6" fontSize="16px" color="primaryDark.main">Quizzes</Typography>
                    </Box>
                    {userData && <QuizDetails userData={userData} />}

                    {/* QUESTION BANKS DETAILS */}
                    <Box padding="20px 20px 0 20px">
                        <Typography variant="h6" fontSize="16px" color="primaryDark.main">Question banks</Typography>
                    </Box>
                    {userData && <QuestionBankDetails userData={userData} />}

                    {/* DISCUSSIONS */}
                    <Box padding="20px 20px 0 20px">
                        <Typography variant="h6" fontSize="16px" color="primaryDark.main">Discussions</Typography>
                    </Box>
                    {userData && <DiscussionDetails userData={userData} />}
                </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading assignments please wait..." />}
            </Card>
        </Box > : <Box display="flex" flexGrow={1} justifyContent="center">
            <TryAgainMessageBlock imageHeight='200px' err={error} loading={loading} getData={getData} />
        </Box>
    )
}

export default FacultyAudit
