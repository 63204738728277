import { Box, Typography, TableContainer, Table, TableRow, TableCell, TableBody, MenuItem, Paper, useTheme, Menu, Button } from '@mui/material'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import TableHeader from '../../../components/common/TableHeader'
import noParticipationsImg from '../../../assets/no-participation.svg'
import { formatDateTimeForDisplay } from '../../../utils/exam-utilities'
import { Link, useSearchParams } from 'react-router-dom'
import { Icons } from '../../../utils/utilities'
import { useCallback, useEffect, useRef, useState } from 'react'
import api from '../../../service/api'
import BreadcrumbList from '../../../components/common/BreadcrumbList'
import TryAgainMessageBlock from '../../../components/common/TryAgainMessageBlock'

const columns = [
    { name: "Sl.no", padding: "10px 20px 10px 40px", maxWidth: "20px", minWidth: "40px", align: "left" },
    { name: "Exam name", minWidth: '150px', align: "left" },
    { name: "Exam date", maxWidth: "50px", minWidth: '120px', align: "left" },
    { name: "Participations", padding: "10px 40px 10px 20px", minWidth: '100px', align: "left" },
]

const AllExamsParticipations = () => {

    const [data, setData] = useState( [] )
    const [examList, setExamList] = useState( {} )
    const [error, setError] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const [filteredData, setFilteredData] = useState( [] )
    const [examsListOpen, setExamsListOpen] = useState( false )

    const menuRef = useRef()

    const [searchParams, setSearchParams] = useSearchParams( { selectedExam: "0" } )
    const selectedExam = searchParams.get( 'selectedExam' ) || "0"

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        } )
    }

    const { palette, table, border } = useTheme()

    const filterExams = ( val, examsdata = data ) => {
        setSearchParamValue( 'selectedExam', val )
        let filteredExams
        if ( parseInt( val ) !== 0 )
            filteredExams = examsdata.filter( p => p.exam_id === +val )
        else filteredExams = examsdata
        setFilteredData( filteredExams )
        setExamsListOpen( false )
    }

    const getData = useCallback( async () => {
        try {
            setInProgress( true )
            const { data: { data: allExamParticipations } } = await api.fetchAllParticipations()
            let exams = {}
            for ( let i = 0; i < allExamParticipations.length; i++ ) {
                exams[allExamParticipations[i].exam_id] = allExamParticipations[i].exam_name
            }
            setExamList( exams )
            setData( allExamParticipations )
            setError( null )
            setInProgress( false )
        } catch ( e ) {
            setError( { message: e?.response?.data?.message, code: e?.response?.status } )
        } finally {
            setInProgress( false )
        }
    }, [] )


    useEffect( () => {
        getData()
    }, [getData] )


    useEffect( () => {
        if ( selectedExam !== "0" && examList[selectedExam] )
            setFilteredData( data.filter( p => +p.exam_id === +selectedExam ) )
        else setFilteredData( data )
    }, [data, selectedExam, examList] )

    return (
        <Box flexGrow="1" display="flex" overflow="auto" flexDirection="column" sx={{ padding: "20px" }}>

            <BreadcrumbList items={[
                { link: "/faculty/exams", label: "Exams" },
                "Participations"
            ]} />

            <Paper sx={{ minHeight: !inProgress && "350px", display: "flex", flexGrow: ( !inProgress && data && data.length > 0 ) ? 1 : "initial", flexDirection: "column", overflow: "hidden" }}>
                <Box sx={{
                    background: `linear-gradient(90deg, #000000ee 0%, rgba(0, 0, 0, 0) 100%)`
                }} display="flex" gap="20px" padding="20px" alignItems="flex-start" flexDirection="column">
                    <Box flexGrow="1">
                        <Typography color="white" variant='h5'>Participations</Typography>
                        <Typography color="whitesmoke" variant='body2'>Check the participations</Typography>
                    </Box>
                    {examList && <Button onClick={() => setExamsListOpen( true )} ref={menuRef} endIcon={Icons.default.DropDown} variant='contained' disableElevation color="customThemeColor" >
                        <Typography noWrap maxWidth="400px">{selectedExam !== "0" && examList[selectedExam] ? examList[selectedExam] : "ALL EXAMS"}</Typography>
                    </Button>}
                    <Menu anchorEl={menuRef.current} onClose={() => setExamsListOpen( false )} open={examsListOpen}>
                        <MenuItem onClick={() => filterExams( "0" )} sx={{ fontSize: "14px" }} key={"-1"} value="-1">All exams</MenuItem>
                        {examList && Object.keys( examList ).map( exam => (
                            <MenuItem onClick={() => filterExams( exam )} sx={{ fontSize: "14px" }} key={exam} value={exam}>{examList[exam]}</MenuItem>
                        ) )}
                    </Menu>
                </Box>

                {selectedExam !== "0" && !examList[selectedExam] && !inProgress && !error && <Typography padding="10px" color="errorMessage.main" borderRadius="10px" border={`2px solid ${palette.errorMessage.main}`} variant='subtitle2' margin="20px" marginBottom="0">
                    The selected exam(ID: {selectedExam} ) does not have any participation!
                </Typography>}

                {!inProgress && !error && data && data.length > 0 && <Box margin="20px" borderRadius="5px" border={border[1]} bgcolor={palette.contentBg} flexGrow="1" overflow="hidden">
                    < TableContainer sx={{ height: "100%", overflow: "auto" }} className='custom-scrollbar' >
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {filteredData.map( ( participation, index ) => (
                                    <TableRow className='table-row' key={participation.exam_id} sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }}>
                                        <TableCell sx={{ padding: "0", maxWidth: "20px" }}>
                                            <Link to={`/faculty/exams/${participation.exam_id}/participation`}>
                                                <Typography padding={columns[0].padding || "10px 20px"} fontSize="14px" fontWeight="bold" color={table.color}>
                                                    {index + 1}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ padding: "0" }}>
                                            <Link to={`/faculty/exams/${participation.exam_id}/participation`}>
                                                <Typography padding={"10px 20px"} fontSize="14px" fontWeight="bold" color={table.color}>
                                                    {participation.exam_name}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell align='left' sx={{ padding: "0" }}>
                                            <Link to={`/faculty/exams/${participation.exam_id}/participation`}>
                                                <Typography padding={"10px 20px"} fontSize="14px" fontWeight="bold" color={table.color}>
                                                    {formatDateTimeForDisplay( participation.exam_start_date, participation.exam_start_time )}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell align='left' sx={{ padding: "0" }}>
                                            <Link to={`/faculty/exams/${participation.exam_id}/participation`}>
                                                <Typography padding={columns[3].padding || "10px 20px"} fontSize="14px" fontWeight="bold" color={table.color}>
                                                    {participation.total_participation}
                                                </Typography>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {!inProgress && !error && ( !data || data.length === 0 ) && <Box margin="10px" borderRadius="5px" flexGrow={1} gap="20px" bgcolor={palette.contentBg} display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding="20px">
                    <img width="200px" src={noParticipationsImg} alt="No participations" />
                    <Typography variant='subtitle2'>No participations yet!</Typography>
                </Box>}
                {inProgress && !error && <DataLoadingSpinner waitingMessage="Loading all participations across exams..." />}
                {error && <Box overflow="auto" flexGrow={1}>
                    <TryAgainMessageBlock err={error.message} code={error.code} getData={getData} loading={inProgress} />
                </Box>}
            </Paper>
        </Box>
    )
}

export default AllExamsParticipations
