import { Box, Button, Card, CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import AnswerSection from '../../../components/exams-components/AnswerSection'
import QuestionList from '../../../components/exams-components/QuestionList'
import { useParams, useNavigate } from 'react-router-dom'
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { flatColors, formatDateTimeForDisplay, formatDateTimeForInputField } from '../../../utils/exam-utilities'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from '../../../service/api'
import { useExamStore } from '../../../store/exam.store'
import { useUserStore } from '../../../store/user.store'
import { shuffleArray } from '../../../utils/utilities'
import { CalendarMonth } from '@mui/icons-material'
import { useSettingsStore } from '../../../store/settings.store'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'

const examWindowHeaderStyle = {
    padding: "20px",
    background: "#151D48",
    color: "white",
    zIndex: 1000,
    '@media(max-width:900px)': {
        flexDirection: "column",
        height: "fit-content"
    }
}

const CountDown = ( { examDuration, time, finishExam, setTimeLeft } ) => {
    const [remainingTime, setRemainingTime] = useState( time )
    const [timeElapsed, setTimeElapsed] = useState( '' )
    const [countdown, setCountdown] = useState( '' )

    useEffect( () => {
        const interval = setInterval( () => {
            if ( remainingTime || remainingTime === 0 ) {
                if ( remainingTime <= 0 ) {
                    finishExam( "TimeUP", 0, 0 )
                    clearInterval( interval )
                } else setRemainingTime( pd => pd - 1 )
                let hours = Math.floor( remainingTime / 3600 )
                let minutes = Math.floor( ( remainingTime % 3600 ) / 60 )
                let seconds = parseInt( ( ( remainingTime % 3600 ) % 60 ) )

                hours = hours < 10 ? "0" + hours : hours
                minutes = minutes < 10 ? "0" + minutes : minutes
                seconds = seconds < 10 ? "0" + seconds : seconds
                setCountdown( `${hours}:${minutes}:${seconds} Sec` )
                setTimeLeft( remainingTime )
            }
        }, 1000 )
        return () => clearInterval( interval )
    }, [remainingTime, finishExam, setTimeLeft] )

    useEffect( () => {

        let totalhours = Math.floor( ( examDuration - remainingTime - 1 ) / 3600 )
        let totalminutes = Math.floor( ( ( examDuration - remainingTime - 1 ) % 3600 ) / 60 )
        let totalseconds = parseInt( ( ( ( examDuration - remainingTime - 1 ) % 3600 ) % 60 ) )

        totalhours = totalhours < 10 ? "0" + totalhours : totalhours
        totalminutes = totalminutes < 10 ? "0" + totalminutes : totalminutes
        totalseconds = totalseconds < 10 ? "0" + totalseconds : totalseconds
        if ( ( examDuration - remainingTime - 1 ) > 0 )
            setTimeElapsed( `${totalhours}:${totalminutes}:${totalseconds} Sec` )
    }, [remainingTime, examDuration] )

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant='subtitle2' fontSize="12px">Elapsed Time: {timeElapsed}</Typography>
            <Typography variant='subtitle2' sx={{ color: remainingTime < 300 ? "red" : "white" }} width="90px"> {countdown} </Typography>
        </Box>
    )
}

const ExamAttempt = () => {
    const [currentQuestion, setCurrentQuestion] = useState( null )
    const [isExamStarted, setIsExamStarted] = useState( false )
    const [participation, setParticipation] = useState( null )
    const [showQuestions, setShowQuestions] = useState( true )
    const [remainingTime, setRemainingTime] = useState( null )
    const [finishDialog, setFinishDialog] = useState( false )
    const [isSubmitting, setIsSubmitting] = useState( false )
    const [examDuration, setExamDuration] = useState( null )
    const [showWarning, setShowWarning] = useState( false )
    const [submissions, setSubmissions] = useState( null )
    const [currentIndex, setCurrentIndex] = useState( 0 )
    const [isStarting, setIsStarting] = useState( false )
    const [questions, setQuestions] = useState( null )
    const [faultCount, setFaultCount] = useState( 0 )
    const [timeLeft, setTimeLeft] = useState( ' ' )
    const [loading, setLoading] = useState( true )
    const [saving, setSaving] = useState( false )
    const [exam, setExam] = useState( null )

    const navigate = useNavigate()

    const ExamStore = useExamStore()
    const UserStore = useUserStore()
    const SettingsStore = useSettingsStore()

    const { id, pid, attempt } = useParams()
    const { palette, border } = useTheme()

    const examAttemptFullScreenHandle = useFullScreenHandle()

    const fullscreeBtnRef = useRef()

    const selectQuestion = id => {
        for ( let i = 0; i < questions.length; i++ ) {
            if ( questions[i].question.question_id === id ) {
                setCurrentQuestion( questions[i] )
                setCurrentIndex( i )
                if ( saving )
                    toast( "Previous question is under saving process...", { position: "bottom-right" } )
                break
            }
        }
    }

    const moveToNextQuestion = () => {
        let nextIndex = currentIndex + 1
        if ( nextIndex >= questions.length ) {
            nextIndex = 0
        }
        setCurrentIndex( nextIndex )
        setCurrentQuestion( questions[nextIndex] )

    }

    const saveAnswer = async ( answer, type, selectionType, reset = false ) => {
        if ( answer || reset ) {
            if ( String( answer ).trim() !== '' || reset ) {
                try {
                    setSaving( true )
                    const res = await api.submitAnswer( id, pid, { answer, qid: currentQuestion.question.question_id, type, selectionType, remainingDuration: timeLeft, reset } )
                    if ( res.status === 200 ) {
                        const { data: sData } = await api.fetchParticipationSubmissions( id, pid )
                        setSubmissions( sData.data )
                        toast( res.data.message, { position: "bottom-right" } )
                    }
                } catch ( e ) {
                    console.log( e )
                    if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                        UserStore.setERPSessionState( "ended" )
                    }
                    else
                        toast( ( e.response && e.response.data ) ? e.response.data : "Something went wrong! submit answer again", { style: { fontSize: "14px" } } )
                } finally {
                    setSaving( false )
                }
            } else toast( "No answer given for previous question!" )
        } else toast( "No answer given for previous question!" )
    }

    const checkForReload = ( e ) => {
        if ( e.code === 'F5' )
            e.preventDefault()
        e = e || e.window.event
        if ( e.ctrlKey ) {
            var c = e.which || e.keyCode
            if ( c === 82 ) {
                e.preventDefault()
                e.stopPropagation()
            }
        }
    }

    const handleFullScreenChange = async ( state, handle ) => {
        // console.log( examAttemptFullScreenHandle.active )
        if ( isExamStarted && state === false ) {
            setFaultCount( prev => prev + 1 )
            setShowWarning( "fullscreenexit" )
            if ( examAttemptFullScreenHandle?.active === false )
                await fullscreeBtnRef?.current?.click()
        }
    }

    const handleScreenChange = useCallback( ( e ) => {
        if ( isExamStarted ) {
            setFaultCount( prev => prev + 1 )
            setShowWarning( "windowchange" )
        }
    }, [isExamStarted] )

    const startExam = async () => {
        setIsStarting( true )
        try {
            const deviceData = {}
            deviceData.platform = navigator?.userAgentData?.platform
            deviceData.userAgent = navigator?.userAgent
            deviceData.vendor = navigator?.vendor
            const res = await api.startExam( id, pid, { device_data: deviceData } )
            const { data: qData } = await api.fetchQuestionsForAttempt( id )
            if ( ExamStore.getExam.exam.enable_randomize )
                shuffleArray( qData.data )
            setCurrentQuestion( qData.data[0] )
            setQuestions( qData.data )
            setRemainingTime( res.data.duration )
            setExamDuration( res.data.totalDuration )
            setIsExamStarted( true )
            return true
        } catch ( e ) {
            if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            }
            else
                toast( e?.response?.data?.message || "Something went wrong! try to start exam again" )
        } finally {
            setIsStarting( false )
        }
    }

    const finishExam = useCallback( async ( reason = "TimeUP" ) => {
        try {
            const { data } = await api.finishExam( id, pid, { reason } )
            toast( data.message )
            navigate( `/student/exams/${id}`, { replace: true } )
        }
        catch ( e ) {
            if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            }
            else
                toast.error( e?.response?.data?.message || "Something went wrong while submitting you answers" )
        }
    }, [UserStore, navigate, id, pid] )

    const toggleQuestions = () => {
        setShowQuestions( ps => !ps )
    }

    useEffect( () => {
        window.addEventListener( 'keydown', checkForReload )
        window.addEventListener( 'blur', handleScreenChange )

        return () => {
            window.removeEventListener( 'keydown', checkForReload )
            window.removeEventListener( 'blur', handleScreenChange )
        }
    }, [handleScreenChange] )

    useEffect( () => {
        const getData = async () => {
            try {
                setLoading( true )
                const participation = await api.fetchParticipationData( id, pid )
                if ( String( participation.data.participation.exam_participation_end_time ) !== String( null ) || participation.data.participation.exam_participation_status === 'Completed' ) {
                    toast.error( "The attempt you tried to acess already ended!" )
                    navigate( `/student/exams/${id}`, { replace: true } )
                }
                await ExamStore.fetchExam( id )
                if ( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date() ) {
                    finishExam( "LateAccess" )
                    toast.error( "Exam already ended!" )
                    navigate( `/student/exams/${id}`, { replace: true } )
                }
                if ( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) > new Date() ) {
                    toast.error( "Exam not yet started!" )
                    navigate( `/student/exams/${id}`, { replace: true } )
                }
                const { data: sData } = await api.fetchParticipationSubmissions( id, pid )
                setParticipation( participation.data )
                setRemainingTime( participation.data?.participation?.remaining_time || participation.data?.participation?.duration )
                setExamDuration( participation.data?.participation?.duration )
                setFaultCount( participation.data?.participation?.faults_made )
                setSubmissions( sData.data )
                setExam( ExamStore.getExam.exam )
            } catch ( e ) {
                console.log( e )
                if ( e.status === 404 )
                    navigate( '/notfound', { replace: true } )
                else if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                    UserStore.setERPSessionState( "ended" )
                }
                else toast( e?.response?.data?.message || "Something went wrong! try again later" )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [id, pid, navigate, finishExam, ExamStore, UserStore] )

    useEffect( () => {

        const registerFault = async () => {
            if ( faultCount > +( participation?.participation?.faults_made || 0 ) ) {
                try {
                    await api.registerExamAttemptFault( id, pid, { faultCount } )
                } catch ( err ) { }
            }
            if ( faultCount >= ( SettingsStore?.getUseSettings['exam_maximum_fault_excuses'] || 5 ) ) {
                setIsExamStarted( ps => false )
                finishExam( "MaximumFaultsReached" )
            }
        }
        registerFault()
    }, [finishExam, SettingsStore, faultCount, id, pid, participation] )

    return (
        <FullScreen handle={examAttemptFullScreenHandle} onChange={handleFullScreenChange} className='full-screen-element'>

            {!loading && !isExamStarted && exam && <Box flexGrow={1} minWidth="0" overflow="auto" padding="20px">

                <Paper sx={{ paddingBottom: "1px", overflow: "hidden" }}>
                    <Box padding="20px" bgcolor={flatColors[+id % flatColors.length]} display="flex" flexDirection="column" gap="5px" color="white">
                        <Typography variant='h5'>{exam.exam_name}</Typography>
                        <Typography display="flex" alignItems="center" gap="10px" fontSize="14px" variant='body1'> <CalendarMonth fontSize="small" /> {`${formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )} - ${formatDateTimeForDisplay( exam.exam_end_date, exam.exam_end_time )}`}</Typography>
                        <Typography variant='subtitle2'>{exam.exam_duration !== null && exam.exam_duration !== "null" ? "Duration: " + exam.exam_duration : ""}</Typography>
                        <Typography variant='body2'>Attempt number: {attempt}</Typography>
                    </Box>
                    <Box margin="20px" borderRadius="5px" border={border[1]} bgcolor={palette.contentBg} padding="20px">
                        <Typography variant='h6'>Exam Description</Typography>
                        <Typography variant='body2' textAlign="justify">{exam.exam_description}</Typography>
                        <Typography marginTop="20px" variant='h6'>Important Instructions</Typography>
                        <ul>
                            <li><Typography fontSize="14px">The exam will be held in fullscreen mode.</Typography> </li>
                            <li><Typography fontSize="14px">If a participant change tabs or window the exam will be ended.</Typography></li>
                            <li><Typography fontSize="14px">Exam will be ended if participant exits fullscreen mode by any means.</Typography></li>
                            <li><Typography fontSize="14px">So please switch off all the notification.</Typography></li>
                        </ul>
                        <Button onClick={async () => {
                            if ( await startExam() ) { }
                            examAttemptFullScreenHandle.enter()
                        }} disabled={isStarting} variant='contained' color='customThemeColor' disableElevation > {isStarting ? <Typography display="flex" alignItems="center" gap="5px" fontSize={14}> <CircularProgress size={14} /> Starting exam...</Typography> : <Typography fontSize={14}>Start Exam</Typography>} </Button>
                    </Box>
                </Paper>

            </Box>
            }

            {loading && <DataLoadingSpinner waitingMessage="Loading exam details..." />}

            {isExamStarted && <Box flexGrow={1} id='exam-window' display="flex" flexDirection="column" overflow="auto">

                <Box bgcolor="rgba(0,0,0,0.3)" sx={{ position: "fixed", zIndex: "20000", display: Boolean( showWarning ) ? "flex" : "none", alignItems: "center", top: 0, right: 0, bottom: 0, left: 0, justifyContent: "center" }} >
                    <Card>
                        <Box maxWidth="400px">
                            <Box padding="10px 20px" display="flex" alignItems="flex-end" justifyContent="space-between" gap="20px">
                                <Typography textAlign="center" color="primaryDark.main" variant='h6' >Warning!</Typography>
                                <Typography textAlign="center" color="primary.main" fontWeight="bolder" variant='subtitle2'>Only {( SettingsStore?.getUseSettings['exam_maximum_fault_excuses'] || 5 ) - faultCount} excuses left</Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" margin="0 10px" borderRadius="5px" alignItems="center" justifyContent="center" bgcolor={palette.contentBg} padding="20px">
                                <Typography marginY="10px" textAlign="center" variant='h6' fontWeight="500" fontSize="16px" color="#ED1C24" >YOU HAVE COMMITTED {faultCount} FAULT(S)!</Typography>
                                <Typography textAlign="center" color="GrayText" fontWeight="bolder" variant='subtitle2'>
                                    {( SettingsStore?.getUseSettings['exam_maximum_fault_excuses'] || 5 ) === faultCount ? "Submitting your answers due to exceeding allowed number of faults..." : "Ongoing exam will be submitted automatically once the excuses exceeds the limit!"}
                                </Typography>
                            </Box>
                            <Box padding="20px" display="flex" gap="20px" justifyContent="center" alignItems="center" flexWrap="wrap">
                                <Button disabled={isSubmitting} onClick={async () => {
                                    setShowWarning( null )
                                    if ( examAttemptFullScreenHandle?.active === false )
                                        await examAttemptFullScreenHandle.enter()
                                }} variant='contained' color="customThemeColor" disableElevation sx={{ textTransform: "capitalize" }}>Okay, got it!</Button>
                                <Button onClick={
                                    async () => {
                                        setIsSubmitting( true )
                                        await finishExam( "ManualSubmission" )
                                        setIsSubmitting( false )
                                    }}
                                    variant='contained' color="secondary" startIcon={isSubmitting && <CircularProgress size={14} />} disabled={isSubmitting} disableElevation sx={{ textTransform: "capitalize" }}>Finish exam now</Button>
                            </Box>
                        </Box>
                    </Card>
                </Box>

                {participation &&
                    <Box sx={examWindowHeaderStyle}>
                        <Button
                            sx={{ position: "fixed", opacity: 0, pointerEvents: "none", width: 0, padding: "0", height: "0" }} onClick={async () => {
                                try { await examAttemptFullScreenHandle.enter() } catch ( err ) {
                                    console.log( err )
                                }
                            }} ref={fullscreeBtnRef}
                        ></Button>
                        <Box display="flex" alignItems="flex-end" marginBottom="10px" justifyContent="space-between">
                            <Box>
                                <Typography variant='h6' display={{ lg: "flex", md: "flex", sm: "none", xs: "none" }} alignItems="center" justifyContent={{ lg: "flex-start", md: "flex-start", sm: "center", xs: "center" }} noWrap>{exam && exam.exam_name}</Typography>
                                <Typography gutterBottom display="flex" alignItems="center" gap="10px" fontSize="14px" variant='body1'> <CalendarMonth fontSize="small" /> {`${formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )} - ${formatDateTimeForDisplay( exam.exam_end_date, exam.exam_end_time )}`}</Typography>
                                <Typography variant='subtitle2' fontSize="12px" display={{ lg: "flex", md: "flex", sm: "none", xs: "none" }} alignItems="center" justifyContent={{ lg: "flex-start", md: "flex-start", sm: "center", xs: "center" }}>{exam && exam.exam_topics.join( ", " )}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap="20px" justifyContent={{ lg: "initial", md: "initial", sm: "space-between", xs: "space-between" }}>
                                <Box textAlign="right" display="flex" flexDirection="column" alignItems="flex-end">
                                    <Typography variant='subtitle2' fontSize="12px">Total marks: {questions?.reduce( ( sum, ques ) => sum + ques.question.question_point, 0 )}</Typography>
                                    <Button variant='contained' size="small" onClick={() => setFinishDialog( true )} sx={{ textTransform: "capitalize !important", margin: "10px 0 0 10px !important", width: "fit-content", '@media(max-width:350px)': { width: "100% !important" } }}>Finish exam</Button>
                                </Box>
                            </Box>
                        </Box>
                        <CountDown setTimeLeft={setTimeLeft} examDuration={examDuration} finishExam={finishExam} time={remainingTime} />
                        <Box bgcolor="rgba(0,0,0,0.3)" sx={{ position: "fixed", zIndex: "20000", display: finishDialog ? "flex" : "none", alignItems: "center", top: 0, right: 0, bottom: 0, left: 0, justifyContent: "center" }} >
                            <Box bgcolor={palette.common.bg} margin="20px" padding="20px" borderRadius="5px">
                                <Typography color="primaryDark.main" variant='h5'>Are you sure?</Typography>
                                <Typography variant='subtitle2' color={palette.common.font} marginY="10px">There is still time left! Do you want to finish the exam now?</Typography>
                                <Box display="flex" gap="10px" marginTop="20px" flexWrap="wrap">
                                    <Button onClick={async () => { setIsSubmitting( true ); await finishExam( "ManualSubmission" ); setIsSubmitting( false ) }} disableElevation disabled={isSubmitting} variant='contained' sx={{ textTransform: "capitalize" }}>{isSubmitting ? <Typography display="flex" alignItems="center" gap="5px" fontSize="14px"> <CircularProgress size={14} /> Submitting your answers</Typography> : "Finish now"}</Button>
                                    <Button onClick={() => setFinishDialog( false )} disabled={isSubmitting} variant='contained' disableElevation color="error" sx={{ textTransform: "capitalize" }}>Cancel</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>}
                {questions && <Box display="grid" flexGrow={1} bgcolor={palette.contentBg} overflow="auto" gridTemplateColumns={{ lg: `${showQuestions ? "300px" : "0px"} auto`, md: `${showQuestions ? "250px" : "0px"} auto`, sm: `${showQuestions ? "50px" : "0px"} auto`, xs: `${showQuestions ? "50px" : "0px"} auto` }} sx={{ transition: "0.2s ease-out" }}>
                    <QuestionList showQuestions={showQuestions} questions={questions} currentQuestionIndex={currentIndex} submissions={submissions} selectQuestion={selectQuestion} />
                    <AnswerSection saving={saving} toggleQuestions={toggleQuestions} showQuestions={showQuestions} question={currentQuestion} submitAnswer={saveAnswer} currentQuestionIndex={currentIndex} nextQuestion={moveToNextQuestion} submissions={submissions} finishExam={finishExam} />
                </Box>}
            </Box >}

        </FullScreen>
    )
}

export default ExamAttempt