import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Box, IconButton, useTheme, CircularProgress } from '@mui/material'
import { Warning } from '@mui/icons-material'
import { Icons } from '../../utils/utilities'
import React from 'react'

const ConfirmDialog = ( { waiting, actionName, confirmAction, message, status, cancelAction, isReversible } ) => {

    const { palette } = useTheme()

    return (
        <Dialog PaperProps={{ sx: { width: "400px", minWidth: "200px", maxWidth: "500px" } }} open={status}>
            <DialogTitle sx={{ justifyContent: "space-between", display: "flex", alignItems: "center", gap: "20px" }}>
                Are you sure?
                <IconButton onClick={cancelAction}>
                    {Icons.default.CloseIcon}
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <Box>
                    {!isReversible && <Typography padding="10px 20px" bgcolor={palette.errorMessage.main} gap="10px" display="flex" alignItems="center" color="white" fontSize="12px" fontWeight="500">
                        <Warning fontSize='small' />
                        This action cannot be undone.
                    </Typography>}
                    <Typography padding="20px" variant='subtitle2'>{message}</Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ background: palette.contentBg }}>
                <Button startIcon={waiting && <CircularProgress size={14} />} disabled={waiting} disableElevation color="errorMessage" variant='contained' onClick={confirmAction} sx={{ textTransform: "capitalize" }}>{actionName}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog
