import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useStudentResumeFormStore } from '../../../store/studentresumeform.store'
import { Box, Chip, FormControl, TextField, Typography, useTheme } from '@mui/material'
import { Icons } from '../../../utils/utilities'

const MultipleValueArrayInput = observer( ( { attrIndex, attr, values, setSectionResponseJson, sectionResponseJson, attrkey, saveChanges, section } ) => {
    const StudentResumeFormStore = useStudentResumeFormStore()

    const { palette } = useTheme()

    const [textfieldValue, setTextfieldValue] = useState( '' )
    const handleChange = ( e ) => {
        setTextfieldValue( e.target.value )
    }

    const removeItem = ( i ) => {
        let newValues = [...values]
        newValues.splice( i, 1 )
        setSectionResponseJson( { ...sectionResponseJson, [attrkey]: [...newValues] } )
        saveChanges( section.section_title, { ...sectionResponseJson, [attrkey]: [...newValues] } )
        StudentResumeFormStore.setChangesMade( true )
    }

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            e.preventDefault()
            setSectionResponseJson( { ...sectionResponseJson, [attrkey]: [...values, textfieldValue] } )
            saveChanges( section.section_title, { ...sectionResponseJson, [attrkey]: [...values, textfieldValue] } )
            StudentResumeFormStore.setChangesMade( true )
            setTextfieldValue( "" )
        }
    }

    return (
        <Box marginBottom="10px" flexGrow={1} key={attrIndex}>
            <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography>
            <Box marginTop="10px" padding="5px 0 0 5px" borderRadius="5px" border={`1px solid ${palette.borderColor}`}>
                <FormControl sx={{ flexGrow: 1 }} fullWidth>
                    <TextField value={textfieldValue} onChange={handleChange} onKeyDown={handleKeyDown} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", paddingLeft: "10px", "@media(max-width:900px)": { width: "100%" } } }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: palette.contentBg, marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "@media(max-width:900px)": { width: "100%" } }} placeholder={`To add ${attr.entity_detail.label}, type and press 'Enter'`} variant="standard" type="text" />
                    {/* <TextField value={textfieldValue} onChange={handleChange} InputProps={{ sx: { outline: "none", border: "none" } }} size="small" type='text' /> */}
                </FormControl>
                <Box padding="10px">
                    <Typography variant='subtitle2' gutterBottom={values.length !== 0} color="GrayText">{values.length === 0 ? `No ${attr.entity_name} Yet` : ``}</Typography>
                    {values.length > 0 && <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap">
                        {values.map( ( val, i ) => (
                            <Chip key={i} label={val} size='large' deleteIcon={Icons.default.CloseIcon} onDelete={() => { removeItem( i ) }} sx={{ border: "1px solid #d3d3d3", fontSize: "14px", fontWeight: "600" }} />
                        ) )}
                    </Box>}
                </Box>
            </Box>
        </Box>
    )
} )

export default MultipleValueArrayInput
