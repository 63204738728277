import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useStudentResumeFormStore } from '../../../store/studentresumeform.store'
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import MultipleValueArrayInput from './MultipleValueArrayInput'
import DateOrPresentInput from './DateOrPresentInput'
import CgpaOrPercentageInput from './CgpaOrPercentageInput'
import { useEffect } from 'react'
import DocumentUploadInput from './DocumentUploadInput'
import { toast } from 'react-toastify'
import { URL_PATTERN } from '../../../utils/resume-builder-utilities'

const AddEditDialog = observer( ( { section, mode, addMode, setAddMode, setSectionResponseJson, sectionResponseJson, saveChanges } ) => {
    const [values, setValues] = useState( {} )
    const [adding, setAdding] = useState( false )

    const StudentResumeFormStore = useStudentResumeFormStore()


    const handleFieldChange = ( type, key, value ) => {
        setValues( { ...values, [key]: value } )
    }

    const handleEmailFieldChange = ( attrkey, e ) => {
        try {
            StudentResumeFormStore.validateEmail( section, attrkey, e.target.value )
            handleFieldChange( "email", attrkey, e.target.value.trim() )
        } catch ( err ) { }
    }


    const handleUrlFieldChange = ( attrkey, e ) => {
        try {
            StudentResumeFormStore.validateURL( section, attrkey, e.target.value )
            handleFieldChange( "url", attrkey, e.target.value.trim() )
        } catch ( err ) { }
    }

    const handleUrlEithLabelFieldChange = ( attrkey, e ) => {
        StudentResumeFormStore.validateURL( section, attrkey, e.target.value )
        handleFieldChange( "url", attrkey, e.target.value.trim() )
    }

    const addDetail = async () => {
        try {
            setAdding( true )
            let files = []
            for ( let i = 0; i < section.attributes.length; i++ ) {
                const group = section.attributes[i]
                for ( let j = 0; j < group.length; j++ ) {
                    const attr = group[j]
                    if ( attr.entity_detail.data_type === 'linkwithlabel' ) {
                        if ( !values[attr.entity_detail.key] || values[attr.entity_detail.key]?.trim()?.length === 0 || !values[`${attr.entity_detail.key}-link`] || values[`${attr.entity_detail.key}-link`]?.trim()?.length === 0 ) {
                            toast( "Please fill all the details!" )
                            return
                        } else {
                            if ( !values[`${attr.entity_detail.key}-link`]?.trim()?.match( URL_PATTERN ) ) {
                                toast( "Please enter valid link!" )
                                return
                            }
                        }
                    } else if ( attr.entity_detail.data_type === "array" ) {
                        if ( !values[attr.entity_detail.key] || values[attr.entity_detail.key]?.length === 0 ) {
                            toast( "Please fill all the details!" )
                            return
                        }
                    } else if ( attr.entity_detail.data_type === 'select' ) {
                        if ( !values[attr.entity_detail.key] || values[attr.entity_detail.key]?.trim()?.length === 0 ) {
                            toast( "Please fill all the details!" )
                            return
                        }
                    }
                    else
                        if ( !values[attr.entity_detail.key] || values[attr.entity_detail.key]?.trim()?.length === 0 ) {
                            toast( "Please fill all the details!" )
                            return
                        }
                }
            }
            for ( const key in values ) {
                if ( Object.hasOwnProperty.call( values, key ) ) {
                    const value = values[key]
                    if ( value instanceof File ) {
                        files.push( { key, file: value } )
                        delete values[key]
                    }
                }
            }
            const promiseData = await Promise.all( files.map( item => StudentResumeFormStore.handleDocumentFieldChange( section, item.key, { target: { files: [item.file], value: '' } } ) ) )
            let updatedValues = Object.assign( values, ...promiseData )
            setSectionResponseJson( [...sectionResponseJson, updatedValues] )
            saveChanges( section.section_title, [...sectionResponseJson, updatedValues] )
            StudentResumeFormStore.setChangesMade( true )
            const initialValues = {}
            for ( let i = 0; i < section.attributes.length; i++ ) {
                const group = section.attributes[i]
                for ( let j = 0; j < group.length; j++ ) {
                    const attr = group[j]
                    if ( attr.entity_detail.data_type === "boolean" )
                        initialValues[attr.entity_detail.key] = false
                    else if ( attr.entity_detail.data_type === 'linkwithlabel' ) {
                        initialValues[attr.entity_detail.key] = ''
                        initialValues[`${attr.entity_detail.key}-link`] = ''
                    } else if ( attr.entity_detail.data_type === "array" )
                        initialValues[attr.entity_detail.key] = []
                    else if ( attr.entity_detail.data_type === "select" )
                        initialValues[attr.entity_detail.key] = ''
                    else
                        initialValues[attr.entity_detail.key] = ''
                }
            }
            await StudentResumeFormStore.save( { ...StudentResumeFormStore.getUpdatedResumeData, [section.section_title]: [...sectionResponseJson, values] } )
            setValues( initialValues )
            setAddMode( false )
        } catch ( err ) { }
        finally {
            setAdding( false )
        }
    }

    useEffect( () => {
        const setInitialValues = () => {
            const initialValues = {}
            for ( let i = 0; i < section.attributes.length; i++ ) {
                const group = section.attributes[i]
                for ( let j = 0; j < group.length; j++ ) {
                    const attr = group[j]
                    if ( attr.entity_detail.data_type === "boolean" )
                        initialValues[attr.entity_detail.key] = false
                    else if ( attr.entity_detail.data_type === 'linkwithlabel' ) {
                        initialValues[attr.entity_detail.key] = ''
                        initialValues[`${attr.entity_detail.key}-link`] = ''
                    }
                    else if ( attr.entity_detail.data_type === "array" )
                        initialValues[attr.entity_detail.key] = []
                    else if ( attr.entity_detail.data_type === "select" )
                        initialValues[attr.entity_detail.key] = ''
                    else
                        initialValues[attr.entity_detail.key] = ''
                }
            }
            setValues( initialValues )
        }
        setInitialValues()
    }, [section] )

    return (
        <Dialog open={addMode}>
            <DialogTitle sx={{ textTransform: "capitalize" }}>{`${mode} ${section.section_title}`}</DialogTitle>
            <Divider />
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: '10px' }} elevation={section.multiple ? 2 : 0}>
                    {section.attributes.map( ( group, groupIndex ) => (
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }} elevation={section.multiple ? 2 : 0} key={groupIndex}>
                            {/* eslint-disable-next-line */}
                            {group.map( ( attr, attrIndex ) => {
                                const type = attr.entity_detail.data_type
                                const children = attr.entity_detail.children
                                const attrkey = attr.entity_detail.key
                                if ( type === 'string' || type === 'number' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={values[attrkey] ? values[attrkey] : ""} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} type={type === 'string' ? "text" : "number"} rows={4} />
                                        </Box>
                                    )
                                } else if ( type === 'boolean' ) {
                                    return (
                                        <FormControl margin='dense' fullWidth sx={{ flexGrow: "1" }} key={attrIndex}>
                                            <FormControlLabel sx={{ marginLeft: "10px" }} control={<Checkbox color='primary' onChange={( e ) => handleFieldChange( type, attrkey, e.target.checked )} checked={values[attrkey] ? values[attrkey] : false} />} label={<Typography>{attr.entity_detail.label}</Typography>} />
                                        </FormControl>
                                    )
                                } else if ( type === 'email' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={values[attrkey] ? values[attrkey] : ""} size="small" onChange={( e ) => handleEmailFieldChange( attrkey, e )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} type="email" rows={4} />
                                            {StudentResumeFormStore.getEmailTypeErrors[`${section.section_title}-${attrkey}`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid E-mail </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'date' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer sx={{ width: "100%" }} components={['DatePicker']}>
                                                    <DatePicker
                                                        disableFuture
                                                        sx={{ width: "100%" }}
                                                        format='DD/MM/YYYY'
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        value={dayjs( values[attrkey] ? values[attrkey] : "" )}
                                                        onAccept={( e ) => {
                                                            handleFieldChange( type, attrkey, new Date( e.$d ).toLocaleDateString( 'en-US' ) )
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            <Typography variant="subtitle2" sx={{ fontStyle: "italic", fontSize: "12px" }} color="GrayText">Date selected: {values[attrkey] ? new Date( values[attrkey] ).toLocaleDateString( 'en-IN' ) : ""}</Typography>
                                        </Box>
                                    )
                                } else if ( type === 'url' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={values[attrkey] ? values[attrkey] : ""} size="small" onChange={( e ) => handleUrlFieldChange( attrkey, e )} variant='outlined' fullWidth multiline={attr.entity_detail.longText} rows={4} />
                                            {StudentResumeFormStore.getUrlTypeErrors[`${section.section_title}-${attrkey}`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid URL </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'linkwithlabel' ) {
                                    return (
                                        <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                            <TextField value={values[attrkey] ? values[attrkey] : ""} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth type="text" rows={4} />
                                            <InputLabel sx={{ marginTop: "10px" }}><Typography variant="subtitle2" color="GrayText">{attr.entity_detail.link_label}</Typography> </InputLabel>
                                            <TextField value={values[`${attrkey}-link`] ? values[`${attrkey}-link`] : ""} size="small" onChange={( e ) => handleUrlEithLabelFieldChange( `${attrkey}-link`, e )} variant='outlined' fullWidth type="text" rows={4} />
                                            {StudentResumeFormStore.getUrlTypeErrors[`${section.section_title}-${attrkey}-link`] && <FormHelperText sx={{ color: "error.dark", fonrSize: "12px", fontWeight: "500" }}> Enter valid URL </FormHelperText>}
                                        </Box>
                                    )
                                } else if ( type === 'cgpaorpercentage' ) {
                                    let fieldValue = values[attrkey] || ""
                                    if ( fieldValue !== "" ) {
                                        if ( fieldValue.includes( "%" ) ) {
                                            fieldValue = parseInt( fieldValue.replace( '%', "" ) )
                                        } else {
                                            fieldValue = parseInt( fieldValue.replace( ' CGPA', "" ) )
                                        }
                                    }
                                    return <CgpaOrPercentageInput key={attrIndex} handleFieldChange={handleFieldChange} saveChanges={saveChanges} attr={attr} attrkey={attrkey} value={fieldValue} />
                                } else if ( type === 'dateorpresent' ) {
                                    let fieldValue = values[attrkey] || ""
                                    if ( fieldValue !== "" ) {
                                        if ( fieldValue !== "present" )
                                            fieldValue = dayjs( fieldValue )
                                    }
                                    return <DateOrPresentInput key={attrIndex} handleFieldChange={handleFieldChange} saveChanges={saveChanges} attr={attr} attrkey={attrkey} value={fieldValue} />
                                } else if ( type === 'select' ) {
                                    return ( <Box sx={{ flexGrow: "1", width: "100%" }} key={attrIndex}>
                                        <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
                                        <Select value={values[attrkey] ? values[attrkey] : ''} size="small" onChange={( e ) => handleFieldChange( type, attrkey, e.target.value )} variant='outlined' fullWidth>
                                            <MenuItem value={""} disabled>Select {attr.entity_detail.label}</MenuItem>
                                            {children.map( child => (
                                                <MenuItem key={child} value={child}>{child}</MenuItem>
                                            ) )}
                                        </Select>
                                    </Box> )
                                } else if ( type === 'document' ) {
                                    return ( <DocumentUploadInput handleFieldChange={handleFieldChange} section={section} attr={attr} sectionResponseJson={values} attrkey={attrkey} key={attrIndex} /> )
                                }
                                else {
                                    return (
                                        <MultipleValueArrayInput saveChanges={saveChanges} section={section} sectionResponseJson={values} values={values[attrkey] ? values[attrkey] : []} setSectionResponseJson={setValues} key={attrIndex} attrkey={attrkey} attrIndex={attrIndex} attr={attr} />
                                    )
                                }
                            } )}
                        </Box>
                    ) )}
                </Box>
                <Box display="flex" alignItems="centre" gap="10px" marginTop="20px">
                    <Button sx={{ textTransform: "capitalize" }} disabled={adding} onClick={addDetail} disableElevation size='small' variant='contained'>
                        <Typography variant='subtitle2' display="flex" alignItems="center" gap="10px">{adding && <CircularProgress size={14} />}{adding ? `Adding ${section.section_title}` : `${mode} ${section.section_title}`}</Typography></Button>
                    <Button sx={{ textTransform: "capitalize" }} onClick={() => setAddMode( false )} size='small' variant='outlined' color='error' disabled={adding} >Cancel</Button>
                </Box>
            </DialogContent>
        </Dialog >
    )
} )

export default AddEditDialog
