import { Box, Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import noExamsImg from '../../../assets/noexams.svg'
import TableHeader from '../../common/TableHeader'
import React, { useCallback, useEffect, useState } from 'react'
import LineGraph from '../../common/LineBarGraph'
import api from '../../../service/api'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import { Link } from 'react-router-dom'

const columns = [
    { name: "Faculty", padding: "10px 20px 10px 40px" },
    { name: "Institute & DEpt", padding: "10px 20px" },
    { name: "Exams", padding: "10px 20px", align: "center" },
    { name: "Participation", align: "center", padding: "10px 40px 10px 20px" },
]

const ExamsLast30Details = () => {

    const [tableData, setTableData] = useState( [] )
    const [graphData, setGraphData] = useState( [] )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const { table } = useTheme()

    const getData = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data: stats } } = await api.audit.getExamsStats( { month: "last30days" } )
            const { data: { data: graphData } } = await api.audit.getExamGraphData()
            setError( null )
            setGraphData( graphData )
            setTableData( stats )
        } catch ( err ) {
            setError( err?.response?.data?.message || err?.response?.data || "Something went wrong while getting the exams stats." )
        } finally {
            setLoading( false )
        }
    }, [] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Card sx={{ flexShrink: 0, maxHeight: "450px", minHeight: "400px", display: "flex", flexDirection: "column" }}>
            {!loading && tableData.length > 0 && <Box padding="10px" flexGrow={1} overflow="auto" alignItems="stretch" display="grid" gap='20px' gridTemplateColumns={{ md: "1fr 1fr", xs: "1fr" }}>
                <Box display="flex" overflow="auto" flexGrow={1} flexDirection="column">
                    <Typography padding="20px" fontSize="16px" fontWeight="bold" color="primaryDark.main">Last 30 days exams stats</Typography>
                    <TableContainer sx={{ overflow: "auto" }} className='slim-custom-scrollbar' >
                        <Table stickyHeader>
                            <TableHeader fontSize="14px" columns={columns} />
                            <TableBody>
                                {tableData.map( ( row, index ) => (
                                    <TableRow className='table-row' key={index}>
                                        <TableCell sx={{ fontSize: "12px", color: table.color, padding: columns[0].padding || "10px 20px" }}>
                                            <Typography sx={{ fontSize: "12px", cursor: "pointer", '&:hover': { textDecoration: "underline" } }} ><Link className='default-link' to={`/audit/faculty/${row.created_by}`} ><strong>{row.created_by_data?.employee_name}</strong></Link></Typography>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px", color: table.color, padding: columns[1].padding || "10px 20px", textTransform: "capitalize" }} align={columns[1].align || "left"}><strong>{row.created_by_data?.institute_name_short} - {row.created_by_data?.branch_name?.toLowerCase()}</strong></TableCell>
                                        <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", padding: "10px 20px", color: table.color }}><strong>{row['count']}</strong></TableCell>
                                        <TableCell align={columns[3].align || "left"} sx={{ fontSize: "12px", color: table.color, padding: columns[2].padding || "10px 20px" }}><strong>{row?.attempts}</strong></TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <LineGraph barColor='#ffaa00' lineColor='#642547' graphTitle="Last 30 days activities" showMenuBar={false} barLegend='Exam attempts' yAxisName="Exams" xAxisName="Date" y2AxisName="Attempts" lineLegend='Exams created' barValuesKey="attempts" barData={graphData} dateformat={"%Y-%m-%d"} xAxisValuesKey={'date'} lineValuesKey={'exams'} lineData={graphData} />
            </Box >}
            {!loading && !error &&
                tableData.length === 0 && <Box flexGrow={1} flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" gap="20px">
                    <img src={noExamsImg} height="120px" alt="No exams in last 30 days" />
                    <Typography textAlign="center" variant='subtitle2'>No exams were created in last 30 days</Typography>
                </Box>
            }
            {loading && !error && <Box display="flex" flexGrow={1} justifyContent="center">
                <DataLoadingSpinner waitingMessage="Loading last 30 days exams activities..." />
            </Box>}
            {error && <Box display="flex" flexGrow={1} justifyContent="center">
                <TryAgainMessageBlock err={error} getData={getData} loading={loading} />
            </Box>}
        </Card >
    )
}

export default ExamsLast30Details
