import DownloadStatusListDialog from '../../study-material-components/DownloadStatusListDialog'
import { Box, Button, Dialog, DialogContent, Typography, useTheme } from '@mui/material'
import { useLayoutContext } from '../../../contexts/layout.context'
import { useSettingsStore } from '../../../store/settings.store'
import sessionEndedImg from '../../../assets/waiting.svg'
import { useAuthStore } from '../../../store/auth.store'
import { Outlet, useNavigate } from 'react-router-dom'
import { LoginOutlined } from '@mui/icons-material'
import SideDrawer from './PresenterSideDrawer'
import { useCallback, useEffect, useState } from 'react'
import Navbar from './PresenterNavbar'
import React from 'react'
import { observer } from 'mobx-react'
import ResourceUploadStatusDialog from '../../live-session/ResourceUploadStatusDialog'

const openedSidebarWidth = 300

const PresenterLayout = observer( ( { navHeadText } ) => {

    const { setSecondarySidebarStatus, active, focusedMenuItem, setSidebarWidth, setMenuState, navHeight, minSidebarCollapseWidth } = useLayoutContext()
    const [showMaintenanceInfo, setShowMaintenanceInfo] = useState( false )
    const [maintenanceMessage, setMaintenanceMessage] = useState( "" )

    const handleResizeOrLoad = useCallback( () => {
        if ( window.innerWidth < minSidebarCollapseWidth )
            setMenuState( false )
        else {
            setMenuState( true )
            if ( focusedMenuItem?.children ) {
                setSidebarWidth( openedSidebarWidth )

                if ( active.match( /^(\/student\/subject\/\d+\/?)\/?$/ ) ) {
                    setSecondarySidebarStatus( false )
                }
            } else {

                if ( active.match( /^(\/student\/subject\/\d+\/?)\/?$/ ) ) {
                    setSecondarySidebarStatus( true )
                }
                else {
                    setSecondarySidebarStatus( false )
                }
            }
        }
    }, [minSidebarCollapseWidth, active, focusedMenuItem, setSecondarySidebarStatus, setSidebarWidth, setMenuState] )

    const SettingsStore = useSettingsStore()
    const AuthStore = useAuthStore()
    const navigate = useNavigate()
    const { palette } = useTheme()

    useEffect( () => {
        if ( Boolean( SettingsStore.getUseSettings['enable_maintenance'] && SettingsStore.getUseSettings['maintenance_start_date'] && SettingsStore.getUseSettings['maintenance_start_date'].match( /^(\d{2}-\d{2}-\d{4})$/ ) && SettingsStore.getUseSettings['maintenance_start_time'] && SettingsStore.getUseSettings['maintenance_start_time'].match( /^(\d{2}:\d{2})$/ ) ) ) {
            const today = new Date().toLocaleDateString().replace( /\//g, "-" )
            const startDate = new Date( SettingsStore.getUseSettings['maintenance_start_date'] ).toLocaleDateString().replace( /\//g, "-" )
            const endDiff = new Date() < new Date( `${SettingsStore.getUseSettings['maintenance_end_date']},${SettingsStore.getUseSettings['maintenance_end_time']}` )
            if ( today === startDate && endDiff ) {
                let message = `Please note that this platform will be under maintenance from ${SettingsStore.getUseSettings['maintenance_start_date']}, ${SettingsStore.getUseSettings['maintenance_start_time']} to ${SettingsStore.getUseSettings['maintenance_end_date']}, ${SettingsStore.getUseSettings['maintenance_end_time']}`
                setShowMaintenanceInfo( true )
                setMaintenanceMessage( message )
            }
        }
    }, [SettingsStore] )

    useEffect( () => {
        window.addEventListener( 'resize', handleResizeOrLoad )
        window.addEventListener( 'load', handleResizeOrLoad )
        return () => {
            window.removeEventListener( 'resize', handleResizeOrLoad )
            window.removeEventListener( 'load', handleResizeOrLoad )
        }
    }, [handleResizeOrLoad] )

    return (
        <Box display="flex" height="100%" bgcolor={palette.layoutBackground} overflow="auto">
            <SideDrawer closeSideMenu={() => setMenuState( false )} />
            <Box
                flexGrow={1}
                display="flex"
                minWidth="0"
                position="relative"
            >
                <Navbar navHeadText={navHeadText} closeSideMenu={() => setMenuState( false )} openSideMenu={() => setMenuState( true )} />
                {AuthStore.getErpSessionState !== "ended" &&
                    <Box
                        flexGrow={1}
                        display="flex"
                        maxHeight="100%"
                        id="page-content"
                        paddingTop={`${navHeight}px`}
                        minWidth="0"
                    >
                        <Outlet />
                    </Box>}
            </Box>
            <Dialog open={AuthStore.getErpSessionState === "ended"}>
                <DialogContent >
                    <Box display="flex" alignItems="center" gap="20px" flexDirection="column">
                        <img width="200px" src={sessionEndedImg} alt="Session ended!" />
                        <Typography textAlign="center" variant='subtitle2'>Your ERP session seems to have ended! did you log in using ERP credentials somewhere else? please login again.</Typography>
                        <Button onClick={() => navigate( `/?redirect=${window.location.pathname}` )} startIcon={<LoginOutlined fontSize='small' />} variant='outlined' sx={{ textTransform: "capitalize" }}>Sign In</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {showMaintenanceInfo && <Typography textAlign="center" padding="0 20px" sx={{ position: "fixed", bottom: 0, right: 0, left: 0, background: palette.warning.main }} fontSize="14px" >{maintenanceMessage}</Typography>}
            <DownloadStatusListDialog />
            <ResourceUploadStatusDialog />
        </Box>
    )
} )

export default PresenterLayout
