import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import TableHeader from '../../components/common/TableHeader'
import noBatches from '../../assets/no-batches.svg'
import { Icons } from '../../utils/utilities'
import api from '../../service/api'
import AddNewBatch from '../../components/temporary-users/AddNewBatch'
import ConfirmDialog from '../../components/common/ConfirmDialog'
import { toast } from 'react-toastify'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'
import TemporaryBatchDetails from '../../components/temporary-users/TemporaryBatchDetails'

const columns = [
    { label: "Sl.No.", align: "center", padding: "10px 20px 10px 40px" },
    { label: "Batch Name", align: "left" },
    { label: "Number of users", align: "center" },
    { label: "Actions", align: "right", padding: "10px 40px 10px 20px" },
]

const TableRowComponent = ( { index, batch, getData } ) => {

    const [deleting, setDeleting] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )
    const [viewDetails, setViewDetails] = useState( false )

    const { palette, table } = useTheme()

    const deleteBatch = async () => {
        setDeleting( true )
        try {
            await api.temporaryUsers.deleteBatch( batch.id )
            toast( <span><strong><em>{batch.batch_name}</em></strong> successfully deleted.</span> )
            await getData()
            setDeleteDialogState( false )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong while deleting the batch." )
        } finally {
            setDeleting( false )
        }
    }

    return (
        <TableRow sx={{ cursor: "pointer", '&:hover .show-on-default': { display: "flex" }, '&:hover': { background: palette.rowHover } }} key={batch.id}>
            <ConfirmDialog message={<Typography fontSize="14px" fontWeight="300">All users associated with <strong><em>{batch.batch_name}</em></strong> will be removed!</Typography>} status={deleteDialogState} actionName="Yes! delete it" cancelAction={() => setDeleteDialogState( false )} confirmAction={deleteBatch} waiting={deleting} />
            <TemporaryBatchDetails openState={viewDetails} setOpenState={setViewDetails} batch={batch} />
            <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[0].padding || "10px 20px" }} align={columns[0].align || "left"} ><strong>{index + 1}</strong></TableCell>
            <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[1].padding || "10px 20px" }} align={columns[1].align || "left"} ><strong>{batch.batch_name}</strong></TableCell>
            <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[2].padding || "10px 20px" }} align={columns[2].align || "left"} ><strong>{batch.number_of_users}</strong></TableCell>
            <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[3].padding || "10px 20px" }} align={columns[3].align || "right"} >
                <Box className="show-on-default" display="flex" alignItems="center" justifyContent="flex-end">
                    <IconButton onClick={() => setViewDetails( true )} size='small' color="primary">
                        {Icons.small.OpenInNewIcon}
                    </IconButton>
                    <IconButton onClick={() => setDeleteDialogState( true )} size='small' color="errorMessage">
                        {Icons.small.DeleteIcon}
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    )
}

const TemporaryBatches = () => {

    const [error, setError] = useState( null )
    const [batches, setBatches] = useState( [] )
    const [loading, setLoading] = useState( true )
    const [newBatchDialogState, setNewBatchDialogState] = useState( false )

    const { palette } = useTheme()

    const getBatches = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data: batches } } = await api.temporaryUsers.fetchBatches()
            setBatches( batches )
        } catch ( err ) {
            setError( { code: err?.response.status, message: err?.response?.data?.message || "Something went wrong while fetching batches" } )
        } finally {
            setLoading( false )
        }
    }, [] )

    useEffect( () => {
        getBatches()
    }, [getBatches] )

    return (
        <Box flexGrow={1} padding="20px" overflow="auto">

            <AddNewBatch getBatches={getBatches} openState={newBatchDialogState} setOpenState={setNewBatchDialogState} />

            <Paper sx={{ overflow: "hidden", display: "flex", flexDirection: "column" }}>
                <Box padding="20px" borderBottom="1px solid #d3d3d3">
                    <Box display="flex" gap="20px" justifyContent="space-between">
                        <Box>
                            <Typography variant='h5'>Temporary Batches and Users</Typography>
                            <Typography variant='subtitle2' color="textSecondary">Manage temporary users</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Button onClick={() => setNewBatchDialogState( true )} disableElevation sx={{ textTransform: "capitalize" }} variant="contained" startIcon={Icons.default.AddIcon} color="primary">
                                Add new batch
                            </Button>
                        </Box>
                    </Box>
                </Box>
                {!loading && !error && batches.length > 0 && <Box>
                    <TableContainer>
                        <Table>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {batches.map( ( batch, index ) => (
                                    <TableRowComponent getData={getBatches} key={batch.id} index={index} batch={batch} />
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {!loading && !error && batches.length === 0 && <Box padding="20px" display="flex" flexDirection="column" bgcolor={palette.contentBg} margin="10px" borderRadius="5px" alignItems="center" justifyContent="center">
                    <img src={noBatches} width="200px" alt="No batches!" />
                    <Typography marginY="20px" variant='subtitle2' color="textSecondary">No batches!</Typography>
                    <Button onClick={() => setNewBatchDialogState( true )} variant='outlined' sx={{ fontSize: "12px", marginBottom: "20px", textTransform: "capitalize" }} color="secondary" startIcon={Icons.default.AddIcon}>Create new batch</Button>
                </Box>}
                {loading && !error && <DataLoadingSpinner waitingMessage="Loading batches..." />}
                {error && <TryAgainMessageBlock err={error.message} code={error.code} getData={getBatches} loading={loading} />}
            </Paper>
        </Box>
    )
}

export default TemporaryBatches
