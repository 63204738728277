import { Avatar, Box, Button, Card, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../service/api'
import { showDateInIndianDateFormat } from '../../../utils/utilities'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import noAssignmentImg from '../../../assets/noassignment.svg'
import AuditDiscussionViewer from './AuditDiscussionViewer'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'

const AuditDiscussionsList = () => {


    const [searchParams, setSearchParams] = useSearchParams( { searchText: "", month: "", selectedInstitute: "all", view: "tabular", sortByName: "1", sortBySubmissionsCount: "1", sortByAssignmentCount: "1" } )
    const searchText = searchParams.get( 'searchText' ) || ""
    const selectedInstitute = searchParams.get( 'selectedInstitute' ) || "all"

    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [discussions, setDiscussions] = useState( [] )
    const [selectedDiscussion, setSelectedDiscussion] = useState( null )

    const { border, palette } = useTheme()

    const navigate = useNavigate()


    const filterFunction = item => {
        const searchValue = new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' )
        return item?.created_by_data?.branch_name?.match( searchValue ) || item.faculty_name?.match( searchValue ) || item.created_by?.match( searchValue )
    }

    const removeAppliedFilters = () => {
        setSearchParams( prev => {
            prev.set( 'searchText', '' )
            prev.set( 'selectedInstitute', '-' )
            prev.set( 'selectedDepartment', '-' )
            return prev
        } )
    }

    const handleDialogClose = () => {
        setSelectedDiscussion( null )
    }

    const getData = useCallback( async () => {
        try {
            setLoading( true )
            const { data: { data } } = await api.audit.getDiscussionsForAuditing( { institute: selectedInstitute !== "all" ? selectedInstitute : "" } )
            setDiscussions( data )
            setError( null )
        } catch ( err ) {
            console.log( err )
            setError( { code: err?.response?.status, essage: err?.message?.data?.message || "Something went wrong while fetching discussions." } )
        } finally {
            setLoading( false )
        }
    }, [selectedInstitute] )

    useEffect( () => {
        getData()
    }, [getData] )


    return (
        <Card sx={{ flexShrink: 0, paddingBottom: "20px", display: "flex", flexDirection: "column" }}>

            {selectedDiscussion && <AuditDiscussionViewer discussion={selectedDiscussion} handleClose={handleDialogClose} openState={Boolean( selectedDiscussion )} />}

            <Box padding="20px" display="flex" gap="20px" justifyContent="space-between" >
                <Typography color="primaryDark.main" fontSize="16px" variant='h6'>Discussions</Typography>
                <Button size="small" onClick={() => navigate( "/audit/discussions" )} variant="contained" disableElevation sx={{ height: "33.5px", textTransform: "capitalize", background: "#FFE0E0", border: `1px solid #FF6C8F`, color: "#FF736F", '&:hover': { background: "#FFE0E0", color: "#FF736F" } }} >View Stats</Button>
            </Box >

            {!error && !loading && discussions.filter( filterFunction ).length > 0 && <Box borderRadius="10px" overflow="hidden">
                <Box display="flex" overflow="auto" className="custom-scrollbar" maxHeight="500px" flexDirection="column" gap="10px" bgcolor={palette.contentBg} margin="0 20px">
                    {discussions.filter( filterFunction ).map( ( row, index ) => {
                        return <Box alignItems="flex-start" sx={{ cursor: "pointer" }} onClick={() => setSelectedDiscussion( row )} borderBottom={border[1]} padding="10px 10px 15px 10px" bgcolor={palette.contentBg} display="flex" gap="10px" key={row._id}>
                            <Box marginTop="5px">
                                <Tooltip title={row.creator_data.name}>
                                    <Avatar sx={{ width: "30px", height: "30px" }} src={row.creator_data?.user_profile_image_url} >{row.creator_data.name[0]}</Avatar>
                                </Tooltip>
                            </Box>
                            <Box>
                                <Typography variant='h6' fontSize="16px">{row.title}</Typography>
                                <Typography variant='subtitle2' color="textSecondary" fontSize="12px">@{row.creator_data.name}, On: {showDateInIndianDateFormat( row.created_at, { month: "short" } )}</Typography>
                            </Box>
                        </Box>
                    } )}
                </Box>
            </Box>}
            {!loading && !error && discussions.filter( filterFunction ).length === 0 && <Box flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" gap="20px">
                <img src={noAssignmentImg} height="120px" alt="No assignmets to show" />
                <Typography textAlign="center" variant='subtitle2'>No discussions were found for applied filters!</Typography>
                <Button onClick={removeAppliedFilters} sx={{ textTransform: "capitalize" }} color="secondary" variant="contained" disableElevation>Remove filters</Button>
            </Box>}
            {loading && !error && <DataLoadingSpinner waitingMessage="Loading discussions please wait...." />}
            {error && <TryAgainMessageBlock err={error.message} code={error.code} loading={loading} getData={getData} />}
        </Card>
    )
}

export default AuditDiscussionsList
