import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Icons } from '../../utils/utilities'
import { Link } from 'react-router-dom'
import TableHeader from '../common/TableHeader'
import { useDownloadStatusListStore } from '../../store/downloadstatuslistdialog.store'

const columns = [
    { name: "Material Name", align: "left", minWidth: "200px", padding: "10px 20px 10px 40px" },
    { name: "Created By", align: "left", minWidth: "200px" },
    { name: "Subject-Code", align: "right", minWidth: "200px" },
    { name: "Action", align: "right", minWidth: "200px", padding: "10px 40px 10px 20px" }
]

const StudentMaterialList = ( { docsCount, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, filteredMaterials } ) => {

    const { palette, table, border } = useTheme()

    const DownloadStatusListStore = useDownloadStatusListStore()

    const downloadMaterial = async ( id, data ) => {
        await DownloadStatusListStore.downloadMaterial( id, data )
    }


    return (
        <Box position="relative" margin="20px" borderRadius="5px" bgcolor={palette.contentBg} border={border[1]} flexGrow={1} overflow="auto" display="flex" flexDirection="column" >
            <TableContainer className='custom-scrollbar' sx={{ overflow: "auto", height: "100%" }}>
                <Table stickyHeader>
                    <TableHeader columns={columns} />
                    <TableBody>
                        {filteredMaterials
                            .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                            .map( material => (
                                <TableRow className="table-row" sx={{ cursor: "pointer", "&:hover": { background: `${palette.rowHover} !important` } }} key={material.study_material_id}>
                                    <TableCell sx={{ color: table.color, padding: "0", fontWeight: "bold", width: "100%", display: "table-cell", alignItems: "center", gap: "10px" }} align='left'>
                                        <Link className='default-link' to={`/student/material/${material.study_material_id}`}>
                                            <Box padding={columns[0].padding || "10px 20px"} display="flex" gap="10px" alignItems="center">
                                                {Icons.default.Material} <Typography variant="subtitle2" ><strong>{material.study_material_name}</strong></Typography>
                                            </Box>
                                        </Link> </TableCell>
                                    <TableCell sx={{ color: table.color, padding: "0" }} align='left'>
                                        <Link to={`/student/material/${material.study_material_id}`} className='default-link'>
                                            <Typography fontSize="14px" padding={columns[2].padding || "10px 20px"}><strong>{material.study_material_created_by}</strong></Typography>
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ color: table.color, padding: "0" }} align='right'>
                                        <Link to={`/student/material/${material.study_material_id}`} className='default-link'>
                                            <Typography fontSize="14px" padding={columns[2].padding || "10px 20px"}><strong>{material.subject_name}-{material.subject_code}</strong></Typography>
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ color: table.color, padding: columns[3].padding || "10px 20px" }} align='right'>
                                        <IconButton size="small" onClick={() => downloadMaterial( material.study_material_id, material )}>
                                            {Icons.default.DownloadIconOutlined}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ) )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                sx={{ background: table.headerBg, position: "sticky", bottom: 0, minHeight: "70px" }}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredMaterials.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}

export default StudentMaterialList
