import { Box, Button, Paper, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React from 'react'
import { useState } from 'react'
import noFeedbacksImg from '../../../assets/nofeedback.svg'
import api from '../../../service/api'
import FeedbackTable from '../../../components/admin/FeedbackTable'
import { useEffect } from 'react'
import { useUserStore } from '../../../store/user.store'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import BlockMessage from '../../../components/common/BlockMessage'

const AdminSessionFeedback = () => {

    const [startDate, setStartDate] = useState( new Date() )
    const [endDate, setEndDate] = useState( new Date() )
    const [feedbacks, setFeedbacks] = useState( [] )
    const [isLoading, setIsLoading] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )

    const UserStore = useUserStore()

    const searchFeedbacks = async () => {
        setIsLoading( true )
        try {
            const res = await api.fetchFeedbacks( { startDate, endDate } )
            setFeedbacks( res.data.data )
            console.log( res.data.data )
        } catch ( err ) {
            console.log( err )
        } finally {
            setIsLoading( false )
        }
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.feedback ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        hasPermission ? <Box padding="20px" overflow={isLoading || ( feedbacks.length === 0 && !isLoading ) ? "auto" : "hidden"} flexGrow={1} display="flex" flexDirection="column">
            <Paper sx={{ flexGrow: feedbacks.length > 0 && !isLoading ? "1" : "initial" }}>
                <Box display="flex" flexDirection="column" gap="10px" padding="20px" borderBottom="1px solid #d3d3d3">
                    <Box><Typography variant='h5'>Feedbacks</Typography>
                        <Typography color="textSecondary" variant='subtitle2'>Select date range to view live classes feedbacks</Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                        <Box >
                            <Box >
                                <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap="20px">
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DatePicker
                                                margin='normal'
                                                variant='inline'
                                                sx={{ width: '100%', fontSize: "10px !important" }}
                                                label="Start date"
                                                value={dayjs( startDate )}
                                                format='DD-MM-YYYY'
                                                onChange={setStartDate}
                                            // onAccept={( e ) => { setFieldValue( 'startDateTime', e.$d ) }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DatePicker
                                                margin='normal'
                                                variant='inline'
                                                sx={{ width: '100%', fontSize: "10px !important" }}
                                                label="End date"
                                                value={dayjs( endDate )}
                                                format='DD-MM-YYYY'
                                                onChange={setEndDate}
                                            // onAccept={( e ) => { setFieldValue( 'startDateTime', e.$d ) }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box>
                                        <Button disableElevation fullWidth variant="contained" color="primary" sx={{ height: "53px" }} onClick={searchFeedbacks} >
                                            Search
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {feedbacks.length === 0 && !isLoading &&
                    <Box bgcolor="#eee" sx={{ padding: "20px", flexGrow: 1, textAlign: "center", height: "fit-content" }}>
                        <img width="200px" src={noFeedbacksImg} alt="No feedbacks!" />
                        <Typography color="textSecondary" marginTop="20px" variant='subtitle2'>No feedbacks for selected date range!</Typography>
                    </Box>
                }
                {feedbacks.length > 0 && !isLoading &&
                    <FeedbackTable rows={feedbacks} />
                }
                {isLoading && <DataLoadingSpinner waitingMessage="Loading sessions..." />}
            </Paper>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>

    )
}

export default AdminSessionFeedback
