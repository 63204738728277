import React, { useCallback, useEffect, useState } from 'react'
import { useOnlineCourseStore } from '../../store/onlinecourse.store'
import { Box, Button, Typography, useTheme } from '@mui/material'
import CustomSearchBar from '../common/CustomSearchBar'
import noCoursesImg from '../../assets/no-courses.svg'
import { Icons, convertToCDNLink, defaultOnlineCourseThumbs, flatLightColors } from '../../utils/utilities'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { Link, useSearchParams } from 'react-router-dom'
import { useSubjectStore } from '../../store/subject.store'
import api from '../../service/api'
import { toast } from 'react-toastify'

const SubjectOnlineCourses = () => {

    const [courseProgresses, setCourseProgresses] = useState( {} )
    const [loading, setLoading] = useState( true )

    const [searchParams, setSearchParams] = useSearchParams( { searchText: "", courseFilter: "all" } )
    const courseFilter = searchParams.get( 'courseFilter' )
    const searchText = searchParams.get( 'searchText' )
    const { palette } = useTheme()

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const SubjectStore = useSubjectStore()
    const OnlineCourseStore = useOnlineCourseStore()

    const getOnlineCourse = useCallback( async () => {
        await OnlineCourseStore.fetchSubjectCourses( SubjectStore.getSubject.subject_code )
        await OnlineCourseStore.fetchEnrolledCourses()
        try {
            const { data } = await api.getEnrolledCoursesProgress()
            let progresses = {}
            for ( let i = 0; i < data.data.length; i++ ) {
                const progress = data.data[i]
                progresses[progress.online_course_id] = progress
            }
            setCourseProgresses( progresses )
        } catch ( err ) { }

        setLoading( false )
    }, [OnlineCourseStore, SubjectStore] )


    const navigateToCourse = async ( onlineCourse ) => {
        let role = 'student'
        try {
            if ( onlineCourse.status === "ENROLLED" ) {
                await api.startCourse( onlineCourse.online_course_id )
                window.open( `/${role}/online-course/${onlineCourse.online_course_id}/learn/${onlineCourse.progress_id}` )
            } else {
                window.open( `/${role}/online-course/${onlineCourse.online_course_id}/learn/${onlineCourse.progress_id}` )
            }
        } catch ( err ) {
            console.log( err )
            toast( "Something went wrong! try again later" )
        }
    }

    useEffect( () => {
        const getData = () => {
            getOnlineCourse()
        }
        getData()
    }, [getOnlineCourse] )

    return (
        <Box flexGrow={1} display="flex" flexDirection="column">
            <Box borderBottom="1px solid #d3d3d3" display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="10px">
                <Typography variant="h6">Online Courses</Typography>
                <CustomSearchBar placeholder='Search courses...' />
            </Box>

            <Box borderBottom="1px solid #d3d3d3" display="flex" alignItems="center" gap="10px" padding="10px">
                <Typography width="100px" textAlign="center" onClick={() => setSearchParamValue( 'courseFilter', 'all' )} sx={{ cursor: "pointer", borderRadius: "3px", "&:hover": { background: "#eee" } }} padding="3px" bgcolor={courseFilter === "all" ? `${palette.primary.light}22` : "#fff"} variant="subtitle2">All</Typography>
                <Typography width="100px" textAlign="center" onClick={() => setSearchParamValue( 'courseFilter', 'enrolled' )} sx={{ cursor: "pointer", borderRadius: "3px", "&:hover": { background: "#eee" } }} padding="3px" bgcolor={courseFilter === "enrolled" ? `${palette.primary.light}22` : "#fff"} variant="subtitle2">Enrolled</Typography>
            </Box>

            {courseFilter === "all" && <Box>
                {!loading && ( OnlineCourseStore.getCourses || [] ).length > 0 && <Box display="flex" padding="20px" gap="10px">
                    {OnlineCourseStore.getCourses.filter( c => c.subject_code.match( new RegExp( SubjectStore.getSubject.subject_code, 'i' ) ) ).filter( c => c.title.toLowerCase().includes( searchText.toLowerCase() ) ).map( ( onlineCourse, k ) => (
                        <Link key={onlineCourse.id} className='default-link' target={"_blank"} to={`/student/online-course/${onlineCourse.id}`}>
                            <Box minWidth="220px" maxWidth="220px" key={onlineCourse.id} border="1px solid #d3d3d3" sx={{ cursor: "pointer", "&:hover": { boxShadow: "1px 0 5px 1px rgba(0,0,0,0.1)" } }} flexDirection="column" display="flex">
                                <Box height="100px" width="auto">
                                    <img src={onlineCourse?.thumbnail_uri.includes( 'http' ) ? convertToCDNLink( onlineCourse?.thumbnail_uri ) : defaultOnlineCourseThumbs[parseInt( onlineCourse?.thumbnail_uri )]} style={{ objectFit: "cover" }} width="100%" height="100%" loading='lazy' alt={onlineCourse.title} />
                                </Box>
                                <Box bgcolor={flatLightColors[+onlineCourse.id % flatLightColors.length]} padding="10px" borderTop="none">
                                    <Typography marginTop="" fontWeight="600" fontSize="16px" lineHeight="1" sx={{ "WebkitLineClamp": 2, "WebkitBoxOrient": "vertical", display: "-webkit-box !important" }} gutterBottom overflow="hidden" textOverflow="ellipsis" whiteSpace="normal">{onlineCourse.title}</Typography>
                                    <Typography marginTop="-5px" color="textSecondary" variant='subtitle2' textTransform="capitalize" >{onlineCourse.author}</Typography>
                                    <Typography marginTop="-5px" color="textSecondary" variant='subtitle2' >Published On: {new Date( onlineCourse.created_at ).toLocaleDateString( 'IN-en' ).replace( /\//g, "-" )}</Typography>
                                </Box>
                            </Box>
                        </Link>
                    ) )}
                </Box>}
                {!loading && ( OnlineCourseStore?.getCourses || [] ).length === 0 &&
                    <Box flexGrow={1} display="flex" alignItems="center" padding="20px" flexDirection="column" gap="10px" justifyContent="center" >
                        <img src={noCoursesImg} width="200px" alt="No Exams" />
                        <Typography variant="subtitle2" >No courses created for <em> <strong>'{SubjectStore.getSubject.subject_name}'</strong> </em> </Typography>
                    </Box>
                }
            </Box>}
            {courseFilter === "enrolled" && <Box>
                {!loading && ( OnlineCourseStore.getEnrolledCourses || [] ).length > 0 && <Box display="flex" padding="20px" gap="10px">
                    {OnlineCourseStore.getEnrolledCourses.filter( c => c.title.toLowerCase().includes( searchText.toLowerCase() ) ).map( ( onlineCourse, k ) => (
                        <Box key={onlineCourse.id}>
                            <Box minWidth="220px" maxWidth="220px" key={onlineCourse.id} border="1px solid #d3d3d3" sx={{ cursor: "pointer", "&:hover": { boxShadow: "1px 0 5px 1px rgba(0,0,0,0.1)" } }} flexDirection="column" display="flex">
                                <Box height="100px" width="auto">
                                    <img src={onlineCourse?.thumbnail_uri.includes( 'http' ) ? convertToCDNLink( onlineCourse?.thumbnail_uri ) : defaultOnlineCourseThumbs[parseInt( onlineCourse?.thumbnail_uri )]} style={{ objectFit: "cover" }} width="100%" height="100%" loading='lazy' alt={onlineCourse.title} />
                                </Box>
                                <Box bgcolor={flatLightColors[+onlineCourse.id % flatLightColors.length]} padding="10px" borderTop="none">
                                    <Typography marginTop="" fontWeight="600" fontSize="16px" lineHeight="1" sx={{ "WebkitLineClamp": 2, "WebkitBoxOrient": "vertical", display: "-webkit-box !important" }} gutterBottom overflow="hidden" textOverflow="ellipsis" whiteSpace="normal">{onlineCourse.title}</Typography>
                                    <Typography marginTop="-5px" color="textSecondary" variant='subtitle2' textTransform="capitalize" >{onlineCourse.author}</Typography>
                                    <Typography marginTop="-5px" color="textSecondary" variant='subtitle2' >Published On: {new Date( onlineCourse.created_at ).toLocaleDateString( 'IN-en' ).replace( /\//g, "-" )}</Typography>
                                    <Typography padding="5px 0" marginTop="5px" variant='subtitle2' position="relative" sx={{
                                        "&::after": { content: "''", position: "absolute", zIndex: 10, height: "3px", background: "#273679", left: 0, top: 0, width: `${courseProgresses[onlineCourse.id]?.progress_percentage || 0}%` },
                                        "&::before": { content: "''", position: "absolute", zIndex: 10, height: "3px", background: "#aaa", left: 0, top: 0, width: `100%` }
                                    }}>
                                        {onlineCourse.progress_percentage || 0}% progress
                                    </Typography>
                                    <Typography fontSize="12px" variant='subtitle2' color="textSecondary">
                                        {onlineCourse.status}
                                    </Typography>
                                    <Button variant='outlined' fullWidth sx={{ marginTop: "10px", textTransform: "capitalize" }} onClick={() => navigateToCourse( onlineCourse )} endIcon={Icons.default.KeyBoardArrowRight}>Continue</Button>
                                </Box>
                            </Box>
                        </Box>
                    ) )}
                </Box>}
                {!loading && ( OnlineCourseStore?.getEnrolledCourses || [] ).length === 0 &&
                    <Box flexGrow={1} display="flex" alignItems="center" padding="20px" flexDirection="column" gap="10px" justifyContent="center" >
                        <img src={noCoursesImg} width="200px" alt="No Exams" />
                        <Typography variant="subtitle2" >You have not enrolled to any online courses related to <em> <strong>'{SubjectStore.getSubject.subject_name}'</strong> </em> </Typography>
                    </Box>
                }
            </Box>}
            {loading && <DataLoadingSpinner waitingMessage="Loading courses pleas wait..." />}
        </Box>
    )
}

export default SubjectOnlineCourses
