import { Typography, Box, Table, TableContainer, TableRow, TableCell, TableBody, Button, CircularProgress, IconButton, MenuItem, Tooltip, TablePagination, Paper, useTheme, Menu } from '@mui/material'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import TableHeader from '../../../components/common/TableHeader'
import { exportExamParticipation } from '../../../utils/exam-utilities'
import noParticipationImg from '../../../assets/no-participation.svg'
import BlockMessage from '../../../components/common/BlockMessage'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Icons } from '../../../utils/utilities'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import BreadcrumbList from '../../../components/common/BreadcrumbList'
import { Upload } from '@mui/icons-material'
import SubmitOrEditRemarkDialog from '../../../components/exams-components/SubmitOrEditRemarkDialog'

export default function ExamParticipation() {

    const { id } = useParams()
    const [participations, setParticipations] = useState( null )
    const [totalMarks, setTotalMarks] = useState( 0 )
    const [inProgress, setInProgress] = useState( true )
    const [exam, setExam] = useState( null )
    const [remarkMode, setRemarkMode] = useState( 'submit' )
    const [attemptForRemark, setAttemptForRemark] = useState( null )
    const [remarkDialogAnchor, setRemarkDialogAnchor] = useState( null )
    const [filteredParticipations, setFilteredParticipations] = useState( null )
    const [rowsPerPage, setRowsPerPage] = useState( 10 )
    const [page, setPage] = useState( 0 )
    const [sortState, setSortState] = useState( {} )
    const [attemptsMenuOpen, setAttemptsMenuOpen] = useState( false )
    const [accessDenied, setAccessDenied] = useState( false )

    const navigate = useNavigate()
    const { palette, border, table } = useTheme()

    const attemptsMenuRef = useRef()

    const [searchParams, setSearchParams] = useSearchParams( { selectedAttempt: "0" } )
    const selectedAttempt = searchParams.get( 'selectedAttempt' )

    const setSearchParamValue = useCallback( ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }, [setSearchParams] )

    const handleAttemptChange = useCallback( ( val = selectedAttempt, data = participations, changeValue = true ) => {
        if ( changeValue )
            setSearchParamValue( 'selectedAttempt', val )
        if ( val === 'latest' ) {
            const groupByID = data.reduce( ( dict, item ) => {
                if ( dict[item.student_id] ) {
                    if ( parseInt( dict[item.student_id].exam_participation_attempt ) < item.exam_participation_attempt )
                        dict[item.student_id] = item
                } else
                    dict[item.student_id] = item
                return dict
            }, {} )
            setFilteredParticipations( Object.values( groupByID ) )
        } else if ( +val !== 0 ) {
            const filteredList = data.filter( p => String( p.exam_participation_attempt ) === String( val ) )
            setFilteredParticipations( filteredList )
        } else if ( +val === 0 ) {
            setFilteredParticipations( data )
        }
        setAttemptsMenuOpen( false )
    }, [participations, selectedAttempt, setSearchParamValue] )

    const sortByProperty = ( property ) => {
        let newFilteredParticipations = [...filteredParticipations]
        newFilteredParticipations.sort( ( a, b ) => {
            if ( typeof a[property] == "number" ) {
                if ( !sortState[property] )
                    return ( a[property] - b[property] )
                else
                    return ( b[property] - a[property] )
            } else {
                if ( !sortState[property] )
                    return ( ( a[property] < b[property] ) ? -1 : 0 )
                else
                    return ( ( a[property] > b[property] ) ? -1 : 0 )
            }
        } )
        setSortState( { ...sortState, [property]: sortState[property] ? false : true } )
        setFilteredParticipations( newFilteredParticipations )
    }

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    const handleEditOrSubmitRemark = ( e, participation, mode ) => {
        setRemarkMode( mode )
        setAttemptForRemark( participation )
        setRemarkDialogAnchor( e.currentTarget )
    }

    const getData = useCallback( async () => {
        try {
            const { data: participationData } = await api.fetchExamParticipation( id )
            setParticipations( participationData.participations )
            if ( selectedAttempt === "0" )
                setFilteredParticipations( participationData.participations || [] )
            else {
                handleAttemptChange( selectedAttempt, participationData.participations, false )
            }
            setTotalMarks( participationData.totalMarks )
            setExam( participationData.exam )
        } catch ( err ) {
            if ( err?.response?.status === 403 || err?.response?.status === 401 )
                setAccessDenied( true )
            else
                toast( err?.response?.data?.message || "Something went wrong" )
        }
        finally {
            setInProgress( false )
        }
    }, [id, selectedAttempt, handleAttemptChange] )

    useEffect( () => {

        const getData = async () => {
            try {
                const { data: participationData } = await api.fetchExamParticipation( id )
                setParticipations( participationData.participations )
                if ( selectedAttempt === 'latest' ) {
                    const groupByID = participationData.participations.reduce( ( dict, item ) => {
                        if ( dict[item.student_id] ) {
                            if ( parseInt( dict[item.student_id].exam_participation_attempt ) < item.exam_participation_attempt )
                                dict[item.student_id] = item
                        } else
                            dict[item.student_id] = item
                        return dict
                    }, {} )
                    setFilteredParticipations( Object.values( groupByID ) )
                } else if ( +selectedAttempt !== 0 ) {
                    const filteredList = participationData.participations.filter( p => String( p.exam_participation_attempt ) === String( selectedAttempt ) )
                    setFilteredParticipations( filteredList )
                } else if ( +selectedAttempt === 0 ) {
                    setFilteredParticipations( participationData.participations || [] )
                }
                setTotalMarks( participationData.totalMarks )
                setExam( participationData.exam )
            } catch ( err ) {
                if ( err?.response?.status === 403 || err?.response?.status === 401 )
                    setAccessDenied( true )
                else
                    toast( err?.response?.data?.message || "Something went wrong" )
            }
            finally {
                setInProgress( false )
            }
        }

        getData()
    }, [id, selectedAttempt] )


    const columns = [
        {
            name: <Box width="fit-content" gap="10px" display="flex" alignItems="center">
                AUID
                <IconButton onClick={() => { sortByProperty( "student_id" ) }} sx={{ color: "whitesmoke", width: "20px", height: "20px", "&:hover": { background: "#eee", color: "GrayText", width: "20px", height: "20px" } }} size='small'>
                    {sortState.student_id ? <ArrowDownwardIcon sx={{ fontSize: "14px" }} /> : <ArrowUpwardIcon sx={{ fontSize: "14px" }} />}
                </IconButton>
            </Box>
            , color: "white", minWidth: "100px", align: "left", padding: "10px 20px 10px 40px"
        },
        {
            name: <Box width="fit-content" display="flex" gap="10px" alignItems="center">
                Student <IconButton onClick={() => { sortByProperty( "student_name" ) }} sx={{ color: "inherit", width: "20px", height: "20px", "&:hover": { background: "#eee", color: "GrayText" } }} size='small'>
                    {sortState.student_name ? <ArrowDownwardIcon sx={{ fontSize: "14px" }} /> : <ArrowUpwardIcon sx={{ fontSize: "14px" }} />}
                </IconButton>
            </Box>, color: "white", minWidth: "100px", align: "left"
        },
        { name: "attempt", color: "white", width: "10px", align: "right" },
        { background: "#f18f08", color: "white", name: "Status", minWidth: "150px" },
        {
            background: "#f18f08", minWidth: "120px", color: "white", name: <Box justifyContent="flex-start" display="flex" gap="10px" alignItems="center">
                Score <IconButton onClick={() => { sortByProperty( "exam_participation_score" ) }} sx={{ color: "inherit", width: "20px", height: "20px", "&:hover": { background: "#eee", color: "GrayText" } }} size='small'>
                    {sortState.exam_participation_score ? <ArrowDownwardIcon sx={{ fontSize: "14px" }} /> : <ArrowUpwardIcon sx={{ fontSize: "14px" }} />}
                </IconButton>
            </Box>, align: "right"
        },
        { name: "Device", minWidth: "150px" },
        { name: "Submission by", minWidth: "150px" },
        { name: "Remarks", minWidth: "150px" },
        { name: "Actions", padding: "10px 40px 10px 20px" }
    ]

    return (
        <Box minHeight="0" display="flex" flexDirection="column" overflow="auto" flexGrow={1} minWidth="0" sx={{ padding: "20px" }}>

            {accessDenied && <BlockMessage actions={[{ label: "Back to Participations", action: () => navigate( `/faculty/participations` ) }]} type='error' message='You do not have access to view this exam information.' />}

            {attemptForRemark && <SubmitOrEditRemarkDialog getData={getData} mode={remarkMode} attempt={attemptForRemark} setAttempt={setAttemptForRemark} anchor={remarkDialogAnchor} setAnchor={setRemarkDialogAnchor} />}

            {!accessDenied &&
                <BreadcrumbList items={[
                    { link: "/faculty/exams", label: "Exams" },
                    { link: "/faculty/participations", label: "All participations" },
                    { link: `/faculty/participations?selectedExam=${id}`, label: exam ? exam.exam_name : <CircularProgress sx={{ color: "textSecondary" }} size={12} /> },
                    "Participations"
                ]} />}

            {!accessDenied && <Paper sx={{ minHeight: !inProgress && "550px", flexGrow: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                {exam && <Box gap="20px" sx={{ background: `linear-gradient(233.69deg, #6A6790 -93.75%, #1F1E2A 145.73%)` }} padding="20px" display="flex" alignItems={{ lg: "center", md: "center", sm: "center", xs: "flex-start" }} justifyContent="space-between" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }} >
                    <Box>
                        <Typography color="white" variant='h5'>{exam.exam_name} </Typography>
                        <Typography color="white" variant='body2'>Participations</Typography>
                    </Box>
                    {participations && participations.length > 0 && <Box display="flex" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }} gap="20px" width={{ lg: "fit-content", md: "fit-content", sm: "fit-content", xs: "100%" }} >
                        <Button ref={attemptsMenuRef} onClick={() => setAttemptsMenuOpen( true )} endIcon={Icons.default.KeyboardArrowDownIcon} variant='contained' disableElevation sx={{ background: `linear-gradient(125.39deg, #139CFF -32.54%, #C54097 110.4%)`, }} >
                            <Typography variant='subtitle2' color="white">{selectedAttempt === "0" ? "All attempts" : `Attempt: ${selectedAttempt}`}</Typography>
                        </Button>
                        <Menu anchorEl={attemptsMenuRef.current} open={attemptsMenuOpen} onClose={() => setAttemptsMenuOpen( false )}>
                            <MenuItem onClick={() => handleAttemptChange( "0" )} sx={{ fontSize: "14px" }} value={"0"}>All attempts</MenuItem>
                            <MenuItem onClick={() => handleAttemptChange( "latest" )} sx={{ fontSize: "14px" }} value="latest">Latest attempt</MenuItem>
                            {new Array( parseInt( Math.max( 1, +( exam.maximum_attempts || 1 ) ) ) ).fill( 1 ).map( ( e, index ) => (
                                <MenuItem onClick={() => handleAttemptChange( index + 1 )} sx={{ fontSize: "14px" }} key={index} value={index + 1}>Attempt - {index + 1}</MenuItem>
                            ) )}
                        </Menu>

                        <Tooltip title="Export participation data as CSV file">
                            <Button disableElevation onClick={() => exportExamParticipation( participations, totalMarks )} sx={{ width: { sm: "150px", xs: "100%" } }} variant='contained' color='customThemeColor' endIcon={<Upload />}>
                                Export All
                            </Button>
                        </Tooltip>
                    </Box>}
                </Box>}
                {!inProgress && participations && participations.length > 0 &&
                    <Box margin="20px" border={border[1]} borderRadius="5px" bgcolor={palette.contentBg} flexGrow={1} display="flex" flexDirection="column" minHeight="300px" overflow="hidden">
                        {filteredParticipations.length > 0 && <TableContainer className='custom-scrollbar' sx={{ flexGrow: 1, overflow: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Table stickyHeader>
                                <TableHeader columns={columns} />
                                <TableBody>
                                    {filteredParticipations
                                        .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                        .map( ( participation, index ) => (
                                            <TableRow className='table-row' key={index} sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }}>
                                                <TableCell sx={{ color: table.color, fontSize: "12px", minWidth: "100px", padding: "0" }}>
                                                    <Link to={`/faculty/exams/${id}/evaluate/${participation.participation_id}`} className="default-link">
                                                        <Box padding={columns[0].padding || "10px 20px"}>
                                                            <strong>{participation.student_id}</strong></Box>
                                                    </Link>
                                                </TableCell>
                                                <TableCell sx={{ color: table.color, fontSize: "12px", minWidth: "100px", padding: "0" }}>
                                                    <Link to={`/faculty/exams/${id}/evaluate/${participation.participation_id}`} className="default-link">
                                                        <Box padding={columns[1].padding || "10px 20px"}>
                                                            <strong>{participation.student_name}</strong></Box>
                                                    </Link>
                                                </TableCell>
                                                <TableCell align='right' sx={{ color: table.color, fontSize: "12px", minWidth: "80px", padding: "0" }}>
                                                    <Link to={`/faculty/exams/${id}/evaluate/${participation.participation_id}`} className="default-link">
                                                        <Box padding={columns[2].padding || "10px 20px"}>
                                                            <strong>{participation.exam_participation_attempt}</strong></Box>
                                                    </Link>
                                                </TableCell>
                                                <TableCell sx={{ color: table.color, padding: "0" }}>
                                                    <Link to={`/faculty/exams/${id}/evaluate/${participation.participation_id}`} className="default-link">
                                                        <Box padding={columns[3].padding || "10px 20px"}>
                                                            <Typography color="inherit" variant="body2" sx={{ textTransform: "uppercase", minWidth: "100px", fontSize: "12px !important" }}>
                                                                <strong>
                                                                    {participation.exam_participation_status === 'Completed' ? <>completed <span style={{ display: "block" }}>{`${new Date( participation.exam_participation_end_time ).toLocaleDateString( 'en-IN' ).replace( /\//g, "-" )}, ${new Date( participation.exam_participation_end_time ).toLocaleTimeString( 'en-IN', { hour: "2-digit", minute: "2-digit" } )}`}</span></>
                                                                        : participation.exam_participation_status}
                                                                </strong>
                                                            </Typography>
                                                        </Box>
                                                    </Link>
                                                </TableCell>
                                                <TableCell align='left' sx={{ color: table.color, padding: 0, minHeight: "100%", minWidth: "200px", gap: "5px", fontWeight: "bold" }} >
                                                    <Link to={`/faculty/exams/${id}/evaluate/${participation.participation_id}`} className="default-link">
                                                        <Box padding={columns[4].padding || "10px 20px"}>{participation.exam_participation_score ? participation.exam_participation_score : "0"} out of {totalMarks}</Box>
                                                    </Link>
                                                </TableCell>
                                                <TableCell align='left' sx={{ color: table.color, padding: 0, minHeight: "100%", minWidth: "200px", gap: "5px", fontWeight: "bold" }} >
                                                    <Link to={`/faculty/exams/${id}/evaluate/${participation.participation_id}`} className="default-link">
                                                        <Box padding={columns[5].padding || "10px 20px"}>{participation?.device_data?.platform || "Not Available"}</Box>
                                                    </Link>
                                                </TableCell>
                                                <TableCell align='left' sx={{ color: table.color, padding: 0, minHeight: "100%", minWidth: "200px", gap: "5px", fontWeight: "bold" }} >
                                                    <Link to={`/faculty/exams/${id}/evaluate/${participation.participation_id}`} className="default-link">
                                                        <Box padding={columns[6].padding || "10px 20px"}>{participation?.finished_reason || "Not Available"}</Box>
                                                    </Link>
                                                </TableCell>
                                                <TableCell sx={{ color: table.color, minWidth: "100px", padding: "0", }}>
                                                    <Link to={`/faculty/exams/${id}/evaluate/${participation.participation_id}`} className="default-link">
                                                        <Typography title={participation.exam_participation_remark ? participation.exam_participation_remark : "No remarks."} padding={columns[7].padding || "10px 20px"} variant='body2' color="inherit" fontSize="12px">
                                                            {participation.exam_participation_remark ? participation.exam_participation_remark.substring( 0, 30 ) : "No remarks."}
                                                        </Typography>
                                                    </Link>
                                                </TableCell>
                                                <TableCell sx={{ color: table.color, minWidth: "100px", padding: columns[8].padding || "10px 20px" }}>
                                                    {participation.exam_participation_remark ? <IconButton onClick={e => handleEditOrSubmitRemark( e, participation, 'edit' )} size="small" color="primaryDark" >
                                                        {Icons.default.EditIconOutlined}
                                                    </IconButton> : <Button size="small" variant='outlined' onClick={e => handleEditOrSubmitRemark( e, participation, 'submit' )} color="primary" sx={{ textTransform: "capitalize" }}>Add remark</Button>}
                                                </TableCell>
                                            </TableRow>
                                        ) )}
                                </TableBody>
                            </Table>
                        </TableContainer >}
                        {filteredParticipations.length > 0 && <TablePagination
                            sx={{ background: table.headerBg, position: "sticky", bottom: 0, right: 0, left: 0, minHeight: "55px" }}
                            rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                            component="div"
                            className='no-scrollbar'
                            count={filteredParticipations.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />}
                        {filteredParticipations.length === 0 && <Box height="100%" flexGrow={1} padding="20px" bgcolor={palette.contentBg} margin="10px" borderRadius="5px" display="flex" flexDirection="column" gap="10px" justifyContent={'center'} alignItems="center">
                            <img width="200px" src={noParticipationImg} alt="No participtions" />
                            <Typography padding="20px" variant='subtitle2' >No participants for selected attempt number!</Typography>
                            <Button startIcon={Icons.default.FilterAltOff} sx={{ textTransform: "capitalize" }} variant='outlined' onClick={() => setSearchParamValue( 'selectedAttempt', "0" )} color='error' >Remove filter</Button>
                        </Box>}
                    </Box>
                }
                {!inProgress && participations.length === 0 && <Box height="100%" flexGrow={1} padding="20px" bgcolor={palette.contentBg} margin="10px" borderRadius="5px" display="flex" flexDirection="column" gap="10px" justifyContent={'center'} alignItems="center">
                    <img width="200px" src={noParticipationImg} alt="No participtions" />
                    <Typography padding="20px" variant='subtitle2' >No participants yet!</Typography>
                </Box>}
                {inProgress && <DataLoadingSpinner waitingMessage="Loading exam participations..." />}
            </Paper >}
        </Box >
    )
}
