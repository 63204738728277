import React from 'react'
import { displayPresets, templateColorThemes } from '../../../utils/resume-builder-utilities'
import { Box, Chip, Typography } from '@mui/material'
import { useStudentResumeFormStore } from '../../../store/studentresumeform.store'
import { useEffect } from 'react'
import { useState } from 'react'

const Image = ( { urlKey, entity } ) => {

    const [fileSrc, setFileSrc] = useState( null )
    const StudentResumeFormStore = useStudentResumeFormStore()

    useEffect( () => {
        const getImageUrl = async () => {
            setFileSrc( await StudentResumeFormStore.getFilePresignedUrl( urlKey ) )
        }
        getImageUrl()
    }, [StudentResumeFormStore, urlKey] )
    return (
        <Box>
            {fileSrc && <img style={{ width: entity.size ? `${entity.size}px` : "150px", height: entity.size ? `${entity.size}px` : "150px", borderRadius: entity.borderRadius ? entity.borderRadius : "50%", objectFit: "cover" }} src={fileSrc} alt="profile" />}
        </Box>
    )
}

const TemplateSectionBox = ( { section, layout, data } ) => {

    const getGroupStyles = ( grpIndex ) => {
        if ( section.groupStyles ) {
            if ( section.groupStyles[grpIndex] ) {
                return section.groupStyles[grpIndex]
            }
        }
        return {}
    }


    return (
        <Box position="relative" display="flex" flexDirection="column" alignItems={section.align ? section.align : "flex-start"} padding={`${section.padding.y}px ${section.padding.x}px`} gap={`${section.gap}px`}>
            {section.show_section_title && data && <Typography variant="h6"
                sx={{ color: templateColorThemes[layout.colorTheme ? layout.colorTheme : "default"].primary }}
                fontSize={`${displayPresets[section.title_preset ? section.title_preset : "header1"].fontSize * layout.fontSize}px`}
                fontWeight={displayPresets[section.title_preset ? section.title_preset : "header1"].fontWeight}
            >{section.section_title}</Typography>}
            {!section.multiple && section.attributes.map( ( grp, grpIndex ) => {
                const { displayDirection, spacing, separator } = getGroupStyles( grpIndex )
                return (
                    <Box key={grpIndex} display="flex" flexDirection={displayDirection} justifyContent={spacing === "space-between" ? "space-between" : "flex-start"} gap={( !spacing || spacing === "gap" ) && !separator ? "10px" : "0"} alignItems={displayDirection === "column" ? "flex-start" : "center"}>
                        {grp.map( ( entity, entityIndex ) => {
                            if ( ( entity.visibility === undefined || entity.visibility ) ) {
                                if ( entity.entity_detail.data_type === "document" || entity.entity_detail.only_for_proctors === true ) return ''
                                else if ( entity.entity_detail.data_type === "array" && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 ) {
                                    return (
                                        <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                            {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="subtitle2" fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                            <Box display="flex" justifyContent={section.align ? section.align : "flex-start"} flexWrap="wrap" gap="5px">
                                                {entity.displayItemAs !== "list" && data[entity.entity_detail.key].length > 0 && data[entity.entity_detail.key].map( ( item, itemIndex ) => (
                                                    <Chip
                                                        key={itemIndex}
                                                        sx={{
                                                            textAlign: entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left",
                                                            fontSize: entity.fontSize ? `${entity.fontSize}px` : `16px`,
                                                            fontWeight: entity.fontWeight ? entity.fontWeight : "400",
                                                            textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: entity.color ? templateColorThemes[layout.colorTheme][entity.color] : "black",
                                                            borderRadius: entity.displayItemAs !== "roundedRectangleChip" ? "0" : "20px"
                                                        }}
                                                        label={item}
                                                    />
                                                ) )}
                                                {entity.displayItemAs === "list" && data[entity.entity_detail.key].length > 0 && <ul style={{ margin: "0", paddingLeft: "15px" }}>
                                                    {data[entity.entity_detail.key].map( ( item, itemIndex ) => (
                                                        <li key={itemIndex}>
                                                            <Typography
                                                                sx={{
                                                                    textAlign: entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left",
                                                                    fontSize: entity.fontSize ? `${entity.fontSize}px` : `16px`,
                                                                    fontWeight: entity.fontWeight ? entity.fontWeight : "400",
                                                                    textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: entity.color ? templateColorThemes[layout.colorTheme][entity.color] : "black",
                                                                    borderRadius: entity.displayItemAs !== "roundedRectangleChip" && "0"
                                                                }}
                                                            > {item}
                                                            </Typography>
                                                        </li>
                                                    ) )}
                                                </ul>
                                                }
                                            </Box>
                                        </Box>
                                    )
                                } else if ( ( entity.entity_detail.data_type === "url" || entity.entity_detail.data_type === "email" ) && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 ) {
                                    return (
                                        <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                            {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography
                                                variant="subtitle2"
                                                textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"}
                                                fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                            <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="body1"
                                                margin="0"
                                                fontStyle={entity.italic ? "italic" : "none"}
                                                fontSize={entity.fontSize ? `${entity.fontSize}px` : `${displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontSize * layout.fontSize}px`}
                                                fontWeight={entity.fontWeight ? entity.fontWeight : displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontWeight}
                                                sx={{ textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: layout.colorTheme ? templateColorThemes[layout.colorTheme][entity.color] : "black" }} >
                                                {entityIndex !== 0 && separator && displayDirection !== "column" && <span style={{ padding: "0 5px" }}>{separator}</span>}
                                                {entity.entity_detail.data_type === "url" && <a style={{ pointerEvents: "all", color: "inherit", fontSize: "inherit", fontWeight: "inherit", fontStyle: "inherit", textDecoration: "inherit" }} href={data[entity.entity_detail.key]} target='blank'>{data[entity.entity_detail.key].replace( /https?:\/\//, '' )}</a>}
                                                {entity.entity_detail.data_type === "email" && <a style={{ pointerEvents: "all", color: "inherit", fontSize: "inherit", fontWeight: "inherit", fontStyle: "inherit", textDecoration: "inherit" }} href={`mailto:${data[entity.entity_detail.key]}`} target='blank'>{data[entity.entity_detail.key]}</a>}
                                            </Typography>
                                        </Box>
                                    )
                                } else if ( entity.entity_detail.data_type === "file" && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 ) {
                                    return (
                                        <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                            {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography
                                                textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"}
                                                variant="subtitle2" fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                            {/* <img src={data[entity.entity_detail.key]} alt="file" style={{ width: entity.size ? `${entity.size}px` : "150px", height: entity.size ? `${entity.size}px` : "150px", borderRadius: entity.borderRadius ? entity.borderRadius : "50%", objectFit: "cover" }} /> */}
                                            <Image entity={entity} urlKey={data[entity.entity_detail.key]} />
                                        </Box>
                                    )
                                } else if ( entity.entity_detail.data_type === "linkwithlabel" && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 ) {
                                    return (
                                        <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                            {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography
                                                variant="subtitle2"
                                                textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"}
                                                fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                            <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="body1"
                                                margin="0"
                                                fontStyle={entity.italic ? "italic" : "none"}
                                                fontSize={entity.fontSize ? `${entity.fontSize}px` : `${displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontSize * layout.fontSize}px`}
                                                fontWeight={entity.fontWeight ? entity.fontWeight : displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontWeight}
                                                sx={{ textDecoration: data[`${entity.entity_detail.key}-link`] && data[`${entity.entity_detail.key}-link`].length > 0 ? "underline" : "none", textTransform: entity.textTransform, color: entity.color ? templateColorThemes[layout.colorTheme][entity.color] : "black" }} >
                                                {entityIndex !== 0 && separator && displayDirection !== "column" && <span style={{ padding: "0 5px" }}>{separator}</span>}
                                                <a style={{ pointerEvents: "all", color: "inherit", fontSize: "inherit", fontWeight: "inherit", fontStyle: "inherit", textDecoration: "inherit" }} href={data[`${entity.entity_detail.key}-link`]} target="_blank" rel="noopener noreferrer">
                                                    {data[entity.entity_detail.key]}
                                                </a>
                                            </Typography>
                                        </Box>
                                    )
                                } else {
                                    return (
                                        <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                            {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="subtitle2" fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                            <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="body1"
                                                margin="0"
                                                fontStyle={entity.italic ? "italic" : "none"}
                                                fontSize={entity.fontSize ? `${entity.fontSize}px` : `${displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontSize * layout.fontSize}px`}
                                                fontWeight={entity.fontWeight ? entity.fontWeight : displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontWeight}
                                                sx={{ textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: entity.color ? templateColorThemes[layout.colorTheme][entity.color] : "black" }} >
                                                {entityIndex !== 0 && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && separator && displayDirection !== "column" && <span style={{ padding: "0 5px" }}>{separator}</span>}
                                                {data[entity.entity_detail.key]}
                                            </Typography>
                                        </Box>
                                    )
                                }
                            } else {
                                return ''
                            }
                        } )}
                    </Box>
                )
            } )}
            {section.multiple && data && data.length > 0 && <Box display="flex" flexDirection="column" gap={section.dataSetGap ? `${section.dataSetGap}px` : `10px`}>
                {data.map( ( data, dataIndex ) => (
                    <Box borderLeft="1px solid #d3d3d3" paddingLeft="10px" key={dataIndex}>
                        {section.attributes.map( ( grp, grpIndex ) => {
                            const { displayDirection, spacing, separator } = getGroupStyles( grpIndex )
                            return (
                                <Box key={grpIndex} display="flex" flexDirection={displayDirection} justifyContent={spacing === "space-between" ? "space-between" : "flex-start"} gap={( !spacing || spacing === "gap" ) && !separator ? "10px" : "0"} alignItems={displayDirection === "column" ? "flex-start" : "center"}>
                                    {grp.map( ( entity, entityIndex ) => {
                                        if ( ( entity.visibility === undefined || entity.visibility ) ) {
                                            if ( entity.entity_detail.data_type === "array" && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 ) {
                                                return (
                                                    <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                                        {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="subtitle2" fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                                        <Box display="flex" justifyContent={section.align ? section.align : "flex-start"} flexWrap="wrap" gap="5px">
                                                            {entity.displayItemAs !== "list" && data[entity.entity_detail.key].length > 0 && data[entity.entity_detail.key].map( ( item, itemIndex ) => (
                                                                <Chip
                                                                    key={itemIndex}
                                                                    sx={{
                                                                        textAlign: entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left",
                                                                        fontSize: entity.fontSize ? `${entity.fontSize}px` : `16px`,
                                                                        fontWeight: entity.fontWeight ? entity.fontWeight : "400",
                                                                        textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: entity.color ? templateColorThemes[layout.colorTheme][entity.color] : "black",
                                                                        borderRadius: entity.displayItemAs !== "roundedRectangleChip" ? "0" : "20px"
                                                                    }}
                                                                    label={item}
                                                                />
                                                            ) )}
                                                            {entity.displayItemAs === "list" && data[entity.entity_detail.key].length > 0 && <ul style={{ margin: "0", paddingLeft: "15px" }}>
                                                                {data[entity.entity_detail.key].map( ( item, itemIndex ) => (
                                                                    <li key={itemIndex}>
                                                                        <Typography
                                                                            sx={{
                                                                                textAlign: entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left",
                                                                                fontSize: entity.fontSize ? `${entity.fontSize}px` : `16px`,
                                                                                fontWeight: entity.fontWeight ? entity.fontWeight : "400",
                                                                                textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: entity.color ? templateColorThemes[layout.colorTheme][entity.color] : "black",
                                                                                borderRadius: entity.displayItemAs !== "roundedRectangleChip" && "0"
                                                                            }}
                                                                        > {item}
                                                                        </Typography>
                                                                    </li>
                                                                ) )}
                                                            </ul>
                                                            }
                                                        </Box>
                                                    </Box>
                                                )
                                            } else if ( entity.entity_detail.data_type === "linkwithlabel" && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 ) {
                                                return (
                                                    <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                                        {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="subtitle2" fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                                        <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="body1"
                                                            margin="0"
                                                            fontStyle={entity.italic ? "italic" : "none"}
                                                            fontSize={entity.fontSize ? `${entity.fontSize}px` : `${displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontSize * layout.fontSize}px`}
                                                            fontWeight={entity.fontWeight ? entity.fontWeight : displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontWeight}
                                                            sx={{ textDecoration: data[`${entity.entity_detail.key}-link`] && data[`${entity.entity_detail.key}-link`].length > 0 ? "underline" : "none", textTransform: entity.textTransform, color: entity.color ? templateColorThemes[layout.colorTheme][entity.color] : "black" }} >
                                                            {entityIndex !== 0 && separator && displayDirection !== "column" && <span style={{ padding: "0 5px" }}>{separator}</span>}
                                                            <a style={{ pointerEvents: "all", color: "inherit", fontSize: "inherit", fontWeight: "inherit", fontStyle: "inherit", textDecoration: "inherit" }} href={data[`${entity.entity_detail.key}-link`]} target="_blank" rel="noopener noreferrer">
                                                                {data[entity.entity_detail.key]}
                                                            </a>
                                                        </Typography>
                                                    </Box>
                                                )
                                            } else if ( ( entity.entity_detail.data_type === "url" || entity.entity_detail.data_type === "email" ) && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 ) {
                                                return (
                                                    <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                                        {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="subtitle2" fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                                        <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="body1"
                                                            margin="0"
                                                            fontStyle={entity.italic ? "italic" : "none"}
                                                            fontSize={entity.fontSize ? `${entity.fontSize}px` : `${displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontSize * layout.fontSize}px`}
                                                            fontWeight={entity.fontWeight ? entity.fontWeight : displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontWeight}
                                                            sx={{ textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: layout.colorTheme ? templateColorThemes[layout.colorTheme][entity.color] : "black" }} >
                                                            {entityIndex !== 0 && separator && displayDirection !== "column" && <span style={{ padding: "0 5px" }}>{separator}</span>}
                                                            {entity.entity_detail.data_type === "url" && <a style={{ pointerEvents: "all", color: "inherit", fontSize: "inherit", fontWeight: "inherit", fontStyle: "inherit", textDecoration: "inherit" }} href={data[entity.entity_detail.key]} target='blank'>{data[entity.entity_detail.key].replace( /https?:\/\//, '' )}</a>}
                                                            {entity.entity_detail.data_type === "email" && <a style={{ pointerEvents: "all", color: "inherit", fontSize: "inherit", fontWeight: "inherit", fontStyle: "inherit", textDecoration: "inherit" }} href={`mailto:${data[entity.entity_detail.key]}`} target='blank'>{data[entity.entity_detail.key]}</a>}
                                                        </Typography>
                                                    </Box>
                                                )
                                            } else if ( entity.entity_detail.data_type === "file" && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 ) {
                                                return (
                                                    <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                                        {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography variant="subtitle2" fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                                        <img src={data[entity.entity_detail.key]} alt="file" style={{ width: entity.size ? `${entity.size}px` : "150px", height: entity.size ? `${entity.size}px` : "150px", borderRadius: entity.borderRadius ? entity.borderRadius : "50%", objectFit: "cover" }} />
                                                    </Box>
                                                )
                                            } else {
                                                return (
                                                    <Box sx={{ pointerEvents: "none" }} alignItems="center" key={entityIndex}>
                                                        {entity.entity_detail.label_show && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="subtitle2" fontSize={`${displayPresets[entity.labelFontSize ? entity.labelFontSize : "label1"].fontSize * layout.fontSize}px`} sx={{ pointerEvents: "none", color: entity.labelColor ? templateColorThemes[layout.colorTheme][entity.labelColor] : "black" }}>{entity.entity_detail.label}</Typography>}
                                                        <Typography textAlign={entity.justify ? "justify" : section.align ? section.align === "flex-end" ? "right" : section.align === "flex-start" ? "left" : "center" : "left"} variant="body1"
                                                            margin="0"
                                                            fontStyle={entity.italic ? "italic" : "none"}
                                                            fontSize={entity.fontSize ? `${entity.fontSize}px` : `${displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontSize * layout.fontSize}px`}
                                                            fontWeight={entity.fontWeight ? entity.fontWeight : displayPresets[entity.displayAs ? entity.displayAs : "custom"]?.fontWeight}
                                                            sx={{ textDecoration: entity.underline ? "underline" : "none", textTransform: entity.textTransform, color: entity.color ? templateColorThemes[layout.colorTheme][entity.color] : "black" }} >
                                                            {entityIndex !== 0 && data[entity.entity_detail.key] && data[entity.entity_detail.key].length > 0 && separator && displayDirection !== "column" && <span style={{ padding: "0 5px" }}>{separator}</span>}
                                                            {data[entity.entity_detail.key]}
                                                        </Typography>
                                                    </Box>
                                                )
                                            }
                                        } else {
                                            return ''
                                        }
                                    } )}
                                </Box>
                            )
                        } )}
                    </Box>
                ) )}
            </Box>}
        </Box>
    )
}

export default TemplateSectionBox
