import { Box, Button, Chip, CircularProgress, Dialog, IconButton, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { formatDateTimeForDisplay } from '../../../utils/exam-utilities'
import api from '../../../service/api'
import { Icons, flatColorsAndBackgrounds } from '../../../utils/utilities'
import { EditableMathField } from 'react-mathquill'
import parse from 'html-react-parser'
import ConfirmDialog from '../../common/ConfirmDialog'
import { toast } from 'react-toastify'


const AuditExamViewer = ( { handleClose, openState, exam, setExamList } ) => {

    const [questions, setQuestions] = useState( [] )
    const [deleting, setDeleting] = useState( false )
    const [fetchingQuestion, setFetchingQuestion] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )
    const [questionFetchError, setQuestionFetchError] = useState( false )

    const { palette } = useTheme()

    const deleteExam = async () => {
        try {
            setDeleting( true )
            await api.deleteExam( exam.exam_id )
            setExamList( prev => prev.filter( e => e.exam_id !== exam.exam_id ) )
            toast( <Typography color="customThemeColor.main"><strong><em>{exam.exam_name}</em></strong> successfully deleted</Typography> )
            handleClose()
        } catch ( e ) {
            toast( e?.response?.data?.message || e?.response?.data || "Something went wrong! try again later" )
        } finally {
            setDeleting( false )
        }
    }

    const fetchExamQuestions = async () => {
        setFetchingQuestion( true )
        try {
            setQuestionFetchError( true )
            const { data: questionsRes } = await api.fetchQuestions( exam.exam_id )
            setQuestions( questionsRes.data || questionsRes )
        } catch ( err ) {
            console.log( err )
            setQuestionFetchError( err?.response?.data?.message || "Opps! An error occured while trying to fetch exam question try again." )
        } finally {
            setFetchingQuestion( false )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "1200px" } }} open={openState}>
            <ConfirmDialog waiting={deleting} status={deleteDialogState} waitingText="Deleting..." actionName="Yes! delete it" confirmAction={deleteExam} cancelAction={() => setDeleteDialogState( false )} message="This action can not be undone and all the data associated with this resource will be lost." />
            <Box sx={{
                background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
            }} display="flex" alignItems="center" justifyContent="space-between" padding="20px" >
                <Box>
                    <Typography color="white" variant="h6">{exam.exam_name}</Typography>
                    <Typography gutterBottom variant='body2' color='white' >{`${formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )} - ${formatDateTimeForDisplay( exam.exam_end_date, exam.exam_end_time )}`}</Typography>
                    {exam.exam_duration !== "null" && exam.exam_duration !== null && <Typography variant='body2' color='white' > {`Duration: ${exam.exam_duration.split( ":" )[0]} Hours ${exam.exam_duration.split( ":" )[1]} Minutes`}</Typography>}
                    <Typography variant='body2' color='white' >Maximum Attempts: {exam.maximum_attempts}</Typography>
                </Box>
                <IconButton onClick={handleClose}>{Icons.default.CloseIcon}</IconButton>
            </Box>
            <Box padding="20px">
                {<Button sx={{ textTransform: "capitalize" }} disableElevation startIcon={Icons.default.DeleteIcon} onClick={() => setDeleteDialogState( true )} color="error" variant='contained' >Delete</Button>}
                <Typography variant='body1'>{exam.exam_description}</Typography>
                <Typography variant='h6' fontSize="18px" marginTop="20px" gutterBottom color={palette.shadedText}>Topics</Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", }} flexWrap="wrap" gap="5px">
                    {exam.exam_topics && exam.exam_topics.map( ( topic, index ) => (
                        <Chip key={topic} label={topic} sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg, fontWeight: "600" }} />
                    ) )}
                </Box>
                <Typography gutterBottom variant='h6' fontSize="18px" marginTop="20px" color={palette.shadedText}>Exam for batch(es):</Typography>
                <Box display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                    {exam.batches_ids && exam.batches_ids.map( ( batch, index ) => (
                        <Chip key={batch} label={batch} sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg, fontWeight: "600" }} />
                    ) )}
                    {( !exam.batches_ids || exam.batches_ids.length === 0 ) && <Typography variant='body2' color="textSecondary">Exam is not yet assigned to batches!</Typography>}
                </Box>
                <Box marginTop="20px">
                    {questions.length === 0 && <Button disabled={fetchingQuestion} startIcon={fetchingQuestion ? <CircularProgress size={14} /> : Icons.default.ViewListIcon} variant="contained" onClick={fetchExamQuestions} disableElevation color="secondary" sx={{ textTransform: "capitalize" }}>View Questions</Button>}
                    {questions.length > 0 && <Box display="flex" marginTop="10px" flexDirection="column" gap="10px">
                        {questions.map( ( question, index ) => (
                            <Box sx={{ padding: "10px 0", fontSize: "14px", borderRadius: "5px", "& p": { margin: "0 " } }} key={question.question.question_id}>
                                <Typography variant='subtitle2' fontSize="10px" > <em>Question {index + 1}</em> </Typography>
                                <Box>
                                    <Box color={palette.shadedText}>
                                        <strong> {question.question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField latex={question.question.question_text.trim().split( "LATEX-" )[1]} id='static-latex' style={{ padding: "0px", fontFamily: "'Roboto' sans-serif !important", fontWeight: "bold", color: palette.shadedText }}> </EditableMathField> : parse( question.question.question_text.trim() )}</strong>
                                    </Box>
                                    <Box display="inline-flex" color={palette.shadedText} marginTop="5px">
                                        <Chip size='small' sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg }} label={question.question.question_type}></Chip>
                                        {question.question.question_type === 'MCQ' && <Chip size='small' sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg }} label={question.question.question_answer_selection}></Chip>}
                                    </Box>
                                </Box>
                            </Box>
                        ) )}
                    </Box>}
                    {questionFetchError && <Typography color="errorMessage.main" variant='subtitle2'>{questionFetchError}</Typography>}
                </Box>
            </Box>
        </Dialog>
    )
}

export default AuditExamViewer
