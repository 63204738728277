import React, { createContext, useState } from "react"

const LayoutContext = createContext()
const minSidebarCollapseWidth = 900

export const LayoutContextProvider = ( { children } ) => {
    const [menuState, setMenuState] = useState( window.innerWidth < minSidebarCollapseWidth ? false : true )
    const [active, setActive] = useState( window.location.pathname )
    const [sidebarWidth, setSidebarWidth] = useState( 300 )
    const [navHeight, setNavHeight] = useState( 60 )
    const [focusedMenuItem, setFocusedMenuItem] = useState( null )
    const [secondarySidebarStatus, setSecondarySidebarStatus] = useState( false )
    const [sidebarOpenState, setSidebarOpenState] = useState( false )
    const [navHeadText, setNavHeadText] = useState( "" )

    return (
        <LayoutContext.Provider value={{
            navHeadText, setNavHeadText, sidebarOpenState, setSidebarOpenState, secondarySidebarStatus, setSecondarySidebarStatus, menuState, setMenuState, minSidebarCollapseWidth: minSidebarCollapseWidth, active, setActive, sidebarWidth, setSidebarWidth, navHeight, setNavHeight, focusedMenuItem, setFocusedMenuItem
        }}>{children}</LayoutContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useLayoutContext = () => React.useContext( LayoutContext )

export default LayoutContextProvider
