import { Box, Button, IconButton, Paper, Typography, useTheme } from '@mui/material'
import noClassesImg from '../../assets/noclasses.svg'
import React, { useCallback, useEffect, useState } from 'react'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import TryAgainMessageBlock from '../common/TryAgainMessageBlock'
import { useRoomStore } from '../../store/room.store'

import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { Icons } from '../../utils/utilities'
import { toast } from 'react-toastify'
import { useSubjectStore } from '../../store/subject.store'
import api from '../../service/api'


// const classes = [
//     { online !=="0": false, "interval_type_id": "5", "time_table_id": "671659", "sTime": "10:00:00.0000000", "eTime": "10:50:00.0000000", "empID": "AI000914", "selected_date": "2021-05-26", "course_branch_assignment_id": "49", "current_year": "1", "current_sem": "2", "course_assignment_id": "47", "start_time": "10:00 AM", "end_time": "10:50 AM", "section_assignment_id": "1806", "batch_assignment_id": "4281", "subject_assignment_id": "17216", "branch_name": "MATHEMATICS", "branch_id": "66", "duration": "50", "batch_short_name": "Batch-I-K- AU+ME", "section_short_name": "K", "subject_name_short": "MAT 2", "course_branch_short_name": "ME", "course_short_name": "BE", "interval": "10:00 AM-10:50 AM", "institute_id": "1", "institute_name_short": "AIT", "subject_id": "1024", "record": false, "presenter_name": "ANITHA R SOMASHEKAR " },
//     { online !=="0": true, "interval_type_id": "3", "time_table_id": "666255", "sTime": "09:00:00.0000000", "eTime": "09:55:00.0000000", "empID": "AI000797", "selected_date": "2021-06-11", "course_branch_assignment_id": "39", "current_year": "1", "current_sem": "2", "course_assignment_id": "37", "start_time": "09:00 AM", "end_time": "09:55 AM", "section_assignment_id": "1781", "batch_assignment_id": "0", "subject_assignment_id": "16893", "branch_name": "MASTER OF BUSINESS ADMINISTRATION", "branch_id": "73", "duration": "55", "batch_short_name": "NA", "section_short_name": "B", "subject_name_short": "HRM", "course_branch_short_name": "MBA", "course_short_name": "MBA", "interval": "09:00 AM-09:55 AM", "institute_id": "1", "institute_name_short": "AIT", "subject_id": "4516", "record": false, "presenter_name": "JUIN CHOUDHURY" },
//     { online !=="0": false, "interval_type_id": "3", "time_table_id": "685079", "sTime": "13:00:00.0000000", "eTime": "13:50:00.0000000", "empID": "AI000124", "selected_date": "2021-05-27", "course_branch_assignment_id": "7", "current_year": "3", "current_sem": "6", "course_assignment_id": "5", "start_time": "1:00 PM", "end_time": "1:50 PM", "section_assignment_id": "1757", "batch_assignment_id": "0", "subject_assignment_id": "2373", "branch_name": "PHYSICS", "branch_id": "243", "duration": "50", "batch_short_name": "NA", "section_short_name": "A", "subject_name_short": "ELE  - VII", "course_branch_short_name": "PME", "course_short_name": "BSC", "interval": "1:00 PM-1:50 PM", "institute_id": "2", "institute_name_short": "AGS", "subject_id": "912", "record": false, "presenter_name": "SHOBHA G N" },
//     { online !=="0": true, "interval_type_id": "3", "time_table_id": "693249", "sTime": "14:40:00.0000000", "eTime": "16:30:00.0000000", "empID": "AI000646", "selected_date": "2021-06-09", "course_branch_assignment_id": "90", "current_year": "1", "current_sem": "2", "course_assignment_id": "74", "start_time": "2:40 PM", "end_time": "4:30 PM", "section_assignment_id": "1680", "batch_assignment_id": "0", "subject_assignment_id": "16067", "branch_name": "Bachelor Of Architecture", "branch_id": "9", "duration": "110", "batch_short_name": "NA", "section_short_name": "A", "subject_name_short": "HOA - II", "course_branch_short_name": "BARCH", "course_short_name": "B.Arch", "interval": "2:40 PM-4:30 PM", "institute_id": "8", "institute_name_short": "ASA", "subject_id": "701", "record": false, "presenter_name": "FATHIMA SAMANA S" },
//     { online !=="0": false, "interval_type_id": "11", "time_table_id": "681723", "sTime": "11:00:00.0000000", "eTime": "12:10:00.0000000", "empID": "AI000215", "selected_date": "2021-05-25", "course_branch_assignment_id": "94", "current_year": "1", "current_sem": "0", "course_assignment_id": "79", "start_time": "11:00 AM", "end_time": "12:10 PM", "section_assignment_id": "1513", "batch_assignment_id": "4378", "subject_assignment_id": "4150", "branch_name": "Nursing", "branch_id": "11", "duration": "70", "batch_short_name": "A1-IA2 PBBSC", "section_short_name": "A", "subject_name_short": "Invigilation", "course_branch_short_name": "PBNUR", "course_short_name": "PBBSc", "interval": "11:00 AM-12:10 PM", "institute_id": "10", "institute_name_short": "ANR", "subject_id": "4150", "record": false, "presenter_name": "MERCY DEVA PRIYA" },
// ]



const SubjectClasses = () => {

    const [loading, setLoading] = useState( true )
    const [classes, setClasses] = useState( [] )
    const [error, setError] = useState( null )

    const SubjectStore = useSubjectStore()
    const RoomStore = useRoomStore()
    const UserStore = useUserStore()
    const navigate = useNavigate()

    const { palette, theme } = useTheme()

    const startSession = async ( data, flag ) => {

        if ( flag === true ) {
            data["record"] = true
        } else {
            data["record"] = false
        }
        if ( data["sTime"] === undefined || data["sTime"] === null || data["eTime"] === undefined || data["eTime"] === null ) {
            toast.error( "ERP not sending Start Time / End Time" )
            return
        } else if ( checkTime( data["sTime"], data["eTime"] ) === false ) {
            toast.error( `You can only start session from ${data["sTime"].substring( 0, 5 )} to ${data["eTime"].substring( 0, 5 )}` )
            return
        }

        try {
            var res = await RoomStore.startSession( data )
            if ( res["status"] === true ) {
                var userAgent = window.navigator.userAgent.toLowerCase()
                if ( userAgent.match( /ipad/i ) || userAgent.match( /iphone/i ) ) {
                    window.open( res["data"], 'Acharya Live', "width=" + window.innerWidth + ",height=" + window.innerHeight )
                }
                else {
                    RoomStore.setStreamDetails( true, res["data"], data, true )
                    navigate( '/faculty/stream' )
                }
            }
        } catch ( err ) {
            console.log( err )
        }
    }

    const joinSession = async ( data ) => {
        try {
            let res = await RoomStore.joinSessionAsModerator( data )
            if ( res["status"] === true ) {
                //window.open(res["data"],'Acharya Live','height=720,width=1280');

                var userAgent = window.navigator.userAgent.toLowerCase()
                if ( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) ) {
                    // iPad or iPhone
                    window.open( res["data"], 'Acharya Live', "width=" + window.innerWidth + ",height=" + window.innerHeight )
                }
                else {
                    RoomStore.setStreamDetails( true, res["data"], data, true )
                    navigate( '/faculty/stream' )
                }
            }
        } catch ( err ) {
            console.log( err )
        }
    } //endSession

    const joinSessionAsViewer = async ( data ) => {
        try {
            let res = await RoomStore.joinSession( data )
            if ( res["status"] === true ) {
                //window.open(res["data"],'Acharya Live','height=720,width=1280');

                var userAgent = window.navigator.userAgent.toLowerCase()
                if ( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) ) {
                    // iPad or iPhone
                    window.open( res["data"], 'Acharya Live', "width=" + window.innerWidth + ",height=" + window.innerHeight )
                }
                else {
                    RoomStore.setStreamDetails( true, res["data"], data, true )
                    if ( UserStore.getUser['user_role'] === "STUDENT" )
                        navigate( '/student/stream' )
                    else
                        navigate( '/faculty/stream' )
                }
            }
        } catch ( err ) {
            console.log( err )
        }
    }


    const endSession = ( data ) => {
        RoomStore.endSession( data )
    }


    const checkTime = ( sTime, eTime ) => {
        var currentDate = new Date()
        var currentTime = currentDate.toString().split( " " )[4]
        var sTimeSplit = sTime.split( ":" )
        var eTimeSplit = eTime.split( ":" )
        var cTimeSplit = currentTime.split( ":" )
        var sTimeHr = parseInt( sTimeSplit[0] ), sTimeMin = parseInt( sTimeSplit[1] )
        var eTimeHr = parseInt( eTimeSplit[0] ), eTimeMin = parseInt( eTimeSplit[1] )
        var cTimeHr = parseInt( cTimeSplit[0] ), cTimeMin = parseInt( cTimeSplit[1] )
        var startMin = 0, endMin = 0, currentMin = 0
        if ( sTimeHr === 0 ) {
            startMin = sTimeMin
        } else if ( sTimeHr > 0 ) {
            startMin = ( ( 60 * sTimeHr ) + sTimeMin )
        }
        if ( eTimeHr === 0 ) {
            endMin = eTimeMin
        } else if ( eTimeHr > 0 ) {
            endMin = ( ( 60 * eTimeHr ) + eTimeMin )
        }
        if ( cTimeHr === 0 ) {
            currentMin = cTimeMin
        } else if ( cTimeHr > 0 ) {
            currentMin = ( ( 60 * cTimeHr ) + cTimeMin )
        }

        if ( currentMin >= ( startMin - 5 ) && currentMin <= endMin ) {
            return true
        }
        else {
            return false
        }
    }

    const getClasses = useCallback( async () => {
        try {
            setLoading( true )
            await RoomStore.getMyActiveSessions()
            const today = new Date()
            let { data: { data } } = await api.getClassesByDate( `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}` )
            setClasses( data )
            setError( null )
        } catch ( err ) {
            setError( { message: err?.response?.data?.message, code: err?.response?.status } )
        } finally {
            setLoading( false )
        }

    }, [RoomStore] )


    useEffect( () => {
        getClasses()
    }, [getClasses] )


    return (
        <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column">
            {!loading && !error && classes.filter( room => room.subject_id === SubjectStore.getSubject.subject_id ).length > 0 && <Box maxHeight="500px" flexGrow={1} display="flex" flexDirection="column" gap="20px" overflow="auto" bgcolor={palette.greyedOut} padding="20px" className="custom-scrollbar">
                {classes.filter( room => room.subject_id === SubjectStore.getSubject.subject_id ).map( ( room, k ) => {
                    let exist = RoomStore.getActiveRoom.filter( ( item, k ) => {
                        return item.room_session_id === room.time_table_id
                    } ).length
                    // let exist = 1
                    return (
                        <Paper key={room.time_table_id}>
                            <Box gap="20px" padding="5px" display="flex" >
                                <Box display="flex" padding="20px" alignItems="center" borderRadius="5px" sx={{ aspectRatio: 1 / 1 }} justifyContent="center" bgcolor={theme === 'dark' ? palette.greyed.dark : palette.greyed.light}>
                                    {Icons.default.OndemandVideoIconOutlined}
                                </Box>
                                <Box flexGrow={1} padding="5px 0">
                                    <Typography variant="h6" fontSize="16px"><strong>{room.subject_name_short}</strong></Typography>
                                    <Typography variant="subtitle2" fontSize="12px">{room.interval}</Typography>
                                    <Box display="flex" gap="5px" alignItems="center">
                                        <Typography fontSize="12px" textTransform="capitalize" variant='body2'>{room.branch_name?.toLowerCase()}</Typography>
                                        <Box width="5px" height="5px" bgcolor={palette.grey[500]} borderRadius="50%"></Box>
                                        <Typography fontSize="12px" variant='body2'>
                                            {room["interval_type_id"] === "11" ? 'Internal' : ''}
                                            {room["interval_type_id"] === "10" ? 'Online Class' : ''}
                                            {room["interval_type_id"] === "9" ? 'Internship' : ''}
                                            {room["interval_type_id"] === "8" ? 'Studio' : ''}
                                            {room["interval_type_id"] === "5" ? 'Elective Lecture Class' : ''}
                                            {room["interval_type_id"] === "4" ? 'Lab' : ''}
                                            {room["interval_type_id"] === "3" ? 'Lecture Class' : ''} By {room?.presenter_name?.toLowerCase()}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box paddingRight="15px" display="flex" alignItems="center">
                                    {room.status === 'Completed' ? <Typography fontSize="14px" color='error' textAlign="right" fontWeight="bold">Session Completed</Typography>
                                        :
                                        room.online !== "0" ? <Box display="flex" justifyContent="flex-start" alignItems="center" gap="10px">
                                            {
                                                // room.room_status === 'online'
                                                ( exist > 0 ) ?
                                                    <Button sx={{ paddingX: "20px", textTransform: "capitalize" }} size="medium" onClick={( e ) => endSession( room )} color="error">
                                                        End
                                                    </Button> : ''
                                            }
                                            {
                                                // room.room_status === 'online'
                                                ( exist > 0 && room.empID === UserStore.getUser["user_id"] ) ?
                                                    <Button sx={{ paddingX: "20px", textTransform: "capitalize" }} size="medium" onClick={( e ) => joinSession( room )}
                                                        color="success">
                                                        Join Class
                                                    </Button> : ''
                                            }
                                            {
                                                // room.room_status === 'online'
                                                ( room.empID !== UserStore.getUser["user_id"] ) ?
                                                    <Button sx={{ paddingX: "20px", textTransform: "capitalize" }} size="medium" onClick={( e ) => joinSessionAsViewer( room )}
                                                        color="success">
                                                        Join Class
                                                    </Button> : ''
                                            }
                                            {
                                                // room.room_status === 'online'
                                                ( exist === 0 && room.empID === UserStore.getUser["user_id"] ) ?
                                                    <Button sx={{ paddingX: "20px", textTransform: "capitalize" }} size="medium" color="primary" onClick={( e ) => startSession( room, false )}
                                                        //disabled={RoomStore.getActiveRoom.length > 0}
                                                        endIcon={Icons.default.ChevronRightIcon}>
                                                        start
                                                    </Button> : ''
                                            }
                                            {
                                                // room.room_status === 'online'
                                                ( exist === 0 && room.empID === UserStore.getUser["user_id"] ) ?
                                                    <IconButton color="primary" onClick={( e ) => startSession( room, true )} aria-label="Start & Recording Session">
                                                        {Icons.default.PlayCircleFilledWhiteIcon}
                                                    </IconButton> : ''
                                            }
                                        </Box> :
                                            <Typography color="errorMessage.light" padding="0 20px" variant='subtitle2'>Offline</Typography>
                                    }

                                </Box>
                            </Box>
                        </Paper>
                    )
                } )}
            </Box>}


            {!loading && !error && classes?.filter( room => room.subject_id === SubjectStore.getSubject.subject_id ).length === 0 &&
                <Box flexGrow={1} display="flex" alignItems="center" padding="20px" flexDirection="column" gap="10px" justifyContent="center" >
                    <img src={noClassesImg} width="200px" alt="No Exams" />
                    <Typography variant="subtitle2" >No classes for <em> <strong>'{SubjectStore.getSubject.subject_name}'</strong> </em> today!</Typography>
                </Box>
            }
            {loading && !error && <DataLoadingSpinner waitingMessage="Loading classes please wait..." />}
            {error && <TryAgainMessageBlock err={error.message} code={error.code} loading={loading} getData={getClasses} />}
        </Box>
    )
}

export default SubjectClasses
