import { Avatar, Box, Button, Card, Typography, useTheme } from '@mui/material'
import { useUserStore } from '../../store/user.store'
import React, { useCallback, useEffect, useState } from 'react'
import badgeImg from '../../assets/badge.svg'
// import attendanceImg from '../../assets/card-images/attendance-img.png'
import resumeImg from '../../assets/card-images/resume-complete.png'
import api from '../../service/api'
import SubjectsCard from '../../components/dashboard-components/SubjectsCard'
import { Icons } from '../../utils/utilities'
import { useNavigate } from 'react-router-dom'
import DiscussionsCard from '../../components/dashboard-components/DiscussionsCard'
import PendingOrRecentAssignmentsCard from '../../components/dashboard-components/PendingOrRecentAssignmentsCard'
import CurrentOrRecentExams from '../../components/dashboard-components/CurrentOrRecentExams'
import WeekCalendar from '../../components/dashboard-components/WeekCalendar'
import AcademicsProgressCard from '../../components/dashboard-components/AcademicsProgressCard'
import { toast } from 'react-toastify'
import LeaderboardCard from '../../components/dashboard-components/LeaderboardCard'

const StudentDashboard = () => {

    const [profileProgress, setProfileProgress] = useState( -1 )

    const UserStore = useUserStore()
    const navigate = useNavigate()

    const { theme, palette } = useTheme()

    const getStudentProfileProgress = useCallback( async () => {
        try {
            const { data } = await api.fetchStudentResumeProgress()
            if ( data?.code !== "NOFORM" ) {
                setProfileProgress( data.data )
            }
        } catch ( err ) {
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            }
            else
                toast( err?.response?.data?.message || "Something went wrong! try again later" )
        }
    }, [UserStore] )

    useEffect( () => {
        getStudentProfileProgress()
    }, [getStudentProfileProgress] )

    return (
        <Box flexGrow={1} display="flex" flexDirection="column" gap="20px" overflow="auto" padding="20px">
            <Box display={{ xs: "flex", md: "grid" }} gridTemplateColumns="2fr 1fr" gap="20px">
                <Box display="flex" flexShrink={0} gridTemplateColumns="1fr 1fr" gap="20px">
                    <Card
                        sx={{
                            flexShrink: "0",
                            background: theme === 'dark' ? palette.common.bg : `linear-gradient(328.48deg, #00A917 37.65%, #D5DF3A 92.12%)`,
                        }}>
                        <Box
                            borderRadius="10px"
                            flexGrow={1}
                            padding="20px"
                        >
                            <Typography textAlign={{ md: "left", xs: "center" }} variant="h6" gutterBottom fontSize="20px" color="#fff" fontWeight="700">Profile</Typography>
                            <Box flexWrap="wrap" display="flex" color="#fff" gap="30px" justifyContent="space-between" alignItems="center" >
                                <Box display="flex" flexDirection={{ md: "row", xs: "column" }} gap="20px" alignItems="center" >
                                    <Avatar src={UserStore.getUser.user_profile} sx={{ width: "80px", height: "80px", borderRadius: "10px" }} />
                                    <Box display="flex" flexGrow={1} flexDirection="column" alignItems={{ md: "initial", xs: "center" }} justifyContent="space-between" >
                                        <Typography textAlign={{ md: "left", xs: "center" }} fontSize="22px" fontWeight={500} textTransform="capitalize">{UserStore.getUser.user_name}</Typography>
                                        <Typography textAlign={{ md: "left", xs: "center" }} fontSize="12px">{UserStore.getUser?.branch} branch</Typography>
                                        <Typography textAlign={{ md: "left", xs: "center" }} fontSize="12px">{UserStore.getUser?.user_org_code}</Typography>
                                    </Box>
                                </Box>
                                <Box display={{ md: "flex", sm: "none" }} flexDirection="column" gap="10px" alignItems="center" >
                                    <img src={badgeImg} width="70px" alt="Badge" />
                                    <Typography color="white" fontWeight="bold" sx={{ background: "linear-gradient(105.99deg, #139CFF -3.04%, #151D48 80.56%)" }} fontSize="10px" borderRadius="5px" padding="5px 20px">
                                        GRADE
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                    <AcademicsProgressCard />
                </Box>
                <LeaderboardCard />


            </Box>

            <Box display="grid" gridTemplateColumns="2fr 1fr" gap="20px">
                <Box flexGrow={1} display="flex" flexDirection="column" gap="20px">
                    <WeekCalendar shadow />
                    <Box display="grid" gap="20px" gridTemplateColumns={{ md: "1fr 1fr", xs: "1fr" }}>
                        <PendingOrRecentAssignmentsCard />
                        <Box display="grid" gap="20px" gridTemplateColumns={{ md: "1fr", xs: "1fr" }}>
                            {/* <OngoingOnlineCoursesCard /> */}
                            <CurrentOrRecentExams />
                        </Box>
                    </Box>


                    <Box display="grid" gap="20px" gridTemplateColumns={{ md: "1fr", xs: "1fr" }}>



                        <Card elevation={1}>
                            <Box borderRadius="10px" padding="20px" >
                                <Typography gutterBottom fontSize="20px" fontWeight="700">Build your resume</Typography>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box flexGrow={1}>
                                        {profileProgress !== 100 && <Typography gutterBottom variant="subtitle2">Complete your resume</Typography>}
                                        <Box overflow="hidden" bgcolor="#CBD9CD" width="80%" height="10px" borderRadius="10px">
                                            <Box bgcolor="#22DD3B" borderRadius="0 10px 10px 0" height="100%" width={`${0}%`}></Box>
                                        </Box>
                                        <Box marginTop="20px">
                                            {profileProgress !== 100 && <Button onClick={() => navigate( '/student/resume-builder' )} disableElevation endIcon={Icons.small.ChevronRightIcon} variant="contained" sx={{ marginTop: "5px", textTransform: "capitalize" }} size='small' color='error'>
                                                Continue
                                            </Button>}
                                            {profileProgress === 100 && <Button onClick={() => navigate( '/student/resume-builder' )} disableElevation endIcon={Icons.small.ChevronRightIcon} variant="contained" sx={{ marginTop: "5px", textTransform: "capitalize" }} size='small' color='error'>
                                                Get resume
                                            </Button>}
                                        </Box>
                                    </Box>
                                    <img src={resumeImg} alt="Complete your resume" />
                                </Box>
                            </Box>
                        </Card>

                    </Box>

                </Box>
                <Box display="flex" flexDirection="column" gap="20px">
                    <SubjectsCard shadow />
                    <DiscussionsCard />
                </Box>
            </Box>
        </Box >
    )
}

export default StudentDashboard


// const getCalendarEvents = useCallback( async () => {
//     try {
//         const events = {}
//         try {
//             const { data: { data: examEvents } } = await api.fetchExamEvents()
//             examEvents.forEach( event => {
//                 const date = showDateInIndianDateFormat( event.from_time, { month: "numeric", day: "numeric" } )
//                 if ( events[date] ) {
//                     if ( !events[date]["eventsPresent"].includes( "exam" ) )
//                         events[date]["eventsPresent"].push( "exam" )
//                     events[date]["events"].push( { eventType: "exam", details: { ...event, id: event.exam_id, title: event.exam_name, from: event.from_time, to: event.to_time } } )
//                 } else {
//                     events[date] = {}
//                     events[date]["eventsPresent"] = ["exam"]
//                     events[date]["events"] = [
//                         { eventType: "exam", details: { ...event, id: event.exam_id, title: event.exam_name, from: event.from_time, to: event.to_time } }
//                     ]
//                 }

//             } )
//         } catch ( err ) { }

//         try {
//             const { data: { data: assignmentEvents } } = await api.fetchAssignmentEvents()
//             assignmentEvents.forEach( event => {
//                 const date = showDateInIndianDateFormat( event.assignment_created_at, { month: "numeric", day: "numeric" } )
//                 if ( events[date] ) {
//                     if ( !events[date]["eventsPresent"].includes( "assignment" ) )
//                         events[date]["eventsPresent"].push( "assignment" )
//                     events[date]["events"].push( { eventType: "assignment", details: { ...event, title: event.assignment_name, from: event.assignment_created_at, to: event.assignment_due_date } } )
//                 } else {
//                     events[date] = {}
//                     events[date]["eventsPresent"] = ["assignment"]
//                     events[date]["events"] = [
//                         { eventType: "assignment", details: { ...event, title: event.assignment_name, from: event.assignment_created_at, to: event.assignment_due_date } }
//                     ]
//                 }

//             } )
//         } catch ( err ) { }
//         if ( RoomStore.getRoom.length > 0 ) {
//             for ( let i = 0; i < RoomStore.getRoom.length; i++ ) {
//                 const room = RoomStore.getRoom[i]
//                 const date = showDateInIndianDateFormat()
//                 if ( events[date] ) {
//                     if ( !events[date]["eventsPresent"].includes( "class" ) )
//                         events[date]["eventsPresent"].push( "class" )
//                     events[date]["events"].push( { eventType: "class", details: { room } } )
//                 } else {
//                     events[date] = {}
//                     events[date]["eventsPresent"] = ["class"]
//                     events[date]["events"] = [
//                         { eventType: "class", details: { room } }
//                     ]
//                 }
//             }
//         }
//         setCalendarEvents( events )
//     } catch ( err ) {

//     } finally {
//         setAreEventsLoaded( true )
//     }
// }, [RoomStore] )


/* <Card elevation={1}>
                            <Box borderRadius="10px" padding="20px" >
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography gutterBottom fontSize="20px" fontWeight="700">Attendance</Typography>
                                    <img width="50px" src={attendanceImg} alt="Attendance" />
                                </Box>
                                <Box padding="20px 0" display="flex" alignItems="center" justifyContent="space-between">
                                    <Progress size={70} progress={79} color="#FF6C8F" secondaryColor='#EDF0EE' />
                                    <Box>
                                        <Typography gutterBottom borderLeft="4px solid #EDF0EE" paddingLeft="10px" variant="subtitle2" textTransform="capitalize">Total</Typography>
                                        <Typography borderLeft="4px solid #FF6C8F" paddingLeft="10px" variant="subtitle2" textTransform="capitalize">Attendance</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Card> */