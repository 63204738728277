import { Box, Button, Dialog, DialogContent, Typography, useTheme } from '@mui/material'
import { useLayoutContext } from '../../contexts/layout.context'
import AuditingSideDrawer from './AuditingSideDrawer'
import Navbar from '../presenter/layout/PresenterNavbar'
import sessionEndedImg from '../../assets/waiting.svg'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAuthStore } from '../../store/auth.store'
import React, { useCallback, useEffect } from 'react'
import { LoginOutlined } from '@mui/icons-material'
import { observer } from 'mobx-react'
import DownloadStatusListDialog from '../study-material-components/DownloadStatusListDialog'


const AuditingLayout = observer( ( { navHeadText } ) => {

    const { navHeight, minSidebarCollapseWidth, setMenuState } = useLayoutContext()


    const handleResizeOrLoad = useCallback( () => {
        if ( window.innerWidth < minSidebarCollapseWidth )
            setMenuState( false )
        else {
            setMenuState( true )
        }
    }, [minSidebarCollapseWidth, setMenuState] )

    const AuthStore = useAuthStore()
    const navigate = useNavigate()
    const { palette } = useTheme()

    useEffect( () => {
        window.addEventListener( 'resize', handleResizeOrLoad )
        window.addEventListener( 'load', handleResizeOrLoad )
        return () => {
            window.removeEventListener( 'resize', handleResizeOrLoad )
            window.removeEventListener( 'load', handleResizeOrLoad )
        }
    }, [handleResizeOrLoad] )

    return (
        <Box display="flex" height="100%" bgcolor={palette.layoutBackground} overflow="auto">
            <AuditingSideDrawer />
            <Box
                flexGrow={1}
                display="flex"
                minWidth="0"
                position="relative"
            >
                <Navbar navHeadText={navHeadText} closeSideMenu={() => { }} openSideMenu={() => { }} />
                {AuthStore.getErpSessionState !== "ended" &&
                    <Box
                        flexGrow={1}
                        display="flex"
                        maxHeight="100%"
                        id="page-content"
                        paddingTop={`${navHeight}px`}
                        minWidth="0"
                    >
                        <Outlet />
                    </Box>}
            </Box>
            <Dialog open={AuthStore.getErpSessionState === "ended"}>
                <DialogContent >
                    <Box display="flex" alignItems="center" gap="20px" flexDirection="column">
                        <img width="200px" src={sessionEndedImg} alt="Session ended!" />
                        <Typography textAlign="center" variant='subtitle2'>Your ERP session seems to have ended! did you log in using ERP credentials somewhere else? please login again.</Typography>
                        <Button onClick={() => navigate( `/?redirect=${window.location.pathname}` )} startIcon={<LoginOutlined fontSize='small' />} variant='outlined' sx={{ textTransform: "capitalize" }}>Sign In</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <DownloadStatusListDialog />
        </Box>
    )
} )

export default AuditingLayout
