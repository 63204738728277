import { Box, Button, Chip, Dialog, DialogActions, Radio, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, RadioGroup, TextField, Typography, Paper } from '@mui/material'
import React, { useEffect } from 'react'
import api from '../../../service/api'
import { useState } from 'react'
import { Icons } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import { useUserStore } from '../../../store/user.store'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import BlockMessage from '../../../components/common/BlockMessage'

const FeedbackSettings = () => {

    const [feedbackQuestions, setFeedbackQuestions] = useState( [] )
    const [isLoading, setIsLoading] = useState( true )
    const [dialogOpen, setDialogOpen] = useState( false )
    const [questionText, setQuestionText] = useState( "" )
    const [questionType, setQuestionType] = useState( "rating" )
    const [isAddingQuestion, setIsAddingQuestion] = useState( false )
    const [isUpdatingQuestion, setIsUpdatingQuestion] = useState( false )
    const [selectedQuestion, setSelectedQuestion] = useState( null )
    const [mode, setMode] = useState( "add" )
    const [isArchivingQuestion, setIsArchivingQuestion] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )

    const UserStore = useUserStore()

    const archiveFeedbackQuestion = async () => {
        setIsArchivingQuestion( true )
        try {
            await api.deleteFeedbackQuestion( selectedQuestion.feedback_setting_id )
            const res = await api.fetchFeedbackQuestions()
            setFeedbackQuestions( res.data.data )
            toast.success( "Successfully archived question" )
        } catch ( err ) {
            console.log( err )
        } finally {
            setIsArchivingQuestion( false )
            setDialogOpen( false )
        }
    }

    const addFeedbackQuestion = async () => {
        if ( questionText.trim() !== "" ) {
            setIsAddingQuestion( true )
            try {
                const res = await api.addFeedbackQuestion( { question: questionText, type: questionType } )
                setFeedbackQuestions( ( p ) => [...p, { question: questionText, type: questionType }] )
                setQuestionText( "" )
                setQuestionType( "rating" )
                toast.success( res.data.data )
            } catch ( err ) {
                console.log( err )
            } finally {
                setIsAddingQuestion( false )
            }
        }
    }

    const updateFeedbackQuestion = async () => {
        if ( questionText.trim() !== "" ) {
            setIsUpdatingQuestion( true )
            try {
                await api.updateFeedbackQuestion( selectedQuestion.feedback_setting_id, { question: questionText, type: questionType } )
                setQuestionText( "" )
                setQuestionType( "rating" )
                setDialogOpen( false )
                const res = await api.fetchFeedbackQuestions()
                setFeedbackQuestions( res.data.data )
                toast.success( res.data.data )
            } catch ( err ) {
                console.log( err )
            } finally {
                setIsUpdatingQuestion( false )
            }
        }
    }

    const handleQuestionClick = ( question ) => {
        setQuestionText( question.question )
        setSelectedQuestion( question )
        if ( question.enable_rating && question.enable_descriptive )
            setQuestionType( "both" )
        else if ( question.enable_descriptive )
            setQuestionType( "descriptive" )
        else
            setQuestionType( "rating" )
        setMode( "edit" )
        setDialogOpen( true )
    }

    useEffect( () => {
        const getData = async () => {
            try {
                const res = await api.fetchFeedbackQuestions()
                setFeedbackQuestions( res.data.data )
                console.log( res.data.data )
            } catch ( err ) {
                console.log( err )
            } finally {
                setIsLoading( false )
            }
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.feedback ) {
            setHasPermission( false )
        } else
            getData()
    }, [UserStore] )

    return (
        hasPermission ? <Box padding="20px" display="flex" flexDirection="column" overflow="auto" flexGrow={1}>
            <Dialog PaperProps={{ sx: { maxWidth: "600px", width: "90%" } }} open={dialogOpen}>
                <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>
                    Add Feedback Questions
                </DialogTitle>
                <DialogContent>
                    <Typography marginTop="10px" fontWeight="500">Add new question</Typography>
                    <FormControl margin='normal' fullWidth>
                        <TextField onChange={( e ) => setQuestionText( e.target.value )} type='text' placeholder='Question goes here...' value={questionText} />
                    </FormControl>
                    <FormControl>
                        <FormLabel sx={{ fontSize: "14px" }}>Select question type</FormLabel>
                        <RadioGroup onChange={( e ) => setQuestionType( e.target.value )} row value={questionType} name="radio-buttons-group">
                            <FormControlLabel value="rating" control={<Radio size="small" />} label="Rating" />
                            <FormControlLabel value="descriptive" control={<Radio size="small" />} label="Descriptive" />
                            <FormControlLabel value="both" control={<Radio size="small" />} label="Both" />
                        </RadioGroup>
                    </FormControl>
                    {mode === "add" && <Button variant='contained' disabled={isAddingQuestion} onClick={addFeedbackQuestion} sx={{ textTransform: "capitalize", display: "block", marginY: "10px" }}>{isAddingQuestion ? "Adding new question..." : "Add question"}</Button>}
                    {mode !== "add" && <Box display="flex">
                        <Button variant='contained' disabled={( isUpdatingQuestion || isArchivingQuestion )} onClick={updateFeedbackQuestion} sx={{ textTransform: "capitalize", margin: "0 10px 10px 0", display: "block", marginY: "10px" }}>{isUpdatingQuestion ? "Updating new question..." : "Update question"}</Button>
                        <Button variant='contained' color="error" disabled={( isArchivingQuestion || isUpdatingQuestion )} onClick={archiveFeedbackQuestion} sx={{ textTransform: "capitalize", display: "block", marginY: "10px" }}>{isArchivingQuestion ? "Archiving question..." : "Archive"}</Button>
                    </Box>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setQuestionText( "" ); setQuestionType( "rating" ); setDialogOpen( false ) }} sx={{ textTransform: "capitalize" }} color="error">Close</Button>
                </DialogActions>
            </Dialog>
            <Paper sx={{ flexGrow: ( isLoading || ( feedbackQuestions.length === 0 && !isLoading ) ) ? "initial" : "1" }}>
                <Box padding="20px" borderBottom="1px solid #d3d3d3" display="flex" alignItems="center" gap="10px" justifyContent="space-between">
                    <Box>
                        <Typography variant='h5' >Feedback Questions</Typography>
                        <Typography variant='subtitle2' color="textSecondary">Manage the feedback questions for live classes</Typography>
                    </Box>
                    <Button sx={{ textTransform: "capitalize" }} variant="contained" onClick={() => { setMode( "add" ); setDialogOpen( true ) }} startIcon={Icons.default.AddIcon} color="primary">
                        Create
                    </Button>
                </Box>
                {!isLoading && feedbackQuestions.length > 0 && <Box padding="20px">
                    {feedbackQuestions.map( ( question, k ) => {
                        if ( question.status === 'active' )
                            return <Box key={question.feedback_setting_id} onClick={() => handleQuestionClick( question )} display="flex" border="1px solid rgba(0,0,0,0.1)" padding="20px" borderRadius="5px" flexWrap="wrap" gap="5px" marginBottom="5px" sx={{ transition: "box-shadow 0.2s", cursor: "pointer", "&:hover": { boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1)" } }}>
                                <Typography>{`${k + 1}. ${question.question}`}</Typography>
                                {question.type && <Chip size='small' label={question.type} />}
                                {question.enable_rating && <Chip size='small' label="rating" />}
                                {question.enable_descriptive && <Chip size='small' label="descriptive" />}
                            </Box>
                        else return ""
                    } )}
                </Box>}
                {feedbackQuestions.length === 0 && !isLoading && <Typography variant='subtitle2' color="textSecondary" padding="20px">No questions added yet!</Typography>}
                {isLoading && <DataLoadingSpinner waitingMessage="Loading feedback settings..." />}
            </Paper>
        </Box>
            : <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>

    )
}

export default FeedbackSettings
