import { Typography, Box } from '@mui/material'
import sessionEndedImg from '../../assets/warning.svg'

const EmbeddBbb = () => {
    return (
        <Box flexGrow={1}>
            <Box id="embed-frame-xclass">
                {/* {JSON.stringify(props.RoomStore.getStreamDetails)} */}
                {
                    //props.RoomStore.getStreamDetails.is_stream?
                    localStorage.getItem( 'active_session' ) ?
                        <iframe title='streem window' type="text/html" id="embedd-bbb" allow="microphone;camera;display-capture;" allowFullScreen
                            src={atob( localStorage.getItem( 'active_session' ) )}
                        /> :
                        <Box >
                            <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '70vh' }}>
                                <Box textAlign="center">
                                    <img src={sessionEndedImg} alt="Session ended" style={{ marginBottom: "30px" }} width="60"></img>
                                    <br></br>
                                    <Typography variant='h5'>Your session has ended!</Typography>
                                    <Typography>Restart your session</Typography>
                                    {/* <button className="btn btn-primary"> Go To Home</button> */}
                                </Box>

                            </Box>
                        </Box>
                }

            </Box>
        </Box>
    )
}

export default EmbeddBbb
