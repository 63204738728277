import { Box } from '@mui/material'
import React from 'react'
import ResourcesCount from '../../components/audit/dashboard/ResourcesCount'
import ExamsLast30Details from '../../components/audit/dashboard/ExamsLast30Details'
import AssignmentLast30Details from '../../components/audit/dashboard/AssignmentLast30Details'
import StudyMaterialsLast30Details from '../../components/audit/dashboard/StudyMaterialsLast30Details'
import QuizzesLast30Details from '../../components/audit/dashboard/QuizzesLast30Details'


const AuditDashboard = () => {
    return (
        <Box padding="20px" display="flex" flexDirection="column" gap="20px" flexGrow={1} overflow="auto">
            <ResourcesCount />
            <AssignmentLast30Details />
            <ExamsLast30Details />
            <QuizzesLast30Details />
            <StudyMaterialsLast30Details />
        </Box>
    )
}

export default AuditDashboard
