import { Icons, showDateInIndianDateFormat } from '../../utils/utilities'
import SubmitAssignmentDialog from '../user/SubmitAssignmentDialog'
import { useAssignmentStore } from '../../store/assignment.store'
import React, { useCallback, useEffect, useState } from 'react'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { useUserStore } from '../../store/user.store'
import { Box, IconButton, Tab, Card, Tabs, Tooltip, Typography, useTheme } from '@mui/material'
import noTasksImg from '../../assets/noassignment.svg'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { SubjectIcon } from '../../assets/custom-icons'
import AssignmentViewer from '../common/AssignmentViewer'

const PendingOrRecentAssignmentsCard = observer( ( { title, subject } ) => {

    const [tabValue, setTabValue] = useState( "pending" )
    const [openAssignmentSubmission, setOpenAssignmentSubmission] = useState( false )
    const [selectedAssignmentData, setSelectedAssignmentData] = useState( {} )
    const [isAssignmentsLoaded, setIsAssignmentsLoaded] = useState( false )
    const [assignmentDetailsViewerAssignment, setAssignmentDetailsViewerAssignment] = useState( null )

    const AssignmentStore = useAssignmentStore()
    const UserStore = useUserStore()

    const { palette } = useTheme()

    const navigate = useNavigate()

    const getAssignments = useCallback( async () => {
        await AssignmentStore.fetchDueOrRecentAssignments( subject ? subject.subject_id : undefined )
        setIsAssignmentsLoaded( true )
    }, [AssignmentStore, subject]
    )

    const handleClickOpenAssignmentSubmission = ( value ) => {
        if ( UserStore.getUser["user_role"] === "STUDENT" ) {
            setSelectedAssignmentData( value )
            setOpenAssignmentSubmission( true )
        } else navigate( `/faculty/assignment/${value.assignment_id}` )
    }

    const handleCloseAssignmentSubmission = ( value ) => {
        setOpenAssignmentSubmission( false )
        setSelectedAssignmentData( value )
    }

    const handleTabChange = ( e, val ) => {
        setTabValue( val )
    }

    const assignmentFileterFunc = a => {
        if ( UserStore.getUser.user_role === 'STUDENT' ) {
            let dueDate = new Date( a.assignment_due_date )
            dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )
            const pending = +a.submission_exists === 0 && dueDate > new Date()
            return tabValue === 'pending' ? pending : !pending
        } else {
            if ( tabValue === 'pending' ) {
                let dueDate = new Date( a.assignment_due_date )
                dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )
                return dueDate > new Date()
            }
            else
                return true
        }
    }

    useEffect( () => {
        const getData = () => {
            getAssignments()
        }
        getData()
    }, [getAssignments] )
    // boxShadow="1px 1px 5px 0px rgba(0,0,0,0.1)"
    return (
        <Card sx={{ flexGrow: 1 }} elevation={1}>
            <Box display="flex" overflow="hidden" height="100%" flexDirection="column" minWidth="200px" flexGrow={1} borderRadius="10px">

                <SubmitAssignmentDialog getAssignments={getAssignments} selectedValue={selectedAssignmentData} open={openAssignmentSubmission} onClose={handleCloseAssignmentSubmission} />
                <AssignmentViewer loaded={true} selectedValue={assignmentDetailsViewerAssignment} open={Boolean( assignmentDetailsViewerAssignment )} onClose={() => setAssignmentDetailsViewerAssignment( null )} />

                <Box display="flex" height="30px" alignItems="center" padding="20px" justifyContent="space-between">
                    <Link className='default-link' to={UserStore.getUser["user_role"] === "STUDENT" ? `/student/assignment` : `/faculty/assignment`}>
                        <Typography fontSize="20px" fontWeight="700" display="flex" alignItems="center" gap="10px">
                            {title || "Assignments"}
                        </Typography>
                    </Link>
                    {AssignmentStore.getDueOrRecentAssignments.length > 0 && <Typography variant='subtitle2' width="20px" fontWeight="bold" display="flex" alignItems="center" justifyContent="center" height="20px" borderRadius="50%" border="1px solid black">{AssignmentStore.getDueOrRecentAssignments.length}</Typography>}
                </Box>
                <Box display="flex" flexGrow={1} flexDirection="column">
                    {isAssignmentsLoaded && AssignmentStore.getDueOrRecentAssignments.length > 0 && <Box height="100%" width="100%" display="flex" justifyContent="flex-start" flexDirection="column">
                        <Box position="relative" sx={{ "&::after": { content: `''`, height: "2px", background: "#D9D9D9", width: "100%", position: "absolute", left: "0", bottom: "0", zIndex: 1 } }} marginTop="-20px">
                            <Tabs sx={{ position: "relative", zIndex: 2 }} scrollButtons="auto" variant='scrollable' value={tabValue} onChange={handleTabChange}>
                                <Tab value="pending"
                                    label={
                                        <Box display="flex" alignItems="center" gap="10px" fontWeight="700" fontSize="14px" textTransform="capitalize">
                                            Pending
                                            <Typography variant='subtitle2' fontSize='10px' width="15px" fontWeight="bold" display="flex" alignItems="center" justifyContent="center" height="15px" borderRadius="50%" border="1px solid black">
                                                {AssignmentStore.getDueOrRecentAssignments.filter( a => {
                                                    let today = new Date()
                                                    today = new Date( today.getFullYear(), today.getMonth() - 1, today.getDate() )
                                                    return !( today > new Date( String( a.assignment_due_date ).split( "T" )[0] ) )
                                                } ).length}
                                            </Typography>
                                        </Box>} />
                                <Tab value="all" label={<Typography fontWeight="700" variant='subtitle2' textTransform="capitalize">Completed</Typography>} />
                            </Tabs>
                        </Box>
                        {AssignmentStore.getDueOrRecentAssignments
                            .filter( assignmentFileterFunc )
                            .map( ( item, index ) => {

                                let dueDate = new Date( item.assignment_due_date )
                                dueDate = new Date( dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate() + 1 )
                                return (
                                    <Box
                                        key={item.assignment_id}
                                        sx={{
                                            borderRadius: "0",
                                            alignItems: "flex-start",
                                            display: "flex",
                                            gap: "15px",
                                            padding: "10px 20px",
                                            transition: "0.1s ease"
                                        }}>
                                        <Box marginTop="5px">
                                            <img width="20px" src={SubjectIcon} alt="Assignment img" />
                                        </Box>
                                        <Box onClick={() => setAssignmentDetailsViewerAssignment( item )} minWidth={0} overflow="hidden" flexGrow={1} display="flex" flexDirection="column" alignItems="flex-start">
                                            <Typography noWrap width="100%" fontSize="16px" textTransform="capitalize" title={item.assignment_name} fontWeight="bold">{item.assignment_name}</Typography>
                                            <Typography noWrap fontSize="12px" >{item.author_name}</Typography>
                                            {item?.assignment_subject?.subject_name && <Typography color="textSecondary" fontSize="12px" fontWeight="bold" >{item?.assignment_subject?.subject_name}</Typography>}
                                            <Typography color="textSecondary" fontSize="12px" fontWeight="bold" >Due: {showDateInIndianDateFormat( item.assignment_due_date, { month: "short" } )}</Typography>
                                            <Typography fontSize="10px" >Created on: {showDateInIndianDateFormat( item.assignment_created_at, { month: "short" } )}</Typography>
                                        </Box>
                                        {UserStore.getUser.user_role === 'STUDENT' && +item?.submission_exists === 0 && dueDate > new Date() && <Tooltip title="Submit assignment">
                                            <IconButton sx={{ marginTop: "-4px" }} onClick={( e ) => handleClickOpenAssignmentSubmission( item )} size="small">
                                                {Icons.default.Upload}
                                            </IconButton>
                                        </Tooltip>}
                                        {UserStore.getUser.user_role !== 'STUDENT' && <Tooltip placement='top' title="View Submissions">
                                            <Box sx={{ cursor: "pointer" }} onClick={e => handleClickOpenAssignmentSubmission( item )} display="flex" gap="10px" flexDirection="column" alignItems="center">
                                                <Typography variant='body2' fontSize="12px">Submissions</Typography>
                                                <Box display="flex" sx={{ aspectRatio: 1 }} alignItems="center" justifyContent="center" height="60px" width="60px" borderRadius="50%" border={`6px solid ${palette.error.main}`} >
                                                    <Typography fontWeight="800" color="error" variant='h5'>{item?.submission_count || 0}</Typography>
                                                </Box>
                                            </Box>
                                        </Tooltip>}
                                    </Box>
                                )
                            } )}
                    </Box>}
                    {( !isAssignmentsLoaded ) && <Box display="flex" justifyContent="center">
                        <DataLoadingSpinner />
                    </Box>}
                    {isAssignmentsLoaded && AssignmentStore.getDueOrRecentAssignments.filter( assignmentFileterFunc ).length === 0 && <Box padding="20px" display="flex" flexGrow={1} alignItems="center" flexDirection="column" gap="20px" justifyContent="center">
                        <img width="150px" src={noTasksImg} alt="No pending tasks" />
                        <Typography variant="subtitle2">Relax no {tabValue === 'pending' && ""}  assignments!</Typography>
                    </Box>}
                </Box>
            </Box>
        </Card>
    )
} )

export default PendingOrRecentAssignmentsCard
