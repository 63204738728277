import { Upload } from '@mui/icons-material'
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableRow, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useCallback, useEffect, useState } from 'react'
import TableHeader from '../../common/TableHeader'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { leadingZeroFormatter } from '../../../utils/utilities'
import CustomTableColumnLabel from '../../academic-program/CustomTableColumnLabel'
import api from '../../../service/api'
import { exportDiscussionsAuditReport } from '../../../utils/auditing-export-utilities'
import NoDatImg from '../../../assets/no-data.svg'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'


const sortByProperty = ( data, setData, property, type, order ) => {
    let newFilteredData = [...data]
    newFilteredData.sort( ( a, b ) => {
        if ( type === "number" ) {

            if ( order === "1" )
                return ( parseInt( a[property] ) - parseInt( b[property] ) )
            else
                return ( parseInt( b[property] ) - parseInt( a[property] ) )
        } else {

            const keys = property.split( "." )

            const aValue = a[keys[0]][keys[1]]
            const bValue = b[keys[0]][keys[1]]

            if ( order === "1" ) {
                return ( ( aValue < bValue ) ? -1 : 0 )
            }
            else
                return ( ( aValue > bValue ) ? -1 : 0 )
        }
    } )
    setData( newFilteredData )
}


const DiscussionsStats = () => {

    const [searchParams, setSearchParams] = useSearchParams( { sortByCommentsCount: "-1", sortByVotesCount: "-1", searchText: "", month: "", selectedInstitute: "all", view: "tabular", sortByName: "1", sortByViewsCount: "-1", sortByDiscussionsCount: "-1" } )
    const month = searchParams.get( 'month' ) || ""
    const searchText = searchParams.get( 'searchText' ) || ""
    const sortByName = searchParams.get( 'sortByName' ) || "1"
    const selectedInstitute = searchParams.get( 'selectedInstitute' ) || "all"
    const sortByDiscussionsCount = searchParams.get( 'sortByDiscussionsCount' ) || "-1"
    const sortByViewsCount = searchParams.get( 'sortByViewsCount' ) || "-1"
    const sortByCommentsCount = searchParams.get( 'sortByCommentsCount' ) || "-1"
    const sortByVotesCount = searchParams.get( 'sortByVotesCount' ) || "-1"

    const [loading, setLoading] = useState( true )
    const [statsError, setStatsError] = useState( null )
    const [facultyStats, setFacultyStats] = useState( [] )
    const [showMonthPicker, setShowMonthPicker] = useState( false )
    const [sortedFacultyStats, setSortedFacultyStats] = useState( [] )


    const { palette, table } = useTheme()

    const navigate = useNavigate()

    const hasFilters = !( !month && selectedInstitute === "all" && !searchText )

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const removeAppliedFilters = () => {
        setSearchParams( prev => {
            prev.set( 'searchText', "" )
            prev.set( 'month', "" )
            prev.set( 'selectedInstitute', "all" )
            return prev
        }, { replace: true } )
    }

    const handleFilterChange = ( e, newVal ) => {
        if ( newVal === '' || newVal === 'last30days' ) {
            setSearchParamValue( 'month', newVal )
        } else {
        }
    }

    const handleMonthChange = newDate => {
        const date = new Date( newDate )
        if ( date !== 'Invalid Date' ) {
            setSearchParamValue( 'month', `${date.getFullYear()}-${leadingZeroFormatter.format( date.getMonth() + 1 )}` )
            setShowMonthPicker( false )
        }
    }

    const searchFilterFunc = item => {
        const searchValue = new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' )
        return item?._id?.institute?.match( searchValue ) || item?._id?.name?.match( searchValue ) || item._id?.created_by?.match( searchValue )
    }

    const columns =
        [
            { id: 1, name: 'Sl.No.', minWidth: "50px", align: "center", padding: "10px 20px 10px 40px" },
            { id: 2, name: <CustomTableColumnLabel data={sortedFacultyStats} setData={setSortedFacultyStats} label="Posted by" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"_id.name"} sortName="sortByName" sortFlag={sortByName} sortValueType="string" />, minWidth: "150px", padding: "10px 20px" },
            { id: 3, name: 'AUID', align: "center", padding: "10px 20px" },
            { id: 6, name: 'institute', padding: "10px 20px" },
            { id: 4, align: "center", style: { align: "center" }, name: <CustomTableColumnLabel align="center" data={sortedFacultyStats} setData={setSortedFacultyStats} label="Discussions" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"count"} sortName="sortByDiscussionsCount" sortFlag={sortByDiscussionsCount} sortValueType="number" />, minWidth: "150px", padding: "10px 20px" },
            { id: 5, align: "center", style: { align: "center" }, name: <CustomTableColumnLabel align="center" data={sortedFacultyStats} setData={setSortedFacultyStats} label="Views" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"views"} sortName="sortByViewsCount" sortFlag={sortByViewsCount} sortValueType="number" />, minWidth: "150px", padding: "10px 40px 10px 20px" },
            { id: 7, align: "center", style: { align: "center" }, name: <CustomTableColumnLabel align="center" data={sortedFacultyStats} setData={setSortedFacultyStats} label="Comments" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"comments"} sortName="sortByCommentsCount" sortFlag={sortByCommentsCount} sortValueType="number" />, minWidth: "150px", padding: "10px 40px 10px 20px" },
            { id: 8, align: "center", style: { align: "center" }, name: <CustomTableColumnLabel align="center" minWidth="170px" data={sortedFacultyStats} setData={setSortedFacultyStats} label="Votes(up - down)" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"votes"} sortName="sortByVotesCount" sortFlag={sortByVotesCount} sortValueType="number" />, minWidth: "150px", padding: "10px 40px 10px 20px" },
        ]


    const getData = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data: facultyStats } } = await api.audit.getDiscussionsStats( { month, showzeroassignmentfaculties: false, institute: selectedInstitute !== "all" ? selectedInstitute : "" } )
            setFacultyStats( facultyStats )
            setSortedFacultyStats( facultyStats )
            setStatsError( null )
        } catch ( err ) {
            setStatsError( { code: err?.response?.status, message: err?.response?.data?.message || "Something went wrong while fetching the assignment audit data." } )
        } finally {
            setLoading( false )
        }
    }, [month, selectedInstitute] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Card sx={{ flexShrink: 0, display: "flex", flexDirection: "column" }}>
            <Box padding="20px" display="flex" gap="20px" justifyContent="space-between" >
                <Typography color="primaryDark.main" fontSize="16px" variant='h6'>Discussions Statistics</Typography>
                <Box position="relative" display="flex" gap="20px" alignItems="center">
                    <ToggleButtonGroup size='small' onChange={handleFilterChange} exclusive value={month || ""}>
                        <ToggleButton size='small' sx={{ textTransform: "capitalize", padding: "4.5px 10px" }} value="">All</ToggleButton>
                        <ToggleButton size='small' sx={{ textTransform: "capitalize", padding: "4.5px 10px" }} value="last30days">Last 30 days</ToggleButton>
                        <ToggleButton size='small' sx={{ textTransform: "capitalize", padding: "4.5px 10px" }} value="-" selected={Boolean( month.match( /^(\d{4}-\d{2})$/ ) )}>
                            {month && month !== 'last30days' ? month : "Select month"}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    open={showMonthPicker}
                                    onClose={() => setShowMonthPicker( false )}
                                    id="startdatetime"
                                    slotProps={{
                                        textField: {
                                            onClick: () => setShowMonthPicker( true )
                                        }
                                    }}
                                    slots={{
                                        openPickerButton: () => null,
                                    }}
                                    sx={{ opacity: 0, position: "absolute", inset: 0, fontSize: "14px", '& input': { padding: "5px 10px", cursor: "pointer" } }}
                                    // value={dayjs( endDateTime )}
                                    views={['year', 'month']}
                                    onMonthChange={handleMonthChange}
                                />
                            </LocalizationProvider>
                        </ToggleButton>
                    </ToggleButtonGroup>


                    <Button size="small" onClick={() => navigate( `list` )} variant="contained" disableElevation sx={{ height: "33.5px", textTransform: "capitalize", background: "#FFE0E0", border: `1px solid #FF6C8F`, color: "#FF736F", '&:hover': { background: "#FFE0E0", color: "#FF736F" } }} >View discussions</Button>
                    <Button size="small" onClick={() => exportDiscussionsAuditReport( sortedFacultyStats, `${month || "complete"}-${selectedInstitute !== "all" ? selectedInstitute + "-" : ""}-discussions-audit-report` )} variant="contained" disableElevation sx={{ height: "33.5px", background: `linear-gradient(200.17deg, #1CB5B5 -47.65%, #105958 167.67%)`, color: "#fff", '&:hover': { background: `linear-gradient(200.17deg, #1CB5B5 -47.65%, #105958 167.67%)`, color: "#fff" } }} endIcon={<Upload fontSize='small' />} >Export</Button>
                </Box>
            </Box >
            {!loading && sortedFacultyStats.filter( searchFilterFunc ).length > 0 && <Box overflow="hidden" bgcolor={palette.contentBg} borderRadius="10px" margin="0 20px 20px 20px">
                {!statsError ? <TableContainer sx={{ overflow: "auto", maxHeight: "500px" }} className='custom-scrollbar' >
                    <Table stickyHeader>
                        <TableHeader columns={columns} />
                        <TableBody>
                            {sortedFacultyStats.filter( searchFilterFunc ).map( ( row, index ) => {
                                return <TableRow className='table-row' key={row._id?.created_by}>
                                    <TableCell align={columns[0].align || "left"} sx={{ fontSize: "12px", padding: columns[0].padding || "10px 20px", color: table.color }}><strong>{index + 1}</strong></TableCell>
                                    {row?._id?.role !== 'STUDENT' && <TableCell sx={{ fontSize: "12px", padding: columns[1].padding || "10px 20px", color: table.color, textTransform: "capitalize", '&:hover': { textDecoration: "underline" } }}><strong><Link to={`/audit/faculty/${row._id.created_by}`} className='default-link' >{row?._id?.name?.toLowerCase() || row?._id.created_by}</Link></strong></TableCell>}
                                    {row?._id?.role === 'STUDENT' && <TableCell sx={{ fontSize: "12px", padding: columns[1].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}><strong>{row?._id?.name?.toLowerCase() || row?._id.created_by}</strong></TableCell>}
                                    {row?._id?.role !== 'STUDENT' && <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", padding: columns[2].padding || "10px 20px", color: table.color, '&:hover': { textDecoration: "underline" } }}><strong><Link to={`/audit/faculty/${row._id.created_by}`} className='default-link' >{row._id.created_by}</Link></strong></TableCell>}
                                    {row?._id?.role === 'STUDENT' && <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", padding: columns[2].padding || "10px 20px", color: table.color }}><strong>{row._id.created_by}</strong></TableCell>}
                                    <TableCell align={columns[3].align || "left"} sx={{ fontSize: "12px", padding: columns[3].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}><strong>{row?._id?.institute}</strong></TableCell>
                                    <TableCell align={columns[4].align || "left"} sx={{ fontSize: "12px", padding: columns[4].padding || "10px 20px", color: table.color }}><strong>{row.count}</strong></TableCell>
                                    <TableCell align={columns[5].align || "left"} sx={{ fontSize: "12px", padding: columns[5].padding || "10px 20px", color: table.color }}><strong>{row.views}</strong></TableCell>
                                    <TableCell align={columns[6].align || "left"} sx={{ fontSize: "12px", padding: columns[6].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}><strong>{row?.comments}</strong></TableCell>
                                    <TableCell align={columns[7].align || "left"} sx={{ fontSize: "12px", padding: columns[7].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}><strong>{row?.votes}</strong></TableCell>
                                </TableRow>
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer> : <TryAgainMessageBlock err={statsError.message} loading={loading} getData={getData} code={statsError.code} />}
            </Box>}

            {!loading && sortedFacultyStats.filter( searchFilterFunc ).length === 0 && <Box flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" gap="20px">
                <img src={NoDatImg} height="120px" alt="No exams in last 30 days" />
                <Typography textAlign="center" variant='subtitle2'>No discussions were found for applied filters!</Typography>
                {( facultyStats.length > 0 || hasFilters ) && <Button onClick={removeAppliedFilters} sx={{ textTransform: "capitalize" }} color="secondary" variant="contained" disableElevation>Remove filters</Button>}
            </Box>}
            {loading && <DataLoadingSpinner waitingMessage="Loading exams audit report please..." />}
        </Card >
    )
}

export default DiscussionsStats
