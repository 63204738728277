import { Box, CircularProgress, Collapse, IconButton, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { Icons } from '../../utils/utilities'
import { Check, CheckBox, CheckBoxOutlineBlank, ExpandMore, NotStartedOutlined, PendingOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { useOnlineCourseStore } from '../../store/onlinecourse.store'
import { observer } from 'mobx-react'
import ConfirmDialog from '../common/ConfirmDialog'
import { toast } from 'react-toastify'
import api from '../../service/api'
import { useParams } from 'react-router-dom'

const CoursePlaylist = observer( ( { progresses, setProgresses, viewAttachment, setIsPlaylistOpen, isPlaylistOpen, selectedAttachment } ) => {

    const [collapseState, setCollapseState] = useState( {} )
    const [loading, setLoading] = useState( true )
    const [confirmDialogState, setConfirmDialogState] = useState( false )
    const [confirmDialogMessage, setConfirmDialogMessage] = useState( "" )
    const [clickedCheckboxProgress, setClickedCheckboxProgress] = useState( null )

    const OnlineCourseStore = useOnlineCourseStore()
    const { progress_id } = useParams()
    const { palette } = useTheme()

    const handleCheckboxChange = ( progress, att ) => {
        if ( progress ) {
            if ( progress.status === "COMPLETED" )
                setConfirmDialogMessage( `Do you want to set the status of ${att.attachment_name} as INPROGRESS` )
            else {
                setConfirmDialogMessage( `Do you want to set the status of ${att.attachment_name} as COMPLETED` )
            }
            setConfirmDialogState( true )
            setClickedCheckboxProgress( progress )
        } else {
            toast( "Cannot set as completed if not started first!" )
        }
    }

    const toggleCompleted = async () => {
        try {
            await api.updateProgressDetail( clickedCheckboxProgress.id, { status: clickedCheckboxProgress.status === "COMPLETED" ? "INPROGRESS" : "COMPLETED", last_saved_duration: 0 } )
            await OnlineCourseStore.fetchProgressData( progress_id )
            const mapProgressAndAttachments = () => {
                const map = {}
                for ( let i = 0; i < OnlineCourseStore.getCourseProgressData.progress_details.length; i++ ) {
                    const progress = OnlineCourseStore.getCourseProgressData.progress_details[i]
                    map[progress.online_course_attachment_id] = progress
                }
                setProgresses( map )
            }
            mapProgressAndAttachments()
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        } finally {
            setConfirmDialogState( false )
        }
    }

    const getCompletedAttachmentCount = ( count, progress ) => {
        if ( progress.status === "COMPLETED" )
            return count += 1
        return count
    }

    useEffect( () => {
        const getSelectedModuleIndex = () => {
            for ( let i = 0; i < OnlineCourseStore.getCourse['modules'].length; i++ ) {
                const module = OnlineCourseStore.getCourse['modules'][i]
                if ( module.id === selectedAttachment.module_id ) {
                    setCollapseState( { [i]: true } )
                    break
                }
            }
        }
        getSelectedModuleIndex()
        setLoading( false )
    }, [OnlineCourseStore, selectedAttachment] )

    return (
        <Box flexGrow={1} flexDirection="column" borderLeft="1px solid #cbcbcb" bgcolor="white" minHeight="350px" top="0" sx={{ opacity: { md: "1", xs: "0.9" } }} right="0" height="100%" position={{ md: "static", xs: "absolute" }} display={isPlaylistOpen ? "flex" : "none"} maxWidth="300px">
            <ConfirmDialog isReversible={true} actionName="Confirm" cancelAction={() => setConfirmDialogState( false )} confirmAction={toggleCompleted} message={confirmDialogMessage} status={confirmDialogState} />
            <Box borderBottom="2px solid grey" padding="10px" display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant='h6' fontSize="16px">Course Content</Typography>
                <IconButton onClick={() => setIsPlaylistOpen( false )} size='small' sx={{ color: "black" }}> {Icons.default.ClearIcon}</IconButton>
            </Box>
            {!loading && <Box overflow="auto">
                {OnlineCourseStore.getCourse['modules']?.map( ( mod, k ) => (
                    <Box borderBottom="1px solid grey" key={mod.id}>
                        <Box padding="10px 10px 0 10px" onClick={() => setCollapseState( { ...collapseState, [k]: !collapseState[k] } )} display="flex" justifyContent="space-between" alignItems="center">
                            <Box flexGrow={1}>
                                <Typography maxWidth="200px" title={mod.module_name} fontSize="14px" fontWeight="bolder" noWrap>Module-{k + 1}: {mod.module_name}</Typography>
                                <Typography color="textSecondary" fontSize="12px"> {`${OnlineCourseStore.getCourseProgressData.progress_details.filter( pd => pd.online_course_module_id === mod.id ).reduce( getCompletedAttachmentCount, 0 )} / ${OnlineCourseStore.getCourse['attachments']?.filter( att => att.online_course_module_id === mod.id ).length} completed`}</Typography>
                            </Box>
                            <IconButton onClick={() => setCollapseState( { ...collapseState, [k]: !collapseState[k] } )}> <ExpandMore /> </IconButton>
                        </Box>
                        <Collapse sx={{ paddingTop: "10px" }} in={collapseState[k] ? true : false}>
                            {OnlineCourseStore.getCourse['attachments']?.filter( att => att.online_course_module_id === mod.id ).map( ( att ) => (
                                <Box borderTop="1px solid #cbcbcb" bgcolor={att.attachment_id !== selectedAttachment.attachment_id ? "white" : `${palette.primary.light}22`} padding="0 10px 5px 10px" paddingleft="25px" key={att.attachment_id}>
                                    <Box display="flex" gap="5px" alignItems="center">
                                        <Box sx={{ cursor: "pointer" }} display="flex" alignItems="center" onClick={() => handleCheckboxChange( progresses[att.attachment_id], att )}>
                                            {progresses[att.attachment_id]?.status === "COMPLETED" ? <CheckBox sx={{ fontSize: "16px", color: "black" }} /> : <CheckBoxOutlineBlank sx={{ fontSize: "16px", color: "GrayText" }} />}
                                        </Box>
                                        <Typography flexGrow={1} sx={{ cursor: "default" }} onClick={() => viewAttachment( att )} fontSize="14px" noWrap>{att.attachment_name.split( "." ).slice( 0, -1 ).join( "" )}</Typography>
                                    </Box>
                                    <Typography display="flex" gap="5px" alignItems="center" fontSize="12px" color="textSecondary">

                                        {( progresses[att.attachment_id] && ( progresses[att.attachment_id]?.status?.toLowerCase() === "notstarted" || !progresses[att.attachment_id] ) ) && <NotStartedOutlined sx={{ color: "GrayText", fontSize: "18px" }} />}
                                        {progresses[att.attachment_id] && progresses[att.attachment_id]?.status?.toLowerCase() === "inprogress" && <PendingOutlined sx={{ color: "primary.main", fontSize: "18px" }} />}
                                        {progresses[att.attachment_id] && progresses[att.attachment_id]?.status?.toLowerCase() === "completed" && <Check sx={{ color: "success.main", fontSize: "18px" }} />}

                                        {progresses[att.attachment_id] ? progresses[att.attachment_id].status.toLowerCase() : "notstarted"}
                                    </Typography>
                                </Box>
                            ) )}
                        </Collapse>
                    </Box>
                ) )}
            </Box>}
            {loading && <CircularProgress />}
        </Box>
    )
} )

export default CoursePlaylist
