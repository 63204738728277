import { CommentOutlined, ThumbsUpDownOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Avatar, Box, Card, Tooltip, Typography } from '@mui/material'
import { flatColorsAndBackgrounds, getTimePassedFrom } from '../../utils/utilities'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import React from 'react'

const DiscussionCard = ( { parent = 'explore', discussion } ) => {

    const setSearchParams = useSearchParams()[1]

    const UserStore = useUserStore()

    const navigate = useNavigate()

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const navigateOrAddSearchQuery = cat => {
        if ( parent === 'mydiscussions' )
            navigate( UserStore.getUser.user_role === "STUDENT" ? `/student/discussion/${discussion._id}?category=${cat?.toLowerCase()}` : `/faculty/discussion/${discussion._id}?category=${cat?.toLowerCase()}` )
        else
            setSearchParamValue( 'category', cat?.toLowerCase() )
    }

    return (
        <Card sx={{ flexShrink: 0 }} >
            <Box padding="20px">
                <Box display="flex" flexDirection="column" gap="10px">
                    <Box display="flex" gap="10px" alignItems="center">
                        <Avatar src={discussion.creator_data?.user_profile_image_url} sx={{ width: "35px", height: "35px" }} >{discussion.creator_data.name.substring( 0, 1 )}</Avatar>
                        <Box>
                            <Typography marginTop="-5px" fontSize="14px" variant='subtitle2' textTransform="capitalize" color="GrayText" >
                                {discussion.access_groups[0].subject_name} {discussion.access_groups?.length > 1 && ` and ${discussion.access_groups.length - 1} more`}
                            </Typography>
                            <Typography fontSize="10px" variant='subtitle2' textTransform="capitalize" color="GrayText" >@<em>{discussion.creator_data.name}</em> </Typography>
                        </Box>
                    </Box>
                    <Box flexGrow={1} >
                        <Link
                            className='default-link'
                            to={
                                window.innerWidth < 900 ?
                                    UserStore.getUser.user_role === "STUDENT" ? `/student/discussion/${discussion._id}${window.location.search}` : `/faculty/discussion/${discussion._id}${window.location.search}` :
                                    `${discussion._id}${window.location.search}`
                            }
                        >
                            <Typography display="inline-block" variant="h6" sx={{ "&:hover": { textDecoration: "underline" } }} fontWeight="500" fontSize="18px">{discussion.title}</Typography>
                        </Link>
                        <Box display="flex" flexWrap="wrap" marginTop="5px" gap="10px" alignItems="center">
                            {discussion.categories.map( ( category, index ) => {
                                const { color } = flatColorsAndBackgrounds[index % flatColorsAndBackgrounds.length]
                                return (
                                    <Box key={category} onClick={() => navigateOrAddSearchQuery( category )} fontSize="12px" padding="5px" borderRadius="5px" sx={{ color: "white", cursor: "pointer" }} bgcolor={color}>{category}</Box>
                                )
                            } )}
                        </Box>
                    </Box>
                    <Box padding="5px 10px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
                        <Box display="flex" gap="20px" alignItems="center">
                            <Tooltip title="Views">
                                <Typography color="textSecondary" display="flex" gap="5px" alignItems="center" variant="subtitle2">{discussion.views} <VisibilityOutlined sx={{ fontSize: "15px" }} /></Typography>
                            </Tooltip>
                            <Tooltip title="Votes">
                                <Typography color="textSecondary" display="flex" gap="5px" alignItems="center" variant="subtitle2">{discussion.votes.up - discussion.votes.down} <ThumbsUpDownOutlined sx={{ fontSize: "15px" }} /></Typography>
                            </Tooltip>
                            <Tooltip title="Comments" >
                                <Typography color="textSecondary" display="flex" gap="5px" alignItems="center" variant="subtitle2">{discussion.comment_count} <CommentOutlined sx={{ fontSize: "15px" }} /> </Typography>
                            </Tooltip>
                        </Box>
                        <Typography color="textSecondary" fontSize="12px" variant="subtitle2">{getTimePassedFrom( discussion.created_at )}</Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}

export default DiscussionCard
