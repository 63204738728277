import React, { useState } from 'react'
import {
  Box,
  Card,
  Collapse,
  Typography,
  useTheme,
  Button,
  Container,
  Paper,
} from '@mui/material'
import {
  Icons,
  getFileSizeFromBytes,
} from '../../utils/utilities'
import uploadimg from '../../assets/worksheet-upload.svg'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { toast } from 'react-toastify'
import api from '../../service/api'
import { useNavigate, useParams } from 'react-router-dom'

const FileInput = ( {
  selectedAttachment,
  setSelectedAttachment,
  isUploading,
} ) => {
  const [dragOverStatus, setDragOverStatus] = useState( false )
  const { palette, border } = useTheme()

  const handleFileInsert = ( e ) => {
    const file = e.target.files[0]

    if ( !file.name?.endsWith( 'pdf' ) ) {
      e.target.value = ''
      setSelectedAttachment( null )
      return toast( "Unsupported file format! Acceptable format: PDF" )
    }
    if ( file ) {
      setSelectedAttachment( file )
    }
    e.target.value = ''
  }

  return (
    <Box>
      <Box
        onDragLeave={() => setDragOverStatus( false )}
        onDrop={() => setDragOverStatus( false )}
        onDragEnter={() => setDragOverStatus( true )}
        onDragOver={() => setDragOverStatus( true )}
        sx={{
          position: 'relative',
          borderRadius: '8px',
          gap: '10px',
          display: 'flex',
          padding: '20px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '150px',
          border: `${dragOverStatus ? '4px' : '3px'} dashed ${dragOverStatus ? palette.primary.dark : palette.primary.main
            }`,
          bgcolor: `${palette.primary.light}11`,
        }}
      >
        <input
          accept='application/pdf'
          disabled={isUploading}
          style={{
            position: 'absolute',
            cursor: isUploading ? 'not-allowed' : 'pointer',
            opacity: 0,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          type='file'
          name='attachment'
          id='attachment'
          onInput={( e ) => {
            handleFileInsert( e )
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            mb: 3,
          }}
          onClick={() => document.getElementById( 'attachment' ).click()}
        >
          <FileUploadIcon color='primary' sx={{ mr: 1 }} />
          <Typography variant='body1' color='#05004E'>
            Upload Worksheet
          </Typography>
        </Box>
        <img src={uploadimg} height='150px' alt='Upload materials' />
        {isUploading && (
          <Typography variant='body2' fontWeight='bold' color='textSecondary'>
            Uploading selected attachment. This could take a moment...
          </Typography>
        )}
        {!isUploading && (
          <Box color='textSecondary' fontSize='14px'>
            {selectedAttachment
              ? 'Drag and drop to replace file'
              : 'Drag and drop to upload attachment'}{' '}
            <Typography
              variant='subtitle2'
              display='inline-block'
              sx={{ textDecoration: 'underline' }}
              fontWeight='bold'
              color='primary.main'
            >
              Or browse
            </Typography>
          </Box>
        )}
        {!isUploading && (
          <Typography variant='subtitle2' color='secondary' textAlign='center'>
            Accepted format: PDF
          </Typography>
        )}
        <Button
          variant='contained'
          startIcon={<FileUploadIcon />}
          onClick={() => document.getElementById( 'attachment' ).click()}
          disabled={isUploading}
          disableElevation
          sx={{
            textTransform: 'none',
            bgcolor: '#2196f3',
            color: 'white',
            '&:hover': { bgcolor: '#1e88e5' },
            px: 4,
            py: 1,
            fontSize: '1rem',
            mt: 2,
          }}
        >
          UPLOAD
        </Button>
      </Box>
      <Collapse in={selectedAttachment !== null} sx={{ marginTop: '10px' }}>
        <Box>
          <Typography variant='subtitle2'>Selected Attachment</Typography>
          <Box
            gap='10px'
            marginTop='10px'
            display='flex'
            alignItems='center'
            flexWrap='wrap'
          >
            {selectedAttachment && (
              <Card
                sx={{
                  position: 'relative',
                  flexGrow: 1,
                  padding: '0',
                  border: border[1],
                }}
              >
                <Box
                  display='flex'
                  gap='10px'
                  alignItems='center'
                  padding='10px'
                >
                  <Box
                    width='40px'
                    sx={{ aspectRatio: 1 }}
                    borderRadius='5px'
                    bgcolor={palette.primary.light + '22'}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    {Icons.default.FileIcon}
                  </Box>
                  <Box display='flex' gap='5px' flexDirection='column'>
                    <Box
                      justifyContent='space-between'
                      gap='20px'
                      alignItems='center'
                    >
                      <Typography color='GrayText' fontSize='10px'>
                        {selectedAttachment.name}
                      </Typography>
                      <Typography color='GrayText' fontSize='10px'>
                        {getFileSizeFromBytes( selectedAttachment?.size )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            )}
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

const AdmissionExamWorksheetUpload = () => {
  const [selectedAttachment, setSelectedAttachment] = useState( null )
  const [isUploading, setIsUploading] = useState( false )

  const { pid, id } = useParams()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {
      setIsUploading( true )
      const formData = new FormData()
      formData.append( 'worksheet', selectedAttachment, selectedAttachment.name )
      formData.append( 'name', selectedAttachment.name )
      await api.admissionExams.uploadWorkSheet( id, pid, formData )
      navigate( `/thankyou`, { replace: true } )
    } catch ( err ) {
      toast( err?.response?.data?.message || "Something went wrong while uploading your worksheet!" )
    } finally {
      setIsUploading( false )
    }
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
        <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              mb: 3,
            }}
          >
            <Box>
              <Typography
                variant='h5'
                color="primaryDark.main"
                sx={{ fontWeight: 500, mb: 1 }}
              >
                Exam Completed!
              </Typography>
              <Typography variant='body1' color='#666'>
                Please upload your worksheet and submit
              </Typography>
            </Box>
          </Box>
          <FileInput
            setFieldValue={() => { }}
            selectedAttachment={selectedAttachment}
            setSelectedAttachment={setSelectedAttachment}
            isUploading={isUploading}
          />
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={!selectedAttachment || isUploading}
            disableElevation
            sx={{
              marginTop: "20px",
              bgcolor: '#4CAF50',
              color: 'white',
              '&:hover': { bgcolor: '#45a049' },
              '&:disabled': { bgcolor: '#a5d6a7' },
              textTransform: 'none',
              px: 4,
              py: 1,
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            {isUploading ? 'UPLOADING...' : 'SUBMIT'}
          </Button>
        </Paper>
      </Container>
    </Box>
  )
}

export default AdmissionExamWorksheetUpload
