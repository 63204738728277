import { Box, Dialog, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { formatDateTimeForDisplay } from '../../../utils/exam-utilities'
import { Icons } from '../../../utils/utilities'
import AuditExamParticipationsList from './AuditExamParticipationsList'
import AuditStudentAnswers from './AuditStudentAnswers'


const AuditExamParticipations = ( { handleClose, openState, exam } ) => {

    const [selectedAttempt, setSelectedAttempt] = useState( null )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "1200px" } }} open={openState}>
            <Box sx={{
                background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
            }} display="flex" alignItems="center" justifyContent="space-between" padding="20px" >
                <Box>
                    <Typography color="white" variant="h6">Participation: {exam.exam_name}</Typography>
                    <Typography gutterBottom variant='body2' color='white' >{`${formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )} - ${formatDateTimeForDisplay( exam.exam_end_date, exam.exam_end_time )}`}</Typography>
                    {exam.exam_duration !== "null" && exam.exam_duration !== null && <Typography variant='body2' color='white' > {`Duration: ${exam.exam_duration.split( ":" )[0]} Hours ${exam.exam_duration.split( ":" )[1]} Minutes`}</Typography>}
                    <Typography variant='body2' color='white' >Maximum Attempts: {exam.maximum_attempts}</Typography>
                </Box>
                <IconButton onClick={handleClose}>{Icons.default.CloseIcon}</IconButton>
            </Box>
            {!selectedAttempt && <Box padding="20px">
                <AuditExamParticipationsList setSelectedAttempt={setSelectedAttempt} exam={exam} />
            </Box>}
            {selectedAttempt && <Box padding="20px">
                <AuditStudentAnswers participation_id={selectedAttempt.participation_id} setSelectedAttempt={setSelectedAttempt} exam={exam} />
            </Box>}
        </Dialog>
    )
}

export default AuditExamParticipations
