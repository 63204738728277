import { Box, Collapse, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useState } from 'react'
import ChecklistItem from './ChecklistItem'

const YearChecklist = ( { year, yearNumber, studentData, getData } ) => {

    const [collapseStatuses, setCollapseStatuses] = useState( {} )
    const { palette, border } = useTheme()

    const toggleCollapseStatus = ( key ) => {
        setCollapseStatuses( prev => {
            return { ...prev, [key]: !prev[key] }
        } )
    }

    return (
        <Box sx={{ marginTop: "20px", overflow: "hidden" }}>
            <Box display="flex" flexDirection="column" gap="5px">
                {year.checklist.length !== 0 && <Box border={border[1]} overflow="hidden" borderRadius="5px">
                    <Tooltip title="Expand checklist" placement='top-start'>
                        <Box alignItems="center" justifyContent="space-between" padding="10px 20px" bgcolor={collapseStatuses[`checklist`] ? palette.error.light + "77" : palette.error.light + "44"} onClick={() => toggleCollapseStatus( `checklist` )} sx={{ cursor: "pointer" }} display="flex" gap="5px">
                            <Typography fontWeight="600" fontSize="14px">Checklist</Typography>
                            <Box marginRight="10px" width="30px" sx={{ aspectRatio: 1, cursor: "pointer" }} display="flex" alignItems="center" justifyContent="center" borderRadius="5px" height="30px" bgcolor={palette.greyedOut}  >{collapseStatuses['checklist'] ? <KeyboardArrowUp fontSize='small' /> : <KeyboardArrowDown fontSize='small' />}</Box>
                        </Box>
                    </Tooltip>
                    <Collapse in={collapseStatuses[`checklist`]}>
                        <Box padding="10px">
                            {year.checklist.map( ( item ) => (
                                <ChecklistItem getData={getData} studentData={studentData[item.id] || {}} key={item.id} item={item} />
                            ) )}
                        </Box>
                    </Collapse>
                </Box>}
                {/* { <Box display="flex" flexDirection="column" gap="5px"> */}
                {year.semester.length > 0 && year.semester.map( ( sem, index ) => (
                    <Box key={index} overflow="hidden" border={border[1]} borderRadius="5px">
                        <Tooltip title="Expand checklist" placement='top-start'>
                            <Box justifyContent="space-between" onClick={() => toggleCollapseStatus( `sem${index}` )} padding="10px 20px" bgcolor={collapseStatuses[`checklist`] ? palette.error.light + "77" : palette.error.light + "44"} sx={{ cursor: "pointer" }} alignItems="center" display="flex" gap="5px">
                                <Typography fontWeight="600" fontSize="14px">Semester {index} Checklist</Typography>
                                <Box marginRight="10px" width="30px" sx={{ aspectRatio: 1, cursor: "pointer" }} display="flex" alignItems="center" justifyContent="center" borderRadius="5px" height="30px" bgcolor={palette.greyedOut}>{collapseStatuses[`sem${index}`] ? <KeyboardArrowUp fontSize='small' /> : <KeyboardArrowDown fontSize='small' />}</Box>
                            </Box>
                        </Tooltip>
                        <Collapse in={collapseStatuses[`sem${index}`]}>
                            <Box padding="10px" display="flex" flexDirection="column" gap="10px">
                                {sem.map( item => (
                                    <ChecklistItem getData={getData} studentData={studentData[item.id] || {}} key={item.id} item={item} />
                                ) )}
                            </Box>
                        </Collapse>
                    </Box>
                ) )}
                {/* </Box>} */}
            </Box>
        </Box >
    )
}

export default YearChecklist
