import { DragIndicator } from '@mui/icons-material'
import { Box, Checkbox, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Icons } from '../../../utils/utilities'


const SectionAttributeList = ( { removeAttribute, sectionIndex, handleAttributeSelect, formJson, updateForm, group, groupIndex, selectedAttributes } ) => {

    const moveAttributeGroup = ( source, target, groups ) => {
        let newOrder = [...groups]
        const draggingModule = newOrder.splice( source, 1 )
        newOrder.splice( target, 0, ...draggingModule )
        console.log( newOrder )
        return newOrder
    }

    const handleDragStart = ( e, groupIndex ) => {
        e.target.classList.add( 'dragging' )
        e.dataTransfer.setData( 'text/plain', JSON.stringify( { groupIndex } ) )
    }

    const handleDragEnd = ( e, groupIndex ) => {
        e.target.classList.remove( 'dragging' )
        e.dataTransfer.setData( 'text/plain', JSON.stringify( { groupIndex } ) )
    }

    const handleDrop = ( e, groupIndex ) => {
        const targetIndex = JSON.parse( e.dataTransfer.getData( 'text/plain' ) ).groupIndex
        const dragedIndex = groupIndex
        if ( +targetIndex !== +dragedIndex ) {
            let newForm = [...formJson]
            const newSection = newForm[sectionIndex]
            newSection.attributes = moveAttributeGroup( dragedIndex, targetIndex, newSection.attributes )
            e.target.classList.remove( 'drag-over' )
            updateForm( newForm )
        }
    }

    const swapAttributes = ( groupIndex, attr1, attr2 ) => {
        let newForm = [...formJson]
        const newSection = newForm[sectionIndex]
        let newAttributes = newSection.attributes[groupIndex]
        let temp = newAttributes[attr1]
        newAttributes[attr1] = newAttributes[attr2]
        newAttributes[attr2] = temp
        updateForm( newForm )
    }

    return (
        <Box key={groupIndex}>
            <div
                className='draggable-element'
                draggable
                onDragOver={e => { e.preventDefault(); e.target.classList.add( 'drag-over' ) }}
                onDragLeave={e => { e.target.classList.remove( 'drag-over' ) }}
                onDragStart={( e ) => { handleDragStart( e, groupIndex ) }}
                onDragEnd={( e ) => { handleDragEnd( e, groupIndex ); e.target.classList.remove( 'drag-over' ) }}
                onDrop={e => { handleDrop( e, groupIndex ) }}
                data-group={true}
                data-group-index={groupIndex}
            >
                <Paper
                    elevation={1}
                    sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "stretch",
                        border: "1px solid #d3d3d3",
                        borderRadius: "5px",
                        pointerEvents: "none"
                    }}>
                    <Box borderRight="1px solid #d3d3d3" sx={{ cursor: "grab" }} display="flex" flexDirection="column" alignItems="center">
                        <Box display="flex" height="100%" flexGrow={1} alignItems="center">
                            <Box display="flex" alignItems="center">
                                <DragIndicator sx={{ color: "#a3a3a3" }} fontSize='small' />
                            </Box>
                            <Box className='show-on-hover' left="0">
                                <Checkbox sx={{ transform: "scale(0.8)", pointerEvents: "all", background: "white", "&:hover": { background: "white" } }} onChange={() => handleAttributeSelect( groupIndex )} checked={selectedAttributes.includes( groupIndex )} />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                        {group.map( ( attr, attrIndex ) => {
                            return (
                                <Box padding="2.5px 5px" display="flex" justifyContent="space-between" borderBottom={group.length > 1 ? "1px solid #d3d3d3" : "none"} key={attr.id} alignItems="center">
                                    <Box display="flex" alignItems="flex-end">
                                        <Typography paddingLeft="15px" variant='subtitle2'>{attr.entity_name}</Typography>
                                        <Typography fontSize="12px" paddingX="10px" variant='subtitle2' color="GrayText">({attr.entity_detail.data_type})</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" sx={{ pointerEvents: "all" }}>
                                        {group.length > 1 && <Tooltip title={`Move ${attr.entity_name} Up`} placement='top-end'>
                                            <Box>
                                                <IconButton sx={{ "&:disabled": { cursor: "not-allowed", pointerEvents: "all" } }} disabled={attrIndex === 0} onClick={() => { swapAttributes( groupIndex, attrIndex, attrIndex - 1 ) }} size='small'>
                                                    {Icons.default.ArrowUpward}
                                                </IconButton>
                                            </Box>
                                        </Tooltip>}
                                        {group.length > 1 && <Tooltip title={`Move ${attr.entity_name} Down`} placement='top-end'>
                                            <Box>
                                                <IconButton sx={{ "&:disabled": { cursor: "not-allowed", pointerEvents: "all" } }} disabled={attrIndex === group.length - 1} onClick={() => { swapAttributes( groupIndex, attrIndex, attrIndex + 1 ) }} size='small'>
                                                    {Icons.default.ArrowDownward}
                                                </IconButton>
                                            </Box>
                                        </Tooltip>}
                                        <Tooltip title={selectedAttributes.length === 0 ? "" : "Remove from section"} placement='top-end'>
                                            <IconButton onClick={() => removeAttribute( groupIndex, attrIndex )} color='error' size='small'>
                                                {Icons.default.CloseIcon}
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )
                        } )}
                    </Box>
                </Paper>
            </div>
        </Box>
    )
}

export default SectionAttributeList
