import {
  Box,
  Button,
  CircularProgress,
  useTheme,
  Paper,
  // TextField,
  Tooltip,
  Typography,
  Drawer,
  IconButton,
  // IconButton,
} from "@mui/material"
import DataLoadingSpinner from "../../components/common/DataLoadingSpinner"
import { downloadParticipationReport } from "../../utils/exam-utilities"
import Submission from "../../components/admission-exam/Submission"
import BlockMessage from "../../components/common/BlockMessage"
import BreadcrumbList from "../../components/common/BreadcrumbList"
import { useNavigate, useParams } from "react-router-dom"
// import { Formik, ErrorMessage, Form } from "formik"
import React, { useState, useEffect, useCallback } from "react"
import { convertToCDNLink, Icons, showDateInIndianDateFormat } from "../../utils/utilities"
import { toast } from "react-toastify"
// import * as Yup from "yup"
import { UploadOutlined } from "@mui/icons-material"
import api from "../../service/api"
import { useAdmissionExamStore } from "../../store/admission-exam.store"
import { observer } from "mobx-react"
import warningImg from '../../assets/warning.svg'
import { useUserStore } from "../../store/user.store"
import DocumentViewer from "../../components/common/DocumentViewer"
import TryAgainMessageBlock from "../../components/common/TryAgainMessageBlock"

const AdmissionExamEvaluate = observer( () => {
  const [participation, setParticipation] = useState( null )
  const [exam, setExam] = useState( null )
  const [submissions, setSubmissions] = useState( null )
  // const [showRemarks, setShowRemarks] = useState( false )
  const [questions, setQuestions] = useState( [] )
  // const [remarks, setRemarks] = useState( "  " )
  const [previewFile, setPreviewFile] = useState( null )
  const [previewingFile, setPreviewingFile] = useState( null )
  const [totalMarks, setTotalMarks] = useState( 0 )
  const [inProgress, setInProgress] = useState( true )
  const [scheme, setScheme] = useState( null )
  const [accessDenied, setAccessDenied] = useState( false )
  const [notFound, setNotFound] = useState( false )
  const [loadingWorkSheet, setLoadingWorkSheet] = useState( false )
  const [hasPermission, setHasPermission] = useState( true )
  const [error, setError] = useState( null )

  const { id, participation_id } = useParams()

  const AdmissionExamStore = useAdmissionExamStore()
  const UserStore = useUserStore()
  const navigate = useNavigate()

  const { palette, border } = useTheme()

  const viewWorksheet = async () => {
    setLoadingWorkSheet( true )
    try {
      const { data: { data: url, details } } = await api.admissionExams.getWorksheetUrl( id, participation_id )
      setPreviewFile( convertToCDNLink( url ) )
      setPreviewingFile( details )
    } catch ( err ) {
      toast( err?.response?.data?.message || "Error occured while fetching the worksheet" )
    } finally {
      setLoadingWorkSheet( false )
    }
  }

  const getData = useCallback( async () => {
    try {
      const { success, code } = await AdmissionExamStore.fetchExam( id )
      if ( success ) {
        let { data: participationData } = await api.admissionExams.fetchParticipationData( id, participation_id )
        let { data: submissionData } = await api.admissionExams.fetchParticipationSubmissions( id, participation_id )
        let { data: questionsData } = await api.admissionExams.fetchParticipationQuestions( id, participation_id )
        let { data: schemeData } = await api.admissionExams.fetchEvaluationScheme( id )
        console.log( participationData )
        setParticipation( participationData.participation )
        setTotalMarks( participationData.marks.sum )
        setExam( AdmissionExamStore.getExam.exam )
        let subs = {}
        let tempScheme = {}
        const submissionsList = submissionData.data || submissionData
        for ( let i = 0; i < submissionsList.length; i++ ) {
          subs[submissionsList[i].question_id]
            ? subs[submissionsList[i].question_id].push( submissionsList[i] )
            : ( subs[submissionsList[i].question_id] = [submissionsList[i]] )
        }
        for ( let i = 0; i < schemeData.data.length; i++ ) {
          tempScheme[schemeData.data[i].question_id] = schemeData.data[i]
        }
        setSubmissions( subs )
        setScheme( tempScheme )
        setQuestions( questionsData?.data || questionsData )
      } else {
        if ( code === 403 || code === 401 )
          setAccessDenied( true )
        else if ( code === 404 ) {
          setNotFound( true )
        }
      }
    } catch ( e ) {
      console.log( e )
      setError( { message: e?.response?.data?.message || e?.response?.data || "Something went wrong!", code: e?.response?.status } )
    } finally {
      setInProgress( false )
    }
  }, [participation_id, id, AdmissionExamStore] )


  useEffect( () => {
    if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.admission_exams ) {
      setHasPermission( false )
    }
  }, [UserStore] )

  useEffect( () => {
    getData()
  }, [getData] )

  return (
    hasPermission ? <Box sx={{ padding: "20px", flexGrow: 1, overflow: "auto", display: "flex", flexDirection: "column", }}
    >
      {notFound && (
        <BlockMessage
          actions={[
            {
              label: "Back to Participations",
              action: () => navigate( `/faculty/participations` ),
            },
          ]}
          type="error"
          blockTitle="Exam Not Found"
          message="The exam is you are looking for seems to be deleted."
        />
      )}


      {accessDenied && (
        <BlockMessage
          actions={[
            {
              label: "Back to Participations",
              action: () => navigate( `/faculty/participations` ),
            },
          ]}
          type="error"
          blockTitle="Access Denied"
          message="You do not have permission to view this exam information. Only exam creator and admin can access a particular exam."
        />
      )}

      {!accessDenied && !notFound && (
        <BreadcrumbList
          items={[
            { label: "Admission Exams", link: "/admin/admission-exam" },
            {
              label:
                exam && exam.exam_name ? (
                  exam.exam_name
                ) : (
                  <CircularProgress sx={{ color: "textSecondary" }} size={12} />
                ),
              link: `/admin/admission-exam/exam/${id}`,
            },
            {
              label:
                exam && exam.exam_name ? (
                  "Participations"
                ) : (
                  <CircularProgress sx={{ color: "textSecondary" }} size={12} />
                ),
              link: `/admin/admission-exam/${id}/participation`,
            },
            "Evaluate",
            participation?.student_name || "",
          ]}
        />
      )}

      {!accessDenied && !notFound && !error && exam && participation && !inProgress && (
        <Box
          sx={{
            minHeight: "450px",
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            height: "100%",
          }}
        >

          <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
            <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
              <Box borderBottom={border[1]} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                <Typography variant='h6'>{previewingFile?.name}</Typography>
                <Box display="flex" gap="10px" alignItems="center">
                  <IconButton onClick={() => { setPreviewFile( null ); setPreviewingFile( null ) }}>
                    {Icons.default.CloseIcon}
                  </IconButton>
                </Box>
              </Box>
              <Box padding="20px" display="flex" flexDirection="column" overflow="auto" flexGrow={1} height="300px">
                <DocumentViewer type={'pdf'} loadingText="Loading worksheet..." setFile={setPreviewFile} file={previewFile} />
              </Box>
            </Box>
          </Drawer>

          <Paper
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              overflow: { xs: "auto", md: "hidden" },
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                background: `linear-gradient(90deg, #151D48 0%, rgba(21, 29, 72, 0.8) 100%)`,
                color: "white",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                alignItems: { xs: "flex-start", md: "center" },
                padding: "20px",
                gap: "20px",
              }}
            >
              <Box>
                <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                  {exam.exam_name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textTransform: "capitalize" }}
                >
                  Submissions of {participation.student_name.toLowerCase()}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  {Icons.default.Calendar}{" "}
                  {`${showDateInIndianDateFormat(
                    participation.exam_participation_start_time
                  )}, ${new Date(
                    participation.exam_participation_start_time
                  ).toLocaleTimeString()}`}
                </Typography>
                <Typography marginTop="10px" variant="body2" sx={{ textTransform: "capitalize" }}>
                  Attempt: {participation.exam_participation_attempt}
                </Typography>
              </Box>
              <Typography
                variant="h6"
                sx={{
                  bgcolor: "rgba(217, 217, 217, 0.08)",
                  border: "1px solid #fff",
                  borderRadius: "5px",
                  padding: "5px 20px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {participation.exam_participation_score
                  ? participation.exam_participation_score
                  : "0"}{" "}
                Out Of {totalMarks}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  flexWrap: "wrap"
                }}
              >
                <Tooltip title="Download Report as CSV">
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    endIcon={<UploadOutlined />}
                    onClick={() =>
                      downloadParticipationReport(
                        participation,
                        questions,
                        submissions
                      )
                    }
                    sx={{
                      textTransform: "capitalize",
                      minWidth: "max-content",
                      background: `linear-gradient(200.17deg, #F3C400 -47.65%, #994322 167.67%)`,
                      "&:hover": {
                        background: `linear-gradient(200.17deg, #F3C400 -47.65%, #994322 167.67%)`,
                      },
                    }}
                  >
                    Export report
                  </Button>
                </Tooltip>
                {( AdmissionExamStore?.getExam?.exam?.exam_constraints?.receiveWorksheet === 'true' || AdmissionExamStore?.getExam?.exam?.exam_constraints?.receiveWorksheet === true ) &&
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    startIcon={loadingWorkSheet && <CircularProgress size={14} />}
                    endIcon={Icons.default.OpenInNewIcon}
                    disabled={loadingWorkSheet}
                    onClick={viewWorksheet}
                    sx={{ textTransform: "capitalize", backgroundColor: "#BEFFC7", color: "black", "&:hover": { backgroundColor: "#7ffa90" } }}
                  >
                    View worksheet
                  </Button>}
              </Box>
            </Box>
            <Box
              minHeight={{ xs: "500px", md: "0" }}
              sx={{
                flexGrow: 1,
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                className="custom-scrollbar"
                sx={{
                  overflow: "auto",
                  flexGrow: 1,
                  bgcolor: palette.contentBg,
                  border: `1px solid ${palette.divider}`,
                  borderRadius: "5px",
                  margin: "20px",
                }}
              >
                <Box
                  className="custom-scrollbar"
                  sx={{
                    padding: "20px",
                    maxWidth: "1150px",
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  {!inProgress && questions?.length > 0 && submissions &&
                    questions &&
                    questions.map( ( question, index ) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 1,
                          width: "100%",
                        }}
                        key={index}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                            mr: 2,
                          }}
                        >
                          <Submission
                            refresh={getData}
                            question={{
                              ...question.question,
                              question_text: `<strong>${question.question.question_text}</strong>`,
                            }}
                            answer={submissions[question.question.question_id]}
                            options={question.options}
                            keyAnswer={scheme[question.question.question_id] || { answer_text: "" }}
                            index={index}
                          />
                        </Box>
                      </Box>
                    ) )}
                  {!inProgress && questions?.length === 0 && <Box padding="10px 20px" borderRadius="10px" border={border[1]} bgcolor={palette.contentBg}  >
                    <Typography color="textSecondary" variant="subtitle2"><em>This attempt is not started!</em></Typography>
                  </Box>}
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
      {!accessDenied && !notFound && !participation && !inProgress && (
        <Typography marginTop="10px" variant="subtitle2" color="textSecondary">
          Participation not found
        </Typography>
      )}
      {inProgress && !error && (
        <Paper sx={{ marginTop: "10px" }}>
          <DataLoadingSpinner waitingMessage="Loading data..." />
        </Paper>
      )}
      {error && <TryAgainMessageBlock err={error.message} code={error.code} getData={getData} loading={inProgress} />}
    </Box> : <Box
      sx={{
        // padding: "20px",
        flexGrow: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
        <img src={warningImg} width="40px" alt="No permission" />
        <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
      </Paper>
    </Box>
  )
} )


export default AdmissionExamEvaluate