import { Box, Collapse, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useLiveSessionStore } from '../../store/livesessions.store'
import { observer } from 'mobx-react'
import { Icons, attachmentThumbnails } from '../../utils/utilities'
import Progress from '../common/Progress'
import { CheckRounded, Error } from '@mui/icons-material'

const ResourceUploadStatusDialog = observer( () => {

    const LiveSessionStore = useLiveSessionStore()

    const { theme, border, palette, shadows } = useTheme()

    const cancelUpload = resource => {
        if ( resource?.controller ) {
            resource.controller.abort()
        }
    }

    const handleCloseBtnClick = () => {
        LiveSessionStore.closeMinimizedResourceDialog()
    }


    return (
        LiveSessionStore.getMinimizedDialogState ? <Box
            borderRadius="10px 10px 0 0"
            overflow="hidden"
            boxShadow={shadows[1]}
            position="fixed"
            bottom="0"
            right="20px"
            width="100%"
            maxWidth="300px"
            sx={{ zIndex: 40000 }}
        >
            <Box display="flex" gap="20px" alignItems="center" justifyContent="space-between" padding="10px 20px" bgcolor={theme === 'dark' ? palette.grey[800] : palette.greyedOut}>
                <Typography variant='subtitle2' >Uploading {Object.keys( LiveSessionStore.getUploadProgressList ).length} resources </Typography>
                <IconButton size="small" onClick={handleCloseBtnClick} >{Icons.default.CloseIcon}</IconButton>
            </Box>
            <Collapse in={LiveSessionStore.getMinimizedUploadProgressDialogExpand}>
                <Box bgcolor={palette.common.bg} overflow="auto" className="slim-custom-scrollbar" maxHeight="400px">
                    {Object.keys( LiveSessionStore.getUploadProgressList ).map( resourceId => {

                        const ext = LiveSessionStore.getUploadProgressList[resourceId].ext

                        return <Box borderBottom={border[1]} display="flex" alignItems="center" padding="10px" key={resourceId}>
                            <Box display="flex" height="40px" sx={{ aspectRatio: "1" }} >
                                {LiveSessionStore.getUploadProgressList[resourceId].type === 'link' ? Icons.default.Link :
                                    <img style={{ objectFit: "fit" }} width="100%" src={attachmentThumbnails[ext] ? attachmentThumbnails[ext] : attachmentThumbnails["default"]} alt={ext} />}
                            </Box>
                            <Box marginLeft="5px" flexGrow={1}>
                                <Typography variant="subtitle2">{LiveSessionStore.getUploadProgressList[resourceId].name}</Typography>
                                <Typography variant="body2" textTransform="uppercase" fontSize="12px">{LiveSessionStore.getUploadProgressList[resourceId].status || "queued"}  {LiveSessionStore.getUploadProgressList[resourceId]?.status === 'inprogress' ? `(${Math.ceil( ( LiveSessionStore.getUploadProgressList[resourceId]?.progress || 0 ) * 100 )}%)` : ""}</Typography>
                            </Box>
                            {( LiveSessionStore.getUploadProgressList[resourceId].status !== 'failed' || LiveSessionStore.getUploadProgressList[resourceId].status !== 'cancelled' || LiveSessionStore.getUploadProgressList[resourceId].status !== 'completed' ) && <Progress size={25} trackWidth={5} indicatorWidth={5} progress={( LiveSessionStore.getUploadProgressList[resourceId].progress || 0 ) * 100} />}
                            {LiveSessionStore.getUploadProgressList[resourceId].status === 'completed' && <CheckRounded color="success" />}
                            {LiveSessionStore.getUploadProgressList[resourceId].status === 'failed' && <Error color="erromMessage" />}
                            <Tooltip placement='top' PopperProps={{ sx: { zIndex: 500000 } }} title={`Cancel upload`}>
                                <span>
                                    <IconButton onClick={() => cancelUpload( LiveSessionStore.getUploadProgressList[resourceId] )} disabled={LiveSessionStore.getUploadProgressList[resourceId].status === 'failed' || LiveSessionStore.getUploadProgressList[resourceId].status === 'cancelled'} color="errorMessage" size="small">{Icons.default.CancelIcon}</IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    } )}
                </Box>
            </Collapse>
        </Box> : ""
    )
} )

export default ResourceUploadStatusDialog
