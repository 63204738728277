import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { useUserStore } from '../../store/user.store'
import { Box, Typography } from '@mui/material'
import { defaultOnlineCourseThumbs } from '../../utils/utilities'
import { PlayArrowOutlined } from '@mui/icons-material'
import api from '../../service/api'
import { toast } from 'react-toastify'

const PlayOnlineCourseCard = ( { onlineCourse } ) => {
    // const UserStore = useUserStore()
    const navigate = useNavigate()

    const navigateToCourse = async () => {
        let role = 'student'
        try {
            if ( onlineCourse.status === "ENROLLED" ) {
                await api.startCourse( onlineCourse.id )
                navigate( `/${role}/online-course/${onlineCourse.online_course_id}/learn/${onlineCourse.progress_id}` )
            } else {
                navigate( `/${role}/online-course/${onlineCourse.online_course_id}/learn/${onlineCourse.progress_id}` )
            }
        } catch ( err ) {
            console.log( err )
            toast( "Something went wrong! try again later" )
        }
    }

    return (
        <Box maxWidth="220px" width="220px" onClick={navigateToCourse} position="relative" sx={{ cursor: "pointer", "&:hover .overlay": { display: "flex" } }}>
            <Box>
                <Box position="relative" sx={{}} border="1px solid rgba(0,0,0,0.4)" borderBottom="none" height="100px" width="auto">
                    <img src={onlineCourse?.thumbnail_uri.includes( 'http' ) ? onlineCourse?.thumbnail_uri : defaultOnlineCourseThumbs[parseInt( onlineCourse?.thumbnail_uri )]} style={{ objectFit: "cover" }} width="100%" height="100%" loading='lazy' alt={onlineCourse.title} />
                    <Box className='overlay' sx={{ display: "none", color: "white", alignItems: "center", justifyContent: "center", position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: "rgba(0,0,0,0.8)" }}>
                        <PlayArrowOutlined sx={{ fontSize: "55px" }} />
                    </Box>
                </Box>
                <Box padding="10px" border="1px solid rgba(0,0,0,0.4)" borderTop="none">
                    <Typography fontWeight="600" fontSize="16px" sx={{ "WebkitLineClamp": 2, "WebkitBoxOrient": "vertical", display: "-webkit-box !important" }} overflow="hidden" textOverflow="ellipsis" whiteSpace="normal">{onlineCourse.title}</Typography>
                    <Typography color="textSecondary" variant='subtitle2' textTransform="capitalize" >{onlineCourse.author}</Typography>
                    {onlineCourse.progress_percentage > 0 && <Typography padding="5px 0" marginTop="5px" variant='subtitle2' position="relative" sx={{
                        "&::after": { content: "''", position: "absolute", zIndex: 10, height: "3px", background: "#273679", left: 0, top: 0, width: `${onlineCourse.progress_percentage}%` },
                        "&::before": { content: "''", position: "absolute", zIndex: 10, height: "3px", background: "#aaa", left: 0, top: 0, width: `100%` }
                    }}>{onlineCourse.progress_percentage}% onlineCourse</Typography>}
                    <Typography fontSize="12px" variant='subtitle2' color="textSecondary">
                        {onlineCourse.status}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default PlayOnlineCourseCard
