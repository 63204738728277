import { Box, Button, Chip, CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import AddEditAuditor from '../../../components/admin/academic-program/AddEditAuditor'
import noAdminsImg from '../../../assets/no-subadmins.svg'
import { Icons } from '../../../utils/utilities'
import api from '../../../service/api'
import { toast } from 'react-toastify'

const AcademicProgressAuditors = () => {

    const { palette } = useTheme()
    const [loading, setLoading] = useState( true )
    const [allBranches, setAllBranches] = useState( [] )
    const [allAuditors, setAllAuditors] = useState( [] )
    const [addEditMode, setAddEditMode] = useState( null )
    const [deletingStatuses, setDeletingStatuses] = useState( {} )
    const [selectedAuditor, setSelectedAuditor] = useState( null )

    const getAuditors = async () => {
        try {
            const { data: { data: auditors } } = await api.getAllAcademicProgramAuditors()
            setAllAuditors( auditors )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong!" )
        }
    }

    const deleteAuditor = async ( id ) => {
        try {
            setDeletingStatuses( { ...deletingStatuses, [id]: true } )
            await api.deleteProgramAuditor( id )
            await getAuditors()
            setDeletingStatuses( { ...deletingStatuses, [id]: false } )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong!" )
        }
    }

    useEffect( () => {
        const getData = async () => {
            try {
                await getAuditors()
                const { data } = await api.fetchAcademicChecklistDepartments()
                setAllBranches( data.data )
            } catch ( err ) {
                console.log( err )
                toast.warning( err?.response?.data?.message || "Something went wrong!" )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [] )

    return (
        <Box padding="20px" flexGrow={1} display="flex" overflow="auto" flexDirection="column">
            <AddEditAuditor getAuditors={getAuditors} auditor={selectedAuditor} mode={addEditMode} state={addEditMode === "add" || ( addEditMode === "edit" && selectedAuditor !== null )} setState={setAddEditMode} />
            <Paper sx={{ display: "flex", flexDirection: "column", minHeight: !loading && "350px", flexGrow: ( loading || ( allAuditors.length === 0 && !loading ) ) ? "initial" : 1 }}>
                <Box display="flex" gap="20px" justifyContent="space-between" flexDirection={{ sm: "row", xs: "column" }} alignItems={{ sm: "center", xs: "stretch" }} padding="20px" borderBottom="1px solid #d3d3d3">
                    <Box>
                        <Typography variant="h5">Student academic progress auditors</Typography>
                        <Typography variant="subtitle2" color="textSecondary">Manage users who can audit students' academic progress</Typography>
                    </Box>
                    <Button onClick={() => setAddEditMode( "add" )} variant="contained" color="primary" startIcon={Icons.default.AddIcon} disableElevation sx={{ textTransform: "capitalize" }}>Create New auditor</Button>
                </Box>
                {allAuditors.length > 0 && !loading && <Box flexGrow={1} className="custom-scrollbar" overflow="auto">
                    {allAuditors.map( auditor => (
                        <Box padding="10px 20px" minWidth="800px" borderBottom="1px solid #d3d3d3" sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }} key={auditor.id} display="flex" alignItems="center">
                            <Box minWidth="200px" maxWidth="200px" onClick={() => { setAddEditMode( "edit" ); setSelectedAuditor( auditor ) }}>
                                <Typography fontSize="14px" fontWeight="500">{auditor.auditor_name}</Typography>
                                <Typography variant="subtitle2" color="GrayText">{auditor.auditor_id}</Typography>
                            </Box>
                            <Box flexGrow={1} onClick={() => { setAddEditMode( "edit" ); setSelectedAuditor( auditor ) }} display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                                {auditor.branches_allowed_for_auditing.map( ( id, index ) => {
                                    const branch = allBranches.find( i => String( i.course_branch_assignment_id ) === id )
                                    return branch ? <Chip key={index} sx={{ fontSize: "12px", fontWeight: "300", border: "1px solid #d3d3d3" }} label={branch.course_name} /> : ""
                                } )}
                            </Box>
                            <Box display="flex" width="fit-content" justifyContent="flex-end">
                                <Button disabled={deletingStatuses[auditor.id] ? true : false} onClick={() => deleteAuditor( auditor.id )} color="error" startIcon={deletingStatuses[auditor.id] ? <CircularProgress size={14} /> : Icons.default.DeleteIcon} variant='outlined' sx={{ textTransform: "capitalize" }}>{deletingStatuses[auditor.id] ? "Deleting..." : "Delete"}</Button>
                            </Box>
                        </Box>
                    ) )}
                </Box>}
                {allAuditors.length === 0 && !loading &&
                    <Box flexGrow={1} padding="20px" display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="10px" bgcolor="#eee">
                        <img src={noAdminsImg} width="170px" alt="No subadmins added" />
                        <Typography variant='subtitle2' color="textSecondary">No auditors exists!</Typography>
                        <Button onClick={() => setAddEditMode( "add" )} startIcon={Icons.default.AddIcon} variant='outlined' sx={{ textTransform: "capitalize" }}>Add Auditor</Button>
                    </Box>
                }
                {loading && <DataLoadingSpinner waitingMessage="Loading data..." />}
            </Paper>
        </Box>
    )
}

export default AcademicProgressAuditors
