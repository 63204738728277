import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAssignmentStore } from '../../store/assignment.store'
import { Box, Button, Drawer, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, useTheme } from '@mui/material'
import AssignmentSubmissionDownloadDialog from '../../components/presenter/AssignmentSubmissionDownloadDialog'
import noSubmissionImg from '../../assets/nosubmissions.svg'
import { Icons, convertToCDNLink, exportAssignmentReport, showDateInIndianDateFormat } from '../../utils/utilities'
import { observer } from 'mobx-react'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import TableHeader from '../../components/common/TableHeader'
import ConfirmDialog from '../../components/common/ConfirmDialog'
import { Comment } from '@mui/icons-material'
import DocumentViewer from '../../components/common/DocumentViewer'
import api from '../../service/api'
import { toast } from 'react-toastify'

const columns = [
    { name: 'Student', width: 260, padding: "10px 20px 10px 40px" },
    { name: 'AUID', width: 160 },
    { name: 'Submitted on', width: 160 },
    { name: 'Attachment', width: 160, align: "center" },
    { name: 'Score', width: 90, align: "center" },
    { name: 'Remark', width: 90, align: "center" },
    { name: 'Action', width: 100, padding: "10px 40px 10px 20px", align: "right" },
]

const EvaluateAssignment = observer( () => {
    let { assignment_id } = useParams()

    const AssignmentStore = useAssignmentStore()

    const [open, setOpen] = React.useState( false )
    const [loaded, setLoaded] = React.useState( false )
    const [deleting, setDeleting] = React.useState( false )
    const [previewFile, setPreviewFile] = React.useState( null )
    const [dialogType, setDialogType] = React.useState( 'download' ) // download || setmarks
    const [previewingFileName, setPreviewingFileName] = React.useState( null )
    const [selectedForDeletion, setSelectedForDeletion] = React.useState( null )
    const [selectedSubmissionForAction, setSelectedSubmissionForAction] = React.useState( [] )

    const { palette, border, table } = useTheme()

    const handleClose = () => {
        setOpen( null )
    }

    const viewSubmission = async ( value ) => {
        try {
            const { data: { data } } = await api.downloadAssignment( value, true )
            setPreviewingFileName( value?.split( "/" ).pop()?.replace( /(%\d{2}|\d+.)/g, " " )?.trim() || "submission.pdf" )
            setPreviewFile( convertToCDNLink( data ) )
        } catch ( err ) {
            console.log( err )
            toast( `An error occurred while viewing the submission!` )
        }
    }

    const openDownloadDialog = ( e, value ) => {
        setDialogType( 'download' )
        setSelectedSubmissionForAction( value )
        setOpen( e.currentTarget )
    }

    const deleteSubmission = async () => {
        setDeleting( true )
        await AssignmentStore.deleteAssignmentSubmission( selectedForDeletion )
        setSelectedForDeletion( null )
        setDeleting( false )
    }

    const openMarksAssignDialog = ( e, value ) => {
        setDialogType( 'setmarks' )
        setSelectedSubmissionForAction( value )
        setOpen( e.currentTarget )
    }

    const submitScore = async ( e, event ) => {
        const value = e.target.value
        if ( event === 'blur' || ( e.key === 'Enter' ) ) {
            let payload = { "assignment_submission_id": selectedSubmissionForAction.assignment_submission_id, "assignment_marks": value }
            await AssignmentStore.setAssignmentScore( payload )
        }
    }

    const openReMarksAssignDialog = ( e, value ) => {
        setDialogType( 'setremarks' )
        setSelectedSubmissionForAction( value )
        setOpen( e.currentTarget )
    }

    useEffect( () => {
        const getData = async () => {
            await AssignmentStore.fetchAssignmentSubmission( assignment_id )
            setLoaded( true )
        }
        getData()
    }, [AssignmentStore, assignment_id] )


    return (
        <Box flexGrow={1} minWidth="0" display="flex" flexDirection="column" overflow="auto" padding="20px">
            {/* Dialog */}
            <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={border[1]} display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFileName}</Typography>
                        <Box display="flex" alignItems="center" gap="10px" >
                            <Box position="relative" sx={{ zIndex: 8000000 }}>
                                <TextField size='small' onBlur={e => submitScore( e, 'blur' )} onKeyUp={e => submitScore( e, 'keyup' )} color="secondary" defaultValue={selectedSubmissionForAction.assignment_marks} InputProps={{ startAdornment: <Typography color="secondary" paddingRight="20px" variant='subtitle2'>SCORE:</Typography>, inputProps: { min: 0 } }} type="number" />
                            </Box>
                            <IconButton onClick={() => setPreviewFile( null )}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} height="300px">
                        <DocumentViewer type={previewingFileName?.split( "." ).pop()} setFile={setPreviewFile} file={previewFile} />
                    </Box>
                </Box>
            </Drawer>
            <AssignmentSubmissionDownloadDialog setPreviewFile={setPreviewFile} previewFile={previewFile} viewSubmission={viewSubmission} dialogType={dialogType} open={open} selectedValue={selectedSubmissionForAction} onClose={handleClose} />
            {selectedForDeletion && <ConfirmDialog actionName="Yes! delete it" status={Boolean( selectedForDeletion )} confirmAction={deleteSubmission} waiting={deleting} cancelAction={() => setSelectedForDeletion( null )} message={`Are you sure you want delete submission of ${selectedForDeletion.student_name}`} />}

            {/* Breadcrumb */}
            <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary">
                <Link className="breadcrumb-link" to='/faculty/assignment'>Assignments</Link>
                {Icons.small.ChevronRightIcon}
                {assignment_id}
                {Icons.small.ChevronRightIcon}
                Submissions
            </Typography>

            {/* Content */}
            <Paper sx={{ maxHeight: 600, flexGrow: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Box
                    sx={{
                        background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
                    }}
                    padding="20px" display="flex" alignItems={{ sm: "center", xs: "flex-start" }} justifyContent="space-between" flexDirection={{ sm: "row", xs: "column" }}>
                    <Box color="white">
                        <Typography variant='h5'>Submissions</Typography>
                        <Typography variant="body2" >Manage assignment submissions.</Typography>
                    </Box>
                    {loaded && AssignmentStore.getSubmission.length > 0 && <Button onClick={() => exportAssignmentReport( AssignmentStore.getSubmission )} sx={{ background: "#BEFFC7", color: "black", '&:hover': { background: "#a6ffb2" } }} variant='contained' disableElevation startIcon={Icons.default.DownloadForOfflineIcon}>Export as Excel</Button>}
                </Box>

                {loaded && AssignmentStore.getSubmission.length > 0 && <Box overflow="auto" bgcolor={palette.contentBg} border={border[1]} flexGrow={1} borderRadius="5px" margin="20px"  >
                    <TableContainer sx={{ overflow: "auto", height: "100%" }} className="custom-scrollbar" >
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {AssignmentStore.getSubmission?.map( submission => {

                                    return (
                                        <TableRow className='table-row' key={submission.id}>
                                            <TableCell sx={{ padding: columns[0].padding || "10px 20px", color: table.color }}>
                                                <strong>{submission.student_name}</strong>
                                            </TableCell>
                                            <TableCell sx={{ padding: columns[1].padding || "10px 20px", color: table.color }}>
                                                <strong>{submission.assignment_submitted_by}</strong>
                                            </TableCell>
                                            <TableCell sx={{ padding: columns[2].padding || "10px 20px", color: table.color }}>
                                                <strong>{showDateInIndianDateFormat( submission.assignment_submitted_on, { month: "short" } )}</strong>
                                            </TableCell>
                                            <TableCell align='center' onClick={e => openDownloadDialog( e, submission )} sx={{ padding: columns[3].padding || "10px 20px", color: table.color }}>
                                                <Button sx={{ fontSize: "14px", textTransform: "capitalize" }} size="small" color="primary" >View submissions</Button>
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: columns[4].padding || "10px 20px", color: table.color }}>
                                                <strong>{submission.assignment_marks || "Not Assigned"}</strong>
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: columns[5].padding || "10px 20px", color: table.color }}>
                                                <strong>{submission.assignment_remarks || "Not given"}</strong>
                                            </TableCell>
                                            <TableCell sx={{ padding: columns[6].padding || "10px 20px", color: "secondary.main" }}>
                                                <Box display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
                                                    <Button sx={{ textTransform: "capitalize", width: "max-content" }} onClick={e => openMarksAssignDialog( e, submission )} size="small" variant='outlined' color="success" >
                                                        Set Score
                                                    </Button>
                                                    <IconButton onClick={e => openReMarksAssignDialog( e, submission )} size="small" color='secondary'>
                                                        <Comment sx={{ fontSize: "18px" }} />
                                                    </IconButton>
                                                    <IconButton onClick={e => setSelectedForDeletion( submission )} size="small" color="error" >{Icons.default.DeleteIconOutlined}</IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                } )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}

                {!loaded && <DataLoadingSpinner waitingMessage="Loading submissions..." />}
                {loaded && AssignmentStore.getSubmission.length === 0 && <Box padding="20px" flexGrow="1" margin="10px" borderRadius="5px" bgcolor={palette.contentBg} display="flex" flexDirection="column" alignItems="center" gap="10px" color="textSecondary" variant='h6' justifyContent="center" textAlign="center" >
                    <img src={noSubmissionImg} width="200px" alt="No submissions!" />
                    <Typography variant='subtitle2' marginTop="10px" color="textSecondary" >No submissions received yet!</Typography>
                </Box>}
            </Paper>
        </Box>
    )
} )

export default EvaluateAssignment
