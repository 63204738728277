import { Box, Button, CircularProgress, Checkbox, FormControlLabel, FormControl, FormHelperText, TextField, Typography, Select, OutlinedInput, Chip, MenuItem, Paper, useTheme, Popover } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { supportedFormats, attachmentsSupportedFormats, formatDateTimeForInputField, formatDateTimeForDB } from '../../../utils/exam-utilities'
import * as Yup from 'yup'
import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import api from '../../../service/api'
import { useExamStore } from '../../../store/exam.store'
import { flatColorsAndBackgrounds } from '../../../utils/utilities'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { useUserStore } from '../../../store/user.store'
import BreadcrumbList from '../../../components/common/BreadcrumbList'

const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
const minutes = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]

export default function EditExam() {
    // const attachmentsRef = useRef()
    const { id } = useParams()
    const ExamStore = useExamStore()

    // const [previewUrl, setPreviewUrl] = useState( null )
    const [isDisabled, setIsDisabled] = useState( true )
    const [exam, setExam] = useState( null )
    const [isFetchingBatches, setIsFetchingBatches] = useState( false )
    const [examName, setExamName] = useState( '' )
    const [description, setDescription] = useState( '' )
    const [examTopics, setExamTopics] = useState( '' )
    const [examStartDate, setExamStartDate] = useState( '' )
    const [examEndDate, setExamEndDate] = useState( '' )
    const [inProgress, setInProgress] = useState( true )
    const [selectedBatches, setSelectedBatches] = useState( [] )
    // const [selectedBatchesNames, setSelectedBatchesNames] = useState( [] )
    const [newBatches, setNewBatches] = useState( [] )
    const [isUpdating, setIsUpdating] = useState( false )
    const [batches, setBatches] = useState( {} )
    const [sections, setSections] = useState( {} )
    const [isOpenExam, setIsOpenExam] = useState( true )
    const [durationHours, setDurationHours] = useState( 0 )
    const [academicYear, setAcademicYear] = useState( "-1" )
    const [maximumAttempt, setMaximumAttempt] = useState( 0 )
    const [openExamInfo, setOpenExamInfo] = useState( null )
    const [academicYears, setAcademicYears] = useState( null )
    const [durationMinutes, setDurationMinutes] = useState( 0 )
    // const [sessionExpired, setSessionExpired] = useState( false )

    const navigate = useNavigate()
    const UserStore = useUserStore()

    const { palette, border } = useTheme()

    const initialValues = {
        title: '',
        description: '',
        topics: '',
        startDateTime: '',
        endDateTime: '',
        attachments: null,
        thumbnail: null,
        selectedBatches: '',
        duration: null
    }

    const validationSchema = Yup.object().shape( {
        title: Yup.string().required( "Exam title is required." ),
        description: Yup.string().required( "Exam description is required." ).min( 5, "Description as to be least 5 characters." ),
        topics: Yup.array().required( "Please provide the exam topics." ),
        thumbnail: Yup.mixed().nullable().notRequired().test( "FILE_FORMAT", "Unsupported file format, accepted formats: JPG,JPEG,PNG.", ( value ) => {
            if ( value )
                return supportedFormats.includes( value.type )
            return true
        } ),
        // startDateTime: Yup.date().required().min( new Date(), "Date is invalid" ),
        // endDateTime: Yup.date().required().when( "startDateTime", ( startDate ) => {
        //     if ( startDate ) return Yup.date().min( startDate, "End date must be after start date" )
        // } ),
        attachments: Yup.mixed().nullable().notRequired().test( "ATTACHMENTS_FILE_FORMAT", "Unsupported attachment format accepted formats: PDF,DOCX,DOC,XLS.", ( value ) => {
            if ( value ) {
                const files = Object.values( value )
                for ( let i = 0; i < files.length; i++ ) {
                    const file = files[i]
                    if ( !attachmentsSupportedFormats.includes( file.type ) ) {
                        return false
                    }
                }
                return true
            }
            else return true
        } ),
        duration: Yup.string().nullable().test( "CHECK_DURATION", "Invalid exam duration", ( value ) => {
            if ( isOpenExam ) {
                if ( value && value !== '00:00' ) return true
                return false
            } else return true
        } ),
        maximumAttempt: Yup.number().required( "This field is required!" ).min( 1, "At least 1 attempt must be given" )
    } )

    const getBatches = async ( year = academicYear ) => {
        setIsFetchingBatches( true )
        try {
            const batchesData = await api.getAllBatch( { ac_year_id: year } )
            if ( !batchesData.data.success && ( batchesData.data.unauth || batchesData.data.message === "Invalid Token" ) ) {
                UserStore.setERPSessionState( "ended" )
            }
            if ( ( batchesData.data.data && batchesData.data.data.batches && batchesData.data.data.batches.length > 0 ) ) {
                const batchesFetched = batchesData.data.data.batches
                const batchesForSelect = {}
                for ( let i = 0; i < batchesFetched.length; i++ ) {
                    const batch = batchesFetched[i]
                    // if ( !selectedBatches.includes( `${batch.batch_short_name}-${batch.batch_assignment_id}` ) ) {
                    batchesForSelect[`${batch.batch_assignment_id}`] = batch.batch_name + "-" + batch.course_name_short + " Y" + batch.current_year + " S" + batch.current_sem + " " + batch.batch_short_name
                    // }
                }
                setBatches( { ...batchesForSelect } )
            }
            if ( ( batchesData.data.data && batchesData.data.data.sections && batchesData.data.data.sections.length > 0 ) ) {
                const sectionsFetched = batchesData.data.data.sections
                const sectionsForSelect = {}
                for ( let i = 0; i < sectionsFetched.length; i++ ) {
                    const section = sectionsFetched[i]
                    // if ( !selectedBatches.includes( `${section.section_short_name}-${section.section_assignment_id}` ) ) {
                    sectionsForSelect[`${section.section_assignment_id}-${section.section_short_name}`] = section.course_branch_short_name + "-" + section.course_short_name + " Y" + section.current_year + " S" + section.current_sem + " (" + section.section_short_name + ")"
                    // }
                }
                setSections( { ...sectionsForSelect } )
            }
            // setSelectedBatchesNames( selectedBatchesAndSectionsNames )
        } catch ( e ) {
            console.log( e )
            if ( e.message === "Network Error" )
                navigate( '/networkerror' )
            toast.error( "Something went wrong!" )
        } finally {
            setIsFetchingBatches( false )
        }
    }

    const handleBatchChange = ( e ) => {
        if ( e.target.value ) {
            setNewBatches( e.target.value )
        }
    }

    const submit = async ( values, props ) => {
        if ( new Date( formatDateTimeForInputField( exam.exam_start_date, exam.exam_start_time ) ) < new Date() ) {
            toast.error( "Exam already started! can't edit now" )
            navigate( `/faculty/exams/${exam.exam_id}` )
        }
        setIsUpdating( true )
        const formData = new FormData()
        formData.append( 'title', values.title )
        if ( values.thumbnail )
            formData.append( "thumbnail", values.thumbnail, `${values.title} - thumb.${values.thumbnail.name.split( "." )[values.thumbnail.name.split( "." ).length - 1]}` )
        formData.append( 'description', values.description )
        formData.append( 'topics', JSON.stringify( values.topics ) )
        formData.append( 'startDateTime', JSON.stringify( formatDateTimeForDB( values.startDateTime, 'en-IN', '-' ) ) )
        formData.append( 'endDateTime', JSON.stringify( formatDateTimeForDB( values.endDateTime, 'en-IN', '-' ) ) )
        formData.append( 'selectedBatches', JSON.stringify( newBatches ) )
        formData.append( 'duration', isOpenExam ? values.duration : null )
        formData.append( 'maximumAttempt', values.maximumAttempt )
        if ( values.attachments )
            for ( let i = 0; i < values.attachments.length; i++ ) {
                formData.append( "attachments[]", values.attachments[i], `${values.title}-${Date.now()}${String( i + 1 )}.${values.attachments[i].name.split( "." )[values.attachments[i].name.split( "." ).length - 1]}` )
            }

        // const res = await Axios.put( '/exams/' + exam.exam_id, formData, {
        //     headers: {
        //         "Authorization": `Bearer ${localStorage.getItem( "id_token" )}`
        //     }
        // } )
        await ExamStore.updateExam( id, formData )
        setIsDisabled( true )
        setIsUpdating( false )
        navigate( `/faculty/exams/${id}` )
    }

    const setFields = ( setFieldValue ) => {
        setFieldValue( 'title', document.getElementById( 'title' ).value )
        setFieldValue( 'description', document.getElementById( 'description' ).value )
        setFieldValue( 'topics', document.getElementById( 'topics' ).value.trim() !== "" ? document.getElementById( 'topics' ).value.split( "," ) : '' )
        setFieldValue( 'startDateTime', dayjs( examStartDate ) )
        setFieldValue( 'endDateTime', dayjs( examEndDate ) )
        setFieldValue( 'selectedBatches', selectedBatches )
        setFieldValue( 'duration', `${durationHours ? durationHours : "0"}:${durationMinutes ? durationMinutes : "0"}` )
        setFieldValue( 'maximumAttempt', maximumAttempt )
    }

    useEffect( () => {
        const getData = async () => {
            const getAcedemicYears = async () => {
                try {
                    const res = await api.getAllBatchAcademicYear()
                    if ( res.data.success === false && res.data.message === "Invalid Token" ) {
                        UserStore.setERPSessionState( "ended" )
                    }
                    setAcademicYears( res.data.data ? res.data.data : [] )
                } catch ( e ) {
                    console.log( e )
                    if ( e.message === "Network Error" )
                        navigate( '/networkerror' )
                    else if ( e?.response.status === 401 || e.response?.data.unauth )
                        UserStore.setERPSessionState( "ended" )
                    else toast.error( "Something went wrong! try again later" )
                }
            }
            try {
                await ExamStore.fetchExam( id )
                const e = ExamStore.getExam.exam
                if ( new Date( formatDateTimeForInputField( e.exam_start_date, e.exam_start_time ) ) < new Date() ) {
                    toast.error( "Exam already started! can't edit now" )
                    navigate( `/faculty/exams/${e.exam_id}` )
                } else if ( e.exam_status === 'Published' ) {
                    toast.error( "Exam already published! can't edit now" )
                    navigate( `/faculty/exams/${e.exam_id}` )
                }
                else {
                    setExam( e )
                    setExamName( e.exam_name )
                    setDescription( e.exam_description )
                    setExamTopics( e.exam_topics )
                    setExamStartDate( formatDateTimeForInputField( e.exam_start_date, e.exam_start_time ) )
                    setExamEndDate( dayjs( formatDateTimeForInputField( e.exam_end_date, e.exam_end_time ) ) )
                    setSelectedBatches( e.batches_ids )
                    setNewBatches( e.batches_ids )
                    if ( e.exam_duration !== null && e.exam_duration !== 'null' ) {
                        setIsOpenExam( true )
                        setDurationHours( dh => e.exam_duration.split( ":" )[0] )
                        setDurationMinutes( dh => e.exam_duration.split( ":" )[1] )
                    } else {
                        setIsOpenExam( false )
                        setDurationHours( dh => "" )
                        setDurationMinutes( dh => "" )
                    }
                    setMaximumAttempt( e.maximum_attempts )
                    await getAcedemicYears()
                    setInProgress( false )
                }
            } catch ( e ) {
                if ( e.response && e.response.data )
                    if ( e.response.data === "Exam not found!" ) {
                        toast.error( "Exam not found" )
                        navigate( '/faculty/exams' )
                    } else
                        toast.error( e.response.data )
                else if ( e.message )
                    toast.error( e.message )
            }
        }
        getData()
    }, [id, ExamStore, navigate, UserStore] )

    return (
        // <Layout>
        <Box padding="20px" flexGrow={1}>
            <BreadcrumbList items={[
                { label: "Exams", link: '/faculty/exams' },
                { label: exam ? exam.exam_name : <CircularProgress size={12} />, link: `/faculty/exams/${id}` },
                "Edit"
            ]} />

            <Paper sx={{ flexGrow: 1, minWidth: "0", overflow: "auto", borderRadius: "10px" }} >

                {exam && !inProgress && <Box>
                    <Box padding="20px" sx={{ background: `linear-gradient(233.69deg, #6A6790 -93.75%, #1F1E2A 145.73%)` }} >
                        <Typography variant='h6' color="white" textTransform="capitalize">{exam ? exam.exam_name : ""}</Typography>
                        <Typography variant='body2' color="white" textTransform="capitalize">Edit exam details</Typography>
                    </Box>
                    <Box margin="20px" bgcolor={palette.contentBg} padding="20px" border={border[1]} borderRadius="5px" >
                        <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                            {( { values, setFieldValue } ) => (
                                <Form method='POST' autoComplete='off' style={{ height: "100%" }} id="edit-form">
                                    <FormControl margin="normal" fullWidth>
                                        <label htmlFor="title"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Exam title * </Typography></label>
                                        <Field as={TextField}
                                            fullWidth name='title'
                                            value={examName}
                                            InputProps={{ sx: { fontSize: "14px" } }}
                                            size="small"
                                            id='title'
                                            onChange={( e ) => { setIsDisabled( false ); setExamName( e.target.value ); setFields( setFieldValue ); setFieldValue( 'title', e.target.value ) }}
                                        />
                                        <FormHelperText sx={{ margin: "-5px 0 0 2px" }}><span style={{ color: "#d32f2f", fontWeight: "bold", dispaly: "block" }}><ErrorMessage name='title' /></span></FormHelperText>
                                    </FormControl>
                                    <FormControl margin="normal" fullWidth>
                                        <label htmlFor="description"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Exam description * </Typography></label>
                                        <Field as={TextField}
                                            multiline
                                            rows={4}
                                            fullWidth name='description'
                                            InputProps={{ sx: { fontSize: "14px" } }}
                                            id='description'
                                            value={description}
                                            onChange={( e ) => { setIsDisabled( false ); setDescription( e.target.value ); setFields( setFieldValue ); setFieldValue( 'description', e.target.value ) }}
                                        />
                                        <FormHelperText sx={{ margin: "-5px 0 0 2px", color: "#d32f2f", fontWeight: "bold" }}><ErrorMessage name='description' /></FormHelperText>
                                    </FormControl>
                                    <FormControl margin="normal" fullWidth>
                                        <label htmlFor="topics"><Typography gutterBottom variant="subtitle2" color={palette.labelColor}> Topics * </Typography></label>
                                        <Field as={TextField} onChange={( e ) => { setIsDisabled( false ); setExamTopics( e.target.value.split( "," ) ); setFields( setFieldValue ); setFieldValue( 'topics', e.target.value.trim() !== "" ? e.target.value.split( "," ) : '' ) }}
                                            onBlur={( e ) => { setFields( setFieldValue ) }}
                                            InputLabelProps={{ sx: { fontSize: "14px !important", } }}
                                            name='topics'
                                            id="topics"
                                            size="small"
                                            value={examTopics}
                                        />
                                        <Typography fontSize="12px" color="error"><ErrorMessage name='topics' /></Typography>
                                    </FormControl>
                                    <Box gap="20px" marginTop="10px" display="flex" alignItems="flex-end" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }}>
                                        <FormControl sx={{ maxWidth: "250px", width: "40%", position: "relative" }} fullWidth>
                                            <label htmlFor='academicYears' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Batches exam conducted for *</Typography> </label>
                                            {academicYears && <Select
                                                size="small"
                                                sx={{ color: academicYear === "-1" && palette.form.placeholder }}
                                                value={academicYear} onChange={( e ) => { setAcademicYear( e.target.value ); getBatches( e.target.value ) }} input={<OutlinedInput />}>
                                                <MenuItem sx={{ fontSize: "14px" }} value="-1" disabled>{academicYears.length === 0 ? "No academic years!" : "Select academic year"}</MenuItem>
                                                {academicYears.length > 0 && academicYears.map( year => (
                                                    <MenuItem sx={{ fontSize: "14px" }} key={year.ac_year_id} value={year.ac_year_id}>{year.ac_year}</MenuItem>
                                                ) )}
                                            </Select>}
                                        </FormControl>
                                        <FormControl sx={{ maxWidth: "250px", width: "40%", position: "relative" }} fullWidth>
                                            <Select displayEmpty size="small" disabled={isFetchingBatches || academicYear === "-1"} name='selectedBatches' multiple value={newBatches} onChange={( e ) => { handleBatchChange( e ); setFieldValue( 'selectedBatches', typeof e.target.value === 'string' ? e.target.value.split( "," ) : e.target.value ); setIsDisabled( false ); setFields( setFieldValue ) }} input={<OutlinedInput />}
                                                renderValue={( selected ) => (
                                                    <Typography noWrap color={newBatches.length === 0 && palette.form.placeholder} fontSize="14px" >
                                                        {newBatches.length > 0 ? newBatches.join( ", " ) : "Select batch or section"}
                                                    </Typography>
                                                )}>
                                                <Typography paddingX="15px" fontWeight="bold" color="secondary">Batches</Typography>
                                                {batches && Object.keys( batches ).length > 0 && Object.keys( batches ).map( batch => (
                                                    <MenuItem sx={{ fontSize: "14px" }} key={batch} value={batch}>{String( batches[batch] )}</MenuItem>
                                                ) )}
                                                {( !batches || Object.keys( batches ).length === 0 ) && <MenuItem value={"No batches"} disabled>No batches for selected year!</MenuItem>}
                                                <hr />
                                                <Typography paddingX="15px" fontWeight="bold" color="secondary">Sections</Typography>
                                                {sections && Object.keys( sections ).length > 0 && Object.keys( sections ).map( section => (
                                                    <MenuItem sx={{ fontSize: "14px" }} key={section} value={section}>{sections[section]}</MenuItem>
                                                ) )}
                                                {( !sections || Object.keys( sections ).length === 0 ) && <MenuItem value={"No sections"} disabled>No sections for selected year!</MenuItem>}
                                            </Select>
                                            {isFetchingBatches && <Typography position="absolute" top="100%" marginLeft="5px" variant='body2' color="textSecondary"> <CircularProgress size={10} sx={{ marginRight: "5px" }} /> Loading batches...</Typography>}
                                        </FormControl>
                                    </Box>

                                    {selectedBatches.length > 0 && <Box marginY="10px" bgcolor={palette.contentBg} borderRadius="5px">
                                        <Typography fontSize="14px" variant='subtitle2'>Selected batches</Typography>
                                        <Box display="flex" marginTop="10px" gap="10px" flexWrap="wrap">
                                            {selectedBatches.map( ( batch, index ) => {
                                                return (
                                                    <Chip sx={{ fontWeight: "500", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, background: flatColorsAndBackgrounds[2].bg }} key={batch} label={batch} />
                                                )
                                            } )}
                                        </Box>
                                    </Box>}

                                    <FormControl margin='normal' variant='filled' fullWidth sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" } }}>
                                        <label htmlFor='maximumAttempt' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Maximum attempt *</Typography> </label>
                                        <Field as={TextField} size="small" value={maximumAttempt} fullWidth name='maximumAttempt' onChange={e => { setMaximumAttempt( e.target.value ); setIsDisabled( false ); setFields( setFieldValue ); setFieldValue( 'maximumAttempt', e.target.value ) }} />
                                        <Typography fontSize="12px" variant='subtitle2' color="errorMessage"><ErrorMessage name='maximumAttempt' /></Typography>
                                    </FormControl>

                                    <FormControl margin='normal' sx={{ display: "flex", maxWidth: "250px" }}>
                                        <Box>
                                            <label htmlFor='startdatetime' > <Typography gutterBottom variant="subtitle2" color={palette.labelColor}>Start date and time *</Typography> </label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    id="startdatetime"
                                                    sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    minDateTime={dayjs( new Date() )}
                                                    value={dayjs( examStartDate )}
                                                    format="DD-MM-YYYY hh:mm A"
                                                    onChange={( e ) => { setIsDisabled( false ); setExamStartDate( e.$d ); setFields( setFieldValue ); setFieldValue( 'startDateTime', e.$d ) }}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <Typography variant="subtitle2" fontSize="12px" color="textSecondary">Enter start date and time.</Typography>
                                        <Typography color="errorMessage"><ErrorMessage name='startDateTime' /></Typography>

                                    </FormControl>

                                    <FormControl margin='normal' sx={{ maxWidth: "250px", display: "flex" }}>
                                        <Box  >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    id="startdatetime"
                                                    minDateTime={dayjs( examStartDate )}
                                                    sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    value={dayjs( examEndDate )}
                                                    format="DD-MM-YYYY hh:mm A"
                                                    onChange={( e ) => { setIsDisabled( false ); setExamEndDate( e.$d );; setFields( setFieldValue ); setFieldValue( 'endDateTime', e.$d ) }}
                                                // onAccept={( e ) => { setIsDisabled( false ); setFieldValue( 'endDateTime', e.$d ) }}
                                                // renderInput={( params ) => <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} {...params} sx={{ width: { lg: "214px", md: "214px", sm: "214px", xs: "100%" } }} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <Typography variant="subtitle2" fontSize="12px" color="textSecondary">Enter end date and time.</Typography>
                                        <Typography color="errorMessage"><ErrorMessage name='endDateTime' /></Typography>
                                    </FormControl>

                                    {isOpenExam && <FormControl variant='outlined' sx={{ display: "flex" }}>
                                        <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Exam duration</Typography>
                                        <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                                            <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                                <Select endAdornment={<Typography fontSize="12px" paddingRight="20px" color="textSecondary" sx={{ pointerEvents: "none" }} >HOURS</Typography>} size="small" onChange={e => { setDurationHours( e.target.value ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours}>
                                                    {hours.map( hour => (
                                                        <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                                    ) )}
                                                </Select>
                                                {/* <TextField type="number" sx={{ width: "246px" }} InputLabelProps={{ sx:{fontSize:"14px !important"} }} label="Hours" onWheel={e => e.target.blur()} onChange={e => { setDurationHours( e.target.value ); setFieldValue( 'duration', `${e.target.value}:${durationMinutes}` ) }} value={durationHours} /> */}
                                            </FormControl >
                                            <FormControl sx={{ maxWidth: "250px" }} fullWidth>
                                                <Select endAdornment={<Typography fontSize="12px" paddingRight="20px" color="textSecondary" sx={{ pointerEvents: "none" }} >MIN</Typography>} size="small" onChange={e => { setDurationMinutes( e.target.value ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes}>
                                                    {minutes.map( min => (
                                                        <MenuItem key={min} value={min}>{min}</MenuItem>
                                                    ) )}
                                                </Select>
                                                {/* <TextField type="number" sx={{ width: "214px" }} InputLabelProps={{ sx:{fontSize:"14px !important"} }} label="Minutes" onWheel={e => e.target.blur()} onChange={e => { setDurationMinutes( e.target.value ); setFieldValue( 'duration', `${durationHours}:${e.target.value}` ) }} value={durationMinutes} /> */}
                                            </FormControl>
                                        </Box>

                                        <Typography variant="subtitle2" >Exam duration: {`${durationHours ? durationHours : 0} hours ${durationMinutes ? durationMinutes : 0} minutes`}</Typography>
                                        <Typography variant="subtitle2" color="errorMessage" ><ErrorMessage name='duration' /></Typography>
                                    </FormControl>}


                                    {<Box position="relative">
                                        {<Popover slotProps={{ paper: { sx: { maxWidth: "300px" } } }} anchorEl={openExamInfo} onClose={e => setOpenExamInfo( null )} open={Boolean( openExamInfo )} >
                                            <Typography padding="10px" borderBottom="1px solid #d3d3d3" variant="subtitle2" color="secondary">Stretched Exams</Typography>
                                            <Typography padding="10px" textAlign="justify" variant='body2'>In stretched exams the the exam countdown once started by a student, ends at the exam's end date and time as opposed to the duration exam where the countdown ends when the duration set by the faculty ends.</Typography>
                                        </Popover>}
                                        <Box display="flex" alignItems="center">
                                            <FormControlLabel control={<Checkbox color='primaryDark' value={!( isOpenExam )} onChange={e => { setIsOpenExam( !e.target.checked ) }} />} label="Stretch exam from start to end time?" />
                                            <InfoIcon sx={{ cursor: "pointer" }} onClick={e => setOpenExamInfo( e.currentTarget )} />
                                        </Box>
                                    </Box>}


                                    <Box display="flex" alignItems="center" marginTop="40px" gap="20px">
                                        <Button disableElevation sx={{ marginBottom: "20px", textTransform: "capitalize" }} disabled={( isDisabled || isUpdating )} type='submit'
                                            variant='contained' >{isUpdating ? "Updating..." : "Update"}</Button>
                                        <Button disableElevation sx={{ marginBottom: "20px", textTransform: "capitalize" }} variant='contained' color="error" onClick={() => navigate( `/faculty/exams/${exam.exam_id}` )}>Cancel</Button>
                                    </Box>
                                </Form >
                            )}
                        </Formik >
                    </Box>
                </Box >}
                {inProgress && <DataLoadingSpinner waitingMessage="Loading exam..." />}
            </Paper >
        </Box >
        // </Layout>
    )
}
