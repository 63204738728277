import WeekCalendar from '../../components/dashboard-components/WeekCalendar'
import { Avatar, Box, Paper, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import SubjectsCard from '../../components/dashboard-components/SubjectsCard'
import { useUserStore } from '../../store/user.store'
import PendingOrRecentAssignmentsCard from '../../components/dashboard-components/PendingOrRecentAssignmentsCard'
import CurrentOrRecentExamsCard from '../../components/dashboard-components/CurrentOrRecentExams'
import DiscussionsCard from '../../components/dashboard-components/DiscussionsCard'

const Dashboard = observer( () => {

    const UserStore = useUserStore()

    return (
        <Box display="grid" flexGrow={1} gridTemplateColumns="100% 0" sx={{ transition: "0.5s ease-in-out", '@media(max-width:1100px)': { gridTemplateColumns: "100% 0" } }} height="100%" overflow="auto">

            <Box display="flex" flexDirection="column" gap="20px" padding="20px" >
                <Paper sx={{ overflow: "hidden" }} >
                    <Box borderRadius="5px" padding="20px 20px" position="relative" sx={{
                        background: `url('/imgs/dashboard-header-bg.jpg')`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}>
                        <Box height="100%" position="absolute" sx={{ zIndex: 0, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 150.03%)`, }}>
                        </Box>
                        <Box sx={{ position: "relative", zIndex: 10 }} display="flex" flexWrap="wrap" gap="20px" alignItems="center">

                            <Avatar sx={{ height: "90px", width: "90px", borderRadius: "10px" }} src={UserStore.getUser.user_profile}>{UserStore.getUser.employee_name[0]}</Avatar>
                            <Box>
                                <Typography color="white" variant="body2" marginBottom="20px" textTransform="capitalize">Profile</Typography>

                                <Typography color="white" variant="h5" textTransform="capitalize">Welcome {UserStore.getUser.employee_name}</Typography>
                                <Typography color="white" fontSize="14px" fontWeight="300">{UserStore.getUser.user_dept_code}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Paper>

                <Box display="grid" gap="20px" gridTemplateColumns={{ md: "2.5fr 1fr" }}>
                    <Box display="flex" flexDirection="column" gap="20px">
                        <WeekCalendar shadow />
                        <Box display="grid" gap="20px" gridTemplateColumns={{ md: "1fr 1fr", xs: "1fr" }}>
                            <PendingOrRecentAssignmentsCard />
                            <Box display="grid" gap="20px" gridTemplateColumns={{ md: "1fr", xs: "1fr" }}>
                                <CurrentOrRecentExamsCard />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="20px">
                        <SubjectsCard />
                        <DiscussionsCard />
                    </Box>
                </Box>

                {/* <OnlineClassesCard isClassesLoaded={isClassesLoaded} />

                <Box display="flex" gap="20px" flexWrap="wrap">

                    {areEventsLoaded && <EventsCalendar events={calendarEvents} />}

                    <SubjectsCard />

                    <PendingOrRecentCard title="Upcoming exams and assignments" />

                </Box> */}
            </Box>
            {/* <SchedulesAndActivities /> */}
        </Box >
    )
} )

export default Dashboard
