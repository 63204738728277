import { Dialog, DialogContent, Typography, Divider, Box, IconButton, useTheme, Drawer } from '@mui/material'
import { useAssignmentStore } from "../../store/assignment.store"
import noPermissionImg from '../../assets/no-permission.svg'
import Download from "@mui/icons-material/Download"
import { useSearchParams } from 'react-router-dom'
import { Icons, attachmentThumbnails, convertToCDNLink, isIOS, showDateInIndianDateFormat } from "../../utils/utilities"
import { toast } from "react-toastify"
import PropTypes from 'prop-types'
import { Visibility, WarningAmberRounded } from '@mui/icons-material'
import api from '../../service/api'
import { useState } from 'react'
import DocumentViewer from './DocumentViewer'

const extentionsToNameMap = {
    'pdf': 'PDF',
    'docx': 'Document',
    'xslx': 'Spreadsheet',
    'jpg': 'Image',
    'jpeg': 'Image',
    'png': 'Image'
}

const AssignmentViewer = ( { onClose, selectedValue, open, loaded } ) => {

    const [previewFile, setPreviewFile] = useState( null )
    const [previewingFileName, setPreviewingFileName] = useState( null )

    const AssignmentStore = useAssignmentStore()
    const { palette, border } = useTheme()
    const [searchParams] = useSearchParams( { searchText: '', selectedAssignmentId: null, open: false } )
    const selectedAssignmentId = searchParams.get( 'selectedAssignmentId' )

    const handleClose = () => {
        onClose( selectedValue )
    }

    const handleListItemClick = ( value ) => {
        AssignmentStore.downloadAssignment( value )
        toast( <span>Downloading <b style={{ color: palette.customThemeColor.main }}>{value.split( "." )[value.split( "." ).length - 2].replace( /%\d+/g, " " )}</b>... </span>, { position: "bottom-left" } )
    }

    const viewAttachment = async ( value ) => {
        try {
            const { data: { data } } = await api.downloadAssignment( value, true )

            setPreviewingFileName( value?.split( "/" ).pop()?.replace( /(%\d{2}|\d+)/g, " " )?.trim() || "submission.pdf" )
            setPreviewFile( convertToCDNLink( data ) )
        } catch ( err ) {
            console.log( err )
            toast( `An error occurred while viewing the submission!` )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "650px" } }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={border[1]} display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFileName}</Typography>
                        <Box display="flex" alignItems="center" gap="10px" >
                            <IconButton onClick={() => setPreviewFile( null )}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} height="300px">
                        <DocumentViewer type={previewingFileName?.split( "." ).pop()} setFile={setPreviewFile} loadingText={`Loading ${previewingFileName}...`} file={previewFile} />
                    </Box>
                </Box>
            </Drawer>
            {loaded && selectedValue && <Box>
                <Box sx={{
                    background: `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)`
                }} display="flex" alignItems="center" justifyContent="space-between" padding="20px" >
                    <Box>
                        <Typography color="white" variant="h6">{selectedValue.assignment_name}</Typography>
                        <Typography color="white" variant="subtitle2">Due Date: {new Date( selectedValue.assignment_due_date ).toLocaleDateString( "en-IN", { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}</Typography>
                    </Box>
                    <IconButton sx={{ color: "white" }} onClick={handleClose}>{Icons.default.CloseIcon}</IconButton>
                </Box>
                <Divider />
                <DialogContent sx={{ marginBottom: "20px" }}>
                    <Typography variant="subtitle1" fontWeight="bold" >Created On</Typography>
                    <Typography variant="subtitle2" fontSize="12px">{showDateInIndianDateFormat( selectedValue.assignment_created_at, { month: "short" } )}</Typography>

                    <Typography marginTop="20px" variant="subtitle1" fontWeight="bold" >Details</Typography>
                    <Typography variant="body1">{selectedValue.assignment_details}</Typography>

                    {/* <Typography variant="subtitle1" marginTop="20px" fontWeight="bold" >Attachments</Typography> */}
                    {selectedValue.assignment_file_paths && selectedValue.assignment_file_paths.length > 0 && <Box marginTop="20px" display="flex" gap="10px" flexDirection="column">
                        {selectedValue.assignment_file_paths.map( ( item, index ) => (
                            <Box gap="20px" color="secondary.main" key={`item-${index}`} width="fit-content" display="flex" alignItems="center" justifyContent="space-between" borderRadius="40px" bgcolor={palette.secondary.light + "22"} border={`1px solid ${palette.secondary.main}`} padding="5px 10px" sx={{ cursor: "pointer" }}>
                                <Box display="flex" gap="5px" alignItems="center">
                                    <Box marginTop="5px">
                                        <img style={{ objectFit: "fit" }} width="35px" src={attachmentThumbnails[item.split( "." ).pop().toLowerCase()] || attachmentThumbnails["default"]} alt={item.split( "/" ).pop().replace( /%\d+/g, " " )} />
                                    </Box>
                                    <Box>
                                        <Typography noWrap color="secondary" variant="subtitle2">{item.split( "/" ).pop().split( "." )[1].replace( /%\d+/g, " " )}</Typography>
                                        <Typography variant="body2" color="secondary" fontSize="10px">{extentionsToNameMap[item.split( "." ).pop().toLowerCase()] || ""}</Typography>
                                    </Box>
                                </Box>
                                <IconButton onClick={() => handleListItemClick( item )} size="small"><Download color='secondary' /></IconButton>
                                {( item?.endsWith( 'pdf' ) || !isIOS() ) && <IconButton onClick={() => viewAttachment( item )} size="small"><Visibility color='secondary' /></IconButton>}
                            </Box>
                        ) )}
                    </Box>}

                </DialogContent>
            </Box>}
            {loaded && !selectedValue && <Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" padding="20px" borderBottom="1px solid #d3d3d3">
                    <Box display="flex" gap="20px" alignItems="center">
                        <WarningAmberRounded />
                        <Typography variant="h6">Access denied</Typography>
                    </Box>
                    <IconButton sx={{ color: "white" }} onClick={handleClose}>{Icons.default.CloseIcon}</IconButton>
                </Box>
                <Box padding="20px" display="flex" flexDirection="column" gap="20px" alignItems="center">
                    <img width="200px" src={noPermissionImg} alt="No permissions" />
                    <Typography variant='subtitle2' fontWeight="normal" textAlign="center">You doesn't have the permission to view assignment with ID <i><b>{selectedAssignmentId}</b></i> or this assignment has already been deleted. </Typography>
                </Box>
            </Box>}
        </Dialog>
    )
}

AssignmentViewer.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default AssignmentViewer