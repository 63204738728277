import { Avatar, Box, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../../service/api'
import { showDateInIndianDateFormat } from '../../../utils/utilities'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import noDiscussionsImg from '../../../assets/no-discussions.svg'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'
import AuditDiscussionViewer from '../discussions/AuditDiscussionViewer'


const DiscussionDetails = ( { userData } ) => {

    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [discussions, setDiscussions] = useState( [] )
    const [selectedDiscussion, setSelectedDiscussion] = useState( null )

    const { palette, border } = useTheme()

    const { auid } = useParams()

    const handleDialogClose = () => {
        setSelectedDiscussion( null )
    }

    const loadQuizzes = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data } } = await api.audit.getDiscussionsByFacultyId( auid )
            console.log( data )
            setDiscussions( data )
            setError( false )
        } catch ( err ) {
            setError( err?.response?.data?.message || "Something went wrong while fetching discussions details." )
        } finally {
            setLoading( false )
        }
    }, [auid] )

    useEffect( () => {
        loadQuizzes()
    }, [loadQuizzes] )

    return (
        <Box borderRadius="10px" margin="10px 20px" bgcolor={palette.contentBg} maxHeight="350px" display="flex" flexDirection="column" overflow="hidden">

            {selectedDiscussion && <AuditDiscussionViewer discussion={selectedDiscussion} handleClose={handleDialogClose} openState={Boolean( selectedDiscussion )} />}

            {!loading && !error && discussions.length > 0 && <Box paddingBottom="20px" display="flex" flexDirection="column" gap="10px" borderRadius="10px" bgcolor={palette.contentBg} margin="0 20px">
                {discussions.map( ( row, index ) => {
                    return <Box sx={{ cursor: "pointer" }} onClick={() => setSelectedDiscussion( row )} borderBottom={border[1]} padding="20px 10px" bgcolor={palette.contentBg} display="flex" gap="20px" key={row._id}>
                        <Box>
                            <Tooltip title={row.creator_data.name}>
                                <Avatar src={row.creator_data?.user_profile_image_url} >{row.creator_data.name[0]}</Avatar>
                            </Tooltip>
                        </Box>
                        <Box>
                            <Typography variant='h6' fontSize="16px">{row.title}</Typography>
                            <Typography variant='subtitle2' fontSize="12px">@{row.creator_data.name}, On: {showDateInIndianDateFormat( row.created_at, { month: "short" } )}</Typography>
                        </Box>
                    </Box>
                } )}</Box>}
            {loading && !error && <Box padding="50px 20px" display="flex" justifyContent="center">
                <DataLoadingSpinner waitingMessage="Please wait! Loading discussions..." />
            </Box>}
            {error && <TryAgainMessageBlock getData={loadQuizzes} loading={loading} err={error} />}
            {!loading && !error && discussions.length === 0 && <Box padding="20px" gap="20px" display="flex" alignItems="center" flexDirection="column" justifyContent="center">
                <img src={noDiscussionsImg} height="100px" alt="Something went wrong!" />
                <Typography variant="subtitle2"><em>{userData.employee_name}</em> hasn't crated any discussions!</Typography>
            </Box>}
        </Box>
    )
}

export default DiscussionDetails
