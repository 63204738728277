import { Box, Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import noQuizzesImg from '../../../assets/no-quiz.svg'
import TableHeader from '../../common/TableHeader'
import React, { useCallback, useEffect, useState } from 'react'
import LineGraph from '../../common/LineBarGraph'
import api from '../../../service/api'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import { Link } from 'react-router-dom'

const columns = [
    { name: "Faculty", padding: "10px 20px 10px 40px" },
    { name: "Institute & DEpt", padding: "10px 20px" },
    { name: "Instances", padding: "10px 20px", align: "center" },
    { name: "Attempts", align: "center", padding: "10px 40px 10px 20px" },
]

const QuizzesLast30Details = () => {

    const [tableData, setTableData] = useState( [] )
    const [graphData, setGraphData] = useState( [] )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const { table } = useTheme()

    const getData = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data: stats } } = await api.audit.getQuizzesStats( { month: "last30days" } )
            const { data: { data: graphData } } = await api.audit.getQuizGraphData()
            setGraphData( graphData )
            setTableData( stats )
            setError( null )
        } catch ( err ) {
            setError( err?.response?.data?.message || err?.response?.data || "Something went wrong while getting the quizzes stats." )
        } finally {
            setLoading( false )
        }
    }, [] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Card sx={{ flexShrink: 0, maxHeight: "450px", minHeight: "400px", display: "flex", flexDirection: "column" }}>
            {!loading && tableData.length > 0 && <Box padding="10px" flexGrow={1} overflow="auto" alignItems="stretch" display="grid" gap='20px' gridTemplateColumns={{ md: "1fr 1fr", xs: "1fr" }}>
                <Box display="flex" overflow="auto" flexGrow={1} flexDirection="column">
                    <Typography padding="20px" fontSize="16px" fontWeight="bold" color="primaryDark.main">Last 30 days quizzes stats</Typography>
                    <TableContainer sx={{ overflow: "auto" }} className='slim-custom-scrollbar' >
                        <Table stickyHeader>
                            <TableHeader fontSize="14px" columns={columns} />
                            <TableBody>
                                {tableData.map( ( row, index ) => (
                                    <TableRow className='table-row' key={row.created_by}>
                                        <TableCell sx={{ fontSize: "12px", color: table.color, padding: columns[0].padding || "10px 20px" }}>
                                            <Typography sx={{ fontSize: "12px", cursor: "pointer", '&:hover': { textDecoration: "underline" } }} ><Link className='default-link' to={`/audit/faculty/${row.created_by}`} ><strong>{row.faculty_name}</strong></Link></Typography>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px", color: table.color, padding: columns[1].padding || "10px 20px", textTransform: "capitalize" }} align={columns[1].align || "left"}><strong>{row.creator_data?.institute} - {row.creator_data?.department?.toLowerCase()}</strong></TableCell>
                                        <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", padding: "10px 20px", color: table.color }}><strong>{row['count']}</strong></TableCell>
                                        <TableCell align={columns[3].align || "left"} sx={{ fontSize: "12px", color: table.color, padding: columns[2].padding || "10px 20px" }}><strong>{row?.attempts}</strong></TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <LineGraph lineColor='#FC766A' barColor='#00203F' graphTitle="Last 30 days activities" showMenuBar={false} barLegend='Quiz attempts' yAxisName="Quiz instances" xAxisName="Date" y2AxisName="Attempts" lineLegend='Quiz instances created' barValuesKey="attempts" barData={graphData} dateformat={"%Y-%m-%d"} xAxisValuesKey={'date'} lineValuesKey={'quizzes'} lineData={graphData} />
            </Box >}
            {!loading &&
                tableData.length === 0 && !error && <Box flexGrow={1} flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" gap="20px">
                    <img src={noQuizzesImg} height="120px" alt="No quizzes in last 30 days" />
                    <Typography textAlign="center" variant='subtitle2'>No quizzes were created in last 30 days</Typography>
                </Box>
            }

            {loading && !error && <Box display="flex" flexGrow={1} justifyContent="center">
                <DataLoadingSpinner waitingMessage="Loading last 30 days quizzes activities..." />
            </Box>}
            {error && <Box display="flex" flexGrow={1} justifyContent="center">
                <TryAgainMessageBlock err={error} getData={getData} loading={loading} />
            </Box>}
        </Card >
    )
}

export default QuizzesLast30Details
