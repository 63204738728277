import { Box, Button, Typography, Tab, Tabs, TableContainer, TableCell, Paper, TableBody, Table, TableRow, useTheme } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import TableHeader from '../../components/common/TableHeader'
import CustomSearchBar from "../../components/common/CustomSearchBar"
import { observer } from "mobx-react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAdmissionExamStore } from "../../store/admission-exam.store"
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import TryAgainMessageBlock from "../../components/common/TryAgainMessageBlock"
import noExamImg from '../../assets/noexams.svg'
import { Icons } from '../../utils/utilities'
import ExamInstructionsDialog from "../../components/admission-exam/ExamInstructionsDialog"
import { useUserStore } from "../../store/user.store"
import warningImg from '../../assets/warning.svg'


const tableColumns = {
  Draft: [
    { id: 0, name: "Sl.no", padding: "10px 20px 10px 40px" },
    { id: 1, name: "Exam name" },
    { id: 2, name: "For Course(s)" },
    { id: 3, name: "Status", align: "right", padding: "10px 40px 10px 20px" },
  ],
  Published: [
    { id: 0, name: "Sl.no", padding: "10px 20px 10px 40px" },
    { id: 1, name: "Exam name" },
    { id: 2, name: "For Course(s)" },
    { id: 3, name: "Status" },
    { id: 4, name: "Participants", align: "right", padding: "10px 40px 10px 20px" },
  ]
}

const AdmissionExams = observer( ( props ) => {
  const [loading, setLoading] = useState( true )
  const [error, setError] = useState( null )
  const [value, setValue] = useState( "Published" )
  const [hasPermission, setHasPermission] = useState( true )
  const { palette, border, table } = useTheme()

  const [searchParams, setSearchParams] = useSearchParams( { searchText: "" } )
  const searchText = searchParams.get( 'searchText' )
  const [instructionsDialogState, setInstructionsDialogState] = useState( false )

  const navigate = useNavigate()

  const AdmissionExamStore = useAdmissionExamStore()
  const UserStore = useUserStore()

  const handleTabChange = ( event, newValue ) => {
    setValue( newValue )
  }

  const handleSearch = e => {
    setSearchParams( prev => {
      prev.set( 'searchText', e.target.value )
      return prev
    }, { replace: true } )
  }

  const getExams = useCallback( async () => {
    setLoading( true )
    const { success, error } = await AdmissionExamStore.fetchExams( { exam_type: "global" } )
    if ( success ) {
      setError( null )
    } else {
      console.log( error )
      setError( error )
    }
    setLoading( false )
  }, [AdmissionExamStore] )


  useEffect( () => {
    if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.admission_exams ) {
      setHasPermission( false )
    }
  }, [UserStore] )

  useEffect( () => {
    getExams()
  }, [getExams] )

  return (
    <Box flexGrow={1} overflow="auto" padding="20px" display="flex" flexDirection="column">

      <ExamInstructionsDialog state={instructionsDialogState} setState={setInstructionsDialogState} instructionsDetails={{}} />

      {hasPermission ? <Paper>
        <Box
          padding="20px"
          sx={{
            background:
              "linear-gradient(357.02deg, #16009A -99.56%, #007BD5 117.69%)",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: " 8px 8px 0 0"
          }}
        >
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography sx={{ color: 'white', fontWeight: '700', fontSize: '20px' }}>Admission Exam</Typography>
            <Typography sx={{ color: 'white', fontWeight: '400', fontSize: '14px' }}>Manage all admission exams here..</Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <CustomSearchBar onChange={handleSearch} value={searchText} />
              <Box display="flex" alignItems="center" gap="10px">
                <Button onClick={() => navigate( `/admin/admission-exam/new` )} sx={{ paddingX: "20px", backgroundColor: "#BEFFC7", color: "black", "&:hover": { backgroundColor: "#7ffa90" } }} >
                  CREATE EXAM
                </Button>
                <Button variant="contained" disableElevation onClick={() => setInstructionsDialogState( true )} color="warning">
                  Set exam instructions
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box marginLeft="10px">
          {!loading && <Tabs value={value} onChange={handleTabChange} indicatorColor="secondary" textColor="secondary" >
            <Tab color="secondary" label="Published" value="Published" margin="0 10px" />
            <Tab color="secondary" label={<Typography variant="subtitle2" display="flex" color={( AdmissionExamStore.getExams || [] ).filter( exam => exam.exam_status === "Draft" ).length > 0 && "errorMessage.main"} alignItems="center" gap="10px">Pending ({( AdmissionExamStore.getExams || [] ).filter( exam => exam.exam_status === "Draft" ).length})</Typography>} value="Draft" margin="0 10px" />
          </Tabs>}
        </Box>
        {!loading && !error && AdmissionExamStore.getExams.filter( exam => exam.exam_status === value && exam.exam_name?.match( new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' ) ) ).length > 0 && <Box width="100%" overflow="auto">
          <Box padding="20px">
            {/* <Typography marginBottom="30px">List of Pending Examinations</Typography> */}
            <Box bgcolor={palette.contentBg} flexGrow={1} border={border[1]} borderRadius="5px" flexDirection="column">
              <TableContainer className="custom-scrollbar" sx={{ overflow: "auto", maxHeight: "450px" }} >
                <Table stickyHeader>
                  <TableHeader columns={tableColumns[value]} />
                  <TableBody >
                    {AdmissionExamStore.getExams.filter( exam => exam.exam_status === value && exam.exam_name?.match( new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' ) ) ).map( ( exam, index ) => (
                      <TableRow onClick={() => navigate( `/admin/admission-exam/exam/${exam.exam_id}` )} className="table-row" sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }} key={index}>
                        <TableCell sx={{ padding: tableColumns[value][0].padding || "10px 20px", fontSize: "12px" }}><strong>{index + 1}</strong></TableCell>
                        <TableCell sx={{ color: table.color, padding: "10px 20px" }}><Typography sx={{ "&:hover": { textDecoration: "underline !important" } }} title={exam.exam_name} maxWidth="250px" noWrap fontSize="14px" fontWeight='bold'>{exam.exam_name}</Typography></TableCell>
                        <TableCell style={{ color: table.color, padding: "10px 20px", fontSize: "12px", textTransform: "uppercase" }}><strong>{exam.global_exam_access_courses?.join( ", " )}</strong></TableCell>
                        <TableCell align={tableColumns[value][3]?.align || "left"} sx={{ padding: tableColumns[value][3]?.padding || "10px 20px", minWidth: "50px", width: "50px", background: "transparent", color: palette.customThemeColor.main, fontSize: "12px" }}>
                          <Box display="flex" alignItems="center" gap="10px">
                            <strong>{exam.exam_status}</strong>
                            {value === 'Published' && index === 0 && <Typography borderRadius="5px" fontSize="10px" padding="2px 5px" bgcolor={palette.success.main} color="white">Active</Typography>}
                          </Box>
                        </TableCell>
                        {value === "Published" && <TableCell align="right" sx={{ padding: "10px 40px 10px 20px", minWidth: "50px", width: "50px", background: "transparent", color: table.color, fontSize: "12px" }}><strong>{exam.participants}</strong></TableCell>}
                      </TableRow>
                    ) )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>}

        {!loading && !error && AdmissionExamStore.getExams.filter( exam => exam.exam_status === value && exam.exam_name?.match( new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' ) ) ).length === 0 && <Box flexGrow={1} borderRadius="5px" flexDirection="column" display="flex" justifyContent="center" alignItems="center" padding="20px" bgcolor={palette.contentBg} margin="10px" gap="20px" color="textSecondary" variant='subtitle2'>
          <img src={noExamImg} width="200px" alt="no exams!" />
          <Typography variant='subtitle2'>{value === "Draft" ? "No exam needs approval!" : `No published exams`} {searchText?.trim() && value !== 'Draft' ? <span>for <em>{searchText}</em> </span> : ""}</Typography>
          {AdmissionExamStore.getExams.length === 0 && <Button sx={{ textTransform: "capitalize" }} variant='outlined' startIcon={Icons.default.AddIcon} onClick={() => navigate( `/admin/admission-exam/new` )} >Create One</Button>}
        </Box>}
        {loading && !error && <DataLoadingSpinner waitingMessage="Loading exams please wait" />}
        {error && <TryAgainMessageBlock code={error.code} err={error.message} getData={getExams} loading={loading} />}
      </Paper> : <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
        <img src={warningImg} width="40px" alt="No permission" />
        <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
      </Paper>}


    </Box>
  )
} )

export default AdmissionExams
