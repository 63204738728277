import { Box, Button, CircularProgress, Dialog, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Icons } from '../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../service/api'

const ImportedUsersPreviewDialog = ( { users, setUsers, fetchUsers, batch } ) => {

    const [adding, setAdding] = useState( false )

    const { border, palette } = useTheme()

    const removeIdentifiedUser = ( index ) => {
        setUsers( prev => {
            const newList = [...prev]
            newList.splice( index, 1 )
            return newList
        } )
    }

    const importExtractedUsers = async () => {
        setAdding( true )
        try {
            const res = await api.temporaryUsers.addUser( batch.id, { batchId: batch.id, users: users } )
            await fetchUsers()
            setUsers( [] )
            toast( res?.data?.message || "Successfully imported the users." )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong while adding users to the batch" )
        } finally {
            setAdding( false )
        }
    }

    const handleClose = () => setUsers( [] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "350px", display: "flex", flexDirection: "column" } }} open={Boolean( users ) && users.length > 0}>
            <Box borderBottom={border[1]} padding="10px 20px">
                <Typography variant='h6'>Extracted users list</Typography>
                <Typography variant='body2'>{users.length} user{users.length > 1 ? "s" : ""} detected.</Typography>
            </Box>
            <Box bgcolor={palette.contentBg} flexGrow={1} className="custom-scrollbar" overflow="auto" display="flex" flexDirection="column" padding="10px 20px">
                {users.map( ( user, index ) => (
                    <Box borderBottom={border[1]} display="flex" padding="5px 0" alignItems="center" justifyContent="space-between" gap="20px" key={user[0]}>
                        <Box>
                            <Typography variant='h6' fontSize="16px">{user[1]}</Typography>
                            <Typography variant='subtitle2' fontSize="12px" fontWeight="normal" >{user[0]}</Typography>
                        </Box>
                        <Tooltip title={`Remove ${user[1]}`}>
                            <IconButton onClick={() => removeIdentifiedUser( index )}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) )}
            </Box>
            <Box display="flex" gap="20px" alignItems="center" padding="10px 20px">
                <Button onClick={importExtractedUsers} disabled={adding} sx={{ textTransform: "capitalize" }} startIcon={adding ? <CircularProgress size={14} /> : Icons.default.DownloadIconOutlined} variant='contained' color="primary" disableElevation >{adding ? "Importing users..." : "Import"}</Button>
                <Button onClick={handleClose} disabled={adding} sx={{ textTransform: "capitalize" }} variant='contained' color="error" disableElevation >Cancel</Button>
            </Box>
        </Dialog>
    )
}

export default ImportedUsersPreviewDialog
