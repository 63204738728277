import React, { useEffect, useState } from 'react'
import { Drawer, IconButton, Box, Typography, useTheme, Collapse } from '@mui/material'
import { Icons } from '../../../utils/utilities'
import { Link } from 'react-router-dom'
import { useLayoutContext } from '../../../contexts/layout.context'
import aliveLogo from '../../../assets/alivelogo.png'

const closedSidebarWidth = 60

const menu = [
    { id: 1, activeMatchRegexes: [/^(\/student\/home)\/?$/], label: "Home", children: null, link: `/student/home`, icon: Icons.default.HomeIconOutlined, activeIcon: Icons.default.HomeIcon },
    { id: 11, activeMatchRegexes: [/^(\/student\/subjects)\/?$/, /^(\/student\/subject\/\d+)\/?$/, /^(\/student\/subject\/\d+\/\w+)\/?$/], label: "Subjects", children: null, link: `/student/subjects`, icon: Icons.default.SubjectsOutlined, activeIcon: Icons.default.Subjects },
    { id: 2, activeMatchRegexes: [/^(\/student\/material)\/?$/, /^(\/student\/material\/\d+)\/?$/], label: "Study Material", children: null, link: `/student/material`, icon: Icons.default.DescriptionIconOutlined, activeIcon: Icons.default.DescriptionIcon },
    { id: 3, activeMatchRegexes: [/^(\/student\/assignment)\/?$/, /^(\/student\/assignment\/\d+)\/?$/], label: "Assignment", children: null, link: `/student/assignment`, icon: Icons.default.AssignmentIconOutlined, activeIcon: Icons.default.AssignmentIcon },
    { id: 4, activeMatchRegexes: [[/^(\/student\/exams)\/?$/, /^(\/student\/exams\/\d+)\/?$/], [/^(\/student\/scores)\/?$/, /^(\/student\/scores\/\d+\/\d+)\/?$/]], label: "Exam", children: ["Exams", "Scores"], childrenIcons: [{ default: Icons.default.QuestionMarkOutlined, activeIcon: Icons.default.QuestionMark }, { default: Icons.default.BadgeOutlined, activeIcon: Icons.default.Badge }], link: ["/student/exams", "/student/scores"], activeIcon: Icons.default.QuizIcon, icon: Icons.default.QuizIconOutlined },
    { id: 5, activeMatchRegexes: [[/^(\/student\/discussions)\/?$/, /^(\/student\/discussions\/\w+)\/?$/], [/^(\/student\/my-discussions)\/?$/, /^(\/student\/my-discussions\/\w+)\/?$/]], permission: true, childrenPermissions: [true, true], label: <span>Discussions <small style={{ fontSize: "8px", background: "#eee3", padding: "3px", borderRadius: "5px" }}><b><i>BETA</i></b> </small> </span>, children: ["Explore", "My discussions"], link: ["/student/discussions", "/student/my-discussions"], childrenIcons: [{ default: Icons.default.QuestionMarkOutlined, activeIcon: Icons.default.QuestionMark }, { default: Icons.default.BadgeOutlined, activeIcon: Icons.default.Badge }], activeIcon: Icons.default.Groups2, icon: Icons.default.Groups2Outlined },
    { id: 6, activeMatchRegexes: [/^(\/student\/resume-builder)\/?$/, /^(\/student\/resume-builder\/preview)\/?$/], label: <span>Resume Builder <small style={{ fontSize: "8px", background: "#eee3", padding: "3px", borderRadius: "5px" }}><b><i>BETA</i></b> </small> </span>, children: null, link: "/student/resume-builder", activeIcon: Icons.default.Protrait, icon: Icons.default.ProtraitOutlined },
    // { id: 12, activeMatchRegexes: [/^(\/student\/ai-tutor)\/?$/], label: <span>AI Tutor <small style={{ fontSize: "8px", background: "#eee3", padding: "3px", borderRadius: "5px" }}><b><i>BETA</i></b> </small> </span>, children: null, link: "/student/ai-tutor", activeIcon: Icons.default.Diversity, icon: Icons.default.Diversity },
    // { id: 7, activeMatchRegexes: [[/^(\/student\/online-course)\/?$/, /^(\/student\/online-course\/\d+)\/?$/], [/^(\/student\/my-online-course)\/?$/]], label: "Online Course", children: ["Online Courses", "Enrolled courses"], link: ["/student/online-course", "/student/my-online-course"], childrenIcons: [{ default: Icons.default.CourseOutlined, activeIcon: Icons.default.Course }, { default: Icons.default.SubscriptionsOutlined, activeIcon: Icons.default.Subscriptions }], activeIcon: Icons.default.PlayCircleFilledWhiteIcon, icon: Icons.default.PlayCircleFilledWhiteIconOutlined },
    { id: 8, activeMatchRegexes: [/^(\/student\/academics)\/?$/], label: "Academics", children: null, link: "/student/academics", activeIcon: Icons.default.School, icon: Icons.default.SchoolOutlined },
    { id: 10, activeMatchRegexes: [/^(\/student\/faq)\/?$/], label: "FAQs", children: null, link: `/student/faq`, activeIcon: Icons.default.LiveHelpIcon, icon: Icons.default.LiveHelpIconOutlined }
]

const SidedrawerMenuItem = ( { item, isActive, sidebarOpenState, closeOnMobileScreen, active } ) => {

    const [collapseState, setCollapseState] = useState( false )
    const { palette } = useTheme()

    return <Box borderRadius="5px" marginX="20px" bgcolor={collapseState && palette.nav.sidebarHover}>
        <Link className='default-link' to={!item.children && item.link}>
            <Box
                onClick={item.children ? () => setCollapseState( !collapseState ) : closeOnMobileScreen}
                bgcolor={isActive && palette.nav.activeSidebarItem}
                color={isActive ? palette.nav.selected : palette.common.white}
                borderRadius="5px"
                sx={{
                    cursor: "pointer",
                    position: "relative",
                    background: collapseState ? isActive ? palette.nav.activeSidebarItem : palette.nav.sidebarHover : isActive ? palette.nav.activeSidebarItem : "none",
                    "&:hover": {
                        background: !isActive ? palette.nav.sidebarHover : palette.nav.activeSidebarItem,
                    }
                }}
                display="flex"
                alignItems="center"
                padding="5px 10px"
                gap="15px"
                marginY="5px"
                minWidth={closedSidebarWidth - 20}
            >
                <IconButton
                    color="inherit"
                    size='small'
                    sx={{
                        padding: "8px",
                        borderRadius: "5px",
                        transform: "scale(0.9)",
                        position: "relative"
                    }}>
                    {isActive ? item.icon : item.icon}
                </IconButton>
                <Typography flexGrow={1} display="flex" alignItems="center" justifyContent='space-between' flexShrink={0} color="inherit" variant="subtitle2" fontWeight={isActive ? "700" : "300"} fontSize="14px" textAlign="center">
                    {item.label}
                    {item.children && Icons.small.KeyboardArrowDown}
                </Typography>
            </Box>
        </Link>
        {item.children && <Collapse in={collapseState}>
            <Box flexDirection="column" paddingBottom="10px" display="flex" gap="5px" paddingRight="20px" paddingLeft="30px">
                {item.children.map( ( sublink, index ) => {
                    const activeChild = item.activeMatchRegexes[index].some( re => active.match( re ) )
                    return <Link className='default-link' key={sublink} to={item.link[index]}>
                        <Box
                            display="flex"
                            onClick={closeOnMobileScreen}
                            borderRadius="5px"
                            padding="2px 5px"
                            gap="20px"
                            alignItems="center"
                            sx={{
                                color: activeChild ? "white" : "lightgrey",
                                cursor: "pointer",
                                position: "relative",

                                "&::before": {
                                    display: activeChild ? "inline-block" : "none",
                                    content: "''",
                                    position: "absolute",
                                    background: "#fff",
                                    height: "60%",
                                    borderRadius: "0 2px 2px 0",
                                    left: 0,
                                    width: "2px",
                                    zIndex: 324434,
                                    top: "50%",
                                    transform: "translate(0,-50%)"
                                },

                                "&:hover": {
                                    textDecoration: "underline",
                                }
                            }}
                        >
                            <Typography variant="subtitle2" flexShrink={0} color="inherit" fontWeight={activeChild ? "400" : "300"} fontSize="14px" textAlign="center">{sublink}</Typography>
                        </Box>
                    </Link>
                } )}
            </Box>
        </Collapse>}
    </Box>
}

const SideDrawer = function ( { closeSideMenu } ) {
    const { sidebarOpenState, setNavHeadText, active, menuState, setMenuState, sidebarWidth, minSidebarCollapseWidth } = useLayoutContext()

    const { palette } = useTheme()

    const closeOnMobileScreen = () => {
        if ( window.innerWidth < minSidebarCollapseWidth )
            closeSideMenu()
    }


    useEffect( () => {
        for ( let i = 0; i < menu.length; i++ ) {
            const item = menu[i]
            const isActive = item.activeMatchRegexes.flat().some( re => active.match( re ) )
            if ( isActive ) {
                setNavHeadText( item.label )
                break
            }
        }
    }, [active, setNavHeadText] )


    return (
        <Box>
            <Drawer
                onClose={() => { setMenuState( false ) }}
                variant='persistent'
                sx={{ position: { md: "static", xs: "fixed" }, zIndex: { md: "initial", xs: "120000" }, overflow: "hidden", width: menuState ? sidebarWidth : "0", height: "100%", flexSrink: 0, display: "flex", flexDirection: "column" }}
                PaperProps={{ className: "custom-scrollbar", sx: { width: sidebarWidth, borderRadius: "0", background: palette.nav.sidebar, color: "white", borderRight: "none" } }}
                anchor="left"
                open={menuState}
                className="custom-scrollbar"
            >
                <Box position="relative" flexGrow={1} overflow="auto" borderRight="1px solid #d3d3d3" display="flex">
                    <Box className="sidebar-scrollbar" minWidth={`${closedSidebarWidth - 21}px`} width="100%" overflow="auto" padding="20px 10px" color="GrayText" alignItems="stretch" gap="10px">

                        <Box padding="10px 20px 40px 20px" display="flex" gap="10px" alignItems="center">
                            <Box padding="5px" borderRadius="5px" bgcolor="white"><img width="30px" src={aliveLogo} alt="Logo" /></Box>
                            <Box>
                                <Typography color="white" variant='h6'>Alive</Typography>
                                <Typography color="white" marginTop="-7px" letterSpacing="1px" fontWeight="300" variant='body2'>Digital Classrooms</Typography>
                            </Box>
                            <IconButton sx={{ color: "white", display: { md: "none", xs: "block" } }} onClick={() => setMenuState( false )}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>

                        {menu.map( item => {
                            const isActive = item.children ? item.activeMatchRegexes.flat().some( re => active.match( re ) ) : item.activeMatchRegexes.some( re => active.match( re ) )
                            return (
                                <SidedrawerMenuItem active={active} closeOnMobileScreen={closeOnMobileScreen} item={item} isActive={isActive} sidebarOpenState={sidebarOpenState} key={item.id} />
                            )
                        } )}
                    </Box>

                </Box >
            </Drawer >
        </Box >
    )
}

export default SideDrawer

