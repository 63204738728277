import { Avatar, Box, Typography, IconButton, MenuItem, AppBar, Button, Popover, useTheme, Select } from "@mui/material"
import { useState } from "react"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuthStore } from "../../../store/auth.store"
import { useLayoutContext } from "../../../contexts/layout.context"
import CustomSwitch from "../../common/CustomSwitch"
import { useUserStore } from "../../../store/user.store"
import { useThemeContext } from "../../../contexts/theme.context"
import { Icons } from "../../../utils/utilities"

const themeOptions = [
    'light', 'dark', 'system'
]

const Navbar = ( { openSideMenu, closeSideMenu } ) => {

    const [anchorEl, setAnchorEl] = useState( null )
    const [settingsAnchorEl, setSettingsAnchorEl] = useState( null )

    const UserStore = useUserStore()
    const AuthStore = useAuthStore()

    const open = Boolean( anchorEl )

    const navigate = useNavigate()
    const { menuState, active, navHeight, navHeadText } = useLayoutContext()

    const { palette, shadows } = useTheme()
    const { setSelectedTheme, selectedTheme } = useThemeContext()

    const handleThemeChange = e => {
        setSelectedTheme( e.target.value )
        localStorage.setItem( 'preferedTheme', e.target.value )
        setSettingsAnchorEl( null )
    }

    const toggleAuditMode = () => {
        localStorage.setItem( 'audit_redirect', `${active}${window.location.search}` )
        navigate( '/audit/' )
    }

    const logout = async () => {
        await AuthStore.doLogout()
        localStorage.clear()
        toast.success( "Logged out successfully!" )
        navigate( '/admin' )
    }

    return (
        <AppBar
            id="appbar"
            elevation={0}
            sx={{
                borderBottom: palette.grey[600] + " 1px solid",
                borderRadius: "0",
                background: palette.layoutBackground,
                transition: "0.2s ease-out",
                position: "absolute",
                color: "black",
                height: "fit-content",
                zIndex: "1220"
            }}
        >
            {/* <Typography fontSize="12px" sx={{ position: "absolute", top: 0, left: 0, right: 0, background: palette.warning.main, color: palette.warning.contrastText }}>Under maintenance</Typography> */}
            <Box alignItems="center" justifyContent="space-between" height={`${navHeight}px`} paddingRight={"20px"} display="flex" maxWidth="100%" id="app-bar" sx={{ transition: "0.2s ease-out", paddingLeft: "20px" }}>
                <Box display="flex" gap="10px" alignItems="center">
                    <IconButton sx={{ marginLeft: "-12px", marginTop: "5px", display: { md: "none", xs: "block" } }} size="small" onClick={menuState ? closeSideMenu : openSideMenu} >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">{navHeadText}</Typography>
                </Box>
                {<Box display="flex" alignItems="center" gap="10px">
                    <Box display={{ sm: "block", xs: "none" }}>
                        <Typography textAlign="right" variant='subtitle1' textTransform="capitalize">{UserStore.getUser["employee_name"] || ""}</Typography>
                    </Box>
                    {( UserStore.getUser.user_role?.match( /^admin$/ig ) || ( UserStore.getUser.user_role?.match( /^subadmin$/ig ) && UserStore.getUser?.user_permissions?.auditing === true ) ) && <Box onClick={toggleAuditMode} display="flex" gap="10px" alignItems="center" sx={{ cursor: "pointer" }} paddingLeft="10px" boxShadow={shadows[1]} bgcolor={palette.common.bg} borderRadius="10px">
                        <Typography variant="subtitle2" color="primary">AUDIT MODE</Typography>
                        <CustomSwitch checked={active.startsWith( '/audit' )} />
                    </Box>}
                    <IconButton onClick={( e ) => setSettingsAnchorEl( e.currentTarget )}>
                        {Icons.default.SettingsOutlined}
                    </IconButton>
                    <IconButton onClick={( e ) => setAnchorEl( e.currentTarget )}>
                        <Avatar src={UserStore.getUser.user_profile} sx={{ height: "32px", width: "32px" }}></Avatar>
                    </IconButton>

                    <Popover transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'left', vertical: 'top' }} elevation={1} open={Boolean( settingsAnchorEl )} onClose={() => setSettingsAnchorEl( null )} slotProps={{ paper: { sx: { maxWidth: "300px" } } }} anchorEl={settingsAnchorEl}>
                        <Box padding="10px" display="flex" gap="20px" alignItems="center">
                            <Typography width="max-content" variant="variant2">Theme</Typography>
                            <Select sx={{ height: "25px", textTransform: "capitalize" }} size="small" value={selectedTheme} onChange={handleThemeChange} >
                                {themeOptions.map( theme => (
                                    <MenuItem key={theme} value={theme} dense sx={{ fontSize: "14px", textTransform: "capitalize" }}>{theme}</MenuItem>
                                ) )}
                            </Select>
                        </Box>
                    </Popover>

                    <Popover slotProps={{ paper: { sx: { border: `1px solid ${palette.borderColor}` } } }} sx={{ maxWidth: "300px" }} onClose={e => { setAnchorEl( null ) }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'left', vertical: 'top' }} anchorEl={anchorEl} open={open}>


                        <Box display="flex" alignItems="center" flexDirection="column" gap="20px" padding="20px">

                            <Avatar sx={{ textTransform: "uppercase", cursor: "pointer", border: `4px solid ${palette.borderColor}` }} alt={UserStore.getUser["user_name"] ? UserStore.getUser["user_name"] : ""} >
                                {( UserStore.getUser["user_role"] ? UserStore.getUser["user_role"] : "" ).substring( 0, 1 )}
                            </Avatar>
                            <Box>
                                <Typography marginBottom="-5px" textAlign="center" variant='subtitle1' textTransform="capitalize">{UserStore.getUser["user_role"]?.toUpperCase()}</Typography>
                            </Box>
                            <Button color="error" onClick={logout} startIcon={<LogoutIcon sx={{ color: "error.main" }} fontSize="small" />}>
                                Logout
                            </Button>
                        </Box>
                    </Popover>
                </Box>}
            </Box>
        </AppBar>
    )
}

export default Navbar
