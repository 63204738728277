import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography, MenuItem, Paper, useTheme, Button, Menu } from '@mui/material'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import noParticipationsImg from '../../../assets/no-participation.svg'
import { Link, useSearchParams } from 'react-router-dom'
import React, { useCallback, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useState } from 'react'
import TableHeader from '../../../components/common/TableHeader'
import BreadcrumbList from '../../../components/common/BreadcrumbList'
import { Icons } from '../../../utils/utilities'

const columns = [
    { name: "Exam Name", borderRadius: "5px 0 0 0", minWidth: "220px", align: "left", padding: "10px 20px 10px 40px" },
    { name: "Attempt", width: "fit-content", minWidth: "20px", align: "center" },
    { name: "Exam date", maxWidth: "50px", minWidth: '150px', align: "right" },
    { name: "Score", minWidth: '130px', align: "left" },
    { name: "Remark", minWidth: '130px', align: "right", padding: "10px 40px 10px 20px" }
]
export default function Scores() {
    const [data, setData] = useState( [] )
    const [participations, setParticipations] = useState( null )
    const [exams, setExams] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const [examsListOpen, setExamsListOpen] = useState( false )
    const [examList, setExamList] = useState( [] )

    const menuRef = useRef()

    const [searchParams, setSearchParams] = useSearchParams( { selectedExam: "-1" } )
    const selectedExam = searchParams.get( 'selectedExam' )

    const { palette, border, table } = useTheme()

    const filterExams = useCallback( ( val, list = data ) => {
        if ( val !== "-1" ) {
            const filteredParticipations = list.filter( p => String( p.exam_id ) === String( val ) )
            setParticipations( filteredParticipations )
        } else {
            setParticipations( list )
        }
        setSearchParams( prev => {
            prev.set( 'selectedExam', val )
            return prev
        }, { replace: true } )
        setExamsListOpen( false )
    }, [data, setSearchParams] )

    useEffect( () => {

        const getData = async () => {
            try {
                const { data } = await api.fetchUserParticipations()
                setData( data.participation )
                setParticipations( data.participation )
                let examsMap = {}
                let uniqueExams = {}
                data.marks.forEach( exam => {
                    examsMap[exam.exam_id] = exam
                } )
                data.participation.forEach( p => {
                    uniqueExams[p.exam_id] = p.exam_title
                } )
                setExamList( uniqueExams )
                setExams( examsMap )
                if ( selectedExam && uniqueExams[selectedExam] ) {
                    const filteredParticipations = ( data.participation || [] )?.filter( p => +p.exam_id === +selectedExam )
                    setParticipations( filteredParticipations )
                }
            }
            catch ( e ) {
                toast( e?.response?.data?.message || "Something went wrong!" )
            }
            finally {
                setInProgress( false )
            }
        }
        getData()
    }, [selectedExam] )

    return (
        <Box padding="20px" flexGrow={1} overflow={inProgress || ( !inProgress && data?.length === 0 ) ? "initial" : "auto"} display="flex" flexDirection="column">

            <BreadcrumbList items={[
                { link: "/student/exams", label: "Exams" },
                "Scores"
            ]} />

            <Paper sx={{ minHeight: "500px", display: "flex", flexDirection: "column", flexGrow: inProgress || ( !inProgress && data?.length === 0 ) ? "initial" : 1, overflow: inProgress || ( !inProgress && data?.length === 0 ) ? "initial" : "auto" }}>
                <Box
                    sx={{
                        position: 'relative',
                        background: `url('/imgs/subjects-header-bg.jpg')`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }} padding="20px" display="flex" flexDirection="column" gap="20px" alignItems="flex-start">
                    <Box height="100%" position="absolute" sx={{ zIndex: 0, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 180.03%)`, }}></Box>
                    <Box flexGrow="1" sx={{ zIndex: 10, position: "relative" }} >
                        <Typography color="white" variant="h5">My scores</Typography>
                        <Typography color="whitesmoke" variant="body2" >Check your scores</Typography>
                    </Box>
                    {examList && <Button onClick={() => setExamsListOpen( true )} ref={menuRef} endIcon={Icons.default.DropDown} variant='contained' disableElevation color="customThemeColor" >
                        <Typography noWrap maxWidth="400px">{selectedExam !== "-1" ? examList[selectedExam] : "ALL EXAMS"}</Typography>
                    </Button>}
                    <Menu anchorEl={menuRef.current} onClose={() => setExamsListOpen( false )} open={examsListOpen}>
                        <MenuItem onClick={() => filterExams( "-1" )} sx={{ fontSize: "14px" }} key={"-1"} value="-1">All exams</MenuItem>
                        {examList && Object.keys( examList ).map( exam => (
                            <MenuItem onClick={() => filterExams( exam )} sx={{ fontSize: "14px" }} key={exam} value={exam}>{examList[exam]}</MenuItem>
                        ) )}
                    </Menu>
                </Box>
                {!inProgress && data && data?.length > 0 && <Box margin="20px" borderRadius="5px" flexGrow={1} bgcolor={palette.contentBg} border={border[1]} overflow="auto">
                    {exams && !inProgress && <TableContainer sx={{ height: "100%", overflow: "auto" }} className='custom-scrollbar' >
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {participations.map( ( participation, index ) => (
                                    <TableRow className='table-row' key={index} sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }}>
                                        <TableCell sx={{ color: table.color, padding: "0", fontSize: "14px" }}>
                                            <Link to={`/student/scores/${participation.exam_id}/${participation.participation_id}`} className='default-link' >
                                                <Typography sx={{ padding: columns[0].padding || "10px 20px", }} display="flex" gap="5px" color="inherit" fontSize="inherit" alignItems="center"> {Icons.small.Subject} <strong>{participation.exam_title}</strong></Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ color: table.color, padding: "0", fontSize: "14px", textAlign: "center" }} align="left">
                                            <Link to={`/student/scores/${participation.exam_id}/${participation.participation_id}`} className='default-link' >
                                                <Box padding="10px 20px"><strong>{participation.exam_participation_attempt}</strong></Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ color: table.color, padding: "0", fontSize: "14px", textAlign: "right" }}>
                                            <Link to={`/student/scores/${participation.exam_id}/${participation.participation_id}`} className='default-link' >
                                                <Box padding="10px 20px"><strong>{`${new Date( participation.exam_participation_start_time ).toLocaleDateString( 'en-IN', { day: "2-digit", month: "2-digit", year: "numeric" } ).replace( /\//g, "-" )}, ${new Date( participation.exam_participation_start_time ).toLocaleTimeString( 'en-IN', { hour: "2-digit", minute: "2-digit" } )}`}</strong></Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ color: table.color, padding: "0", minWidth: "130px", textAlign: "left" }}>
                                            <Link to={`/student/scores/${participation.exam_id}/${participation.participation_id}`} className='default-link' >
                                                <Typography padding="10px 20px" fontWeight="bold">{participation.exam_participation_score ? `${participation.exam_participation_score} ` : `${0} `}<span style={{ fontSize: "14px" }}>OUT OF</span>  {exams[participation.exam_id]?.sum || JSON.stringify( exams[participation.exam_id] ) || "ID" + participation.exam_id}</Typography>
                                            </Link>
                                        </TableCell>
                                        <TableCell align={columns[4].align || 'left'} sx={{ color: table.color, minWidth: "130px", padding: "0" }}>
                                            <Link to={`/student/scores/${participation.exam_id}/${participation.participation_id}`} className='default-link' >
                                                <Typography textAlign={columns[4].align} sx={{ padding: columns[4].padding || "10px 20px", }} fontSize="12px">{participation.exam_participation_remark ? ( participation.exam_participation_remark ).length > 150 ? ( participation.exam_participation_remark ).substring( 0, 150 ) + "..." : ( participation.exam_participation_remark ) : "No remarks."}</Typography>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Box>}
                {( !data || data.length === 0 ) && !inProgress && <Box bgcolor="#eee" display="flex" flexDirection="column" gap="10px" alignItems="center" justifyContent="center" padding="20px">
                    <img src={noParticipationsImg} width="200px" alt="No participations" />
                    <Typography variant='subtitle2' >You seems to have not participated in any exams!</Typography>
                </Box>}
                {inProgress && <DataLoadingSpinner waitingMessage="Loading your scores..." />}
            </Paper >
        </Box >
    )
}
