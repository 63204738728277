import DiscussionForumFilterButton from '../../components/discussion-forum/DiscussionForumFilterButton'
import { Box, Button, IconButton, MenuItem, Paper, Select, Typography, useTheme } from '@mui/material'
import CreateThreadDialog from '../../components/discussion-forum/CreateThreadDialog'
import DiscussionCard from '../../components/discussion-forum/DiscussionCard'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { useDiscussionForum } from '../../store/discussionforum.store'
import noDiscussionsImg from '../../assets/no-discussions.svg'
import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Icons, flatColorsAndBackgrounds } from '../../utils/utilities'
import { Close } from '@mui/icons-material'
import { observer } from 'mobx-react'

const Discussions = observer( () => {

    const [searchParams, setSearchParams] = useSearchParams( { sort: 'latest', category: "", searchText: "", page: 0, discussionsPerPage: 2 } )
    const searchText = searchParams.get( 'searchText' )
    const category = searchParams.get( 'category' )
    const page = +searchParams.get( 'page' )
    const sort = searchParams.get( 'sort' )

    const [createDialogStatus, setCreateDialogStatus] = useState( false )
    const [discussionsPerPage, setDiscussionsPerPage] = useState( 20 )
    const [paginationDetails, setPaginationDetails] = useState( { from: 1, to: discussionsPerPage, pageValue: 1, maxPagePossible: 1 } )
    const [loading, setLoading] = useState( true )

    const DiscussionForumStore = useDiscussionForum()
    const { palette } = useTheme()

    const { id } = useParams()

    const navigate = useNavigate()

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const getData = async () => {
        setLoading( true )
        await DiscussionForumStore.fetchDiscussions( { sort, category, search: searchText } )
        calculatePaginationDetails( DiscussionForumStore.getDiscussions )
        setLoading( false )
    }

    const removeCategory = ( cat ) => {
        let items = category.split( ',' )
        const index = items.indexOf( cat )
        items.splice( index, 1 )
        setSearchParamValue( 'category', items.join( ',' ) )
    }

    const clearFilters = () => {
        setSearchParams( prev => {
            prev.set( 'searchText', '' )
            prev.set( 'category', '' )
            prev.set( 'latest', '' )
        }, { replace: true } )
    }

    const moveToPreviousPage = () => {
        if ( page > 1 )
            setSearchParamValue( 'page', page - 1 )
    }

    const moveToNextPage = () => {
        if ( page < paginationDetails.maxPagePossible )
            setSearchParamValue( 'page', page + 1 )
    }


    const calculatePaginationDetails = useCallback( ( data = DiscussionForumStore.getDiscussions, currentPage = page ) => {
        let pageValue = currentPage, from = 1, to = discussionsPerPage
        const maxPagePossible = Math.ceil( data.length / discussionsPerPage )
        if ( currentPage <= 1 ) {
            pageValue = 1
            from = 1
            to = discussionsPerPage
        }
        else if ( currentPage >= maxPagePossible ) {
            pageValue = maxPagePossible
            to = data.length
            from = ( pageValue - 1 ) * discussionsPerPage + 1
        } else {
            from = ( currentPage - 1 ) * discussionsPerPage + 1
            to = currentPage * discussionsPerPage
        }
        if ( to > data.length )
            to = data.length
        setPaginationDetails( { pageValue, from, to, maxPagePossible } )
    }, [page, discussionsPerPage, DiscussionForumStore] )

    useEffect( () => {
        calculatePaginationDetails( DiscussionForumStore.getDiscussions )
    }, [page, discussionsPerPage, DiscussionForumStore, calculatePaginationDetails] )


    useEffect( () => {
        const getData = async () => {
            await DiscussionForumStore.fetchDiscussions( { sort, category, search: searchText } )
            const searchParams = window.location.search
            calculatePaginationDetails( DiscussionForumStore.getDiscussions )
            if ( DiscussionForumStore?.getDiscussions.length > 0 && !id ) {
                navigate( `${DiscussionForumStore?.getDiscussions[0]?._id}${searchParams}` )
            }
            setLoading( false )
        }
        getData()
    }, [calculatePaginationDetails, DiscussionForumStore, sort, category, searchText, navigate, id] )


    return (
        <Box padding="20px" overflow="auto" flexGrow={1}>

            <CreateThreadDialog getData={getData} state={createDialogStatus} setState={setCreateDialogStatus} />

            <Paper sx={{ minHeight: !loading && "350px", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Box
                    display="flex"
                    position="relative"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="20px"
                    sx={{
                        background: `url('/imgs/discussions-header-bg.jpg')`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box height="100%" position="absolute" sx={{ zIndex: 0, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 150.03%)`, }}></Box>
                    <Box position="relative" sx={{ zIndex: 10 }} >
                        <Typography color="white" variant="h5">Discussions</Typography>
                        <Typography color="white" variant="body2" >Browse discussions from wide range of categories here...</Typography>
                    </Box>
                    {<Button onClick={() => setCreateDialogStatus( true )} variant="contained" disableElevation startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize" }}>Create</Button>}
                </Box>

                {!loading && <Box flexGrow={1} display="flex" flexDirection="column">

                    {/* Filter and details bar */}
                    <Box padding="10px 20px" bgcolor={palette.rowHover} borderBottom="1px solid #d3d3d3" display="flex" flexDirection="column" gap="10px">

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle2"> {DiscussionForumStore.getDiscussions.length} discussions found</Typography>
                            <DiscussionForumFilterButton />
                        </Box>
                        {( category || searchText ) && <Box display="flex" gap="10px" color={flatColorsAndBackgrounds[2].color} flexWrap="wrap" alignItems="center">
                            {searchText && <Box bgcolor={flatColorsAndBackgrounds[2].bg} border={`2px solid ${flatColorsAndBackgrounds[2].color}`} textTransform="capitalize" borderRadius="20px" fontSize="14px" alignItems="center" display="flex" gap="10px" padding="0 10px">
                                {Icons.default.SearchIcon}
                                {searchText}
                                <IconButton onClick={() => setSearchParamValue( 'searchText', "" )} size="small">
                                    <Close sx={{ color: flatColorsAndBackgrounds[2].color, fontSize: "20px" }} />
                                </IconButton>
                            </Box>}
                            {category && category.split( ',' ).map( cat => (
                                <Box bgcolor={flatColorsAndBackgrounds[2].bg} border={`2px solid ${flatColorsAndBackgrounds[2].color}`} textTransform="capitalize" borderRadius="20px" key={cat} fontSize="14px" alignItems="center" display="flex" gap="10px" padding="0 10px">
                                    {cat}
                                    <IconButton onClick={() => removeCategory( cat )} size="small">
                                        <Close sx={{ color: flatColorsAndBackgrounds[2].color, fontSize: "20px" }} />
                                    </IconButton>
                                </Box>
                            ) )}
                        </Box>}
                    </Box>

                    {/* Discussions */}
                    {DiscussionForumStore.getDiscussions.length > 0 && <Box minHeight="300px" flexGrow={1} height="600px" alignItems="flex-start" display="grid" bgcolor={palette.greyedOut} overflow="auto" gridTemplateColumns={{ md: "350px auto", xs: "1fr" }} gap="20px" padding="20px">
                        <Box display="flex" className="slim-custom-scrollbar" flexDirection="column" height="100%" overflow="auto" gap="10px" >
                            {DiscussionForumStore.getDiscussions
                                .slice( paginationDetails.from - 1, paginationDetails.to )
                                .map( discussion => (
                                    < DiscussionCard key={discussion._id} discussion={discussion} />
                                ) )}

                        </Box>
                        <Box height="100%" display={{ md: "flex", xs: "none" }} flexDirection="column" overflow="auto">
                            <Outlet />
                        </Box>
                    </Box>}

                    {/* Pagination bar */}
                    {DiscussionForumStore.getDiscussions.length > 0 && <Box display="flex" alignItems="center" justifyContent="flex-end" padding="20px 40px" bgcolor={palette.rowHover}>
                        <Typography color="textSecondary" marginRight="10px" variant='subtitle2'>Discussion per page</Typography>
                        <Select onChange={e => setDiscussionsPerPage( e.target.value )} size="small" value={discussionsPerPage}>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                        <IconButton onClick={moveToPreviousPage} disabled={paginationDetails.pageValue === 1} size="small">{Icons.default.ChevronLeftIcon}</IconButton>
                        <Typography color="textSecondary" variant="subtitle2">{`${paginationDetails.from}-${paginationDetails.to}`} of {DiscussionForumStore.getDiscussions.length}</Typography>
                        <IconButton onClick={moveToNextPage} disabled={paginationDetails.pageValue >= paginationDetails.maxPagePossible} size="small">{Icons.default.ChevronRightIcon}</IconButton>
                    </Box>}

                    {/* No discussions secction */}
                    {DiscussionForumStore.getDiscussions.length === 0 && <Box padding="20px" bgcolor={palette.contentBg} display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="10px">
                        <img width="150px" src={noDiscussionsImg} alt="No discussions" />
                        <Typography variant='subtitle2' color="textSecondary">No discussions</Typography>
                        {( category?.length > 0 || searchText?.length > 0 ) &&
                            <Button onClick={clearFilters} sx={{ width: "fit-content", textTransform: "capitalize", flexGrow: 1 }} variant="outlined" color="error" startIcon={Icons.default.FilterAltOff}>Clear filters</Button>
                        }
                    </Box>}
                </Box>}

                {loading && <DataLoadingSpinner waitingMessage="Loading discussions..." />}
            </Paper>
        </Box>
    )
} )

export default Discussions


