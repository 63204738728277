import { Box, Button, Collapse, Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Icons } from '../../utils/utilities'

import docImg from '../../assets/word-icon.svg'
import pptImg from '../../assets/ppt-icon.svg'

import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import { useUserStore } from '../../store/user.store'
import { DeleteOutline, EditOutlined, InsertDriveFile, QuizOutlined } from '@mui/icons-material'
import api from '../../service/api'
import { toast } from 'react-toastify'
import { useOnlineCourseStore } from '../../store/onlinecourse.store'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import ConfirmDialog from '../common/ConfirmDialog'
import AddQuizToCourseDialog from './AddQuizToCourseDialog'

const ModuleBox = ( { selectedAttachment, setSelectedAttachment, viewAttachment, setMode, index, module, attachments, setSelectedModule, handleModuleDrag, handleModuleDrop } ) => {
    const UserStore = useUserStore()
    const [collapseState, setCollapseState] = useState( false )
    const [dragObjectStyle, setDragObjectStyle] = useState( { border: "1px solid #cbcbcb" } )
    const [isInEditMode, setIsInEditMode] = useState( false )
    const [editValue, setEditValue] = useState( '' )
    const [addQuizDialogState, setAddQuizDialogState] = useState( false )
    const [attachmentDialogStatus, setAttachmentDialogStatus] = useState( false )
    const [attachmentEditValue, setAttachmentEditValue] = useState( '' )

    const [deleteConfirmDialogData, setDeleteConfirmDialogData] = useState( { status: false } )

    const OnlineCourseStore = useOnlineCourseStore()

    const { course_id } = useParams()
    const { palette } = useTheme()

    const deleteModule = async () => {
        try {
            await api.deleteModule( module.id )
            await OnlineCourseStore.fetchCourse( course_id )
            setDeleteConfirmDialogData( { status: false } )
            toast( <span>Successfully deleted <i sx={{ color: 'orange' }}>{module.module_name}</i> </span> )
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    const deleteAttachment = async ( id, name ) => {
        try {
            setMode( "view" )
            await api.deleteCourseAttachment( id )
            await OnlineCourseStore.fetchCourse( course_id )
            setDeleteConfirmDialogData( { status: false } )
            toast( <span>Successfully deleted <i style={{ color: 'orange' }}>{name}</i> </span> )
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    const updateModule = async () => {
        try {
            if ( editValue !== "" && editValue.trim() !== "" ) {
                const res = await api.updateModuleName( module.id, { name: editValue } )
                await OnlineCourseStore.fetchCourse( course_id )
                toast( res.data.message )
                setIsInEditMode( false )
            }
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    const updateAttachment = async () => {
        try {
            if ( attachmentEditValue !== "" && attachmentEditValue.trim() !== "" ) {
                const res = await api.updateAttachmentName( selectedAttachment.attachment_id, { name: `${attachmentEditValue}.${selectedAttachment.attachment_name.split( "." ).pop()}` } )
                await OnlineCourseStore.fetchCourse( course_id )
                toast( res.data.message )
                setAttachmentDialogStatus( false )
            }
        } catch ( err ) {
            console.log( err )
            toast( err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    useEffect( () => {
        setEditValue( module.module_name )
    }, [module] )

    return (
        <div
            onDrop={( e ) => { handleModuleDrop( e, index ); setDragObjectStyle( { border: "1px solid #cbcbcb" } ) }}
            onDragOver={( e ) => { e.preventDefault(); setDragObjectStyle( { borderTop: "2px solid gray" } ) }}
            onDragLeave={() => setDragObjectStyle( { border: "1px solid #cbcbcb" } )}
            onDragStart={( e ) => { handleModuleDrag( e, index ); setDragObjectStyle( { opacity: "1", border: "1px solid #cbcbcb" } ) }}
            onDragEnd={() => { setDragObjectStyle( { opacity: "1", border: "1px solid #cbcbcb" } ) }}
            draggable={UserStore.getUser["user_role"] === "admin"}
            style={dragObjectStyle} >

            <ConfirmDialog actionName="delete" message={deleteConfirmDialogData.message} status={deleteConfirmDialogData.status} cancelAction={() => setDeleteConfirmDialogData( { status: false } )} confirmAction={deleteConfirmDialogData.action} />

            <Dialog PaperProps={{ sx: { width: "70vw" } }} open={isInEditMode}>
                <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>Edit module</DialogTitle>
                <DialogContent>
                    <TextField sx={{ marginTop: "20px" }} fullWidth placeholder='Enter the module name here...' onChange={( e ) => setEditValue( e.target.value )} value={editValue} />
                    <Box marginTop="20px" display="flex" gap="10px">
                        <Button startIcon={Icons.default.EditIcon} disableElevation variant='contained' onClick={updateModule} sx={{ textTransform: "capitalize" }}>Update</Button>
                        <Button startIcon={Icons.default.CloseIcon} variant='outlined' color='error' onClick={() => setIsInEditMode( false )} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog PaperProps={{ sx: { width: "70vw" } }} open={attachmentDialogStatus}>
                <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>Edit chapter name</DialogTitle>
                <DialogContent>
                    <TextField sx={{ marginTop: "20px" }} fullWidth placeholder='Enter the module name here...' onChange={( e ) => setAttachmentEditValue( e.target.value )} value={attachmentEditValue} />
                    <Box marginTop="20px" display="flex" gap="10px">
                        <Button startIcon={Icons.default.EditIcon} variant='contained' disableElevation onClick={() => updateAttachment()} sx={{ textTransform: "capitalize" }}>Update</Button>
                        <Button startIcon={Icons.default.CloseIcon} variant='outlined' color='error' onClick={() => setAttachmentDialogStatus( false )} sx={{ textTransform: "capitalize" }}>Cancel</Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <AddQuizToCourseDialog setState={setAddQuizDialogState} state={addQuizDialogState} />

            <Box bgcolor="#eee" paddingX="20px" display="flex" justifyContent="space-between" alignItems="center" sx={{ cursor: "pointer" }}>
                <Box display="flex" width="100%" gap="10px" alignItems="center">
                    {UserStore.getUser["user_role"] === "admin" && <DragIndicatorIcon sx={{ fontSize: "20px", cursor: "move" }} />}
                    <Box flexGrow={1} padding="10px 0" onClick={() => setCollapseState( !collapseState )} height="40px" display="flex" gap="10px" alignItems="center">
                        {Icons.default.KeyboardArrowDownIcon}
                        <Typography variant='subtitle2' fontSize="16px" fontWeight="600" noWrap>{module.module_name}</Typography>
                    </Box>
                </Box>
                {UserStore.getUser["user_role"] === "admin" && <Tooltip placement='top-start' title={`Edit`}>
                    <IconButton size='small' onClick={() => setIsInEditMode( true )} > {Icons.default.EditIcon}</IconButton>
                </Tooltip>}
                {UserStore.getUser["user_role"] === "admin" && <Tooltip placement='top-start' title={`Add new attachments to ${module.module_name}`}>
                    <IconButton size='small' onClick={() => setSelectedModule( module )} > {Icons.default.AddIcon}</IconButton>
                </Tooltip>}
                {UserStore.getUser["user_role"] === "admin" && <Tooltip placement='top-start' title={`Add a quiz`}>
                    <IconButton size='small' onClick={() => setAddQuizDialogState( true )} > {Icons.default.AddBox}</IconButton>
                </Tooltip>}
                {UserStore.getUser["user_role"] === "admin" && <Tooltip placement='top-start' title={`Delete module ${module.module_name}`}>
                    <IconButton size='small' color='error' onClick={() => { setDeleteConfirmDialogData( { status: true, message: `Do you really want delete this module?`, action: () => deleteModule() } ) }} > {Icons.default.DeleteIcon}</IconButton>
                </Tooltip>}
            </Box>
            {collapseState && <Collapse in={collapseState}>
                <Box display="flex" flexDirection="column" borderTop="1px solid #cbcbcb">
                    {attachments.length > 0 && attachments.map( att => {
                        const ext = att.attachment_link.split( "." ).pop()
                        return <Box
                            borderTop="1px solid #cbcbcb"
                            padding="10px 20px"
                            sx={{
                                borderLeft: selectedAttachment?.id === att.id ? "#f18f08 5px solid" : "5px solid #fff0",
                                boxShadow: selectedAttachment?.id === att.id ? "1px 1px 3px 1px rgba(0,0,0,0.1)" : "none",
                                backgroundColor: selectedAttachment?.id === att.id ? palette.rowHover : "none",
                                "&:hover": {
                                    backgroundColor: palette.rowHover
                                }
                            }}
                            key={att.id}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box flexGrow={1} onClick={() => { if ( viewAttachment ) viewAttachment( att ) }} display="flex" gap="10px" alignItems="center" fontSize="13px">
                                {["webm", "mp4", "mkv"].includes( ext.toLowerCase() ) && <PlayCircleFilledWhiteIcon sx={{ fontSize: "20px" }} />}
                                {ext.toLowerCase() === 'txt' && <InsertDriveFile sx={{ fontSize: "20px" }} />}
                                {ext.toLowerCase() === 'docx' && <img src={docImg} width="20px" alt="File" />}
                                {ext.toLowerCase() === 'pptx' && <img src={pptImg} width="20px" alt="File" />}
                                <Typography title={att.attachment_name.split( "." ).slice( 0, -1 ).join( "" )} fontWeight="bold" fontSize="14px" width="60%" sx={{ textDecoration: "underline", cursor: "pointer" }}>{att.attachment_name.split( "." ).slice( 0, -1 ).join( "" )}</Typography>
                            </Box>
                            {UserStore.getUser["user_role"] === "admin" && <Box display="flex" gap="10px" alignItems="center">
                                <Tooltip placement="top" title="Edit name">
                                    <IconButton size="small" onClick={() => { setSelectedAttachment( att ); setAttachmentEditValue( att.attachment_name.split( "." ).slice( 0, -1 ).join( "" ) ); setAttachmentDialogStatus( true ) }} sx={{ marginTop: "-5px" }} color="secondary" > <EditOutlined sx={{ fontSize: "20px" }} /> </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Add Quiz">
                                    <IconButton size="small" onClick={() => { setSelectedAttachment( att ); setAttachmentEditValue( att.attachment_name.split( "." ).slice( 0, -1 ).join( "" ) ); setAttachmentDialogStatus( true ) }} sx={{ marginTop: "-5px" }} color="primary" > <QuizOutlined sx={{ fontSize: "20px" }} /> </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Delete chapter">
                                    <IconButton size="small" onClick={() => { setDeleteConfirmDialogData( { status: true, message: `Do you really want delete this attachment?`, action: () => deleteAttachment( att.attachment_id, att.attachment_name ) } ) }} sx={{ marginTop: "-5px" }} color="error"> <DeleteOutline sx={{ fontSize: "20px" }} /> </IconButton>
                                </Tooltip>
                            </Box>}
                        </Box>
                    } )}
                    {attachments.length === 0 && <Typography padding="20px" variant='subtitle2' color="textSecondary">No chapters present in this module.</Typography>}
                </Box>
            </Collapse>}
        </div>
    )
}

export default ModuleBox
