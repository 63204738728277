import { Typography, Button, Box, RadioGroup, FormControlLabel, Tooltip, FormControl, Radio, Checkbox, useTheme, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import { useEffect } from 'react'
// import AudioPlayer from '../common/AudioRecorder'
import { AudioFileOutlined, CheckCircleOutline } from '@mui/icons-material'
import { useUserStore } from '../../store/user.store'
import api from '../../service/api'
import { latexDelimiters, shuffleArray } from '../../utils/utilities'
import Latex from 'react-latex-next'
import { observer } from 'mobx-react'
import { useAdmissionExamStore } from '../../store/admission-exam.store'
import CustomWysiwyg from '../common/CustomWysiwyg'


const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
            ['code-block'],
        ],
        // handlers: {
        //     image: async () => await handleFileInput( 'image' ),
        //     video: async () => await handleFileInput( 'video' ),
        // },
    },
}

const AnswerSection = observer( ( { allAnswered, saving, submitAnswer, question, currentQuestionIndex, nextQuestion, submissions, finishExam, handleClickOnFinishExam, toggleQuestions, showQuestions } ) => {
    const [currentSubmissions, setcurrentSubmissions] = useState( null )
    const [resetting, setResetting] = useState( false )

    const AdmissionExamStore = useAdmissionExamStore()
    const UserStore = useUserStore()

    const { palette } = useTheme()

    const moveToNextQuestion = ( e ) => {
        nextQuestion()
    }

    const handleSingleSelectionChange = async e => {
        const newSubmissionValue = { ...currentSubmissions, [question.question.question_id]: e.target.value }
        setcurrentSubmissions( newSubmissionValue )
        await submitAnswer( newSubmissionValue[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
        moveToNextQuestion()
    }

    const handleMultipleSelectionChange = ( e, option ) => {
        let newSubmissionValue
        if ( currentSubmissions[question.question.question_id] )
            newSubmissionValue = { ...currentSubmissions, [question.question.question_id]: { ...currentSubmissions[question.question.question_id], [option.answer_id]: e.target.checked ? 1 : 0 } }
        else
            newSubmissionValue = { ...currentSubmissions, [question.question.question_id]: { [option.answer_id]: e.target.checked ? 1 : 0 } }
        setcurrentSubmissions( newSubmissionValue )
        submitAnswer( newSubmissionValue[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
    }

    const handleDescriptiveAnswerChange = ( e ) => {
        setcurrentSubmissions( { ...currentSubmissions, [question.question.question_id]: e } )
    }

    const saveDescriptiveAnswer = () => {
        submitAnswer( currentSubmissions[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
    }

    const resetAnswer = () => {
        setResetting( true )
        const newSubmissionValue = { ...currentSubmissions, [question.question.question_id]: '' }
        setcurrentSubmissions( newSubmissionValue )
        submitAnswer( newSubmissionValue[question.question.question_id], question.question.question_type, question.question.question_answer_selection, true )
        setResetting( false )
    }

    useEffect( () => {
        const getAudioUrls = async () => {
            let signedUrlsList = []
            try {
                for ( let i = 0; i < question.question?.audio_attachments_keys.length; i++ ) {
                    const key = question.question.audio_attachments_keys[i]
                    const { data: signedUrl } = await api.getAudioPresignedUrl( AdmissionExamStore.getExam.exam.exam_id, question.question.question_id, { key } )
                    signedUrlsList.push( signedUrl.data )
                }
                // setSignedUrls( signedUrlsList )
            } catch ( err ) {
                if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                    UserStore.setSessionState( "ended" )
                }
                else
                    toast( err?.response?.data?.message || "Something went wrong while fetchiing audio attachments", { containerId: "main" } )
            }
        }
        getAudioUrls()
        // }, [question] )
    }, [question, AdmissionExamStore, UserStore] )


    useEffect( () => {
        try {
            const subs = {}
            submissions.forEach( sub => {
                if ( sub.answer_id )
                    if ( sub.question_answer_selection === 'single' )
                        subs[sub.question_id] = sub.answer_id
                    else
                        subs[sub.question_id] = subs[sub.question_id] ? { ...subs[sub.question_id], [sub.answer_id]: 1 } : { [sub.answer_id]: 1 }
                else
                    subs[sub.question_id] = sub.answer_descriptive
            } )
            if ( AdmissionExamStore.getExam.exam.enable_randomize && question.options )
                shuffleArray( question.options )
            setcurrentSubmissions( subs )
        } catch ( err ) {
            console.log( err )
        }
    }, [submissions, AdmissionExamStore, question] )

    return (
        <Box borderRadius="0 0 10px 0" sx={{ height: "100%", overflow: "auto", flexGrow: 1, }} display="flex" flexDirection="column" >
            <Box flexGrow={1} overflow="auto" className="custom-scrollbar" alignItems="flex-start" bgcolor={palette.common.bg} display="flex" flexDirection="column" padding="20px" >
                <Tooltip title="Toggle questions">
                    <Button onClick={toggleQuestions} startIcon={showQuestions ? <ToggleOnIcon /> : <ToggleOffIcon />} sx={{ textTransform: "capitalize" }}>
                        {showQuestions ? "Close questions' list" : "Open questions' list"}
                    </Button>
                </Tooltip>
                {question && <Box sx={{ userSelect: "none" }} >
                    <Typography fontSize={{ lg: '14px', md: "14px", sm: "12px", xs: "12px" }} paddingLeft="10px" variant='subtitle2' color="textSecondary">Question {currentQuestionIndex + 1}</Typography>
                    <Box marginBottom="20px" fontSize="16px" display="flex" alignItems="center" flexWrap="wrap" gap="10px" fontWeight="600" marginTop="10px" color={palette.common.font} paddingLeft="10px" align='justify'>
                        {/* {question.question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField latex={question.question.question_text.trim().split( "LATEX-" )[1]} id='static-latex'> </EditableMathField> : parse( question.question.question_text.trim() )} */}
                        {question.question.question_text.trim() && <Latex delimiters={latexDelimiters} >{question.question.question_text.trim().replace( "LATEX-" )}</Latex>}
                        {submissions?.find( s => s.question_id === question.question.question_id ) ? <Typography variant='subtitle2' color="success.main" display="flex" alignItems="center"> <CheckCircleOutline sx={{ fontSize: "16px" }} /> Saved</Typography> : ""}
                    </Box>
                    {question.question.question_hint && <Typography paddingLeft="10px" fontSize="13px" fontStyle="italic" variant='body2' align='justify' color="textSecondary">HINT: {question.question.question_hint}</Typography>}
                    {question.question.audio_attachments.length > 0 && <Box display="flex" margin="10px 0 0 10px" flexWrap="wrap" gap="10px" alignItems="center">
                        {question.question.audio_attachments.map( ( attachment, index ) => (
                            <Box padding="5px" bgcolor="#fff" display="flex" gap="10px" alignItems="center" border="1px solid #d3d3d3">
                                <AudioFileOutlined sx={{ fontSize: "20px" }} />
                                {/* <AudioPlayer audioName={question.question.audio_attachments[index]} audioSrc={signedUrls[index]} /> */}
                            </Box>
                        ) )}
                    </Box>}
                </Box>}
                {currentSubmissions && question &&
                    <Box flexGrow={1} width="100%" className="custom-scrollbar" sx={{ overflow: "auto" }}>
                        <form method="post" id='answer-form' style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box style={{ flexGrow: 1 }}>
                                <FormControl fullWidth>
                                    {question.question.question_type === 'MCQ' && question.question.question_answer_selection === 'single' &&
                                        <>
                                            <RadioGroup sx={{ padding: "0 0 0 20px", display: "flex", gap: "10px", flexDirection: "column", maxWidth: "600px", width: "90%" }} value={currentSubmissions[question.question.question_id] ? currentSubmissions[question.question.question_id] : 'NA'} name={String( question.question.question_id )} onChange={handleSingleSelectionChange}>
                                                {question.options.map( ( option, index ) => {
                                                    return (
                                                        <FormControlLabel key={option.answer_id} value={option.answer_id} control={<Radio />}
                                                            label={<Latex delimiters={latexDelimiters} >{option.answer_text.trim().replace( "LATEX-" )}</Latex>}
                                                            sx={( currentSubmissions[question.question.question_id] && parseInt( currentSubmissions[question.question.question_id] ) === parseInt( option.answer_id ) ) ? {
                                                                width: "90%",
                                                                maxWidth: "600px",
                                                                padding: "10px",
                                                                borderRadius: "5px",
                                                                background: palette.common.bg,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                transition: "0.2s ease",
                                                                whiteSpace: "pre-wrap",
                                                                '& >p': {
                                                                    margin: "0", display: "inline-block"
                                                                },
                                                                boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
                                                                border: `2px solid ${palette.primary.main}`,
                                                                color: "primary.main",
                                                                "&:hover": {
                                                                    boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
                                                                    border: `2px solid ${palette.primary.main}`
                                                                }, "& span": {
                                                                    fontWeight: "bolder",
                                                                }
                                                            } : {
                                                                width: "90%",
                                                                maxWidth: "600px",
                                                                padding: "10px",
                                                                borderRadius: "5px",
                                                                background: palette.common.bg,
                                                                border: "2px solid #aaaaaa99",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                transition: "0.2s ease",
                                                                whiteSpace: "pre-wrap",
                                                                "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #fff" },
                                                                '& >p': {
                                                                    margin: "0", display: "inline-block"
                                                                }
                                                            }
                                                            } />
                                                    )
                                                } )}
                                            </RadioGroup>
                                        </>
                                    }
                                    {question.question.question_type === 'MCQ' && question.question.question_answer_selection !== 'single' &&
                                        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column", padding: "0 0 0 20px" }}>
                                            {question.options.map( ( option, index ) => (
                                                <FormControlLabel key={option.answer_id}
                                                    value={option.answer_id}
                                                    checked={currentSubmissions[question.question.question_id] ? currentSubmissions[question.question.question_id][option.answer_id] === 1 ? true : false : false}
                                                    control={<Checkbox onChange={e => handleMultipleSelectionChange( e, option )} />}
                                                    label={<Latex delimiters={latexDelimiters} >{option.answer_text.trim().split( "LATEX-" )[1]}</Latex>}
                                                    sx={{
                                                        padding: "10px", borderRadius: "5px", background: palette.common.bg, border: "2px solid #aaaaaa99", display: "flex", alignItems: "center", transition: "0.2s ease", whiteSpace: "pre-wrap", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #fff" }, '& >p': {
                                                            margin: "0", display: "inline-block"
                                                        }
                                                    }} style={( currentSubmissions[question.question.question_id] && parseInt( currentSubmissions[question.question.question_id] ) === parseInt( option.answer_id ) ) ? { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #374ba4", color: "#374ba4", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #374ba4" }, "& span": { fontWeight: "bolder", } } : null}
                                                />
                                            ) )}
                                        </Box>
                                    }
                                    {question.question.question_type === 'descriptive' && question.question.question_id && <Box marginX="10px" height="300px">
                                        <CustomWysiwyg
                                            key={question.question.question_id}
                                            onBlur={saveDescriptiveAnswer}
                                            classList='custom-quill'
                                            modules={modules} value={currentSubmissions[question.question.question_id] ? currentSubmissions[question.question.question_id] : ''} onChange={handleDescriptiveAnswerChange} />
                                    </Box>}
                                </FormControl>
                            </Box>

                            <Box marginTop="60px" display="flex" flexWrap="wrap" gap="20px" paddingRight="10px">
                                {submissions?.find( s => s.question_id === question.question.question_id ) && <Button disabled={resetting} variant='outlined' startIcon={resetting && <CircularProgress size={14} />} disableElevation color='secondary' sx={{ textTransform: "capitalize", }} onClick={resetAnswer}>{resetting ? "Resetting..." : "Reset"}</Button>}
                                {question.question.question_type !== 'MCQ' && <Button type='button' onClick={saveDescriptiveAnswer} size='small' color="primaryDark" variant="contained" sx={{ textTransform: "capitalize" }}>Save</Button>}
                                <Button disabled={saving} size='small' startIcon={saving && <CircularProgress size={14} />} variant="contained" sx={{ textTransform: "capitalize" }} onClick={moveToNextQuestion} >{saving ? "saving..." : "next"}</Button>
                                {allAnswered && <Button variant='contained' color='errorMessage' size="small" onClick={handleClickOnFinishExam} sx={{ textTransform: "capitalize" }}>Finish Exam</Button>}
                            </Box>
                        </form>
                    </Box >}
            </Box>
        </Box >
    )
} )


export default AnswerSection