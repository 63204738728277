import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { Icons } from '../../utils/utilities'

const DepartmentSelect = ( { disabled, selectedDepartment, handleDepartmentChange, departments } ) => {

    const [departmentSelectOpen, setDepartmentSelectOpen] = useState( false )

    const departmentSelectRef = useRef()


    const handleSelect = val => {
        setDepartmentSelectOpen( false )
        handleDepartmentChange( val )
    }

    return (
        <Box>
            <Button disabled={disabled} onClick={() => setDepartmentSelectOpen( true )} ref={departmentSelectRef} endIcon={Icons.default.KeyboardArrowDownIcon} variant='contained' disableElevation sx={{ height: "40px", '&:disabled': { pointerEvents: "all", cursor: disabled && "not-allowed !important", }, background: `linear-gradient(125.39deg, #139CFF -32.54%, #C54097 110.4%)`, opacity: disabled ? 0.6 : 1 }} >
                <Typography variant='subtitle2' color={disabled ? "lightgray" : "white"}>{selectedDepartment === "all" ? "Department" : departments?.find( d => d.department_id === selectedDepartment )?.department_name}</Typography>
            </Button>
            <Menu anchorEl={departmentSelectRef.current} open={departmentSelectOpen} onClose={() => setDepartmentSelectOpen( false )}>
                <MenuItem dense sx={{ fontSize: "12px" }} onClick={() => handleSelect( "all" )} value="all">All</MenuItem>
                {departments.map( ( dept, index ) => (
                    <MenuItem dense sx={{ fontSize: "12px", textTransform: "capitalize" }} onClick={() => handleSelect( dept.department_id )} value={dept.department_id} key={`dept-${index + 1}`}>{dept?.department_name?.toLowerCase()}</MenuItem>
                ) )}
            </Menu>
        </Box>
    )
}

export default DepartmentSelect
