import { Box, Chip, FormControlLabel, IconButton, Paper, Switch, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Icons, mergeArray } from '../../../utils/utilities'
import { observer } from 'mobx-react'
import { JoinFullOutlined, Delete } from '@mui/icons-material'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { useParams } from 'react-router-dom'
import SectionAttributeList from './SectionAttributeList'
import AddNewEntity from './AddNewEntity'
// import { handleGroupItemDragStart } from '../../../utils/resume-builder-utilities'

const SectionBox = observer( ( { sectionIndex, activeSection, formJson, section, setAsActive, setFormJson, setSaveStatus, setSaving, setSuccessfulSave, successfulSave, allEntities } ) => {

    const [titleEditMode, setTitleEditMode] = useState( false )
    const [attributeSearchText, setAttributeSearchText] = useState( '' )
    const [entitiesDropdown, setEntitiesDropdown] = useState( false )
    const [selectedAttributes, setSelectedAttributes] = useState( [] )
    const [addEntityDialogstate, setAddEntityDialogState] = useState( false )

    const ResumeBuilderStore = useResumeBuilderStore()
    const { form_id } = useParams()

    const updateForm = async ( newForm ) => {
        setFormJson( newForm )
        setSaving( true )
        setSaveStatus( { status: "Saving", lastSaved: null } )
        if ( await ResumeBuilderStore.updateFormJson( form_id, { fields: newForm } ) )
            setSuccessfulSave( { ...successfulSave, fields: true } )
        else setSuccessfulSave( { ...successfulSave, fields: false } )
        setSaving( false )
        setSaveStatus( { status: "Saved", lastSaved: new Date() } )
    }

    const swapSections = ( section1, section2 ) => {
        setAsActive( -1 )
        let newForm = [...formJson]
        let temp = newForm[section1]
        newForm[section1] = newForm[section2]
        newForm[section2] = temp
        updateForm( newForm )
    }

    const saveTitle = async ( e ) => {
        if ( e.target.value.trim() !== "" || e.target.value !== section.title ) {
            let newForm = [...formJson]
            const newSection = { ...section }
            newSection.section_title = e.target.value
            newForm[sectionIndex] = newSection
            setTitleEditMode( false )
            setSaving( true )
            updateForm( newForm )
        }
    }

    const handleKeyDown = async ( e ) => {
        if ( e.key === "Enter" && ( e.target.value.trim() !== "" || e.target.value !== section.title ) ) {
            let newForm = [...formJson]
            const newSection = { ...section }
            newSection.section_title = e.target.value
            newForm[sectionIndex] = newSection
            setTitleEditMode( false )
            updateForm( newForm )
        }
    }

    const handleSearchEntities = e => {
        setAttributeSearchText( e.target.value.toLowerCase() )
        if ( e.target.value.toLowerCase().trim() !== "" ) {
            setEntitiesDropdown( true )
        } else {
            setEntitiesDropdown( false )
        }
    }

    const changeSectionType = async ( e ) => {
        let newForm = [...formJson]
        const newSection = { ...section }
        newSection.multiple = e.target.checked
        newForm[sectionIndex] = newSection
        updateForm( newForm )
    }

    const handleAttributeSelect = ( id ) => {
        let newSelectedAttributes = [...selectedAttributes]
        if ( newSelectedAttributes.includes( id ) ) {
            const attrIndex = newSelectedAttributes.indexOf( id )
            newSelectedAttributes.splice( attrIndex, 1 )
        } else {
            newSelectedAttributes.push( id )
        }
        setSelectedAttributes( newSelectedAttributes )
    }

    const groupAttributes = async ( indexes ) => {
        const firstIndex = Math.min( ...indexes )
        let newForm = [...formJson]
        const newSection = { ...section }
        let newSectionAttributes = []
        const groupedValue = mergeArray( indexes, newSection.attributes )
        for ( let j = 0; j < newSection.attributes.length; j++ ) {
            if ( j === firstIndex ) {
                newSectionAttributes.push( groupedValue )
            }
            if ( !indexes.includes( j ) ) {
                newSectionAttributes.push( newSection.attributes[j] )
            }
        }
        newSection.attributes = newSectionAttributes
        newForm[sectionIndex] = newSection
        setSelectedAttributes( [] )
        updateForm( newForm )
    }

    const deleteSelectedAttributes = async ( indexes ) => {
        let newForm = [...formJson]
        const newSection = { ...section }
        let newSectionAttributes = []
        for ( let j = 0; j < newSection.attributes.length; j++ ) {
            if ( !indexes.includes( j ) ) {
                newSectionAttributes.push( newSection.attributes[j] )
            }
        }
        newSection.attributes = newSectionAttributes
        newForm[sectionIndex] = newSection
        setSelectedAttributes( [] )
        updateForm( newForm )
    }

    const addNewAttribute = async ( entity ) => {
        let newForm = [...formJson]
        const newSection = { ...section }
        newSection.attributes.push( [entity] )
        newForm[sectionIndex] = newSection
        setAttributeSearchText( "" )
        setEntitiesDropdown( false )
        if ( window.document.getElementById( "attribute-search-field" ) )
            window.document.getElementById( "attribute-search-field" ).focus()
        updateForm( newForm )
    }

    const removeAttribute = async ( groupIndex, attrIndex ) => {
        let newForm = [...formJson]
        const newSection = { ...section }
        const attributes = newSection.attributes
        if ( attributes[groupIndex].length > 1 ) {
            attributes[groupIndex].splice( attrIndex, 1 )
        } else {
            attributes.splice( groupIndex, 1 )
        }
        newSection.attributes = attributes
        updateForm( newForm )
        setSelectedAttributes( [] )
    }

    const deleteSection = () => {
        let newForm = [...formJson]
        newForm.splice( sectionIndex, 1 )
        updateForm( newForm )
    }

    return (
        <div>
            <AddNewEntity entities={allEntities} addDialogstate={addEntityDialogstate} setAddDialogState={setAddEntityDialogState} />
            <Box border={sectionIndex === activeSection ? "1px solid #374ba4" : '1px solid #d3d3d3'} borderRadius="10px">
                <Box sx={{ pointerEvents: "all", zIndex: "3", position: "relative", display: "flex", justifyContent: "space-between", alignItems: "center", userSelect: "none" }} bgcolor={sectionIndex === activeSection ? "#374ba4" : "#ddd"} borderRadius="10px 10px 0 0">
                    <Typography onClick={() => setAsActive( sectionIndex )} flexGrow={1} padding="10px 20px" fontWeight="600" color={sectionIndex === activeSection ? "white" : "primary"} variant="subtitle2">Section {sectionIndex + 1}</Typography>
                    {formJson.length > 1 && <Box display="flex" alignItems="center">
                        <Tooltip title={`Move ${section.section_title} Up`} placement='top-end'>
                            <Box>
                                <IconButton sx={{ color: sectionIndex === activeSection ? "white" : "primary.light", "&:disabled": { cursor: "not-allowed", pointerEvents: "all" } }} disabled={sectionIndex === 0} onClick={() => { swapSections( sectionIndex, sectionIndex - 1 ) }} size='small'>
                                    {Icons.default.ArrowUpward}
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title={`Move ${section.section_title} Down`} placement='top-end'>
                            <Box>
                                <IconButton sx={{ color: sectionIndex === activeSection ? "white" : "primary.light", "&:disabled": { cursor: "not-allowed", pointerEvents: "all" } }} disabled={sectionIndex === formJson.length - 1} onClick={() => { swapSections( sectionIndex, sectionIndex + 1 ) }} size='small'>
                                    {Icons.default.ArrowDownward}
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>}
                </Box>
                <Paper onClick={() => setAsActive( sectionIndex )} elevation={sectionIndex === activeSection ? 4 : 0}
                    sx={{
                        borderRadius: "0 0 10px 10px", position: "relative", zIndex: "30", padding: "20px",
                        borderTop: sectionIndex === activeSection ? "4px solid #374ba4" : "1px solid rgba(0,0,0,0.2)",
                        display: "flex"
                    }}>
                    <Box flexGrow={1} sx={{ transition: "all 350ms 0s ease" }} paddingRight="20px">
                        <Box display="flex" gap="10px" alignItems="center" borderBottom={titleEditMode ? "3px solid #374ba4" : "1px solid #d3d3d3"}>
                            {!titleEditMode && <Typography sx={{ flexGrow: "1" }} onClick={() => setTitleEditMode( true )} gutterBottom variant='h5'>
                                {section.section_title}
                            </Typography>}
                            {titleEditMode && <TextField sx={{ flexGrow: "1" }} autoFocus onFocus={e => e.target.select()} fullWidth InputProps={{ disableUnderline: true, sx: { fontSize: "24px" } }} color='primary' size='large' variant="standard" onKeyDown={handleKeyDown} defaultValue={section.section_title} onBlur={saveTitle} />}
                            <FormControlLabel disabled={sectionIndex !== activeSection} sx={{ pointerEvents: sectionIndex === activeSection ? "all" : "none" }} control={<Switch size='small' onChange={changeSectionType} checked={section.multiple} />} labelPlacement='start' label={<Typography variant='subtitle2' color="GrayText">Multiple</Typography>} />
                        </Box>
                        <Box display={sectionIndex === activeSection ? "block" : "none"}>
                            <Box marginTop="5px">
                                {sectionIndex === activeSection && <Box position="relative">
                                    <TextField sx={{ marginTop: "10px" }} id="attribute-search-field" onChange={handleSearchEntities} value={attributeSearchText} placeholder='Add attributes to this section...' name='ewu' fullWidth variant='outlined' InputProps={{ sx: { borderRadius: "0" } }} size='small' />
                                    {entitiesDropdown && <Box border="1px solid #d3d3d3" right="0" left="0" display="flex" alignItems="center" gap="10px" flexWrap="wrap" position="absolute" sx={{ zIndex: "20" }} bgcolor="#fffe" padding="10px" borderRadius="0 0 5px 5px" maxHeight="300px">
                                        <Chip
                                            clickable
                                            key={"new"}
                                            size='large'
                                            label={"Add New Entity"}
                                            onClick={() => { setAddEntityDialogState( true ) }}
                                            deleteIcon={Icons.default.AddCircleIcon}
                                            onDelete={() => { setAddEntityDialogState( true ) }}
                                            sx={{ fontWeight: "500" }} />
                                        {ResumeBuilderStore.getEntities
                                            .filter( item => ( item.entity_name.toLowerCase().includes( attributeSearchText ) || item.entity_detail.label.toLowerCase().includes( attributeSearchText ) || item.entity_detail.key.toLowerCase().includes( attributeSearchText ) ) )
                                            .map( ( entity, index ) => {
                                                const present = section.attributes.flatMap( x => x ).map( x => x.id ).includes( entity.id )
                                                return (
                                                    <Chip
                                                        clickable
                                                        key={entity.id}
                                                        color={present ? 'success' : 'error'}
                                                        size='large'
                                                        label={entity.entity_name}
                                                        onClick={() => { addNewAttribute( entity ) }}
                                                        onDelete={() => {
                                                            if ( !present ) addNewAttribute( entity )
                                                        }}
                                                        deleteIcon={present ? Icons.default.CheckIcon : Icons.default.AddIcon} sx={{ fontWeight: "500" }} />
                                                )
                                            } )
                                        }
                                        {ResumeBuilderStore.getEntities
                                            .filter( item => ( item.entity_name.toLowerCase().includes( attributeSearchText ) || item.entity_detail.label.toLowerCase().includes( attributeSearchText ) || item.entity_detail.key.toLowerCase().includes( attributeSearchText ) ) ).length === 0 &&
                                            <Typography variant="subtitle2">No entities found for search '<i>{attributeSearchText}</i>' </Typography>
                                        }
                                    </Box>}
                                </Box>}
                                {section.attributes.length > 0 && <Paper elevation={2} sx={{ marginTop: "20px" }} >
                                    <Box display="flex" alignItems="center" justifyContent="space-between" bgcolor="#eee" padding="10px">
                                        <Typography gutterBottom variant='subtitle2' color='textSecondary'>Section attributes</Typography>
                                        {selectedAttributes.length > 0 && <Box>
                                            {selectedAttributes.length > 1 && <Tooltip title="Group selected">
                                                <IconButton sx={{ height: "25px" }} onClick={() => groupAttributes( selectedAttributes )} size='small'>
                                                    <JoinFullOutlined fontSize='14px' />
                                                </IconButton>
                                            </Tooltip>}
                                            <Tooltip title="Delete selected">
                                                <IconButton sx={{ height: "25px" }} size='small'>
                                                    <Delete onClick={() => deleteSelectedAttributes( selectedAttributes )} fontSize='14px' />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>}
                                    </Box>
                                    <Box padding="5px" paddingBottom="10px" display="flex" gap="5px" flexDirection="column">
                                        {section.attributes.length > 0 && section.attributes.map( ( group, groupIndex ) => {
                                            return (
                                                <SectionAttributeList sectionIndex={sectionIndex} formJson={formJson} key={groupIndex} group={group} groupIndex={groupIndex} selectedAttributes={selectedAttributes} setSaving={setSaving} handleAttributeSelect={handleAttributeSelect} removeAttribute={removeAttribute} updateForm={updateForm} />
                                            )

                                        } )}
                                    </Box>
                                </Paper>}
                                {section.attributes.length === 0 && <Box padding="20px 0 0 0">
                                    <Typography color="textSecondary" fontWeight="500" fontSize="14px">No attributes in this section</Typography>
                                </Box>}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ transition: "all 350ms 0s ease" }} maxWidth={sectionIndex === activeSection ? "200px" : "0px"} overflow="hidden">
                        <Box borderLeft="2px solid #d3d3d3" height="100%" paddingLeft="10px" display="flex" flexDirection="column" gap="10px">
                            <Tooltip onClick={deleteSection} placement='left' color='error' title="Delete section">
                                <IconButton size="small">{Icons.default.DeleteIcon}</IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Paper>
            </Box >
        </div >
    )
} )

export default SectionBox



// <Box onDrag={( e ) => { itemDragEnable = true; handleDragStart( e, groupIndex ) }} className='draggable-element' key={groupIndex}>
//     <div draggable={!itemDragEnable}>
//         <Paper
//             elevation={1}
//             sx={{
//                 position: "relative",
//                 display: "flex",
//                 alignItems: "stretch",
//                 border: "1px solid #d3d3d3",
//                 borderRadius: "5px"
//             }}>
//             <Box borderRight="1px solid #d3d3d3" sx={{ cursor: "grab" }} display="flex" flexDirection="column" alignItems="center">
//                 <Box display="flex" height="100%" flexGrow={1} alignItems="center">
//                     <Box display="flex" alignItems="center">
//                         <DragIndicator sx={{ color: "#a3a3a3" }} fontSize='small' />
//                     </Box>
//                     <Box className='show-on-hover' left="0">
//                         <Checkbox sx={{ transform: "scale(0.8)", background: "white", "&:hover": { background: "white" } }} onChange={() => handleAttributeSelect( groupIndex )} checked={selectedAttributes.includes( groupIndex )} />
//                     </Box>
//                 </Box>
//             </Box>
//             <Box display="flex" flexDirection="column" flexGrow={1}>
//                 {group.map( ( attr, attrIndex ) => {
//                     return (
//                         <div onDragOver={e => e.preventDefault()} onDrop={e => handleDrop( e )} onDragEnd={( e ) => { itemDragEnable = true; handleDragStart( e, groupIndex, attrIndex ) }} onDragStart={( e ) => { itemDragEnable = true; handleGroupDragStart( e, groupIndex, attrIndex ) }} data-group-index={groupIndex} data-attribute-index={attrIndex} className='draggable-element' draggable={group.length > 1} key={attr.id}>
//                             <Box padding="2.5px 5px" display="flex" justifyContent="space-between" borderBottom={group.length > 1 ? "1px solid #d3d3d3" : "none"} alignItems="center">
//                                 <Box display="flex" alignItems="center">
//                                     {group.length > 1 && <DragIndicator sx={{ color: "#a3a3a3", cursor: "grab" }} fontSize='small' />}
//                                     <Typography paddingLeft="15px" variant='subtitle2'>{attr.entity_name}</Typography>
//                                 </Box>
//                                 <Box>
//                                     <Tooltip title={selectedAttributes.length === 0 ? "" : "Remove from section"} placement='top-end'>
//                                         <IconButton onClick={() => removeAttribute( groupIndex, attrIndex )} color='error' size='small'>
//                                             {Icons.default.CloseIcon}
//                                         </IconButton>
//                                     </Tooltip>
//                                 </Box>
//                             </Box>
//                         </div>
//                     )
//                 } )}
//             </Box>
//         </Paper>
//     </div>
// </Box>