import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
import NotificationStore from "./notification.store"
import React from 'react'
const notification = NotificationStore

class OnlineCourse {
    onlineCourse = { course: {}, modules: [] }
    onlineCourses

    constructor () {
        this.onlineCourse = { course: {}, modules: [], attachments: [] }
        this.enrolledOnlineCourses = []
        this.onlineCourses = []
        this.currentCourseEnrollmentStatus = null
        this.enrollmentData = null
        this.courseProgressData = { progress: {}, progress_details: [] }

        makeObservable( this, {
            onlineCourse: observable,
            enrolledOnlineCourses: observable,
            onlineCourses: observable,
            currentCourseEnrollmentStatus: observable,
            enrollmentData: observable,
            courseProgressData: observable,
            addNewCourse: action,
            addNewModule: action,
            fetchCourses: action,
            fetchSubjectCourses: action,
            fetchCourse: action,
            setNewModuleOrder: action,
            fetchProgressData: action,
            updateCourse: action,
            fetchCourseEnrollmentDetails: action,
            getCourses: computed,
            getEnrolledCourses: computed,
            getCourseEnrollmentData: computed,
            getCourse: computed,
            getEnrollmentStatus: computed,
            getCourseProgressData: computed
        } )
    }

    get getCourses() {
        return toJS( this.onlineCourses )
    }

    get getCourse() {
        return toJS( this.onlineCourse )
    }

    get getEnrolledCourses() {
        return toJS( this.enrolledOnlineCourses )
    }

    get getCourseEnrollmentData() {
        return toJS( this.enrollmentData )
    }

    get getEnrollmentStatus() {
        return this.currentCourseEnrollmentStatus
    }

    get getCourseProgressData() {
        return toJS( this.courseProgressData )
    }

    setNewModuleOrder( newOrder ) {
        runInAction( () => {
            this.onlineCourse = { ...this.onlineCourse, modules: newOrder }
        } )
    }

    async addNewCourse( payload ) {
        try {
            await api.addOnlineCourse( payload )
            return true
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err.response && err.response.data ? err.response.data : "Something went wrong!" )
            return false
        }
    }

    async fetchCourseEnrollmentDetails( course_id ) {
        try {
            const { data } = await api.getCourseEnrollmentStatus( course_id )
            this.currentCourseEnrollmentStatus = data.hasEnrolled
            if ( data.hasEnrolled )
                this.enrollmentData = data.data
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    async fetchEnrolledCourses() {
        try {
            runInAction( () => {
                this.enrolledOnlineCourses = []
            } )
            const { data } = await api.getEnrolledCourses()
            runInAction( () => {
                this.enrolledOnlineCourses = data.success ? data.data : []
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    async fetchProgressData( id ) {
        try {
            const { data } = await api.getCourseProgressData( id )
            runInAction( () => {
                this.courseProgressData = data.success ? data.data : { progress: {}, progress_details: [] }
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    async publish( id ) {
        try {
            const res = await api.publishCourse( id )
            this.fetchCourse( id )
            notification.setNotification( true, res.data.message )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    async fetchCourses() {
        try {
            const res = await api.getCourses()
            runInAction( () => {
                this.onlineCourses = res.data.success ? res.data.data : []
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    async fetchSubjectCourses( subjectCode ) {
        try {
            const res = await api.getSubjectCourses( { subjectCode } )
            runInAction( () => {
                this.onlineCourses = res.data.success ? res.data.data : []
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    async fetchCourse( id ) {
        try {
            const res = await api.getCourse( id )
            runInAction( () => {
                this.onlineCourse = res.data.success ? res.data.data : []
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }

    async updateCourse( course_id, payload ) {
        try {
            const res = await api.updateCourse( course_id, payload )
            notification.setNotification( true, res.data.message )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, "Something went wrong!" )
        }
    }

    async addNewModule( course_id, payload ) {
        try {
            await api.addNewModule( course_id, payload )
            this.fetchCourse( course_id )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err.response && err.response.data ? err.response.data : "Something went wrong!" )
        }
    }
}


const OnlineCourseInstance = new OnlineCourse()

export default OnlineCourseInstance

const OnlineCourseContext = React.createContext()

export const OnlineCourseProvider = ( { children, store } ) => {
    return (
        <OnlineCourseContext.Provider value={store}>{children}</OnlineCourseContext.Provider>
    )
}

export const useOnlineCourseStore = () => React.useContext( OnlineCourseContext )