import { Box, Button, Chip, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import { useNavigate } from 'react-router-dom'
import noFormImg from '../../../assets/no-resume-templates.svg'
import { useUserStore } from '../../../store/user.store'
import { Icons } from '../../../utils/utilities'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import BlockMessage from '../../../components/common/BlockMessage'

const tableColumns = [
    { label: "Form", minWidth: "200px" },
    { label: "Added by", minWidth: "150px" },
    { label: "For institutes", minWidth: "100px" },
]

const ResumeForms = observer( () => {

    const [creatingForm, setCreatingForm] = useState( false )
    const [loading, setLoading] = useState( true )
    const [hasPermission, setHasPermission] = useState( true )

    const ResumeBuilderStore = useResumeBuilderStore()
    const UserStore = useUserStore()
    const navigate = useNavigate()

    const { palette } = useTheme()

    const createAndNavigateToConfigureForm = async () => {
        setCreatingForm( true )
        const { success, data } = await ResumeBuilderStore.createForm( { formName: '', formDescription: '' } )
        setCreatingForm( false )
        if ( success ) {
            const role = UserStore.getUser.user_role.toUpperCase()
            if ( ["ADMIN", "SUBADMIN"].includes( role ) ) {
                navigate( `/admin/resume-forms/${data.id}` )
            } else if ( ["HOD", "PRESENTER", "FACULTY", "PRINCIPAL"].includes( role ) ) {
                navigate( `/faculty/resume-forms/${data.id}` )
            } else if ( ["STUDENT", "OBSERVER"].includes( role ) ) {
                navigate( `/student/resume-forms/${data.id}` )
            } else {
                navigate( `/resume-forms/${data.id}` )
            }
        }
    }

    const navigateToForm = ( id ) => {
        const role = UserStore.getUser.user_role.toUpperCase()
        if ( ["ADMIN", "SUBADMIN"].includes( role ) ) {
            navigate( `/admin/resume-forms/${id}` )
        } else if ( ["HOD", "PRESENTER", "FACULTY", "PRINCIPAL"].includes( role ) ) {
            navigate( `/faculty/resume-forms/${id}` )
        } else if ( ["STUDENT", "OBSERVER"].includes( role ) ) {
            navigate( `/student/resume-forms/${id}` )
        } else {
            navigate( `/resume-forms/${id}` )
        }
    }

    useEffect( () => {
        const getData = async () => {
            await ResumeBuilderStore.fetchForms()
            setLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.resume_builder ) {
            setHasPermission( false )
        } else
            getData()
    }, [ResumeBuilderStore, UserStore] )

    return (
        hasPermission ? <Box flexGrow={1} display="flex" flexDirection="column" padding="20px" overflow="auto">
            <Paper sx={{ minHeight: !loading && "350px", display: "flex", flexGrow: !loading && ResumeBuilderStore.getForms.length > 0 ? "1" : "initial", flexDirection: "column" }}>
                <Box borderBottom="1px solid #d3d3d3" display="flex" flexWrap="wrap" alignItems="center" padding="20px">
                    <Box flexGrow={1}>
                        <Typography variant='h5'>Resume Form</Typography>
                        <Typography variant='subtitle2' color="textSecondary">Manage and build resume Form here</Typography>
                    </Box>
                    {!loading && <Button variant='contained' sx={{ textTransform: "capitalize" }} startIcon={Icons.default.AddIcon}>Create</Button>}
                </Box>
                {/* {!loading && <Fab sx={{ position: "fixed", bottom: "20px", right: "20px" }} color='secondary' onClick={createAndNavigateToConfigureForm}>
                        {!creatingForm ? Icons.default.AddIcon : <CircularProgress sx={{ color: "white !important" }} size={20} />}
                    </Fab>} */}
                {!loading && ResumeBuilderStore.getForms.length === 0 && <Box bgcolor="#eee" flexGrow={1} justifyContent="center" padding="20px" display="flex" flexDirection="column" gap="20px" alignItems="center">
                    <img src={noFormImg} height="150px" style={{ marginBottom: "20px" }} alt="No Form" />
                    <Typography color="textSecondary" variant='subtitle2'>Seems like there are no resume forms to show!</Typography>
                    {creatingForm ? <Button disabled variant='outlined'><Typography textTransform="capitalize" display="flex" gap="10px" alignItems="center"> <CircularProgress size={14} /> creating form... </Typography></Button> : <Button onClick={createAndNavigateToConfigureForm} variant="outlined" startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} > Add form </Button>}
                </Box>
                }
                {!loading && ResumeBuilderStore.getForms.length > 0 && <Box flexGrow={1} overflow="hidden">
                    <TableContainer className='custom-scrollbar' sx={{ height: "100%" }}>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map( column => (
                                        <TableCell key={column.label} sx={{ background: palette.primary.main, color: "white", minWidth: column.minWidth, paddingY: "10px" }}>{column.label}</TableCell>
                                    ) )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ResumeBuilderStore.getForms.map( ( form, index ) => (
                                    <TableRow sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }} onClick={() => navigateToForm( form.id )} key={form.id}>
                                        <TableCell sx={{ fontWeight: "bold", padding: "10px" }}>{form.name}</TableCell>
                                        <TableCell sx={{ paddingY: "10px" }}>{form.added_by.user_name}</TableCell>
                                        <TableCell sx={{ paddingY: "10px" }}>
                                            <Box gap='10px' display="flex" flexWrap="wrap" alignItems="center">
                                                {form.access_list.length > 0 && form.access_list.map( item => (
                                                    <Chip size="small" key={item} label={item} sx={{ fontSize: "12px", fontWeight: "bold" }} />
                                                ) )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>
                }
                {loading && <DataLoadingSpinner waitingMessage="Loading resume forms..." />}
            </Paper>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
} )

export default ResumeForms
