import { Box, Button, CircularProgress, FormControl, useTheme, FormHelperText, Paper, TextField, Tooltip, Typography, IconButton } from '@mui/material'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { downloadParticipationReport } from '../../../utils/exam-utilities'
import Submission from '../../../components/exams-components/Submission'
import BlockMessage from '../../../components/common/BlockMessage'
import BreadcrumbList from '../../../components/common/BreadcrumbList'
import { useNavigate, useParams } from 'react-router-dom'
import { useExamStore } from '../../../store/exam.store'
import { Formik, ErrorMessage, Form } from 'formik'
import React, { useState, useEffect } from 'react'
import { Icons, showDateInIndianDateFormat } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import * as Yup from 'yup'
import { UploadOutlined } from '@mui/icons-material'

export default function Evaluate() {
    const [participation, setParticipation] = useState( null )
    const [exam, setExam] = useState( null )
    const [submissions, setSubmissions] = useState( null )
    const [showRemarks, setShowRemarks] = useState( false )
    const [questions, setQuestions] = useState( null )
    const [remarks, setRemarks] = useState( '  ' )
    const [totalMarks, setTotalMarks] = useState( 0 )
    const [inProgress, setInProgress] = useState( true )
    const [scheme, setScheme] = useState( null )
    const [accessDenied, setAccessDenied] = useState( false )
    const [notFound, setNotFound] = useState( false )

    const { id, participation_id } = useParams()

    const ExamStore = useExamStore()
    const navigate = useNavigate()

    const { palette, border } = useTheme()

    const initialValues = {
        remarks: '  '
    }

    const validationSchema = Yup.object().shape( {
        remarks: Yup.string().required( "This field is required!" ).test( {
            name: "TEXT_LENGTH", message: "Remark must be atleast 5 characters long", test: ( value ) => {
                if ( !value || value?.trim().length < 5 ) {
                    return false
                }
                else return true
            }
        } )
    } )

    const remarksSubmit = async ( values, props ) => {
        try {
            const { data } = await api.submitRemark( id, participation_id, values )
            toast( data.message )
        } catch ( e ) {
            toast.error( e?.response?.data?.message || "Something went wrong while submitting the remark" )
        }
    }

    useEffect( () => {
        const getData = async () => {
            try {
                const { success, code } = await ExamStore.fetchExam( id )
                if ( success ) {
                    const participationData = await api.fetchParticipationData( id, participation_id )
                    const { data: submissionData } = await api.fetchParticipationSubmissions( id, participation_id )
                    const { data: questionsData } = await api.fetchQuestions( id )
                    const { data: schemeData } = await api.fetchEvaluationScheme( id )
                    setParticipation( participationData.data.participation )
                    setTotalMarks( participationData.data.marks.sum )
                    setExam( ExamStore.getExam.exam )
                    setRemarks( participationData.data.participation.exam_participation_remark )
                    let subs = {}
                    let tempScheme = {}
                    for ( let i = 0; i < submissionData.data.length; i++ ) {
                        subs[submissionData.data[i].question_id] ? subs[submissionData.data[i].question_id].push( submissionData.data[i] ) : subs[submissionData.data[i].question_id] = [submissionData.data[i]]
                    }
                    for ( let i = 0; i < schemeData.data.length; i++ ) {
                        tempScheme[schemeData.data[i].question_id] = schemeData.data[i]
                    }
                    setSubmissions( subs )
                    setScheme( tempScheme )
                    setQuestions( questionsData.data )
                } else {
                    if ( code === 403 || code === 401 )
                        setAccessDenied( true )
                    else if ( code === 404 ) {
                        setNotFound( true )
                    }
                }
            }
            catch ( e ) {
                console.log( e )
                toast( e?.response?.data?.message || "Something went wrong!" )
            } finally {
                setInProgress( false )
            }
        }
        getData()
    }, [participation_id, id, ExamStore] )

    return (
        <Box sx={{ padding: "20px", flexGrow: 1, overflow: "auto" }} display="flex" flexDirection="column">
            {notFound && <BlockMessage actions={[{ label: "Back to Participations", action: () => navigate( `/faculty/participations` ) }]} type='error' blockTitle='Exam Not Found' message='The exam is you are looking for seems to be deleted.' />}
            {accessDenied && <BlockMessage actions={[{ label: "Back to Participations", action: () => navigate( `/faculty/participations` ) }]} type='error' blockTitle='Access Denied' message='You do not have permission to view this exam information. Only exam creator and admin can access a particular exam.' />}

            {!accessDenied && !notFound && <BreadcrumbList
                items={[
                    { label: "Exams", link: "/faculty/exams" },
                    { label: exam && exam.exam_name ? exam.exam_name : <CircularProgress sx={{ color: "textSecondary" }} size={12} />, link: `/faculty/exams/${id}` },
                    { label: exam && exam.exam_name ? "Participations" : <CircularProgress sx={{ color: "textSecondary" }} size={12} />, link: `/faculty/exams/${id}/participation` },
                    "Evaluate",
                    participation?.student_name || ""
                ]}
            />}

            {!accessDenied && !notFound && exam && participation && !inProgress && <Box minHeight="450px" flexGrow="1" display="flex" justifyContent="center" gap="20px" height="100%">
                <Paper sx={{ flexGrow: "1", display: "flex", flexDirection: "column", overflow: "hidden" }} >
                    <Box sx={{ background: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 180%)` }} color="white" gap="20px" display="flex" flexDirection={{ md: "row", xs: "column" }} justifyContent="space-between" alignItems={{ md: "center", xs: "flex-start" }} padding="20px">
                        <Box>
                            <Typography variant='h5' textTransform="capitalize">{exam.exam_name}</Typography>
                            <Typography variant='subtitle2' textTransform="capitalize"> Submissions of {participation.student_name.toLowerCase()}</Typography>
                        </Box>
                        <Typography display="flex" alignItems="center" gap="10px" variant='subtitle2' textTransform="capitalize">{Icons.default.Calendar} {`${showDateInIndianDateFormat( participation.exam_participation_start_time )}, ${new Date( participation.exam_participation_start_time ).toLocaleTimeString()}`}</Typography>
                        <Typography variant='body2' textTransform="capitalize">Attempt:  {participation.exam_participation_attempt}</Typography>
                        <Typography bgcolor="#D9D9D914" border="1px solid #fff" borderRadius="5px" padding="5px 20px" fontWeight="bold" fontSize="16px" variant='h6'>{participation.exam_participation_score ? participation.exam_participation_score : "0"} Out Of {totalMarks}</Typography>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Tooltip title="Download Report as CSV">
                                <Button
                                    variant='contained'
                                    disableElevation
                                    color='customThemeColor'
                                    endIcon={<UploadOutlined />}
                                    onClick={() => downloadParticipationReport( participation, questions, submissions )}
                                    sx={{
                                        textTransform: "capitalize",
                                        minWidth: "max-content",
                                        background: `linear-gradient(200.17deg, #F3C400 -47.65%, #994322 167.67%)`,
                                        '&:hover': {
                                            background: `linear-gradient(200.17deg, #F3C400 -47.65%, #994322 167.67%)`
                                        }
                                    }} >
                                    Export report
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box flexGrow={1} className="custom-scrollbar" overflow="auto" display="flex" flexDirection="column" gap="10px">
                        <Box overflow="auto" flexGrow={1} bgcolor={palette.contentBg} className="custom-scrollbar" border={border[1]} borderRadius="5px" margin="20px">
                            <Box padding="20px" maxWidth="700px" display="flex" className="custom-scrollbar" overflow="auto" flexDirection="column" gap="20px">
                                {submissions && questions && !inProgress && questions.map( ( question, index ) => (
                                    <Box key={index}>
                                        < Submission question={question.question} answer={submissions[question.question.question_id]} options={question.options} keyAnswer={scheme[question.question.question_id]} index={index} />
                                    </Box>
                                ) )}
                            </Box>

                            {!inProgress && <Box position="sticky" bottom="0" sx={{ flexGrow: 1, fontSize: "14px", background: palette.contentBg, borderRadius: "5px" }}>
                                <Box display="flex" justifyContent="space-between" bgcolor={palette.primary.light + "22"} alignItems="center">
                                    <Typography padding="10px" variant='subtitle2' color="primary">Remark</Typography>
                                    <IconButton size="small" onClick={() => setShowRemarks( !showRemarks )}>
                                        {!showRemarks ? Icons.default.KeyboardArrowUp : Icons.default.KeyboardArrowDownIcon}
                                    </IconButton>
                                </Box>
                                {showRemarks && <Box padding="20px" paddingTop="0">
                                    <Formik onSubmit={remarksSubmit} validationSchema={validationSchema} initialValues={initialValues}>
                                        {( { setFieldValue } ) => (
                                            <Form method="post" autoComplete='off'>
                                                <FormControl margin="normal" fullWidth>
                                                    <Typography variant='subtitle' gutterBottom color={palette.labelColor}>Remarks</Typography>
                                                    <TextField placeholder='Eg: Good work keep it up!' multiline name='remarks' defaultValue={remarks} onChange={e => setFieldValue( 'remarks', e.target.value )} />
                                                    <FormHelperText sx={{ color: "error.main", marginLeft: "0px" }}> <ErrorMessage name="remarks" /> </FormHelperText>
                                                    <Button disableElevation type="submit" variant='contained' sx={{ textTransform: 'capitalize', width: "fit-content", height: "53px", marginTop: "10px" }}>Submit remarks</Button>
                                                </FormControl>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>}
                            </Box>}
                        </Box>
                    </Box >
                </Paper >
            </Box >}
            {!accessDenied && !notFound && !participation && !inProgress && <Typography marginTop="10px" variant='h6' color="textSecondary">Participation not found</Typography>}
            {
                inProgress && <Paper sx={{ marginTop: "10px" }}>
                    <DataLoadingSpinner waitingMessage="Loading data..." />
                </Paper>
            }
        </Box >
    )
}
