import { Drawer, IconButton, Box, Typography, useTheme, Collapse } from '@mui/material'
import { useLayoutContext } from '../../contexts/layout.context'
import { useUserStore } from '../../store/user.store'
import aliveLogo from '../../assets/alivelogo.png'
import { Icons } from '../../utils/utilities'
import React, { useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const menu = [
    { id: 1, activeMatchRegexes: [/^(\/audit)\/?$/], permission: true, label: "Dashboard", children: null, link: `/audit`, icon: Icons.default.HomeIconOutlined, activeIcon: Icons.default.HomeIcon },
    { id: 3, activeMatchRegexes: [/^(\/audit\/study-materials)\/?$/, /^(\/audit\/study-materials)\/list\/?$/], permission: true, label: "Study Material", children: null, link: `/audit/study-materials`, icon: Icons.default.DescriptionIconOutlined, activeIcon: Icons.default.DescriptionIcon },
    { id: 4, activeMatchRegexes: [/^(\/audit\/assignments)\/?$/, /^(\/audit\/assignments)\/list\/?$/], permission: true, label: "Assignments", children: null, link: `/audit/assignments`, icon: Icons.default.AssignmentIconOutlined, activeIcon: Icons.default.AssignmentIcon },
    { id: 5, activeMatchRegexes: [/^(\/audit\/exams)\/?$/, /^(\/audit\/exams)\/list\/?$/], permission: true, label: "Exam", children: null, link: "/audit/exams", activeIcon: Icons.default.QuizIcon, icon: Icons.default.QuizIconOutlined },
    { id: 7, activeMatchRegexes: [/^(\/audit\/quizzes)\/?$/, /^(\/audit\/quizzes)\/list\/?$/], permission: true, label: "Quizzes", children: null, link: "/audit/quizzes", activeIcon: Icons.default.QuizIcon, icon: Icons.default.QuizIconOutlined },
    { id: 8, activeMatchRegexes: [/^(\/audit\/questionbanks)\/?$/, /^(\/audit\/questionbanks)\/list\/?$/], permission: true, label: "Question Banks", children: null, link: "/audit/questionbanks", activeIcon: Icons.default.QuestionMark, icon: Icons.default.QuestionMarkOutlined },
    { id: 6, activeMatchRegexes: [/^(\/audit\/discussions)\/?$/, /^(\/audit\/discussions)\/list\/?$/], permission: ["PRINCIPAL", "ADMIN", "SUBADMIN", "is_auditor"], label: "Discussions", children: null, link: "/audit/discussions", activeIcon: Icons.default.Groups2, icon: Icons.default.Groups2Outlined },
]


const SidedrawerMenuItem = ( { item, isActive, sidebarOpenState, closeOnMobileScreen, active } ) => {

    const [collapseState, setCollapseState] = useState( false )
    const { palette } = useTheme()
    const { pathname, search } = useLocation()


    return <Box borderRadius="5px" marginX="20px" bgcolor={collapseState && palette.nav.sidebarHover}>
        <Link className='default-link' to={!item.children ? item.link : pathname + search}>
            <Box
                onClick={item.children ? () => setCollapseState( !collapseState ) : closeOnMobileScreen}
                bgcolor={isActive && palette.nav.activeSidebarItem}
                color={isActive ? palette.nav.selected : palette.common.white}
                borderRadius="5px"
                sx={{
                    cursor: "pointer",
                    position: "relative",
                    background: collapseState ? isActive ? palette.nav.activeSidebarItem : palette.nav.sidebarHover : isActive ? palette.nav.activeSidebarItem : "none",
                    "&:hover": {
                        background: !isActive ? palette.nav.sidebarHover : palette.nav.activeSidebarItem,
                    }
                }}
                display="flex"
                alignItems="center"
                padding="5px 10px"
                gap="15px"
                marginY="5px"
            >
                <IconButton
                    color="inherit"
                    size='small'
                    sx={{
                        padding: "8px",
                        borderRadius: "5px",
                        transform: "scale(0.9)",
                        position: "relative"
                    }}>
                    {isActive ? item.icon : item.icon}
                </IconButton>
                <Typography flexGrow={1} display="flex" alignItems="center" justifyContent='space-between' flexShrink={0} color="inherit" variant="subtitle2" fontWeight={isActive ? "700" : "300"} fontSize="14px" textAlign="center">
                    {item.label}
                    {item.children && Icons.small.KeyboardArrowDown}
                </Typography>
            </Box>
        </Link>
        {item.children && <Collapse in={collapseState}>
            <Box flexDirection="column" paddingBottom="10px" display="flex" gap="5px" paddingRight="20px" paddingLeft="30px">
                {item.children.map( ( sublink, index ) => {
                    const activeChild = item.activeMatchRegexes[index].some( re => active.match( re ) )
                    return <Link className='default-link' key={sublink} to={item.link[index]}>
                        <Box
                            display="flex"
                            onClick={closeOnMobileScreen}
                            borderRadius="5px"
                            padding="2px 5px"
                            gap="20px"
                            alignItems="center"
                            sx={{
                                color: activeChild ? "white" : "lightgrey",
                                cursor: "pointer",
                                position: "relative",

                                "&::before": {
                                    display: activeChild ? "inline-block" : "none",
                                    content: "''",
                                    position: "absolute",
                                    background: "#fff",
                                    height: "60%",
                                    borderRadius: "0 2px 2px 0",
                                    left: 0,
                                    width: "2px",
                                    zIndex: 324434,
                                    top: "50%",
                                    transform: "translate(0,-50%)"
                                },

                                "&:hover": {
                                    textDecoration: "underline",
                                }
                            }}
                        >
                            <Typography variant="subtitle2" flexShrink={0} color="inherit" fontWeight={activeChild ? "400" : "300"} fontSize="14px" textAlign="center">{sublink}</Typography>
                        </Box>
                    </Link>
                } )}
            </Box>
        </Collapse>}
    </Box>
}

const SideDrawer = function ( { closeSideMenu } ) {
    const { sidebarOpenState, setNavHeadText, active, setMenuState, menuState, sidebarWidth, minSidebarCollapseWidth } = useLayoutContext()

    const { palette } = useTheme()

    const UserStore = useUserStore()

    const closeOnMobileScreen = () => {
        if ( window.innerWidth < minSidebarCollapseWidth )
            closeSideMenu()
    }

    useEffect( () => {
        for ( let i = 0; i < menu.length; i++ ) {
            const item = menu[i]
            const isActive = item.activeMatchRegexes.flat().some( re => active.match( re ) )
            if ( isActive ) {
                setNavHeadText( item.label )
                break
            }
        }
    }, [active, setNavHeadText] )

    return (
        <Box>
            <Drawer
                variant='persistent'
                sx={{ position: { md: "static", xs: "fixed" }, zIndex: { md: "initial", xs: "120000" }, overflow: "hidden", width: menuState ? sidebarWidth : 0, height: "100%", flexSrink: 0, display: "flex", flexDirection: "column" }}
                PaperProps={{ className: "custom-scrollbar", sx: { width: sidebarWidth, borderRadius: "0", background: palette.nav.sidebar, color: "white", borderRight: "none" } }}
                anchor="left"
                open={menuState}
                className="custom-scrollbar"
            >
                <Box position="relative" flexGrow={1} overflow="auto" borderRight="1px solid #d3d3d3" display="flex">
                    <Box className="sidebar-scrollbar" width="100%" overflow="auto" padding="20px 10px" color="GrayText" alignItems="stretch" gap="10px">

                        <Box padding="10px 20px 40px 20px" display="flex" gap="10px" alignItems="center">
                            <Box padding="5px" borderRadius="5px" bgcolor="white"><img width="30px" src={aliveLogo} alt="Logo" /></Box>
                            <Box>
                                <Typography color="white" variant='h6'>Alive</Typography>
                                <Typography color="white" marginTop="-7px" letterSpacing="1px" fontWeight="300" variant='body2'>Digital Classrooms</Typography>
                            </Box>
                            <IconButton sx={{ color: "white", display: { md: "none", xs: "block" } }} onClick={() => setMenuState( false )}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>

                        {menu.map( item => {
                            const isActive = item.children ? item.activeMatchRegexes.flat().some( re => active.match( re ) ) : item.activeMatchRegexes.some( re => active.match( re ) )
                            let permission = false
                            if ( typeof item.permission !== 'boolean' ) {
                                const userRole = UserStore.getUser.user_role
                                if ( item.permission.includes( userRole?.toUpperCase() ) || ( UserStore.getUser.user_auditor && item.permission.includes( 'is_auditor' ) ) ) {
                                    permission = true
                                }
                            } else
                                permission = item.permission

                            return permission ? (
                                <SidedrawerMenuItem active={active} closeOnMobileScreen={closeOnMobileScreen} item={item} isActive={isActive} sidebarOpenState={sidebarOpenState} key={item.id} />
                            ) : ""
                        } )}
                    </Box>

                </Box >
            </Drawer >
        </Box >
    )
}

export default SideDrawer


