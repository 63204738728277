import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
import { Icons } from '../../utils/utilities'

import api from '../../service/api'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useOnlineCourseStore } from '../../store/onlinecourse.store'
import { useParams } from 'react-router-dom'
import addFileImg from '../../assets/material-upload.svg'
import { useUploadStatusListStore } from '../../store/uploadstatuslistdialog.store'


const AddAttachmentsDialog = ( { state, setState, module } ) => {

    const [inputFiles, setInputFiles] = useState( [] )

    const OnlineCourseStore = useOnlineCourseStore()
    const UploadStatusList = useUploadStatusListStore()

    const { course_id } = useParams()

    const validateAndUpload = () => {
        for ( let k = 0; k < inputFiles.length; k++ ) {
            const file = inputFiles[k]
            if ( file.name === "" ) {
                toast( "Attachment name cannot be empty" )
                return
            }
        }
        UploadStatusList.setAttachmentList( inputFiles )
        setInputFiles( [] )
        uploadAttachments( inputFiles, UploadStatusList.getStatusList )
        UploadStatusList.openDialog()
        setState( null )
    }

    const uploadAttachments = async ( files, statuses ) => {
        for ( let i = 0; i < files.length; i++ ) {
            const file = files[i]
            if ( statuses[i].statusText !== "completed" ) {
                try {
                    const fileData = new FormData()
                    fileData.append( 'attachment', file.file, file.name + "." + file.ext )
                    const updatedStatus = [...statuses]
                    updatedStatus[i].statusText = "uploading"
                    UploadStatusList.setStatusList( updatedStatus )
                    await api.addAttachment( module.id, fileData, ( e ) => {
                        const updatedStatus = [...statuses]
                        updatedStatus[i].progress = e.progress * 100
                        updatedStatus[i].statusText = "uploading"
                        if ( !e.progress || e.progress === 1 ) {
                            updatedStatus[i].progress = 100
                            updatedStatus[i].statusText = "completed"
                            OnlineCourseStore.fetchCourse( course_id )
                        }
                        UploadStatusList.setStatusList( updatedStatus )
                    } )
                } catch ( err ) {
                    console.log( err )
                    if ( err.response && JSON.stringify( err.response.data ).includes( 'Invalid file format' ) )
                        toast( `Invalid file format: ${UploadStatusList.getAttachmentList[i]?.name}` )
                    const updatedStatus = [...statuses]
                    updatedStatus[i].statusText = "failed"
                    UploadStatusList.setStatusList( updatedStatus )
                }
            }
        }
    }

    const removeFile = ( index ) => {
        let newList = [...inputFiles]
        let newStatuses = [...UploadStatusList.getStatusList]
        newList.splice( index, 1 )
        newStatuses.splice( index, 1 )
        setInputFiles( newList )
        UploadStatusList.setStatusList( newStatuses )
    }

    const changeAttachmentName = ( value, index ) => {
        let updatedList = [...inputFiles]
        updatedList[index] = { ...updatedList[index], name: value }
        setInputFiles( updatedList )
    }

    const onImportChange = async ( e ) => {
        let files = []
        let statuses = []
        for ( let i = 0; i < e.target.files.length; i++ ) {
            const file = e.target.files[i]
            files.push( { file: file, module: module, ext: file.name.split( "." ).pop(), name: file.name.split( "." ).slice( 0, -1 ).join( "" ) } )
            statuses.push( { statusText: "queued", progress: 0 } )
        }
        UploadStatusList.setStatusList( [...UploadStatusList.getStatusList, ...statuses] )
        setInputFiles( [...inputFiles, ...files] )
        e.target.value = ""
    }

    const handleDragEnter = () => {
        try {
            document.getElementById( 'file-upload-container' ).classList.add( 'drag' )
        } catch ( err ) { }
    }

    const handleDragLeave = () => {
        try {
            document.getElementById( 'file-upload-container' ).classList.remove( 'drag' )
        } catch ( err ) { }
    }

    const handleDrop = () => {
        try {
            document.getElementById( 'file-upload-container' ).classList.remove( 'drag' )
        } catch ( err ) { }
    }

    return (
        <Dialog open={state ? true : false} PaperProps={{ sx: { width: "75vw", maxWidth: "600px" } }}>
            <DialogTitle sx={{ display: "flex", fontSize: "16px", borderBottom: "1px solid #d3d3d3", justifyContent: "space-between", alignItems: "center" }}>Upload chapters attachments
                <IconButton size='small' onClick={() => { setInputFiles( [] ); setState( null ) }} sx={{ textTransform: "capitalize" }}>{Icons.default.CloseIcon}</IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: "0" }}>
                <Box padding="20px">
                    <Box border="3px rgba(0,0,0,0.3) dashed" id="file-upload-container" height="200px" borderRadius="5px" position="relative">
                        <Box display="flex" justifyContent="center" gap="10px" flexDirection="column" height="100%" alignItems="center">
                            <input onDrop={handleDrop} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} multiple type="file" onChange={onImportChange} style={{ position: "absolute", cursor: "pointer", opacity: "0", top: "0", left: "0", width: "100%", height: "100%" }} />
                            <img id='file-img' width="120" src={addFileImg} alt="document" />
                            <Typography variant='subtitle2'>Drag and drop your {inputFiles.length > 0 ? "more files" : "files here"}, or <Typography fontWeight="bold" display="inline-block" sx={{ color: "primary.main", textDecoration: "underline" }}>Browse</Typography> </Typography>
                            <Typography fontSize="14px" fontWeight="500" color="textSecondary">Supports: <i>.docx, .pptx, .mp4, .webm, .mkv</i> </Typography>
                        </Box>
                    </Box>
                    {inputFiles.length > 0 && <Box marginTop="20px">
                        <Typography gutterBottom variant='h6'>Selected attachments</Typography>
                        <Box display="flex" flexWrap="wrap" gap="10px">
                            {inputFiles.map( ( att, index ) => (
                                <Box key={index}
                                    borderRadius="5px"
                                    marginBottom="5px"
                                    boxShadow="1px 1px 10px 1px #d3d3d3"
                                    width="100%"
                                    display="flex"
                                    alignItems="center"
                                    gap="5px"
                                    padding="10px"
                                >
                                    <Typography color="GrayText" fontSize="12px"> {index + 1}</Typography>
                                    {Icons.default.InsertDriveFileIcon}
                                    <Box display="flex" flexGrow={1} alignItems="center">
                                        <Box flexGrow={1}>
                                            <TextField onFocus={( e ) => e.target.select()} fullWidth sx={{ background: "white", borderRadius: "5px", padding: "5px" }} variant='standard' value={att.name} onChange={( e ) => { changeAttachmentName( e.target.value, index ) }} inputProps={{ sx: { height: "15px", fontWeight: "bold" } }} />
                                        </Box>
                                        <IconButton size='small' onClick={() => { removeFile( index ) }}>{Icons.default.CloseIcon}</IconButton>
                                    </Box>
                                </Box>
                            ) )}
                        </Box>
                        <Button variant='contained' sx={{ textTransform: "capitalize", marginTop: "20px" }} onClick={validateAndUpload}>Upload attachments</Button>
                    </Box>}
                </Box>
            </DialogContent>
        </Dialog >
    )
}
export default AddAttachmentsDialog
