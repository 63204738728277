import { Analytics, Upload, ViewList } from '@mui/icons-material'
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useCallback, useEffect, useState } from 'react'
import TableHeader from '../../common/TableHeader'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { leadingZeroFormatter } from '../../../utils/utilities'
import CustomTableColumnLabel from '../../academic-program/CustomTableColumnLabel'
import api from '../../../service/api'
import { exportAssignmentsAuditReport } from '../../../utils/auditing-export-utilities'
import NoDatImg from '../../../assets/no-data.svg'
import LineBarGraph from '../../common/LineBarGraph'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'
import CustomSwitch from '../../common/CustomSwitch'


const sortByProperty = ( data, setData, property, type, order ) => {
    let newFilteredData = [...data]
    newFilteredData.sort( ( a, b ) => {
        if ( type === "number" ) {
            if ( order === "1" )
                return ( parseInt( a[property] ) - parseInt( b[property] ) )
            else
                return ( parseInt( b[property] ) - parseInt( a[property] ) )
        } else {
            if ( order === "1" ) {
                return ( ( a[property] < b[property] ) ? -1 : 0 )
            }
            else
                return ( ( a[property] > b[property] ) ? -1 : 0 )
        }
    } )
    setData( newFilteredData )
}

const AssignmentsStats = () => {

    const [searchParams, setSearchParams] = useSearchParams( { searchText: "", month: "", selectedDepartment: "all", selectedInstitute: "all", view: "tabular", sortByName: "1", sortBySubmissionsCount: "1", sortByAssignmentCount: "1" } )
    const month = searchParams.get( 'month' ) || ""
    const view = searchParams.get( 'view' ) || "tabular"
    const searchText = searchParams.get( 'searchText' ) || ""
    const sortByName = searchParams.get( 'sortByName' ) || "1"
    const selectedInstitute = searchParams.get( 'selectedInstitute' ) || "all"
    const selectedDepartment = searchParams.get( 'selectedDepartment' ) || "all"
    const sortByAssignmentCount = searchParams.get( 'sortByAssignmentCount' ) || "1"
    const sortBySubmissionsCount = searchParams.get( 'sortBySubmissionsCount' ) || "1"

    const [loading, setLoading] = useState( true )
    const [loadingGraphData, setLoadingGraphData] = useState( true )
    const [showMonthPicker, setShowMonthPicker] = useState( false )
    const [graphData, setGraphData] = useState( [] )
    const [facultyStats, setFacultyStats] = useState( [] )
    const [statsError, setStatsError] = useState( null )
    const [graphError, setGraphError] = useState( null )
    const [sortedFacultyStats, setSortedFacultyStats] = useState( [] )
    const [showzeroassignmentsfaculties, setShowzeroassignmentsfaculties] = useState( false )


    const { palette, table } = useTheme()

    const navigate = useNavigate()

    const hasFilters = !( !month && selectedDepartment === "all" && selectedInstitute === "all" && !searchText )

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const removeAppliedFilters = () => {
        setSearchParams( prev => {
            prev.set( 'searchText', "" )
            prev.set( 'month', "" )
            prev.set( 'selectedDepartment', "all" )
            prev.set( 'selectedInstitute', "all" )
            return prev
        }, { replace: true } )
    }

    const handleFilterChange = ( e, newVal ) => {
        if ( newVal === '' || newVal === 'last30days' ) {
            setSearchParamValue( 'month', newVal )
        } else {
        }
    }

    const handleMonthChange = newDate => {
        const date = new Date( newDate )
        if ( date !== 'Invalid Date' ) {
            setSearchParamValue( 'month', `${date.getFullYear()}-${leadingZeroFormatter.format( date.getMonth() + 1 )}` )
            setShowMonthPicker( false )
        }
    }

    const searchFilterFunc = item => {
        const searchValue = new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' )
        if ( selectedDepartment !== "all" )
            return item.department_id === selectedDepartment && ( item.department?.match( searchValue ) || item.faculty_name?.match( searchValue ) || item.assignment_created_by?.match( searchValue ) )
        else
            return item.department?.match( searchValue ) || item.faculty_name?.match( searchValue ) || item.assignment_created_by?.match( searchValue )
    }

    const columns =
        [
            { id: 1, name: 'Sl. No.', minWidth: "70px", align: "center", padding: "10px 20px 10px 40px" },
            { id: 2, name: <CustomTableColumnLabel data={sortedFacultyStats} setData={setSortedFacultyStats} label="Faculty" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"faculty_name"} sortName="sortByName" sortFlag={sortByName} sortValueType="string" />, minWidth: "150px", padding: "10px 20px" },
            { id: 3, name: 'AUID', padding: "10px 20px" },
            { id: 6, name: 'DEPARTMENT', padding: "10px 20px" },
            { id: 4, align: "center", style: { align: "center" }, name: <CustomTableColumnLabel align="center" data={sortedFacultyStats} setData={setSortedFacultyStats} label="Assignments" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"count"} sortName="sortByAssignmentCount" sortFlag={sortByAssignmentCount} sortValueType="number" />, minWidth: "150px", padding: "10px 20px" },
            { id: 5, align: "center", style: { align: "center" }, name: <CustomTableColumnLabel align="center" data={sortedFacultyStats} setData={setSortedFacultyStats} label="Submissions" sortByProperty={sortByProperty} setSearchParamValue={setSearchParamValue} sortKey={"submissions_received"} sortName="sortBySubmissionsCount" sortFlag={sortBySubmissionsCount} sortValueType="number" />, minWidth: "150px", padding: "10px 40px 10px 20px" },
        ]



    const getGraphData = useCallback( async () => {
        try {
            setLoadingGraphData( true )
            const institute = selectedInstitute !== "all" ? selectedInstitute : ""
            const { data: graphDetails } = await api.audit.getAssignmentGraph( { month, institute } )
            setGraphData( graphDetails.data )
            setGraphError( null )
        } catch ( err ) {
            setGraphError( { code: err?.response?.status, message: err?.response?.data?.message || "Something went wrong while fetching the assignment graph data." } )
        } finally {
            setLoadingGraphData( false )
        }
    }, [month, selectedInstitute] )


    const getData = useCallback( async () => {
        try {
            setLoading( true )
            const { data: { data: facultyStats, zeroAssignmentFaculties = [] } } = await api.audit.getAssignmentStats( { month, showzeroassignmentsfaculties, institute: selectedInstitute !== "all" ? selectedInstitute : "" } )
            let alldata = facultyStats
            if ( showzeroassignmentsfaculties ) {
                alldata = [...zeroAssignmentFaculties, ...facultyStats]
            }
            setFacultyStats( alldata )
            setSortedFacultyStats( alldata )
            setStatsError( null )
        } catch ( err ) {
            setStatsError( { code: err?.response?.data, message: err?.response?.data?.message || "Something went wrong while fetching the assignment audit data." } )
        } finally {
            setLoading( false )
        }
    }, [month, selectedInstitute, showzeroassignmentsfaculties] )

    useEffect( () => {
        getData()
    }, [getData] )

    useEffect( () => {
        getGraphData()
    }, [getGraphData] )

    return (
        <Card sx={{ flexShrink: 0, display: "flex", flexDirection: "column" }}>
            <Box padding="20px" display="flex" gap="20px" justifyContent="space-between" >
                <Box>
                    <Typography color="primaryDark.main" fontSize="16px" variant='h6'>Assignment Statistics</Typography>
                    {month === "" && view === 'tabular' && <Box display="flex" alignItems="center" gap="10px">
                        <Typography color="primaryDark.main" fontSize="12px" variant='subtitle2'>Show faculty with zero assignments</Typography>
                        <CustomSwitch checked={showzeroassignmentsfaculties} onChange={e => setShowzeroassignmentsfaculties( e.target.checked )} />
                    </Box>}
                </Box>
                <Box position="relative" display="flex" gap="20px" alignItems="center">
                    <ToggleButtonGroup size='small' value={view} exclusive onChange={( e, newVal ) => setSearchParamValue( 'view', newVal )} >
                        <Tooltip title="Tabular view">
                            <ToggleButton value='tabular' sx={{ padding: "6px", '&.Mui-selected': { background: palette.primary.light, color: "white", '&:hover': { background: palette.primary.light } } }} size="small">
                                <ViewList fontSize='small' />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Graphical view">
                            <ToggleButton sx={{ padding: "6px", '&.Mui-selected': { background: palette.primary.light, color: "white", '&:hover': { background: palette.primary.light } } }} value='graphical' size="small">
                                <Analytics fontSize='small' />
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup size='small' onChange={handleFilterChange} exclusive value={month || ""}>
                        {view === 'tabular' && <ToggleButton size='small' sx={{ textTransform: "capitalize", padding: "4.5px 10px" }} value="">All</ToggleButton>}
                        <ToggleButton size='small' sx={{ textTransform: "capitalize", padding: "4.5px 10px" }} value="last30days">Last 30 days</ToggleButton>
                        <ToggleButton size='small' sx={{ textTransform: "capitalize", padding: "4.5px 10px" }} value="-" selected={Boolean( month.match( /^(\d{4}-\d{2})$/ ) )}>
                            {month && month !== 'last30days' ? month : "Select month"}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    open={showMonthPicker}
                                    onClose={() => setShowMonthPicker( false )}
                                    id="startdatetime"
                                    slotProps={{
                                        textField: {
                                            onClick: () => setShowMonthPicker( true )
                                        }
                                    }}
                                    slots={{
                                        openPickerButton: () => null,
                                    }}
                                    sx={{ opacity: 0, position: "absolute", inset: 0, fontSize: "14px", '& input': { padding: "5px 10px", cursor: "pointer" } }}
                                    // value={dayjs( endDateTime )}
                                    views={['year', 'month']}
                                    onMonthChange={handleMonthChange}
                                />
                            </LocalizationProvider>
                        </ToggleButton>
                    </ToggleButtonGroup>


                    <Button size="small" onClick={() => navigate( `list` )} variant="contained" disableElevation sx={{ height: "33.5px", textTransform: "capitalize", background: "#FFE0E0", border: `1px solid #FF6C8F`, color: "#FF736F", '&:hover': { background: "#FFE0E0", color: "#FF736F" } }} >View Assignments</Button>
                    <Button size="small" onClick={() => exportAssignmentsAuditReport( sortedFacultyStats, `${month || "complete"}-${selectedInstitute !== "all" ? selectedInstitute + "-" : ""}${selectedDepartment !== "all" ? selectedDepartment : "alldept"}-assignments-audit-report` )} variant="contained" disableElevation sx={{ height: "33.5px", background: `linear-gradient(200.17deg, #1CB5B5 -47.65%, #105958 167.67%)`, color: "#fff", '&:hover': { background: `linear-gradient(200.17deg, #1CB5B5 -47.65%, #105958 167.67%)`, color: "#fff" } }} endIcon={<Upload fontSize='small' />} >Export</Button>
                </Box>
            </Box >
            {!loading && view === 'tabular' && sortedFacultyStats.filter( searchFilterFunc ).length > 0 && <Box overflow="hidden" bgcolor={palette.contentBg} borderRadius="10px" margin="0 20px 20px 20px">
                {!statsError ? <TableContainer sx={{ overflow: "auto", maxHeight: "500px" }} className='custom-scrollbar' >
                    <Table stickyHeader>
                        <TableHeader columns={columns} />
                        <TableBody>
                            {sortedFacultyStats.filter( searchFilterFunc ).map( ( row, index ) => {
                                return <TableRow sx={{ background: +row.count === 0 && palette.error.light + "44" }} className='table-row' key={row.assignment_created_by}>
                                    <TableCell align={columns[0].align || "left"} sx={{ fontSize: "12px", padding: columns[0].padding || "10px 20px", color: table.color }}><strong>{index + 1}</strong></TableCell>
                                    <TableCell sx={{ fontSize: "12px", padding: columns[1].padding || "10px 20px", color: table.color, textTransform: "capitalize", '&:hover': { textDecoration: "underline" } }}><strong><Link to={`/audit/faculty/${row.assignment_created_by}`} className='default-link' >{row.faculty_name?.toLowerCase()}</Link></strong></TableCell>
                                    <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", padding: columns[2].padding || "10px 20px", color: table.color }}><strong><Link to={`/audit/faculty/${row.assignment_created_by}`} className='default-link' >{row.assignment_created_by}</Link></strong></TableCell>
                                    <TableCell align={columns[3].align || "left"} sx={{ fontSize: "12px", padding: columns[3].padding || "10px 20px", color: table.color, textTransform: "capitalize" }}><strong>{row.department?.toLowerCase()}</strong></TableCell>
                                    <TableCell align={columns[4].align || "left"} sx={{ fontSize: "12px", padding: columns[3].padding || "10px 20px", color: table.color }}><strong>{row.count}</strong></TableCell>
                                    <TableCell align={columns[5].align || "left"} sx={{ fontSize: "12px", padding: columns[4].padding || "10px 20px", color: table.color }}><strong>{row.submissions_received}</strong></TableCell>
                                </TableRow>
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer> : <TryAgainMessageBlock err={statsError.message} loading={loading} getData={getData} code={statsError.code} />}
            </Box>}
            {!loading && sortedFacultyStats.filter( searchFilterFunc ).length === 0 && <Box flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" gap="20px">
                <img src={NoDatImg} height="120px" alt="No assignmets in last 30 days" />
                <Typography textAlign="center" variant='subtitle2'>No assignments were found for applied filters!</Typography>
                {( facultyStats.length > 0 || hasFilters ) && <Button onClick={removeAppliedFilters} sx={{ textTransform: "capitalize" }} color="secondary" variant="contained" disableElevation>Remove filters</Button>}
            </Box>}
            {
                !loading && !loadingGraphData && view !== 'tabular' && sortedFacultyStats.filter( searchFilterFunc ).length > 0 &&
                <Box display="flex" flexGrow={1} minHeight="400px">
                    {!graphError ? <LineBarGraph graphTitle="" barLegend='Submissions submitted' yAxisName="Assignments" xAxisName="Date" y2AxisName="Submissions" lineLegend='Assignments created' barValuesKey="submissions" barData={graphData} dateformat={"%Y-%m-%d"} xAxisValuesKey={'date'} lineValuesKey={'assignments_created'} lineData={graphData} />
                        : <TryAgainMessageBlock err={graphError.message} loading={loadingGraphData} getData={getGraphData} code={graphError.code} />}
                </Box>
            }
            {( loading || loadingGraphData ) && <DataLoadingSpinner waitingMessage="Loading assignment audit report please..." />}
        </Card >
    )
}

export default AssignmentsStats
