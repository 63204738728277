import React from 'react'
import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
import UserStore from "./user.store"
import fileDownload from 'js-file-download'
import NotificationStore from "./notification.store"
const notification = NotificationStore
class RoomStore {

    room
    activeRoom
    auditRoom
    streamDetails
    feedback
    loading
    exam
    evaluate
    constructor () {
        this.room = []
        this.api = api
        this.activeRoom = []
        this.auditRoom = []
        this.streamDetails = { "is_stream": false, "stream_url": null, "stream_session": null, "stream_presenter": false }
        this.feedback = []
        this.loading = false
        this.exam = []
        this.evaluate = []
        makeObservable( this, {
            room: observable,
            exam: observable,
            evaluate: observable,
            loading: observable,
            auditRoom: observable,
            streamDetails: observable,
            feedback: observable,
            activeRoom: observable,
            getRoom: computed,
            getExam: computed,
            getSubmission: computed,
            getAuditRoom: computed,
            getStreamDetails: computed,
            getFeedback: computed,
            getActiveRoom: computed,
            fetchRoom: action,
            fetchAuditRoom: action,
            createRoom: action,
            startSession: action,
            fetchViewerAndPresenterRooms: action,
            joinSession: action,
            joinSessionAsModerator: action,
            endSession: action,
            endSessionByAdmin: action,
            getMyActiveSessions: action,
            setStreamDetails: action,
            submitFeedback: action,
            //getSessionClassAttendence:action,
            resetStreamDetails: action,

        } )
    }

    get getRoom() {
        return toJS( this.room )
    }

    get getExam() {
        return toJS( this.exam )
    }

    get getSubmission() {
        return toJS( this.evaluate )
    }

    get getActiveRoom() {
        return toJS( this.activeRoom )
    }

    get getAuditRoom() {
        return toJS( this.auditRoom )
    }

    get getStreamDetails() {
        return toJS( this.streamDetails )
    }

    get getFeedback() {
        return toJS( this.feedback )
    }

    resetStreamDetails = () => {
        this.streamDetails = { "is_stream": false, "stream_url": null, "stream_session": null, "stream_presenter": false }
    }

    setStreamDetails = ( bool, url, session, stream_presenter = false ) => {
        this.streamDetails = { "is_stream": bool, "stream_url": url, "stream_session": session, "stream_presenter": stream_presenter }
    }

    fetchRoom = async () => {

        try {
            const res = await this.api.getRoom()
            this.room = res["data"]["data"] ? res["data"]["data"] : []
            //console.log(res)
        } catch ( err ) {
            console.log( err )
        }

    }

    fetchAuditRoom = async ( data ) => {
        try {
            const res = await this.api.auditSession( data )
            runInAction( () => {
                this.auditRoom = res["data"]["data"]
            } )
        } catch ( err ) {
            console.log( err )
        }
    }

    fetchViewerAndPresenterRooms = async () => {

        let payload = {
            "org_code": UserStore.getUser["user_org_code"],
            "dept_code": UserStore.getUser["user_dept_code"]
        }

        try {
            const res = await this.api.getOrgRoom( payload )
            //console.log(res["data"]["data"])
            this.room = res["data"]["data"] ? res["data"]["data"] : []
            //console.log(res)
        } catch ( err ) {

        }

    }
    fetchFeedback = async ( payload ) => {
        try {
            const res = await this.api.auditFeedback( payload )
            this.feedback = res["data"]["data"] ? res["data"]["data"] : []
        } catch ( err ) {
            console.log( err )
        }
    }
    createRoom = async ( payload ) => {

        try {
            this.api.createRoom( payload )
            //console.log(res)
            this.fetchRoom()
        } catch ( err ) {
            console.log( err )
        }

    }

    startSession = async ( payload ) => {
        //console.log(payload)
        try {
            const res = await this.api.startSession( payload )
            //console.log(res);

            if ( res["data"]["status"] === true ) {
                //notification.setNotification(true, "Starting session...")
                let baseUrl = btoa( res["data"]["data"] )
                localStorage.setItem( 'active_session', baseUrl )
                localStorage.setItem( 'poll_session', JSON.stringify( payload ) )
            } else {
                notification.setNotification( true, "Unable to start session..." )
            }
            return res["data"]
        } catch ( err ) {
            notification.setNotification( true, "Something went wrong. Try again!" )
            return { 'status': false, "message": "err" }
        }
    }

    joinSession = async ( payload ) => {
        try {
            const res = await this.api.joinSession( payload )
            //console.log(res);
            if ( res["data"]["status"] === true ) {
                //notification.setNotification(true, res["data"]["message"])
                let baseUrl = btoa( res["data"]["data"] )
                localStorage.setItem( 'active_session', baseUrl )
            } else {
                notification.setNotification( true, res["data"]["message"] )
            }
            return res["data"]
        } catch ( err ) {
            console.log( err )
            return { 'status': false }
        }
    }
    //
    joinSessionAsModerator = async ( payload ) => {
        try {
            const res = await this.api.joinSessionAsModerator( payload )
            //console.log(res);
            if ( res["data"]["status"] === true ) {
                //notification.setNotification(true, res["data"]["message"])
                let baseUrl = btoa( res["data"]["data"] )
                localStorage.setItem( 'active_session', baseUrl )
                localStorage.setItem( 'poll_session', JSON.stringify( payload ) )
            } else {
                notification.setNotification( true, res["data"]["message"] )
            }
            return res["data"]
        } catch ( err ) {
            console.log( err )
            return { 'status': false }
        }
    }

    endSession = async ( payload ) => {
        try {
            const res = await this.api.endSession( payload )
            //console.log(res);
            if ( res["data"]["status"] === true ) {
                notification.setNotification( true, res["data"]["message"] )
                // set stream
                this.streamDetails = { "is_stream": false, "stream_url": null, "stream_session": null }
                this.getMyActiveSessions()
                localStorage.removeItem( 'active_session' )
                localStorage.removeItem( 'poll_session' )
            } else {
                notification.setNotification( true, res["data"]["message"] )
            }
        } catch ( err ) {
            return false

        }
    }

    endSessionByAdmin = async ( payload ) => {
        try {
            const res = await this.api.endSession( payload )
            //console.log(res);
            if ( res["data"]["status"] === true ) {
                notification.setNotification( true, res["data"]["message"] )
                // set stream
                this.streamDetails = { "is_stream": false, "stream_url": null, "stream_session": null }
                return true
            } else {
                notification.setNotification( true, res["data"]["message"] )
                return false
            }
            // return res["data"];
        } catch ( err ) {
            return false

        }
    }

    getMyActiveSessions = async () => {
        try {
            const res = await this.api.getMySession()
            //console.log(`Active Session : `,res["data"]);
            if ( res["data"]["status"] === true ) {
                this.activeRoom = res["data"]["data"]
            }
            //return res["data"];
        } catch ( err ) {
            console.log( err )

        }
    }

    submitFeedback = async ( payload ) => {
        try {
            const res = await this.api.sendFeedback( payload )

            if ( res["data"]["status"] === false ) {

                if ( res["data"]["message"].constructor === Object && res["data"]["message"]["code"] === "23505" )
                    notification.setNotification( true, "You have already submitted feedback!" )
                else
                    notification.setNotification( true, res["data"]["message"] )
            } else {
                notification.setNotification( true, res["data"]["message"] )
            }
        } catch ( error ) {
            console.log( error )
        }
    }

    submitExam = async ( payload ) => {
        try {
            const res = await this.api.submitExamAnswers( payload )
            if ( res["data"]["status"] === false ) {
                notification.setNotification( true, res["data"]["message"] )
            } else {
                notification.setNotification( true, res["data"]["message"] )
            }
        } catch ( error ) {
            notification.setNotification( true, error )
        }
    }


    getExamTeacher = async () => {
        try {
            const res = await this.api.examFetch()
            this.exam = res["data"]["data"]
        } catch ( err ) {
            console.log( `err `, err )

        }
    }

    getExamSubmission = async ( id ) => {
        try {
            const res = await this.api.fetchExamSubmission( id )
            this.evaluate = res["data"]["data"]
        } catch ( err ) {
            console.log( `err `, err )

        }
    }

    downloadExamSubmission = async ( url ) => {
        let fname = url.split( "." )[url.split( "." ).length - 2] + "." + url.split( "." )[url.split( "." ).length - 1]
        try {
            const res = await this.api.downloadAssignment( url )
            fileDownload( res.data, fname )
        } catch ( err ) {

        }
    }

    assignScore = async ( payload ) => {
        try {
            const res = await this.api.assignScore( payload )
            notification.setNotification( true, res["data"]["message"] )
            this.getExamSubmission( payload.exam_id )
        } catch ( err ) {
            notification.setNotification( true, "Error! unable to assigning score" )

        }
    }


    getSessionClassAttendence = async ( payload ) => {
        if ( payload !== null ) {
            try {
                const res = await this.api.getSessionClassAttendence( payload )
                //console.log(`Attanadence Data`, res)
                if ( res["data"]["data"] === true ) {
                    //notification.setNotification(true, res["data"]["message"])
                    alert( res["data"]["message"] )
                } else {
                    //notification.setNotification(true, res["data"]["message"])
                    alert( res["data"]["message"] )
                }
            } catch ( err ) {
                console.log( err )
                alert( "Something went wrong!" )
                //notification.setNotification(true, "Something went wrong!")
            }

        } else {
            alert( "No session details!" )
            //notification.setNotification(true, "No session details!")
        }
    }
}

// decorate( RoomStore, {
//     room: observable,
//     exam: observable,
//     evaluate: observable,
//     loading: observable,
//     auditRoom: observable,
//     streamDetails: observable,
//     feedback: observable,
//     activeRoom: observable,
//     getRoom: computed,
//     getExam: computed,
//     getSubmission: computed,
//     getAuditRoom: computed,
//     getStreamDetails: computed,
//     getFeedback: computed,
//     getActiveRoom: computed,
//     fetchRoom: action,
//     fetchAuditRoom: action,
//     createRoom: action,
//     startSession: action,
//     fetchViewerAndPresenterRooms: action,
//     joinSession: action,
//     joinSessionAsModerator: action,
//     endSession: action,
//     endSessionByAdmin: action,
//     getMyActiveSessions: action,
//     setStreamDetails: action,
//     submitFeedback: action,
//     //getSessionClassAttendence:action,
//     resetStreamDetails: action,

// } )

const RoomStoreInstance = new RoomStore()

export default RoomStoreInstance

const RoomStoreContext = React.createContext()

export const RoomStoreProvider = ( { children, store } ) => {
    return (
        <RoomStoreContext.Provider value={store}>{children}</RoomStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useRoomStore = () => React.useContext( RoomStoreContext )