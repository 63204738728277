import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import { useSubjectStore } from '../../../store/subject.store'
import noQuizImage from '../../../assets/no-quiz.svg'
import { observer } from 'mobx-react'
import CreateQuizDialog from '../../../components/exams-components/quiz-questionbank/CreateQuizDialog'
import { Icons, showDateInIndianDateFormat } from '../../../utils/utilities'
import { Link, useParams } from 'react-router-dom'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import TableHeader from '../../../components/common/TableHeader'

const columns = [
    { name: "TITLE", align: "left", padding: "10px 20px 10px 40px" },
    { name: "CREATED AT", align: "left" },
    { name: "SUBJECT", align: "left" },
    { name: "TOPIC COUNT", align: "center" },
    { name: "QUESTIONS COUNT", align: "center", padding: "10px 40px 10px 20px" },
]


const Quizes = observer( ( { fromComponent = 'subject' } ) => {

    const [loading, setLoading] = useState( true )
    const [createDialogState, setCreateDialogState] = useState( false )

    const QuizStore = useQuizAndQuestionbankStore()
    const SubjectStore = useSubjectStore()
    const { palette, table, border } = useTheme()

    const { subject_id } = useParams()

    useEffect( () => {
        const getData = async () => {
            await QuizStore.fetchQuizes( subject_id ? { subject_id: subject_id || undefined } : {} )
            setLoading( false )
        }
        getData()
    }, [QuizStore, SubjectStore, subject_id] )

    return (
        <Box padding={fromComponent !== 'subject' && "20px"} flexGrow={1} display="flex" flexDirection="column" overflow="auto">
            {createDialogState && <CreateQuizDialog fromComponent={fromComponent} subject={SubjectStore?.getSubject} state={createDialogState} setState={setCreateDialogState} />}
            <Paper sx={{ display: "flex", flexGrow: 1, flexDirection: "column", overflow: "hidden", minHeight: fromComponent !== 'subject' && "450px" }} elevation={0}>
                <Box sx={{ background: fromComponent !== 'subject' && `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)` }} display="flex" justifyContent="space-between" borderBottom={fromComponent === 'subject' && "1px solid #d3d3d3"} alignItems="center" gap="10px" padding={fromComponent === 'subject' ? "10px 20px" : "20px"} >
                    <Box>
                        <Typography variant={fromComponent !== 'subject' ? 'h5' : "h6"} color={fromComponent !== 'subject' ? 'white' : 'primaryDark.main'} fontSize={fromComponent === 'subject' && "18px"}>Quizzes</Typography>
                        {fromComponent !== 'subject' && <Typography variant="body2" color={fromComponent !== 'subject' ? 'white' : 'textSecondary'}>Manage your quizzes here</Typography>}
                    </Box>
                    {fromComponent !== 'subject' && <Button onClick={() => setCreateDialogState( true )} variant="contained" disableElevation startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} color="primary">
                        Add Quiz
                    </Button>}
                </Box>
                {!loading && QuizStore.getQuizes.length > 0 && <Box margin="20px" bgcolor={palette.contentBg} border={border[1]} borderRadius="5px" flexGrow={1} display="flex" flexDirection='column' overflow="auto" >
                    <TableContainer className='custom-scrollbar' sx={{ minWidth: "750px", height: "100%", flexGrow: 1, overflow: "auto", borderRadius: "5px" }}>
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {QuizStore.getQuizes.map( ( quiz, index ) => (
                                    <TableRow sx={{
                                        position: "relative",
                                        '&::after': {
                                            content: `''`,
                                            background: "#d3d3d3",
                                            position: "absolute",
                                            height: "1px",
                                            width: "calc(100% - 40px)",
                                            bottom: 0,
                                            left: "50%",
                                            transform: "translateX(-50%)"
                                        },

                                        "&:hover": {
                                            background: palette.rowHover
                                        },
                                        cursor: "pointer"
                                    }} key={quiz.id}>
                                        <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[0].align || 'left'} >
                                            <Link className='default-link' to={`${quiz.id}`}>
                                                <Box padding={columns[0].padding || "10px 40px"} display="flex" alignItems="center" gap="10px">
                                                    {/* <Box width="20px" height="20px">
                                                        <img width="100%" height="100%" style={{ objectFit: "contain" }} src={QuizStore.getDefaultThumbs[`default-${( index % 3 ) + 1}`]} alt="Thumb" />
                                                    </Box> */}
                                                    <strong>
                                                        {quiz.title}
                                                    </strong>
                                                </Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ border: "none", color: table.color, padding: "0" }}  >
                                            <Link className='default-link' to={`${quiz.id}`}>
                                                <Box padding="10px 20px">
                                                    <strong>
                                                        {showDateInIndianDateFormat( quiz.created_at )}
                                                    </strong>
                                                </Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ border: "none", color: table.color, padding: "0" }}  >
                                            <Link className='default-link' to={`${quiz.id}`}>
                                                <Box padding="10px 20px">
                                                    <strong>
                                                        {quiz.subject.subject_name}
                                                    </strong>
                                                </Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[3].align || 'left'} >
                                            <Link className='default-link' to={`${quiz.id}`}>
                                                <Box padding="10px 20px">
                                                    <strong>
                                                        {quiz?.question_bank_topics?.length}
                                                    </strong>
                                                </Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[4].align || 'left'} >
                                            <Link className='default-link' to={`${quiz.id}`}>
                                                <Box padding={columns[4].padding || "10px 20px"}>
                                                    <strong>
                                                        {quiz.number_of_question}
                                                    </strong>
                                                </Box>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>}
                {!loading && QuizStore.getQuizes.length === 0 && <Box bgcolor={palette.contentBg} flexGrow={1} display="flex" flexDirection="column" alignItems="center" padding="20px" justifyContent="center" gap="20px">
                    <img src={noQuizImage} width="150px" alt="No quizes" />
                    <Typography variant="subtitle2">You haven't created any quizes!</Typography>
                    <Button onClick={() => setCreateDialogState( true )} startIcon={Icons.default.AddIcon} size="small" variant='outlined' color="primaryDark" sx={{ textTransform: "capitalize" }} >Create one</Button>
                </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading quizes..." />}
            </Paper>
        </Box>
    )
} )

export default Quizes
