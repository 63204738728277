import { Box, Button, Collapse, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CustomSearchBar from '../../../components/common/CustomSearchBar'
import AddRule from '../../../components/admin/academic-program/AddRule'
import { useOrganizationStore } from '../../../store/organization.store'
import selectInstituteImg from '../../../assets/selectinstitute.svg'
import BlockMessage from '../../../components/common/BlockMessage'
import { useUserStore } from '../../../store/user.store'
import noRulesImg from '../../../assets/no-rules.svg'
import { DoNotDisturb } from '@mui/icons-material'
import { Icons } from '../../../utils/utilities'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { useCallback, useEffect } from 'react'
import { useState } from 'react'
import React from 'react'

const columns = [
    { label: "Department", minWidth: "80px", maxWidth: "100px" },
    { label: "Academic year", minWidth: "80px", maxWidth: "100px" },
    { label: "Rule for", minWidth: "80px", maxWidth: "100px" },
]

const AccordionRow = ( { departmentRules, department } ) => {

    const [expandState, setExpandState] = useState( false )
    const { palette } = useTheme()
    const navigate = useNavigate()

    return (
        <Box sx={{ marginBottom: "1px", "&:hover": { boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)", borderColor: "#d3d3d3" } }} boxShadow={expandState && "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"} border="1ps solid #fff" borderBottom="1px solid #d3d3d3">
            <Box bgcolor={expandState && palette.rowHover} sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }} border="1px solid #d3d3d3" padding="10px 20px" onClick={() => setExpandState( !expandState )} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant='subtitle2'>{department}</Typography>
                <Typography textAlign="right" variant="body2" fontStyle="italic" display="flex" gap="10px" alignItems="center">
                    {departmentRules.length} rule{departmentRules.length > 1 ? 's' : ''}
                    {expandState ? Icons.default.KeyboardArrowUp : Icons.default.KeyboardArrowDownIcon}
                </Typography>
            </Box>
            <Collapse in={expandState}>
                {departmentRules.length > 0 && <Box padding="0">
                    {departmentRules.map( rule => (
                        <Box key={rule.id} border="1px solid #d3d3d3" padding="10px 20px">
                            <Typography onClick={() => navigate( `/admin/academic-rule/${rule.id}/checklist` )} width="fit-content" fontSize="14px" fontWeight="500" sx={{ cursor: "pointer", textDecoration: "underline", "&:hover": { textDecoration: "none" } }} >{rule.academic_year}  (Rule for: {rule.session_type === "year" ? "Year" : "Semesters"})</Typography>
                        </Box>
                    ) )}
                </Box>}
            </Collapse>
        </Box >
    )
}

const InstituteAcademicRules = () => {

    const [departmentsAndRules, setDepartmentsAndRules] = useState( [] )
    const [addDialogState, setAddDialogState] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )
    const [loading, setLoading] = useState( true )
    const [allRules, setAllRules] = useState( [] )

    const { institute_id } = useParams()

    const OrganizationStore = useOrganizationStore()
    const UserStore = useUserStore()
    const { palette } = useTheme()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams( { viewType: 'accordion', searchText: '', instituteCode: "-" } )
    const viewType = searchParams.get( 'viewType' )
    const searchText = searchParams.get( 'searchText' )
    const instituteCode = searchParams.get( 'instituteCode' )

    const setViewType = ( val ) => {
        setSearchParams( prev => {
            prev.set( 'viewType', val )
            return prev
        }, { replace: true } )
    }
    const setSearchText = ( val ) => {
        setSearchParams( prev => {
            prev.set( 'searchText', val )
            return prev
        }, { replace: true } )
    }
    const setInstituteCode = ( val ) => {
        setSearchParams( prev => {
            prev.set( 'instituteCode', val )
            return prev
        }, { replace: true } )
    }

    const handleInstituteChange = async ( e ) => {
        setInstituteCode( e.target.value )
        await getData( e.target.value )
    }

    const getData = useCallback( async ( institute_id = instituteCode ) => {
        try {
            setLoading( true )
            if ( OrganizationStore.getOrganization.length === 0 ) {
                await OrganizationStore.fetchOrganization()
            }
            if ( institute_id !== "-" ) {
                const { data } = await api.getInstituteAcademicRuleChecklist( institute_id )
                setDepartmentsAndRules( data.data )
                let allRules = []
                for ( let i = 0; i < data.data.length; i++ ) {
                    const deptRules = data.data[i].rules
                    allRules = [...allRules, ...deptRules]
                }
                setAllRules( allRules )
            }
        } catch ( err ) {
            console.log( err )
            if ( err.response )
                toast( err.response?.data?.message || "Error occured while fetching rules!" )
            else
                toast( "Error occured while fetching rules!" )
        } finally {
            setLoading( false )
        }
    }, [OrganizationStore, instituteCode] )

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.academics ) {
            setHasPermission( false )
        } else
            getData()
    }, [getData, UserStore] )

    return (
        hasPermission ? <Box flexGrow={1} padding="20px" display="flex" overflow="auto" flexDirection="column" height="100%">
            <AddRule dialogState={addDialogState} setDialogState={setAddDialogState} getRules={getData} />
            <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> Academic program {Icons.small.ChevronRightIcon} Rules </Typography>
            <Paper sx={{ minHeight: !loading && "450px", display: "flex", flexGrow: !loading && departmentsAndRules.length > 0 ? "1" : "0", flexDirection: "column" }}>
                <Box padding="20px" display="flex" gap="20px" flexDirection={{ xs: "column", md: "row" }} justifyContent={{ xs: "stretch", md: "space-between" }} borderBottom="1px solid #d3d3d3">
                    <Box>
                        <Typography variant='h5'>Academic program for {institute_id}</Typography>
                        <Typography variant='subtitle2' color="textSecondary">Manage academic rules under {institute_id}</Typography>
                    </Box>
                    <Box display="flex" gap="20px" justifyContent="stretch" flexWrap="wrap" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Tooltip title="Group by deparment">
                                <IconButton size='small' color={viewType === 'accordion' ? "primary" : "GrayText"} onClick={() => setViewType( "accordion" )}>
                                    {Icons.default.SegmentView}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Flat tabular view">
                                <IconButton size='small' color={viewType === 'flat' ? "primary" : "GrayText"} onClick={() => setViewType( "flat" )}>
                                    {Icons.default.ViewListIcon}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Select sx={{ flexGrow: 1 }} size="small" onChange={handleInstituteChange} variant='outlined' value={instituteCode}>
                            <MenuItem value="-" disabled dense sx={{ fontSize: "14px", textTransform: "capitalize" }}>Select institute</MenuItem>
                            {OrganizationStore.getOrganization.map( inst => (
                                <MenuItem dense key={inst.institute_name_short} sx={{ fontSize: "14px", textTransform: "capitalize" }} value={inst.institute_name_short}>{inst.institute_name_short}</MenuItem>
                            ) )}
                        </Select>
                        <Button variant="contained" disableElevation sx={{ textTransform: "capitalize" }} onClick={() => setAddDialogState( true )} startIcon={Icons.default.AddIcon} >Create</Button>
                    </Box>
                </Box>
                {loading && <DataLoadingSpinner waitingMessage={`Loading ${institute_id} academic rules`} />}
                {!loading && departmentsAndRules.length > 0 && viewType === "accordion" && <Box flexGrow="1" className='custom-scrollbar' display="flex" flexDirection="column" overflow="auto">
                    <Box padding="10px 20px" display="flex" alignItems="center" justifyContent="space-between" bgcolor="primary.main">
                        <Typography variant="subtitle2" color="white">Rules grouped by departments</Typography>
                        {/* <TextField value={searchText} onChange={( e ) => { setSearchText( e.target.value ) }} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", "@media(max-width:900px)": { width: "100%" } }, startAdornment: <Search sx={{ marginX: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#fff", outline: "none", borderRadius: "5px", height: "40px", color: "black", width: "200px", "@media(max-width:900px)": { width: "100%" } }} placeholder="Search Dept." variant="standard" type="text" /> */}
                        <CustomSearchBar value={searchText} onChange={( e ) => { setSearchText( e.target.value ) }} placeholder='Search departments...' />
                    </Box>
                    {departmentsAndRules.filter( item => item.department_code.toLowerCase().includes( searchText.toLowerCase() ) ).map( deptDetails => (
                        <AccordionRow key={deptDetails.department_code} department={deptDetails.department_code} departmentRules={deptDetails.rules} />
                    ) )}
                    {departmentsAndRules.filter( item => item.department_code.toLowerCase().includes( searchText.toLowerCase() ) ).length === 0 && <Box flexGrow={1} justifyContent="center" alignItems="center" display="flex" flexDirection="column" gap="10px" padding="20px" bgcolor="#eee">
                        <Typography variant="subtitle2">No rules defined for department <b>{searchText}</b></Typography>
                        <Button variant="outlined" onClick={() => setSearchText( '' )} color="secondary" sx={{ textTransform: "capitalize" }} startIcon={<DoNotDisturb />} >Clear filters</Button>
                    </Box>}
                </Box>}
                {!loading && departmentsAndRules.length > 0 && viewType === "flat" && <Box flexGrow="1" overflow="hidden">
                    <TableContainer className="custom-scrollbar" sx={{ height: "100%", position: "relative" }}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ position: "sticky", top: "0" }}>
                                    {columns.map( col => (
                                        <TableCell align={col.align} key={col.label} sx={{ paddingY: "10px", minWidth: col.minWidth, maxWidth: col.maxWidth, background: palette.primary.main, color: "#fff" }} >{col.label}</TableCell>
                                    ) )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allRules.map( rule => (
                                    <TableRow onClick={() => navigate( `/admin/academic-rule/${rule.id}/checklist` )} sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }} key={rule.id}>
                                        <TableCell sx={{ paddingY: "10px", fontWeight: "600" }} >{rule.department_code}</TableCell>
                                        <TableCell sx={{ paddingY: "10px" }} >{rule.academic_year}</TableCell>
                                        <TableCell sx={{ paddingY: "10px" }} >{rule.session_type}</TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {!loading && instituteCode !== "-" && departmentsAndRules.length === 0 &&
                    <Box flexGrow={1} alignItems="center" justifyContent="center" padding="20px" bgcolor="#eee" display="flex" flexDirection="column" gap="10px">
                        <img src={noRulesImg} width="200px" alt="select institute" />
                        <Typography variant="subtitle2" color="textSecondary">No rules for {instituteCode}</Typography>
                    </Box>
                }
                {!loading && instituteCode === "-" && <Box flexGrow={1} alignItems="center" justifyContent="center" padding="20px" bgcolor="#eee" display="flex" flexDirection="column" gap="10px">
                    <img src={selectInstituteImg} height="170px" alt="select institute" />
                    <Typography variant='subtitle2' color="textSecondary">Select institute to browse rules</Typography>
                </Box>}
            </Paper>
        </Box>
            :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
}

export default InstituteAcademicRules
