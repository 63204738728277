import { Box, Card, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDiscussionForum } from '../../store/discussionforum.store'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { observer } from 'mobx-react'
import { chipColors, getTimePassedFrom } from '../../utils/utilities'
import { CommentOutlined, ThumbsUpDownOutlined, VisibilityOutlined } from '@mui/icons-material'
import noDiscussionsImg from '../../assets/no-discussions.svg'
import { Link } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import layer1 from '../../assets/card-images/discussionscard/layer1.svg'
import layer2 from '../../assets/card-images/discussionscard/layer2.svg'

const DiscussionsCard = observer( () => {

    const [loading, setLoading] = useState( true )
    const DiscussionForumStore = useDiscussionForum()

    const UserStore = useUserStore()


    useEffect( () => {
        const getData = async () => {
            await DiscussionForumStore.fetchDiscussions( {} )
            setLoading( false )
        }
        getData()
    }, [DiscussionForumStore] )

    return (
        <Card elevation={1} sx={{
            flexGrow: 1,
            background: "#000",
            position: "relative",
        }} >
            <Box position="absolute" sx={{ inset: "0", zIndex: 1 }} ><img width="100%" height="100%" src={layer1} alt="layer1" /></Box>
            <Box position="absolute" sx={{ inset: "0", zIndex: 1 }} ><img width="100%" height="100%" src={layer2} alt="layer2" /></Box>
            <Box height="100%" display="flex" flexDirection="column" position="relative" sx={{ zIndex: 10 }}>
                <Box maxWidth="750px" display="flex" flexGrow={1} flexDirection="column" minHeight="300px" padding="20px">
                    <Box height="30px" display="flex" justifyContent="space-between" alignItems="center">
                        <Link className='default-link' to={UserStore.getUser.user_role === "STUDENT" ? `/student/discussions` : `/faculty/discussions`}>
                            <Typography fontSize="20px" fontWeight="700" color="#fff">
                                Discussions
                            </Typography>
                        </Link>
                    </Box>
                    {DiscussionForumStore.getDiscussions.length > 0 && <Box width="100%" marginTop="10px" flexGrow={1} display="flex" flexDirection="column" overflow="auto" gap="10px">
                        {DiscussionForumStore.getDiscussions
                            .slice( 0, 3 )
                            .map( ( discussion, index ) => {
                                return (
                                    <Link key={discussion._id} className='default-link' to={UserStore.getUser.user_role === "STUDENT" ? `/student/discussions/${discussion._id}` : `/faculty/discussions/${discussion._id}`}>
                                        <Box padding="10px" borderRadius="10px" border={`1px solid #FFFFFF33`} bgcolor="#FBFBFB14">
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Box>
                                                    <Typography color="#fff" maxWidth="220px" noWrap fontSize="14px" fontWeight={400} alignItems="center">
                                                        {discussion.access_groups.map( ( sub, subIndex ) => sub.subject_name !== "all" ? (
                                                            <span key={sub.subject_id} >{sub.subject_name === "all" ? "Public discussions" : sub.subject_name} {subIndex !== discussion.access_groups.length - 1 && ", "}</span>
                                                        ) : <span>Global discussion</span> )}
                                                    </Typography>
                                                    <Typography fontSize="12px" fontWeight="300" color="#fff">@{discussion.creator_data.name}</Typography>
                                                </Box>
                                                <Typography fontSize="12px" fontWeight="300" color="#fff">{getTimePassedFrom( discussion.created_at )}</Typography>
                                            </Box>
                                            <Typography variant='h6' fontWeight="500" marginY="10px" fontSize="18px" color="#fff" lineHeight="20px">{discussion.title}</Typography>
                                            <Box display="flex" flexWrap="wrap" marginY="10px" gap="10px" alignItems="center">
                                                {discussion.categories.map( ( category, index ) => {
                                                    const chipColor = chipColors[index % chipColors.length]
                                                    return (
                                                        <Box key={category} fontSize="10px" padding="5px" borderRadius="5px" sx={{ cursor: "pointer", "&:hover": { background: `${chipColor}88` } }} bgcolor={chipColor} color="#fff">{category}</Box>
                                                    )
                                                } )}
                                            </Box>
                                            <Box marginTop="10px" display="flex" justifyContent="space-between" color="#fff" gap="20px" alignItems="center">
                                                <Tooltip title="Views">
                                                    <Typography display="flex" gap="5px" alignItems="center" variant="subtitle2">{discussion.views} <VisibilityOutlined sx={{ fontSize: "15px" }} /></Typography>
                                                </Tooltip>
                                                <Tooltip title="Votes">
                                                    <Typography display="flex" gap="5px" alignItems="center" variant="subtitle2">{discussion.votes.up - discussion.votes.down} <ThumbsUpDownOutlined sx={{ fontSize: "15px" }} /></Typography>
                                                </Tooltip>
                                                <Tooltip title="Comments" >
                                                    <Typography display="flex" gap="5px" alignItems="center" variant="subtitle2">{discussion.comment_count} <CommentOutlined sx={{ fontSize: "15px" }} /> </Typography>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </Link>
                                )
                            } )}
                    </Box>}
                    {!loading && DiscussionForumStore.getDiscussions.length === 0 && <Box padding="20px" display="flex" alignItems="center" justifyContent="center" flexGrow={1} flexDirection="column" gap="10px">
                        <img width="150px" src={noDiscussionsImg} alt="No discussions" />
                        <Typography variant='subtitle2' color="white">No discussions</Typography>

                    </Box>}
                    {loading && <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
                        <DataLoadingSpinner />
                    </Box>}
                </Box>
            </Box>
        </Card>
    )
} )

export default DiscussionsCard
