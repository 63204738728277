import { Box, Button, FormControl, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { useUserStore } from '../../store/user.store'
import { Icons } from '../../utils/utilities'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

const Password = () => {

    const userStore = useUserStore()

    const [showPassword, setShowPassword] = useState( false )
    const [password, setPassword] = useState( '' )

    const save = async () => {
        const pattern = ``
        // const pattern = `^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,32}$`
        if ( password.match( [pattern] ) ) {
            let payload = {
                "password": password
            }
            await userStore.updatePassword( payload )
            toast.success( "Password changed successfully!" )
            setPassword( "" )
        } else {
            toast.error( "Please provide a strong password" )
        }
    }

    return (
        <Box padding="20px" flexGrow={1} >
            <Paper>
                <Box borderBottom="1px solid #d3d3d3" padding="20px">
                    <Typography variant='h5'>Change Password</Typography>
                    <Typography color="textSecondary" variant='subtitle2'>Change this account password here</Typography>
                </Box>
                <Box display="flex" gap="20px" flexDirection="column" padding="20px">
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            label="New password"
                            sx={{ width: "100%" }}
                            id="filled-adornment-password"
                            onChange={( e ) => setPassword( e.target.value )}
                            type={!showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    < InputAdornment position="end" >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword( !showPassword )}
                                            onMouseDown={( e ) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? Icons.default.VisibilityOffIcon : Icons.default.VisibilityIcon}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </FormControl>
                    <Button sx={{ textTransform: "capitalize", width: "fit-content" }} onClick={save} disableElevation variant='contained'>Save new password</Button>
                </Box>
            </Paper>
        </Box>
    )
}

export default Password
