import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ExamNotfound = () => {

    const navigate = useNavigate()

    return (
        // <Layout>
        <Box display="flex" height="100%" alignItems="center" justifyContent="center">
            <Box bgcolor="#eee" padding="20px" borderRadius="5px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" minWidth="220px" width="300px" height="300px">
                <Typography textAlign="center" variant="h4" color="secondary">Exam you are looking for doesn't exists!</Typography>
                <Button onClick={() => navigate( -1 )} variant="outlined" sx={{ textTransform: "capitalize", marginTop: "20px" }}>Back</Button>
            </Box>
        </Box>
        // </Layout>
    )
}

export default ExamNotfound
