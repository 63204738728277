import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import CustomSwitch from '../../common/CustomSwitch'
import { Icons } from '../../../utils/utilities'
import api from '../../../service/api'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

const AddChecklist = ( { state, setState, getItems, rule } ) => {

    const [adding, setAdding] = useState( false )
    const { id } = useParams()

    const initialValues = {
        session_number: 0,
        input_type: 'text',
        input_label: '',
        input_required: false,
        has_attachment: false,
        attachment_label: '',
        attachment_required: false,
        attachment_file_size_limit: 0,
        attachment_allowed_file_extentions: '',
        is_required: true
    }

    const validationSchema = Yup.object().shape( {
        session_number: Yup.number().min( 1, "Value less than minimum value" ).test( "SESSION_MAX_VALUE", "Value exceeds maximum limit", ( val, { parent: formData } ) => {
            return rule.session_type === 'year' ? val > 5 ? false : true : val > 10 ? false : true
        } ),
        input_label: Yup.string().required( "Input label is required" ),
        has_attachment: Yup.boolean().test( "CHECK_FOR_AT_LEAST_ONE_INPUT_TYPE", "Atleast one of attachment or textual input should be choosen", ( val, { parent: formData } ) => {
            if ( formData.input_type === "boolean" || ( val === true || formData.input_required === true ) ) return true
            else return false
        } ),
        attachment_label: Yup.string().test( "CHECK_FOR_ATTACHMENT_LABEL", "Attachment label is required", ( val, { parent: formData } ) => {
            if ( formData.has_attachment )
                if ( !val || val.trim() === '' ) return false
                else return true
            return true
        } ),
        attachment_file_size_limit: Yup.number().test( "CHECK_FOR_ATTACHMENT_FILE_SIZE", "Attachment size limit is required", ( val, { parent: formData } ) => {
            if ( formData.has_attachment ) {
                try {
                    if ( !val || parseInt( val ) < 1 ) return false
                    else return true
                } catch ( err ) {
                    return false
                }
            }
            return true
        } ),
        attachment_allowed_file_extentions: Yup.string().test( "CHECK_FOR_ATTACHMENT_FILE_EXTENTIONS", "Attachment allowed extentions should be defined", ( val, { parent: formData } ) => {
            if ( formData.has_attachment ) {
                try {
                    if ( !val || val.trim() === '' ) return false
                    else return true
                } catch ( err ) {
                    return false
                }
            }
            return true
        } ).test( "CHECK_FOR_ATTACHMENT_FILE_EXTENTIONS_FORMAT", "Invalid extention format", ( val ) => {
            if ( val ) {
                let exts = val.split( "," ).filter( ext => ext.trim() !== "" )
                for ( let i = 0; i < exts.length; i++ ) {
                    const ext = exts[i].trim()
                    if ( !ext.match( /^(\.[a-zA-Z]+)$/ ) )
                        return false
                }
            } return true
        } )
    } )

    const addChecklist = async ( values, { resetForm } ) => {
        try {
            setAdding( true )
            await api.createAcademicRuleChecklist( id, values )
            await getItems()
            resetForm()
            setState( false )
        } catch ( err ) {
            if ( err.response )
                toast( err.response?.data?.message || "Error occured while adding new rule!" )
            else
                toast( "Error occured while adding new rule!" )
        } finally {
            setAdding( false )
        }
    }

    return (
        <Dialog PaperProps={{ sx: { maxWidth: "650px", width: "90%" } }} open={state}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>Add new checklist</DialogTitle>
            <DialogContent>
                <Formik onSubmit={addChecklist} initialValues={initialValues} validationSchema={validationSchema} >
                    {( { values, setFieldValue } ) => (
                        <Form>
                            <FormControl fullWidth margin="normal">
                                <Field onWheel={e => e.target.blur()} as={TextField} name="session_number" type="number" InputLabelProps={{ sx: { textTransform: "capitalize" } }} label={`${rule.session_type} number *`} />
                                <Typography fontSize="12px" fontWeight="500" color="error.dark"  > <ErrorMessage name="session_number" /> </Typography>
                            </FormControl>
                            <Box>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Input Type *</InputLabel>
                                    <Field as={Select} name="input_type" label="Input Type *" >
                                        <MenuItem dense sx={{ fontSize: "14px" }} value="boolean">Boolean</MenuItem>
                                        <MenuItem dense sx={{ fontSize: "14px" }} value="number">Number</MenuItem>
                                        <MenuItem dense sx={{ fontSize: "14px" }} value="text">Text</MenuItem>
                                    </Field>
                                    <Typography fontSize="12px" fontWeight="500" color="error.dark"  > <ErrorMessage name="input_type" /> </Typography>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <Field as={TextField} name="input_label" type="text" label="Name *" />
                                    <Typography fontSize="12px" fontWeight="500" color="error.dark"  > <ErrorMessage name="input_label" /> </Typography>
                                </FormControl>
                            </Box>
                            <FormControl fullWidth>
                                <FormControlLabel checked={values.input_required} onChange={e => setFieldValue( 'input_required', e.target.checked )} sx={{ justifyContent: "space-between", margin: "0" }} name='input_required' label="Text input required?" labelPlacement='start' control={<CustomSwitch />} />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormControlLabel checked={values.has_attachment} onChange={e => setFieldValue( 'has_attachment', e.target.checked )} sx={{ justifyContent: "space-between", margin: "0" }} name='has_attachment' label="Receive attachment?" labelPlacement='start' control={<CustomSwitch />} />
                                <Typography fontSize="12px" fontWeight="500" color="error.dark"  > <ErrorMessage name="has_attachment" /> </Typography>
                            </FormControl>
                            {values.has_attachment && <Box>
                                <FormControl fullWidth margin="normal">
                                    <Field as={TextField} name="attachment_label" type="text" label="Attachment label *" />
                                    <Typography fontSize="12px" fontWeight="500" color="error.dark"  > <ErrorMessage name="attachment_label" /> </Typography>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <Field InputProps={{ endAdornment: <Typography marginLeft="10px" fontSize="14px" fontWeight="600" color="GrayText">MB</Typography> }} onWheel={e => e.target.blur()} as={TextField} name="attachment_file_size_limit" type="number" label="Attachment size limit *" />
                                    <Typography fontSize="12px" fontWeight="500" color="error.dark"  > <ErrorMessage name="attachment_file_size_limit" /> </Typography>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <Field as={TextField} name="attachment_allowed_file_extentions" type="text" label={<span>Allowed file extentions(<i><b>EX. Format: .pdf, .docx</b></i> ) *</span>} />
                                    {values.attachment_allowed_file_extentions && <Typography marginTop="10px" fontSize="14px" fontWeight="500" color="GrayText" display="flex" flexWrap="wrap" gap="10px"> {values.attachment_allowed_file_extentions.split( "," ).map( ( ext, index ) => {
                                        if ( ext.trim() !== '' )
                                            return <span style={{ padding: "5px", border: "1px solid #d3d3d3" }} key={index}>{ext}</span>
                                        else
                                            return ''
                                    } )} </Typography>}
                                    <Typography fontSize="12px" fontWeight="500" color="error.dark"  > <ErrorMessage name="attachment_allowed_file_extentions" /> </Typography>
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormControlLabel checked={values.attachment_required} onChange={e => setFieldValue( 'attachment_required', e.target.checked )} sx={{ justifyContent: "space-between", margin: "0" }} name='attachment_required' label="Is Attachment Required?" labelPlacement='start' control={<CustomSwitch />} />
                                </FormControl>
                            </Box>}
                            <FormControl fullWidth>
                                <FormControlLabel checked={values.is_required} onChange={e => setFieldValue( 'is_required', e.target.checked )} sx={{ justifyContent: "space-between", margin: "0" }} name='is_required' label="Is Required?" labelPlacement='start' control={<CustomSwitch />} />
                            </FormControl>
                            <Box display="flex" gap="10px" marginTop="10px">
                                <Button disabled={adding} type='submit' disableElevation startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} color="primary" sx={{ textTransform: "capitalize" }} variant="contained">{adding ? "Adding new checklist item..." : "Add rule checklist"}</Button>
                                <Button disabled={adding} onClick={() => setState( false )} startIcon={Icons.default.CloseIcon} color="error" sx={{ textTransform: "capitalize" }} variant="outlined">Cancel</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default AddChecklist
