import { formatVideoDuration } from "./utilities"


// Actions
export const togglePlay = async ( videoPlayer ) => {
    try {
        videoPlayer.paused ? await videoPlayer.play() : await videoPlayer.pause()
    } catch ( err ) { }
}

export const toggleMute = ( videoPlayer, setVolume ) => {
    videoPlayer.muted = !videoPlayer.muted
    setVolume( videoPlayer.muted ? 0 : videoPlayer.volume )
}


export const handleVolumeSlide = ( event, setVolume, player ) => {
    try {
        setVolume( event.target.value )
        player.volume = event.target.value
        player.muted = event.target.value === "0"
    } catch ( err ) { }
}

export const handlePlay = ( setIsPaused, controls, setShowReplayBtn ) => {
    setIsPaused( false )
    controls?.classList.remove( 'paused' )
    setShowReplayBtn( false )
}

export const handleOnCanPlay = async ( event, setIsBuffering, videoPlayer ) => {
    setIsBuffering( false )
    try {
        if ( videoPlayer && videoPlayer.paused && event.target.autoplay )
            await videoPlayer.play()
    } catch ( err ) { }
}

export const handleMouseEnter = ( event, controls, overlayDisappearInterval ) => {
    if ( overlayDisappearInterval ) {
        clearInterval( overlayDisappearInterval )
        overlayDisappearInterval = null
    }
    if ( controls ) {
        controls?.classList.add( 'hovered' )
        if ( event.type !== "mouseenter" ) {
            overlayDisappearInterval = setTimeout( () => {
                controls?.classList.remove( 'hovered' )
            }, 5000 )
        }
    }
    return overlayDisappearInterval
}

export const handleMouseLeave = ( controls ) => {
    if ( controls ) {
        setTimeout( () => {
            controls?.classList.remove( 'hovered' )
        }, 500 )
    }
}

export const handlePause = ( setIsPaused, controls ) => {
    setIsPaused( true )
    controls?.classList.add( 'paused' )
}

export const handleVolumeChange = ( setVolume, videoPlayer ) => {
    setVolume( videoPlayer.muted ? 0 : videoPlayer.volume )
}

export const handleVideoBuffer = ( videoPlayer, timelineContainer ) => {
    if ( videoPlayer ) {
        try {
            let range = 0
            const bf = videoPlayer.buffered
            const time = videoPlayer.currentTime
            while ( !( bf.start( range ) <= time && time <= bf.end( range ) ) ) {
                range += 1
            }
            const loadStartPercentage = bf.start( range ) / videoPlayer.duration
            const loadEndPercentage = bf.end( range ) / videoPlayer.duration
            const loadPercentage = loadEndPercentage - loadStartPercentage
            timelineContainer.style.setProperty( "--buffer-position", loadPercentage )
        } catch ( err ) { }
    }
}

export const handleVideoDataLoad = ( setDurationData, videoPlayer, timelineContainer ) => {
    const timeData = `${formatVideoDuration( videoPlayer.currentTime )} / ${formatVideoDuration( videoPlayer.duration )}`
    setDurationData( timeData )
    const percent = videoPlayer.currentTime / videoPlayer.duration
    timelineContainer.style.setProperty( "--progress-position", percent )
}

export const handleOnlineCourseVideoDataLoad = ( setDurationData, videoPlayer, timelineContainer, progressDetails, updateProgressDetail, videoStartTime ) => {
    if ( progressDetails && progressDetails?.status === "NOTSTARTED" )
        updateProgressDetail( progressDetails.id, { status: "INPROGRESS" } )
    if ( videoStartTime ) videoPlayer.currentTime = videoStartTime
    setDurationData( `${formatVideoDuration( videoPlayer.currentTime )} / ${formatVideoDuration( videoPlayer.duration )}` )
    const percent = videoPlayer.currentTime / videoPlayer.duration
    timelineContainer?.style.setProperty( "--progress-position", percent )
}

export const handleVideoData = ( setDurationData, videoPlayer, timelineContainer ) => {
    const timeData = `${formatVideoDuration( videoPlayer.currentTime )} / ${formatVideoDuration( videoPlayer.duration )}`
    setDurationData( timeData )
    const percent = videoPlayer.currentTime / videoPlayer.duration
    timelineContainer.style.setProperty( "--progress-position", percent )
}

// Seeking
export const handleMouseOnTimeline = ( event, isSeeking, timelineContainer ) => {
    const boundary = timelineContainer.getBoundingClientRect()
    const percent = Math.min( Math.max( 0, event.clientX - boundary.x ), boundary.width ) / boundary.width
    timelineContainer.style.setProperty( "--preview-position", percent )
    if ( isSeeking ) {
        event.preventDefault()
        timelineContainer.style.setProperty( "--progress-position", percent )
    }
}

export const toggleSeeking = async ( event, videoPlayer, videoContainer, timelineContainer, isSeeking, wasPaused ) => {
    const boundary = await timelineContainer.getBoundingClientRect()
    const percent = Math.min( Math.max( 0, event.clientX - boundary.x ), boundary.width ) / boundary.width
    isSeeking = ( event.buttons & 1 ) === 1
    videoContainer?.classList.toggle( 'scrubbing', isSeeking )
    if ( isSeeking ) {
        wasPaused = videoPlayer.paused
        try {
            await videoPlayer.pause()
        } catch ( err ) { }
    } else {
        try {
            videoPlayer.currentTime = percent * videoPlayer.duration
            if ( !wasPaused )
                await videoPlayer.play()
        } catch ( err ) { }
    }
    handleMouseOnTimeline( event, isSeeking, timelineContainer )
    return { isSeeking, wasPaused }
}

export const handleSeekingAround = ( event, isSeeking, videoPlayer, videoContainer, timelineContainer, wasPaused ) => {
    if ( isSeeking ) {
        return toggleSeeking( event, videoPlayer, videoContainer, timelineContainer, isSeeking, wasPaused )
    }
    return { wasPaused, isSeeking }
}

export const seekPlusTen = ( videoPlayer ) => {
    if ( videoPlayer ) {
        let newTime = videoPlayer.currentTime + 10
        newTime >= videoPlayer.duration ? videoPlayer.currentTime = videoPlayer.duration - 2 : videoPlayer.currentTime = newTime
    }
}

export const seekMinusTen = ( videoPlayer ) => {
    if ( videoPlayer ) {
        let newTime = videoPlayer.currentTime - 10
        newTime <= 0 ? videoPlayer.currentTime = 0 : videoPlayer.currentTime = newTime
    }
}

export const toggleFullScreen = async ( videoContainer ) => {
    if ( !document.fullscreenElement ) {
        if ( videoContainer.requestFullscreen ) {
            videoContainer.requestFullscreen()
        } else if ( videoContainer.webkitRequestFullscreen ) {
            videoContainer.webkitRequestFullscreen()
        } else if ( videoContainer.msRequestFullscreen ) {
            videoContainer.msRequestFullscreen()
        }
        try {
            await window.screen.orientation.lock( 'portrait-primary' )
        } catch ( err ) { }
        videoContainer?.classList.add( 'fullscreen' )
    } else {
        if ( document.exitFullscreen ) {
            document.exitFullscreen()
        } else if ( document.webkitExitFullscreen ) {
            document.webkitExitFullscreen()
        } else if ( document.msExitFullscreen ) {
            document.msExitFullscreen()
        }
        videoContainer?.classList.remove( 'fullscreen' )
    }
}

export const handleFullScreenChange = ( videoContainer, setIsInFullscreenMode ) => {
    if ( document.fullscreenElement ) {
        setIsInFullscreenMode( true )
        videoContainer?.classList.add( 'fullscreen' )
    }
    else {
        setIsInFullscreenMode( false )
        videoContainer?.classList.remove( 'fullscreen' )
    }
}

// Keyboard shortcuts
export const handleKeyDown = ( event, videoPlayer, videoContainer ) => {
    const { key } = event
    switch ( key ) {
        case 'ArrowRight':
            seekPlusTen( videoPlayer )
            break

        case 'ArrowLeft':
            seekMinusTen( videoPlayer )
            break

        case 'ArrowUp':
            event.preventDefault()
            if ( videoPlayer.volume + 0.1 <= 1 )
                videoPlayer.volume = videoPlayer.volume + 0.1
            break

        case 'ArrowDown':
            event.preventDefault()
            if ( videoPlayer.volume - 0.1 >= 0 )
                videoPlayer.volume = videoPlayer.volume - 0.1
            break

        case 'f':
        case 'F':
            toggleFullScreen( videoContainer )
            break

        case ' ':
            event.preventDefault()
            togglePlay( videoPlayer )
            break

        default: break
    }
}