import { handleSeekingAround, handleVolumeSlide, toggleSeeking, toggleMute, handleKeyDown, togglePlay, toggleFullScreen, handleFullScreenChange, handlePlay, handleVideoData, handleOnCanPlay, handleMouseOnTimeline, handleVideoDataLoad, handleMouseEnter, handleVideoBuffer, handleMouseLeave, handlePause, handleVolumeChange, seekMinusTen, seekPlusTen } from '../../utils/video-player-utilities'
import React, { useEffect, useRef, useState } from 'react'
import { Icons } from '../../utils/utilities'
import { Box, CircularProgress, Typography } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { RefreshOutlined } from '@mui/icons-material'
import noVideosImg from '../../assets/norecording.svg'

let isSeeking = false, wasPaused, overlayDisappearInterval

const VideoPlayer = ( { src, containerHeight, title, thumb, defaults = { start: 0, volume: 1 }, controls = { volume: true, seeking: true, forward: true, backward: true } } ) => {

    const [attachmentDataLoading, setAttachmentDataLoading] = useState( false )
    const [isInFullscreenMode, setIsInFullscreenMode] = useState( false )
    const [durationData, setDurationData] = useState( '' )
    const [isBuffering, setIsBuffering] = useState( true )
    const [showReplayBtn, setShowReplayBtn] = useState( false )
    const [isPaused, setIsPaused] = useState( true )
    const [volume, setVolume] = useState( 1 )
    const [error, setError] = useState( null )

    const timelineContainerRef = useRef()
    const videoContainerRef = useRef()
    const videoPlayerRef = useRef()
    const controlsContainerRef = useRef()

    const handleSeeking = e => {
        if ( isSeeking )
            handleMouseOnTimeline( e, isSeeking, timelineContainerRef.current )
    }

    const handleOrientationChange = ( e ) => {
        const screenOrientation = window.screen.orientation.type
        if ( screenOrientation === "landscape-primary" || screenOrientation === "landscape-secondary" ) {
            toggleFullScreen( videoContainerRef.current )
        } else if ( screenOrientation === "portrait-secondary" || screenOrientation === "portrait-primary" ) {
            toggleFullScreen( videoContainerRef.current )
        } else if ( screenOrientation === undefined ) {
            console.log( "The orientation API isn't supported in this browser :(" )
        }
    }

    const handleError = () => {
        setError( true )
    }

    const fullscreenChangeHandler = () => handleFullScreenChange( videoContainerRef.current, setIsInFullscreenMode )

    const mouseUpHandler = async ( e ) => {
        const returnedValue = await handleSeekingAround( e, isSeeking, videoPlayerRef.current, videoContainerRef.current, timelineContainerRef.current, wasPaused )
        wasPaused = returnedValue.wasPaused
        isSeeking = returnedValue.isSeeking
    }

    useEffect( () => {
        setError( false )
        const videoContainer = videoContainerRef.current
        videoContainerRef.current.addEventListener( 'fullscreenchange', fullscreenChangeHandler )
        document.addEventListener( 'mouseup', mouseUpHandler )
        document.addEventListener( 'mousemove', handleSeeking )
        window.screen.orientation.addEventListener( "change", handleOrientationChange )
        try {
            videoPlayerRef.current.volume = defaults.volume || 1
            videoPlayerRef.current.muted = defaults.volume === 0
            videoPlayerRef.current.currentDuration = defaults.start || 0
        } catch ( err ) { }
        return () => {
            videoContainer.removeEventListener( 'fullscreenchange', fullscreenChangeHandler )
            document.removeEventListener( 'mouseup', mouseUpHandler )
            document.removeEventListener( 'mousemove', handleSeeking )
            window.screen.orientation.removeEventListener( "change", handleOrientationChange )
        }
    }, [defaults] )


    return (
        <Box height={containerHeight} ref={videoContainerRef} bgcolor={error ? "white" : "#4f4f4f"} onMouseEnter={( e ) => overlayDisappearInterval = handleMouseEnter( e, controlsContainerRef.current, overlayDisappearInterval )} onMouseMove={( e ) => overlayDisappearInterval = handleMouseEnter( e, controlsContainerRef.current, overlayDisappearInterval )} onMouseLeave={() => handleMouseLeave( controlsContainerRef.current )} tabIndex={0} onKeyDown={( e ) => handleKeyDown( e, videoPlayerRef.current, videoContainerRef.current )} className='video-container' id='video-container' width={{ lg: "auto", xs: "100%" }}>
            {error && !attachmentDataLoading && <Box display="flex" flexDirection="column" gap="20px" padding="50px" alignItems="center" justifyContent="center">
                <img width="150px" src={noVideosImg} alt="Video fetch error" />
                <Typography variant='subtitle2' > Oops! something went wrong! couldn't fetch the video frames </Typography>
            </Box>}
            {!error && !attachmentDataLoading &&
                <video
                    thumb={thumb}
                    onError={handleError}
                    ref={videoPlayerRef}
                    onCanPlay={( e ) => handleOnCanPlay( e, setIsBuffering, videoPlayerRef.current )}
                    onWaiting={() => setIsBuffering( true )}
                    id='video' className='video-player'
                    src={src}
                    onPlay={() => handlePlay( setIsPaused, controlsContainerRef.current, setShowReplayBtn )}
                    onPause={() => handlePause( setIsPaused, controlsContainerRef.current )}
                    onVolumeChange={() => handleVolumeChange( setVolume, videoPlayerRef.current )}
                    onLoadedData={() => handleVideoDataLoad( setDurationData, videoPlayerRef.current, timelineContainerRef.current )}
                    onEnded={() => setShowReplayBtn( true )}
                    onTimeUpdate={() => handleVideoData( setDurationData, videoPlayerRef.current, timelineContainerRef.current )}
                    onLoadedMetadata={() => setAttachmentDataLoading( false )}
                    onProgress={() => handleVideoBuffer( videoPlayerRef.current, timelineContainerRef.current )}
                ></video>}
            {!error && !attachmentDataLoading && <Box width="100%" ref={controlsContainerRef} height="100%" className="video-controls-container paused">
                <Box className="pause-play-box">
                    {
                        <Box onClick={() => togglePlay( videoPlayerRef.current )} position="absolute" top="0" bottom="0" right="0" left="0" display="flex" alignItems="center" justifyContent="center">
                            <Box className="play-pause-btn" style={{ opacity: isPaused ? 1 : 0 }}>
                                <div className="backdrop"></div>
                                {!showReplayBtn ? isPaused ? <PlayArrowIcon sx={{ fontSize: "50px !important" }} /> : <PauseIcon sx={{ fontSize: "60px !important" }} /> : ''}
                                {showReplayBtn && <RefreshOutlined sx={{ fontSize: "50px !important" }} />}
                            </Box>
                        </Box>
                    }
                    {
                        ( isBuffering || attachmentDataLoading ) && <Box onClick={() => togglePlay( videoPlayerRef.current )} position="absolute" top="0" bottom="0" right="0" left="0" display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress sx={{ color: "white" }} size={40} />
                        </Box>
                    }
                </Box>
                <Box className='title-and-timeline'>
                    <div className="overlay"></div>
                    {title && <Box className="video-title">
                        <Typography fontWeight="300" height="fit-content" fontSize="3.5vh" variant='body2' color="white">{title}</Typography>
                    </Box>}
                    {!attachmentDataLoading &&
                        <Box id="controls-area" className="video-controls">
                            <Box onMouseDown={
                                async ( e ) => {
                                    if ( controls.seeking !== false ) {
                                        const returnedValue = await toggleSeeking( e, videoPlayerRef.current, videoContainerRef.current, timelineContainerRef.current, isSeeking, wasPaused )
                                        wasPaused = returnedValue.wasPaused
                                        isSeeking = returnedValue.isSeeking
                                    }
                                }}
                                ref={timelineContainerRef}
                                onMouseMove={( e ) => {
                                    if ( controls.seeking !== false )
                                        handleMouseOnTimeline( e, isSeeking, timelineContainerRef.current )
                                }}
                                id="timeline-container"
                                className="timeline-container"
                            >
                                <div className="timeline">
                                    <span className="thumb-indicator"></span>
                                    <span className="buffer-loaded"></span>
                                </div>
                            </Box>
                            <Box sx={{ pointerEvents: "all" }} display="flex" gap="20px" justifyContent="space-between" alignItems="flex-end">
                                <Box display="flex" alignItems="center" gap="10px" flexGrow="1">
                                    <button onClick={() => togglePlay( videoPlayerRef.current )} className='control-btn'>{!isPaused ? Icons.default.PauseIcon : Icons.default.PlayArrowIcon}</button>
                                    <button disabled={controls.backward === false} onClick={() => seekMinusTen( videoPlayerRef.current )} className='control-btn'>{Icons.default.Replay10Icon}</button>
                                    <button disabled={controls.forward === false} onClick={() => seekPlusTen( videoPlayerRef.current )} className='control-btn'>{Icons.default.Forward10Icon}</button>

                                    <Typography className='duration' style={{ fontSize: "12px" }}>{durationData}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap="10px" >
                                    <Box className="volume-container" display="flex" alignItems="center">
                                        {controls.volume !== false && <input
                                            orient='vertical'
                                            orientation='vertical'
                                            type="range"
                                            onChange={( e ) => handleVolumeSlide( e, setVolume, videoPlayerRef.current )}
                                            className='volume-slider'
                                            value={volume}
                                            step="0.1"
                                            min={0}
                                            max={1}
                                        />}
                                        {controls.volume !== false && <div className="slider-background"></div>}
                                        {videoPlayerRef.current?.muted ? <button disabled={controls.volume === false} onClick={() => toggleMute( videoPlayerRef.current, setVolume )} className="control-btn">{Icons.small.VolumeOffIcon}</button> : volume > 0.5 ? <button disabled={controls.volume === false} onClick={() => toggleMute( videoPlayerRef.current, setVolume )} className="control-btn">{Icons.small.VolumeUpIcon}</button> : <button disabled={controls.volume === false} onClick={() => toggleMute( videoPlayerRef.current, setVolume )} className="control-btn">{Icons.small.VolumeDownIcon}</button>}
                                        {controls.volume === false && defaults.volume === 0 && <Typography fontSize="10px" color="GrayText" >DISABLED</Typography>}
                                    </Box>
                                    <button onClick={() => toggleFullScreen( videoContainerRef.current )} className='control-btn'>{isInFullscreenMode ? Icons.default.FullscreenExitIcon : Icons.default.FullscreenIcon}</button>
                                </Box>
                            </Box>
                        </Box>}
                </Box>
            </Box>}
        </Box >
    )
}

export default VideoPlayer