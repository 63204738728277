import { quillDefaultModule } from "../../utils/utilities"
import React, { useEffect, useRef } from "react"
import { Box, useTheme } from "@mui/material"
import PropTypes from 'prop-types'
import 'quill/dist/quill.snow.css'
import Quill from "quill"

const CustomWysiwyg = ( { onBlur, setParentEditorRef, readOnly = false, modules = quillDefaultModule, classList, placeholder, value, onChange, height = '200px', styles = {} } ) => {
    const editorRef = useRef( null )
    const quillRef = useRef( null )

    const { palette } = useTheme()

    useEffect( () => {
        if ( !quillRef.current ) {
            const quill = new Quill( editorRef.current, {
                readOnly,
                placeholder,
                theme: "snow",
                modules,
            } )
            quillRef.current = quill
            if ( setParentEditorRef ) {
                setParentEditorRef( quillRef )
            }
            quillRef.current.root.innerHTML = value

            quill.on( Quill.events.TEXT_CHANGE, () => {
                const html = quillRef.current.root.innerHTML
                if ( html !== value ) {
                    onChange( html, quillRef.current?.getText() )
                }
            } )

            quillRef.current.on( 'blur', ( range, oldRange, source ) => {
                if ( onBlur ) {
                    const html = quillRef.current.root.innerHTML

                    onBlur( html, quillRef.current?.getText() )
                }
            } )
        }
    }, [onChange, onBlur, modules, placeholder, readOnly, setParentEditorRef, value] )

    useEffect( () => {
        if ( quillRef.current && quillRef.current.root.innerHTML !== value ) {
            quillRef.current.root.innerHTML = value
        }
    }, [value] )

    return (
        <Box flexGrow={1} width="100%">
            <Box color={palette.common.font} className={classList} ref={editorRef} sx={{ height: height, width: "100%", border: '1px solid #ccc', ...styles }} ></Box>
        </Box>
    )
}

CustomWysiwyg.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    height: PropTypes.string,
    styles: PropTypes.object,
    placeholder: PropTypes.string,
    classList: PropTypes.string,
}

export default CustomWysiwyg
