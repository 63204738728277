import { Box, Button, ButtonGroup, CircularProgress, Dialog, IconButton, MenuItem, Popover, Select, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useLiveSessionStore } from '../../store/livesessions.store'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Icons, convertToCDNLink, getFileSizeFromBytes, showDateInIndianDateFormat } from '../../utils/utilities'
import { useUserStore } from '../../store/user.store'
import { observer } from 'mobx-react'
import TableHeader from '../../components/common/TableHeader'
import VideoPlayer from '../../components/common/VideoPlayer'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import CreateLiveSessionDialog from '../../components/presenter/live-session/CreateLiveSessionDialog'
import { CheckBox, CheckBoxOutlineBlank, CopyAllOutlined } from '@mui/icons-material'
import AddResourcesDialog from '../../components/presenter/live-session/AddResourcesDialog'
import { toast } from 'react-toastify'
import api from '../../service/api'

let latestMonth

const columns = [
    { name: "TITLE", align: "left", padding: "10px 20px 10px 40px" },
    { name: "Resource type", align: "left" },
    { name: "resource size", align: "left" },
    { name: "enabled on", align: "left" },
    { name: "Actions", align: "right", padding: "10px 40px 10px 20px" },
]

let newMonth = []

const typeIcons = {
    'document': Icons.default.Document,
    'video': Icons.default.Video,
    'link': Icons.default.Link
}


const ClassSession = observer( () => {

    const [loading, setLoading] = useState( true )
    const [videoSrc, setVideoSrc] = useState( null )
    const [addOptionsOpen, setAddOptionsOpen] = useState( false )
    const [selectedAddOption, setSelectedAddOption] = useState( 0 )
    const [videoPlayerControls, setVideoPlayerControls] = useState( {} )
    const [videoPlayerDefaults, setVideoPlayerDefaults] = useState( {} )
    const [selectedResource, setSelectedResource] = useState( null )
    const [editNewDialogState, setEditNewDialogState] = useState( false )
    const [uploadResourcesDialogState, setUploadResourcesDialogState] = useState( false )
    const [enabling, setEnabling] = useState( false )

    const [searchParams, setSearchParams] = useSearchParams( { filter: 'IN CLASS' } )
    const filter = searchParams.get( 'filter' )

    const setFilter = ( filter ) => {
        setSearchParams( prev => {
            prev.set( 'filter', filter )
            return prev
        }, { replace: true } )
    }

    const LiveSessionStore = useLiveSessionStore()
    const UserStore = useUserStore()

    const addOptionsRef = useRef()

    const navigate = useNavigate()

    const { palette, border, table, theme, shadows } = useTheme()

    const { session_id, subject_id } = useParams()

    const addOptions = [{ icon: Icons.default.AddCircleIcon, label: "Upload resources", action: () => { console.log( "UPLOAD" ); setUploadResourcesDialogState( true ) }, value: "upload" }, { icon: Icons.default.DownloadForOfflineIcon, label: "Import resources", action: () => { console.log( "UPLOAD" ); setUploadResourcesDialogState( true ) }, value: 'import' }]

    const navigateBack = () => {
        navigate( `/${UserStore.getUser.user_role === 'STUDENT' ? 'student' : 'faculty'}/subject/${subject_id}/class-sessions` )
    }

    const handleClose = ( event ) => {
        if ( addOptionsRef.current && addOptionsRef.current.contains( event.target ) ) {
            return
        }
        setAddOptionsOpen( false )
    }

    const handleMenuItemClick = ( event, index, action ) => {
        setSelectedAddOption( index )
        setAddOptionsOpen( false )
        if ( action ) action()
    }

    const enableResource = async ( id ) => {
        setEnabling( true )
        await LiveSessionStore.enableResource( session_id, id )
        setEnabling( false )
    }

    const disableResource = async ( id ) => {
        setEnabling( true )
        await LiveSessionStore.disableResource( session_id, id )
        setEnabling( false )
    }

    const unlinkResource = async ( id ) => {
        await LiveSessionStore.unlinkResource( session_id, id )
    }

    const handleDragStart = e => {
        e.currentTarget.style.opacity = 1
    }

    const downloadResource = async ( key, relation_id, details ) => {
        await LiveSessionStore.downloadResource( key, session_id, relation_id, details )
    }

    const copyLink = ( url ) => {
        window.navigator.clipboard.writeText( url )
        toast( "Copied link successfully" )
    }

    const handleClickOnVideoResource = async ( resource ) => {
        try {
            const { data: { data } } = await api.liveSessions.fetchLiveSessionResourceLink( session_id, resource.relation_id, { key: resource.resource_key } )
            console.log( UserStore.getUser.user_role === 'STUDENT', filter === 'IN CLASS' )
            if ( UserStore.getUser.user_role === 'STUDENT' && filter === 'IN CLASS' ) {
                setVideoPlayerControls( { volume: false } )
                setVideoPlayerDefaults( { volume: 0 } )
            } else {
                setVideoPlayerControls( {} )
                setVideoPlayerDefaults( {} )
            }
            setVideoSrc( convertToCDNLink( data ) )
            setSelectedResource( resource )
        } catch ( err ) {
            toast( err?.responce?.data?.message || "Couldn't fetch session resource!" )
        }
    }

    useEffect( () => {
        const container = document.getElementById( 'resource-container' )
        if ( container ) {
            container.scrollTo( 0, container.scrollHeight + 200 )
        }
    }, [filter] )



    useEffect( () => {
        const getData = async () => {
            const { accessDenied } = await LiveSessionStore.fetchLiveSession( session_id )
            if ( accessDenied ) {
                const userRole = UserStore.getUser.user_role === 'STUDENT' ? 'student' : "faculty"
                navigate( `/${userRole}/subject/${subject_id}/class-sessions` )
            }
            setLoading( false )
        }
        getData()
    }, [LiveSessionStore, session_id, UserStore, subject_id, navigate] )

    return (
        <Box flexGrow={1} display="flex" position="relative" overflow="auto" flexDirection="column">


            {!['STUDENT'].includes( UserStore.getUser.user_role ) && <CreateLiveSessionDialog mode={editNewDialogState ? 'edit' : 'create'} session={LiveSessionStore?.getLiveSession?.details} setState={() => setEditNewDialogState( false )} state={editNewDialogState} />}
            {!['STUDENT'].includes( UserStore.getUser.user_role ) && selectedAddOption >= 0 && <AddResourcesDialog setLinkMode={setSelectedAddOption} initialMode={addOptions[selectedAddOption].value} filter={filter} session={LiveSessionStore?.getLiveSession?.details} setState={setUploadResourcesDialogState} state={uploadResourcesDialogState} />}
            <Dialog open={Boolean( videoSrc && selectedResource )} onClose={() => { setVideoSrc( null ); setSelectedResource( null ) }}>
                {
                    videoSrc && selectedResource &&
                    <VideoPlayer
                        src={videoSrc}
                        title={selectedResource.name}
                        controls={videoPlayerControls}
                        defaults={videoPlayerDefaults}
                    />}
            </Dialog>


            <Box display="flex" flexWrap="wrap" justifyContent="space-between" borderBottom="1px solid #d3d3d3" alignItems="center" gap="10px" padding="10px 20px" >
                <Box display="flex" gap="10px" alignItems="center">
                    <IconButton size="small" onClick={navigateBack}>
                        {Icons.default.ArrowBack}
                    </IconButton>
                    <Box>
                        <Typography variant="h6" color='primaryDark.main' fontSize="18px">{LiveSessionStore?.getLiveSession?.details?.name || "Session"}</Typography>
                        <Select size="small" sx={{ fontSize: "12px" }} variant='standard' value={filter} onChange={e => setFilter( e.target.value )}>
                            <MenuItem dense sx={{ fontSize: "12px" }} value="IN CLASS">In Class</MenuItem>
                            <MenuItem dense sx={{ fontSize: "12px" }} value="BEYOND CLASS">Beyond Class</MenuItem>
                        </Select>
                    </Box>
                </Box>
                {UserStore.getUser?.user_role !== "STUDENT" && <Box display="flex" gap="20px" alignItems="center">
                    <Button onClick={() => { setEditNewDialogState( true ) }} variant="contained" disableElevation startIcon={Icons.default.EditIcon} sx={{ textTransform: "capitalize" }} color="secondary">
                        Edit
                    </Button>

                    <ButtonGroup ref={addOptionsRef} color='primary' variant='contained' disableElevation>
                        <Button onClick={addOptions[selectedAddOption].action} variant="contained" disableElevation startIcon={Icons.default.AddCircleIcon} sx={{ textTransform: "capitalize" }} color="primary">
                            {addOptions[selectedAddOption]['label']}
                        </Button>
                        <Button
                            size="small"
                            aria-controls={addOptionsOpen ? 'split-button-menu' : undefined}
                            aria-expanded={addOptionsOpen ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={() => setAddOptionsOpen( !addOptionsOpen )}
                        >
                            {Icons.default.DropDown}
                        </Button>
                    </ButtonGroup>

                    <Popover slotProps={{ paper: { sx: { padding: "0", width: "fit-content" } } }} onClose={handleClose} open={addOptionsOpen} anchorEl={addOptionsRef.current}>
                        <Box >
                            <Box sx={{ padding: "0" }} display="flex" flexDirection="column" id="split-button-menu">
                                {addOptions.map( ( option, index ) => (
                                    <Button
                                        startIcon={option.icon}
                                        variant='contained'
                                        disableElevation
                                        sx={{ display: "inline-flex", borderRadius: "0", textTransform: "capitalize" }}
                                        color="primary"
                                        key={option.label}
                                        onClick={( event ) => handleMenuItemClick( event, index, option.action )}
                                    >
                                        {option.label}
                                    </Button>
                                ) )}
                            </Box>
                        </Box>
                    </Popover>


                </Box>}
            </Box>
            {!loading && UserStore.getUser?.user_role !== 'STUDENT' && <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column">
                {LiveSessionStore.getLiveSession?.resources?.filter( r => r.resource_category === filter )?.length > 0 && <Box bgcolor={palette.contentBg} margin="20px" border={border[1]} borderRadius="5px" flexGrow={1} display="flex" flexDirection='column' overflow="auto" >

                    <TableContainer sx={{ minWidth: "750px", flexGrow: 1, overflow: "auto", borderRadius: "5px" }}>
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {LiveSessionStore.getLiveSession?.resources?.filter( r => r.resource_category === filter )?.map( ( resource, index ) => {
                                    return (
                                        <TableRow onDragStart={handleDragStart} draggable className='table-row draggable-ele' sx={{ "&:hover": { background: palette.rowHover }, cursor: "pointer" }} key={resource.relation_id}>
                                            <TableCell sx={{ border: "none", color: table.color, bgcolor: palette.contentBg, padding: "0" }} align={columns[0].align || 'left'} >
                                                <div>
                                                    <Box padding={columns[0].padding || "10px 20px"} display="flex" alignItems="center" gap="10px">
                                                        {!enabling && !resource.is_enabled && <Tooltip title="Enable resource for students">
                                                            <CheckBoxOutlineBlank fontSize='small' onClick={() => enableResource( resource.relation_id )} color="greyed" />
                                                        </Tooltip>}
                                                        {!enabling && resource.is_enabled && <Tooltip title="Enabled!">
                                                            <CheckBox onClick={() => disableResource( resource.relation_id )} fontSize='small' color="success" />
                                                        </Tooltip>}
                                                        {enabling && <CircularProgress size={12} />}
                                                        <Typography title={resource.name} maxWidth="200px" fontSize="14px" noWrap>
                                                            <strong>
                                                                {resource.name}
                                                            </strong>
                                                        </Typography>
                                                    </Box>
                                                </div>
                                            </TableCell>
                                            <TableCell sx={{ border: "none", color: table.color, bgcolor: palette.contentBg, padding: "0" }} align={columns[1].align || 'left'} >
                                                <Box padding={columns[1].padding || "10px 20px"} display="flex" alignItems="center" gap="10px">
                                                    <strong>
                                                        {resource.resource_type}
                                                    </strong>
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ border: "none", color: table.color, bgcolor: palette.contentBg, padding: "0" }} align={columns[2].align || 'left'} >
                                                <Box padding={columns[2].padding || "10px 20px"} display="flex" alignItems="center" gap="10px">
                                                    <strong>
                                                        {resource.resource_type !== 'link' ? getFileSizeFromBytes( resource?.resource_metadata?.size || 0 ) : "NA"}
                                                    </strong>
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ border: "none", color: table.color, bgcolor: palette.contentBg, padding: "0" }} align={columns[3].align || 'left'} >
                                                <Box padding={columns[3].padding || "10px 20px"} display="flex" alignItems="center" gap="10px">
                                                    <strong>
                                                        {resource.is_enabled ? showDateInIndianDateFormat( resource.enabled_at ) : "Not yet enabled"}
                                                    </strong>
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ border: "none", color: table.color, bgcolor: palette.contentBg, padding: "0" }} align={columns[4].align || 'left'} >
                                                <Box padding={columns[4].padding || "10px 20px"} display="flex" justifyContent="right" alignItems="center" gap="10px">
                                                    {resource.resource_type === 'link' && <Tooltip title="Visit site">
                                                        <Link target='_blank' to={`${resource.resource_key}`} >
                                                            <IconButton>
                                                                {Icons.default.OpenInNewIcon}
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>}
                                                    {resource.resource_type === 'link' && <Tooltip title="Copy link">
                                                        <span>
                                                            <IconButton onClick={() => copyLink( resource.resource_key )}>
                                                                <CopyAllOutlined />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>}
                                                    {resource.resource_type === 'video' && <IconButton onClick={() => handleClickOnVideoResource( resource )} >
                                                        {Icons.default.PlayCircleFilledWhiteIconOutlined}
                                                    </IconButton>}
                                                    {( resource.resource_type === 'image' || resource.resource_type === 'document' || resource.resource_type === 'video' ) && <IconButton onClick={() => downloadResource( resource.resource_key, resource.relation_id, resource )}>
                                                        {Icons.default.DownloadForOfflineIcon}
                                                    </IconButton>}
                                                    <Tooltip title="Remove this resource">
                                                        <IconButton onClick={() => { unlinkResource( resource.relation_id ) }} color="error">{Icons.default.ClearIcon}</IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                } )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {!loading && LiveSessionStore?.getLiveSession?.resources?.filter( r => r.resource_category === filter )?.length === 0 && <Box bgcolor={palette.contentBg} flexGrow={1} display="flex" flexDirection="column" alignItems="center" padding="20px" justifyContent="center" gap="20px">
                    {/* <img src={noSessionsImg} width="150px" alt="No quizes" /> */}
                    <Typography variant="subtitle2">You haven't added any resources to '<em>{filter.toLowerCase()}</em>' sessions!</Typography>
                    {UserStore.getUser?.user_role !== "STUDENT" && <Button onClick={addOptions[selectedAddOption].action} startIcon={Icons.default.AddIcon} variant='outlined' color="primaryDark" sx={{ textTransform: "capitalize" }} >Add resources</Button>}
                </Box>}
            </Box>}

            {!loading && UserStore.getUser?.user_role === 'STUDENT' && <Box overflow="auto" className="custom-scrollbar" flexGrow={1} bgcolor={palette.greyedOut} display="flex" flexDirection="column">
                {LiveSessionStore.getLiveSession?.resources?.filter( r => r.resource_category === filter )?.length > 0 && <Box id="resource-container" className="custom-scrollbar" margin="20px" alignItems="center" flexGrow={1} display="flex" gap="10px" flexDirection='column' overflow="auto" >
                    {LiveSessionStore.getLiveSession?.resources?.filter( r => r.resource_category === filter ).map( ( resource, index ) => {

                        const monthAndYear = new Date( resource?.enabled_at || resource?.added_at ).toLocaleDateString( 'en-IN', { month: "long", year: "numeric" } )

                        if ( monthAndYear !== latestMonth ) {
                            newMonth[index] = true
                            latestMonth = monthAndYear
                        }


                        return (
                            <Box key={resource.relation_id} width="90%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" minWidth="200px" maxWidth="700px" >
                                {newMonth[index] && <Typography borderRadius="5px" marginBottom="20px" padding="5px 20px" bgcolor={palette.secondary.main} color="white">
                                    {monthAndYear}
                                </Typography>}


                                <Box color={resource.is_enabled ? "black" : "DarkGrey"} position="relative" sx={{ cursor: resource.is_enabled ? "pointer" : "not-allowed", '&:hover': { boxShadow: resource.is_enabled && shadows[1] } }} display="flex" gap="10px" padding="10px" width="100%" borderRadius="5px" bgcolor={palette.background.default}>
                                    <Box padding="20px" borderRadius="5px" sx={{ aspectRatio: 1, opacity: resource.is_enabled ? 1 : 0.5 }} bgcolor={theme === 'dark' ? palette.grey[600] : palette.grey[200]} display="flex" alignItems="center" justifyContent="center">
                                        {typeIcons[resource.resource_type]}
                                    </Box>
                                    <Box flexGrow={1} display="flex" flexDirection="column">
                                        <Box display="grid" minWidth="0" gridTemplateColumns="auto 70px" gap="20px" alignItems="flex-start">
                                            <Typography minWidth="0" variant="body2" noWrap fontSize="16px" color="inherit">
                                                {resource.name}
                                            </Typography>
                                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                {resource.resource_type === 'link' && <Tooltip title="Visit site">
                                                    <Link target='_blank' to={`${resource.resource_key}`} >
                                                        <IconButton disabled={!resource.is_enabled}>
                                                            {Icons.default.OpenInNewIcon}
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>}
                                                {resource.resource_type === 'link' && <Tooltip title="Copy link">
                                                    <span>
                                                        <IconButton onClick={() => copyLink( resource.resource_key )} disabled={!resource.is_enabled}>
                                                            <CopyAllOutlined />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>}
                                                {resource.resource_type === 'video' && <IconButton onClick={() => handleClickOnVideoResource( resource )} disabled={!resource.is_enabled}>
                                                    {Icons.default.PlayCircleFilledWhiteIconOutlined}
                                                </IconButton>}
                                                {( resource.resource_type === 'image' || resource.resource_type === 'document' || resource.resource_type === 'video' ) && <IconButton onClick={() => downloadResource( resource.resource_key, resource.relation_id, resource )} disabled={!resource.is_enabled}>
                                                    {Icons.default.DownloadForOfflineIcon}
                                                </IconButton>}
                                            </Box>
                                        </Box>
                                        <Typography variant="subtitle2" color={resource.is_enabled ? "textSecondary" : "inherit"} marginTop="-5px">{resource.resource_type}</Typography>
                                        <Typography variant="body2" marginTop="5px" color={resource.is_enabled ? "GrayText" : "inherit"} fontSize="12px">{resource.resource_type === 'link' ? "" : getFileSizeFromBytes( resource.resource_metadata?.size )}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    } )}
                </Box>}
                {!loading && LiveSessionStore?.getLiveSession?.resources?.filter( r => r.resource_category === filter )?.length === 0 && <Box bgcolor={palette.contentBg} flexGrow={1} display="flex" flexDirection="column" alignItems="center" padding="20px" justifyContent="center" gap="20px">
                    {/* <img src={noSessionsImg} width="150px" alt="No quizes" /> */}
                    <Typography variant="subtitle2">Resources are not added to this session yet!</Typography>
                    {UserStore.getUser?.user_role !== "STUDENT" && <Button onClick={addOptions[selectedAddOption].action} startIcon={Icons.default.AddIcon} variant='outlined' color="primaryDark" sx={{ textTransform: "capitalize" }} >Create one</Button>}
                </Box>}
            </Box>}

            {loading && <DataLoadingSpinner waitingMessage="Loading resources..." />}
        </Box>
    )
} )

export default ClassSession
