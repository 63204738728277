import { formatDateTimeForInputField, formatDateTimeForDisplay, ATTACHMENTS_BASE_URL } from '../../utils/exam-utilities'
import { Box, Typography, Button, CircularProgress, useTheme, Chip, IconButton, Paper, Tooltip } from '@mui/material'
import QuestionImportFileInputDialog from '../../components/exams-components/QuestionImportFileInputDialog'
import QuestionsPreviewDialog from '../../components/exams-components/QuestionsPreviewDialog'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ConfirmDialog from '../../components/common/ConfirmDialog'
import examNotFoundImg from '../../assets/exam-notfound.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BlockMessage from '../../components/common/BlockMessage'
import AttachmentIcon from '@mui/icons-material/Attachment'
import DownloadIcon from '@mui/icons-material/Download'
import RefreshIcon from '@mui/icons-material/Refresh'
import PublishIcon from '@mui/icons-material/Publish'
import { useUserStore } from '../../store/user.store'
import { useExamStore } from '../../store/exam.store'
import GetAppIcon from '@mui/icons-material/GetApp'
import { EditableMathField } from 'react-mathquill'
import React, { useEffect, useState } from 'react'
import { Icons, flatColorsAndBackgrounds, isMobileDevice, latexDelimiters } from '../../utils/utilities'
import Edit from '@mui/icons-material/Edit'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react'
import api from '../../service/api'
import { useSubjectStore } from '../../store/subject.store'
import BreadcrumbList from '../../components/common/BreadcrumbList'
import ExamRescheduleDialog from '../../components/exams-components/ExamRescheduleDialog'
import { AccessTime } from '@mui/icons-material'
import { useSettingsStore } from '../../store/settings.store'
import Latex from 'react-latex-next'

const Exam = observer( ( { fromSubject = false } ) => {
    let { id } = useParams()
    const [batches, setBatches] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const [deleting, setDeleting] = useState( false )
    const [questions, setQuestions] = useState( null )
    const [isImporting, setIsImporting] = useState( false )
    const [notFound, setNotFound] = useState( false )
    const [rescheduleDialogState, setRescheduleDialogState] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )
    const [questionsPreview, setQuestionsPreview] = useState( null )
    const [importDialogStatus, setImportDialogStatus] = useState( false )
    const [publishing, setPublishing] = useState( false )
    const [accessDenied, setAccessDenied] = useState( false )
    const [attempting, setAttempting] = useState( false )

    const navigate = useNavigate()
    const UserStore = useUserStore()
    const ExamStore = useExamStore()
    const SubjectStore = useSubjectStore()
    const SettingsStore = useSettingsStore()

    const { palette, border } = useTheme()

    const navigateToExamsPage = () => {
        navigate( UserStore.getUser['user_role'] === "STUDENT" ? "/student/exams" : "/faculty/exams" )
    }

    const navigateOrOpen = url => {
        if ( fromSubject )
            window.open( url )
        else
            navigate( url )
    }

    const getExam = async () => {
        try {
            const { success, code } = await ExamStore.fetchExam( id )
            if ( !success ) {
                if ( code === 404 )
                    return setNotFound( true )
                else if ( code === 403 || code === 401 ) {
                    return setAccessDenied( true )
                }
            }

        } catch ( e ) {
            if ( e.response && e.response.data ) {
                if ( e.response.status === 404 && e.response.data === "Exam not found!" ) {
                    setNotFound( true )
                } else if ( e.response.status === 401 && e.response.status === 403 ) {
                    setAccessDenied( true )
                } else
                    toast.error( e?.response?.data || "Something went wrong while importing questions" )
            }
            else toast.error( "Something went wrong!" )
            navigateToExamsPage()
        } finally {
            setInProgress( false )
        }
    }

    const publish = async () => {
        try {
            setPublishing( true )
            await api.publish( ExamStore.getExam.exam.exam_id )
            await getExam()
            toast.success( "Exam published!" )
        } catch ( e ) {
            console.log( e )
            toast.error( e?.response?.data?.message || "Something went wrong!" )
        } finally {
            setPublishing( false )
        }
    }

    const deleteExam = async () => {
        try {
            setDeleting( true )
            await api.deleteExam( ExamStore.getExam.exam.exam_id )
            toast.success( <div><span style={{ color: "#f18f08" }}>{ExamStore.getExam.exam.exam_name}</span> successfully deleted</div> )
            navigate( "/faculty/exams" )
        } catch ( e ) {
            toast( e?.response?.data?.message || "Something went wrong! try again later" )
        } finally {
            setDeleteDialogState( false )
            setDeleting( false )
        }
    }

    const attempt = async () => {
        try {
            setAttempting( true )
            const { isMobile } = isMobileDevice()
            if ( isMobile && SettingsStore?.getUseSettings['restrict_exam_attempt_on_mobile_devices'] === true ) {
                toast( `This exam cannot be taken on mobile device! please use laptop or desktop with stable internet connection.` )
                return
            }

            const { status, data } = await api.attempt( ExamStore.getExam.exam.exam_id )
            if ( status === 200 ) {
                if ( !data.isNewParticipation ) toast.success( "Proceeding with the previously unfinished exam! finish this to start new attampt" )
                const url = `/student/exams/${ExamStore.getExam.exam.exam_id}/attempt/${data.attempt}/${data.participation_id}`
                if ( fromSubject )
                    window.open( url )
                else
                    navigate( url )
            }
        } catch ( e ) {
            if ( e?.response?.status === 401 || e?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            }
            else
                toast( e?.response?.data?.message || "Something went wrong" )

        } finally {
            setAttempting( false )
        }
    }

    const getQuestions = async () => {
        try {
            const { data: questionsRes } = await api.fetchQuestions( id )
            setQuestions( questionsRes.data || questionsRes )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong" )
        }
    }


    useEffect( () => {
        const getExam = async () => {
            try {
                const { success, code } = await ExamStore.fetchExam( id )
                if ( !success ) {
                    if ( code === 404 )
                        return setNotFound( true )
                    else if ( code === 403 || code === 401 ) {
                        return setAccessDenied( true )
                    }
                    else
                        toast.error( "Something went wrong!" )
                } else {
                    if ( UserStore.getUser["user_role"] === 'FACULTY' || UserStore.getUser["user_role"] === 'HOD' || UserStore.getUser["user_role"] === 'PRINCIPAL' || UserStore.getUser["user_role"] === 'presenter' ) {
                        const { data: questionsRes } = await api.fetchQuestions( id )
                        setQuestions( questionsRes.data || questionsRes )
                    }
                    setBatches( ExamStore.getExam.batches )
                }
            } catch ( e ) {
                console.log( e.response )
                if ( e.response && e.response.data ) {
                    if ( e.response.status === 404 && e.response.data === "Exam not found!" ) {
                        setNotFound( true )
                    } else if ( e.response.status === 403 ) {
                        setAccessDenied( true )
                    } else
                        toast.error( e?.response?.data?.message || "Something went wrong while importing questions" )
                }
                else toast.error( "Something went wrong!" )
                // navigate( '/exams' )
            } finally {
                setInProgress( false )
            }
        }
        getExam()
        return () => {
            setInProgress( true )
        }
    }, [ExamStore, UserStore, id, navigate] )

    return (
        !notFound ? <Box flexGrow={1} minWidth="0" overflow="auto" padding="20px">
            {accessDenied && <BlockMessage blockTitle='Access Denied' actions={[{ label: "Back to Exams", icon: Icons.default.ArrowBack, action: () => navigate( UserStore.getUser['user_role'] === "STUDENT" ? "/student/exams" : "/faculty/exams" ) }]} type='error' message='You do not have permission to view this exam information. Only exam creator and admin can access a particular exam.' />}
            {!inProgress && ExamStore.getExam.exam && <ExamRescheduleDialog state={rescheduleDialogState} setState={setRescheduleDialogState} exam={ExamStore.getExam.exam} />}
            <QuestionImportFileInputDialog setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} isImporting={isImporting} importDialogStatus={importDialogStatus} setImportDialogStatus={setImportDialogStatus} />
            <QuestionsPreviewDialog setIsImporting={setIsImporting} getQuestions={getQuestions} examId={id} setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} />
            <ConfirmDialog waiting={deleting} status={deleteDialogState} confirmAction={deleteExam} cancelAction={() => setDeleteDialogState( false )} actionName="Yes, Delete It" message={<p>The exam <strong><em>{ExamStore.getExam?.exam?.exam_name}</em></strong> will be deleted along with it's question details.</p>} />

            {!fromSubject && !accessDenied && !notFound && <BreadcrumbList items={[
                { link: UserStore.getUser['user_role'] === "STUDENT" ? "/student/exams" : "/faculty/exams", label: "Exams" },
                ExamStore.getExam.exam && ExamStore.getExam.exam.exam_name ? ExamStore.getExam.exam.exam_name : <CircularProgress sx={{ color: "textSecondary" }} size={12} />
            ]} />}

            {fromSubject && <Button sx={{ textTransform: 'capitalize', marginTop: "-10px", marginBottom: "10px" }} startIcon={Icons.default.ArrowBack} onClick={() => navigate( `${UserStore.getUser['user_role'] === "STUDENT" ? `/student/subject/${SubjectStore.getSubject.subject_id}/exams` : `/faculty/subject/${SubjectStore.getSubject.subject_id}/exams`}` )} >Back to Exams</Button>}

            {!inProgress && ExamStore.getExam.exam && ExamStore.getExam.exam.exam_name && <>
                <Paper sx={{ border: border[1], overflow: "hidden" }} >
                    <Box sx={{
                        minHeight: "150px", display: "flex", alignItems: "flex-end", padding: "20px", color: "white",
                        background: `linear-gradient(13.26deg, #DDA86A -66.32%, #A6773E 247.85%)`
                    }} >
                        <Box>
                            <Typography variant='h4' >
                                {ExamStore.getExam.exam.exam_name}<small style={{ fontWeight: "bold", fontSize: "10px", marginLeft: "5px", letterSpacing: "1px", color: "white", textTransform: "uppercase" }}>{ExamStore.getExam.exam.exam_status}</small>
                            </Typography>
                            <Typography gutterBottom variant='body2' color='white' >{`${formatDateTimeForDisplay( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time )} - ${formatDateTimeForDisplay( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time )}`}</Typography>
                            {ExamStore.getExam.exam.exam_duration !== "null" && ExamStore.getExam.exam.exam_duration !== null && <Typography variant='body2' color='white' > {`Duration: ${ExamStore.getExam.exam.exam_duration.split( ":" )[0]} Hours ${ExamStore.getExam.exam.exam_duration.split( ":" )[1]} Minutes`}</Typography>}
                            <Typography variant='body2' color='white' >Maximum Attempts: {ExamStore.getExam.exam.maximum_attempts}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" padding="20px" gap="10px">
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px", "@media(max-width:800px)": { flexDirection: "column", alignItems: "flex-start", flexWrap: "wrap" } }} >
                            {UserStore.getUser["user_role"] === 'STUDENT' && <Box display="flex" gap="10px" title={new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date() ? "Exam already ended" : ""}>
                                <Button sx={{ '&.Mui-disabled': { pointerEvents: "all", cursor: "not-allowed" } }} color="customThemeColor" variant='contained' startIcon={attempting && <CircularProgress size={14} />} disableElevation onClick={attempt} disabled={attempting || new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date()}>
                                    Attempt test
                                </Button>
                                {new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date() && <Button onClick={() => navigateOrOpen( `/student/scores?selectedExam=${id}` )} variant='contained' disableElevation>
                                    View scores
                                </Button>}
                            </Box>}
                            {UserStore.getUser["user_role"] !== 'STUDENT' && <Box flexWrap="wrap" display="flex" justifyContent="space-between" gap="10px" alignItems="center">
                                <Button sx={{ '&.Mui-disabled': { pointerEvents: "all", cursor: "not-allowed" }, textTransform: "capitalize" }} disableElevation type="button" disabled={( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || isImporting || ExamStore.getExam.exam.exam_status === 'Published' )} startIcon={<GetAppIcon />} onClick={() => setImportDialogStatus( true )} color='customThemeColor' variant='contained'>Import Questions</Button>
                                <Button sx={{ '&.Mui-disabled': { pointerEvents: "all", cursor: "not-allowed" }, textTransform: "capitalize" }} disableElevation disabled={( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || ExamStore.getExam.exam.exam_status === 'Published' )} onClick={() => navigateOrOpen( `/faculty/exams/${ExamStore.getExam.exam.exam_id}/addquestions` )} color="primaryDark" variant='contained' startIcon={<AddCircleOutlineIcon />}>Add questions </Button>
                                {( ExamStore.getExam.exam.exam_status.toLowerCase() === "published" || ( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date() ) ) &&
                                    <Button disableElevation variant='contained' startIcon={<AccessTime />} onClick={() => setRescheduleDialogState( true )} sx={{ textTransform: "capitalize" }} color='secondary'> {( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_end_date, ExamStore.getExam.exam.exam_end_time ) ) < new Date() ) ? "Reopen" : "Reschedule"}</Button>
                                }
                                <Button disableElevation variant='contained' color="success" sx={{ '&.Mui-disabled': { pointerEvents: "all", cursor: "not-allowed" }, textTransform: "capitalize" }} startIcon={Icons.default.AnalyticsIcon} onClick={() => navigateOrOpen( `/faculty/exams/${ExamStore.getExam.exam.exam_id}/participation` )}>View Scores</Button>
                                <Tooltip title={( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || ExamStore.getExam.exam.exam_status === 'Published' ) ? "Exam Published or already started" : `Edit ${ExamStore.getExam?.exam?.exam_name}`}>
                                    <span>
                                        <IconButton sx={{ '&.Mui-disabled': { pointerEvents: "all", cursor: "not-allowed" }, textTransform: "capitalize" }} disabled={( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || ExamStore.getExam.exam.exam_status === 'Published' )} color="secondary" onClick={() => navigateOrOpen( `/faculty/exams/${ExamStore.getExam.exam.exam_id}/edit` )}>{Icons.default.EditIconOutlined}</IconButton>
                                    </span>
                                </Tooltip>
                            </Box>}
                            {UserStore.getUser["user_role"] !== 'STUDENT' && <Box flexWrap="wrap" display="flex" gap="10px" alignItems="center">
                                <Button disableElevation variant='contained' startIcon={Icons.default.DeleteIconOutlined} onClick={() => setDeleteDialogState( true )} sx={{ textTransform: "capitalize" }} color='error'>Delete </Button>
                                {ExamStore.getExam.exam.exam_status.toLowerCase() !== "published" &&
                                    <Button startIcon={publishing ? <CircularProgress size={14} /> : <PublishIcon />} disableElevation variant="contained" sx={{ textTransform: "capitalize" }} onClick={publish} color='secondary'>{publishing ? "Publishing..." : "Publish"}</Button>}
                            </Box>}
                        </Box>
                        {UserStore.getUser["user_role"] !== 'STUDENT' && <Box color={palette.common.font} fontWeight="300" fontSize="12px">
                            <i >To know about the import file format <Typography display="inline" color="secondary" fontSize="14px" sx={{ textDecoration: "underline" }} ><Link to='https://docs.moodle.org/401/en/Aiken_format' className='default-link' target="_blank" >Aiken click here.</Link></Typography> </i>
                        </Box>}
                    </Box>
                    <Typography marginX="20px" textAlign="justify" variant="body2">
                        {ExamStore.getExam.exam.exam_description}
                    </Typography>

                    <Box padding="20px" bgcolor={palette.contentBg} margin="20px" border={border[1]} borderRadius="5px">

                        <Typography variant='h6' fontSize="18px" gutterBottom color={palette.shadedText}>Topics</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap", }} flexWrap="wrap" gap="5px">
                            {ExamStore.getExam.exam.exam_topics && ExamStore.getExam.exam.exam_topics.map( ( topic, index ) => (
                                <Chip key={topic} label={topic} sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg, fontWeight: "600" }} />
                            ) )}
                        </Box>
                        {UserStore.getUser["user_role"] !== 'STUDENT' && <Box marginY="10px">
                            <Typography gutterBottom variant='h6' fontSize="18px" marginTop="20px" color={palette.shadedText}>Exam for batch(es):</Typography>
                            <Box display="flex" gap="10px" flexWrap="wrap" alignItems="center">
                                {batches && batches.map( ( batch, index ) => (
                                    <Chip key={batch} label={batch} sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg, fontWeight: "600" }} />
                                ) )}
                                {( !batches || batches.length === 0 ) && <Typography variant='body2' color="textSecondary">Exam is not yet assigned to batches!</Typography>}
                            </Box>
                        </Box>}
                        <Typography variant='h6' fontSize="18px" marginTop="20px" display="flex" alignItems="center" gap="10px" color={palette.shadedText}>Attachments<AttachmentIcon fontSize="small" /> </Typography>
                        <Box sx={{ padding: "10px 0", display: "flex", flexWrap: "wrap", }} flexWrap="wrap" gap="10px">
                            {ExamStore.getExam.exam.exam_attachments && ExamStore.getExam.exam.exam_attachments.map( ( attachment ) => (
                                <Chip href={`${ATTACHMENTS_BASE_URL}${attachment}`} clickable deleteIcon={<DownloadIcon />} onDelete={e => window.open( `${ATTACHMENTS_BASE_URL}${attachment}` )} sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg, fontWeight: "600" }} label={attachment.split( "exam/" )[1]} key={attachment} />
                            ) )}
                            {!ExamStore.getExam.exam.exam_attachments &&
                                <Typography variant='body2' mt={-1}>No attachments provided!</Typography>
                            }
                        </Box>
                        {UserStore.getUser["user_role"] !== 'STUDENT' && <Box>

                            <Box display="flex" justifyContent="space-between" borderBottom={`1px solid ${palette.borderColor}`} marginTop="10px" flexWrap="wrap" gap="10px">
                                <Typography variant='h6' fontSize="18px" color={palette.shadedText} marginTop="20px">Questions</Typography>
                                <Box display="flex" alignItems="center" gap="20px">
                                    {!( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() || ExamStore.getExam.exam.exam_status === 'Published' ) && questions && questions.length > 0 && <Button color="greyed" size='small' onClick={() => navigate( `/faculty/exams/${ExamStore.getExam.exam.exam_id}/questions` )} sx={{ fontSize: "14px", textTransform: "capitalize" }} startIcon={<Edit sx={{ fontSize: "16px !important" }} />} >Edit questions</Button>}
                                    <IconButton sx={{ flexShrink: "0" }} size="small" onClick={getQuestions} >
                                        {<RefreshIcon sx={{ fontSize: "20px" }} />}
                                    </IconButton>
                                </Box>
                            </Box>

                            {questions && questions.length > 0 && <Box display="flex" marginTop="10px" flexDirection="column" gap="10px">
                                {questions.map( ( question, index ) => (
                                    <Box sx={{ padding: "10px 0", fontSize: "14px", borderRadius: "5px", "& p": { margin: "0 " } }} key={question.question.question_id}>
                                        <Typography variant='subtitle2' fontSize="10px" > <em>Question {index + 1}</em> </Typography>
                                        <Box>
                                            <Box color={palette.shadedText}>
                                                <strong> {question.question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField latex={question.question.question_text.trim().split( "LATEX-" )[1]} id='static-latex' style={{ padding: "0px", fontFamily: "'Roboto' sans-serif !important", fontWeight: "bold", color: palette.shadedText }}> </EditableMathField> : <Latex delimiters={latexDelimiters}>{question.question.question_text.trim()}</Latex>}</strong>
                                            </Box>
                                            <Box display="inline-flex" color={palette.shadedText} marginTop="5px">
                                                <Chip size='small' sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg }} label={question.question.question_type}></Chip>
                                                {question.question.question_type === 'MCQ' && <Chip size='small' sx={{ marginRight: "5px", borderRadius: "5px", border: `1px solid ${flatColorsAndBackgrounds[2].color}`, color: flatColorsAndBackgrounds[2].color, bgcolor: flatColorsAndBackgrounds[2].bg }} label={question.question.question_answer_selection}></Chip>}
                                            </Box>
                                        </Box>
                                    </Box>
                                ) )}
                            </Box>}
                            {( !questions || questions.length === 0 ) && <Typography color="textSecondary" marginTop="10px" variant='body2'>Questions not yet added!</Typography>}
                        </Box>
                        }
                    </Box>
                </Paper>
            </>
            }
            {inProgress &&
                <Paper height="100%">
                    <DataLoadingSpinner waitingMessage="Loading exam..." />
                </Paper>
            }
        </Box> :
            <Box flexGrow={1} display="flex" gap="20px" flexDirection="column" padding="20px" >
                <Paper sx={{ flexGrow: 1, display: "flex", gap: "20px", flexDirection: "column" }}>
                    <Box display="flex" gap="20px" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="20px">
                        <img width="180px" src={examNotFoundImg} alt="Exam not found" />
                        <Typography color="error.main" variant="h6">404 ERROR</Typography>
                        <Typography variant="subtitle2">Ooops! The exam you are looking for does not exists any more!</Typography>
                        <Button onClick={navigateToExamsPage} startIcon={Icons.default.ArrowBack} sx={{ textTransform: "capitalize" }} variant="outlined" color="error">Back to exams</Button>
                    </Box>
                </Paper>
            </Box>
    )
} )

export default Exam