import { observable, makeObservable, action, runInAction, toJS, computed } from 'mobx'
import api from '../service/api'
import React from 'react'
import notificationStore from './notification.store'
import UserStore from './user.store'

class DiscussionForumStore {

    discussions
    discussion
    votes

    constructor () {
        this.votes = null
        this.discussions = []
        this.discussion = null
        makeObservable( this, {
            discussions: observable,
            discussion: observable,
            votes: observable,
            createDiscussion: action,
            updateDiscussion: action,
            deleteDiscussion: action,
            deleteComment: action,
            fetchDiscussions: action,
            fetchUserDiscussions: action,
            fetchDiscussion: action,
            fetchDiscussionForAdmin: action,
            voteComment: action,
            postComment: action,
            updateComment: action,
            getDiscussions: computed,
            getDiscussion: computed,
            getVotes: computed,
        } )
    }

    get getDiscussions() {
        return toJS( this.discussions )
    }

    get getDiscussion() {
        return toJS( this.discussion )
    }

    get getVotes() {
        return toJS( this.votes )
    }

    async createDiscussion( values ) {
        try {
            const { data: { data } } = await api.discussionForum.createDiscussion( values )
            return { success: true, data: data }
        } catch ( err ) {
            console.log( err )
            notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while posting your discussion." )
            return false
        }
    }

    async updateDiscussion( id, values ) {
        try {
            const { data } = await api.discussionForum.updateDiscussion( id, values )
            if ( data.success ) {
                runInAction( () => {
                    this.discussion.details = data.data
                } )
            }
            return { success: true }
        } catch ( err ) {
            console.log( err )
            notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while updating your discussion." )
            return false
        }
    }


    async fetchDiscussions( options ) {
        try {
            const { data } = await api.discussionForum.fetchDiscussions( options )
            runInAction( () => {
                this.discussions = data.success ? data.data : []
            } )
            if ( !this.votes )
                this.fetchVotes()
            return { success: true }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else {
                notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching discussions." )
                return false
            }
        }
    }

    async fetchUserDiscussions() {
        try {
            const { data } = await api.discussionForum.fetchUserDiscussions()
            runInAction( () => {
                this.discussions = data.success ? data.data : []
            } )
            if ( !this.votes )
                this.fetchVotes()
            return { success: true }
        } catch ( err ) {
            console.log( err )
            notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching discussions." )
            return false
        }
    }

    async fetchDiscussion( id, setNull = true ) {
        try {
            if ( setNull )
                runInAction( () => {
                    this.discussion = null
                } )
            const { data } = await api.discussionForum.fetchDiscussion( id )
            runInAction( () => {
                this.discussion = data.success ? data.data : null
            } )
            if ( !this.votes )
                this.fetchVotes()
            return { success: true }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
                return { unauth: true }
            } else if ( err?.response?.status === 403 || err?.response?.data?.forbidden )
                return { success: false, forbidden: true }
            else if ( err?.response?.status === 404 )
                return { success: false, notfound: true }
            else {
                notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching discussion." )
                return { success: false }
            }
        }
    }

    async fetchDiscussionForAdmin( id ) {
        try {
            const { data } = await api.discussionForum.fetchDiscussion( id )
            runInAction( () => {
                this.discussion = data.success ? data.data : null
            } )
        } catch ( err ) {
            console.log( err )
            notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while fetching discussion." )
            return { success: false }
        }
    }


    async deleteDiscussion( id ) {
        try {
            await api.discussionForum.deleteDiscussion( id )
            notificationStore.setNotification( true, "Discussion deleted" )
            return { success: true }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else if ( err?.response?.status === 403 || err?.response?.data?.forbidden )
                return { success: false, forbidden: true }
            else {
                notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while deleting your discussion." )
                return { success: false }
            }
        }
    }


    async postComment( discussionId, payload ) {
        try {
            const { data } = await api.discussionForum.postComment( discussionId, payload )
            if ( data.success ) {
                runInAction( () => {
                    this.discussion.comments.push( data.data )
                } )
            }
            return true
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else {
                notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while posting your comment." )
                return false
            }
        }
    }


    async updateComment( id, payload, commentPos ) {
        try {
            const { data } = await api.discussionForum.updateComment( id, payload )
            if ( data.success ) {
                runInAction( () => {
                    this.discussion.comments[commentPos].comment_content = payload.comment_content
                    this.discussion.comments[commentPos].updated_at = Date.now()
                } )
            }
            notificationStore.setNotification( true, "Update successful!" )
            return true
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else {
                notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while updating your comment." )
                return false
            }
        }
    }

    async deleteComment( id, commentPos ) {
        try {
            await api.discussionForum.deleteComment( id, )
            runInAction( () => {
                const comments = [...this.discussion.comments]
                comments.splice( commentPos, 1 )
                this.discussion.comments = comments
            } )
            // if ( this.discussion ) this.fetchDiscussion( this.discussion.details?._id )
            notificationStore.setNotification( true, "Comment deleted" )
            return { success: true }
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else {
                notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while deleting your comment." )
                return { success: false }
            }
        }
    }

    async voteComment( comment_id, vote, commentPos ) {
        try {
            const { data } = await api.discussionForum.voteComment( comment_id, { vote } )
            if ( data.success )
                runInAction( () => {
                    this.votes = data.data.votes
                    this.discussion.comments[commentPos] = data.data.comment
                } )
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else {
                notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while voting the comment." )
                return false
            }
        }
    }

    async voteDiscussion( discussion_id, vote ) {
        try {
            const { data } = await api.discussionForum.voteDiscussion( discussion_id, { vote } )
            if ( data.success )
                runInAction( () => {
                    this.votes = data.data.votes
                    this.discussion.details = data.data.discussion
                } )
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                UserStore.setERPSessionState( "ended" )
            } else {
                notificationStore.setNotification( true, err?.response?.data?.message || "Something went wrong while voting the discussion." )
                return false
            }
        }
    }

    async fetchVotes() {
        try {
            const { data } = await api.discussionForum.getUserVotes()
            runInAction( () => {
                this.votes = data.success ? data.data : null
            } )
        } catch ( err ) {
            console.log( err )
        }
    }


}


const DiscussionForumInstance = new DiscussionForumStore()

export default DiscussionForumInstance

const DiscussionForumContext = React.createContext()

export const DiscussionForumProvider = ( { children, store } ) => {
    return (
        <DiscussionForumContext.Provider value={store}>{children}</DiscussionForumContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useDiscussionForum = () => React.useContext( DiscussionForumContext )