import { Box, TableCell, TableRow, Tooltip, useTheme } from '@mui/material'
import React from 'react'
import Progress from '../common/Progress'
import PendingDataList from './PendingDataList'

const AuditStudentRow = ( { setDetailsDialogState, setSelectedStudent, requiredChecklistItemsCount, item } ) => {

    const { palette, table } = useTheme()

    return (
        <TableRow className='table-row' sx={{ "&:hover": { background: palette.rowHover }, cursor: " pointer" }}>
            <TableCell onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px" }}><strong>{item.auid}</strong></TableCell>
            <TableCell onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px", maxWidth: "200px", textTransform: "capitalize" }}><strong>{item.student_name?.toLowerCase()}</strong></TableCell>
            <TableCell onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px" }}><strong>{requiredChecklistItemsCount}</strong></TableCell>
            <TableCell onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} sx={{ fontSize: "12px", color: table.color, padding: "10px 20px" }}>
                <strong> <em>{item.pendingCount}</em> </strong>
            </TableCell>
            <TableCell sx={{ padding: "10px 20px" }}>
                <PendingDataList onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} list={item.pendingItems} item={item} />
            </TableCell>
            <TableCell onClick={() => { setDetailsDialogState( true ); setSelectedStudent( item ) }} align="right" sx={{ padding: "10px 20px" }}>
                <Box display="flex" justifyContent="flex-end" gap="10px">
                    <Tooltip sx={{ cursor: "pointer" }} placement="top" title="Required checklist completion percentage">
                        <span>
                            <Progress size={51} progress={item.requiredItemsCompletionPercentage || 0} />
                        </span>
                    </Tooltip>
                    <Tooltip sx={{ cursor: "pointer" }} placement="top" title="Total progress">
                        <span>
                            <Progress size={51} progress={item.totalProgress || 0} />
                        </span>
                    </Tooltip>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default AuditStudentRow
