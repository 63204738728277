import { Box, Button, Card, IconButton, Popover, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Icons, calendarColors, leadingZeroFormatter, months, showDateInIndianDateFormat, weekDays } from '../../utils/utilities'
import { useUserStore } from '../../store/user.store'
import { useRoomStore } from '../../store/room.store'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import AddQuizFromClassDialog from '../exams-components/quiz-questionbank/class-quizzes/AddQuizFromClassDialog'
import api from '../../service/api'
import CalendarDateColumn from './CalendarDateColumn'


const checkTense = ( testDate, month, year ) => {
    const dateVal = new Date()
    const strDate = showDateInIndianDateFormat( dateVal )
    const testDateVal = new Date( `${year}-${month}-${testDate}` )
    const strTestDate = showDateInIndianDateFormat( testDateVal )
    return {
        isToday: strDate === strTestDate,
        isFuture: dateVal < testDateVal,
    }
}

const WeekCalendar = ( { shadow = false } ) => {

    const [selectedDate, setSelectedDate] = useState( new Date() )
    const [selectedDateDetails, setSelectedDateDetails] = useState( { day: selectedDate.getDate(), year: selectedDate.getFullYear(), month: selectedDate.getMonth() } )
    const [createNewQuizDialogState, setCreateNewQuizDialogState] = useState( false )
    const [classDetailsAnchor, setClassDetailsAnchor] = useState( null )
    const [roomSelectedForQuiz, setRoomSelectedForQuiz] = useState( null )
    const [selectedRoom, setSelectedRoom] = useState( null )
    const [calendarDates, setCalendarDates] = useState( [] )
    const [quizzes, setQuizzes] = useState( [] )
    const [ending, setEnding] = useState( false )
    const [areQuizzesLoading, setAreQuizzesLoading] = useState( true )

    const { palette, border, theme } = useTheme()

    const UserStore = useUserStore()
    const RoomStore = useRoomStore()

    const navigate = useNavigate()


    // Room Functions


    const startSession = async ( data, flag ) => {

        if ( flag === true ) {
            data["record"] = true
        } else {
            data["record"] = false
        }
        if ( data["sTime"] === undefined || data["sTime"] === null || data["eTime"] === undefined || data["eTime"] === null ) {
            toast.error( "ERP not sending Start Time / End Time" )
            return
        } else if ( checkTime( data["sTime"], data["eTime"] ) === false ) {
            toast.error( `You can only start session from ${data["sTime"].substring( 0, 5 )} to ${data["eTime"].substring( 0, 5 )}` )
            return
        }

        try {
            var res = await RoomStore.startSession( data )
            if ( res["status"] === true ) {
                var userAgent = window.navigator.userAgent.toLowerCase()
                if ( userAgent.match( /ipad/i ) || userAgent.match( /iphone/i ) ) {
                    window.open( res["data"], 'Acharya Live', "width=" + window.innerWidth + ",height=" + window.innerHeight )
                }
                else {
                    RoomStore.setStreamDetails( true, res["data"], data, true )
                    navigate( '/faculty/stream' )
                }
            }

            setClassDetailsAnchor( null )
            setSelectedRoom( null )
        } catch ( err ) {
            console.log( err )
        }
    }

    const joinSession = async ( data ) => {
        try {
            let res = await RoomStore.joinSessionAsModerator( data )
            if ( res["status"] === true ) {
                //window.open(res["data"],'Acharya Live','height=720,width=1280');

                var userAgent = window.navigator.userAgent.toLowerCase()
                if ( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) ) {
                    // iPad or iPhone
                    window.open( res["data"], 'Acharya Live', "width=" + window.innerWidth + ",height=" + window.innerHeight )
                }
                else {
                    RoomStore.setStreamDetails( true, res["data"], data, true )
                    navigate( '/faculty/stream' )
                }

                setClassDetailsAnchor( null )
                setSelectedRoom( null )
            }
        } catch ( err ) {
            console.log( err )
        }
    } //endSession

    const joinSessionAsViewer = async ( data ) => {
        try {
            let res = await RoomStore.joinSession( data )
            if ( res["status"] === true ) {
                //window.open(res["data"],'Acharya Live','height=720,width=1280');

                var userAgent = window.navigator.userAgent.toLowerCase()
                if ( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) ) {
                    // iPad or iPhone
                    window.open( res["data"], 'Acharya Live', "width=" + window.innerWidth + ",height=" + window.innerHeight )
                }
                else {
                    RoomStore.setStreamDetails( true, res["data"], data, true )
                    if ( UserStore.getUser['user_role'] === "STUDENT" )
                        navigate( '/student/stream' )
                    else
                        navigate( '/faculty/stream' )
                }

                setClassDetailsAnchor( null )
                setSelectedRoom( null )
            }
        } catch ( err ) {
            console.log( err )
        }
    }


    const endSession = async ( data ) => {
        setEnding( true )
        await RoomStore.endSession( data )
        setClassDetailsAnchor( null )
        setSelectedRoom( null )
        setEnding( false )
    }


    const checkTime = ( sTime, eTime ) => {
        // if ( UserStore.getUser.user_id === 'AI002432' ) {
        //     return true

        // } else {
        var currentDate = new Date()
        var currentTime = currentDate.toString().split( " " )[4]
        var sTimeSplit = sTime.split( ":" )
        var eTimeSplit = eTime.split( ":" )
        var cTimeSplit = currentTime.split( ":" )
        var sTimeHr = parseInt( sTimeSplit[0] ), sTimeMin = parseInt( sTimeSplit[1] )
        var eTimeHr = parseInt( eTimeSplit[0] ), eTimeMin = parseInt( eTimeSplit[1] )
        var cTimeHr = parseInt( cTimeSplit[0] ), cTimeMin = parseInt( cTimeSplit[1] )
        var startMin = 0, endMin = 0, currentMin = 0
        if ( sTimeHr === 0 ) {
            startMin = sTimeMin
        } else if ( sTimeHr > 0 ) {
            startMin = ( ( 60 * sTimeHr ) + sTimeMin )
        }
        if ( eTimeHr === 0 ) {
            endMin = eTimeMin
        } else if ( eTimeHr > 0 ) {
            endMin = ( ( 60 * eTimeHr ) + eTimeMin )
        }
        if ( cTimeHr === 0 ) {
            currentMin = cTimeMin
        } else if ( cTimeHr > 0 ) {
            currentMin = ( ( 60 * cTimeHr ) + cTimeMin )
        }

        if ( currentMin >= ( startMin - 5 ) && currentMin <= endMin ) {
            return true
        }
        else {
            return false
        }
        // }
    }

    const nextWeek = () => {
        setSelectedDate( new Date( selectedDateDetails.year, selectedDateDetails.month, selectedDateDetails.day + 7 ) )
    }

    const previousWeek = () => {
        setSelectedDate( new Date( selectedDateDetails.year, selectedDateDetails.month, selectedDateDetails.day - 7 ) )
    }

    const getData = useCallback( async () => {
        setAreQuizzesLoading( true )
        try {
            await RoomStore.getMyActiveSessions()
            if ( UserStore.getUser.user_role === 'STUDENT' ) {
                const response = await api.quiz.fetchQuizes()
                if ( response.data.success ) {
                    setQuizzes( response.data.data )
                }
            } else {
                const response = await api.quiz.fetchAllInstances()
                if ( response.data.success ) {
                    setQuizzes( response.data.data )
                }
            }
        } catch ( err ) {
            console.log( err )
        } finally {
            setAreQuizzesLoading( false )
        }
    }, [UserStore, RoomStore] )


    const generateDates = useCallback( ( { month, year, day } ) => {
        let lastDate = new Date( year, month + 1, 0 ).getDate()
        let previousMonthLastDate = new Date( year, month, 0 ).getDate()
        let dates = []

        let currentWeekDay = selectedDate.getDay() - 1
        currentWeekDay = currentWeekDay === -1 ? 6 : currentWeekDay

        if ( day - currentWeekDay < 1 ) {
            for ( let i = currentWeekDay - 1; i > -1; i-- ) {
                const newDate = {
                    date: previousMonthLastDate - i,
                    active: false,
                    complete_date: `${previousMonthLastDate - i}-${( month - 1 < 0 ? 11 : month - 1 ) + 1}-${month - 1 < 0 ? year - 1 : year}`,
                    erp_date: `${month - 1 < 0 ? year - 1 : year}-${leadingZeroFormatter.format( ( month - 1 < 0 ? 11 : month - 1 ) + 1 )}-${leadingZeroFormatter.format( previousMonthLastDate - i )}`,
                }
                newDate.tense = checkTense( previousMonthLastDate - i, ( month - 1 < 0 ? 11 : month - 1 ) + 1, month - 1 < 0 ? year - 1 : year )
                dates.push( newDate )
            }
        } else {
            for ( let i = currentWeekDay; i > 0; i-- ) {
                const newDate = { date: day - i, active: true, complete_date: `${day - i}-${month + 1}-${year}`, erp_date: `${year}-${leadingZeroFormatter.format( month + 1 )}-${leadingZeroFormatter.format( day - i )}` }
                newDate.tense = checkTense( day - i, month + 1, year )
                dates.push( newDate )
            }
        }
        const selectedDateData = {
            date: day, active: true, isToday: true,
            complete_date: `${day}-${month + 1}-${year}`,
            erp_date: `${year}-${leadingZeroFormatter.format( month + 1 )}-${leadingZeroFormatter.format( day )}`
        }
        selectedDateData.tense = checkTense( day, month + 1, year )
        dates.push( selectedDateData )
        if ( day + ( 6 - currentWeekDay ) > lastDate ) {
            let nextMonthDays = ( 7 - currentWeekDay )
            for ( let i = 1; i <= lastDate - day; i++ ) {
                const newDate = {
                    date: day + i, active: true,
                    complete_date: `${day + i}-${( month ) + 1}-${year}`,
                    erp_date: `${year}-${leadingZeroFormatter.format( ( month ) + 1 )}-${leadingZeroFormatter.format( day + i )}`,
                }
                newDate.tense = checkTense( day + i, month + 1, year )
                dates.push( newDate )
                nextMonthDays--
            }
            for ( let i = 1; i <= nextMonthDays; i++ ) {
                const newDate = {
                    date: i, active: false,
                    complete_date: `${i}-${( month + 1 > 11 ? 0 : month + 1 ) + 1}-${month + 1 > 11 ? year + 1 : year}`,
                    erp_date: `${month + 1 > 11 ? year + 1 : year}-${leadingZeroFormatter.format( ( month + 1 > 11 ? 0 : month + 1 ) + 1 )}-${leadingZeroFormatter.format( i )}`,
                }
                newDate.tense = checkTense( i, ( month + 1 > 11 ? 0 : month + 1 ) + 1, month + 1 > 11 ? year + 1 : year )
                dates.push( newDate )
            }
        } else {
            for ( let i = 1; i <= 6 - currentWeekDay; i++ ) {
                const newDate = {
                    date: day + i, active: false,
                    complete_date: `${day + i}-${( month ) + 1}-${year}`,
                    erp_date: `${year}-${leadingZeroFormatter.format( ( month ) + 1 )}-${leadingZeroFormatter.format( day + i )}`,
                }
                newDate.tense = checkTense( day + i, month + 1, year )
                dates.push( newDate )
            }
        }
        setCalendarDates( dates )
    }, [selectedDate] )

    useEffect( () => {
        generateDates( selectedDateDetails )
    }, [selectedDateDetails, generateDates] )

    useEffect( () => {
        setSelectedDateDetails( { day: selectedDate.getDate(), year: selectedDate.getFullYear(), month: selectedDate.getMonth() } )
    }, [selectedDate] )

    useEffect( () => {
        getData()
    }, [getData] )


    return (
        <Card>

            {roomSelectedForQuiz && <AddQuizFromClassDialog fetchQuizzesInstances={getData} createMode={createNewQuizDialogState} setCreateMode={setCreateNewQuizDialogState} state={Boolean( roomSelectedForQuiz )} room={roomSelectedForQuiz} setState={setRoomSelectedForQuiz} />}

            <Box position="relative" borderRadius="10px" padding="20px">
                <Box display="flex" justifyContent="space-between" alignItems="center" gap="20px">
                    <Box position="relative"    >
                        <Typography variant='h6' display="flex" padding="2px 10px" borderRadius="5px" sx={{ cursor: "pointer", "&:hover": { background: palette.rowHover } }} alignItems="center" gap="10px">{months[selectedDateDetails?.month]}, {selectedDateDetails?.year} {Icons.small.KeyboardArrowDown} </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{ opacity: 0, position: "absolute", inset: 0, fontSize: "14px", '& input': { padding: "10px" } }}
                                disableToolbar
                                variant="inline"
                                format="DD-MM-YYYY"
                                id="date-picker-inline"
                                value={dayjs( selectedDate )}
                                onChange={( val ) => setSelectedDate( new Date( val ) )}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box display="flex" alignItems="center" gap="30px">
                        <Typography variant="subtitle2" color={calendarColors.offlineDark}
                            sx={{
                                "&::before": {
                                    content: `''`,
                                    position: "absolute",
                                    top: "50%",
                                    left: "-10px",
                                    transform: "translateY(-50%)",
                                    height: "5px",
                                    width: "5px",
                                    borderRadius: "50%",
                                    background: calendarColors.offlineDark,
                                },
                                position: "relative"
                            }}
                        >
                            Offline
                        </Typography>
                        <Typography variant="subtitle2" color={calendarColors.onlineDark}
                            sx={{
                                "&::before": {
                                    content: `''`,
                                    position: "absolute",
                                    top: "50%",
                                    left: "-10px",
                                    transform: "translateY(-50%)",
                                    height: "5px",
                                    width: "5px",
                                    borderRadius: "50%",
                                    background: calendarColors.onlineDark,
                                },
                                position: "relative"
                            }}
                        >
                            Online
                        </Typography>
                    </Box>
                </Box>
                <Box marginTop="20px">
                    {/* DAYS */}
                    <Box sx={{ zIndex: 1, position: "relative" }} overflow="auto" minHeight="250px" className='slim-custom-scrollbar' display="flex" gap="10px">
                        {!areQuizzesLoading && calendarDates.length > 0 && weekDays.map( ( day, index ) => {
                            const { isToday, isFuture } = calendarDates[index]?.tense || { isToday: false, isFuture: false }
                            return (
                                <CalendarDateColumn key={index} day={day} quizzesData={quizzes} setSelectedRoom={setSelectedRoom} setClassDetailsAnchor={setClassDetailsAnchor} isToday={isToday} isFuture={isFuture} dateDetails={calendarDates[index]} />
                            )
                        } )}
                    </Box>
                </Box>

                <Popover elevation={1} onClose={() => {
                    setClassDetailsAnchor( null )
                    setSelectedRoom( null )
                }} anchorEl={classDetailsAnchor} sx={{ border: theme === 'dark' && border[1] }} open={Boolean( classDetailsAnchor )} >
                    <Box padding="20px" sx={{
                        background: theme === 'dark' ? palette.greyedOut : palette.common.bg
                    }} >
                        <Typography fontSize="16px">{selectedRoom?.branch_name || selectedRoom?.course_short_name}, <em>{selectedRoom?.subject_name || selectedRoom?.subject_name_short}</em></Typography>
                        <Typography textTransform="capitalize" fontSize="12px">
                            {selectedRoom?.interval_type_id === "11" ? 'Internal' : ''}
                            {selectedRoom?.interval_type_id === "10" ? 'Online Class' : ''}
                            {selectedRoom?.interval_type_id === "9" ? 'Internship' : ''}
                            {selectedRoom?.interval_type_id === "8" ? 'Studio' : ''}
                            {selectedRoom?.interval_type_id === "5" ? 'Elective Lecture Class' : ''}
                            {selectedRoom?.interval_type_id === "4" ? 'Lab' : ''}
                            {selectedRoom?.interval_type_id === "3" ? 'Lecture Class' : ''}
                        </Typography>
                        <Typography fontSize="14px" fontWeight="400">{selectedRoom?.start_time}-{selectedRoom?.end_time}</Typography>
                        {selectedRoom?.status === 'Completed' && <Typography sx={{ color: selectedRoom?.date?.isFuture ? selectedRoom?.is_offline ? calendarColors.offlineDark : calendarColors.onlineDark : selectedRoom?.date?.isToday ? palette.common.font : "GrayText" }} fontSize="16px" color='error' textAlign="right" fontWeight="bold">Session Completed</Typography>}
                        {( selectedRoom?.date?.isFuture || selectedRoom?.date?.isToday ) && selectedRoom?.status !== 'Completed' && <Box display="flex" marginTop="10px" justifyContent="flex-start" alignItems="center" gap="5px">

                            {
                                // room.room_status === 'online'
                                selectedRoom?.does_exists > 0 && selectedRoom?.online !== '0' &&
                                <Button disabled={selectedRoom?.date?.isFuture || ending} disableElevation variant='contained' color="error" sx={{ minWidth: "initial", textTransform: "capitalize" }} size="small" onClick={( e ) => endSession( selectedRoom )} >
                                    {ending ? "Ending..." : "End"}
                                </Button>
                            }
                            {
                                // selectedRoom?.room_status === 'online'
                                selectedRoom?.does_exists > 0 && selectedRoom?.empID === UserStore.getUser["user_id"] && selectedRoom?.online !== '0' &&
                                <Button disabled={selectedRoom?.date?.isFuture} variant='contained' disableElevation sx={{ minWidth: "initial", textTransform: "capitalize" }} size="small" onClick={( e ) => joinSession( selectedRoom )} >
                                    Join
                                </Button>
                            }
                            {
                                // selectedRoom?.room_status === 'online'
                                selectedRoom?.empID !== UserStore.getUser["user_id"] && selectedRoom?.online !== '0' &&
                                <Button disabled={selectedRoom?.date?.isFuture} variant='contained' disableElevation sx={{ minWidth: "initial", textTransform: "capitalize" }} size="small" onClick={( e ) => joinSessionAsViewer( selectedRoom )} >
                                    Join
                                </Button>
                            }
                            {
                                // selectedRoom?.room_status === 'online'
                                selectedRoom?.does_exists === 0 && selectedRoom?.empID === UserStore.getUser["user_id"] && selectedRoom?.online !== '0' &&
                                <Button disableElevation variant='contained' disabled={selectedRoom?.date?.isFuture} sx={{ minWidth: "initial", textTransform: "capitalize" }} size="small" color="primary" onClick={( e ) => startSession( selectedRoom, false )}
                                    //disabled={RoomStore.getActiveRoom.length > 0}
                                    endIcon={Icons.default.ChevronRightIcon}>
                                    start
                                </Button>
                            }

                            {['faculty', 'hod', 'principal'].includes( UserStore.getUser.user_role.toLowerCase() ) && selectedRoom.quizzes.length === 0 && <Button
                                disableElevation
                                variant='contained'
                                sx={{
                                    minWidth: "initial",
                                    textTransform: "capitalize"
                                }}
                                size="small"
                                color="primary"
                                onClick={( e ) => { setRoomSelectedForQuiz( selectedRoom ); setClassDetailsAnchor( null ) }}
                                endIcon={Icons.default.QuizIconOutlined}
                            >
                                Assign Quiz
                            </Button>}

                            {['faculty', 'hod', 'principal'].includes( UserStore.getUser.user_role.toLowerCase() ) && selectedRoom.quizzes.length > 0 && selectedRoom.quizzes?.map( quiz => {
                                return <Tooltip key={quiz.id} title={quiz.title}>
                                    <Button key={quiz.id} disableElevation variant='contained' sx={{ minWidth: "initial", textTransform: "capitalize" }} size="small" color="primary" onClick={( e ) => { navigate( `/faculty/subject/${selectedRoom.subject_id}/quiz/${quiz.quiz_id}?selectedQuizInstance=${quiz.id}` ); setClassDetailsAnchor( null ) }} endIcon={Icons.default.QuizIconOutlined}>
                                        {`Open quiz`}
                                    </Button>
                                </Tooltip>
                            } )}

                            {UserStore.getUser.user_role === 'STUDENT' && selectedRoom.quizzes && selectedRoom.quizzes.length > 0 && selectedRoom.quizzes?.map( quiz => {
                                return <Tooltip key={quiz.id} title={quiz.title} >
                                    <Button disableElevation variant='contained' disabled={selectedRoom?.date?.isFuture} sx={{ minWidth: "initial", textTransform: "capitalize" }} size="small" color="primary" onClick={( e ) => { navigate( `/student/quiz?quiz_id=${quiz.quiz_id}&instance_id=${quiz.id}` ); setClassDetailsAnchor( null ) }} endIcon={Icons.default.QuizIconOutlined}>
                                        {`Take Quiz`}
                                    </Button>
                                </Tooltip>
                            } )}

                            {selectedRoom?.does_exists === 0 && selectedRoom?.empID === UserStore.getUser["user_id"] && selectedRoom?.online !== '0' &&
                                <IconButton color="primary" disabled={selectedRoom?.date?.isFuture} onClick={( e ) => startSession( selectedRoom, true )} aria-label="Start & Recording Session">
                                    {Icons.default.PlayCircleFilledWhiteIcon}
                                </IconButton>
                            }
                        </Box>}
                    </Box>
                </Popover>

                <Box position="absolute" sx={{ zIndex: "20" }} bottom="20px" border="1px solid #d3d3d3" borderRadius="50%" boxShadow="0px 2px 5px 0px #DBDBDBAB" left="20px">
                    <IconButton onClick={previousWeek} size="small">
                        {Icons.default.ArrowBack}
                    </IconButton>
                </Box>
                <Box position="absolute" sx={{ zIndex: "20" }} bottom="20px" border="1px solid #d3d3d3" borderRadius="50%" boxShadow="0px 2px 5px 0px #DBDBDBAB" right="20px">
                    <IconButton onClick={nextWeek} size="small">
                        {Icons.default.ArrowForward}
                    </IconButton>
                </Box>
            </Box>
        </Card>
    )
}

export default WeekCalendar


