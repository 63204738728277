import { Box, Button, Checkbox, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import { observer } from 'mobx-react'
import Latex from 'react-latex-next'
import { latexDelimiters } from '../../../utils/utilities'

const AttemptQuestion = observer( ( { resetCurrentQuestion, goToNextQuestion, index, question } ) => {


    const QuizStore = useQuizAndQuestionbankStore()

    const [value, setValue] = useState( QuizStore.getAttemptingAnswers[question.id] ? QuizStore.getAttemptingAnswers[question.id] : question.selection_type === 'single' ? null : [] )

    const { palette } = useTheme()

    const changeMultipleAnswersValues = ( val, index ) => {
        try {
            let newValues = [...value]
            newValues[index] = val
            setValue( newValues )
            QuizStore.setAttemptingAnswers( { ...QuizStore.getAttemptingAnswers, [question.id]: newValues } )
        } catch ( err ) { }
    }

    const changeValue = ( val ) => {
        setValue( val )
        QuizStore.setAttemptingAnswers( { ...QuizStore.getAttemptingAnswers, [question.id]: val } )
    }

    const reset = () => {
        resetCurrentQuestion()
        setValue( question.selection_type === 'single' ? null : [] )
    }

    useEffect( () => {
        setValue( QuizStore.getAttemptingAnswers[question.id] ? QuizStore.getAttemptingAnswers[question.id] : question.selection_type === 'single' ? null : [] )
    }, [QuizStore, index, setValue, question] )

    return (
        <Box>
            <Box >

                <Box className='questionbank-wysiwyg-container' fontWeight="700" marginTop="-5px" fontSize="16px">
                    <Latex delimiters={latexDelimiters}>{question.question_text}</Latex>
                </Box>

                <Typography variant='subtitle2' color="textSecondary">
                    <em>{question.question_type}</em> <em>{question.selection_type}</em>
                </Typography>

                {question.selection_type === "single" && <Box marginTop="20px" paddingLeft="10px">
                    <RadioGroup
                        sx={{ display: "flex", width: "max-content", flexDirection: "column", gap: "10px" }}
                        value={value || ""}
                        onChange={e => changeValue( e.target.value )}
                    >
                        {question.options.map( ( option, optionIndex ) => {
                            return (
                                <FormControlLabel
                                    sx={{
                                        padding: { xs: "5px 10px", md: "5px 200px 5px 10px" },
                                        background: value && +value === optionIndex ? palette.success.main : "none",
                                        border: `1px solid ${value && +value === optionIndex ? palette.success.main : "#d3d3d3"}`,
                                        borderRadius: "5px",
                                    }}
                                    key={optionIndex}
                                    value={optionIndex}
                                    control={
                                        <Radio
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: "white",
                                                }
                                            }}
                                            size='medium'
                                            color='success' />
                                    }
                                    label={
                                        <Box color={value && +value === optionIndex ? "white" : palette.defaultColor} paddingLeft="20px" fontSize="14px">
                                            <Latex delimiters={latexDelimiters}>{option.option_text}</Latex>
                                        </Box>
                                    }
                                />
                            )
                        } )}
                    </RadioGroup>
                </Box>}
                {question.selection_type === "multiple" && <Box
                    marginTop="20px"
                    paddingLeft="10px"
                    display="flex"
                    width="max-content"
                    flexDirection="column"
                    gap="10px"
                    value={value || ""}
                >
                    {question?.options && question.options.map( ( option, optionIndex ) => {
                        return (
                            <FormControlLabel
                                sx={{
                                    padding: { xs: "5px 10px", md: "5px 200px 5px 10px" },
                                    background: value && value[optionIndex] ? palette.success.main : "none",
                                    border: `1px solid ${value && value[optionIndex] ? palette.success.main : "#d3d3d3"}`,
                                    borderRadius: "5px",
                                }}
                                key={optionIndex}
                                onChange={
                                    ( e ) => changeMultipleAnswersValues( e.target.checked, optionIndex )
                                }
                                checked={value[optionIndex] || false}
                                control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: "white",
                                    }
                                }} />}
                                label={
                                    <Box color={value && value[optionIndex] ? "white" : palette.defaultColor} paddingLeft="20px" fontSize="14px" dangerouslySetInnerHTML={{ __html: option.option_text }}></Box>
                                }
                            />
                        )
                    } )}
                </Box>}
                <Box display="flex" marginTop="40px" gap="20px" alignItems="center">
                    <Button onClick={reset} sx={{ paddingX: "40px" }} variant='outlined' color="secondary" >Reset</Button>
                    <Button onClick={goToNextQuestion} sx={{ paddingX: "40px" }} variant='contained' disableElevation color="secondary" >Next</Button>
                </Box>
            </Box>
        </Box>
    )
} )

export default AttemptQuestion
