import * as React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { TableHead, Button, Box, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Paper, IconButton, Modal, Typography, Rating } from '@mui/material'
import { Icons } from '../../utils/utilities'
import { useEffect } from 'react'
import api from '../../service/api'

const columns = [
    { name: "Session Information", align: "left" },
    { name: "Faculty name", align: "left" },
    { name: "Feedback by", align: "right" },
    { name: "Time (to and from)", align: "right", width: "150px" },
    { align: "right", width: "fit-content" },
]

function TablePaginationActions( { count, page, rowsPerPage, onPageChange } ) {
    const theme = useTheme()

    const handleFirstPageButtonClick = ( event ) => {
        onPageChange( event, 0 )
    }

    const handleBackButtonClick = ( event ) => {
        onPageChange( event, page - 1 )
    }

    const handleNextButtonClick = ( event ) => {
        onPageChange( event, page + 1 )
    }

    const handleLastPageButtonClick = ( event ) => {
        onPageChange( event, Math.max( 0, Math.ceil( count / rowsPerPage ) - 1 ) )
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil( count / rowsPerPage ) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil( count / rowsPerPage ) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
}

export default function FeedbackTable( { rows } ) {
    const [page, setPage] = React.useState( 0 )
    const [rowsPerPage, setRowsPerPage] = React.useState( 15 )
    const [open, setOpen] = React.useState( false )
    const [questions, setQuestions] = React.useState( {} )
    const [feedback, setFeedback] = React.useState( [] )

    const handleFeedBack = ( feedback ) => {
        setFeedback( feedback )
        setOpen( true )
    }

    const handleClose = () => {
        setOpen( false )
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max( 0, ( 1 + page ) * rowsPerPage - rows.length ) : 0

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    useEffect( () => {
        const getData = async () => {
            try {
                const res = await api.fetchFeedbackQuestions()
                const questionIdMap = {}
                for ( let i = 0; i < res.data.data.length; i++ ) {
                    const question = res.data.data[i]
                    questionIdMap[question.feedback_setting_id] = question
                }
                setQuestions( questionIdMap )
            } catch ( err ) {
                console.log( err )
            }
        }
        getData()
    }, [] )

    return (
        <Box>
            <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
                <Table sx={{ minWidth: "600px" }} stickyHeader aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {columns.map( ( column, index ) => (
                                <TableCell key={index} sx={{ width: column.width }} align={column.align}>{column.name}</TableCell>
                            ) )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {( rowsPerPage > 0
                            ? rows.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                            : rows
                        ).filter( row => Object.keys( row ).length > 0 ).map( ( row, index ) => {
                            const session_data = row["feedback"][0]["room_session_data"]
                            return <TableRow key={index}>
                                <TableCell >
                                    <Typography fontSize="14px">{row["room_session_id"]}</Typography>
                                    <Typography fontSize="14px"> {`${session_data["institute_name_short"]}-${session_data["branch_name"]}`}</Typography>
                                    <Typography fontSize="14px"> {`${session_data["course_short_name"]}-${session_data["subject_name_short"]}`}</Typography>
                                </TableCell>
                                <TableCell sx={{ width: "100px" }} align="right">
                                    {session_data["presenter_name"]}
                                </TableCell>
                                <TableCell sx={{ width: "100px" }} align="right">
                                    {row["submitted_by"]}
                                </TableCell>
                                <TableCell sx={{ width: "100px" }} align="right">
                                    <Typography fontSize="14px">{session_data["selected_date"]}</Typography>
                                    <Typography fontSize="14px">{session_data["interval"]}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        disableElevation
                                        onClick={( e ) =>
                                            handleFeedBack( row )
                                        }
                                        sx={{ "&:disabled": { background: "none", color: "textSecondary", textTransform: "capitalize" } }}
                                        color="primary"
                                        variant="contained"
                                        // style={{ width: "115px", padding: "8px 0" }}
                                        size="small"
                                    > {( row.data && row.data.feedback === null ) ? 'No Feedback' : 'View Feedback'}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        } )}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow >
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100, { label: 'All', value: -1 }]}
                                colSpan={7}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <Box sx={{ background: "#fff", width: "85%", height: "90%", overflow: "auto", borderRadius: "5px" }}>
                    <Box>
                        <Typography display="flex" alignItems="center" justifyContent="space-between" variant='h5' color="secondary" padding="20px" textAlign="center">
                            Feedback Data
                            <IconButton onClick={handleClose}>
                                {Icons.default.ClearIcon}
                            </IconButton>
                        </Typography>
                    </Box>
                    <Box padding="0 20px">
                        {feedback?.feedback?.map( feedbackRes => {
                            return <Box marginBottom="10px" borderRadius="5px" bgcolor="#eee" padding="10px" key={feedbackRes.session_feedback_id}>
                                <Typography color="textSecondary" gutterBottom>{questions[feedbackRes.room_session_feedback_question_id].question}</Typography>
                                {feedbackRes.rating > -1 && <Rating size='small' readOnly value={feedbackRes.rating} />}
                                {feedbackRes.remarks && feedbackRes.remarks !== "" && <Typography>
                                    {feedbackRes.remarks}
                                </Typography>}
                            </Box>
                        } )}
                    </Box>
                </Box>
            </Modal>
        </Box >
    )
}