import { Box, Button, CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import SectionForm from '../../../components/user/resume-builder-components/SectionForm'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { CheckCircleOutline, PendingActionsOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useStudentResumeFormStore } from '../../../store/studentresumeform.store'

const ResumeBuilderHome = observer( () => {
    const [loading, setLoading] = useState( true )
    const [formAvailable, setFormAvailable] = useState( false )

    const StudentResumeFormStore = useStudentResumeFormStore()
    const navigate = useNavigate()

    const { palette } = useTheme()

    const clearAllData = async () => {
        setLoading( true )
        await StudentResumeFormStore.clearForm()
        setLoading( false )
    }
    const saveChanges = async () => {
        await StudentResumeFormStore.updateStudentResumeInfo()
    }

    useEffect( () => {
        const getData = async () => {
            if ( await StudentResumeFormStore.fetchStudentForm() )
                setFormAvailable( true )
            await StudentResumeFormStore.fetchStudentResumeInfo()
            StudentResumeFormStore.setUpdatedResumeData( StudentResumeFormStore.getSavedFormData )
            setLoading( false )
        }
        getData()
    }, [StudentResumeFormStore] )

    return (
        <Box flexGrow={1} overflow="hidden" padding="20px">
            <Paper sx={{ borderRadius: "5px", height: "100%", display: "flex", flexDirection: "column" }}>
                <Box padding="20px" display="flex" flexWrap="wrap" alignItems="flex-end" justifyContent="space-between" gap="10px" borderBottom="1px solid #d3d3d3">
                    <Box>
                        <Typography variant='h5'>Please fill the form</Typography>
                        <Typography variant='subtitle2' fontSize="14px" color="textSecondary">Provide the required information for us to build your resume!</Typography>
                        {!loading && <Box marginTop="5px">
                            {StudentResumeFormStore.getSaveData.status && <Typography color={StudentResumeFormStore.getSaveData.statusText === 'saving' ? "GrayText" : "success.main"} display="flex" alignItems="center" gap="5px" fontSize="12px" fontWeight={500} textAlign="right"> {StudentResumeFormStore.getSaveData.statusText === 'saving' ? <PendingActionsOutlined fontSize='10px' /> : <CheckCircleOutline fontSize='10px' />} {StudentResumeFormStore.getSaveData.statusText === 'saving' ? "Saving..." : "Saved"} </Typography>}
                        </Box>}
                    </Box>
                    {!loading && formAvailable && <Box display="flex" flexDirection={{ lg: "row", xs: "row-reverse" }} alignItems="center" gap="10px" flexWrap="wrap">
                        {StudentResumeFormStore.getChangesMade && StudentResumeFormStore.getSaveData.statusText !== 'saving' && <Button disabled={StudentResumeFormStore.getSaveData.statusText === 'saving'} onClick={saveChanges} size="small" variant="outlined" color="success" sx={{ textTransform: "capitalize" }}>
                            Save Changes
                        </Button>}
                        <Button onClick={() => navigate( '/student/resume-builder/preview' )} size="small" variant="contained" disableElevation color="secondary" sx={{ textTransform: "capitalize" }}>
                            Preview Resume
                        </Button>
                        <Button onClick={clearAllData} size="small" variant="contained" disableElevation color="errorMessage" sx={{ textTransform: "capitalize" }}>
                            Clear all
                        </Button>
                    </Box>}
                </Box>
                {!loading && formAvailable && <Box className='custom-scrollbar' padding="20px" display="flex" flexDirection="column" gap="10px" overflow="auto" flexGrow={1} bgcolor={palette.contentBg}   >
                    {StudentResumeFormStore.getForm?.fields?.map( ( section, index ) => (
                        <SectionForm key={index} section={section} />
                    ) )}
                    {StudentResumeFormStore.getForm?.fields?.length === 0 && <Typography>No fields!</Typography>}
                </Box>}
                {loading && <Typography flexGrow={1} variant='subtitle2' color="GrayText" gap="10px" display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={30} /> Loading your form, please wait...
                </Typography>}
                {!loading && !formAvailable && <Typography flexGrow={1} variant='subtitle2' color="GrayText" gap="10px" display="flex" alignItems="center" justifyContent="center">
                    The resume form for your institute is not yet set please contact your department HOD.
                </Typography>}
            </Paper>
        </Box>
    )
} )

export default ResumeBuilderHome
