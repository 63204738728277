import { Icons, examCardColors } from '../../utils/utilities'
import { formatDateTimeForDisplay } from '../../utils/exam-utilities'
import React, { useCallback, useEffect, useState } from 'react'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { useUserStore } from '../../store/user.store'
import { useExamStore } from '../../store/exam.store'
import { Box, Typography, Card, useTheme } from '@mui/material'
import noTasksImg from '../../assets/noexams.svg'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

const CurrentOrRecentExamsCard = observer( ( { title, subject } ) => {


    const [isExamsLoaded, setIsExamsLoaded] = useState( false )

    const ExamStore = useExamStore()
    const UserStore = useUserStore()

    const { theme, palette, border } = useTheme()

    const getExams = useCallback( async () => {
        await ExamStore.fetchPendingExams( subject ? subject.subject_id : undefined )
        setIsExamsLoaded( true )
    }, [ExamStore, subject] )


    useEffect( () => {
        const getData = () => {
            getExams()
        }
        getData()
    }, [getExams] )

    return (
        <Card elevation={1} sx={{ flexGrow: 1 }}>
            <Box maxHeight="400px" display="flex" overflow="auto" flexDirection="column" minWidth="200px" >
                <Box display="flex" height="30px" alignItems="center" justifyContent="space-between" padding="20px">
                    <Link className='default-link' to={UserStore.getUser["user_role"] === "STUDENT" ? `/student/exams` : `/faculty/exams`}>
                        <Typography fontSize="20px" fontWeight="700" display="flex" alignItems="center" gap="10px"> {title || "Exams"}</Typography>
                    </Link>
                    {ExamStore.getExams.length > 0 && <Typography variant='subtitle2' width="20px" fontWeight="bold" display="flex" alignItems="center" justifyContent="center" height="20px" borderRadius="50%" border={`1px solid ${palette.borderColor}`}>{ExamStore.getExams.length}</Typography>}
                </Box>
                <Box display="flex" flexGrow={1} overflow="auto" flexDirection="column">
                    {isExamsLoaded && ExamStore.getExams.length > 0 && <Box className='custom-scrollbar' padding="0 20px 20px 20px" overflow="auto" gap="10px" display="flex" justifyContent="flex-start" flexDirection="column">
                        {ExamStore.getExams.map( ( exam, index ) => {
                            const color = theme === 'light' ? examCardColors[index % examCardColors.length] : palette.contentBg
                            return (
                                <Link key={exam.exam_id} className='default-link' to={UserStore.getUser["user_role"] === "STUDENT" ? `/student/exams/${exam.exam_id}` : `/faculty/exams/${exam.exam_id}`}>
                                    <Box border={theme === 'dark' && border[1]} bgcolor={color + "ee"} sx={{ borderRadius: "5px", alignItems: "flex-start", display: "flex", gap: "10px", padding: "10px", cursor: "pointer", transition: "0.1s ease" }}>
                                        <Box marginTop="5px">{Icons.small.Subject}</Box>
                                        <Box>
                                            <Typography maxWidth="250px" title={exam.name} width="150px" noWrap fontSize="16px" textTransform="capitalize" fontWeight="bold" >{exam.exam_name}</Typography>
                                            {exam?.subject?.subject_name && <Typography noWrap marginTop="-2px" fontWeight={400} fontSize="14px"><em>{exam?.subject?.subject_name}</em></Typography>}
                                            <Typography marginTop="10px" noWrap fontWeight={500} fontSize="12px">Start: {formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )} </Typography>
                                            <Typography noWrap fontWeight={500} fontSize="12px">End: {formatDateTimeForDisplay( exam.exam_end_date, exam.exam_end_time )}</Typography>
                                            {UserStore.getUser.user_role === 'STUDENT' && <Typography noWrap fontWeight={500} fontSize="12px">By {exam?.created_by_data?.employee_name || exam?.created_by_data?.name || exam.created_by}</Typography>}
                                        </Box>
                                    </Box>
                                </Link>
                            )
                        } )
                        }
                    </Box>}
                    {( !isExamsLoaded ) && <Box display="flex" justifyContent="center">
                        <DataLoadingSpinner />
                    </Box>}
                    {isExamsLoaded && ExamStore.getExams.length === 0 && <Box padding="20px" display="flex" flexGrow={1} alignItems="center" flexDirection="column" gap="20px" justifyContent="center">
                        <img width="150px" src={noTasksImg} alt="No pending tasks" />
                        <Typography variant="subtitle2">Relax no upcomming exams!</Typography>
                    </Box>}
                </Box>
            </Box>
        </Card>
    )
} )

export default CurrentOrRecentExamsCard
