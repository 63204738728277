import { Avatar, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Icons } from '../../utils/utilities'
import bronzeMedal from '../../assets/card-images/bronze-medal.svg'
import silverMedal from '../../assets/card-images/silver-medal.svg'
import goldMedal from '../../assets/card-images/gold-medal.svg'
import profileImg from '../../assets/avatar.jpg'
import bgImg from '../../assets/leaderboard-bg.jpg'


const columns = [
    { name: "Sl. NO.", },
    { name: "NAME", minWidth: "250px" },
    { name: "RANK", minWidth: "10px" },
    { name: "SCORE", minWidth: "100px", align: "right" },
]

const leaderboard = [
    { profile: profileImg, "name": "Jasper Devyn Baum", "email": "JasperDevynBaum@acharya.ac.in", "points": 335, "rank": 1 },
    { profile: profileImg, "name": "Allan Patrick Palmer", "email": "AllanPatrickPalmer@acharya.ac.in", "points": 319, "rank": 2 },
    { profile: profileImg, "name": "Daniela Catrina Askew", "email": "DanielaCatrinaAskew@acharya.ac.in", "points": 351, "rank": 3 },
    { profile: profileImg, "name": "Zachary Niko Rosas", "email": "ZacharyNikoRosas@acharya.ac.in", "points": 317, "rank": 3 },
    { profile: profileImg, "name": "Kristopher Kevin Hoang", "email": "KristopherKevinHoang@acharya.ac.in", "points": 379, "rank": 4 },
    { profile: profileImg, "name": "Liza Dahlia Acosta", "email": "LizaDahliaAcosta@acharya.ac.in", "points": 258, "rank": 5 },
    { profile: profileImg, "name": "Raegan Shakira Hogue", "email": "RaeganShakiraHogue@acharya.ac.in", "points": 361, "rank": 6 },
    { profile: profileImg, "name": "Cristofer Pierce Pereira", "email": "CristoferPiercePereira@acharya.ac.in", "points": 344, "rank": 6 },
    { profile: profileImg, "name": "Yusuf Elian Dubois", "email": "YusufElianDubois@acharya.ac.in", "points": 324, "rank": 7 },
    { profile: profileImg, "name": "Flor Ayanna Clinton", "email": "FlorAyannaClinton@acharya.ac.in", "points": 298, "rank": 8 }
]

const Leaderboard = () => {

    const { palette, border } = useTheme()

    return (
        <Box overflow="auto" position="relative" display="flex" flexDirection="column" flexGrow={1}>

            <Box position="absolute" overflow="hidden" sx={{ display: "flex", bottom: "0", left: 0, right: 0, top: 0, zIndex: 5 }}>
                <img width="100%" height="100%" style={{ objectFit: "cover" }} src={bgImg} alt="Background" />
                <Box sx={{ background: "rgba(0,0,0,0.5)", position: "absolute", inset: "0" }}>

                </Box>
            </Box>

            <Box padding="20px" position="relative" sx={{ zIndex: 10 }} overflow="auto">
                <Box>
                    <Typography color="white" fontWeight={900} gutterBottom textAlign="center" variant="h2">LEADERBOARD</Typography>
                    <Box display="flex" gap="30px">
                        {leaderboard.slice( 0, 3 ).map( ( student, index ) => (
                            <Box marginTop={index !== 1 && "50px"} key={student.name} sx={{
                                background: "linear-gradient(0deg, #292E31 0%, #32383C 18%, #4B5359 49%, #727F88 90%, #7B8993 99%)",
                                borderRadius: "10px"
                            }}
                                display="flex"
                                padding="0 40px"
                                gap="10px"
                                justifyContent="space-between"
                                flexGrow={1}
                                maxWidth="400px"
                                position="relative"
                                height="200px"
                            >
                                <Box height="150px">
                                    <img src={index === 0 ? silverMedal : index === 1 ? goldMedal : bronzeMedal} alt={"Medal: " + index} width="100%" height="100%" />
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="center" gap="20px" justifyContent="center">
                                    <Avatar sx={{ width: "80px", height: "80px" }} src={student.profile}>{student.name[0]}</Avatar>
                                    <Typography display="flex" gap="10px" alignItems="center" color="white" variant="subtitle2" fontSize="18px">
                                        {Icons.small.Crown}
                                        Rohan Sharma
                                    </Typography>
                                </Box>
                            </Box>
                        ) )}
                    </Box>
                </Box>
                <Box overflow="auto" flexGrow={1} display="flex" justifyContent="center" marginTop="20px">
                    <TableContainer sx={{ border: border[1], borderRadius: "10px", bgcolor: "#3c3c41ee", padding: "20px", maxWidth: "700px", overflow: "auto", display: "flex", justifyContent: "center" }}>
                        <Table stickyHeader sx={{ borderRadius: "5px", borderCollapse: "collapse" }}>
                            <TableHead>
                                <TableRow>
                                    {columns.map( ( col, index ) => (
                                        <TableCell align={col.align} key={col.name} sx={{ padding: "10px 10px", borderRadius: index === 0 ? "5px 0 0 0" : index === columns.length - 1 ? "0 5px 0 0" : "initial", minWidth: col.minWidth, background: "none", color: palette.primary.contrastText }} >{col.name}</TableCell>
                                    ) )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leaderboard.map( ( leader, index ) => (
                                    <TableRow sx={{ overflow: "hidden", borderRadius: "5px", '&:nth-of-type(even)': { background: "#1133365E" }, '&:nth-of-type(odd)': { background: "#4636244F" } }} key={leader.name}>
                                        <TableCell sx={{ borderBottom: "10px solid #0000", color: "white", padding: "10px", borderRadius: "5px 0 0 5px" }}><strong>{index + 1}</strong></TableCell>
                                        <TableCell sx={{ borderBottom: "10px solid #0000", color: "white", padding: "10px" }}><strong>{leader.name}</strong></TableCell>
                                        <TableCell sx={{ borderBottom: "10px solid #0000", color: "white", padding: "10px" }}><strong>{leader.rank}</strong></TableCell>
                                        <TableCell align='right' sx={{ borderBottom: "10px solid #0000", borderRadius: "0 5px 5px 0", color: "white", padding: "10px" }}><strong>{leader.points}</strong></TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    )
}

export default Leaderboard
