import { Pause, PlayArrow } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, Paper, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { leadingZeroFormatter } from '../../utils/utilities'
import PropTypes from 'prop-types'

const AudioPlayer = ( { audioName, audioSrc, variant, width, padding } ) => {

    const [playerState, setPlayerState] = useState( "paused" )
    const [isWaiting, setIsWaiting] = useState( true )
    const [playerTimer, setPlayerTimer] = useState( "00:00" )
    const [audioDuration, setAudioDuration] = useState( "00:00" )
    const [audioProgress, setAudioProgress] = useState( 0 )
    const audioRef = useRef()
    const { palette, border } = useTheme()

    const togglePlay = async () => {
        try {
            if ( audioRef.current.paused ) {
                await audioRef.current.play()
            } else {
                await audioRef.current.pause()
            }
        } catch ( err ) { }
    }


    const handleAudioMetadataLoad = async () => {
        if ( audioRef.current.duration === Infinity || isNaN( Number( audioRef.current.duration ) ) ) {
            audioRef.current.currentTime = 1e101
        }
        audioRef.current.addEventListener( 'timeupdate', getDuration )
    }

    const getDuration = () => {
        audioRef.current.currentTime = 0
        audioRef.current.removeEventListener( 'timeupdate', getDuration )
        const duration = Math.floor( audioRef.current.duration )
        const durationMinutes = Math.floor( duration / 60 )
        const durationSeconds = duration % 60
        setAudioDuration( `${leadingZeroFormatter.format( durationMinutes )}:${leadingZeroFormatter.format( durationSeconds )}` )
    }

    const handleTimeUpdate = () => {
        const currentTime = Math.floor( audioRef.current.currentTime )
        const currentTimeMinutes = Math.floor( currentTime / 60 )
        const currentTimeSeconds = currentTime % 60
        setPlayerTimer( `${leadingZeroFormatter.format( currentTimeMinutes )}:${leadingZeroFormatter.format( currentTimeSeconds )}` )
        setAudioProgress( ( audioRef.current.currentTime / audioRef.current.duration ) * 100 )
    }

    return (
        <Paper elevation={variant === 'paper' ? 1 : 0} sx={{ background: "none", border: variant !== 'paper' ? variant === 'bordered' ? "1px solid #d3d3d3" : "none" : "none", width: width, padding: padding || "0" }}>
            <audio
                controlsList="nodownload"
                style={{ height: "0px !important" }}
                ref={audioRef}
                src={audioSrc}
                onWaiting={() => setIsWaiting( true )}
                onLoadedMetadata={handleAudioMetadataLoad}
                onCanPlay={() => setIsWaiting( false )}
                onPlay={() => setPlayerState( "playing" )}
                onPause={() => setPlayerState( "paused" )}
                onEnded={() => { setPlayerState( "paused" ) }}
                onTimeUpdate={handleTimeUpdate}
                onError={( e ) => console.log( "Error occured while loading the audio file", e )}
            />
            <Box padding={( variant === 'bordered' || variant === 'paper' ) ? "10px" : "0"} display="flex" gap="10px" alignItems="center">
                {!isWaiting ? <IconButton sx={{ border: border[1] }} onClick={togglePlay}>
                    {playerState !== "paused" ? <Pause /> : <PlayArrow />}
                </IconButton> : <CircularProgress size={14} />}
                <Box flexGrow={1}>
                    <Typography fontSize="12px" display="block" noWrap variant='subtitle2' maxWidth="150px">{audioName}</Typography>
                    <Box display="flex" alignItems="center" gap="10px">
                        <Box height="5px" minWidth="70px" position="relative" borderRadius="3px" bgcolor="#eee" flexGrow={1}>
                            <Box height="5px" sx={{ background: palette.primary.main, transform: "scale(1.5)", width: "5px", borderRadius: "50%", zIndex: 3, transition: "all 450ms ease" }} left={`${audioProgress}%`} position="absolute"></Box>
                            <Box height="5px" sx={{ background: palette.primary.main, width: `${audioProgress}%`, transform: "translateY(-50%)", top: "50%", borderRadius: "3px 0 0 3px", zIndex: 2, transition: "all 450ms ease" }} position="absolute"></Box>
                        </Box>
                        <Typography fontSize="12px" variant='subtitle2'>
                            {playerTimer} / {audioDuration}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}

AudioPlayer.propTypes = {
    variant: PropTypes.oneOf( ['bordered', 'paper'] ),
}

export default AudioPlayer
