import { Box, Button, ButtonGroup, Dialog, IconButton, Popover, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import noUsers from '../../assets/no-users.svg'
import { Icons, showDateInIndianDateFormat } from '../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../service/api'
import TableHeader from '../common/TableHeader'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import TryAgainMessageBlock from '../common/TryAgainMessageBlock'
import { CheckBox, CheckBoxOutlineBlank, InfoOutlined } from '@mui/icons-material'
import { read, utils } from 'xlsx'
import AddUserDialog from './AddUserDialog'
import ImportedUsersPreviewDialog from './ImportedUsersPreviewDialog'
import importFileFormatImg from '../../assets/card-images/temporary-users-import-format.png'
import ConfirmDialog from '../common/ConfirmDialog'

const columns = [
    { name: "sl.no.", padding: "10px 20px 10px 40px", align: "left" },
    { name: "Name", align: "left" },
    { name: "User ID", align: "left" },
    { name: "added on", align: "left" },
    { name: "Actions", padding: "10px 40px 10px 20px", align: "right" },
]

const UsersTableRow = ( { user, selected, setUsers, index, toggleUserSelect } ) => {

    const [deleting, setDeleting] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )

    const deleteUser = async () => {
        try {
            setDeleting( true )
            await api.temporaryUsers.deleteUsers( { userIds: [user.id] } )
            setUsers( prev => {
                const newList = [...prev]
                newList.splice( index, 1 )
                return newList
            } )
            toast( `Removed ${user.name} successfully` )
            setDeleteDialogState( false )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong whilw removing users." )
        } finally {
            setDeleting( false )
        }
    }


    const { palette, table } = useTheme()
    return (
        <TableRow className='table-row' key={user.id}>
            <ConfirmDialog status={deleteDialogState} confirmAction={deleteUser} actionName="Yes! remove user" message="The user will be remove and all access will be revoked." cancelAction={() => setDeleteDialogState( false )} />
            <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[0].padding || "10px 20px" }} align={columns[0].align || "left"} >
                <Box sx={{ cursor: "pointer" }} display="flex" gap="20px" alignItems="center">
                    <Box marginBottom="-5px" onClick={() => toggleUserSelect( user.id, selected )}>{selected >= 0 ? <CheckBox sx={{ fontSize: "18px", '&:hover': { opacity: 1 }, opacity: "0.8", color: palette.primary.dark }} /> : <CheckBoxOutlineBlank sx={{ fontSize: "18px", '&:hover': { opacity: 1 }, opacity: "0.8", color: 'GrayText' }} />}</Box>
                    <strong>{index + 1}</strong>
                </Box>
            </TableCell>
            <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[1].padding || "10px 20px" }} align={columns[1].align || "left"} ><strong>{user.name}</strong></TableCell>
            <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[2].padding || "10px 20px" }} align={columns[2].align || "left"} ><strong>{user.user_id}</strong></TableCell>
            <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[3].padding || "10px 20px" }} align={columns[3].align || "left"} ><strong>{showDateInIndianDateFormat( user.created_at )}</strong></TableCell>
            <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[4].padding || "10px 20px" }} align={columns[4].align || "left"} >
                <IconButton onClick={() => setDeleteDialogState( true )} disabled={deleting} size="small" color="errorMessage">
                    {Icons.small.DeleteIcon}
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

const TemporaryBatchDetails = ( { openState, setOpenState, batch } ) => {

    const [users, setUsers] = useState( [] )
    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [addMode, setAddMode] = useState( false )
    const [deleting, setDeleting] = useState( false )
    const [selectedUsers, setSelectedUsers] = useState( [] )
    const [extractedUsersList, setExtractedUsersList] = useState( [] )
    const [deleteDialogState, setDeleteDialogState] = useState( false )
    const [importFileInfoAnchor, setImportFileInfoAnchor] = useState( null )

    const { palette, border } = useTheme()

    const importFileInputRef = useRef()

    const toggleUserSelect = ( id, selected ) => {
        if ( selected >= 0 ) {
            setSelectedUsers( prev => {
                const newList = [...prev]
                newList.splice( selected, 1 )
                return newList
            } )
        } else {
            setSelectedUsers( ( prev ) => [...prev, id] )
        }
    }

    const deleteUsers = async ( ids = selectedUsers ) => {
        try {
            setDeleting( true )
            await api.temporaryUsers.deleteUsers( { userIds: ids } )
            toast( `Removed ${ids.length} users successfully` )
            setSelectedUsers( [] )
            setDeleteDialogState( false )
            fetchUsers()
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong while removing users." )
        } finally {
            setDeleting( false )
        }
    }

    const extractDataFromImportFile = e => {
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = ( event ) => {
            const workbook = read( event.target.result, { type: 'binary' } )
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const sheetData = utils.sheet_to_json( sheet )

            setExtractedUsersList( sheetData.map( u => [u.userid, u.name] ) )
        }

        reader.readAsArrayBuffer( file )
        e.target.value = ''
    }

    const fetchUsers = useCallback( async () => {
        setLoading( true )
        try {
            const { data: { data: batchUsers } } = await api.temporaryUsers.fetchBatchUsers( batch.id )
            setUsers( batchUsers )
            setError( null )
        } catch ( err ) {
            setError( { code: err?.response?.status, message: err?.response?.data?.message || "Something went wrong while fetching batch users" } )
        } finally {
            setLoading( false )
        }
    }, [batch] )

    useEffect( () => {
        fetchUsers()
    }, [fetchUsers] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "1400px" } }} open={openState}>
            <ConfirmDialog status={deleteDialogState} message={`Selected ${selectedUsers.length} will be removed`} actionName="Yes! remove selected users" confirmAction={() => deleteUsers()} waiting={deleting} cancelAction={() => setDeleteDialogState( false )} />
            <Box borderBottom={border[1]} display="flex" alignItems="center" justifyContent="space-between" padding="20px">
                <Box>
                    <Typography variant='h5'>{batch.batch_name}</Typography>
                    <Typography variant='body2' >Users list</Typography>
                </Box>
                <Box display="flex" gap="20px" alignItems="center" >
                    <Button variant='contained' disableElevation onClick={() => setAddMode( true )} sx={{ textTransform: "capitalize" }} color="secondary" startIcon={Icons.default.AddIcon}>Add new users</Button>
                    <Box>
                        <input
                            accept='text/csv,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            type="file" style={{ width: 0, height: 0, }} onChange={extractDataFromImportFile} ref={importFileInputRef} id="excelfileimportinput" />
                        <ButtonGroup>
                            <Button sx={{ textTransform: "capitalize" }} onClick={() => { importFileInputRef.current.click() }} startIcon={Icons.default.DownloadIconOutlined} color="primary" disableElevation variant='contained' >Import users from file</Button>
                            <Button size='small' sx={{ textTransform: "capitalize" }} onClick={e => { setImportFileInfoAnchor( e.currentTarget ) }} color="warning" disableElevation variant='contained' > <InfoOutlined fontSize='small' /> </Button>
                        </ButtonGroup>
                    </Box>
                    <IconButton onClick={() => setOpenState( false )}>
                        {Icons.default.CloseIcon}
                    </IconButton>
                </Box>
            </Box>
            {!loading && !error && users.length === 0 && <Box padding="20px" display="flex" flexDirection="column" bgcolor={palette.contentBg} margin="10px" borderRadius="5px" alignItems="center" justifyContent="center">
                <img src={noUsers} width="200px" alt="No users!" />
                <Typography marginY="20px" variant='subtitle2' color="textSecondary">No Users!</Typography>
            </Box>}
            {!loading && !error && users.length > 0 && selectedUsers.length > 0 && <Box margin="10px" marginTop="10px" border={border[1]} bgcolor={palette.contentBg} display="flex" gap="20px" alignItems="center" padding="5px 10px" borderRadius="20px">
                <IconButton disabled={deleting} onClick={() => setSelectedUsers( [] )} size="small">
                    {Icons.small.CloseIcon}
                </IconButton>
                <Typography variant='subtitle2'>{selectedUsers.length} selected</Typography>
                <IconButton disabled={deleting} onClick={() => setDeleteDialogState( true )} size="small">
                    {Icons.small.DeleteIcon}
                </IconButton>
            </Box>}
            {!loading && !error && users.length > 0 && <Box minHeight="350px" overflow="hidden" display="flex" bgcolor={palette.contentBg} margin="10px" borderRadius="5px" >
                <TableContainer className='custom-scrollbar' >
                    <Table stickyHeader>
                        <TableHeader columns={columns} />
                        <TableBody>
                            {users.map( ( user, index ) => {
                                const selected = selectedUsers.indexOf( user.id )
                                return (
                                    <UsersTableRow setUsers={setUsers} deleteUsers={deleteUsers} index={index} toggleUserSelect={toggleUserSelect} user={user} selected={selected} />
                                )
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
            {loading && !error && <DataLoadingSpinner waitingMessage="Loading batch users..." />}
            {error && <TryAgainMessageBlock err={error.message} code={error.code} loading={loading} getData={fetchUsers} />}

            <AddUserDialog fetchUsers={fetchUsers} batch={batch} addMode={addMode} setAddMode={setAddMode} />
            <ImportedUsersPreviewDialog batch={batch} fetchUsers={fetchUsers} users={extractedUsersList} setUsers={setExtractedUsersList} />

            <Popover slotProps={{ paper: { sx: { width: "90%", maxWidth: "270px", border: border[1] } } }} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} transformOrigin={{ vertical: 'top', horizontal: 'center', }} onClose={() => setImportFileInfoAnchor( null )} open={Boolean( importFileInfoAnchor )} anchorEl={importFileInfoAnchor} >
                <Box padding="10px">
                    <Typography variant='h6' fontSize="16px">Import file format</Typography>
                    <Box overflow="hidden" marginY="10px" borderRadius="10px">
                        <img width="100%" src={importFileFormatImg} alt="import file example" />
                    </Box>
                    <Typography lineHeight="1.2" fontSize="12px" variant='body2'>
                        The file should contain two columns with the following Headers: <strong><em>Name</em></strong> and <strong><em>UserId</em></strong>
                    </Typography>
                    <Typography marginTop="10px" borderRadius="5px" padding="5px" bgcolor={palette.contentBg} lineHeight="1.2" fontSize="12px" variant='body2'>
                        <strong>Supported Formats:</strong> <em> <i>
                            .xlsx, .csv, xslm and .xls
                        </i> </em>
                    </Typography>
                </Box>
            </Popover>

        </Dialog>
    )
}

export default TemporaryBatchDetails
