import { Box, Button, Icon, IconButton, Paper, Typography, useTheme } from '@mui/material'
import noQuestionBanksImg from '../../../assets/illustrations/no-questions_bank_questions.svg'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../../utils/utilities'
import { useNavigate, useParams } from 'react-router-dom'
import AddQuestionDialog from '../../../components/exams-components/AddQuestionDialog'
import QuestionImportFileInputDialog from '../../../components/exams-components/QuestionImportFileInputDialog'
import QuestionsPreviewDialog from '../../../components/exams-components/QuestionsPreviewDialog'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import CreateTopicDialog from '../../../components/exams-components/quiz-questionbank/CreateTopicDialog'
import { observer } from 'mobx-react'
import TopicBlock from '../../../components/exams-components/quiz-questionbank/TopicBlock'
import ConfirmDialog from '../../../components/common/ConfirmDialog'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { useSubjectStore } from '../../../store/subject.store'

const QuestionBank = observer( ( { fromMainPage = false } ) => {

    const [loading, setLoading] = useState( true )
    const [deleting, setDeleting] = useState( false )
    const [isImporting, setIsImporting] = useState( false )
    const [selectedTopicId, setSelectedTopicId] = useState( null )
    const [questionsPreview, setQuestionsPreview] = useState( false )
    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )
    const [addTopicDialogState, setAddTopicDialogState] = useState( false )
    const [addQuestionDialogState, setAddQuestionDialogState] = useState( false )
    const [questionsImportDialogState, setQuestionsImportDialogState] = useState( false )

    const { bank_id, subject_id } = useParams()
    const { palette, table, border } = useTheme()
    const navigate = useNavigate()

    const QuizStore = useQuizAndQuestionbankStore()
    const SubjectStore = useSubjectStore()

    const deleteQuestionBank = async () => {
        setDeleting( true )
        if ( await QuizStore.deleteQuestionBank( bank_id ) ) {
            setDeleteConfirmDialogState( false )
            navigate( subject_id ? `/faculty/subject/${subject_id}/questionbank` : `/faculty/questionbank` )
        }
        setDeleting( false )
    }

    const editQuestionbankName = async ( e ) => {
        try {
            if ( e.type === 'keydown' && e.key === "Enter" ) {
                e.preventDefault()
            }
            const val = e.target.innerText
            if ( e.type === "blur" && val?.length > 0 && val !== QuizStore.getQuestionBank?.name ) {
                await api.quiz.editQuestionbankName( bank_id, { name: val } )
                QuizStore.fetchQuestionBank( bank_id )
                toast( "Update successful" )
            }
        } catch ( err ) {
            toast( "Something went wrong!" )
        }
    }

    useEffect( () => {
        const getData = async () => {
            const errorCode = await QuizStore.fetchQuestionBank( bank_id )
            if ( errorCode )
                navigate( subject_id ? `/faculty/subject/${subject_id}/questionbank` : `/faculty/questionbank` )
            setLoading( false )
        }
        getData()
    }, [QuizStore, bank_id, navigate, subject_id, SubjectStore] )

    return (
        <Box padding={fromMainPage && "20px"} display="flex" flexDirection="column" flexGrow={1} overflow="auto">

            <AddQuestionDialog setSelectedTopicId={setSelectedTopicId} setAddTopicDialogState={setAddTopicDialogState} bankId={bank_id} topicId={selectedTopicId} fromComponent="questionbank" state={addQuestionDialogState} setState={setAddQuestionDialogState} />
            <QuestionImportFileInputDialog setSelectedTopicId={setSelectedTopicId} bankId={bank_id} topicId={selectedTopicId} fromComponent="questionbank" setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} isImporting={isImporting} importDialogStatus={questionsImportDialogState} setImportDialogStatus={setQuestionsImportDialogState} />
            <QuestionsPreviewDialog isImporting={isImporting} bankId={bank_id} topicId={selectedTopicId} fromComponent="questionbank" setIsImporting={setIsImporting} getQuestions={() => { }} parent="questionbank" setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} />
            <CreateTopicDialog bankId={bank_id} topicId={selectedTopicId} fromComponent="questionbank" state={addTopicDialogState} setState={setAddTopicDialogState} />
            <ConfirmDialog waiting={deleting} message="All the associated topics and questions will be deleted, are you sure?" actionName="Yes, delete it!" status={deleteConfirmDialogState} confirmAction={deleteQuestionBank} cancelAction={() => setDeleteConfirmDialogState( false )} />

            <Paper sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }} elevation={0}>
                {!loading && <Box padding="15px 20px">
                    <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="10px" >
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={() => navigate( subject_id ? `/faculty/subject/${subject_id}/questionbank` : `/faculty/questionbank` )} size="small">
                                {Icons.default.ArrowBack}
                            </IconButton>
                            <Typography border="1px solid #fff" borderRadius="5px" variant="h6">
                                <div onBlur={editQuestionbankName} onKeyDown={editQuestionbankName} className='editable-name-element' contentEditable suppressContentEditableWarning>{QuizStore.getQuestionBank?.name}
                                    <Icon sx={{ marginLeft: "10px", color: "GrayText" }}>{Icons.default.EditIconOutlined}</Icon>
                                </div>
                            </Typography>
                        </Box>
                        <Box display="flex" gap="12px" alignItems="center">
                            <Button variant='contained' disableElevation startIcon={Icons.default.AddIcon} onClick={() => setAddTopicDialogState( true )} color="primary" sx={{ textTransform: "capitalize" }}>Add Topic</Button>
                            <Button variant='contained' disableElevation startIcon={Icons.default.DeleteIconOutlined} onClick={() => setDeleteConfirmDialogState( true )} color="error" sx={{ textTransform: "capitalize" }}>Delete</Button>
                        </Box>
                    </Box>
                </Box>}
                {!loading && <Box flexGrow={1} display="flex" flexDirection="column" padding="0 20px 20px 20px">
                    <Box border={border[1]} flexGrow={1} borderRadius="5px" overflow="hidden" bgcolor={palette.contentBg}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" bgcolor={table.headerBg} padding="10px 20px">
                            <Typography display="flex" gap="5px" alignItems="center" > {Icons.default.ViewListIcon} Questions List</Typography>
                        </Box>
                        {
                            QuizStore.getQuestionBank?.topics?.map( topic => (
                                <TopicBlock setQuestionsImportDialogState={setQuestionsImportDialogState} setAddQuestionDialogState={setAddQuestionDialogState} setSelectedTopicId={setSelectedTopicId} key={topic.id} topic={topic} questions={QuizStore.getQuestionBank?.formattedQuestions[topic.id] || []} />
                            ) )
                        }
                        {
                            !loading && ( ( !QuizStore.getQuestionBank?.questions || QuizStore.getQuestionBank?.questions.length === 0 ) && ( !QuizStore.getQuestionBank?.topics || QuizStore.getQuestionBank?.topics.length === 0 ) ) && <Box display="flex" padding="20px" alignItems="center" flexDirection="column" gap="20px" bgcolor={palette.contentBg}>
                                <img src={noQuestionBanksImg} width="200px" alt="No questions" />
                                <Typography variant="subtitle2" >No questions or topics in this question bank</Typography>
                                <Box display="flex" gap="10px" alignItems="center">
                                    <Button startIcon={Icons.default.AddIcon} onClick={() => setAddTopicDialogState( true )} color="primaryDark" variant="outlined" >Add new Topic</Button>
                                    <Button startIcon={Icons.default.AddCircleOutlinedIcon} onClick={() => setAddQuestionDialogState( true )} color="secondary" variant="outlined" >Add new questions</Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading the question bank..." />}
            </Paper >
        </Box >
    )
} )

export default QuestionBank
