import { Box, TextField, Button, Typography, Paper, CircularProgress } from '@mui/material'
import CustomSearchBar from '../../components/common/CustomSearchBar'
import BlockMessage from '../../components/common/BlockMessage'
import { useUserStore } from '../../store/user.store'
import { Icons } from '../../utils/utilities'
import { JSONTree } from 'react-json-tree'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

const DebugUser = () => {

    const UserStore = useUserStore()

    const [username, setUsername] = useState( '' )
    const [token, setToken] = useState( '' )
    const [isSearching, setIsSearching] = useState( false )
    const [timetable, setTimetable] = useState( [] )
    const [hasPermission, setHasPermission] = useState( true )

    const search = async () => {
        let payload = {
            "user_id": username
        }
        setIsSearching( true )
        try {
            const res = await UserStore.userDebugTimetable( payload )
            await UserStore.fetchUser( payload )
            if ( res.data.success ) {
                setToken( res["data"]["data"]["token"] )
                setTimetable( res["data"]["data"]["timetable"] )
            } else toast.error( "No user found with the given ID" )
        } catch ( err ) {
            console.log( `Debug : `, err )
        } finally {
            setIsSearching( false )
        }
    }

    const handleSearchOnEnter = ( e ) => {
        console.log( e.key )
        if ( e.key === "Enter" )
            search()
    }

    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.debug_user ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    return (
        hasPermission ? <Box padding="20px" flexGrow={1}>
            <Paper>
                <Box padding="20px">
                    <Typography variant='h5'>Debug User</Typography>
                    <Typography color="textSecondary" variant='subtitle2'>Debug any user by their username or AUID or Employee ID</Typography>
                    <Box marginTop="20px" >
                        <Box display="flex" alignItems="center" gap="10px">
                            <CustomSearchBar onKeyDown={handleSearchOnEnter} border="1px solid #d3d3d3" onChange={( e ) => setUsername( e.target.value )} value={username} placeholder="Search users by Username / AUID / Employee ID..." style={{ width: "100%" }} />
                            <Button disableElevation startIcon={isSearching ? <CircularProgress size={14} /> : Icons.default.SearchIcon} disabled={isSearching} sx={{ height: "40px", textTransform: "capitalize" }} variant="contained" color="primary" onClick={search} >
                                {isSearching ? "Searching..." : "Search"}
                            </Button>
                        </Box>
                    </Box>
                    <Box marginY="20px" >
                        {token && <Box display="flex" alignItems="center">
                            <Typography borderRight="1px solid rgba(0,0,0,0.3)" padding="10px" variant='h6' color="textSecondary">Token</Typography>
                            <TextField sx={{ marginLeft: "20px" }} fullWidth value={token} disabled />
                        </Box>}
                        {timetable && timetable.length > 0 && <Box>
                            <Box sx={{ "background": "#272822" }}>
                                <Box marginTop="20px" >
                                    <JSONTree
                                        theme={{
                                            extend: "monokai",
                                            nestedNodeLabel: ( { style }, keyPath, nodeType, expanded ) => ( {
                                                style: {
                                                    padding: "20px",
                                                    textTransform: expanded ? 'uppercase' : style.textTransform,
                                                },
                                            } ),
                                        }} data={timetable} />
                                </Box>
                            </Box>
                        </Box>}
                    </Box>

                </Box>
            </Paper>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
}

export default DebugUser
