import { Box, Paper, Dialog, AppBar, Toolbar, Typography, IconButton, } from '@mui/material'
import React, { useEffect } from 'react'
import api from '../../service/api'
import thumbImg from '../../assets/recording-thumbnail.svg'
import PropTypes from 'prop-types'
import { Icons } from '../../utils/utilities'
import noRecodings from '../../assets/norecording.svg'

const videoCardStyles = {
    maxWidth: "450px",
    width: "fit-content",
    alignItems: "center",
    background: "white",
    borderRadius: "5px",
    padding: "0",
    display: "flex",
    cursor: "pointer",
    "@media(max-width:350px)": {
        flexDirection: "column",
        maxWidth: "100%",
        width: "100%",
        alignItems: "flex-start"
    }
}

const SimpleDialog = ( { onClose, selectedValue, open } ) => {

    const handleClose = () => {
        onClose( selectedValue )
    }

    return (
        <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <AppBar sx={{ position: "relative" }} >
                <Toolbar>

                    <Typography variant="h6" sx={{ fontSize: 14, color: '#ffffff' }} >
                        Recorded Session
                    </Typography>
                    <IconButton color="inherit" onClick={handleClose} aria-label="close">
                        {Icons.default.CloseIcon}
                    </IconButton>


                </Toolbar>
            </AppBar>
            {/* <DialogTitle id="simple-dialog-title">Video Player</DialogTitle> */}
            <iframe title="Recorded session" type="text/html" id="embedd-bbb" allowFullScreen
                src={selectedValue}
            />
        </Dialog>
    )
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
}



const PresenterRecording = () => {
    const [recording, setRecording] = React.useState( [] )

    const [open, setOpen] = React.useState( false )
    const [selectedValue, setSelectedValue] = React.useState( '' )

    useEffect( () => {
        const getData = async () => {
            try {
                const res = await api.getFacultyRecording()
                setRecording( res["data"]["data"] ? res["data"]["data"] : 0 )
            } catch ( err ) {
                console.log( err )
            }
        }
        getData()
    }, [] )

    const playVideo = ( data ) => {
        setOpen( true )
        setSelectedValue( `https://recording.alive.university/?meetingId=${data.session_id}&server=${data.server_id}` )
    }

    const handleClose = ( val ) => {
        setOpen( false )
        setSelectedValue( val )
    }

    return (
        <Box flexGrow={1} minWidth="0" overflow="auto" padding="20px">
            <Box >
                <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
            </Box>

            <Box>
                <Paper>
                    <Box borderBottom="1px solid #d3d3d3" sx={{ padding: "20px" }}>
                        <Typography variant='h5'>Recordings</Typography>
                        <Typography variant='body2' color="textSecondary">Manage all your recordings.</Typography>
                    </Box>

                    {recording.length > 0 && <Box padding="20px">
                        {recording.map( ( item, i ) => {
                            return <Box boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.19)" boxSizing="border-box" onClick={() => playVideo( item )} sx={videoCardStyles}>
                                {/* <Box class="card-body"> */}
                                <img width="160px" style={{ objectFit: "cover", borderRadius: "5px 0 0 5px" }} height="100%" src={thumbImg} alt="thumbnail" />
                                <Box className="details" padding="10px" >
                                    <Typography width="160px" fontSize="12px" fontWeight="bold"> {item.course_name}-{item.room_session_data.branch_name}</Typography>
                                    <Typography noWrap width="120px" fontSize="12px"> {item.room_session_data.current_sem}{item.room_session_data.section_short_name} </Typography>
                                    <Typography noWrap width="120px" fontSize="12px" fontWeight="bold"> {item.faculty}  </Typography>
                                    <Typography noWrap width="120px" fontSize="12px"> {item.room_session_data.interval}  </Typography>
                                    <Typography noWrap width="120px" fontSize="12px"> {item.room_session_data.duration} min </Typography>
                                </Box>
                                {/* </Box> */}

                            </Box>
                        } )}
                    </Box>}

                    {
                        ( recording.length === 0 ) ?
                            <Box bgcolor="#eee" sx={{ padding: "20px", display: "flex", gap: "10px", flexDirection: "column", alignItems: "center" }}>
                                <img width="200px" src={noRecodings} alt="No recordings!" />
                                <Typography variant='subtitle2' color="textSecondary">You doesn't seem to have any recordings!</Typography>
                            </Box> : ''
                    }
                </Paper>
            </Box>


        </Box>
    )
}

export default PresenterRecording
