import { Box, Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import TableHeader from '../../common/TableHeader'
import noAssignmentsImage from '../../../assets/noassignment.svg'
import React, { useCallback, useEffect, useState } from 'react'
import LineGraph from '../../common/LineBarGraph'
import api from '../../../service/api'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'
import { Link } from 'react-router-dom'

let columns = [
    { name: "Faculty", padding: "10px 20px 10px 40px" },
    { name: "Created", padding: "10px 20px", align: "center" },
    { name: "Submissions", align: "center", padding: "10px 40px 10px 20px" },
]

const AssignmentLast30Details = () => {

    const [tableData, setTableData] = useState( [] )
    const [graphData, setGraphData] = useState( [] )
    const [loading, setLoading] = useState( true )
    const [error, setError] = useState( null )

    const { table } = useTheme()


    const getData = useCallback( async () => {
        setLoading( true )
        try {
            const { data: facultyStats } = await api.audit.getAssignmentStats( { month: "last30days" } )
            const { data: graphDetails } = await api.audit.getAssignmentGraph()
            setGraphData( graphDetails.data )
            setTableData( facultyStats.data )
            setError( null )
        } catch ( err ) {
            setError( { code: err?.response?.status, message: err?.response?.data?.message || "Something went wrong while getting the assignments stats." } )
        } finally {
            setLoading( false )
        }
    }, [] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Card sx={{ flexShrink: 0, maxHeight: "450px", minHeight: "400px", display: "flex", flexDirection: "column" }}>
            {!loading && tableData.length > 0 && <Box padding="10px" flexGrow={1} overflow="auto" alignItems="stretch" display="grid" gap='20px' gridTemplateColumns={{ md: "1fr 1fr", xs: "1fr" }}>
                <Box display="flex" overflow="auto" flexGrow={1} flexDirection="column">
                    <Typography padding="20px" fontSize="16px" fontWeight="bold" color="primaryDark.main">Last 30 days assignments stats</Typography>
                    <TableContainer sx={{ overflow: "auto" }} className='slim-custom-scrollbar' >
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {tableData.map( ( row, index ) => (
                                    <TableRow className='table-row' key={index}>
                                        <TableCell sx={{ fontSize: "12px", color: table.color, padding: columns[0].padding || "10px 20px" }}>
                                            <Typography sx={{ fontSize: "12px", cursor: "pointer", '&:hover': { textDecoration: "underline" } }} ><Link className='default-link' to={`/audit/faculty/${row.assignment_created_by}`} ><strong>{row['faculty_name']}</strong></Link></Typography>
                                        </TableCell>
                                        <TableCell align={columns[1].align || "left"} sx={{ padding: "10px 20px", fontSize: "12px", color: table.color }}><strong>{row['count']}</strong></TableCell>
                                        <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", color: table.color, padding: columns[2].padding || "10px 20px" }}><strong>{row['submissions_received']}</strong></TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <LineGraph graphTitle="Last 30 days activities" showMenuBar={false} barLegend='Submissions submitted' yAxisName="Assignments" xAxisName="Date" y2AxisName="Submissions" lineLegend='Assignments created' barValuesKey="submissions" barData={graphData} dateformat={"%Y-%m-%d"} xAxisValuesKey={'date'} lineValuesKey={'assignments_created'} lineData={graphData} />
            </Box>}
            {!loading && !error && tableData.length === 0 && <Box flexGrow={1} flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" gap="20px">
                <img src={noAssignmentsImage} height="120px" alt="No assignmets in last 30 days" />
                <Typography textAlign="center" variant='subtitle2'>No assignments were created in last 30 days</Typography>
            </Box>}

            {loading && !error && <Box display="flex" flexGrow={1} justifyContent="center">
                <DataLoadingSpinner waitingMessage="Loading last 30 days assignments activities..." />
            </Box>}
            {error && <Box display="flex" flexGrow={1} justifyContent="center">
                <TryAgainMessageBlock code={error?.code} err={error.message} getData={getData} loading={loading} />
            </Box>}
        </Card >
    )
}

export default AssignmentLast30Details
