import React from 'react'
import { useLiveSessionStore } from '../../store/livesessions.store'
import { Box, IconButton, Paper, Typography, useTheme } from '@mui/material'
import Progress from '../common/Progress'
import { Icons } from '../../utils/utilities'
import { ArrowDropDown, CheckRounded } from '@mui/icons-material'
import { observer } from 'mobx-react'

const ResourceDownloadStatusDialog = observer( () => {

    const LiveSessionStore = useLiveSessionStore()

    const { palette, theme } = useTheme()

    const handleDownloadsDialogClose = () => {
        LiveSessionStore.clearDownloaded()
    }

    return (
        <Box>
            {Object.keys( LiveSessionStore.getDownloadStatuses ).length > 0 &&
                <Paper
                    elevation={5}
                    sx={{
                        borderRadius: " 10px 10px 0 0 ",
                        border: theme === 'dark' ? `1px solid ${palette.borderColor}` : "none",
                        position: "fixed",
                        display: { md: "block", xs: "none" },
                        zIndex: 2000,
                        bottom: "0",
                        right: "20px",
                        width: "250px",
                        maxWidth: "250px"
                    }}>
                    <Box display="flex" padding="10px" alignItems="center" justifyContent="space-between">
                        {LiveSessionStore.getDownloadingCount > 0 ? <Typography variant="subtitle2">
                            Downloading {LiveSessionStore.getDownloadingCount} items...
                        </Typography> : <Typography variant="subtitle2">
                            Downloaded {Object.values( LiveSessionStore.getDownloadStatuses ).length} items
                        </Typography>}
                        <IconButton color="greyed" size="small" onClick={handleDownloadsDialogClose}>
                            {LiveSessionStore.getDownloadingCount > 0 ? <ArrowDropDown /> : Icons.default.CloseIcon}
                        </IconButton>
                    </Box>
                    {Object.values( LiveSessionStore.getDownloadStatuses ).length > 0 && <Box bgcolor={palette.contentBg} maxHeight="150px" overflow="auto">
                        {Object.keys( LiveSessionStore.getDownloadStatuses ).map( key => (
                            <Box key={key} borderTop="2px solid rgba(0,0,0,0.1)" fontSize="14px" display="flex" alignItems="center" justifyContent="space-betweeen" gap="5px" padding="10px">
                                <Box flexGrow="1">
                                    <Typography variant='subtitle2' fontSize="12px" width="150px" noWrap>{LiveSessionStore.getDownloadStatuses[key].name}</Typography>
                                    <Typography variant='body2' fontSize="10px" width="150px" noWrap>{LiveSessionStore.getDownloadStatuses[key].type}</Typography>
                                </Box>
                                {LiveSessionStore.getDownloadStatuses[key].inProgress ? <Progress size={25} trackWidth={5} indicatorWidth={5} progress={LiveSessionStore.getDownloadStatuses[key].progress} /> : <CheckRounded color="success" />}
                            </Box>
                        ) )}
                    </Box>}
                </Paper>}
        </Box>
    )
} )

export default ResourceDownloadStatusDialog
