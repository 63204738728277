import { Box, Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import noExamsImg from '../../../assets/no-material.svg'
import TableHeader from '../../common/TableHeader'
import React, { useCallback, useEffect, useState } from 'react'
import LineGraph from '../../common/LineBarGraph'
import api from '../../../service/api'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import TryAgainMessageBlock from '../../common/TryAgainMessageBlock'
import { Link } from 'react-router-dom'

const columns = [
    { name: "Faculty", padding: "10px 20px 10px 40px" },
    { name: "Institute-DEpt", minWidth: "120px", padding: "10px 20px" },
    { name: "Attachments", padding: "10px 20px", align: "center" },
    { name: "Downloads", align: "center", padding: "10px 40px 10px 20px" },
]

const StudyMaterialsLast30Details = () => {

    const [tableData, setTableData] = useState( [] )
    const [graphData, setGraphData] = useState( [] )
    const [loading, setLoading] = useState( false )
    const [error, setError] = useState( null )

    const { table } = useTheme()

    const getData = useCallback( async () => {
        try {
            setLoading( true )
            const { data: { data: stats } } = await api.audit.fetchStudyMaterialStats( { month: "last30days" } )
            const { data: { data: graphData } } = await api.audit.getStudyMaterialGraph()
            setGraphData( graphData )
            setTableData( stats )
            setError( null )
        } catch ( err ) {
            setError( err?.response?.data?.message || "Something went wrong while getting the study material stats." )
        } finally {
            setLoading( false )
        }
    }, [] )

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Card sx={{ flexShrink: 0, maxHeight: "450px", minHeight: "400px", display: "flex", flexDirection: "column" }}>
            {!loading && !error && tableData.length > 0 && <Box padding="10px" flexGrow={1} overflow="auto" alignItems="stretch" display="grid" gap='20px' gridTemplateColumns={{ md: "1fr 1fr", xs: "1fr" }}>
                <Box display="flex" overflow="auto" flexGrow={1} flexDirection="column">
                    <Typography padding="20px" fontSize="16px" fontWeight="bold" color="primaryDark.main">Last 30 days study materials stats</Typography>
                    <TableContainer sx={{ overflow: "auto" }} className='slim-custom-scrollbar' >
                        <Table stickyHeader>
                            <TableHeader fontSize="14px" columns={columns} />
                            <TableBody>
                                {tableData.map( ( row, index ) => (
                                    <TableRow className='table-row' key={index}>
                                        <TableCell sx={{ fontSize: "12px", color: table.color, padding: columns[0].padding || "10px 20px" }}>
                                            <Typography sx={{ fontSize: "12px", cursor: "pointer", '&:hover': { textDecoration: "underline" } }} ><Link className='default-link' to={`/audit/faculty/${row.auid}`} ><strong>{row.faculty_name}</strong></Link></Typography>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px", color: table.color, padding: columns[1].padding || "10px 20px" }} align={columns[1].align || "left"}><strong>{row?.institute} - {row?.department?.toLowerCase()}</strong></TableCell>
                                        <TableCell align={columns[2].align || "left"} sx={{ fontSize: "12px", padding: "10px 20px", color: table.color }}><strong>{row['attachments']}</strong></TableCell>
                                        <TableCell align={columns[3].align || "left"} sx={{ fontSize: "12px", color: table.color, padding: columns[2].padding || "10px 20px" }}><strong>{row?.downloads}</strong></TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <LineGraph lineColor='#101820' barColor='#FEE715' graphTitle="Last 30 days activities" showMenuBar={false} yAxisName="Attachments" xAxisName="Date" lineLegend='Attachments uploaded' dateformat={"%Y-%m-%d"} xAxisValuesKey={'date'} lineValuesKey={'attachments_uploaded'} lineData={graphData} />
            </Box >}
            {!loading && !error &&
                tableData.length === 0 && <Box flexDirection="column" padding="20px" display="flex" alignItems="center" justifyContent="center" flexGrow={1} gap="20px">
                    <img src={noExamsImg} height="120px" alt="No assignmets in last 30 days" />
                    <Typography textAlign="center" variant='subtitle2'>No assignments were created in last 30 days</Typography>
                </Box>
            }
            {loading && !error && <Box display="flex" flexGrow={1} justifyContent="center">
                <DataLoadingSpinner waitingMessage="Loading last 30 days study material activities..." />
            </Box>}
            {error && <Box display="flex" flexGrow={1} justifyContent="center">
                <TryAgainMessageBlock err={error} getData={getData} loading={loading} />
            </Box>}
        </Card >
    )
}

export default StudyMaterialsLast30Details
