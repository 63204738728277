import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
// import UserStore from "./user.store"
// import fileDownload from 'js-file-download'
import React from 'react'
import NotificationStore from "./notification.store"

const notification = NotificationStore

class StudyMaterialStore {
    materials
    selectedMaterial
    attachments
    materialBatches

    constructor () {
        this.materials = { materials: [], docsCount: [] }
        this.selectedMaterial = { studyMaterial: [], attachments: [] }
        this.attachments = []
        this.materialBatches = []
        this.api = api

        makeObservable( this, {
            materials: observable,
            selectedMaterial: observable,
            attachments: observable,
            materialBatches: observable,
            setMaterials: action,
            fetchMaterial: action,
            fetchMaterials: action,
            createMaterial: action,
            updateMaterial: action,
            fetchMaterialBatches: action,
            fetchMaterialsBySubject: action,
            deleteMaterial: action,
            addAttachments: action,
            downloadMaterial: action,
            fetchAttachments: action,
            deleteAttachment: action,
            downloadAttachment: action,
            grant: action,
            revoke: action,
            getAttachments: computed,
            getMaterials: computed,
            getMaterialBatches: computed,
            getMaterial: computed

        } )
    }

    get getMaterials() {
        return toJS( this.materials )
    }

    get getAttachments() {
        return toJS( this.attachments )
    }

    get getMaterialBatches() {
        return toJS( this.materialBatches )
    }

    get getMaterial() {
        return toJS( this.selectedMaterial )
    }

    setMaterials = ( data ) => {
        runInAction( () => {
            this.materials = data
        } )
    }

    fetchMaterials = async ( institute = undefined ) => {
        try {
            const res = await this.api.getMaterials( institute )
            this.setMaterials( res.data.success ? res.data.data : { materials: [], docsCount: [] } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    fetchMaterialsBySubject = async ( subject = undefined ) => {
        try {
            const res = await this.api.getMaterialsBySubject( subject )
            this.setMaterials( res.data.success ? res.data.data : { materials: [], docsCount: [] } )
        } catch ( err ) {
            this.setMaterials( { materials: [], docsCount: [] } )
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    fetchMaterial = async ( id ) => {
        try {
            const res = await this.api.getMaterial( id )
            runInAction( () => {
                this.selectedMaterial = res.data.success ? res.data.data : { studyMaterial: null, attachments: [] }
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    fetchBranchMaterials = async ( institute, code ) => {
        try {
            const res = await this.api.getBranchMaterials( institute, code )
            runInAction( () => {
                this.setMaterials( res.data.data )
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    fetchInstituteMaterials = async ( institute ) => {
        try {
            const res = await this.api.getInstituteMaterials( institute )
            runInAction( () => {
                this.setMaterials( res.data.data )
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    createMaterial = async ( payload, progressHandler ) => {
        try {
            const res = await this.api.uploadStudyMaterial( payload, progressHandler )
            notification.setNotification( true, <div>New study material <span style={{ color: "#f18f08" }}><i><b>{res.data.data.study_material_name}</b></i></span> created successfully </div> )
            return res.data.data
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    updateMaterial = async ( id, payload ) => {
        try {
            const res = await this.api.updateMaterial( id, payload )
            notification.setNotification( true, res.data.message )
            return res.data.data
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    fetchMaterialBatches = async ( id ) => {
        try {
            const res = await this.api.getMaterialBatches( id )
            console.log( res )
            if ( res.data.success ) {
                this.materialBatches = res.data.data
                return res.data.data
            }
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    deleteMaterial = async ( id ) => {
        try {
            const res = await this.api.deleteStudyMaterial( id )
            if ( res.data.status ) {
                notification.setNotification( true, res.data.message )
                const res1 = await this.api.getMaterials()
                this.setMaterials( res1.data.success ? res1.data.data : { materials: [], docsCount: [] } )
            }
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    downloadMaterial = async ( id, progressHandler ) => {
        try {
            const res = await this.api.downloadMaterial( id, progressHandler )
            if ( res.data.success )
                return res.data.data
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    addAttachments = async ( id, payload, progress ) => {
        const res = await this.api.uploadAttachments( id, payload, progress )
        if ( res.data.status )
            notification.setNotification( true, "Attachments uploaded successfully" )

    }

    fetchAttachments = async ( id ) => {
        try {
            const res = await this.api.getAttachments( id )
            this.attachments = res.data.success ? res.data.data : []
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    deleteAttachment = async ( id, name ) => {
        try {
            const res = await this.api.deleteAttachment( id )
            if ( res.data.status ) {
                await this.fetchMaterial( this.selectedMaterial?.studyMaterial?.study_material_id )
            }
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, "Something went wrong! while deleting the attachment " + ( name || "" ) )
        }
    }

    downloadAttachment = async ( id, payload, progressHandler ) => {
        try {
            const res = await this.api.downloadAttachment( id, payload, progressHandler )
            if ( res.data.success )
                return res.data.data
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    grant = async ( id, payload ) => {
        try {
            const res = await this.api.grantAccess( id, payload )
            notification.setNotification( true, res.data.message )
            return res.data.data
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    revoke = async ( id, accessId, payload ) => {
        try {
            const res = await this.api.revokeAccess( id, accessId, payload )
            notification.setNotification( true, res.data.message )
            return res.data.data
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }
}

const StudyMaterialStoreInstance = new StudyMaterialStore()

export default StudyMaterialStoreInstance

const StudyMaterialStoreContext = React.createContext()

export const StudyMaterialStoreProvider = ( { children, store } ) => {
    return (
        <StudyMaterialStoreContext.Provider value={store}>{children}</StudyMaterialStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useStudyMaterialStore = () => React.useContext( StudyMaterialStoreContext )