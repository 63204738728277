import React from 'react'
import dayjs from 'dayjs'
import { Box, Typography, Button, Paper, Slider, TableContainer, Table, useTheme, TableBody, TableRow, TableCell, Dialog } from '@mui/material'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import noAttendenceImg from '../../assets/noattendence.svg'
import api from '../../service/api'
import TableHeader from '../../components/common/TableHeader'

const columns = [
    { name: 'selected date', padding: "10px 20px 10px 40px" },
    { name: 'subject', },
    { name: 'duration', }, //subject_name_short
    { name: 'attendance', padding: "10px 40px 10px 20px" }, //selected_date
]


const Attendence = () => {
    const [loading, setLoading] = React.useState( false )
    const [allAttendance, setAllAttendance] = React.useState( [] )
    const [fromDate, setFromDate] = React.useState( new Date().toISOString() )
    const [toDate, setToDate] = React.useState( new Date().toISOString() )
    const [sliderPercentage, setSliderPercentage] = React.useState( 50 )

    const [openDialog, setOpenDialog] = React.useState( false )
    const [auidList, setAuidList] = React.useState( [] )

    const { palette, border, table } = useTheme()

    const submit = async () => {
        let payload = {
            "date_from": fromDate.toString().split( "T" )[0],
            "date_to": toDate.toString().split( "T" )[0],
            "percentage": sliderPercentage
        }
        setLoading( true )
        try {
            const res = await api.searchAttendence( payload )
            console.log( res.data )
            setAllAttendance( res["data"]["data"] ? res["data"]["data"] : [] )
        } catch ( error ) {
            alert( error )
        } finally {
            setLoading( false )
        }

    }

    const showAuid = ( list ) => {
        setAuidList( list )
        setOpenDialog( true )
    }

    const handleClose = () => {
        setOpenDialog( false )
        setAuidList( [] )
    }

    return (
        <Box flexGrow={1} minWidth="0" display="flex" flexDirection="column" overflow="auto" padding="20px">
            <Paper sx={{ padding: "0", overflow: "hidden", flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Box padding="20px" position="relative" sx={{
                    background: `url('/imgs/attendance-header-bg.jpg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }} display="flex" flexDirection="column" gap="20px">

                    <Box height="100%" position="absolute" sx={{ zIndex: 0, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 150.03%)`, }}>
                    </Box>

                    <Box sx={{ zIndex: 10, position: "relative" }}>
                        <Box>
                            <Typography color="white" variant='h5'>Attendance</Typography>
                            <Typography color="white" variant='body2'>View your classes attendance here.</Typography>
                        </Box>
                        <Box marginTop="20px" sx={{ width: "100%", display: "flex", alignItems: "flex-end", gap: "20px", }} noValidate autoComplete="off">
                            <Box>
                                <Typography variant='body2' fontWeight="300" gutterBottom color="white">From date *</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                        disableToolbar
                                        variant="inline"
                                        format="DD-MM-YYYY"
                                        margin="normal"
                                        id="fromdate-picker-inline"
                                        value={dayjs( fromDate )}
                                        onChange={( e ) => setFromDate( e.toISOString() )}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box>
                                <Typography variant='body2' fontWeight="300" gutterBottom color="white">End date *</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        sx={{ width: "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                        disableToolbar
                                        maxDate={dayjs( new Date() )}
                                        variant="inline"
                                        format="DD-MM-YYYY"
                                        margin="normal"
                                        id="todate-picker-inline"
                                        value={dayjs( toDate )}
                                        onChange={( e ) => setToDate( e.toISOString() )}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box >
                                <Typography gutterBottom variant='body2' fontWeight="300" color="white">Fetch student(s) with {sliderPercentage ? sliderPercentage : 'NA'}% attendance</Typography>
                                <Box
                                    sx={{ maxWidth: "200px", padding: "5px 20px", bgcolor: palette.form.inputBg, borderRadius: "20px", }}
                                >
                                    <Slider size='small' defaultValue={50}
                                        aria-label="Attendance"
                                        valueLabelDisplay="auto" onChange={( e, val ) =>
                                            setSliderPercentage( val )
                                        } />
                                </Box>
                                {/* <small>Note: Student is present atleast {'50'}% time of the session.</small> */}
                            </Box>
                            <Button sx={{ marginBottom: "3px" }} variant='contained' disableElevation color="customThemeColor" onClick={submit}>
                                Search
                            </Button>
                        </Box>
                    </Box>
                </Box>
                {
                    // allAttendance.length > 0 &&
                    !loading && <Box padding="20px" display="flex" flexDirection="column" flexGrow={1} >

                        <Box border={border[1]} bgcolor={palette.contentBg} flexGrow={1} display="flex" flexDirection="column" borderRadius="5px" overflow="hidden">
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHeader columns={columns} />
                                    <TableBody>
                                        {allAttendance.map( ( atte, index ) =>
                                            <TableRow onClick={() => showAuid( atte.student_attendence )} sx={{ '&:hover': { background: palette.rowHover } }} className='table-row' key={index}>
                                                <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[0].padding || "15px 20px" }}><strong>{atte.selected_date}</strong></TableCell>
                                                <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[1].padding || "15px 20px" }}><strong>{atte.subject_name_short}</strong></TableCell>
                                                <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[2].padding || "15px 20px" }}><strong>{atte.duration}</strong></TableCell>
                                                <TableCell sx={{ color: table.color, fontSize: "14px", padding: columns[3].padding || "15px 20px" }}><strong>{atte.attendance}</strong></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        {/* <DataGrid autoHeight={true} rows={allAttendance} onRowClick={showAuid} columns={columns} pageSize={( allAttendance.length > 10 ) ? 10 : 0} /> */}
                    </Box>}

                <Dialog onClose={handleClose} open={openDialog}>
                    {auidList.map( auid => (
                        auid
                    ) )}
                </Dialog>

                {allAttendance.length === 0 && !loading &&
                    <Paper sx={{ flexGrow: 1, padding: "20px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                        <img src={noAttendenceImg} width="200px" alt="No attendence details for selected date range!" />
                        <Typography color="textSecondary" variant='subtitle2' marginY="20px">No attendence details for selected date range!</Typography>
                    </Paper>
                }
                {loading &&
                    <Paper sx={{ flexGrow: 1, gap: "10px", padding: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <DataLoadingSpinner waitingMessage="Loading attendance details..." />
                    </Paper>
                }
            </Paper>

        </Box>
    )
}

export default Attendence
