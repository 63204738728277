import React, { useState } from 'react'
import { Box, Divider, Typography, useTheme, TextField, Button, Collapse, Card, CardContent, IconButton, Tooltip, CircularProgress } from '@mui/material'
import addFileImg from '../../../assets/add-file-img.svg'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Icons, attachmentThumbnails, getFileSizeFromBytes } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useParams } from 'react-router-dom'
import { useLiveSessionStore } from '../../../store/livesessions.store'
import { observer } from 'mobx-react'
import { Close } from '@mui/icons-material'

const getFileType = ( file ) => {
    const mime = file.type
    const [type] = mime.split( "/" )
    if ( type === 'video' || mime === "application/x-mpegURL" ) return "video"
    if ( type === 'image' ) return "image"
    return "document"
}


const ResourceInput = observer( ( { handleClose, uploading, setUploading, setState, selectedResources, setSelectedResources, filter } ) => {
    const [fetchingLinkMetadata, setFetchingLinkMetadata] = useState( false )
    const [link, setLink] = useState( '' )

    const LiveSessionStore = useLiveSessionStore()

    const mimeTypeToExtension = {
        'application/pdf': 'pdf',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'text/plain': 'txt',
        'video/mp4': 'mp4'
    }

    const { session_id } = useParams()

    const { palette, border, theme } = useTheme()

    const handleLinkChange = ( event ) => {
        setLink( event.target.value )

    }

    const handleFilesInsert = ( e ) => {
        const files = e.target.files
        let selectedResourceList = []
        for ( let i = 0; i < files.length; i++ ) {

            const resource = {}
            resource.resource = files[i]
            resource.name = files[i].name.split( "." ).slice( 0, -1 ).join( "" )
            resource.type = getFileType( files[i] )
            resource.size = files[i].size
            resource.id = `${getFileType( files[i] )}-${i}${Date.now()}`

            selectedResourceList.push( resource )
        }
        setSelectedResources( [...selectedResources, ...selectedResourceList] )
        e.target.value = ''
    }

    const handleFileNameChange = ( index, value ) => {
        let values = [...selectedResources]
        values[index].name = value
        setSelectedResources( values )
    }

    const removeAttachment = ( index ) => {
        if ( LiveSessionStore.getUploadProgressList[selectedResources[index].id]?.status === 'inprogress' && LiveSessionStore.getUploadProgressList[selectedResources[index].id]?.controller ) {
            LiveSessionStore.getUploadProgressList[selectedResources[index].id].controller.abort()
        } else {
            const resources = [...selectedResources]
            resources.splice( index, 1 )
            setSelectedResources( resources )
        }
    }

    const fetchLinkMetadata = async () => {
        try {
            if ( link && link.length > 0 ) {
                setFetchingLinkMetadata( true )
                const { data: { data } } = await api.liveSessions.extractLinksMetadata( { links: link.split( "," ).map( l => l?.trim() ).filter( l => l?.length > 0 && l.match( /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig ) ) } )
                let selectedResourceList = []
                let errorUrls = []
                let index = 0
                for ( const { meta, success } of data ) {
                    if ( success ) {
                        const resource = {}
                        resource.name = meta["og:title"] || meta["twitter:title"] || meta.title || meta.url
                        resource.type = "link"
                        const linkData = {}
                        linkData.url = meta.url
                        linkData.description = meta.description
                        linkData.title = meta["og:title"] || meta["twitter:title"] || meta.title || meta.url
                        linkData.name = meta.title
                        if ( meta["og:image"] || meta["twitter:image"] )
                            linkData.image = ( meta["og:image"] || meta["twitter:image"] ).split( "," )[0]
                        else if ( meta.favicons && meta.favicons?.length > 0 ) {
                            linkData.image = meta.favicons.find( i =>
                                i.rel === 'icon' && i.href.match( /(http|https)/ ) &&
                                ( ["230x230", "228x228", "192x192", "96x96", "32x32", "16x16"].includes( i.sizes ) )
                            )?.href
                        }
                        linkData.description = meta.description || meta.url
                        resource.resource = linkData
                        resource.id = `link-${index}${Date.now()}`
                        selectedResourceList.push( resource )
                    } else {
                        errorUrls.push( meta.url )
                    }
                    index++
                }
                setSelectedResources( [...selectedResources, ...selectedResourceList] )
                setLink( '' )
                if ( errorUrls.length > 0 )
                    toast( "Metadata of these URLs not found: " + errorUrls.join( ", " ) )
            }
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Couldn't fetch links metadata" )
        } finally {
            setFetchingLinkMetadata( false )
        }
    }

    const uploadResources = async () => {
        for ( let i = 0; i < selectedResources.length; i++ ) {
            const { name } = selectedResources[i]
            if ( !name || name?.trim() === "" ) {
                return toast( "Please provide names to all resources." )
            }
        }
        setUploading( true )

        LiveSessionStore.setUploadsList( selectedResources )

        await Promise.allSettled( selectedResources.map( async resource => {
            const formData = new FormData()
            formData.append( 'name', resource?.name?.split( "." )[0] || "Unknown Resource" )
            formData.append( 'size', resource?.size )
            formData.append( 'resource_type', resource.type )
            formData.append( 'session_category', filter )
            formData.append( 'session_id', session_id )
            formData.append( 'resource', resource.type === 'link' ? JSON.stringify( resource ) : resource.resource )
            return await LiveSessionStore.addResource( formData, session_id, resource )
        } ) )

        setUploading( false )
        handleClose()
    }


    return (
        <>
            <Box display="flex" justifyContent="center">
                <Box padding="20px" gap="10px" width="90%" maxWidth="600px" display="flex" flexDirection="column">
                    <Box >
                        <Box
                            id="file-upload-container"
                            height="200px"
                            borderRadius="10px"
                            position="relative"
                            overflow="hidden"
                            sx={{
                                background: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke-width='2' stroke='${palette.primary.main?.replace( /#/, '%23' )}' stroke-dasharray='6%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                                backgroundColor: palette.primary.light + "22"
                            }}>
                            <Box display="flex" sx={{ background: "none" }} justifyContent="center" gap="10px" flexDirection="column" height="100%" alignItems="center">
                                <input multiple type="file" onChange={handleFilesInsert} style={{ background: "none", position: "absolute", cursor: "pointer", opacity: "0", top: "0", left: "0", width: "100%", height: "100%" }} />
                                <img id='file-img' width="60" height="60" src={addFileImg} alt="document" />
                                <Typography variant='subtitle2'>Drag and drop your file here, or <Typography display="inline-block" fontWeight="bold" color="customThemeColor.main">Choose files</Typography> </Typography>
                                <Typography fontSize="14px" fontWeight="500" color="textSecondary">Supports: <i>{[".mp4", ".webp", ".webm", '.pdf', '.docx', '.ppsx', '.pptx', '.odp', '.potx', '.xslx'].join( ", " )}</i> </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <TextField
                            disabled={fetchingLinkMetadata}
                            size='medium'
                            placeholder='Type or paste urls here...'
                            variant="outlined"
                            value={link}
                            sx={{
                                flexGrow: 1,
                                '& input': {
                                    outline: link && link?.trim() !== "" && link.split( "," ).map( l => l?.trim() ).filter( l => !( l.match( /([(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))*/ig ) ) ).length > 0 ? `2px solid ${palette.errorMessage.main} !important` : "none",
                                    borderRadius: `5px`
                                }
                            }}
                            onChange={handleLinkChange} />
                        <Button disabled={fetchingLinkMetadata || uploading} startIcon={fetchingLinkMetadata && <CircularProgress size={14} />} onClick={fetchLinkMetadata} variant='contained' sx={{ paddingX: "40px" }} disableElevation>{fetchingLinkMetadata ? "Fetching details" : "Add"}</Button>
                    </Box>
                </Box>
            </Box>
            <Divider></Divider>

            <Collapse in={selectedResources.length > 0}  >
                <Box padding="20px">
                    <Box display="flex" justifyContent="space-between">
                        <Typography fontSize="16px">Attachments ({selectedResources.length})</Typography>
                        <Button disabled={uploading} disableElevation size='small' variant='contained' onClick={uploadResources} startIcon={<FileUploadIcon fontSize='small' />} >{uploading ? "Uploading resources..." : "Upload"}</Button>
                    </Box>
                    <Box gap="10px" display="flex" alignItems="center" flexWrap="wrap" padding="20px"  >
                        {selectedResources.length > 0 && selectedResources.map( ( resource, index ) => {
                            const attachment = resource.resource
                            const ext = attachment?.name?.split( "." ).pop()
                            const type = resource.type

                            return (
                                <Card key={index} sx={{ position: "relative", width: '350px', bgcolor: theme === 'dark' && palette.contentBg, border: theme === 'dark' && border[1] }}>
                                    <CardContent sx={{ padding: "10px", '&:last-child': { paddingBottom: "10px" } }} >
                                        <Box display="flex" gap="10px">
                                            <Box display="flex" height="70px" overflow="hidden" sx={{ aspectRatio: "1" }} justifyContent="center" alignItems="center" borderRadius="10px" bgcolor={palette.greyedOut}>
                                                {type === 'link' ?
                                                    attachment['image'] ? <img style={{ objectFit: "fit", maxHeight: "100%", maxWidth: "100%" }} src={attachment['image']} alt={attachment.name} /> : Icons.default.Link :
                                                    <img style={{ objectFit: "fit" }} width="100%" src={attachmentThumbnails[ext] ? attachmentThumbnails[ext] : attachmentThumbnails["default"]} alt={attachment.name} />}
                                            </Box>
                                            <Box padding="5px 0">
                                                <Box display="flex" gap="10px" alignItems="flex-start">
                                                    <TextField disabled={uploading} onFocus={e => e.target.select()} size="small" placeholder='Enter file name here...' onChange={( e ) => handleFileNameChange( index, e.target.value )} value={resource.name} inputProps={{ sx: { fontSize: "16px" } }} variant='outlined' />
                                                    <Tooltip sx={{ position: "absolute", top: "10px", right: "10px", border: border[1] }} title={`Remove ${attachment.name}`}>
                                                        <IconButton onClick={() => { removeAttachment( index ) }} size='small'  >
                                                            <Close sx={{ fontSize: "10px", color: palette.common.font }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Box display="flex" marginTop="5px" alignItems="flex-end" gap="10px" justifyContent="space-between" >
                                                    <Box flexGrow={1}>
                                                        <Box marginBottom="5px" display="flex" gap="10px" alignItems="center" justifyContent="space-between">
                                                            <Typography variant="subtitle2" fontSize="10px" color="textSecondary">{type === 'link' ? "URL" : `.${mimeTypeToExtension[attachment.type] || 'Unknown Type'}`}</Typography>
                                                            <Box>
                                                                <Typography
                                                                    marginRight="10px"
                                                                    fontSize="10px"
                                                                    textTransform="uppercase"
                                                                    display="inline"
                                                                    variant='subtitle2'
                                                                    color={
                                                                        ['failed', 'cancelled'].includes( LiveSessionStore.getUploadProgressList[resource.id]?.status ) ? "errorMessage.main" :
                                                                            LiveSessionStore.getUploadProgressList[resource.id]?.status === 'completed' ? "success" : 'GrayText'
                                                                    }
                                                                >
                                                                    {LiveSessionStore.getUploadProgressList[resource.id]?.status || "queued"} {LiveSessionStore.getUploadProgressList[resource.id]?.status === 'inprogress' ? `(${Math.ceil( ( LiveSessionStore.getUploadProgressList[resource.id]?.progress || 0 ) * 100 )}%)` : ""}
                                                                </Typography>
                                                                {type !== 'link' && <Typography display="inline" fontSize="10px" color='GrayText'>{getFileSizeFromBytes( attachment.size || 0 )}</Typography>}
                                                            </Box>
                                                        </Box>
                                                        {LiveSessionStore.getUploadProgressList[resource.id]?.status !== "failed" && <Box height="3px" borderRadius="3px" overflow="hidden" width="100%" bgcolor="#ccc">
                                                            <Box width={`${( LiveSessionStore.getUploadProgressList[resource.id]?.progress || 0 ) * 100}%`}
                                                                bgcolor={
                                                                    ['failed', 'cancelled'].includes( LiveSessionStore.getUploadProgressList[resource.id]?.status ) ? palette.errorMessage.main :
                                                                        LiveSessionStore.getUploadProgressList[resource.id]?.status === 'completed' ? palette.success.main : palette.primary.main} sx={{ transition: "400ms ease" }} height="100%"></Box>
                                                        </Box>}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            )
                        } )}
                    </Box>
                </Box>


            </Collapse>


        </>
    )
} )

export default ResourceInput