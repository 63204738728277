import { Typography } from "@mui/material"

const Progress = ( {
    size = 150,
    progress = 0,
    trackWidth = 10,
    secondaryColor = `#ddd`,
    indicatorWidth = 10,
    color = `#273679`,
    indicatorCap = `round`,
    label = `Progress`,
    labelColor = `#333`,
    spinnerMode = false,
    spinnerSpeed = 1,
    showLabel = false,
    icon = undefined
} ) => {


    const center = size / 2,
        radius = center - ( trackWidth > indicatorWidth ? trackWidth : indicatorWidth ),
        dashArray = 2 * Math.PI * radius,
        dashOffset = dashArray * ( ( 100 - progress ) / 100 )

    let hideLabel = ( size < 50 || !label.length || spinnerMode ) ? true : false

    return (
        <>
            <div
                className="svg-pi-wrapper"
                style={{ width: size, height: size }}
            >
                <svg
                    className="svg-pi"
                    style={{ width: size, height: size }}
                >
                    <circle
                        className="svg-pi-track"
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={secondaryColor}
                        strokeWidth={trackWidth}
                    />
                    <circle
                        className={`svg-pi-indicator ${spinnerMode ? "svg-pi-indicator--spinner" : ""
                            }`}
                        style={{ animationDuration: spinnerSpeed * 1000 }}
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={color}
                        strokeWidth={indicatorWidth}
                        strokeDasharray={dashArray}
                        strokeDashoffset={dashOffset}
                        strokeLinecap={indicatorCap}
                    />
                </svg>

                {icon && <div
                    className="svg-pi-label"
                    style={{ color: labelColor }}
                >
                    <Typography variant="body2" className="svg-pi-label__loading">
                        {icon}
                    </Typography>

                </div>}

                {!hideLabel && !icon && (
                    <div
                        className="svg-pi-label"
                        style={{ color: labelColor }}
                    >
                        {showLabel && <Typography variant="body2" fontSize="12px" className="svg-pi-label__loading">
                            {label}
                        </Typography>}


                        <Typography variant="body2" fontSize="12px" className="svg-pi-label__progress">
                            {`${progress > 100 ? 100 : progress
                                }%`}
                        </Typography>

                    </div>
                )}
            </div>
        </>
    )

    // return (
    //     <div>
    //         <Box id="circular-progress" sx={{ transition: "background 1s ease", background: `conic-gradient(${color} ${progress * 3.6}deg,${secondaryColor} 0deg)` }} position="relative" width={`${size}px`} height={`${size}px`} bgcolor="#7d2ae8" borderRadius="50%" display="flex" alignItems="center" justifyContent="center">
    //             {!isNaN( progress ) && <Typography bgcolor="#fff" width={`${size * 0.8}px`} borderRadius="50%" fontSize={`${size * 0.3}px`} display="flex" alignItems="center" justifyContent="center" height={`${size * 0.8}px`} fontWeight="bold">{`${progress}%`}</Typography>}
    //         </Box>
    //     </div >
    // )
}

export default Progress
