import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Icons, showDateInIndianDateFormat } from '../../utils/utilities'
import TableHeader from '../../components/common/TableHeader'
import noSessionsImg from '../../assets/noclasses.svg'
import { Link, useParams } from 'react-router-dom'
import { useLiveSessionStore } from '../../store/livesessions.store'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import ConfirmDialog from '../../components/common/ConfirmDialog'
import { useUserStore } from '../../store/user.store'
import CreateLiveSessionDialog from '../../components/presenter/live-session/CreateLiveSessionDialog'
import api from '../../service/api'
import { toast } from 'react-toastify'

const columns = [
    { name: "SL. NO.", align: "left", padding: "10px 20px 10px 40px" },
    { name: "TITLE", align: "left" },
    { name: "SESSION BY", align: "left" },
    { name: "LAST UPDATED", align: "left" },
    { name: "RESOURCE COUNT", align: "right", padding: "10px 40px 10px 20px" },
    { name: "Access groups", align: "left" },
    { name: "ACTIONS", align: "right", padding: "10px 40px 10px 20px" },
]

const SubjectClassSessions = observer( () => {

    const [loading, setLoading] = useState( true )
    const [deleting, setDeleting] = useState( false )
    const [createNewDialogState, setCreateNewDialogState] = useState( false )
    const [sessionSelectedForDeletion, setSessionSelectedForDeletion] = useState( null )

    const UserStore = useUserStore()
    const LiveSessionsStore = useLiveSessionStore()

    const { subject_id } = useParams()

    const { palette, table, border } = useTheme()

    const deleteSession = async () => {
        try {
            setDeleting( true )
            if ( sessionSelectedForDeletion )
                await api.liveSessions.deleteLiveSession( sessionSelectedForDeletion.id )
            await LiveSessionsStore.fetchLiveSessions( subject_id )
            setSessionSelectedForDeletion( null )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while deleting the class session" )
        } finally {
            setDeleting( false )
        }
    }

    const getAccessGroups = groups => {
        let groupsNames = []
        for ( let i = 0; i < groups.length; i++ ) {
            const group = groups[i]
            let title = ''
            if ( group.section_assignment_id ) {
                title = `${group.course_branch_short_name ? group.course_branch_short_name + "," : ""} ${group?.current_year ? `Y${group?.current_year},` : ""} ${group.current_sem ? `S${group.current_sem},` : ""} Sec: ${group.section_short_name}`
            } else
                title = `${group.course_name_short ? group.course_name_short + "," : ""} ${group?.current_year ? `Y${group?.current_year},` : ""} ${group.current_sem ? `S${group.current_sem},` : ""} Batch: ${group.batch_short_name}`
            groupsNames.push( title )
        }
        return groupsNames
    }

    useEffect( () => {
        const getData = async () => {
            await LiveSessionsStore.fetchLiveSessions( subject_id )
            setLoading( false )
        }
        getData()
    }, [LiveSessionsStore, subject_id] )

    return (
        <Box flexGrow={1} display="flex" flexDirection="column">

            {'STUDENT' !== UserStore.getUser.user_role && <CreateLiveSessionDialog setState={() => setCreateNewDialogState( false )} state={createNewDialogState} />}

            <ConfirmDialog status={Boolean( sessionSelectedForDeletion )} confirmAction={deleteSession} message={`All the resource association will be lost!`} actionName="Yes! delete it" cancelAction={() => setSessionSelectedForDeletion( null )} waiting={deleting} />

            <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" padding="10px 20px 0 20px" >
                <Typography variant="h6" color='primaryDark.main' fontSize="18px">Your Sessions</Typography>
                {UserStore.getUser?.user_role !== "STUDENT" && <Button onClick={() => setCreateNewDialogState( true )} variant="contained" disableElevation startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} color="primary">
                    Create Session
                </Button>}
            </Box>

            {!loading && LiveSessionsStore.getLiveSessions.length > 0 && <Box margin="10px 20px 20px 20px" border={border[1]} borderRadius="5px" flexGrow={1} display="flex" flexDirection='column' bgcolor={palette.contentBg} overflow="auto" >
                <TableContainer sx={{ minWidth: "750px", flexGrow: 1, overflow: "auto", borderRadius: "5px" }}>
                    <Table stickyHeader>
                        <TableHeader columns={'STUDENT' === UserStore.getUser.user_role ? columns.slice( 0, columns.length - 2 ) : columns} />
                        <TableBody>
                            {LiveSessionsStore.getLiveSessions.map( ( session, index ) => (
                                <TableRow className='table-row' sx={{ "&:hover": { background: palette.rowHover }, cursor: "pointer" }} key={session.id}>
                                    <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[0].align || 'left'} >
                                        <Link className='default-link' to={`${session.id}`}>
                                            <Box padding={columns[0].padding || "10px 40px"} display="flex" alignItems="center" gap="10px">
                                                <strong>
                                                    {index + 1}
                                                </strong>
                                            </Box>
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[1].align || 'left'} >
                                        <Link className='default-link' to={`${session.id}`}>
                                            <Box padding={columns[1].padding || "10px 20px"} display="flex" alignItems="center" gap="10px">
                                                <strong>
                                                    {session.name}
                                                </strong>
                                            </Box>
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[2].align || 'left'} >
                                        <Link className='default-link' to={`${session.id}`}>
                                            <Box padding={columns[2].padding || "10px 20px"} display="flex" alignItems="center" gap="10px">
                                                <strong>
                                                    {session.faculty_details?.name}
                                                </strong>
                                            </Box>
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[3].align || 'left'} >
                                        <Link className='default-link' to={`${session.id}`}>
                                            <Box padding={columns[3].padding || "10px 20px"} display="flex" alignItems="center" gap="10px">
                                                <strong>
                                                    {showDateInIndianDateFormat( session.updated_at )}
                                                </strong>
                                            </Box>
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[4].align || 'left'} >
                                        <Link className='default-link' to={`${session.id}`}>
                                            <Box padding={columns[4].padding || "10px 20px"} gap="10px">
                                                <strong>
                                                    {session.resource_count}
                                                </strong>
                                            </Box>
                                        </Link>
                                    </TableCell>

                                    {'STUDENT' !== UserStore.getUser.user_role && <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[5].align || 'left'} >
                                        <Link className='default-link' to={`${session.id}`}>
                                            <Box padding={columns[5].padding || "10px 20px"} display="flex" flexDirection="column" gap="5px">
                                                {getAccessGroups( session.access_groups ).map( group => (
                                                    <strong key={group}>
                                                        {group}
                                                    </strong>
                                                ) )}

                                            </Box>
                                        </Link>
                                    </TableCell>}
                                    {'STUDENT' !== UserStore.getUser.user_role && <TableCell sx={{ border: "none", color: table.color, padding: "0" }} align={columns[6].align || 'left'} >
                                        <Box padding={columns[6].padding || "10px 20px"} gap="10px">
                                            <IconButton onClick={() => setSessionSelectedForDeletion( session )} size="small" color="error">
                                                {Icons.default.DeleteIconOutlined}
                                            </IconButton>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            ) )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>}

            {!loading && LiveSessionsStore.getLiveSessions.length === 0 && <Box margin="10px" borderRadius="5px" bgcolor={palette.contentBg} flexGrow={1} display="flex" flexDirection="column" alignItems="center" padding="20px" justifyContent="center" gap="20px">
                <img src={noSessionsImg} width="150px" alt="No quizes" />
                <Typography variant="subtitle2">You does not have any live sessions!</Typography>
                {UserStore.getUser?.user_role !== "STUDENT" && <Button onClick={() => setCreateNewDialogState( true )} startIcon={Icons.default.AddIcon} variant='outlined' color="primaryDark" sx={{ textTransform: "capitalize" }} >Create one</Button>}
            </Box>}
            {loading && <DataLoadingSpinner waitingMessage="Loading sessions..." />}

        </Box>
    )
} )

export default SubjectClassSessions
