import { Typography, Button, Box, RadioGroup, FormControlLabel, Tooltip, FormControl, Radio, Checkbox, useTheme, CircularProgress, } from '@mui/material'
import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-quill/dist/quill.snow.css'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import { useEffect } from 'react'
import { EditableMathField } from 'react-mathquill'
import { latexDelimiters, shuffleArray } from '../../utils/utilities'
import { useExamStore } from '../../store/exam.store'
import AudioPlayer from './AudioPlayer'
import { AudioFileOutlined } from '@mui/icons-material'
import api from '../../service/api'
import { useUserStore } from '../../store/user.store'
import Latex from 'react-latex-next'
import CustomWysiwyg from '../common/CustomWysiwyg'

export default function AnswerSection( { saving, question, currentQuestionIndex, nextQuestion, submissions, submitAnswer, toggleQuestions, showQuestions } ) {
    const [currentSubmissions, setcurrentSubmissions] = useState( null )
    const [resetting, setResetting] = useState( false )
    const [signedUrls, setSignedUrls] = useState( [] )

    const ExamStore = useExamStore()
    const UserStore = useUserStore()

    const { palette, border } = useTheme()


    const moveToNextQuestion = ( e ) => {
        nextQuestion()
    }

    const handleSingleSelectionChange = async e => {
        const newSubmissionValue = { ...currentSubmissions, [question.question.question_id]: e.target.value }
        setcurrentSubmissions( newSubmissionValue )
        await submitAnswer( newSubmissionValue[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
        moveToNextQuestion()
    }

    const handleMultipleSelectionChange = ( e, option ) => {
        let newSubmissionValue
        if ( currentSubmissions[question.question.question_id] )
            newSubmissionValue = { ...currentSubmissions, [question.question.question_id]: { ...currentSubmissions[question.question.question_id], [option.answer_id]: e.target.checked ? 1 : 0 } }
        else
            newSubmissionValue = { ...currentSubmissions, [question.question.question_id]: { [option.answer_id]: e.target.checked ? 1 : 0 } }
        setcurrentSubmissions( newSubmissionValue )
        submitAnswer( newSubmissionValue[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
    }

    // const saveDescriptiveAnswer = () => {
    //     submitAnswer( currentSubmissions[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
    // }

    const resetAnswer = () => {
        setResetting( true )
        const newSubmissionValue = { ...currentSubmissions, [question.question.question_id]: '' }
        setcurrentSubmissions( newSubmissionValue )
        submitAnswer( newSubmissionValue[question.question.question_id], question.question.question_type, question.question.question_answer_selection, true )
        setResetting( false )
    }

    const submit = ( e ) => {
        e.preventDefault()
        // console.log( currentSubmissions[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
        nextQuestion( currentSubmissions[question.question.question_id], question.question.question_type, question.question.question_answer_selection )
    }

    const modules = {
        toolbar: [
            [{ header: 1 }, { header: 2 }, { header: [3, 4, 5, 6] }, { font: [] }],
            [{ size: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
        ]
    }

    useEffect( () => {
        const getAudioUrls = async () => {
            let signedUrlsList = []
            try {
                for ( let i = 0; i < question.question?.audio_attachments_keys.length; i++ ) {
                    const key = question.question.audio_attachments_keys[i]
                    const { data: signedUrl } = await api.getAudioPresignedUrl( ExamStore.getExam.exam.exam_id, question.question.question_id, { key } )
                    signedUrlsList.push( signedUrl.data )
                }
                setSignedUrls( signedUrlsList )
            } catch ( err ) {
                if ( err?.response?.status === 401 || err?.response?.data?.unauth ) {
                    UserStore.setSessionState( "ended" )
                }
                else
                    toast( err?.response?.data?.message || "Something went wrong while fetchiing audio attachments" )
            }
        }
        getAudioUrls()
    }, [question, ExamStore, UserStore] )


    useEffect( () => {
        const subs = {}
        submissions.forEach( sub => {
            if ( sub.answer_id )
                if ( sub.question_answer_selection === 'single' )
                    subs[sub.question_id] = sub.answer_id
                else
                    subs[sub.question_id] = subs[sub.question_id] ? { ...subs[sub.question_id], [sub.answer_id]: 1 } : { [sub.answer_id]: 1 }
            else
                subs[sub.question_id] = sub.answer_descriptive
        } )
        if ( ExamStore.getExam.exam.enable_randomize )
            shuffleArray( question.options )
        setcurrentSubmissions( subs )
    }, [submissions, ExamStore, question] )


    return (
        <Box sx={{ height: "100%", overflow: "auto", flexGrow: 1, }} borderLeft={showQuestions ? "3px solid grey" : "none"} display="flex" flexDirection="column" >
            <Box flexGrow={1} overflow="auto" borderRadius="5px" alignItems="flex-start" margin="10px" bgcolor={palette.contentBg} display="flex" flexDirection="column" border={border[1]} padding="10px" >
                <Tooltip title="Toggle questions">
                    <Button onClick={toggleQuestions} startIcon={showQuestions ? <ToggleOnIcon /> : <ToggleOffIcon />} sx={{ textTransform: "capitalize" }}>
                        {showQuestions ? "Close" : "Open"} Question list
                    </Button>
                </Tooltip>
                {question && <Box>
                    <Typography fontSize={{ lg: '14px', md: "14px", sm: "12px", xs: "12px" }} paddingLeft="10px" variant='subtitle2' color="textSecondary">Question {currentQuestionIndex + 1}</Typography>
                    <Box fontSize="16px" fontWeight="600" marginTop="10px" color={palette.common.font} paddingLeft="10px" align='justify'>
                        {question.question.question_text.trim().includes( 'LATEX-' ) ? <EditableMathField latex={question.question.question_text.trim().split( "LATEX-" )[1]} id='static-latex'> </EditableMathField> : <Latex delimiters={latexDelimiters} >{question.question.question_text.trim()}</Latex>}
                    </Box>
                    {question.question.question_hint && <Typography paddingLeft="10px" fontSize="13px" fontStyle="italic" variant='body2' align='justify' color="textSecondary">HINT: {question.question.question_hint}</Typography>}
                    {question.question.audio_attachments.length > 0 && <Box display="flex" margin="10px 0 0 10px" flexWrap="wrap" gap="10px" alignItems="center">
                        {question.question.audio_attachments.map( ( attachment, index ) => (
                            <Box padding="5px" bgcolor="#fff" display="flex" gap="10px" alignItems="center" border="1px solid #d3d3d3">
                                <AudioFileOutlined sx={{ fontSize: "20px" }} />
                                <AudioPlayer audioName={question.question.audio_attachments[index]} audioSrc={signedUrls[index]} />
                            </Box>
                        ) )}
                    </Box>}
                </Box>}
                {currentSubmissions && question &&
                    <Box flexGrow={1} width="100%" sx={{ overflow: "auto" }}>
                        <form onSubmit={submit} method="post" id='answer-form' style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box style={{ flexGrow: 1, marginTop: "20px" }}>
                                <FormControl fullWidth>
                                    {question.question.question_type === 'MCQ' && question.question.question_answer_selection === 'single' &&
                                        <>
                                            <RadioGroup sx={{ padding: "0 0 0 20px", display: "flex", gap: "10px", flexDirection: "column", maxWidth: "600px", width: "90%" }} value={currentSubmissions[question.question.question_id] ? currentSubmissions[question.question.question_id] : 'NA'} name={String( question.question.question_id )} onChange={handleSingleSelectionChange}>
                                                {question.options.map( ( option, index ) => (
                                                    <FormControlLabel key={option.answer_id} value={option.answer_id} control={<Radio />}
                                                        // label={parse( option.answer_text )}
                                                        label={
                                                            option.answer_text.trim().includes( 'LATEX-' ) ?
                                                                <EditableMathField id='static-latex' latex={option.answer_text.trim().split( "LATEX-" )[1] + "LATEX"} /> :
                                                                <Latex delimiters={latexDelimiters}>{option.answer_text.trim()}</Latex>
                                                        }
                                                        sx={( currentSubmissions[question.question.question_id] && parseInt( currentSubmissions[question.question.question_id] ) === parseInt( option.answer_id ) ) ? {
                                                            width: "90%",
                                                            maxWidth: "600px",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                            background: palette.common.bg,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            transition: "0.2s ease",
                                                            whiteSpace: "pre-wrap",
                                                            '& >p': {
                                                                margin: "0", display: "inline-block"
                                                            },
                                                            boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
                                                            border: `2px solid ${palette.primary.main}`,
                                                            color: "primary.main",
                                                            "&:hover": {
                                                                boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
                                                                border: `2px solid ${palette.primary.main}`
                                                            }, "& span": {
                                                                fontWeight: "bolder",
                                                            }
                                                        } : {
                                                            width: "90%",
                                                            maxWidth: "600px",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                            background: palette.common.bg,
                                                            border: "2px solid #aaaaaa99",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            transition: "0.2s ease",
                                                            whiteSpace: "pre-wrap",
                                                            "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #fff" },
                                                            '& >p': {
                                                                margin: "0", display: "inline-block"
                                                            }
                                                        }
                                                        } />
                                                ) )}
                                            </RadioGroup>
                                        </>
                                    }
                                    {question.question.question_type === 'MCQ' && question.question.question_answer_selection !== 'single' &&
                                        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column", padding: "0 0 0 20px" }}>
                                            {question.options.map( ( option, index ) => (
                                                <FormControlLabel key={option.answer_id}
                                                    value={option.answer_id}
                                                    checked={currentSubmissions[question.question.question_id] ? currentSubmissions[question.question.question_id][option.answer_id] === 1 ? true : false : false}
                                                    control={<Checkbox onChange={e => handleMultipleSelectionChange( e, option )} />}
                                                    // label={parse( option.answer_text )}
                                                    label={option.answer_text.trim().includes( 'LATEX-' ) ?
                                                        <EditableMathField id='static-latex' latex={option.answer_text.trim().split( "LATEX-" )[1]} /> :
                                                        <Latex delimiters={latexDelimiters}>{option.answer_text.trim()}</Latex>
                                                    }
                                                    sx={{
                                                        padding: "10px", borderRadius: "5px", background: "white", border: "2px solid #aaaaaa99", display: "flex", alignItems: "center", transition: "0.2s ease", whiteSpace: "pre-wrap", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #fff" }, '& >p': {
                                                            margin: "0", display: "inline-block"
                                                        }
                                                    }} style={( currentSubmissions[question.question.question_id] && parseInt( currentSubmissions[question.question.question_id] ) === parseInt( option.answer_id ) ) ? { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #374ba4", color: "#374ba4", "&:hover": { boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 4px 8px 1px rgb(0 0 0 / 10%), 0px 3px 14px 2px rgb(0 0 0 / 12%)", border: "2px solid #374ba4" }, "& span": { fontWeight: "bolder", } } : null}
                                                />
                                            ) )}
                                            <Button variant='outlined' disableElevation color='secondary' sx={{ textTransform: "capitalize", margin: "20px 0 0 -10px", width: "fit-content", '@media(max-width:350px)': { width: "calc(100% - 20px)" } }} onClick={e => setcurrentSubmissions( { ...currentSubmissions, [question.question.question_id]: null } )}>Reset</Button>
                                        </Box>
                                    }
                                    {question.question.question_type === 'descriptive' && <CustomWysiwyg modules={modules} value={currentSubmissions[question.question.question_id] ? currentSubmissions[question.question.question_id] : ''} onChange={( e ) => setcurrentSubmissions( { ...currentSubmissions, [question.question.question_id]: e } )} />}
                                </FormControl>
                            </Box>
                            <Box marginTop="40px" display="flex" flexWrap="wrap" gap="20px" paddingRight="10px">
                                <Button variant='outlined' disabled={resetting || saving} disableElevation color='secondary' startIcon={resetting && <CircularProgress size={14} />} sx={{ textTransform: "capitalize", }} onClick={resetAnswer}>{resetting ? "Resetting..." : "Reset"}</Button>
                                <Button disableElevation disabled={saving} type='submit' size='small' variant="contained" sx={{ textTransform: "capitalize" }}>{saving ? "Saving your answer..." : "Next"}</Button>
                            </Box>
                        </form>
                    </Box >}
                <ToastContainer position='top-center' autoClose={2000} hideProgressBar closeOnClick theme='dark' style={{ fontSize: "12px", zIndex: 100000000 }} />
            </Box>
        </Box >
    )
}
