import { Box, Button, Paper, Typography } from '@mui/material'
import { useAuthStore } from '../store/auth.store'
import { useNavigate } from 'react-router-dom'
import notFoundImg from '../assets/not-found.svg'
import React from 'react'

const PageNotFound = () => {

    const AuthStore = useAuthStore()
    const navigate = useNavigate()

    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1}>
            <Paper sx={{ width: "100%", position: "relative", background: `url('/imgs/bg-pattern1.png')`, padding: "20px 40px", display: "flex", flexDirection: "column", gap: "20px", alignItems: "center", borderRadius: "5px", flexGrow: 1 }}>
                <Box sx={{ borderRadius: "5px", zIndex: 5, background: "rgba(0,0,0,0.7)", position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}></Box>
                {AuthStore.getAuth ? <Box justifyContent="center" position="relative" sx={{ zIndex: 10 }} display="flex" flexDirection="column" flexGrow={1} alignItems="center">
                    <img width="200px" src={notFoundImg} alt="not-found" />
                    <Typography color="white" variant='subtitle2' fontSize="3rem" sx={{ margin: "0" }}>404</Typography>
                    <Typography color="white" textAlign="center" marginTop="-10px" marginBottom="20px" variant='h6' fontWeight="300"> <i>The resource you are looking for is unavailable! please check the URL</i> </Typography>
                    <Button disableElevation sx={{ marginBottom: "40px", background: "white", color: "black", textTransform: "capitalize", "&:hover": { background: "white", color: "black", } }} variant='contained' onClick={() => navigate( -1, { replace: true } )} >Go Back</Button>
                </Box> :
                    <Box position="relative" sx={{ zIndex: 10 }} display="flex" flexDirection="column" flexGrow={1} justifyContent="center" alignItems="center">
                        <Typography color="white" variant='subtitle2' fontSize="20vh" sx={{ margin: "0" }}>OOPS!</Typography>
                        <Typography color="white" textAlign="center" marginBottom="20px" variant='h6' fontWeight="300">
                            <i>Session Ended!</i>
                        </Typography>
                        <Button disableElevation sx={{ background: "white", color: "black", textTransform: "capitalize", "&:hover": { background: "white", color: "black", } }} variant='contained' onClick={() => navigate( window.location.pathname.includes( "admin" ) ? "/admin" : "/", { replace: true } )} >Signin</Button>
                    </Box>
                }

            </Paper>
        </Box>
    )
}

export default PageNotFound
