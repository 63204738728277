import { Box } from '@mui/material'
import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import DataLoadingSpinner from './DataLoadingSpinner'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import { isIOS } from '../../utils/utilities'

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.mjs`


const DocumentViewer = ( { file, type, loadingText = "Loading submission file...", } ) => {
    const [pages, setPages] = useState( [] )

    function onDocumentLoadSuccess( { numPages } ) {
        const pageArray = []
        for ( let i = 1; i < numPages + 1; i++ ) {
            pageArray.push( i )
        }
        setPages( pageArray )
    }

    return (
        <Box flexGrow={1} flexShrink={0} display="flex" alignItems="center" justifyContent="center">
            {type?.toLowerCase() === 'pdf' ? <Document onError={() => { }} loading={<DataLoadingSpinner waitingMessage={loadingText} />} file={file} onLoadSuccess={onDocumentLoadSuccess}>
                {pages.map( ( page ) => (
                    <Page key={page} pageNumber={page} />
                ) )}
            </Document> : !isIOS() ?
                <DocViewer
                    className={`react-doc-viewer-custom ${type}`}
                    pluginRenderers={DocViewerRenderers}
                    documents={[{ uri: file, fileType: type }]}
                /> : ""}
        </Box>
    )
}

export default DocumentViewer