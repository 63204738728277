import { Box, Card, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import Progress from '../common/Progress'
import api from '../../service/api'
import { toast } from 'react-toastify'

const AcademicsProgressCard = () => {

    const [progressColors, setProgressColors] = useState( [] )
    const [progressValue, setProgressValue] = useState( 0 )
    const [selectedProgress, setSelectedProgress] = useState( null )
    const [studentAcademicsData, setStudentAcademicsData] = useState( {} )
    const [isStudentsAcademicsDataLoaded, setIsStudentsAcademicsDataLoaded] = useState( false )

    const getStudentsAcademicsData = useCallback( async () => {
        try {
            const { data } = await api.fetchStudentAcademicRules()
            const studentData = data.data
            const structuredData = { itemCount: studentData.length, requiredAndCompleted: 0, totalCompleted: 0, totalRequired: 0, checklistData: [] }
            for ( let i = 0; i < studentData.length; i++ ) {
                const checklist = studentData[i]
                if ( checklist.session_type === "year" ) {
                    if ( structuredData.checklistData[checklist.session_number] ) {
                        if ( checklist.is_required ) {
                            structuredData.checklistData[checklist.session_number].totalRequired += 1
                            structuredData.totalRequired += 1
                            if ( checklist.is_completed )
                                structuredData.requiredAndCompleted += 1

                        }
                        if ( checklist.is_completed ) {
                            structuredData.checklistData[checklist.session_number].totalCompleted += 1
                            structuredData.totalCompleted += 1
                        }
                    } else {
                        structuredData.checklistData[checklist.session_number] = { totalRequired: checklist.is_required ? 1 : 0, totalCompleted: checklist.is_completed ? 1 : 0 }
                        structuredData.totalCompleted += checklist.is_completed ? 1 : 0
                        structuredData.totalRequired += checklist.is_required ? 1 : 0
                        structuredData.requiredAndCompleted += checklist.is_required && checklist.is_completed ? 1 : 0
                    }
                } else {
                    const year = Math.ceil( checklist.session_number / 2 )
                    if ( structuredData.checklistData[year] ) {
                        if ( checklist.is_required ) {
                            structuredData.checklistData[year].totalRequired += 1
                            structuredData.totalRequired += 1
                            if ( checklist.is_completed )
                                structuredData.requiredAndCompleted += 1
                        }
                        if ( checklist.is_completed ) {
                            structuredData.checklistData[year].totalCompleted += 1
                            structuredData.totalCompleted += 1
                        }
                    } else {
                        const temp = { totalRequired: checklist.is_required ? 1 : 0, totalCompleted: checklist.is_completed ? 1 : 0 }
                        structuredData.checklistData[year] = temp
                        structuredData.totalCompleted += checklist.is_completed ? 1 : 0
                        structuredData.totalRequired += checklist.is_required ? 1 : 0
                        structuredData.requiredAndCompleted += checklist.is_required && checklist.is_completed ? 1 : 0
                    }
                }
            }
            setStudentAcademicsData( structuredData )
            setIsStudentsAcademicsDataLoaded( true )
            setSelectedProgress( 'all' )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong" )
        }
    }, [] )

    useEffect( () => {
        getStudentsAcademicsData()
    }, [getStudentsAcademicsData] )

    useEffect( () => {
        if ( isStudentsAcademicsDataLoaded && selectedProgress ) {
            if ( selectedProgress === 'all' ) {
                const prog = Math.floor( ( studentAcademicsData.totalCompleted / ( studentAcademicsData.itemCount > 0 ? studentAcademicsData.itemCount : 1 ) ) * 100 ) || 0
                setProgressValue( prog || 0 )
                setProgressColors( ['#50B5FF', '#EDF0EE'] )
            } else if ( selectedProgress === 'required' ) {
                const prog = Math.floor( ( studentAcademicsData.requiredAndCompleted / ( studentAcademicsData.totalRequired > 0 ? studentAcademicsData.totalRequired : 1 ) ) * 100 ) || 0
                setProgressValue( prog || 0 )
                setProgressColors( ['#FB916B', '#EDF0EE'] )
            }
        }
    }, [isStudentsAcademicsDataLoaded, studentAcademicsData, selectedProgress] )

    return (
        <Card sx={{ flexShrink: "0", flexGrow: 1, display: "flex", flexDirection: "column" }} elevation={1}>
            <Box display="flex" flexDirection="column" flexGrow={1} borderRadius="10px" padding="20px" >
                <Typography variant="h6" fontSize="20px" fontWeight="700">Academic Progress</Typography>
                <Box flexGrow={1} display="flex" justifyContent="space-between" flexWrap="wrap" gap="50px" alignItems="center" >
                    <Box>
                        <Typography onMouseEnter={() => setSelectedProgress( 'required' )} marginBottom="15px" variant='subtitle2' borderLeft="4px solid #FB916B" fontWeight={selectedProgress !== 'required' ? "300" : "600"} paddingLeft="10px" textTransform="capitalize">Required</Typography>
                        <Typography onMouseEnter={() => setSelectedProgress( 'all' )} variant='subtitle2' borderLeft="4px solid #50B5FF" paddingLeft="10px" fontWeight={selectedProgress !== 'all' ? "300" : "600"} textTransform="capitalize">Completed</Typography>
                    </Box>
                    {isStudentsAcademicsDataLoaded && <Progress size={80} color={progressColors[0] || '#50B5FF'} secondaryColor={progressColors[1] || '#EDF0EE'} progress={progressValue} />}
                </Box>
            </Box>
        </Card>
    )
}

export default AcademicsProgressCard
