import { Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { useDiscussionForum } from '../../store/discussionforum.store'
import { Icons } from '../../utils/utilities'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'
import React, { useRef, useState } from 'react'
import api from '../../service/api'
import *  as Yup from 'yup'
import CustomWysiwyg from '../common/CustomWysiwyg'

let editorRef, filesList = []

const handleFileInput = async ( type ) => {
    const input = document.createElement( 'input' )

    input.setAttribute( 'type', 'file' )
    input.setAttribute( 'accept', type === 'image' ? 'image/*' : "video/*" )
    input.click()

    input.onchange = async () => {
        var file = input.files[0]
        var formData = new FormData()
        // var fileName = `${Date.now()}_${file.name}`
        if ( type === 'image' && !file.type?.includes( 'image' ) ) {
            toast( "Invalid image format!" )
            return ''
        }
        if ( type === 'video' && !file.type?.includes( 'video' ) ) {
            toast( "Invalid video format!" )
            return ''
        }

        formData.append( type, file )

        try {
            const { data: { data: file } } = type === "image" ? await api.discussionForum.uploadImage( formData ) : await api.discussionForum.uploadVideo( formData )
            const range = editorRef.current.getSelection()

            var url = file.location

            editorRef.current.insertEmbed( range.index, type, url )
            editorRef.current.setSelection( range.index + 1 )
            filesList.push( url )
            setTimeout( () => {
                editorRef.current.focus()
            }, 10 )

        } catch ( err ) {
            console.log( err )
        }
    }
}

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
            ['code-block'],
        ],
        handlers: {
            image: async () => await handleFileInput( 'image' ),
            video: async () => await handleFileInput( 'video' )
        }
    }
}

function CommentBox( { commentBoxState, setCommentingTo, setCommentBoxState, commentingTo } ) {

    const DiscussionForumStore = useDiscussionForum()
    const { id: discussionId } = useParams()
    editorRef = useRef()

    const [posting, setPosting] = useState( false )

    const { palette } = useTheme()

    const initialValues = {
        comment_content: '',
        parent: commentingTo.parent
    }

    const validationSchema = Yup.object().shape( {} )


    const handleClose = ( reset ) => {
        reset()
        setCommentingTo( null )
        setCommentBoxState( false )
    }

    const handleCommentValueChange = ( val, setFieldValue, previousValue ) => {
        setFieldValue( 'comment_content', val )

        if ( val.length !== previousValue.length ) {
            for ( let i = 0; i < filesList.length; i++ ) {
                const url = filesList[i]
                if ( !val.includes( url ) ) {
                    try {
                        api.discussionForum.deleteFile( { url } )
                    } catch ( err ) {

                    }
                }
            }
        }
    }

    const submitComment = async ( values, { resetForm } ) => {
        setPosting( true )
        const val = values.comment_content
        if ( !val || val?.trim() === "" || val?.trim()?.replace( /\s/gi, "" ) === `<p></p>` || val?.trim()?.replace( /\s/gi, "" ) === `<p><br></p>` ) {
            toast( "Please write a comment before submitting" )
        } else {
            if ( await DiscussionForumStore.postComment( discussionId, values ) ) {
                resetForm()
                handleClose( resetForm )
            }
        }
        setPosting( false )
    }

    return (
        <Dialog open={commentBoxState} PaperProps={{ sx: { width: "85%", maxWidth: "750px" } }}>
            <DialogContent sx={{ padding: 0 }} >
                <Box display="flex" height="100%" flexDirection="column">
                    <Formik initialValues={initialValues} onSubmit={submitComment} validationSchema={validationSchema} >
                        {( { values, setFieldValue, resetForm } ) => ( <Box>
                            <Box padding="10px 20px 0 20px" display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle2" fontSize="16px"><em>{commentingTo.name}</em> </Typography>
                                <Tooltip title="Cancel comment">
                                    <IconButton onClick={() => handleClose( resetForm )}>{Icons.default.CloseIcon}</IconButton>
                                </Tooltip>
                            </Box>
                            <Box margin="20px" marginTop="10px" borderRadius="5px" bgcolor={palette.form.formBg} >
                                <Form>
                                    <CustomWysiwyg height='150px' onChange={val => handleCommentValueChange( val, setFieldValue, values.comment_content )} setParentEditorRef={( refVal ) => editorRef = refVal} value={values.comment_content} modules={modules} placeholder='Add your comment here...' />
                                    <Box marginTop="20px">
                                        <Button disabled={posting} size="large" type="submit" endIcon={posting ? <CircularProgress size={14} /> : Icons.default.Send} variant='contained' disableElevation sx={{ textTransform: "capitalize" }}>{posting ? "Posting..." : "Post comment"}</Button>
                                    </Box>
                                </Form>
                            </Box>
                        </Box> )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default CommentBox