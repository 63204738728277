import { Box, Button, CircularProgress, Dialog, DialogActions, useTheme, DialogContent, DialogTitle, Paper, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import api from '../../service/api'
import { useState } from 'react'
import noClassessImg from '../../assets/nosession.svg'

const SessionFeedback = () => {

    const [sessions, setSessions] = useState( [] )
    const [isLoading, setIsLoading] = useState( true )
    const [feedbackData, setFeedbackData] = useState( { default: "" } )
    const [feedbackQuestions, setFeedbackQuestions] = useState( [] )
    const [isSubmittingFeedback, setIsSubmittingFeedback] = useState( false )
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState( false )
    const [selectedRoom, setSelectedRoom] = useState( null )
    const [studentFeedbacks, setStudentFeedbacks] = useState( [] )

    const { palette } = useTheme()

    const submitFeedback = async () => {
        setIsSubmittingFeedback( true )
        try {
            await api.submitSessionFeedback( selectedRoom.room_session_id, feedbackData )
            setSelectedRoom( null )
            setFeedbackDialogOpen( false )
            toast.success( "Feedback submitted successfully!" )
            setFeedbackData( { default: "" } )
            setFeedbackDialogOpen( false )
        } catch ( err ) {
            console.log( err )
        } finally {
            setIsSubmittingFeedback( false )
        }
    }

    const getFeedbackQuestions = async ( room ) => {
        try {
            setSelectedRoom( room )
            const res = await api.fetchFeedbackQuestions()
            setFeedbackQuestions( res.data.data )
            setFeedbackDialogOpen( true )
        } catch ( err ) {
            console.log( err )
        }
    }

    useEffect( () => {
        const getData = async () => {
            try {
                const res = await api.getFeedbackSessions()
                const studentFeedbacks = await api.fetchStudentFeedbacks()
                const roomStudentMap = {}
                for ( let i = 0; i < studentFeedbacks.data.data.length; i++ ) {
                    const element = studentFeedbacks.data.data[i]
                    roomStudentMap[element.room_session_id] = true
                }
                setStudentFeedbacks( roomStudentMap )
                setSessions( res.data.data )
            } catch ( err ) {
                console.log( err )
            } finally {
                setIsLoading( false )
            }
        }
        getData()
    }, [] )

    return (
        <Box padding="20px" flexGrow={1} minWidth="0">
            <Dialog PaperProps={{ sx: { width: "600px" } }} open={feedbackDialogOpen}>
                <DialogTitle>
                    {`${selectedRoom?.room_session_data.course_short_name}-${selectedRoom?.room_session_data.subject_name_short}`}
                    <Typography variant='subtitle2' component="small" display="block">Give your feedback</Typography>
                </DialogTitle>
                <DialogContent>
                    {feedbackQuestions?.map( ( question, k ) => (
                        <Box marginBottom="10px" key={k}>
                            <Typography gutterBottom variant='subtitle2'>{question.question}</Typography>
                            {question.enable_rating && <Rating onChange={( e, newRating ) => setFeedbackData( { ...feedbackData, [question.feedback_setting_id]: feedbackData[question.feedback_setting_id] ? { ...feedbackData[question.feedback_setting_id], rating: newRating } : { rating: newRating } } )} value={feedbackData[question.feedback_setting_id] ? feedbackData[question.feedback_setting_id]["rating"] ? feedbackData[question.feedback_setting_id]["rating"] : 0 : 0} />}
                            {question.enable_descriptive && <TextField onChange={( e ) => setFeedbackData( { ...feedbackData, [question.feedback_setting_id]: feedbackData[question.feedback_setting_id] ? { ...feedbackData[question.feedback_setting_id], remarks: e.target.value } : { remarks: e.target.value } } )} value={feedbackData[question.feedback_setting_id] ? feedbackData[question.feedback_setting_id]["remarks"] ? feedbackData[question.feedback_setting_id]["remarks"] : "" : ""} fullWidth multiline rows={2} />}
                        </Box>
                    ) )}
                    {!feedbackQuestions && <Typography color="textSecondary" display="flex" alignItems="center" gap="5px" variant='subtitle2'> <CircularProgress size={14} /> Loading question... </Typography>}
                    <Box>
                        <Typography gutterBottom variant='subtitle2'>Anything you want to convey?</Typography>
                        <TextField onChange={( e ) => { setFeedbackData( { ...feedbackData, default: e.target.value } ) }} fullWidth multiline rows={2} />
                    </Box>
                    <Button disabled={isSubmittingFeedback} onClick={submitFeedback} sx={{ textTransform: "capitalize", marginTop: "20px" }} variant='contained'>{isSubmittingFeedback ? "Submitting your response" : "Submit feedback"}</Button>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: "capitalize" }} onClick={() => { setFeedbackQuestions( null ); setFeedbackData( { default: "" } ); setFeedbackDialogOpen( false ); setSelectedRoom( null ) }} color="error">Close</Button>
                </DialogActions>
            </Dialog>
            <Paper>
                <Box borderBottom="1px solid #d3d3d3" padding="20px">
                    <Typography variant='h5'>Feedback</Typography>
                    <Typography color="textSecondary" variant='subtitle2'>Provide feedbacks for the live classes</Typography>
                </Box>
                {sessions.length > 0 && !isLoading && <Box padding="20px">
                    <Typography variant='h6'>Classes attended</Typography>
                    <TableContainer sx={{ borderRadius: "5px", maxHeight: "450px" }}>
                        <Table sx={{ minWidth: "650px" }} stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Course Details</TableCell>
                                    <TableCell align="right">Branch</TableCell>
                                    <TableCell align="right">Presenter</TableCell>
                                    <TableCell align="right">Subject</TableCell>
                                    <TableCell align="right">Session Time</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sessions.map( ( row, i ) => (
                                    <TableRow key={'row_' + i}>
                                        <TableCell scope="row">
                                            Course : {row.room_session_data["course_short_name"]} <br></br>
                                            <small>

                                                Semester {row.room_session_data["current_sem"]} -
                                                Section {row.room_session_data["section_short_name"]}</small>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px" }} align="right">{row.room_session_data["branch_name"]}</TableCell>
                                        <TableCell sx={{ fontSize: "12px" }} align="right">{row.room_session_data["presenter_name"] ? row.room_session_data["presenter_name"] : "Not Available"}</TableCell>
                                        <TableCell sx={{ fontSize: "12px" }} align="right">{row.room_session_data["subject_name_short"]}</TableCell>
                                        <TableCell sx={{ fontSize: "12px" }} align="right">{row.room_session_data["interval"]}</TableCell>
                                        <TableCell sx={{
                                            fontSize: "12px"
                                        }} align="right" >
                                            {!studentFeedbacks[row.room_session_id] ? <Button variant="contained" color="primary"
                                                onClick={( e ) => getFeedbackQuestions( row )}
                                            >
                                                Give feedback
                                            </Button> : <Button disabled color="primary">
                                                Submitted
                                            </Button>}
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {sessions.length === 0 && !isLoading && <Box padding="20px" flexDirection="column" bgcolor={palette.contentBg} display="flex" alignItems="center" justifyContent="center" gap="10px">
                    <img width="250px" src={noClassessImg} alt="No sessions attended" />
                    <Typography variant='subtitle2' color="textSecondary">No session attended in last two months!</Typography>
                </Box>}
                {isLoading && <DataLoadingSpinner waitingMessage="Loading sessions..." />}
            </Paper >
        </Box >
    )
}

export default SessionFeedback
