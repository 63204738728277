import AttemptQuestion from '../../../components/exams-components/quiz-questionbank/AttemptQuestion'
import { Box, Button, Dialog, Paper, Typography, useTheme, IconButton } from '@mui/material'
import quizSubmitSuccessfulImg from '../../../assets/illustrations/quiz-completed.svg'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import React, { useEffect, useState } from 'react'
import { Icons, secondsToHMS } from '../../../utils/utilities'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react'
import api from '../../../service/api'


const QuizAttempt = observer( () => {

    const [loading, setLoading] = useState( true )
    const [viewScoresMode, setViewScoresMode] = useState( false )
    const [submittingAnswers, setSubmittingAnswers] = useState( false )
    const [scoresDetails, setScoresDetails] = useState( {} )
    const [timerValue, setTimerValue] = useState( '' )
    const [remainingTime, setRemainingTime] = useState( null )
    const [hasAlreadyTakenTheQuiz, setHasAlreadyTakenTheQuiz] = useState( false )
    const [selectedQuestionDetails, setSelectedQuestionDetails] = useState( null )

    const { palette } = useTheme()

    const { quiz_id, instance_id, attempt_id } = useParams()

    const navigate = useNavigate()

    const QuizStore = useQuizAndQuestionbankStore()

    const setQuestion = ( index ) => {
        setSelectedQuestionDetails( { index, id: QuizStore.getQuizAttemptQuestions[index]?.id } )
    }

    const resetCurrentQuestion = () => {
        QuizStore.setAttemptingAnswers( { ...QuizStore.getAttemptingAnswers, [selectedQuestionDetails?.id]: undefined } )
    }

    const goToNextQuestion = () => {
        const { index: currentIndex } = selectedQuestionDetails
        if ( currentIndex + 1 >= QuizStore.getQuizAttemptQuestions?.length )
            setQuestion( 0 )
        else setQuestion( currentIndex + 1 )
    }

    const fetchScores = async () => {
        try {
            const { data: { data } } = await api.quiz.fetchAttemptScore( quiz_id, instance_id, attempt_id )
            setScoresDetails( data )
            console.log( data )
            setViewScoresMode( true )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong whie fetching scores!" )
        }
    }

    const submitAnswers = async () => {
        setSubmittingAnswers( true )
        const answeredQuestions = Object.keys( QuizStore.getAttemptingAnswers )
        if ( answeredQuestions.length === QuizStore.getQuizAttemptQuestions.length ) {
            const multipleSelectionQuestions = QuizStore.getQuizAttemptQuestions.filter( q => q.selection_type === 'multiple' )
            let hasAnsweredAll = true
            for ( let i = 0; i < multipleSelectionQuestions.length; i++ ) {
                const ques = multipleSelectionQuestions[i]
                const correctAnswer = QuizStore.getAttemptingAnswers[ques.id]?.filter( a => a === true ).length
                if ( correctAnswer === 0 ) {
                    hasAnsweredAll = false
                    break
                }
            }
            if ( hasAnsweredAll ) {
                if ( await QuizStore.submitQuizAnswers( quiz_id, instance_id, attempt_id ) ) {
                    setHasAlreadyTakenTheQuiz( true )
                    // navigate( `/student/quiz/${quiz_id}/${instance_id}` )
                } else
                    toast( "Couldn't submit your answers! something went wrong" )
            } else
                toast( "Please answer all the questions" )
        } else {
            toast( "Please answer all the questions" )
        }
        setSubmittingAnswers( false )
    }

    useEffect( () => {
        const getData = async () => {
            const { success, message } = await QuizStore.fetchQuizInstanceForStudent( quiz_id, instance_id )
            if ( success ) {
                if ( +QuizStore.getQuizInstance?.attempted > 0 )
                    setHasAlreadyTakenTheQuiz( true )
                else {
                    const { success, message } = await QuizStore.fetchQuizQuestionsForAttempt( quiz_id, instance_id, attempt_id )
                    if ( success ) {
                        setSelectedQuestionDetails( { index: 0, id: QuizStore.getQuizAttemptQuestions[0]?.id } )
                        try {
                            if ( QuizStore.getQuizInstance?.quiz_duration ) {
                                let duration = QuizStore.getQuizInstance?.quiz_duration?.split( ":" )
                                let timeRemainingInSec = ( +duration[0] * 60 * 60 ) + ( +duration[1] * 60 )
                                setRemainingTime( localStorage.getItem( `quizinstance-${instance_id}-timeleft` ) || timeRemainingInSec )
                            }
                        } catch ( err ) {
                            toast( "Error loading quiz initial data!" )
                            navigate( '/student/home' )
                        }
                    } else {
                        toast( message )
                        navigate( `/student/home` )
                    }
                }
            }
            else {
                toast( message )
                navigate( `/student/home` )
            }
            setLoading( false )
        }
        getData()

    }, [QuizStore, quiz_id, instance_id, attempt_id, navigate] )

    useEffect( () => {
        if ( +remainingTime > 0 ) {
            let interval = setInterval( () => {
                setRemainingTime( p => p - 1 )
                localStorage.setItem( `quizinstance-${instance_id}-timeleft`, remainingTime )
            }, 1000 )

            return () => {
                if ( interval )
                    return clearInterval( interval )
            }
        }
    } )

    useEffect( () => {

        const submit = async () => {
            let success = false
            let retries = 0
            while ( !success ) {
                success = await QuizStore.submitQuizAnswers( quiz_id, instance_id, attempt_id )
                retries += 1
                if ( retries > 5 ) {
                    toast( "Error while submitting quiz!" )
                    return
                }
            }
            if ( success ) {
                setHasAlreadyTakenTheQuiz( true )
                localStorage.removeItem( `quizinstance-${instance_id}-timeleft` )
            }
        }

        if ( remainingTime !== null && +remainingTime >= 0 ) {
            if ( remainingTime <= 0 ) {
                toast( "Quiz timeout!" )
                submit()
            } else {
                const remVal = secondsToHMS( remainingTime )
                setTimerValue( remVal )
            }
        }

    }, [remainingTime, navigate, QuizStore, quiz_id, instance_id, attempt_id] )

    return (
        <Box padding="20px" display="flex" flexDirection="column" flexGrow={1} overflow="auto">

            {!hasAlreadyTakenTheQuiz && !loading && <Paper sx={{ flexGrow: 1, display: "flex", flexDirection: "column", overflow: "auto" }}>
                <Box display="flex" justifyContent="space-between" gap="20px" flexWrap="wrap" alignItems="center">
                    <Typography variant="h6" padding="20px" color="primary" fontWeight="700">Quiz #{QuizStore.getQuizInstance?.id}</Typography>
                    <Typography variant="h6" padding="20px" fontSize="18px" color="primary" fontWeight="500">{timerValue}</Typography>
                </Box>
                {<Box display="flex" flexGrow={1} overflow="auto">
                    <Box className='slim-custom-scrollbar' borderRight="2px solid #d3d3d3" display="flex" flexGrow={1} flexDirection="column" overflow="auto">
                        {QuizStore.getQuizInstance && <Box display="flex" flexDirection="column" gap='20px' padding="20px" paddingTop="0">
                            <Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="stretch" overflow="hidden" gap="20px" border={`1px solid ${palette.primary.dark}`} borderRadius="5px">
                                <Typography variant="h6" color="primary" padding="10px 20px">{QuizStore.getQuizInstance.title}</Typography>
                                <Box display="flex" justifyContent="flex-end" alignItems="center" flexGrow={1} gap="20px">
                                    <Typography>
                                        {QuizStore.getAnsweredCount} / {QuizStore.getQuizAttemptQuestions?.length}
                                    </Typography>
                                    <Box overflow="hidden" boxShadow="inset 1px 0px 5px 1px #DDD9D9" bgcolor="#DDD9D9" borderRadius="10px" height="10px" width="35%">
                                        <Box height="100%" sx={{ transition: "200ms" }} bgcolor={palette.success.main} width={`${QuizStore.getAnsweredCount * 100 / QuizStore.getQuizAttemptQuestions?.length}%`}></Box>
                                    </Box>
                                    <Button onClick={submitAnswers} disabled={submittingAnswers} variant='contained' sx={{ borderRadius: "0", height: "100%" }} disableElevation >
                                        Submit Answers
                                    </Button>
                                </Box>
                            </Box>
                        </Box>}
                        <Box paddingRight="20px" flexGrow={1} display="flex" flexDirection="column" overflow="auto" >
                            {selectedQuestionDetails && QuizStore.getQuizAttemptQuestions?.length > 0 && <Box className='slim-custom-scrollbar' flexGrow={1} overflow="auto" padding="20px" display="flex" flexDirection="column" gap="20px">
                                <Typography variant="body1" fontSize="14px" marginTop="-10px" marginBottom="10px" >Question {( selectedQuestionDetails?.index || 0 ) + 1}</Typography>
                                <AttemptQuestion goToNextQuestion={goToNextQuestion} resetCurrentQuestion={resetCurrentQuestion} key={selectedQuestionDetails?.id} index={selectedQuestionDetails?.index} question={QuizStore.getQuizAttemptQuestions[selectedQuestionDetails?.index]} />
                            </Box>}
                        </Box>
                    </Box>
                    {/* {QuizStore.getQuizAttemptQuestions?.length > 0 && <Box>
                        <Box bgcolor={palette.grey[400]} width="3px" height="400px" ></Box>
                    </Box>} */}
                    {QuizStore.getQuizAttemptQuestions?.length > 0 && <Box padding="20px" minWidth="250px" maxWidth="260px" display="flex" flexDirection="column" gap="20px">
                        <Typography variant="h6">Questions</Typography>
                        <Box display="flex" flexWrap="wrap" gap="20px" alignItems="center">
                            <Typography
                                paddingLeft="20px"
                                position="relative"
                                sx={{
                                    '&::before': {
                                        content: `''`,
                                        position: "absolute",
                                        left: "0",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        height: "10px",
                                        width: "10px",
                                        background: palette.success.main,
                                        borderRadius: "2px",
                                    }
                                }}
                                variant='subtitle1'
                            >
                                Answered
                            </Typography>
                            <Typography
                                paddingLeft="20px"
                                position="relative"
                                sx={{
                                    '&::before': {
                                        content: `''`,
                                        position: "absolute",
                                        left: "0",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        height: "10px",
                                        width: "10px",
                                        background: palette.primary.main,
                                        borderRadius: "2px",
                                    }
                                }}
                                variant='subtitle1'
                            >
                                Selected
                            </Typography>
                        </Box>
                        <Box display="flex" flexWrap="wrap" gap="20px">
                            {QuizStore.getQuizAttemptQuestions?.map( ( ques, index ) => {

                                const isSelected = selectedQuestionDetails?.index === index
                                let isAnswered = false

                                if ( ques.selection_type === 'single' && QuizStore.getAttemptingAnswers[ques.id] )
                                    isAnswered = true
                                else if ( ques.selection_type !== 'single' && QuizStore.getAttemptingAnswers[ques.id] && QuizStore.getAttemptingAnswers[ques.id]?.length > 0 )
                                    isAnswered = true

                                return (
                                    <Box
                                        onClick={
                                            () => setQuestion( index )
                                        }
                                        bgcolor={isAnswered ? palette.success.main : isSelected ? palette.primary.light : "none"}
                                        sx={{
                                            cursor: "pointer",
                                            '&:hover': {
                                                background: !isAnswered && !isSelected && palette.rowHover
                                            }
                                        }}
                                        color={( isSelected || isAnswered ) ? "white" : palette.defaultColor}
                                        key={index}
                                        width="30px"
                                        height="30px"
                                        border={`1px solid ${isAnswered ? palette.success.main : isSelected ? palette.primary.light : "#d3d3d3"}`}
                                        borderRadius="5px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography variant='h6' fontSize="16px">{index + 1}</Typography>
                                    </Box>
                                )
                            } )}
                        </Box>
                    </Box>}
                </Box>}

                {/* {hasAlreadyTakenTheQuiz && <BlockMessage
                    actions={[{ actionIcon: Icons.default.AnalyticsIconOutlined, action: () => navigate( `/student/quiz/${quiz_id}/${instance_id}` ), label: "Check your scores" }]}
                    message='You have already submitted your answers for this quiz!' />} */}


            </Paper>}
            {loading && <DataLoadingSpinner waitingMessage="Loading quiz details..." />}

            {!loading && <Dialog PaperProps={{ className: 'slim-custom-scrollbar', sx: { width: "60%", maxWidth: "600px" } }} open={( hasAlreadyTakenTheQuiz || ( viewScoresMode && hasAlreadyTakenTheQuiz ) )}>
                {!viewScoresMode && <Box bgcolor="#FBCF58" justifyContent="center" flexGrow={1} alignItems="center" display="flex" flexDirection="column" gap="20px" padding="50px">
                    <img width="70%" src={quizSubmitSuccessfulImg} alt="Submitted successfully" />
                    <Box alignItems="center" display="flex" flexDirection="column">
                        <Typography gutterBottom variant="h5">Congratulation!</Typography>
                        <Typography variant="body2">You have successfully completed the quiz</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="20px" flexWrap="wrap">
                        <Button onClick={fetchScores} sx={{ paddingX: "40px", fontSize: "12px", fontWeight: "bolder", marginTop: "20px", background: 'white', borderColor: "white", '&:hover': { background: "white", borderColor: "white" } }} variant='outlined' color='primary' >View Score</Button>
                        <Button onClick={() => navigate( '/student/home' )} sx={{ paddingX: "40px", fontSize: "12px", fontWeight: "bolder", marginTop: "20px", background: 'white', borderColor: "white", '&:hover': { background: "white", borderColor: "white" } }} variant='outlined' color='primary' >Back to home</Button>
                    </Box>
                </Box>}
                {viewScoresMode && <Box bgcolor={palette.background.paper} flexGrow={1} display="flex" flexDirection="column">
                    <Box position="sticky" top="0" display="flex" alignItems="center" justifyContent="space-between" padding="20px" color={palette.primaryDark.contrastText} bgcolor={palette.primaryDark.main}>
                        <Typography variant="h6">{QuizStore.getQuizInstance.title}</Typography>
                        <IconButton sx={{ color: "white" }} size="small" onClick={() => setViewScoresMode( false )}>
                            {Icons.default.CancelIcon}
                        </IconButton>
                    </Box>
                    <Box padding="20px">
                        <Box display="flex" alignItems="center" gap="30px">
                            <Typography variant="h6">OVERALL SCORE</Typography>
                            <Typography variant="h6"> <strong>{scoresDetails?.total_score}/{scoresDetails?.max_score}</strong></Typography>
                        </Box>
                        <Box display="flex" marginTop="30px" flexDirection="column" gap="20px">
                            {Object.keys( scoresDetails?.scores || {} ).map( ( quesId, index ) => {
                                const ques = scoresDetails?.scores[quesId]
                                return <Box key={index}>
                                    <Box color={palette.contrstText} className='questionbank-wysiwyg-container' fontWeight="bold" marginTop="-5px" fontSize="14px" dangerouslySetInnerHTML={{ __html: ques.question_text }}></Box>
                                    <Typography variant='body2' color="textSecondary"><em>{ques.selection_type}</em></Typography>
                                    <Box marginTop="10px" display="flex" flexDirection="column" gap="10px">
                                        {ques.options.map( ( option, optionIndex ) => {
                                            const isCorrect = ques.selection_type === "single" ? +ques.correct_answer === optionIndex : ques.options_status[optionIndex]
                                            const hasAnswered = ques.selection_type === "single" ? +ques.given === optionIndex : ques.given.includes( optionIndex )
                                            return (
                                                <Box padding="10px" borderRadius="5px" bgcolor={
                                                    isCorrect ? "success.light" : hasAnswered ? "error.light" : "none"} display="flex" marginBottom="2px" alignItems="flex-start" gap="10px" key={optionIndex}>
                                                    <Typography padding="2px 2px 0px 2px" variant="subtitle2" fontSize="12px" sx={{ aspectRatio: 1 / 1 }} bgcolor={palette.greyedOut} border={`1px solid ${palette.borderColor}`} display="flex" alignItems="center" justifyContent="center" width='20px' borderRadius="50%"
                                                        fontWeight={( isCorrect || hasAnswered ) ? "bolder" : "regular"} color={isCorrect ? "success" : hasAnswered ? "error" : palette.contrastText}>{optionIndex + 1}</Typography>
                                                    <Box variant="subtitle2" className='questionbank-wysiwyg-container' color={( isCorrect || hasAnswered ) ? "white" : palette.contrastText} dangerouslySetInnerHTML={{ __html: option.option_text }}></Box>
                                                </Box>
                                            )
                                        } )}
                                    </Box>
                                </Box>
                            } )}
                        </Box>
                    </Box>
                </Box>}
            </Dialog>}
        </Box>
    )
} )

export default QuizAttempt
