import { Box, Typography, Paper, Chip } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { convertToCDNLink, defaultOnlineCourseThumbs } from '../../utils/utilities'
import { useUserStore } from '../../store/user.store'

const defaultHoverCssRules = {
    width: "350px",
    height: "fit-content",
    display: "block",
}

const OnlineCourseCard = ( { onlineCourse } ) => {

    const [hoverCssRules, setHoverCssRules] = useState( defaultHoverCssRules )
    const UserStore = useUserStore()
    const navigate = useNavigate()

    const handleHover = ( e ) => {
        if ( window.innerWidth < 501 ) {
            setHoverCssRules( { display: "none" } )
        } else {
            if ( e.target.getBoundingClientRect().left > window.innerWidth / 2 ) {
                if ( ( window.innerHeight - e.target.getBoundingClientRect().bottom ) < 180 )
                    setHoverCssRules( { ...defaultHoverCssRules, right: "100%", top: "initial", bottom: "0" } )
                else
                    setHoverCssRules( { ...defaultHoverCssRules, right: "100%" } )
            } else {
                if ( ( window.innerHeight - e.target.getBoundingClientRect().bottom ) < 180 )
                    setHoverCssRules( { ...defaultHoverCssRules, left: "100%", top: "initial", bottom: "0" } )
                else
                    setHoverCssRules( { ...defaultHoverCssRules, left: "100%" } )
            }
        }
    }

    const navigateToCourse = () => {
        let role
        if ( ['admin', 'subadmin'].includes( UserStore.getUser['user_role'].toLowerCase() ) ) role = 'admin'
        else if ( ['student'].includes( UserStore.getUser['user_role'].toLowerCase() ) ) role = 'student'
        else if ( ['presenter', 'faculty', 'hod', 'principal'].includes( UserStore.getUser['user_role'].toLowerCase() ) ) role = 'faculty'
        navigate( `/${role}/online-course/${onlineCourse.id}` )
    }


    return (
        <Box maxWidth={{ sm: "220px" }} width={{ sm: "220px", xs: "100%" }} onClick={navigateToCourse} onMouseEnter={handleHover} position="relative" sx={{ cursor: "pointer", "&:hover .hidden": hoverCssRules }}>
            <Box>
                <Box border="1px solid rgba(0,0,0,0.4)" borderBottom="none" height="100px" width="auto">
                    <img src={onlineCourse?.thumbnail_uri.includes( 'http' ) ? convertToCDNLink( onlineCourse?.thumbnail_uri ) : defaultOnlineCourseThumbs[parseInt( onlineCourse?.thumbnail_uri )]} style={{ objectFit: "cover" }} width="100%" height="100%" loading='lazy' alt={onlineCourse.title} />
                </Box>
                <Box padding="10px" border="1px solid rgba(0,0,0,0.4)" borderTop="none">
                    <Typography fontWeight="600" fontSize="16px" lineHeight="1" sx={{ "WebkitLineClamp": 2, "WebkitBoxOrient": "vertical", display: "-webkit-box !important" }} gutterBottom overflow="hidden" textOverflow="ellipsis" whiteSpace="normal">{onlineCourse.title}</Typography>
                    <Typography color="textSecondary" variant='subtitle2' textTransform="capitalize" >{onlineCourse.author}</Typography>
                    <Typography color="textSecondary" variant='subtitle2' >Published On: {new Date( onlineCourse.created_at ).toLocaleDateString( 'IN-en' ).replace( /\//g, "-" )}</Typography>
                </Box>
            </Box>
            <Box className="hidden" sx={{ position: "absolute", zIndex: "200", width: "0", top: "0", height: "0", overflow: "hidden" }} >
                <Box padding="10px">
                    <Paper elevation={2} sx={{ padding: "20px", maxWidth: "400px", borderRadius: "3px", border: "1px solid rgba(0,0,0,0.2)" }}>
                        <Typography fontSize="18px" fontWeight="600">{onlineCourse.title}</Typography>
                        <Typography color="textSecondary" variant='subtitle2' >{onlineCourse.author}</Typography>
                        <Typography color="textSecondary" variant='subtitle2' >Published On: {new Date( onlineCourse.created_at ).toLocaleDateString( 'IN-en' ).replace( /\//g, "-" )}</Typography>
                        <Typography variant='body2' >{onlineCourse.description.length > 150 ? `${onlineCourse.description.substring( 0, 150 )}...` : onlineCourse.description}</Typography>
                        <Box marginTop="10px" display="flex" alignItems="center" flexWrap="wrap" gap="5px">
                            {onlineCourse.key_word.split( "," ).map( ( keyword, i ) => (
                                <Chip key={`${i}-${keyword}`} label={keyword} size='small' />
                            ) )}
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Box>
    )
}

export default OnlineCourseCard
