import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useApiProviderStore } from '../../store/apiprovider.store'
import { useOrganizationStore } from '../../store/organization.store'
import { Icons } from '../../utils/utilities'
import { useUserStore } from '../../store/user.store'
import noApiProviderImg from '../../assets/no-apiprovider.svg'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import BlockMessage from '../../components/common/BlockMessage'

const ApiSettings = () => {
    const [open, setOpen] = React.useState( false )
    const [apiId, setApiId] = useState( "" )
    const [apiProviderName, setApiProviderName] = useState( "" )
    const [apiEndpoint, setApiEndpoint] = useState( "" )
    const [apiSecret, setApiSecret] = useState( "" )
    const [apiOrgCodes, setApiOrgCodes] = useState( [] )
    const [institutioncode, setInstitutionCode] = React.useState( '' )
    const [apiUserThreshold, setApiUserThreshold] = React.useState( 0 )
    const [action, setAction] = useState( "create" )
    const [hasPermission, setHasPermission] = useState( true )
    const [isLoading, setIsLoading] = useState( true )
    const { palette } = useTheme()

    const ApiProviderStore = useApiProviderStore()
    const OrganizationStore = useOrganizationStore()
    const UserStore = useUserStore()

    const handleClickOpen = () => {
        setOpen( true )
    }

    const handleClose = () => {

        setOpen( false )
    }

    useEffect( () => {
        //props.ApiProviderStore.fetchApiProviders()
        //setApiprovider(props.ApiProviderStore.getApiProvider)
        const getData = async () => {
            await ApiProviderStore.fetchApiProviders()
            await OrganizationStore.fetchOrganization()
            setIsLoading( false )
        }
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.api_settings ) {
            setHasPermission( false )
        } else
            getData()
    }, [ApiProviderStore, OrganizationStore, UserStore] )

    const createApiProvider = () => {
        let payload = {
            "api_provider": apiProviderName,
            "api_endpoint": apiEndpoint,
            "api_secret": apiSecret,
            "api_org_codes": apiOrgCodes,
            "api_user_threshold": apiUserThreshold
        }
        ApiProviderStore.createApiProvider( payload )
    }

    const updateApiProvider = () => {
        let payload = {
            "api_provider": apiProviderName,
            "api_endpoint": apiEndpoint,
            "api_secret": apiSecret,
            "api_org_codes": apiOrgCodes,
            "api_user_threshold": apiUserThreshold
        }
        ApiProviderStore.updateApiProvider( payload, apiId )
    }

    const viewData = ( currentData ) => {
        setAction( "view" )
        setApiProviderName( currentData["api_provider"] )
        setApiEndpoint( currentData["api_endpoint"] )
        setApiSecret( currentData["api_secret"] )
        setApiOrgCodes( currentData["api_org_codes"] ? currentData["api_org_codes"] : [] )
        setApiUserThreshold( currentData["api_user_threshold"] )
        handleClickOpen()
    }
    const editData = ( currentData ) => {
        setAction( "edit" )
        setApiProviderName( currentData["api_provider"] )
        setApiEndpoint( currentData["api_endpoint"] )
        setApiSecret( currentData["api_secret"] )
        setApiOrgCodes( currentData["api_org_codes"] ? currentData["api_org_codes"] : [] )
        setApiUserThreshold( currentData["api_user_threshold"] )
        setApiId( currentData["api_id"] )
        handleClickOpen()
    }

    const createData = () => {
        setAction( "create" )
        setApiProviderName( "" )
        setApiEndpoint( "" )
        setApiSecret( "" )
        setApiUserThreshold( "" )
        setApiOrgCodes( [] )
        handleClickOpen()
    }

    const deleteData = ( api_id ) => {
        ApiProviderStore.deleteApiProvider( api_id )
    }

    const chipDelete = ( k ) => {
        let tempChipArray = apiOrgCodes
        tempChipArray.splice( k, 1 )
        setApiOrgCodes( [...tempChipArray] )
    }

    const addChip = ( e ) => {
        e.preventDefault()
        let tempChipArray = apiOrgCodes
        tempChipArray.push( institutioncode )
        setApiOrgCodes( [...tempChipArray] )
    }
    return (
        hasPermission ? <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column" padding="20px">
            <Paper sx={{ minHeight: !isLoading && "350px", flexGrow: ( isLoading || ( ApiProviderStore.getApiProvider.length === 0 && !isLoading ) ) ? "initial" : "1", display: "flex", flexDirection: "column" }}>
                <Box display="flex" alignItems="center" flexDirection={{ xs: "column", md: "row" }} justifyContent={{ xs: "flex-start", md: "space-between" }} padding="20px" borderBottom="1px solid #d3d3d3">
                    <Box>
                        <Typography variant='h5'>API settings</Typography>
                        <Typography color="textSecondary" variant='subtitle2'>Manage your third party API's</Typography>
                    </Box>
                    <Button sx={{ textTransform: "capitalize" }} variant="contained" onClick={createData} startIcon={Icons.default.AddIcon}>Create</Button>
                </Box>
                {ApiProviderStore.getApiProvider.length > 0 && !isLoading && <Box overflow="hidden">
                    <TableContainer className='custom-scrollbar' sx={{ position: "relative", height: "100%", overflow: "auto" }} >
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ position: "sticky", top: "0", zIndex: "10" }}>
                                    <TableCell sx={{ background: palette.primary.main, paddingY: "10px", color: palette.primary.contrastText, minWidth: "100px" }}>API Name</TableCell>
                                    <TableCell sx={{ background: palette.primary.main, paddingY: "10px", color: palette.primary.contrastText, minWidth: "200px" }}>Institute</TableCell>
                                    <TableCell sx={{ background: palette.primary.main, paddingY: "10px", color: palette.primary.contrastText, minWidth: "100px" }} align="left">End Point</TableCell>
                                    <TableCell sx={{ background: palette.primary.main, paddingY: "10px", color: palette.primary.contrastText, minWidth: "100px" }} align="left">API Secret</TableCell>
                                    <TableCell sx={{ background: palette.primary.main, paddingY: "10px", color: palette.primary.contrastText, minWidth: "40px" }} align="left">Threshold</TableCell>
                                    <TableCell sx={{ background: palette.primary.main, paddingY: "10px", color: palette.primary.contrastText, minWidth: "120px" }} align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ position: "relative", zIndex: "5" }}>
                                {ApiProviderStore.getApiProvider.map( ( row, k ) => (
                                    <TableRow sx={{ paddingY: "10px", "&:hover": { background: palette.rowHover } }} key={'key-' + k}>
                                        <TableCell component="th" scope="row">
                                            {row.api_provider}
                                        </TableCell>
                                        <TableCell align="left">
                                            {
                                                row.api_org_codes ?
                                                    row.api_org_codes.map( ( item, k ) => {
                                                        return <Chip sx={{ margin: "0 5px 5px 0" }} key={'k-' + k}
                                                            label={item}
                                                            className="mr-3"
                                                        />
                                                    } ) : 'No Institution Allocated'
                                            }
                                        </TableCell>
                                        <TableCell align="left">{row.api_endpoint}</TableCell>
                                        <TableCell align="left">xxxxxxxxxxxxxxxxxx</TableCell>
                                        <TableCell align="left">{row.api_user_threshold}</TableCell>
                                        <TableCell align="right">


                                            <IconButton color="primary" aria-label="Edit" onClick={() => editData( row )}>
                                                {Icons.default.RateReviewIcon}
                                            </IconButton>

                                            <IconButton aria-label="View" onClick={() => viewData( row )}>
                                                {Icons.default.VisibilityIcon}
                                            </IconButton>

                                            <IconButton color="secondary" aria-label="delete" onClick={() => deleteData( row.api_id )} >
                                                {Icons.default.DeleteIcon}
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {ApiProviderStore.getApiProvider.length === 0 && !isLoading && <Box flexGrow={1} justifyContent="center" display="flex" flexDirection="column" gap="20px" bgcolor="#eee" padding="20px">
                    <img src={noApiProviderImg} alt="No Api Provider" />
                    <Typography variant="subtitle2" color="textSecondary">No API providers</Typography>
                </Box>}
                {isLoading &&
                    <DataLoadingSpinner waitingMessage="Loading API providers..." />
                }
            </Paper>

            {/* DIALOG */}
            <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "450px" } }} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }} >{
                    action === "create" ? `Add a new API service` : `Update ${apiProviderName} API service`
                } </DialogTitle>
                <DialogContent >
                    <form style={{ marginTop: "20px", display: "flex", gap: "20px", flexDirection: "column" }} noValidate autoComplete="off">

                        <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} className="mb-4" id="outlined-basic" onChange={( e ) => setApiProviderName( e.target.value )}
                            label="API Name" variant="outlined" value={apiProviderName} disabled={( action === "view" ) ? true : false} />

                        <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} className="mb-4" id="outlined-basic" onChange={( e ) => setApiEndpoint( e.target.value )}
                            label="API Endpoint" variant="outlined" value={apiEndpoint} disabled={( action === "view" ) ? true : false} />

                        <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} className="mb-4" id="outlined-basic" onChange={( e ) => setApiSecret( e.target.value )}
                            label="API Secret" variant="outlined" value={apiSecret} disabled={( action === "view" ) ? true : false} />

                        <TextField InputLabelProps={{ sx: { fontSize: "14px" } }} className="mb-4" id="outlined-basic" onChange={( e ) => setApiUserThreshold( e.target.value )}
                            label="API Threshold" variant="outlined" value={apiUserThreshold} disabled={( action === "view" ) ? true : false} />

                        <FormControl variant="outlined" className="mb-4">
                            <InputLabel id="institutioncode-select-outlined-label">Allocated Institution</InputLabel>
                            <Select
                                sx={{ fontSize: "14px" }}
                                labelId="institutioncode-select-outlined-label"
                                id="institutioncode-select-outlined"
                                value={institutioncode}
                                onChange={( e ) => { setInstitutionCode( e.target.value ) }}
                                label="Allocated Institution">
                                <MenuItem sx={{ fontSize: "14px" }} value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    OrganizationStore.getOrganization.filter( ( itm, i ) => {
                                        return itm.departments !== null
                                    } ).map( ( row, k ) => {
                                        return <MenuItem sx={{ fontSize: "14px" }} value={row.institute_name_short} key={k}>{row.institute_name}</MenuItem>
                                    } )
                                }

                            </Select>
                        </FormControl>
                        <Button type="button" disableElevation variant='contained' onClick={addChip}>Add</Button>
                        <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
                            {
                                apiOrgCodes.map( ( item, k ) => {
                                    return <Chip key={'k-' + k}
                                        //icon={<FaceIcon />}
                                        label={item}
                                        className="mr-3"
                                        onDelete={() => chipDelete( k )}
                                        color="secondary"
                                    />
                                } )
                            }
                        </Box>

                    </form>

                </DialogContent>
                <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap" padding="20px">
                    {
                        ( action === "create" ) ?
                            <Button variant="contained" disableElevation sx={{ textTransform: "capitalize" }} startIcon={Icons.default.AddIcon} onClick={createApiProvider} color="primary">
                                Create
                            </Button> : ""
                    }
                    {
                        ( action === "edit" ) ?
                            <Button variant="contained" disableElevation sx={{ textTransform: "capitalize" }} startIcon={Icons.default.EditIcon} onClick={updateApiProvider} color="primary">
                                Update
                            </Button> : ""
                    }
                    <Button sx={{ textTransform: "capitalize" }} startIcon={Icons.default.CloseIcon} variant="outlined" onClick={handleClose} color="error">
                        Cancel
                    </Button>
                </Box>
            </Dialog>
        </Box> :
            <Box padding="20px">
                <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
            </Box>
    )
}

export default ApiSettings
