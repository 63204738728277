import { Search } from '@mui/icons-material'
import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import OnlineCourseCard from '../../../components/online-course-components/OnlineCourseCard'
import { useOnlineCourseStore } from '../../../store/onlinecourse.store'
import { useEffect } from 'react'
import noCourses from '../../../assets/no-courses.svg'
import { useSearchParams } from 'react-router-dom'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'

const StudentOnlineCourses = () => {

    const [isLoading, setIsLoading] = useState( true )

    const [searchParams, setSearchParams] = useSearchParams( { searchText: "" } )
    const searchText = searchParams.get( "searchText" )

    const OnlineCourseStore = useOnlineCourseStore()

    useEffect( () => {
        const getData = async () => {
            await OnlineCourseStore.fetchCourses()
            setIsLoading( false )
        }
        getData()
    }, [OnlineCourseStore] )

    return (
        <Box padding="20px" flexGrow={1}>
            <Paper>
                <Box>
                    <Box padding="20px" display="flex" borderBottom="1px solid #d3d3d3" gap="20px" alignItems={{ md: "center", xs: "flex-start" }} flexDirection={{ md: "row", xs: "column" }} justifyContent="space-between">
                        <Typography variant="h5" width="fit-content" fontWeight="normal">Online Courses</Typography>
                        <TextField name='alivevideosearch' autoComplete='off' value={searchText} onChange={( e ) => setSearchParams( prev => { prev.set( "searchText", e.target.value ); return prev }, { replace: true } )} InputProps={{ disableUnderline: true, sx: { outline: "none !important", border: "none !important", height: "40px", fontSize: "14px", "@media(max-width:900px)": { width: "100%" } }, startAdornment: <Search sx={{ marginX: "10px" }} /> }} sx={{ border: "1px solid rgba(0,0,0,0.1)", background: "#eee", marginRight: "10px", outline: "none", borderRadius: "5px", height: "40px", color: "black", "@media(max-width:900px)": { width: "100%" } }} placeholder="Search by subjects..." variant="standard" type="text" />
                    </Box>
                    {isLoading && <DataLoadingSpinner waitingMessage="Loading courses..." />}
                    {!isLoading && OnlineCourseStore.getCourses.length > 0 && <Box>
                        <Box display="flex" flexWrap="wrap" padding="20px" gap="20px">
                            {OnlineCourseStore.getCourses.filter( c => c.title.toLowerCase().includes( searchText.toLowerCase() ) ).map( ( onlineCourse, k ) => (
                                <OnlineCourseCard key={k} onlineCourse={onlineCourse} />
                            ) )}
                        </Box>
                    </Box>}
                </Box>
                {!isLoading && ( OnlineCourseStore.getCourses.length === 0 || OnlineCourseStore.getCourses.filter( c => c.title.toLowerCase().includes( searchText.toLowerCase() ) ).length === 0 ) && <Box padding="20px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor="#eee">
                    <img width="200px" src={noCourses} alt="no-courses" />
                    <Typography marginY="10px" color="textSecondary" variant='subtitle2'>No online courses. please wait for uploads...</Typography>
                    {searchText !== "" && <Button onClick={() => setSearchParams( prev => { prev.set( "searchText", "" ); return prev }, { replace: true } )} color='secondary' variant='outlined' sx={{ textTransform: "capitalize" }}>Clear filter</Button>}
                </Box>}
            </Paper>
        </Box>
    )
}

export default StudentOnlineCourses
