import { Box, Button, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { useResumeTemplateEditStore } from '../../../store/resumetemplateEdit.store'
import { useState } from 'react'
import { Icons } from '../../../utils/utilities'

const ConfigureSelectedRow = ( { setTab } ) => {

    const TemplateStore = useResumeTemplateEditStore()
    const [gapValue, setGapValue] = useState( TemplateStore.getTemplate.rows[TemplateStore.getSelectedRowIndex].gap )

    const changeRowSettings = ( key, val ) => {
        const newTemplate = { ...TemplateStore.getTemplate }
        newTemplate.rows[TemplateStore.getSelectedRowIndex][key] = val
        TemplateStore.updateTemplate( newTemplate )
    }

    const handleRowGapChange = ( e ) => {
        let val = e.target.value
        val = val < 5 ? 5 : val
        changeRowSettings( "gap", val )
    }

    const deleteRow = ( rowIndex ) => {
        const newTemplate = { ...TemplateStore.getTemplate }
        newTemplate.rows.splice( rowIndex, 1 )
        TemplateStore.updateTemplate( newTemplate )
        TemplateStore.setSelectedRowIndex( null )
        setTab( "Home" )
    }

    return (
        <Box display="flex" flexDirection="column" gap="10px">
            <Typography color="secondary" padding="0 10px" variant="h6">
                Row {TemplateStore.getSelectedRowIndex + 1}
            </Typography>
            <Box padding="0 10px">
                <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Gap between columns</Typography>
                <TextField
                    onFocus={e => e.target.select()}
                    value={gapValue}
                    onChange={( e ) => { setGapValue( e.target.value ) }}
                    sx={{ fontSize: "14px" }} InputProps={{ sx: { fontSize: "14px" } }} fullWidth
                    size='small'
                    type="number"
                    onKeyDown={e => { if ( e.key === "Enter" ) handleRowGapChange( e ) }}
                    onBlur={e => handleRowGapChange( e )
                    }
                />
            </Box>
            <Box padding="0 10px">
                <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Alignment along row</Typography>
                <Select fullWidth sx={{ fontSize: "14px" }} size='small' onChange={e => changeRowSettings( "align", e.target.value )} value={TemplateStore.getTemplate.rows[TemplateStore.getSelectedRowIndex].align ? TemplateStore.getTemplate.rows[TemplateStore.getSelectedRowIndex].align : "stretch"}>
                    <MenuItem value={"stretch"} sx={{ fontSize: "14px" }}>Stretch</MenuItem>
                    <MenuItem value={"flex-start"} sx={{ fontSize: "14px" }}>Top</MenuItem>
                    <MenuItem value={"center"} sx={{ fontSize: "14px" }}>Center</MenuItem>
                    <MenuItem value={"flex-end"} sx={{ fontSize: "14px" }}>Bottom</MenuItem>
                </Select>
            </Box>
            <Box padding="0 10px">
                <Button onClick={() => deleteRow( TemplateStore.getSelectedRowIndex )} size='small' sx={{ textTransform: "capitalize" }} fullWidth color="error" variant='outlined' startIcon={Icons.default.RemoveCircleIcon}>Remove this row</Button>
            </Box>
        </Box>
    )
}

export default ConfigureSelectedRow
