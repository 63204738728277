import { Box, Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import YearChecklist from '../user/academics/YearChecklist'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../service/api'
import DataLoadingSpinner from '../common/DataLoadingSpinner'

const AuditStudentChecklistDetailsDialog = ( { student, state, setState, rules } ) => {

    const [loading, setLoading] = useState( true )
    const [data, setData] = useState( [] )
    const [title, setTitle] = useState( '' )

    useEffect( () => {
        const getData = async () => {
            try {
                const { data } = await api.fetchStudentAcademicRulesForAudit( student.auid, { rule_ids: rules.join( "," ) } )
                const studentData = data.data
                const structuredData = []
                for ( let i = 0; i < studentData.length; i++ ) {
                    const checklist = studentData[i]
                    if ( checklist.session_type === "year" ) {
                        if ( structuredData[checklist.session_number] ) {
                            structuredData[checklist.session_number].checklist.push( checklist )
                            if ( checklist.is_required )
                                structuredData[checklist.session_number].totalRequired += 1
                            if ( checklist.is_completed && checklist.is_required )
                                structuredData[checklist.session_number].totalCompleted += 1
                        } else {
                            structuredData[checklist.session_number] = { checklist: [checklist], semester: [], totalRequired: checklist.is_required ? 1 : 0, totalCompleted: checklist.is_completed && checklist.is_required ? 1 : 0 }
                        }
                    } else {
                        const year = Math.ceil( checklist.session_number / 2 )
                        if ( structuredData[year] ) {
                            if ( checklist.is_required )
                                structuredData[year].totalRequired += 1
                            if ( checklist.is_completed && checklist.is_required )
                                structuredData[year].totalCompleted += 1
                            if ( structuredData[year]['semester'][checklist.session_number] ) {
                                structuredData[year]['semester'][checklist.session_number].push( checklist )
                            } else {
                                structuredData[year]['semester'][checklist.session_number] = [checklist]
                            }
                        } else {
                            const temp = { checklist: [], semester: [], totalRequired: checklist.is_required ? 1 : 0, totalCompleted: checklist.is_completed && checklist.is_required ? 1 : 0 }
                            temp.semester[checklist.session_number] = [checklist]
                            structuredData[year] = temp
                        }
                    }
                }
                setData( structuredData )
            } catch ( err ) {
                console.log( err )
                toast( err?.response?.data?.message || "Something went wrong while fetching your data!" )
            } finally {
                setLoading( false )
            }
        }
        getData()
        if ( student.student_name.toLowerCase().charAt( student.student_name.length - 1 ) === "s" )
            setTitle( `${student.student_name}' progress report` )
        else
            setTitle( `${student.student_name}'s progress report` )

    }, [student, rules] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "750px" } }} open={state}>
            <DialogContent sx={{ padding: "0" }}>
                <Box borderBottom="1px solid #d3d3d3" padding="20px" gap="40px" display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" textTransform="capitalize">{title.toLowerCase()} </Typography>
                    <IconButton onClick={() => setState( false )} size="small">
                        {Icons.default.CloseIcon}
                    </IconButton>
                </Box>
                <Box>
                    {!loading && data?.length > 0 && <Box display="flex" flexDirection="column" gap="5px" padding="20px">
                        {data?.map( ( year, yearNumber ) => (
                            <YearChecklist key={yearNumber} year={year} yearNumber={yearNumber} />
                        ) )}
                    </Box>}
                    {!loading && data?.length === 0 && <Box display="flex" flexDirection="column" gap="5px" padding="20px">
                        <Typography variant="subtitle2" padding="50px 20px" textAlign="center">No checklist created for this rule.</Typography>
                    </Box>}
                    {loading && <DataLoadingSpinner waitingMessage="Loading data..." />}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AuditStudentChecklistDetailsDialog
