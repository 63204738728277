import { Box, FormControlLabel, InputLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'

const CgpaOrPercentageInput = ( { attr, value, attrkey, handleFieldChange } ) => {
    const [type, setType] = useState( "CGPA" )
    const [fieldValue, setFieldValue] = useState( value )

    const handleChange = ( e ) => {
        setFieldValue( e.target.value )
    }

    const handleBlur = e => {
        let val = e.target.value
        if ( type === "CGPA" ) {
            val = val > 10 ? 10 : val
            val = val < 0 ? 0 : val
            setFieldValue( val )
            handleFieldChange( "cgpaorpercentage", attrkey, `${val} CGPA` )
        } else {
            val = val > 100 ? 100 : val
            val = val < 0 ? 0 : val
            setFieldValue( val )
            handleFieldChange( "cgpaorpercentage", attrkey, `${val}%` )
        }
    }

    return (
        <Box width="100%">
            <InputLabel> <Typography variant="subtitle2" color="GrayText">{attr.entity_detail.label}</Typography> </InputLabel>
            <RadioGroup sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }} onChange={( e ) => { setType( e.target.value ); setFieldValue( 0 ) }} value={type}>
                <FormControlLabel value="CGPA" control={<Radio size='small' />} label="CGPA" />
                <FormControlLabel value="Percentage" control={<Radio size='small' />} label="Percentage" />
            </RadioGroup>
            <TextField value={fieldValue} size="small" onBlur={handleBlur} onChange={( e ) => handleChange( e )} variant='outlined' inputProps={{ step: "0.1" }} InputProps={{ step: "0.1", startAdornment: <Typography paddingRight="5px" fontSize="14px" color="GrayText">{type}:</Typography> }} type="number" fullWidth />
        </Box>
    )
}

export default CgpaOrPercentageInput
