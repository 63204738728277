import { Box, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Icons } from '../../../utils/utilities'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'
import api from '../../../service/api'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const ImportChecklistItemsDialog = ( { getItems, checklistItems, setState, state, allItems, uniqueItems, isLoadingUniqueChecklistItems } ) => {

    const [importing, setImporting] = useState( false )
    const [selectedItems, setSelectedItems] = useState( [] )
    const [unselectedItems, setUnselectedItems] = useState( [] )

    const { id } = useParams()

    const handleSelectionChange = ( e, itemIndex ) => {
        if ( e?.target?.checked ) {
            let updatedUnselectedList = [...unselectedItems]
            const selecteItem = updatedUnselectedList[itemIndex]
            updatedUnselectedList.splice( itemIndex, 1 )
            setSelectedItems( [...selectedItems, selecteItem] )
            setUnselectedItems( updatedUnselectedList )
        } else {
            let updatedSelectedList = [...selectedItems]
            const removingItem = updatedSelectedList[itemIndex]
            updatedSelectedList.splice( itemIndex, 1 )
            setSelectedItems( updatedSelectedList )
            setUnselectedItems( [removingItem, ...unselectedItems] )
        }
    }

    const importChecklist = async () => {
        try {
            setImporting( true )
            const { data } = await api.importChecklistItems( id, { items: selectedItems } )
            await getItems()
            toast( data?.message || `Successfully imported selected items to this rule` )
            setSelectedItems( [] )
            setUnselectedItems( [] )
            setState( false )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Error occured while importing checklist!" )
        } finally {
            setImporting( false )
        }
    }

    useEffect( () => {
        const getData = async () => {
            try {
                const existingItems = allItems.map( i => i.input_label )
                const selectableItems = uniqueItems.filter( i => !existingItems.includes( i.input_label ) )
                setUnselectedItems( selectableItems )
            } catch ( err ) {
                if ( err.response )
                    toast( err.response?.data?.message || "Error occured while fetching rules!" )
                else
                    toast( "Error occured while fetching rules!" )
            }
        }
        getData()
    }, [checklistItems, id, allItems, uniqueItems] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "600px" } }} open={state}>
            <DialogTitle sx={{ borderBottom: "1px solid #d3d3d3" }}>
                Select checklist items to import to this rule.
            </DialogTitle>
            <DialogContent sx={{ padding: "0" }}>
                {isLoadingUniqueChecklistItems && <Box display="flex" alignItems="center" justifyContent="center" minHeight="200px" >
                    <DataLoadingSpinner waitingMessage="Loading checklist items..." />
                </Box>}
                {!isLoadingUniqueChecklistItems && <Box minHeight="200px" >
                    {selectedItems.length > 0 && <Box padding="20px" display="flex" gap="10px" flexWrap="wrap" borderBottom="1px solid #d3d3d3">
                        {selectedItems.map( ( item, itemIndex ) => (
                            <Box key={itemIndex} display="flex" alignItems="center" >
                                <Chip sx={{ fontWeight: "500", border: "1px solid #d3d3d3" }} color='success' deleteIcon={Icons.default.CancelIcon} onDelete={( e ) => handleSelectionChange( e, itemIndex )} label={item.input_label} />
                            </Box>
                        ) )}
                    </Box>}
                    {unselectedItems.length > 0 && <Box padding="20px">
                        {unselectedItems.map( ( item, itemIndex ) => (
                            <Box display="flex" alignItems="center" key={itemIndex}>
                                <FormControlLabel
                                    label={<Typography variant='subtitle2'>{item.input_label}</Typography>}
                                    control={<Checkbox onChange={( e ) => handleSelectionChange( e, itemIndex )} size='small' checked={false} />} />
                            </Box>
                        ) )}
                    </Box>}
                    {unselectedItems.length === 0 && <Box display="flex" minHeight="200px" justifyContent="center" alignItems="center" height="100%">
                        <Typography variant='subtitle2' padding="20px" textAlign="center" color="GrayText">No more checklist item to import! all the importable items are already included in the rule.</Typography>
                    </Box>}
                </Box>}
            </DialogContent>
            <DialogActions>
                {selectedItems.length > 0 && <Button startIcon={importing ? <CircularProgress size={14} /> : Icons.default.DownloadForOfflineIcon} disabled={importing} onClick={importChecklist} sx={{ textTransform: "capitalize" }} variant='contained'>{importing ? "Importing the selected..." : "Import selected"}</Button>}
                <Button
                    startIcon={Icons.default.CloseIcon}
                    onClick={() => {
                        setUnselectedItems( [] )
                        setSelectedItems( [] )
                        setState( false )
                    }}
                    sx={{ textTransform: "capitalize" }}
                    color="error"
                    variant='outlined'
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ImportChecklistItemsDialog
